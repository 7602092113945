import React, { Children } from "react";
import styled from "@emotion/styled";
import {
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Button,
  HStack,
  Box,
  Heading,
  Flex,
  Text,
} from "@chakra-ui/react";
import { icons } from "../../../utils/icons";
import {
  white,
  primary,
  secondary,
  border,
  textBlack,
  bgLight,
  placeholderColor,
  newBorder,
  textPrimary,
  textSecondary,
} from "../../../utils/colors";
import FilterPopover from "../../../components/popovers/FilterPopover";
import useIsMobile from "../../../hooks/useIsMobile";

const SearchIcon = () => <img src={icons.search2} alt="search icon" />;

const TableTop = ({
  title,
  subtitle,
  children,
  className,
  searchName,
  onChange,
  onClick,
  placeholder,
  buttonLabel,
  buttonHandler,
  isDisabledButton,
  isFixedTitle,
  noSearch,
  isFilter,
  filterHandler,
  filterLabel,
  isFilterActive,
  setIsFilterActive,
  filterConfig,
  filterStatusOptions,
  savedFilters,
  savedFiltersKey,
}) => {
  const isMobile = useIsMobile();

  return (
    <>
      <Flex
        align={["flex-start", "center", "center"]}
        justify={["center", "space-between", "space-between"]}
        w="100%"
        p="1.5rem 0"
        direction={["column", "row", "row"]}
      >
        <Flex
          align={["center", "center", "center"]}
          direction={["column", "row", "row"]}
          w="100%"
        >
          <Box
            p={["0", "0", "0 2rem 0 0"]}
            w={!isFixedTitle ? "180px" : "initial"}
          >
            <Heading
              size="md"
              fontWeight="600"
              p={["0rem 0 0.75rem", "0", "0"]}
              textAlign={["center", "left", "left"]}
            >
              {title}
            </Heading>
            {subtitle && (
              <Text
                fontSize="sm"
                color={textSecondary}
                isTruncated
                maxWidth="200px"
              >
                {subtitle}
              </Text>
            )}
          </Box>
          {!noSearch && (
            <InputGroup w={["100%", "300px", "300px"]}>
              <Input
                type="text"
                placeholder={placeholder}
                size="lg"
                bg={white}
                borderRadius="10rem"
                border={`1px solid ${newBorder}`}
                color={textPrimary}
                value={searchName}
                onChange={onChange}
                height="3rem"
                fontSize="0.938rem"
                fontWeight="500"
                _placeholder={{ color: placeholderColor }}
                _focusVisible={{
                  zIndex: "1",
                  borderColor: primary,
                  boxShadow: `0 0 0 1px ${primary}`,
                }}
              />
              <InputLeftElement h="100%" pl="1rem">
                <IconButton
                  h="34px"
                  w="34px"
                  icon={<SearchIcon />}
                  borderRadius="full"
                  _hover={{ bgColor: "none" }}
                  _focus={{ bgColor: "none" }}
                  variant="ghost"
                />
              </InputLeftElement>
            </InputGroup>
          )}
        </Flex>

        <Flex align="center" gap="2" display={["none", "flex", "flex"]}>
          {children}

          {isFilter && (
            <FilterPopover
              onFilterApply={filterHandler}
              isFilterActive={isFilterActive}
              setIsFilterActive={setIsFilterActive}
              filterConfig={filterConfig}
              filterStatusOptions={filterStatusOptions}
              savedFilters={savedFilters}
              savedFiltersKey={savedFiltersKey}
            />
          )}

          {buttonHandler && (
            <Button
              bg={primary}
              color={white}
              variant="solid"
              onClick={buttonHandler}
              size="lg"
              borderRadius="10rem"
              fontSize="md"
              fontWeight="600"
              lineHeight="1"
              _hover={{ bg: secondary }}
              isDisabled={isDisabledButton}
            >
              {buttonLabel}
            </Button>
          )}
        </Flex>
      </Flex>

      {buttonHandler && isMobile && (
        <Button
          position="fixed"
          bg={primary}
          color={white}
          variant="solid"
          onClick={buttonHandler}
          size="lg"
          borderRadius="10rem"
          fontSize="md"
          fontWeight="600"
          lineHeight="1"
          _hover={{ bg: secondary }}
          isDisabled={isDisabledButton}
          bottom="2rem"
          right="2rem"
          zIndex={90}
        >
          {buttonLabel}
        </Button>
      )}
    </>
  );
};

export default TableTop;
