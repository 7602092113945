import React from "react";
import {
  Text,
  Image,
  HStack,
  Stack,
  Flex,
  Tooltip,
  Avatar,
  Icon,
  Box,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import "dayjs/locale/en"; // Import the locale you want to use (if not already done)
import "dayjs/locale/lt";
import {
  white,
  textSecondary,
  textPrimary,
  buttonBlueText,
  buttonGreenText,
  newBorder,
  newDarkBlue,
  buttonBlue,
  buttonGreen,
  buttonRed,
  greenVivid,
  buttonRedNew,
  buttonRedText,
  backgroundLight,
  buttonLightPurple,
} from "../../../../utils/colors";
import { FaRegCircleCheck, FaRegCircleDot } from "react-icons/fa6";
import StatusButton from "../../../../components/buttons/StatusButton"; // Update the path accordingly
import MoreButton from "../../../../components/buttons/MoreButton";
import TooltipStatusEntry from "../../../../components/partials/TooltipStatusEntry"; // Update the path accordingly
import ColumnStatusCountEntry from "../../../../components/partials/ColumnStatusCountEntry";

export const columns = (
  dispatch,
  openEditSidePanelModal,
  translate,
  updateSpeakerStatus,
  deleteSpeakerHandler,
  handleSortChange
) => {
  return [
    {
      Header: () => (
        <Box onClick={() => handleSortChange("customId")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.customId")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "customId",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { customId } = row.original;

        return (
          <Text
            maxWidth="50px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="11px"
            color={textSecondary}
            fontWeight="500"
          >
            {customId}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("lastName")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("speaker.columnSpeaker")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "lastName",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { firstName, lastName, email, image } = row.original;

        const name = `${lastName} ${firstName}`;

        return (
          <HStack gap={2}>
            {image ? (
              <Image
                w="3rem"
                h="3rem"
                src={image}
                alt={name}
                borderRadius="10rem"
                objectFit="cover"
              />
            ) : (
              <Avatar
                name={name}
                // bg="#dceeff" color="#3a74a9"
              />
            )}
            <Stack gap={0} maxWidth="200px" overflow="hidden">
              <Text
                fontSize="md"
                color={textPrimary}
                fontWeight="600"
                isTruncated
                maxWidth="200px"
              >
                {name}
              </Text>
              {email && (
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  isTruncated
                  noOfLines={1}
                  whiteSpace="normal"
                  overflow="hidden"
                  w="100%"
                  maxWidth="200px"
                >
                  {email.toLowerCase()}
                </Text>
              )}
            </Stack>
          </HStack>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("city")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("speaker.columnCity")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "city",
      disableSortBy: true,
      Cell: ({ row }) => {
        const city =
          row.original.address.length > 0 ? row.original.address[0].city : null;

        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {city ? city : "-"}
          </Text>
        );
      },
    },
    // {
    //   Header: translate('speaker.columnBirthDate'),
    //   disableSortBy: false,
    //   accessor: (row) => {
    //     const { birthDate } = row;

    //     return (
    //       <Text fontSize="sm" fontWeight="500" color={textSecondary}>
    //         {birthDate}
    //       </Text>
    //     );
    //   },
    // },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("phone")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("speaker.columnPhone")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "phone",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { phone } = row.original;

        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {phone}
          </Text>
        );
      },
    },
    {
      Header: translate("attendee.columnStats"),
      disableSortBy: false,
      accessor: (row) => {
        const { courses } = row;

        // Initialize counters for each status

        let upcomingCount = 0;
        let completedCount = 0;
        let draftCount = 0;
        let cancelledCount = 0;

        // Loop through the courses and count courses for each status
        courses.forEach((course) => {
          if (course.status === "upcoming") {
            upcomingCount++;
          } else if (course.status === "completed") {
            completedCount++;
          } else if (course.status === "draft") {
            draftCount++;
          } else if (course.status === "cancelled") {
            cancelledCount++;
          }
        });

        return (
          <Stack spacing={1}>
            <Tooltip
              p={4}
              borderRadius="1rem"
              bg={newDarkBlue}
              label={
                <Flex
                  direction="column"
                  justify="flex-start"
                  align="flex-start"
                >
                  <TooltipStatusEntry
                    bg={buttonLightPurple}
                    color={textPrimary}
                    label={translate("status.upcoming")}
                    count={upcomingCount}
                    icon="FaClock"
                  />

                  <TooltipStatusEntry
                    bg={buttonGreen}
                    color={buttonGreenText}
                    label={translate("status.completed")}
                    count={completedCount}
                    icon="FaRegCircleCheck"
                  />
                  <TooltipStatusEntry
                    bg={backgroundLight}
                    color={textSecondary}
                    label={translate("status.draft")}
                    count={draftCount}
                    icon="FaRegCircle"
                  />

                  <TooltipStatusEntry
                    bg={buttonRed}
                    color={buttonRedText}
                    label={translate("status.cancelled")}
                    count={cancelledCount}
                    icon="FaCircleXmark"
                  />
                </Flex>
              }
              fontSize="md"
            >
              <HStack>
                <ColumnStatusCountEntry
                  bg={buttonLightPurple}
                  color={textPrimary}
                  count={upcomingCount}
                  icon="FaClock"
                />
                <Text
                  fontSize="0.875rem"
                  fontWeight="500"
                  lineHeight="1"
                  color={newBorder}
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  |
                </Text>
                <ColumnStatusCountEntry
                  bg={buttonGreen}
                  color={buttonGreenText}
                  count={completedCount}
                  icon="FaRegCircleCheck"
                />
              </HStack>
            </Tooltip>
          </Stack>
        );
      },
    },

    {
      Header: translate("speaker.columnNextEvent"),
      disableSortBy: false,
      accessor: (row) => {
        const { courses } = row;

        console.log("next event courses", courses);

        // Filter out cancelled courses
        const upcomingCourses = courses.filter(
          (course) => course.status !== "cancelled"
        );

        // Find the course with the earliest start date among upcoming courses
        const nextCourse = upcomingCourses.reduce((acc, course) => {
          if (
            !acc.startDate ||
            (course.startDate && course.startDate < acc.startDate)
          ) {
            return course;
          }
          return acc;
        }, {});

        // Format the date using dayjs
        const formattedStartDate = nextCourse.startDate
          ? dayjs(nextCourse.startDate).format("DD MMMM")
          : "-";

        // Render Tooltip only if there is a next event
        if (nextCourse.startDate) {
          return (
            <Tooltip
              p={4}
              bg={white}
              borderRadius="1rem"
              border={`1px solid ${newBorder}`}
              label={
                <Flex align="center" direction="column">
                  <Flex align="center">
                    {nextCourse.image ? (
                      <Box
                        h="2rem"
                        w="2rem"
                        borderRadius="0.5rem"
                        overflow="hidden"
                        mr={2}
                      >
                        <Image
                          w="100%"
                          h="100%"
                          src={nextCourse.image}
                          alt={nextCourse.title}
                          objectFit="cover"
                        />
                      </Box>
                    ) : (
                      <Avatar
                        h="2rem"
                        w="2rem"
                        borderRadius="0.5rem"
                        src={nextCourse.image || ""}
                        name={nextCourse.title}
                        mr={2}
                      />
                    )}
                    <Stack gap={0}>
                      <Text
                        fontSize="0.875rem"
                        fontWeight="500"
                        color={textPrimary}
                        isTruncated
                        noOfLines={1}
                        whiteSpace="normal"
                        overflow="hidden"
                        w="100%"
                        maxWidth="200px"
                      >
                        {nextCourse.title}
                      </Text>
                      <Text
                        fontSize="0.875rem"
                        fontWeight="500"
                        textTransform="capitalize"
                        color={textSecondary}
                      >
                        {dayjs(nextCourse.startDate).format("DD MMMM")}
                      </Text>
                    </Stack>
                  </Flex>
                </Flex>
              }
              fontSize="md"
            >
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textSecondary}
                textTransform="capitalize"
              >
                {formattedStartDate}
              </Text>
            </Tooltip>
          );
        } else {
          return (
            <Text fontSize="sm" fontWeight="500" color={textSecondary}>
              -
            </Text>
          );
        }
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("common.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { status, _id } = row.original;

        const handleUpdateStatus = (newStatus) => {
          dispatch(updateSpeakerStatus(_id, newStatus));
        };

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
              options="active"
            />
            <MoreButton
              id={_id}
              deleteHandler={deleteSpeakerHandler}
              editHandler={(e) => {
                e.stopPropagation();
                openEditSidePanelModal(_id);
              }}
            />
          </HStack>
        );
      },
    },
    // {
    //   Header: 'Action',
    //   accessor: (values) => {
    //     const speakerId = values._id;
    //     return speakerId;
    //   },
    //   disableSortBy: true,
    //   Cell: ({ cell }) => {
    //     const { value } = cell;

    //     const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);

    //     const deleteHandler = (speakerId) => {
    //       dispatch(deleteSpeaker(speakerId));
    //       toast.success('Speaker Deleted');
    //       setIsConfirmModalOpen(false);
    //     };

    //     return (
    //       <>
    //         <HStack justify="flex-end">
    //           <Button
    //             bg="#dceeff"
    //             color="#3a74a9"
    //             _hover={{ bg: '#3a74a9', color: white }}
    //             py={4}
    //             px={8}
    //             aria-label="Edit"
    //             fontSize="sm"
    //           >
    //             {translate('button.view')}
    //           </Button>
    //           <IconButton
    //             bg="#dceeff"
    //             color="#3a74a9"
    //             _hover={{ bg: '#3a74a9', color: white }}
    //             onClick={(e) => {
    //               e.stopPropagation();
    //               openEditSidePanelModal(value);
    //             }}
    //             aria-label="Edit"
    //             icon={<EditIcon />}
    //           />
    //           <IconButton
    //             bg="#fbd3d3"
    //             color="#bf5959"
    //             _hover={{ bg: '#bf5959', color: white }}
    //             onClick={(e) => {
    //               e.stopPropagation();
    //               setIsConfirmModalOpen(true);
    //             }}
    //             aria-label="Delete"
    //             icon={<DeleteIcon />}
    //           />
    //         </HStack>
    //         <ConfirmDeleteModal
    //           isOpen={isConfirmModalOpen}
    //           onClose={() => setIsConfirmModalOpen(false)}
    //           confirmAction={() => deleteHandler(value)}
    //         />
    //       </>
    //     );
    //   },
    // },
  ];
};
