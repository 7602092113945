import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { listCourses } from "../../actions/courseActions";
import { useTranslation } from "react-i18next";
import OptionsOutsideSelect from "./OptionsOutsideSelect";
import { Flex, Text } from "@chakra-ui/react";

const SelectCourseMain = ({
  currentOptions,
  value,
  onChange,
  isSelectedHidden,
  title,
  placeholderText,
  noteText,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const courseList = useSelector((state) => state.courseList);
  const { courses } = courseList;

  const [courseOptions, setCourseOptions] = useState([]);

  useEffect(() => {
    dispatch(listCourses());
  }, [dispatch]);

  useEffect(() => {
    if (courses && courses.length > 0) {
      const activeCourses = courses.filter(
        (course) => course.status !== "completed"
      );
      const options = activeCourses.map((s) => ({
        value: s._id,
        label: s.title,
        image: s.image,
        status: s.status,
      }));

      console.log("courses", courses);
      console.log("currentOptions", currentOptions);

      // It seems like currentOptions is already in the correct format based on the logs,
      // so we might not need to reformat it. If it's not, adjust this part.
      const currentOptionsIds = currentOptions.map((co) => co._id); // Assuming currentOptions contains {_id, ...}

      const filteredOptions = options.filter(
        (option) => !currentOptionsIds.includes(option.value)
      );

      console.log("filteredOptions", filteredOptions);
      setCourseOptions(filteredOptions);
    }
  }, [courses, currentOptions]);

  console.log("Multi Courses select courses loaded", courses);
  console.log("currentOptions", currentOptions);
  console.log("courseOptions", courseOptions);

  return (
    <>
      <div>
        <OptionsOutsideSelect
          onChange={onChange}
          isMulti
          options={courseOptions}
          value={value}
          title={title}
          isSelectedHidden={isSelectedHidden}
          placeholderText={placeholderText}
        />
        {noteText && (
          <Flex mt={2} align="center" justify="center">
            <Text fontSize="xs" color="gray.500">
              {noteText}
            </Text>
          </Flex>
        )}
      </div>
    </>
  );
};

export default SelectCourseMain;
