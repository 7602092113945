import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { registerTenant, verifyEmail } from "../../actions/tenantActions";
import {
  Box,
  Flex,
  Image,
  Heading,
  Icon,
  IconButton,
  Select,
  Text,
  Spinner,
} from "@chakra-ui/react";
import logo from "../../assets/icons/logo.svg";
import { Link } from "react-router-dom";
import { white, newBorder, textPrimary, dark } from "../../utils/colors";
import { FaArrowLeft } from "react-icons/fa6";
import Agreement from "./partials/Agreement";
import StepZero from "./forms/register/steps/StepZero";
import Step1Form from "./forms/register/steps/Step1Form";
import VerificationStep from "./forms/register/steps/VerificationStep";
import StepSuccess from "./forms/register/steps/StepSuccess";
import { motion } from "framer-motion";
import ToastComponent from "../../components/partials/ToastComponent";
import {
  TENANT_ALREADY_EXISTS_RESET,
  TENANT_ALREADY_VERIFIED_RESET,
  TENANT_REGISTER_RESET,
  TENANT_VERIFY_RESET,
} from "../../constants/tenantConstants";
import { login } from "../../actions/authActions";
import LanguageButton from "../../components/buttons/LanguageButton";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const Register = () => {
  // GENERAL
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastComponent = ToastComponent();

  // CONSTANTS
  const [show, setShow] = React.useState(false);

  const [clinicName, setClinicName] = useState(""); // Add clinicName state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");

  const storedLanguage = localStorage.getItem("language");
  const initialLanguage = storedLanguage || "lt"; // Default to 'en'
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);

  // REDUX
  const tenantRegister = useSelector((state) => state.tenantRegister);
  const {
    loading: loadingRegister,
    error: errorRegister,
    success,
  } = tenantRegister;

  const tenantVerify = useSelector((state) => state.tenantVerify);
  const {
    loading: loadingVerify,
    error: errorVerify,
    success: successVerify,
    alreadyVerified,
  } = tenantVerify;

  // REDUX: Access the tenantExists state from the Redux store
  const { tenantExists } = useSelector((state) => state.tenantRegister);

  // STEPS
  const [currentStep, setCurrentStep] = useState(0);

  // REDUX
  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo, loading, error } = authLogin;

  // SEARCH PARAMS
  const [searchParams] = useSearchParams();

  // FUNCTIONS
  const redirect = searchParams.get("redirect")
    ? searchParams.get("redirect")
    : "/patients";

  // USE EFFECT
  useEffect(() => {
    if (userInfo) {
      console.log("Redirecting to:", redirect);
      navigate(redirect);
    }

    setTimeout(() => {
      setShow(true);
    }, 100);
  }, [userInfo, redirect, navigate]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const initialLanguage = storedLanguage || "lt";
    setCurrentLanguage(initialLanguage);

    i18n.changeLanguage(initialLanguage);
    dayjs.locale(initialLanguage);
    // eslint-disable-next-line
  }, []);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    dayjs.locale(lng);
    setCurrentLanguage(lng);
    console.log("Language changed to", lng);
  };

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    changeLanguage(selectedLanguage);
  };

  // HANDLERS

  const handleMenuClick = (language) => {
    changeLanguage(language);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClinicNameChange = (e) => {
    setClinicName(e.target.value);
  };

  const handleLoginClick = () => {
    if (email && password) {
      dispatch(login(email, password));
    } else {
      console.error("Email or password not available for login");
      // Handle the error case here
    }
  };

  // STEPS HANDLERS
  const goToNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleEmailButtonClick = () => {
    goToNextStep(); // Go to step 1
  };

  // SUBMIT
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(registerTenant(clinicName, email, password));
  };

  // SUBMIT for Verification
  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    dispatch(verifyEmail(email, verificationCode));
  };

  // USE EFFECT
  // useEffect for handling registration success
  useEffect(() => {
    if (success) {
      toastComponent.showToast(t("toasts.registerSuccess"), "success", 3000);

      setCurrentStep(2);

      dispatch({ type: TENANT_REGISTER_RESET });
    }
  }, [success, dispatch, toastComponent, t]);

  // Messages to be displayed in sequence
  const messages = [
    "Creating a database...",
    "Setting up your account...",
    "Finalizing setup...",
  ];

  // useEffect for handling verification success
  useEffect(() => {
    if (successVerify) {
      let totalDelay = 0;
      const messageInterval = 3000 / messages.length;

      messages.forEach((message, index) => {
        setTimeout(() => {
          setLoadingMessage(message);
          // Transition to success step after the last message
          if (index === messages.length - 1) {
            setTimeout(() => {
              setLoadingMessage("");
              toastComponent.showToast(
                t("toasts.verifySuccess"),
                "success",
                3000
              );
              setCurrentStep(3);
            }, messageInterval);
          }
        }, totalDelay);

        totalDelay += messageInterval;
      });

      dispatch({ type: TENANT_VERIFY_RESET });
    }
  }, [successVerify, dispatch, toastComponent, t]);

  // Separate useEffects for error handling
  useEffect(() => {
    if (errorRegister) {
      toastComponent.showToast(t("toasts.registerError"), "error", 3000);
      dispatch({ type: TENANT_REGISTER_RESET });
    }
  }, [errorRegister, dispatch, toastComponent, t]);

  useEffect(() => {
    if (errorVerify) {
      toastComponent.showToast(t("toasts.verifyError"), "error", 3000);
      dispatch({ type: TENANT_VERIFY_RESET });
    }
  }, [errorVerify, dispatch, toastComponent, t]);

  useEffect(() => {
    if (tenantExists) {
      toastComponent.showToast(t("toasts.tenantExists"), "error", 3000);
      dispatch({ type: TENANT_ALREADY_EXISTS_RESET });
    }

    if (alreadyVerified) {
      toastComponent.showToast(t("toasts.alreadyVerified"), "error", 3000);
      dispatch({ type: TENANT_ALREADY_VERIFIED_RESET });
    }
  }, [
    tenantExists,
    alreadyVerified,
    dispatch,
    toastComponent,
    t,
    loadingRegister,
    loadingVerify,
  ]);

  // LOGS
  console.log("Current step:", currentStep);

  return (
    <>
      <Box position="fixed" top="3rem" right="3rem" zIndex="9">
        <LanguageButton
          currentLanguage={currentLanguage}
          languages={["en", "lt", "lv", "pl"]}
          onChangeLanguage={handleChangeLanguage}
          onMenuClick={handleMenuClick}
        />
      </Box>

      {show && (
        <Flex
          w="100%"
          h="100vh"
          align="stretch"
          position="relative"
          justify="flex-start"
          bg={white}
        >
          <Box position="fixed" top="2rem" left="2rem" zIndex={2}>
            <Link to="/">
              <Image src={logo} h="1.5rem" w="initial" />
            </Link>
          </Box>
          {/* <Box position="fixed" top="2rem" right="2rem" zIndex={2}>
            <Select
              value={currentStep}
              onChange={(e) => setCurrentStep(parseInt(e.target.value))}
            >
              <option value={0}>Step 0</option>
              <option value={1}>Step 1</option>
              <option value={2}>Step 2</option>
              <option value={3}>Step 3</option>
            </Select>
          </Box> */}

          <Box
            w="29%"
            position="relative"
            flexShrink={0}
            as={motion.div}
            initial={{ opacity: 0, y: -40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
          >
            <Video
              playsInline
              autoPlay
              className="auth-sidebar-video"
              loop
              muted
              // src="https://cdn.dribbble.com/uploads/48226/original/b8bd4e4273cceae2889d9d259b04f732.mp4?1689028949"
              src="https://cdn.dribbble.com/uploads/48292/original/30fd1f7b63806eff4db0d4276eb1ac45.mp4?1689187515"
            ></Video>
          </Box>

          <Flex
            w="100%"
            h="100%"
            align="flex-start"
            justify="center"
            pl="100px"
            direction="column"
            as={motion.div}
            initial={{ opacity: 0, y: -40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
          >
            {currentStep === 1 && (
              <Box
                position="absolute"
                top="2rem"
                left={`calc(29% + 2rem)`}
                zIndex={2}
              >
                <IconButton
                  border={`1px solid ${newBorder}`}
                  size="md"
                  borderRadius="100rem"
                  bg={white}
                  fontSize="20px"
                  icon={<Icon h="0.875rem" as={FaArrowLeft} />}
                  onClick={() => setCurrentStep(0)}
                />
              </Box>
            )}

            <form
              onSubmit={handleSubmit}
              style={{
                width: "100%",
                maxWidth: "410px",
              }}
            >
              {!loadingRegister && (
                <Heading
                  fontSize="1.875rem"
                  fontWeight="700"
                  color={textPrimary}
                  mb={6}
                >
                  {currentStep === 0 &&
                    !loadingMessage &&
                    t("common.registerHeading")}
                  {currentStep === 1 &&
                    !loadingMessage &&
                    t("common.registerHeading")}
                </Heading>
              )}

              {currentStep === 0 && (
                <StepZero handleEmailButtonClick={handleEmailButtonClick} />
              )}
              {currentStep === 2 && !loadingMessage && !loadingVerify && (
                <VerificationStep
                  onVerify={handleVerificationSubmit}
                  verificationCode={verificationCode}
                  setVerificationCode={setVerificationCode}
                  email={email}
                />
              )}
              {currentStep === 1 && !loadingMessage && !loadingRegister && (
                <Step1Form
                  clinicName={clinicName}
                  handleClinicNameChange={handleClinicNameChange}
                  email={email}
                  handleEmailChange={handleEmailChange}
                  password={password}
                  handlePasswordChange={handlePasswordChange}
                />
              )}

              {currentStep === 3 && (
                <StepSuccess
                  email={email}
                  password={password}
                  handleLoginClick={handleLoginClick}
                />
              )}

              {loadingRegister && (
                <Flex direction="column" justify="center" align="center">
                  <Spinner size="xl" />
                </Flex>
              )}

              {loadingVerify && (
                <Flex direction="column" justify="center" align="center">
                  <Spinner size="xl" />
                </Flex>
              )}

              {loadingMessage && (
                <Flex direction="column" justify="center" align="center">
                  <Spinner size="xl" />
                  <Text mt={8} color={textPrimary} fontWeight="500" size="md">
                    {loadingMessage}
                  </Text>
                </Flex>
              )}
            </form>
          </Flex>
        </Flex>
      )}
    </>
  );
};

const Video = styled.video`
  display: block;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
`;

export default Register;
