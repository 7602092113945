import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  listSpeakers,
  deleteSpeaker,
  updateSpeakerStatus,
} from "../../../../actions/speakerActions";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import { Box, Text, Button } from "@chakra-ui/react";
import {
  textSecondary,
  white,
  newBorder,
  primary,
} from "../../../../utils/colors";
import { icons } from "../../../../utils/icons";
import { columns } from "../columns/SpeakersColumns"; // Adjust the path according to your file structure
import CreateSpeaker from "../sidepanels/CreateSpeaker";
import CitySelect from "../../../../components/select/CitySelect";
import EditSpeaker from "../sidepanels/EditSpeaker";
import { useTranslation } from "react-i18next";
import {
  SPEAKER_STATUS_UPDATE_RESET,
  SPEAKER_CREATE_RESET,
  SPEAKER_UPDATE_RESET,
} from "../../../../constants/speakerConstants";
import ToastComponent from "../../../../components/partials/ToastComponent";

const Speakers = () => {
  const navigate = useNavigate();

  const [speakerId, setSpeakerId] = useState("");
  const [searchTitle, setSearchTitle] = useState("");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const pageSizes = [4, 10, 25, 100];
  const [isFilterActive, setIsFilterActive] = useState(false);

  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const toast = useToast();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  const speakerList = useSelector((state) => state.speakerList);
  const {
    loading,

    speakers,
    error,
    pages,
  } = speakerList;

  const speakerDelete = useSelector((state) => state.speakerDelete);
  const { success: successDelete } = speakerDelete;

  const speakerCreate = useSelector((state) => state.speakerCreate);
  const { success: successCreate, speaker: createdSpeaker } = speakerCreate;

  const speakerUpdate = useSelector((state) => state.speakerUpdate);
  const { success: successUpdate } = speakerUpdate;

  const speakerStatusUpdate = useSelector((state) => state.speakerStatusUpdate);
  const { success: successStatusUpdate } = speakerStatusUpdate;

  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  // SORTING
  const [sortField, setSortField] = useState("customId");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(listSpeakers(searchTitle, 1, pageSize, sortField, sortOrder));
  };

  // HANDLERS

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const deleteSpeakerHandler = (speakerId) => {
    console.log("click");
    dispatch(deleteSpeaker(speakerId));
    toastComponent.showToast(t("toasts.speakerDelete"), "success", 3000);
    dispatch({ type: "SPEAKER_DELETE_RESET" });
    if (speakers.length === 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    }

    if (successCreate) {
      toastComponent.showToast(t("toasts.speakerCreate"), "success", 3000);
      dispatch({ type: SPEAKER_CREATE_RESET });
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch(listSpeakers(searchTitle, 1, pageSize, sortField, sortOrder));
    } else if (successStatusUpdate) {
      toastComponent.showToast(t("toasts.speakerStatus"), "success", 3000);
      dispatch({ type: SPEAKER_STATUS_UPDATE_RESET });
      document.body.classList.remove("no-scroll");
    } else if (successUpdate) {
      // NOT WORKING NOTIFICATION
      toastComponent.showToast(t("toasts.speakerEdit"), "success", 3000);
      closeEditSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch({ type: SPEAKER_UPDATE_RESET });

      dispatch(listSpeakers(searchTitle, 1, pageSize, sortField, sortOrder));
    } else {
      dispatch(listSpeakers(searchTitle, 1, pageSize, sortField, sortOrder));
    }
  }, [
    dispatch,
    toast,
    userInfo,
    navigate,
    searchTitle,
    page,
    pageSize,
    successCreate,
    successUpdate,
    successDelete,
    successStatusUpdate,
  ]);

  const gotoPage = (page) => {
    setPage(page);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = (value) => {
    setEditSidePanelOpen(true);
    setSpeakerId(value);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    setSpeakerId("");
    document.body.classList.remove("no-scroll");
  };
  const closeEditSidePanelModal = () => {
    setEditSidePanelOpen(false);
    setSidePanelOpen(false);
    setSpeakerId("");
    document.body.classList.remove("no-scroll");
  };

  const FilterIcon = () => <img src={icons.filter} alt="filter" />;

  const handleRowClick = (value) => {
    navigate(`/academy/speakers/${value}`);
  };

  console.log("speakers", speakers);

  return (
    <>
      <Helmet>
        <title>Admin Speakers</title>
        <meta
          name="description"
          content="Megagen SMT Tool administration speakers list"
        />
      </Helmet>
      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("speaker.createSpeaker")}
        noBorder={false}
      >
        <CreateSpeaker close={closeSidePanelModal} />
      </SidePanel>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanelModal}
        title={t("speaker.editSpeaker")}
        noBorder={false}
      >
        <EditSpeaker speakerId={speakerId} close={closeEditSidePanelModal} />
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("speaker.speakers")}
                onChange={onChangeSearchTitle}
                placeholder={t("speaker.search")}
                searchName={searchTitle}
                buttonLabel={t("speaker.createSpeaker")}
                buttonHandler={openSidePanelModal}
                isFixedTitle
              />
            </Box>

            <Table.Container
              columns={columns(
                dispatch,
                openEditSidePanelModal,
                translate,
                updateSpeakerStatus,
                deleteSpeakerHandler,
                handleSortChange
              )}
              data={speakers}
              loading={loading}
              error={error}
              navigate={navigate}
              navigateTo="/academy/speakers"
              noItemsButtonHandler={openSidePanelModal}
              noItemsTitle={t("noItems.noSpeakers")}
              noItemsDescription={t("noItems.noSpeakersText")}
              noItemsButtonLabel={t("noItems.noSpeakersButton")}
              onRowClick={handleRowClick}
              sortField={sortField}
              sortOrder={sortOrder}
            />
            <Table.Pagination
              page={page}
              pages={pages}
              nextPage={nextPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
              handlePageSizeChange={handlePageSizeChange}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
            />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default Speakers;
