import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import {
  Flex,
  Button,
  VStack,
  Text,
  Checkbox,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Wrap,
  WrapItem,
  IconButton,
} from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import FormControlDatePicker from "./FormControlDatePicker";
import FormControlSliderPicker from "./FormControlSliderPicker";
import {
  textPrimary,
  textSecondary,
  primary,
  white,
  backgroundLight,
} from "../../utils/colors";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

const AppointmentPicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const { t } = useTranslation();

  // DURATION
  const [duration, setDuration] = useState(30);

  // DAY
  const day = dayjs(startDate).date();
  const nameOfWeekDay = dayjs(startDate).format("dddd");
  const month = dayjs(startDate).format("MMMM");
  const year = dayjs(startDate).format("YYYY");

  const handlePrevDay = () => {
    setStartDate(dayjs(startDate).subtract(1, "day").toDate());
  };

  const handleNextDay = () => {
    setStartDate(dayjs(startDate).add(1, "day").toDate());
  };

  // TIME
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);

  // Placeholder function to generate or fetch time slots
  // This function would ideally fetch available time slots for the selected day from your backend
  const fetchTimeSlots = (date) => {
    // Example: Generate time slots for the given day, from 9:00 to 17:00, every hour
    let slots = [];
    for (let hour = 9; hour <= 17; hour++) {
      const timeString = `${hour}:00`; // Simple hour format, adjust as needed
      slots.push(timeString);
    }
    return slots;
  };

  const selectTimeSlot = (timeSlot) => {
    // Update startDate with the selected time slot
    // This will need adjustment based on your date handling (e.g., using dayjs or a similar library)
    const [hours, minutes] = timeSlot.split(":").map(Number);
    const newStartDate = new Date(startDate.setHours(hours, minutes));
    setStartDate(new Date(newStartDate)); // Ensure we're triggering a state update
    setSelectedTimeSlot(timeSlot);
  };

  const selectClosestTimeSlot = (slots) => {
    const currentTime = dayjs();
    const closestSlot = slots.reduce((closest, current) => {
      const currentSlotTime = dayjs().hour(current.split(":")[0]).minute(0);
      const closestSlotTime = dayjs().hour(closest.split(":")[0]).minute(0);
      return Math.abs(currentSlotTime.diff(currentTime)) <
        Math.abs(closestSlotTime.diff(currentTime))
        ? current
        : closest;
    }, slots[0]);
    selectTimeSlot(closestSlot);
  };

  // USE EFFECT

  useEffect(() => {
    const slots = fetchTimeSlots(startDate);
    setTimeSlots(slots);
    if (slots.length > 0) {
      selectClosestTimeSlot(slots);
    }
  }, []);

  useEffect(() => {
    // Calculate the new endDate whenever startDate or duration changes
    const start = dayjs(startDate);
    const end = start.add(duration, "minute");
    setEndDate(end.toDate());
  }, [startDate, duration, setEndDate]);

  // LOGS
  // console.log("startDate", startDate);
  // console.log("endDate", endDate);
  // console.log("selectedTimeSlot", selectedTimeSlot);
  // console.log("duration", duration);

  return (
    <>
      <Flex direction="column">
        <Flex direction="column" align="center" justify="center">
          <Text
            fontSize="xs"
            color={textPrimary}
            fontWeight="500"
            textTransform="uppercase"
          >
            {nameOfWeekDay}
          </Text>
          <Flex align="center">
            <IconButton
              icon={<FaArrowLeft />}
              color={textSecondary}
              borderRadius="50%"
              size="md"
              fontSize="sm"
              onClick={handlePrevDay}
            />
            <Text
              my="1rem"
              mx="2rem"
              fontSize="2rem"
              color={textPrimary}
              fontWeight="600"
            >
              {day}
            </Text>
            <IconButton
              icon={<FaArrowRight />}
              color={textSecondary}
              borderRadius="50%"
              size="md"
              fontSize="sm"
              onClick={handleNextDay}
            />
          </Flex>
          <Text
            fontSize="md"
            color={textPrimary}
            fontWeight="500"
            textTransform="capitalize"
          >
            {month}, {year}
          </Text>
        </Flex>

        <Flex
          align="center"
          justify="center"
          p="2rem 0 0"
          m="0 auto"
          maxW="300px"
        >
          <FormControlDatePicker
            type="date"
            value={startDate}
            placeholder={t("placeholder.startDate")}
            onChange={(newValue) => {
              const dateValue = new Date(newValue);
              setStartDate(dateValue);
            }}
            onOpen={() => {}}
            w="100%"
            errorMessage={t("formError.startDateRequired")}
            className="appointment-custom"
          />
        </Flex>

        <Wrap p="4" justify="center">
          {timeSlots.map((timeSlot, index) => (
            <WrapItem key={index}>
              <Button
                onClick={() => selectTimeSlot(timeSlot)}
                bg={selectedTimeSlot === timeSlot ? primary : backgroundLight}
              >
                {timeSlot}
              </Button>
            </WrapItem>
          ))}
        </Wrap>

        <FormControlSliderPicker
          label={t("appointment.duration")}
          min={15} // Minimum duration of 15 minutes
          max={120} // Maximum duration of 120 minutes, adjust as needed
          value={duration}
          setValue={setDuration}
          step={15} // Adjust in 15-minute increments
          w="100%" // Adjust the width as necessary
          isRequired={true}
          errorMessage={t("formError.durationRequired")}
        />
      </Flex>
    </>
  );
};

export default AppointmentPicker;
