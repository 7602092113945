import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Panel from "../../../components/shared/Panel";
import { Flex, Box, Text, Heading, Icon, Select } from "@chakra-ui/react";
import ToastComponent from "../../../components/partials/ToastComponent";
import { useTranslation } from "react-i18next";
import Table from "../../../components/shared/Table";
import { FaUser } from "react-icons/fa6";
import BoxUser from "./partials/BoxUser";
import DashboardPatients from "./partials/DashboardPatients";
import DashboardTreatmentPlans from "./partials/DashboardTreatmentPlans";
import DashboardTreatmentCoordinator from "./partials/DashboardTreatmentCoordinator";
import DashboardTopGainers from "./partials/DashboardTopGainers";
import useRoleCheck from "../../../hooks/useRoleCheck";

const Dashboard = () => {
  // GENERAL
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // ROLES
  const isAdmin = useRoleCheck("admin");
  const isDoctor = useRoleCheck("employee_doctor");

  // CONSTANTS

  // REDUX
  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  // console.log("Dashboard component rendered. userInfo:", userInfo);

  // HANDLERS

  // USEEFFECT

  useEffect(() => {
    // USEEFFECT LOGIN
    if (!userInfo) {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo]);

  // SIDEPANEL HANDLERS

  // LOGS

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>{t("pageTitle.dashboard")}</title>
        <meta name="description" content="Dentacasa Treatment Plan List" />
      </Helmet>

      {/* SIDEPANELS  */}

      {/* MAIN  */}
      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                noSearch
                title={t("dashboard.dashboard")}
                placeholder={t("dashboard.search")}
                // buttonLabel={t("dashboard.newDashboard")}
              >
                <Box h="3rem" />
              </Table.Top>
            </Box>

            <Flex w="100%" p="0 2rem" direction="column">
              {!isDoctor && <DashboardPatients />}

              <DashboardTopGainers />

              <DashboardTreatmentPlans />
              <DashboardTreatmentCoordinator />
            </Flex>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default Dashboard;
