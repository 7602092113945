import axios from "axios";
import React, { useState } from "react";
import {
  Box,
  Image,
  Spinner,
  Stack,
  useToast,
  Text,
  Flex,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import { icons } from "../../utils/icons";
import styled from "@emotion/styled";
import {
  backgroundLight,
  newBorder,
  textSecondary,
  textPrimary,
  white,
  primary,
  secondary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";

const LogoUpload = ({ currentImage, image, setImage, onUpload }) => {
  const [uploading, setUploading] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

      setImage(data);
      setUploading(false);

      //   // Display success toast
      //   toast({
      //     title: t("toast.logoUploadSuccess"),
      //     status: "success",
      //     duration: 3000,
      //     isClosable: true,
      //   });

      onUpload(data);
    } catch (error) {
      console.error(error);
      setUploading(false);

      // Display error toast
      toast({
        title: t("toast.logoUploadError"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  console.log(image);

  return (
    <Flex
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border={`1px dashed ${newBorder}`}
      borderRadius="0.75rem"
      p={4}
      w="300px"
      h="214px"
    >
      <Tooltip
        bg={secondary}
        p={4}
        borderRadius="1rem"
        placement="top"
        label={
          <Text fontSize="sm" fontWeight="500" color={white}>
            {image ? t("common.editLogo") : t("common.addLogo")}
          </Text>
        }
      >
        <Box
          border={`1px solid ${newBorder}`}
          w="100%"
          h="100%"
          flexShrink="0"
          borderRadius="50%"
          overflow="hidden"
          bg={white}
          display="flex"
          margin="0 auto"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            document.getElementById("file-upload").click();
          }}
          role="group"
          _hover={{
            cursor: "pointer",
          }}
        >
          {image ? (
            <Image
              src={currentImage || image || icons.noImage}
              objectFit="cover"
              h="100%"
              w="100%"
            />
          ) : (
            <Image
              h="1.75rem"
              w="1.75rem"
              src={icons.noImage}
              transition="all 0.2s ease-in-out"
              _groupHover={{ transform: "scale(1.2)" }}
            />
          )}

          {uploading && <Spinner />}
        </Box>
      </Tooltip>

      <Flex display="none">
        <UploadButton htmlFor="file-upload" className="custom-file-upload">
          <Icon2 src={icons.upload} />
          {image ? t("common.change") : t("imageUpload.browse")}
        </UploadButton>
        <input
          id="file-upload"
          type="file"
          onChange={uploadFileHandler}
          style={{ display: "none" }}
        />
      </Flex>
    </Flex>
  );
};
const Icon2 = styled.img`
  height: 14px;
  margin-right: 8px;
`;

const UploadButton = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 12px 1.5rem;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
  background: ${white};
  color: ${textSecondary};
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${primary};
    color: ${white};
  }
`;

export default LogoUpload;
