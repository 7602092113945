import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Flex,
  Text,
  Tooltip,
  Icon,
  Image,
  Stack,
  Heading,
  useToast,
} from "@chakra-ui/react";
import {
  white,
  textSecondary,
  backgroundLight,
} from "../../../../utils/colors";
import FormControlEmployeeNew from "../../../../components/input/FormControlEmployeeNew";
import FormControlTenantType from "../../../../components/input/FormControlTenantType";
import FormControlOperatingRooms from "../../../../components/input/FormControlOperatingRooms";
import { useTranslation } from "react-i18next";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import {
  updateTenantAdminUser,
  updateTenantType,
  updateTenantOperatingRooms,
} from "../../../../actions/tenantActions";
import {
  TENANT_UPDATE_ADMIN_USER_RESET,
  TENANT_UPDATE_TYPE_RESET,
  TENANT_UPDATE_OPERATING_ROOMS_RESET,
} from "../../../../constants/tenantConstants";

const TenantSettings = ({ tenant }) => {
  // TRANSLATION
  const { t } = useTranslation();

  // TOAST
  const toast = useToast();

  // STATE
  const [adminUser, setAdminUser] = useState({});
  const [tenantType, setTenantType] = useState({});
  const [operatingRooms, setOperatingRooms] = useState(1);
  const [supportEmail, setSupportEmail] = useState("");
  const [serviceEmail, setServiceEmail] = useState("");

  // REDUX
  const dispatch = useDispatch();

  const tenantUpdateAdminUser = useSelector(
    (state) => state.tenantUpdateAdminUser
  );
  const { success: successUpdateAdminUser } = tenantUpdateAdminUser;

  const tenantUpdateType = useSelector((state) => state.tenantUpdateType);
  const { success: successUpdateType } = tenantUpdateType;

  const tenantUpdateOperatingRooms = useSelector(
    (state) => state.tenantUpdateOperatingRooms
  );
  const { success: successUpdateOperatingRooms } = tenantUpdateOperatingRooms;

  // HANDLERS

  // const handleEmployeeChangeMulti = (selectedOptions) => {
  //   console.log("Selected Options: ", selectedOptions);

  //   const selectedValues = selectedOptions
  //     ? selectedOptions.map((option) => option.value)
  //     : [];

  //   setAdminUser(selectedValues);

  //   console.log("Selected Values: ", selectedValues);
  // };

  const handleEmployeeChangeSingle = (selectedOption) => {
    console.log("Selected Option in handle: ", selectedOption);
    const selectedValue = selectedOption ? selectedOption.value : "";

    setAdminUser(selectedValue);
    console.log("Selected Value: ", selectedValue);

    dispatch(updateTenantAdminUser({ adminUser: adminUser }));
    console.log("Admin User: ", adminUser);
  };

  const handleTypeChange = (selectedOption) => {
    console.log("Selected Option in handle: ", selectedOption);
    const selectedValue = selectedOption ? selectedOption.value : "";

    // Schedule the state update
    setTenantType(selectedValue);
    console.log("Selected Value: ", selectedValue);

    console.log("Dispatching with tenantType:", selectedValue);
    // Use selectedValue directly since it's the current value selected by the user
    dispatch(updateTenantType({ type: selectedValue }));

    console.log("tenantType: ", selectedValue); // This will log the correct, current value
  };

  const handleOperatingRoomsSave = (newValue) => {
    console.log("Saving new operating rooms count:", newValue);
    // Dispatch the action to update operating rooms
    dispatch(updateTenantOperatingRooms({ operatingRooms: newValue }));
  };

  // USE EFFECT
  useEffect(() => {
    if (tenant) {
      setAdminUser(tenant.adminUser);
      setTenantType(tenant.type);
      setOperatingRooms(tenant.operatingRooms);
      setSupportEmail(tenant.email);
      setServiceEmail(tenant.email);
    }
  }, [tenant]);

  useEffect(() => {
    if (successUpdateAdminUser) {
      toast({
        title: t("toasts.tenantAdminUpdate"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      dispatch({ type: TENANT_UPDATE_ADMIN_USER_RESET });
    } else if (successUpdateType) {
      toast({
        title: t("toasts.tenantTypeUpdate"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      dispatch({ type: TENANT_UPDATE_TYPE_RESET });
    } else if (successUpdateOperatingRooms) {
      toast({
        title: t("toasts.tenantOperatingRoomsUpdate"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      dispatch({ type: TENANT_UPDATE_OPERATING_ROOMS_RESET });
    }
  }, [successUpdateAdminUser, successUpdateType, successUpdateOperatingRooms]);

  console.log("Tenant: ", tenant);
  console.log("Admin User: ", adminUser);
  console.log("Tenant Type: ", tenantType);
  console.log("Operating Rooms: ", operatingRooms);

  return (
    <Flex w="100%" direction="column" p="1rem 2rem">
      <Flex w="100%" justify="space-between" gap="2rem">
        <Stack gap={1} w="300px">
          <Heading fontSize="1.125rem" fontWeight="600">
            {t("tenant.adminDetails")}
          </Heading>
          <Text
            color={textSecondary}
            fontSize="xs"
            maxW="300px"
            fontWeight="500"
          >
            {t("tenant.adminDetailsText")}
          </Text>
        </Stack>
        <Flex
          w="100%"
          direction="column"
          bg={backgroundLight}
          borderRadius="1rem"
          p="1.5rem"
        >
          <FormControlEmployeeNew
            label={t("form.currentCompanyAdmin")}
            value={adminUser}
            placeholder={t("placeholder.currentCompanyAdmin")}
            onChange={handleEmployeeChangeSingle}
            isMulti={false}
            noBottom
            isSearchable={false}
          />
        </Flex>
      </Flex>

      <Flex w="100%" justify="space-between" gap="2rem" mt="3rem">
        <Stack gap={1} w="300px">
          <Heading fontSize="1.125rem" fontWeight="600">
            {t("tenant.generalInformation")}
          </Heading>
          <Text
            color={textSecondary}
            fontSize="xs"
            maxW="300px"
            fontWeight="500"
          >
            {t("tenant.generalInformationText")}
          </Text>
        </Stack>
        <Flex
          w="100%"
          direction="column"
          bg={backgroundLight}
          borderRadius="1rem"
          p="1.5rem"
        >
          <FormControlTenantType
            value={tenantType}
            label={t("form.tenantCompanyType")}
            placeholder={t("placeholder.tenantCompanyType")}
            onChange={handleTypeChange}
          />

          <FormControlOperatingRooms
            initialValue={operatingRooms}
            value={operatingRooms}
            label={
              tenantType === "clinic"
                ? t("form.tenantCompanyChairSize")
                : t("form.tenantCompanyLabChairSize")
            }
            placeholder={t("placeholder.tenantChairSize")}
            onSave={handleOperatingRoomsSave}
          />
        </Flex>
      </Flex>

      <Flex w="100%" justify="space-between" gap="2rem" mt="3rem">
        <Stack gap={1} w="300px">
          <Heading fontSize="1.125rem" fontWeight="600">
            {t("tenant.emailSettings")}
          </Heading>
          <Text
            color={textSecondary}
            fontSize="xs"
            maxW="300px"
            fontWeight="500"
          >
            {t("tenant.emailSettingsText")}
          </Text>
        </Stack>
        <Flex
          w="100%"
          direction="column"
          bg={backgroundLight}
          borderRadius="1rem"
          p="1.5rem"
        >
          <FormControlDefault
            type="email"
            isRequired
            label={t("form.tenantSupportEmail")}
            value={supportEmail}
            placeholder={t("placeholder.tenantSupportEmail")}
            onChange={(e) => {
              setSupportEmail(e.target.value);
              // setEmailTouched(true);
            }}
            // isTouched={emailTouched}
            // isError={isEmailError}
            w="100%"
            errorMessage={t("formError.tenantSupportEmailRequired")}
            isDisabled
          />

          <FormControlDefault
            type="email"
            isRequired
            label={t("form.tenantServiceEmail")}
            value={serviceEmail}
            placeholder={t("placeholder.tenantServiceEmail")}
            onChange={(e) => {
              setServiceEmail(e.target.value);
              // setEmailTouched(true);
            }}
            // isTouched={emailTouched}
            // isError={isEmailError}
            w="100%"
            errorMessage={t("formError.tenantServiceEmailRequired")}
            isDisabled
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TenantSettings;
