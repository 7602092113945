import axios from "axios";
import {
  EMPLOYEE_LIST_REQUEST,
  EMPLOYEE_LIST_SUCCESS,
  EMPLOYEE_LIST_FAIL,
  EMPLOYEE_DETAILS_REQUEST,
  EMPLOYEE_DETAILS_SUCCESS,
  EMPLOYEE_DETAILS_FAIL,
  EMPLOYEE_CREATE_REQUEST,
  EMPLOYEE_CREATE_SUCCESS,
  EMPLOYEE_CREATE_FAIL,
  EMPLOYEE_UPDATE_REQUEST,
  EMPLOYEE_UPDATE_SUCCESS,
  EMPLOYEE_UPDATE_FAIL,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_DELETE_FAIL,
} from "../constants/employeeConstants";

import { logout } from "./userActions";

export const listEmployees =
  (keyword = "", pageNumber = "", pageSize = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: EMPLOYEE_LIST_REQUEST });

      const { data } = await axios.get(
        `/api/employees?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}`
      );

      dispatch({
        type: EMPLOYEE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EMPLOYEE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listEmployeeDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/employees/${id}`);

    dispatch({
      type: EMPLOYEE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createEmployee =
  (
    firstName,
    lastName,
    email,
    birthDate,
    phone,
    phoneAlt,
    gender,
    child,
    personalCode,
    role,
    profession,
    street,
    zip,
    city,
    state,
    country,
    iban,
    bankName,
    licenseNumber,
    rolePermission,
    password
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EMPLOYEE_CREATE_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      let sendPassword = null;
      if (password === "") {
        sendPassword = null;
      } else {
        sendPassword = password;
      }

      // Prepare the data payload
      const dataPayload = {
        firstName,
        lastName,
        email,
        birthDate,
        phone,
        phoneAlt,
        gender,
        child,
        personalCode,
        role,
        profession,
        street,
        zip,
        city,
        state,
        country,
        iban,
        bankName,
        licenseNumber,
        rolePermission,
        password: sendPassword,
      };

      // Make the API call
      const { data } = await axios.post(
        `/api/users/create-employee`,
        dataPayload,
        config
      );

      dispatch({
        type: EMPLOYEE_CREATE_SUCCESS,
        payload: data,
      });

      console.log("USER_CREATE_EMPLOYEE_SUCCESS data", data);
    } catch (error) {
      dispatch({
        type: EMPLOYEE_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

export const updateEmployee = (employee) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EMPLOYEE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/employees/${employee._id}`,
      employee,
      config
    );

    dispatch({
      type: EMPLOYEE_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: EMPLOYEE_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (error.response.status === 401) {
      dispatch(logout());
    }
  }
};

export const deleteEmployee = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EMPLOYEE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/employees/${id}`, config);

    dispatch({
      type: EMPLOYEE_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
