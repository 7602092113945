import {
  PUBLIC_PATIENT_TREATMENT_PLAN_LIST_REQUEST,
  PUBLIC_PATIENT_TREATMENT_PLAN_LIST_SUCCESS,
  PUBLIC_PATIENT_TREATMENT_PLAN_LIST_FAIL,
  PUBLIC_PATIENT_TREATMENT_PLAN_LIST_RESET,
  PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_REQUEST,
  PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_SUCCESS,
  PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_FAIL,
  PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_RESET,
  PUBLIC_TENANT_DETAILS_REQUEST,
  PUBLIC_TENANT_DETAILS_SUCCESS,
  PUBLIC_TENANT_DETAILS_FAIL,
  PUBLIC_TENANT_DETAILS_RESET,
  PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_REQUEST,
  PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_SUCCESS,
  PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_FAIL,
  PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_RESET,
  PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_REQUEST,
  PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_SUCCESS,
  PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_FAIL,
  PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_RESET,
  PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_REQUEST,
  PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_SUCCESS,
  PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_FAIL,
  PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_RESET,
} from "../constants/publicConstants";

export const publicPatientTreatmentPlanListReducer = (
  state = { patientInfo: {} },
  action
) => {
  switch (action.type) {
    case PUBLIC_PATIENT_TREATMENT_PLAN_LIST_REQUEST:
      return { loading: true, patientInfo: {} };
    case PUBLIC_PATIENT_TREATMENT_PLAN_LIST_SUCCESS:
      return {
        loading: false,
        patientInfo: action.payload, // Store the entire payload as patientInfo
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PUBLIC_PATIENT_TREATMENT_PLAN_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PUBLIC_PATIENT_TREATMENT_PLAN_LIST_RESET:
      return { patientInfo: {} };
    default:
      return state;
  }
};

export const publicPatientTreatmentPlanDetailsReducer = (
  state = { treatmentPlan: {} },
  action
) => {
  switch (action.type) {
    case PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_SUCCESS:
      return { loading: false, treatmentPlan: action.payload };
    case PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_RESET:
      return { treatmentPlan: {} };
    default:
      return state;
  }
};

export const publicTenantDetailsReducer = (state = { tenant: {} }, action) => {
  switch (action.type) {
    case PUBLIC_TENANT_DETAILS_REQUEST:
      return { loading: true, tenant: {} };
    case PUBLIC_TENANT_DETAILS_SUCCESS:
      return { loading: false, tenant: action.payload };
    case PUBLIC_TENANT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PUBLIC_TENANT_DETAILS_RESET:
      return { tenant: {} };
    default:
      return state;
  }
};

export const publicPatientApproveTreatmentPlanReducer = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_REQUEST:
      return { loading: true };
    case PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_SUCCESS:
      return { loading: false, success: true };
    case PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_FAIL:
      return { loading: false, error: action.payload };
    case PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_RESET:
      return { success: false };
    default:
      return state;
  }
};

export const publicPatientCancelTreatmentPlanReducer = (
  state = { success: false },
  action
) => {
  switch (action.type) {
    case PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_REQUEST:
      return { loading: true };
    case PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_SUCCESS:
      return { loading: false, success: true };
    case PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_FAIL:
      return { loading: false, error: action.payload };
    case PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_RESET:
      return { success: false };
    default:
      return state;
  }
};

export const publicPatientDownloadPdfTreatmentPlanReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_REQUEST:
      return { loading: true };
    case PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_SUCCESS:
      return { loading: false, pdf: action.payload };
    case PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_FAIL:
      return { loading: false, error: action.payload };
    case PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_RESET:
      return {};
    default:
      return state;
  }
};
