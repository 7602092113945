import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import { Box, Select, Input } from "@chakra-ui/react";
import ToastComponent from "../../../../components/partials/ToastComponent";

import { useTranslation } from "react-i18next";

import {
  white,
  primary,
  textPrimary,
  textSecondary,
  newBorder,
  backgroundLight,
} from "../../../../utils/colors";

const Profile = () => {
  // GENERAL
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // CONSTANTS
  const [serviceId, setServiceId] = useState("");

  // REDUX
  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  // HANDLERS

  // USEEFFECT

  // LOGS

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>{t("pageTitle.profile")}</title>
        <meta name="description" content="Dentacasa Account Profile" />
      </Helmet>

      {/* MAIN  */}
      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>asfsfa</Box>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default Profile;
