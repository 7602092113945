import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  listServiceGroups,
  deleteServiceGroup,
  updateServiceGroupStatus,
  updateServiceGroupIsConsulting,
} from "../../../../actions/serviceGroupActions";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import { Box } from "@chakra-ui/react";
import ToastComponent from "../../../../components/partials/ToastComponent";
import { columns } from "../columns/ServiceGroupListColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import {
  SERVICEGROUP_DELETE_RESET,
  SERVICEGROUP_UPDATE_RESET,
  SERVICEGROUP_CREATE_RESET,
  SERVICEGROUP_UPDATE_STATUS_RESET,
  SERVICEGROUP_UPDATE_ISCONSULTING_RESET,
} from "../../../../constants/serviceGroupConstants";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import CreateServiceGroup from "../sidepanels/CreateServiceGroup";
import EditServiceGroup from "../sidepanels/EditServiceGroup";
import IsFilterActive from "../../../../components/partials/IsFilterActive";
import ServiceGroupsStats from "../partials/ServiceGroupsStats";
import useRoleCheck from "../../../../hooks/useRoleCheck";

const ServiceGroupList = () => {
  // GENERAL
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // ROLES
  const isDoctor = useRoleCheck("employee_doctor");
  const isAdmin = useRoleCheck("admin");

  // PAGINATION
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [4, 10, 25, 100];

  // REDUX
  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  const serviceGroupList = useSelector((state) => state.serviceGroupList);
  const {
    loading,
    error,
    serviceGroups,
    pages,
    countsByStatus,
    globalTotalCount,
  } = serviceGroupList;

  const serviceGroupCreate = useSelector((state) => state.serviceGroupCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    serviceGroup: createdServiceGroup,
  } = serviceGroupCreate;

  const serviceGroupUpdate = useSelector((state) => state.serviceGroupUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = serviceGroupUpdate;

  const serviceGroupDelete = useSelector((state) => state.serviceGroupDelete);

  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = serviceGroupDelete;

  const serviceGroupUpdateStatus = useSelector(
    (state) => state.serviceGroupUpdateStatus
  );
  const {
    loading: loadingUpdateStatus,
    error: errorUpdateStatus,
    success: successUpdateStatus,
  } = serviceGroupUpdateStatus;

  const serviceGroupUpdateIsConsulting = useSelector(
    (state) => state.serviceGroupUpdateIsConsulting
  );
  const {
    loading: loadingUpdateIsConsulting,
    error: errorUpdateIsConsulting,
    success: successUpdateIsConsulting,
  } = serviceGroupUpdateIsConsulting;

  // SEARCH
  const [searchTitle, setSearchTitle] = useState("");

  // FILTERING
  const [isFilterActive, setIsFilterActive] = useState(false);

  const filterConfig = {
    serviceGroups: true,
  };

  const [filterParams, setFilterParams] = useState({
    status: "",
    isConsulting: "",
  });

  const handleFilterApply = (filters) => {
    setFilterParams(filters);
    setIsFilterActive(true);
    setPage(1);
    localStorage.setItem(
      "filterSettings_serviceGroups",
      JSON.stringify(filters)
    ); // Example for PatientList
    dispatch(
      listServiceGroups(
        searchTitle,
        1,
        pageSize,
        sortField,
        sortOrder,
        false, // Assuming `fetchAll` is false by default
        filters.status,
        filters.isConsulting
      )
    );

    // console.log("filters", filters);
  };

  // SORTING
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(
      listServiceGroups(
        searchTitle,
        1,
        pageSize,
        sortField,
        sortOrder,
        false,
        filterParams.status,
        filterParams.isConsulting
      )
    );
  };

  // SIDEPANELS
  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);

  // CONSTANTS
  const [serviceGroupId, setServiceGroupId] = useState("");

  // FILTER INACTIVE
  // Function to filter services based on role
  const filterServiceGroupsByRole = (serviceGroups) => {
    if (isAdmin) {
      // Admins see all services
      return serviceGroups;
    } else {
      // Non-admins see only active services
      return serviceGroups.filter(
        (serviceGroup) => serviceGroup.status === "active"
      );
    }
  };

  // HANDLERS

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const deleteServiceGroupHandler = (serviceGroupId) => {
    console.log("click");
    dispatch(deleteServiceGroup(serviceGroupId));
    toastComponent.showToast(t("toasts.serviceGroupDelete"), "success", 3000);
    dispatch({ type: SERVICEGROUP_DELETE_RESET });

    if (serviceGroups.length === 1) {
      setPage(page - 1);
    }
  };

  // USEEFFECT

  useEffect(() => {
    console.log("trying to access saved filters");
    const savedFilters = localStorage.getItem("filterSettings_serviceGroups");
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      setFilterParams(filters);
      setIsFilterActive(true);
    }
    console.log("savedFilters", savedFilters);
  }, []);

  useEffect(() => {
    // USEEFFECT LOGIN
    if (!userInfo) {
      navigate("/login");
    }

    // USEEFFECT CREATE
    if (successCreate) {
      toastComponent.showToast(t("toasts.serviceGroupCreate"), "success", 3000);
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");

      dispatch({ type: SERVICEGROUP_CREATE_RESET });
    }
    // USEEFFECT UPDATE
    else if (successUpdate) {
      toastComponent.showToast(
        t("toasts.serviceGroupsUpdate"),
        "success",
        3000
      );
      closeEditSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch({ type: SERVICEGROUP_UPDATE_RESET });

      dispatch(
        listServiceGroups(
          searchTitle,
          page,
          pageSize,
          sortField,
          sortOrder,
          false,
          filterParams.status,
          filterParams.isConsulting
        )
      );
    }
    // USEEFFECT UPDATE STATUS
    else if (successUpdateStatus) {
      toastComponent.showToast(
        t("toasts.serviceGroupUpdateStatus"),
        "success",
        3000
      );
      dispatch({ type: SERVICEGROUP_UPDATE_STATUS_RESET });

      dispatch(
        listServiceGroups(
          searchTitle,
          page,
          pageSize,
          sortField,
          sortOrder,
          false,
          filterParams.status,
          filterParams.isConsulting
        )
      );
    } else if (successUpdateIsConsulting) {
      toastComponent.showToast(
        t("toasts.serviceGroupUpdateIsConsulting"),
        "success",
        3000
      );
      dispatch({ type: SERVICEGROUP_UPDATE_ISCONSULTING_RESET });

      dispatch(
        listServiceGroups(
          searchTitle,
          page,
          pageSize,
          sortField,
          sortOrder,
          false,
          filterParams.status,
          filterParams.isConsulting
        )
      );
    }

    // USEEFFECT MAIN
    else {
      dispatch(
        listServiceGroups(
          searchTitle,
          page,
          pageSize,
          sortField,
          sortOrder,
          false,
          filterParams.status,
          filterParams.isConsulting
        )
      );
    }
  }, [
    dispatch,
    navigate,
    searchTitle,
    page,
    pageSize,
    successCreate,
    successUpdate,
    successDelete,
    successUpdateStatus,
    successUpdateIsConsulting,
    userInfo,
    sortField,
    sortOrder,
    filterParams,
  ]);

  // SIDEPANEL HANDLERS

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = (value) => {
    setEditSidePanelOpen(true);
    setServiceGroupId(value);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    setServiceGroupId("");
    document.body.classList.remove("no-scroll");
  };
  const closeEditSidePanelModal = () => {
    setEditSidePanelOpen(false);
    setSidePanelOpen(false);
    setServiceGroupId("");
    document.body.classList.remove("no-scroll");
  };

  const handleRowClick = (value) => {
    if (userInfo && userInfo.isAdmin) {
      openEditSidePanelModal(value);
    } else {
      // toastComponent.showToast(
      //   t("toasts.notAuthorized"), // Update this message to a suitable one
      //   "error",
      //   3000
      // );
      console.log("not authorized");
    }
  };

  // LOGS

  console.log("serviceGroups", serviceGroups);

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>Service Group List</title>
        <meta name="description" content="Dentacasa Service List" />
      </Helmet>

      {/* SIDEPANELS  */}
      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("serviceGroup.createServiceGroup")}
        noBorder={true}
      >
        {isAdmin && <CreateServiceGroup close={closeSidePanelModal} />}
      </SidePanel>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanelModal}
        title={t("serviceGroup.editServiceGroup")}
        noBorder={true}
      >
        {isAdmin && (
          <EditServiceGroup
            close={closeEditSidePanelModal}
            serviceGroupId={serviceGroupId}
          />
        )}
      </SidePanel>

      {/* MAIN  */}
      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("serviceGroup.serviceGroups")}
                onChange={onChangeSearchTitle}
                placeholder={t("serviceGroup.search")}
                searchName={searchTitle}
                buttonLabel={t("serviceGroup.createServiceGroup")}
                buttonHandler={openSidePanelModal}
                isFilter
                filterLabel={t("patient.filter")}
                filterHandler={handleFilterApply}
                isFilterActive={isFilterActive}
                setIsFilterActive={setIsFilterActive}
                filterConfig={filterConfig}
                savedFilters={filterParams}
                savedFiltersKey="filterSettings_serviceGroups"
                isDisabledButton={!isAdmin}
                isFixedTitle={true}
              ></Table.Top>
            </Box>

            <ServiceGroupsStats
              loading={loading}
              countsByStatus={countsByStatus}
              globalTotalCount={globalTotalCount}
            />

            {isFilterActive && !loading && (
              <IsFilterActive label={t("filter.filterActive")} />
            )}

            <Table.Container
              columns={columns(
                dispatch,
                translate,
                deleteServiceGroupHandler,
                openEditSidePanelModal,
                updateServiceGroupStatus,
                handleSortChange,
                updateServiceGroupIsConsulting,
                isAdmin
              )}
              // data={serviceGroups}

              data={filterServiceGroupsByRole(serviceGroups)}
              onRowClick={handleRowClick}
              loading={loading}
              error={error}
              navigate={navigate}
              navigateTo="/service-groups"
              noItemsButtonHandler={openSidePanelModal}
              noItemsTitle={t("noItems.noServiceGroups")}
              noItemsDescription={t("noItems.noServiceGroupsText")}
              noItemsButtonLabel={t("noItems.noServiceGroupsButton")}
              sortField={sortField}
              sortOrder={sortOrder}
            />
            <Table.Pagination
              loading={loading}
              page={page}
              pages={pages}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
              setPage={setPage}
              setPageSize={setPageSize}
              totalCount={serviceGroups.length}
            />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default ServiceGroupList;
