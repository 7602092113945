import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPatientStatistics } from "../../../../actions/statisticActions";
import {
  Flex,
  Heading,
  Select,
  Text,
  Spinner,
  Button,
  Icon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  backgroundLight,
  newBorder,
  textPrimary,
  primary,
  textSecondary,
  white,
} from "../../../../utils/colors";
import BoxUser from "./BoxUser";
import useCountUp from "../../../../hooks/useCountUp";
import { FaCaretDown } from "react-icons/fa6";

const DashboardPatients = () => {
  // GENERAL
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // REDUX
  const statisticPatient = useSelector((state) => state.statisticPatient);
  const { loading, error, statisticPatients } = statisticPatient;

  // States for statistics
  const [totalPatients, setTotalPatients] = useState(0);
  const [newPatients, setNewPatients] = useState(0);
  const [activePatients, setActivePatients] = useState(0);
  const [inactivePatients, setInactivePatients] = useState(0);
  const [consultingPatients, setConsultingPatients] = useState(0);
  const [planningTreatmentPatients, setPlanningTreatmentPatients] = useState(0);
  const [approvedTreatmentPatients, setApprovedTreatmentPatients] = useState(0);
  const [ongoingTreatmentPatients, setOngoingTreatmentPatients] = useState(0);
  const [completedTreatmentPatients, setCompletedTreatmentPatients] =
    useState(0);
  const [cancelledTreatmentPatients, setCancelledTreatmentPatients] =
    useState(0);

  // console.log("activePatients type:", typeof activePatients);

  const animatedTotalPatients = useCountUp(totalPatients);
  const animatedNewPatients = useCountUp(newPatients);
  const animatedActivePatients = useCountUp(activePatients);
  const animatedInactivePatients = useCountUp(inactivePatients);
  const animatedConsultingPatients = useCountUp(consultingPatients);
  const animatedPlanningTreatmentPatients = useCountUp(
    planningTreatmentPatients
  );
  const animatedApprovedTreatmentPatients = useCountUp(
    approvedTreatmentPatients
  );
  const animatedOngoingTreatmentPatients = useCountUp(ongoingTreatmentPatients);
  const animatedCompletedTreatmentPatients = useCountUp(
    completedTreatmentPatients
  );
  const animatedCancelledTreatmentPatients = useCountUp(
    cancelledTreatmentPatients
  );

  // USEEFFECT
  useEffect(() => {
    dispatch(getPatientStatistics());
  }, [dispatch]);

  useEffect(() => {
    if (statisticPatients) {
      setTotalPatients(statisticPatients.total || 0);
      setNewPatients(statisticPatients.new || 0);
      setActivePatients(statisticPatients.active || 0);
      setInactivePatients(statisticPatients.inactive || 0);
      setConsultingPatients(statisticPatients.consulting || 0);
      setPlanningTreatmentPatients(statisticPatients.planningTreatment || 0);
      setApprovedTreatmentPatients(statisticPatients.approvedTreatment || 0);
      setOngoingTreatmentPatients(statisticPatients.ongoingTreatment || 0);
      setCompletedTreatmentPatients(statisticPatients.completedTreatment || 0);
      setCancelledTreatmentPatients(statisticPatients.cancelledTreatment || 0);
    }
  }, [statisticPatients]);

  // LOG

  //   console.log("statisticPatients", statisticPatients);
  // console.log("totalPatients", totalPatients);
  // console.log("newPatients", newPatients);
  // console.log("activePatients", activePatients);
  // console.log("inactivePatients", inactivePatients);

  if (error) return <p>Error: {error}</p>;

  return (
    <Flex
      w="100%"
      direction="column"
      p="0rem 1.5rem"
      border={`1px solid ${newBorder}`}
      borderRadius="1rem"
      mb={8}
    >
      <Flex w="100%" justify="space-between" align="center" py={4}>
        <Heading size="sm" fontWeight="600" color={textPrimary}>
          {t("dashboard.patients")}
        </Heading>
        <Button
          size="md"
          borderRadius="10rem"
          border={`1px solid ${newBorder}`}
          bg={white}
          color={textSecondary}
          fontWeight="500"
          fontSize="sm"
          isDisabled
        >
          {t("common.filter")}
          <Icon as={FaCaretDown} ml={1} h="0.75rem" w="0.6rem" />
        </Button>
      </Flex>

      <Flex w="100%" pt={4} pb={8}>
        <BoxUser
          loading={loading}
          label={t("dashboard.patientsTotal")}
          data={animatedTotalPatients}
          color={primary}
        />
        <BoxUser
          loading={loading}
          label={t("dashboard.patientsNew")}
          data={animatedNewPatients}
          color="#A0D1FF"
        />
        <BoxUser
          loading={loading}
          label={t("dashboard.patientsActive")}
          data={
            animatedActivePatients +
            animatedConsultingPatients +
            animatedPlanningTreatmentPatients +
            animatedApprovedTreatmentPatients +
            animatedOngoingTreatmentPatients +
            animatedCompletedTreatmentPatients +
            animatedCancelledTreatmentPatients
          }
          color="#65C790"
        />
        <BoxUser
          loading={loading}
          label={t("dashboard.patientsInactive")}
          data={animatedInactivePatients}
          color="#D6D6D7"
        />
      </Flex>
    </Flex>
  );
};

export default DashboardPatients;
