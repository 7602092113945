import axios from "axios";
import {
  STATISTIC_PATIENT_STATS_REQUEST,
  STATISTIC_PATIENT_STATS_SUCCESS,
  STATISTIC_PATIENT_STATS_FAIL,
  STATISTIC_TREATMENT_PLAN_STATS_REQUEST,
  STATISTIC_TREATMENT_PLAN_STATS_SUCCESS,
  STATISTIC_TREATMENT_PLAN_STATS_FAIL,
  STATISTIC_TOP_SERVICES_REQUEST,
  STATISTIC_TOP_SERVICES_SUCCESS,
  STATISTIC_TOP_SERVICES_FAIL,
  STATISTIC_TOP_SERVICE_GROUPS_REQUEST,
  STATISTIC_TOP_SERVICE_GROUPS_SUCCESS,
  STATISTIC_TOP_SERVICE_GROUPS_FAIL,
  STATISTIC_TOP_EMPLOYEES_REQUEST,
  STATISTIC_TOP_EMPLOYEES_SUCCESS,
  STATISTIC_TOP_EMPLOYEES_FAIL,
  STATISTIC_COORDINATORS_REQUEST,
  STATISTIC_COORDINATORS_SUCCESS,
  STATISTIC_COORDINATORS_FAIL,
  STATISTIC_DOCTOR_TOP_SERVICE_REQUEST,
  STATISTIC_DOCTOR_TOP_SERVICE_SUCCESS,
  STATISTIC_DOCTOR_TOP_SERVICE_FAIL,
  STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_REQUEST,
  STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_SUCCESS,
  STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_FAIL,
  STATISTIC_COORDINATOR_USER_REQUEST,
  STATISTIC_COORDINATOR_USER_SUCCESS,
  STATISTIC_COORDINATOR_USER_FAIL,
  STATISTIC_EMPLOYEE_DOCTOR_STATS_REQUEST,
  STATISTIC_EMPLOYEE_DOCTOR_STATS_SUCCESS,
  STATISTIC_EMPLOYEE_DOCTOR_STATS_FAIL,
} from "../constants/statisticContstants";

import { logout } from "./userActions";

export const getPatientStatistics = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: STATISTIC_PATIENT_STATS_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/statistics/patients`, config);

    dispatch({
      type: STATISTIC_PATIENT_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STATISTIC_PATIENT_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    if (
      error.response &&
      error.response.data.message === "Not authorized, token failed"
    ) {
      dispatch(logout());
    }
  }
};

export const getTreatmentPlanStatistics = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: STATISTIC_TREATMENT_PLAN_STATS_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/statistics/treatment-plans`, config);

    dispatch({
      type: STATISTIC_TREATMENT_PLAN_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STATISTIC_TREATMENT_PLAN_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    if (
      error.response &&
      error.response.data.message === "Not authorized, token failed"
    ) {
      dispatch(logout());
    }
  }
};

export const getTopServices = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: STATISTIC_TOP_SERVICES_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Get top services
    const { data } = await axios.get(`/api/statistics/top-services`, config);

    dispatch({
      type: STATISTIC_TOP_SERVICES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STATISTIC_TOP_SERVICES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    if (
      error.response &&
      error.response.data.message === "Not authorized, token failed"
    ) {
      dispatch(logout());
    }
  }
};

export const getTopServiceGroups = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: STATISTIC_TOP_SERVICE_GROUPS_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Get top service groups
    const { data } = await axios.get(
      `/api/statistics/top-service-groups`,
      config
    );

    dispatch({
      type: STATISTIC_TOP_SERVICE_GROUPS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STATISTIC_TOP_SERVICE_GROUPS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    if (
      error.response &&
      error.response.data.message === "Not authorized, token failed"
    ) {
      dispatch(logout());
    }
  }
};

export const getTopEmployees = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: STATISTIC_TOP_EMPLOYEES_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Get top employees
    const { data } = await axios.get(`/api/statistics/top-employees`, config);

    dispatch({
      type: STATISTIC_TOP_EMPLOYEES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STATISTIC_TOP_EMPLOYEES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    if (
      error.response &&
      error.response.data.message === "Not authorized, token failed"
    ) {
      dispatch(logout());
    }
  }
};

export const getCoordinatorStatistics = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: STATISTIC_COORDINATORS_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Get top employees
    const { data } = await axios.get(`/api/statistics/coordinators`, config);

    dispatch({
      type: STATISTIC_COORDINATORS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STATISTIC_COORDINATORS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    if (
      error.response &&
      error.response.data.message === "Not authorized, token failed"
    ) {
      dispatch(logout());
    }
  }
};

export const getTopServicesDoctor = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: STATISTIC_DOCTOR_TOP_SERVICE_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    // Get top services
    const { data } = await axios.get(
      `/api/statistics/top-user-services`,
      config
    );

    dispatch({
      type: STATISTIC_DOCTOR_TOP_SERVICE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STATISTIC_DOCTOR_TOP_SERVICE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getTreatmentPlanStatisticsEmployee =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Get top services
      const { data } = await axios.get(
        `/api/statistics/employee-treatment-plans`,
        config
      );

      dispatch({
        type: STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getCoordinatorUserStatistics =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: STATISTIC_COORDINATOR_USER_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Get top services
      const { data } = await axios.get(
        `/api/statistics/coordinators-user`,
        config
      );

      dispatch({
        type: STATISTIC_COORDINATOR_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STATISTIC_COORDINATOR_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getEmployeeDoctorStatistics =
  (employeeId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: STATISTIC_EMPLOYEE_DOCTOR_STATS_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Get top services
      const { data } = await axios.get(
        `/api/statistics/employees/doctor/${employeeId}`,
        config
      );

      dispatch({
        type: STATISTIC_EMPLOYEE_DOCTOR_STATS_SUCCESS,
        payload: data,
      });

      console.log("data", data);
    } catch (error) {
      dispatch({
        type: STATISTIC_EMPLOYEE_DOCTOR_STATS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
