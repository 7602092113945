export const STATISTIC_PATIENT_STATS_REQUEST =
  "STATISTIC_PATIENT_STATS_REQUEST";
export const STATISTIC_PATIENT_STATS_SUCCESS =
  "STATISTIC_PATIENT_STATS_SUCCESS";
export const STATISTIC_PATIENT_STATS_FAIL = "STATISTIC_PATIENT_STATS_FAIL";
export const STATISTIC_PATIENT_STATS_RESET = "STATISTIC_PATIENT_STATS_RESET";

export const STATISTIC_TREATMENT_PLAN_STATS_REQUEST =
  "STATISTIC_TREATMENT_PLAN_STATS_REQUEST";
export const STATISTIC_TREATMENT_PLAN_STATS_SUCCESS =
  "STATISTIC_TREATMENT_PLAN_STATS_SUCCESS";
export const STATISTIC_TREATMENT_PLAN_STATS_FAIL =
  "STATISTIC_TREATMENT_PLAN_STATS_FAIL";
export const STATISTIC_TREATMENT_PLAN_STATS_RESET =
  "STATISTIC_TREATMENT_PLAN_STATS_RESET";

export const STATISTIC_TOP_SERVICES_REQUEST = "STATISTIC_TOP_SERVICES_REQUEST";
export const STATISTIC_TOP_SERVICES_SUCCESS = "STATISTIC_TOP_SERVICES_SUCCESS";
export const STATISTIC_TOP_SERVICES_FAIL = "STATISTIC_TOP_SERVICES_FAIL";
export const STATISTIC_TOP_SERVICES_RESET = "STATISTIC_TOP_SERVICES_RESET";

export const STATISTIC_TOP_SERVICE_GROUPS_REQUEST =
  "STATISTIC_TOP_SERVICE_GROUPS_REQUEST";
export const STATISTIC_TOP_SERVICE_GROUPS_SUCCESS =
  "STATISTIC_TOP_SERVICE_GROUPS_SUCCESS";
export const STATISTIC_TOP_SERVICE_GROUPS_FAIL =
  "STATISTIC_TOP_SERVICE_GROUPS_FAIL";
export const STATISTIC_TOP_SERVICE_GROUPS_RESET =
  "STATISTIC_TOP_SERVICE_GROUPS_RESET";

export const STATISTIC_TOP_EMPLOYEES_REQUEST =
  "STATISTIC_TOP_EMPLOYEES_REQUEST";
export const STATISTIC_TOP_EMPLOYEES_SUCCESS =
  "STATISTIC_TOP_EMPLOYEES_SUCCESS";
export const STATISTIC_TOP_EMPLOYEES_FAIL = "STATISTIC_TOP_EMPLOYEES_FAIL";
export const STATISTIC_TOP_EMPLOYEES_RESET = "STATISTIC_TOP_EMPLOYEES_RESET";

export const STATISTIC_COORDINATORS_REQUEST = "STATISTIC_COORDINATORS_REQUEST";
export const STATISTIC_COORDINATORS_SUCCESS = "STATISTIC_COORDINATORS_SUCCESS";
export const STATISTIC_COORDINATORS_FAIL = "STATISTIC_COORDINATORS_FAIL";
export const STATISTIC_COORDINATORS_RESET = "STATISTIC_COORDINATORS_RESET";

export const STATISTIC_DOCTOR_TOP_SERVICE_REQUEST =
  "STATISTIC_DOCTOR_TOP_SERVICE_REQUEST";
export const STATISTIC_DOCTOR_TOP_SERVICE_SUCCESS =
  "STATISTIC_DOCTOR_TOP_SERVICE_SUCCESS";
export const STATISTIC_DOCTOR_TOP_SERVICE_FAIL =
  "STATISTIC_DOCTOR_TOP_SERVICE_FAIL";
export const STATISTIC_DOCTOR_TOP_SERVICE_RESET =
  "STATISTIC_DOCTOR_TOP_SERVICE_RESET";

export const STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_REQUEST =
  "STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_REQUEST";
export const STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_SUCCESS =
  "STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_SUCCESS";
export const STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_FAIL =
  "STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_FAIL";
export const STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_RESET =
  "STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_RESET";

export const STATISTIC_COORDINATOR_USER_REQUEST =
  "STATISTIC_COORDINATOR_USER_REQUEST";
export const STATISTIC_COORDINATOR_USER_SUCCESS =
  "STATISTIC_COORDINATOR_USER_SUCCESS";
export const STATISTIC_COORDINATOR_USER_FAIL =
  "STATISTIC_COORDINATOR_USER_FAIL";
export const STATISTIC_COORDINATOR_USER_RESET =
  "STATISTIC_COORDINATOR_USER_RESET";

export const STATISTIC_EMPLOYEE_DOCTOR_STATS_REQUEST =
  "STATISTIC_EMPLOYEE_DOCTOR_STATS_REQUEST";
export const STATISTIC_EMPLOYEE_DOCTOR_STATS_SUCCESS =
  "STATISTIC_EMPLOYEE_DOCTOR_STATS_SUCCESS";
export const STATISTIC_EMPLOYEE_DOCTOR_STATS_FAIL =
  "STATISTIC_EMPLOYEE_DOCTOR_STATS_FAIL";
export const STATISTIC_EMPLOYEE_DOCTOR_STATS_RESET =
  "STATISTIC_EMPLOYEE_DOCTOR_STATS_RESET";
