import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Flex,
  Box,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Heading,
} from "@chakra-ui/react";
import {
  white,
  primary,
  textPrimary,
  textSecondary,
  backgroundLight,
  newBorder,
} from "../../../../../utils/colors";
import {
  listCourseAttendees,
  addCourseAttendee,
  removeCourseAttendee,
  updateCourseAttendeeStatus,
  updateCourseAttendeePaidStatus,
  updateCourseAttendeePrice,
} from "../../../../../actions/courseActions";
import Table from "../../../../../components/shared/Table";
import { columns as courseAttendeesColumns } from "../../columns/CourseAttendeesColumns";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
import MultiAttendeeSelect2 from "../../../../../components/select/MultiAttendeeSelect2";
import ToastComponent from "../../../../../components/partials/ToastComponent";
import {
  COURSE_ATTENDEE_PRICE_UPDATE_RESET,
  COURSE_ATTENDEE_PAID_STATUS_UPDATE_RESET,
} from "../../../../../constants/courseConstants";
import SidePanelHeader from "../../../../../components/sidepanel/SidePanelHeader";
import { shade } from "polished";

const TabCourseAttendees = ({ title }) => {
  const courseId = useParams().id;
  const toast = useToast();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(`${key}`);

  const dispatch = useDispatch();

  const [addAttendeeSidePanelOpen, setAddAttendeeSidePanelOpen] =
    useState(false);

  const [keyword, setKeyword] = useState("");

  const [currentAttendees] = useState([]);
  const [customAttendees] = useState([]);
  const [updatedAttendees, setUpdatedAttendees] = useState([]);

  const courseAttendeesList = useSelector((state) => state.courseAttendeesList);
  const { loading, error, attendees } = courseAttendeesList;

  const courseAttendeePaidStatusUpdate = useSelector(
    (state) => state.courseAttendeePaidStatusUpdate
  );
  const { success: successUpdateAttendeePaidStatus } =
    courseAttendeePaidStatusUpdate;

  const courseAttendeeAdd = useSelector((state) => state.courseAttendeeAdd);
  const { success: successAddAttendee } = courseAttendeeAdd;

  const courseAttendeeRemove = useSelector(
    (state) => state.courseAttendeeRemove
  );
  const { success: successRemoveAttendee } = courseAttendeeRemove;

  const courseAttendeeStatusUpdate = useSelector(
    (state) => state.courseAttendeeStatusUpdate
  );
  const { success: successUpdateAttendeeStatus } = courseAttendeeStatusUpdate;

  const courseAttendeePriceUpdate = useSelector(
    (state) => state.courseAttendeePriceUpdate
  );
  const { success: successUpdateAttendeePrice } = courseAttendeePriceUpdate;

  const courseUpdatePricing = useSelector((state) => state.courseUpdatePricing);
  const { success: successUpdatePricing } = courseUpdatePricing;

  // SORTING
  const [sortField, setSortField] = useState("customId");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(listCourseAttendees(courseId, keyword, sortField, sortOrder));
  };

  const addAttendeeHandleNew = (values) => {
    const attendeeIds = values.map((attendee) => attendee.value);
    const attendeeExists = attendeeIds.some((attendeeId) =>
      attendees.some((a) => a._id === attendeeId)
    );
    if (attendeeExists) {
      console.log("ATTENDEE EXISTS");
      toastComponent.showToast(t("toasts.attendeeError"), "error", 3000);
    } else {
      dispatch(addCourseAttendee(courseId, attendeeIds));
      setUpdatedAttendees([...updatedAttendees, ...attendeeIds]);
      // toastComponent.showToast(t('toasts.attendeeAdd'), 'success', 3000);
      closeAddAttendeeSidePanel();
    }
  };

  const removeAttendeeHandler = (attendeeId) => {
    dispatch(removeCourseAttendee(courseId, attendeeId));
    // toastComponent.showToast(t('toasts.attendeeRemove'), 'success', 3000);
  };

  useEffect(() => {
    if (successAddAttendee) {
      dispatch(listCourseAttendees(courseId, keyword, sortField, sortOrder));
      toastComponent.showToast(t("toasts.attendeeAdd"), "success", 3000);

      dispatch({ type: "COURSE_ATTENDEE_ADD_RESET" });
    } else if (successRemoveAttendee) {
      dispatch(listCourseAttendees(courseId, keyword, sortField, sortOrder));
      toastComponent.showToast(t("toasts.attendeeRemove"), "success", 3000);
    } else if (successUpdateAttendeeStatus) {
      dispatch(listCourseAttendees(courseId, keyword, sortField, sortOrder));
      toastComponent.showToast(t("toasts.attendeeStatus"), "success", 3000);
    } else if (successUpdateAttendeePaidStatus) {
      dispatch(listCourseAttendees(courseId, keyword, sortField, sortOrder));
      toastComponent.showToast(t("toasts.attendeeStatusPaid"), "success", 3000);
      dispatch({ type: COURSE_ATTENDEE_PAID_STATUS_UPDATE_RESET });
    } else if (successUpdateAttendeePrice) {
      dispatch(listCourseAttendees(courseId, keyword, sortField, sortOrder));
      toastComponent.showToast(t("toasts.attendeePrice"), "success", 3000);
      dispatch({ type: COURSE_ATTENDEE_PRICE_UPDATE_RESET });
    } else if (successUpdatePricing) {
      dispatch(listCourseAttendees(courseId, keyword, sortField, sortOrder));
    } else {
      dispatch(listCourseAttendees(courseId, keyword, sortField, sortOrder));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    courseId,
    keyword,
    sortField,
    sortOrder,
    successAddAttendee,
    successRemoveAttendee,
    successUpdateAttendeeStatus,
    successUpdateAttendeePaidStatus,
    successUpdateAttendeePrice,
    successUpdatePricing,
  ]);

  const openAddAttendeeSidePanel = () => {
    setAddAttendeeSidePanelOpen(true);
  };

  const closeAddAttendeeSidePanel = () => {
    setAddAttendeeSidePanelOpen(false);
  };

  const updateCourseAttendeeStatusHandler = (attendeeId, status) => {
    dispatch(updateCourseAttendeeStatus(courseId, attendeeId, status));
  };

  const updateCourseAttendeePaidStatusHandler = (attendeeId, status) => {
    dispatch(updateCourseAttendeePaidStatus(courseId, attendeeId, status));
  };

  const updateCourseAttendeePriceHandler = (attendeeId, price) => {
    dispatch(updateCourseAttendeePrice(courseId, attendeeId, price));
  };

  // console.log("currentAttendees", currentAttendees);
  // console.log("customAttendees", customAttendees);
  // console.log("updatedAttendees", updatedAttendees);
  // console.log("attendees", attendees);

  return (
    <>
      <SidePanel
        open={addAttendeeSidePanelOpen}
        close={closeAddAttendeeSidePanel}
        title={t("course.addAttendee")}
      >
        <Box position="relative">
          <SidePanelHeader
            close={closeAddAttendeeSidePanel}
            title={t("course.addAttendee")}
          />

          <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="7rem 0 4rem"
            >
              <Flex
                direction="column"
                w="100%"
                bg={white}
                borderRadius="1rem"
                p="0rem 1.5rem"
              >
                <FormControl mt={4}>
                  <>
                    <FormLabel fontSize="sm">
                      <Flex justify="space-between">
                        {t("select.attendee")}
                      </Flex>
                    </FormLabel>

                    <Flex w="100%" mb={10} flexDirection="column">
                      <Box w="100%">
                        <MultiAttendeeSelect2
                          currentOptions={attendees}
                          value={customAttendees}
                          onChange={addAttendeeHandleNew}
                          isSelectedHidden={true}
                        />
                      </Box>
                    </Flex>
                  </>
                </FormControl>
              </Flex>
              <Flex
                mt={4}
                direction="column"
                borderTop={`1px solid ${newBorder}`}
                p="2rem 0 3rem"
                w="calc(100% - 6rem)"
                position="absolute"
                bottom="0px"
                left="3rem"
              >
                <Flex justify="flex-end" gap={2}>
                  <Button
                    minWidth="8rem"
                    mr={0}
                    bg={backgroundLight}
                    border={`1px solid ${newBorder}`}
                    size="lg"
                    color={textSecondary}
                    fontSize="md"
                    fontWeight="500"
                    borderRadius="10rem"
                    _hover={{
                      bg: shade(0.1, backgroundLight),
                    }}
                    onClick={closeAddAttendeeSidePanel}
                  >
                    {t("common.back")}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </SidePanel>

      <Box>
        <Flex w="100%" direction="column">
          <Table.Top
            title={t("course.tabAttendees")}
            subtitle={title}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder={t("course.search")}
            searchName={keyword}
            isFixedTitle={true}
          >
            {/* <Button
              leftIcon={<FilterIcon />}
              bg={white}
              color={textSecondary}
              border={`1px solid ${isFilterVisible ? primary : newBorder}`}
              variant="solid"
              size="lg"
              borderRadius="10rem"
              fontSize="16px"
              onClick={() => setIsFilterVisible(!isFilterVisible)}
            >
              {t('common.filter')}
            </Button> */}
            <Button
              bg={primary}
              color={white}
              size="lg"
              borderRadius="10rem"
              fontSize="1rem"
              onClick={openAddAttendeeSidePanel}
            >
              {t("course.addAttendee")}
            </Button>
          </Table.Top>
          <Box borderRadius="0.75rem" overflow="hidden" p="0 0 2rem">
            <Table.Container
              loading={loading}
              data={attendees}
              error={error}
              columns={courseAttendeesColumns(
                translate,
                removeAttendeeHandler,
                updateCourseAttendeeStatusHandler,
                updateCourseAttendeePaidStatusHandler,
                handleSortChange,
                updateCourseAttendeePriceHandler
              )}
              noItemsTitle={t("noItems.noAttendees")}
              noItemsDescription={t("noItems.noAttendeesText")}
              noItemsButtonLabel={t("course.noAttendeesCourseButtonLabel")}
              noItemsButtonHandler={openAddAttendeeSidePanel}
              sortField={sortField}
              sortOrder={sortOrder}
              onRowClick={(row) => console.log(row)}
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default TabCourseAttendees;
