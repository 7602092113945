import React, { useEffect } from "react";
import { Flex, Text, Icon, Box, Heading, IconButton } from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  TbCalendarEvent,
  TbDeviceMobile,
  TbMail,
  TbMapPin,
  TbBuilding,
} from "react-icons/tb";
import {
  textPrimary,
  textSecondary,
  white,
  backgroundLight,
  newBorder,
} from "../../../../../utils/colors";
import { icons } from "../../../../../utils/icons";
import { useTranslation } from "react-i18next";
import TooltipText from "../../../../../components/partials/TooltipText";

const SpeakerInfo = ({ speaker }) => {
  const { t } = useTranslation();

  const [place, setPlace] = React.useState("");
  const [city, setCity] = React.useState("");

  useEffect(() => {
    if (speaker?.address) {
      setPlace(
        speaker?.address[0]?.street + `, ` + speaker?.address[0]?.zip || ""
      );
      setCity(speaker?.address[0]?.city || "");
    }
  }, [speaker]);

  const MiniSliderArrowBackIcon = () => (
    <img src={icons.miniSliderArrowBack} alt="<" />
  );
  const MiniSliderArrowForwardIcon = () => (
    <img src={icons.miniSliderArrowForward} alt=">" />
  );

  const infoArray = [
    {
      label: "Email",
      value: (
        <TooltipText
          icon="TbMail"
          label={t("form.email")}
          value={speaker.email || "-"}
        />
      ),
    },
    {
      label: "Birthdate",
      value: (
        <TooltipText
          icon="TbCalendarEvent"
          label={t("form.birthDate")}
          value={`${dayjs(speaker.birthDate).format(
            "D MMMM YYYY"
          )} (${dayjs().diff(speaker.birthDate, "years")}m.)`}
        />
      ),
    },

    {
      label: "Phone",
      value: (
        <TooltipText
          icon="TbDeviceMobile"
          label={t("form.phone")}
          value={speaker.phone || "-"}
        />
      ),
    },

    {
      label: "Place",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("form.phone")}
          value={place || "-"}
        />
      ),
    },

    {
      label: "City",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("form.city")}
          value={city || "-"}
        />
      ),
    },
  ];

  return (
    <Box w="45%" pr={8}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading fontSize="md" fontWeight="600" color={textPrimary}>
          {t("speaker.speakerDetails")}
        </Heading>
        <Box>
          <IconButton
            h="2.5rem"
            w="1.5rem"
            bg={white}
            border={`1px solid ${newBorder}`}
            icon={<MiniSliderArrowBackIcon />}
            borderRadius="10.5rem"
            opacity="0.8"
            _hover={{
              cursor: "not-allowed",
              background: backgroundLight,
            }}
            isDisabled
          />
          <IconButton
            h="2.5rem"
            w="1.5rem"
            bg={white}
            border={`1px solid ${newBorder}`}
            icon={<MiniSliderArrowForwardIcon />}
            borderRadius="10.5rem"
            ml="0.5rem"
            opacity="0.5"
            _hover={{
              cursor: "not-allowed",
              background: backgroundLight,
            }}
          />
        </Box>
      </Flex>
      <Box>
        {infoArray.map((item, index) => (
          <Flex key={index} alignItems="center">
            {item.value && (
              <Flex p="0.35rem 0" alignItems="center">
                {item.value}
              </Flex>
            )}
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default SpeakerInfo;
