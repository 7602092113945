import React from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import Select from "react-select";
import { newBorder, white, primary } from "../../utils/colors";
import { useTranslation } from "react-i18next";

const FormControlDuration = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
}) => {
  const { t } = useTranslation();
  const generateDurationOptions = () => {
    let options = [];

    // Add 'none' option with value null
    options.push({ value: null, label: "None" });

    for (let i = 5; i <= 60; i += 5) {
      options.push({ value: i * 60, label: `${i} min` });
    }

    options.push({ value: 90 * 60, label: "1.5 hours" });
    options.push({ value: 120 * 60, label: "2 hours" });
    options.push({ value: 180 * 60, label: "3 hours" });
    options.push({ value: 240 * 60, label: "4 hours" });

    return options;
  };

  const durationOptions = generateDurationOptions();

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize={isSmall ? "xs" : "sm"} position="relative">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>

      <Select
        isSearchable
        value={durationOptions.find((option) => option.value === value)}
        onChange={onChange}
        options={durationOptions}
        placeholder={t("placeholder.selectDuration")}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            borderRadius: "1rem",
            backgroundColor: white,
            border: `1px solid ${newBorder}`,
            fontSize: isSmall ? "0.875rem" : "0.938rem",
            fontWeight: "500",
            height: isSmall ? "2.5rem" : "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition };
          },
        }}
      />
    </FormControl>
  );
};

export default FormControlDuration;
