import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const TableCopy = ({ text }) => {
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [isCopied]);

  return (
    <Wrapper>
      <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
        <Button>
          <p className={isCopied ? 'copied' : ''}>{text}</p>
          <span className={isCopied ? 'copied' : ''}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-copy"
            >
              <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
            </svg>
          </span>

          {isCopied ? <p className="copytext">Copied!</p> : null}
        </Button>
      </CopyToClipboard>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    span {
      opacity: 1;
      transition: 0.2s all;
      svg {
      }
    }
    p {
      opacity: 0;
      transition: 0.2s all;
      &.copytext {
        opacity: 1;
        color: #61bf6b;
      }
    }
  }

  p {
    color: #727190;
    font-size: 13px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    margin: 0;
    opacity: 1;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    &.copied {
      display: none;
    }
    &.copytext {
      color: #61bf6b;
    }
  }
  span {
    position: absolute;
    opacity: 0;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    top: 8px;
    left: 0px;
    &.copied {
      display: none;
    }
    svg {
      height: 20px;
    }
  }
`;

const Button = styled.div`
  cursor: pointer;
  text-align: left;
  width: 100%;
  overflow: hidden;
  width: 60px;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
  }
`;

export default TableCopy;
