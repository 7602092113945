import React, { useEffect } from "react";
import { Flex, Text, Heading, Icon, Spinner } from "@chakra-ui/react";
import {
  primary,
  white,
  textSecondary,
  textPrimary,
  newBorder,
} from "../../../../utils/colors";

const BoxUser2 = ({ label, color, data, loading, of, icon, isPrice }) => {
  return (
    <Flex
      w="25%"
      justify="center"
      align="center"
      direction="column"
      borderRight={`1px solid ${newBorder}`}
      _last={{ borderRight: "none" }}
    >
      <Flex
        borderRadius="0.75rem"
        bg={color}
        w="2.25rem"
        h="2.25rem"
        jusityf="center"
        align="center"
        textAlign="center"
      >
        <Icon as={icon} color={white} w="initial" h="0.875rem" m="0 auto" />
      </Flex>
      <Text
        color={textSecondary}
        fontSize="13px"
        fontWeight="500"
        pt={2}
        pb={1}
      >
        {label}
      </Text>
      {!loading ? (
        <Text fontSize="sm" fontWeight="600" color={textPrimary}>
          {data}

          {isPrice && (
            <Text as="span" fontWeight="500" color={textPrimary} fontSize="sm">
              {" "}
              €
            </Text>
          )}
          {of && (
            <Text
              as="span"
              fontWeight="500"
              color={textSecondary}
              fontSize="sm"
            >
              {" "}
              / {of}
            </Text>
          )}
        </Text>
      ) : (
        <Spinner size="md" color={primary} />
      )}
    </Flex>
  );
};

export default BoxUser2;
