import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  ChakraProvider,
  Box,
  Text,
  Flex,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import theme from "./theme";
import {
  BrowserRouter as Router,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { backgroundLight, primary, white } from "./utils/colors";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/lt";
import "dayjs/locale/en";
import { Header } from "./components/layout/Header";
import Sidebar from "./components/shared/Sidebar";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs"; // Import dayjs here
import Helmet from "react-helmet";
import { GoogleApiProvider } from "./contexts/GoogleApiContext";
import MenuItems from "./menu/MenuItems";
import useAuthNavigate from "./hooks/useAuthNavigate";
import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import { hotjar } from "react-hotjar";

const AuthenticatedApp = () => {
  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;
  // const isAdmin = userInfo && userInfo.isAdmin;

  const location = useLocation();

  const { rolePermission } = userInfo;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  //  const isAdmin = true;

  // console.log(rolePermission);

  const isMobile = useBreakpointValue({ base: true, lg: false });

  const menuItems = MenuItems(rolePermission);

  const isNoSidebar = location.pathname.includes("public");

  useAuthNavigate();

  useEffect(
    () => {
      if (isMobile) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    },
    // eslint-disable-next-line
    [isMobile]
  );

  console.log("isNoSidebar", isNoSidebar);
  console.log("isSidebarOpen", isSidebarOpen);

  return (
    <>
      {isNoSidebar ? (
        <>
          <AnimatePresence mode="wait">
            <PrivateRoutes />
          </AnimatePresence>
        </>
      ) : (
        <Flex w="100%" bg={backgroundLight}>
          {isMobile && (
            <Button
              position="fixed"
              bg="blue"
              color="white"
              size="lg"
              bottom="4rem"
              right="2rem"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              zIndex={11}
            >
              <Text>Toggle Sidebar</Text>
            </Button>
          )}

          <Sidebar.Wrapper isOpen={isSidebarOpen}>
            <Sidebar.Container userInfo={userInfo}>
              <Sidebar.Header />
              <Sidebar.Routing menuItems={menuItems} />
            </Sidebar.Container>
          </Sidebar.Wrapper>

          <Flex
            direction="column"
            position="relative"
            left={["0px", "280px", "280px"]}
            w={["100%", "100%", "calc(100% - 280px)"]}
          >
            <Box p="0" minH="100vh" w="100%">
              <Header />
              <AnimatePresence exitBeforeEnter>
                <PrivateRoutes />
              </AnimatePresence>
            </Box>
          </Flex>
        </Flex>
      )}
    </>
  );
};

const App = () => {
  const { i18n } = useTranslation();

  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  // console.log("App component rendered. userInfo:", userInfo);

  useEffect(() => {
    // Retrieve the selected language from localStorage
    const storedLanguage = localStorage.getItem("language");
    const initialLanguage = storedLanguage || "lt"; // Default to 'en' if no language is stored

    // Initialize i18n with the stored or default language
    i18n.changeLanguage(initialLanguage);
    dayjs.locale(initialLanguage); // Initialize dayjs with the selected language

    // eslint-disable-next-line
  }, [userInfo]);

  useEffect(() => {
    // Initialize Hotjar
    hotjar.initialize("3754239", 6);
  }, []);

  return (
    <>
      <Helmet></Helmet>

      <ChakraProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="lt">
          <GoogleApiProvider googleApiKey="AIzaSyCNQfHnntkLgj5Es5V8V0H2BfIetOOZ9yw">
            <Router>
              {userInfo ? <AuthenticatedApp /> : <PublicRoutes />}
            </Router>
          </GoogleApiProvider>
        </LocalizationProvider>
      </ChakraProvider>
    </>
  );
};

export default App;
