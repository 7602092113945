import React from "react";
import { NavLink } from "react-router-dom";
import { icons } from "../../../utils/icons";
import { white, newBorder, secondary, primary } from "../../../utils/colors";
import { Flex, Box, HStack, Image } from "@chakra-ui/react";

const SidebarHeader = () => {
  return (
    <>
      <Flex
        justify="center"
        align="center"
        h="5rem"
        w="100%"
        bg={white}
        borderBottom={`1px solid ${newBorder}`}
      >
        <Box pl="2rem" w="100%">
          <NavLink to="/dashboard">
            <HStack gap={0} alignItems="flex-end">
              <Image src={icons.logo} h="1.36rem" w="auto" />
            </HStack>
          </NavLink>
        </Box>

        {/* <Box pl="2rem" w="100%">
          <NavLink to="/dashboard">
            <HStack gap={0} alignItems="flex-end">
              <Box bg={primary} p="0.5rem 1rem" borderRadius="0.875rem">
                <Image src={icons.logoDark} h="1.36rem" w="auto" />
              </Box>
            </HStack>
          </NavLink>
        </Box> */}
      </Flex>
    </>
  );
};

export default SidebarHeader;
