import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TabPanels,
  TabPanel,
  HStack,
  Flex,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import {
  listSpeakerDetails,
  updateSpeakerStatus,
  deleteSpeaker,
} from "../../../../actions/speakerActions";
import Panel from "../../../../components/shared/Panel";
import { primary } from "../../../../utils/colors";
import TabsComponentAlt from "../../../../components/tabs/TabsComponentAlt";
import StatusButton from "../../../../components/buttons/StatusButton";
import SinglePageTopBar from "../../../../components/layout/SinglePageTopBar";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import EditSpeaker from "../sidepanels/EditSpeaker";
import {
  SPEAKER_UPDATE_RESET,
  SPEAKER_STATUS_UPDATE_RESET,
} from "../../../../constants/speakerConstants";
import { useTranslation } from "react-i18next";
import TabSpeakerCourses from "./tabs/TabSpeakerCourses";
import SpeakerInfo from "./partials/SpeakerInfo";
import SpeakerCharts from "./partials/SpeakerCharts";
import SinglePageImageWrapper from "../../../../components/partials/SinglePageImageWrapper";
import MoreButton from "../../../../components/buttons/MoreButton";
import SinglePagePieChart from "../../../../components/charts/SinglePagePieChart";
import TabSpeakerDescription from "./tabs/TabSpeakerDescription";
import ToastComponent from "../../../../components/partials/ToastComponent";

const SpeakerSingle = () => {
  const speakerId = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();

  const tabsData = [
    { label: t("speaker.courses") },
    { label: t("speaker.tabDescription") },
  ];

  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [status, setStatus] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [courses, setCourses] = useState([]);
  const [place, setPlace] = useState("");
  const [city, setCity] = useState("");
  const [image, setImage] = useState("");

  const speakerDetails = useSelector((state) => state.speakerDetails);
  const { loading, speaker } = speakerDetails;

  const speakerUpdate = useSelector((state) => state.speakerUpdate);
  const { success: successUpdate } = speakerUpdate;

  const speakerDelete = useSelector((state) => state.speakerDelete);
  const { success: successDelete } = speakerDelete;

  const speakerStatusUpdate = useSelector((state) => state.speakerStatusUpdate);
  const { success: successStatusUpdate } = speakerStatusUpdate;

  const speakerUpdateImage = useSelector((state) => state.speakerUpdateImage);
  const { success: successUpdateImage } = speakerUpdateImage;

  const deleteSpeakerHandler = (id) => {
    dispatch(deleteSpeaker(id));
    toastComponent.showToast(t("speaker.attendeeDeleted"), "success", 3000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: SPEAKER_UPDATE_RESET });
      navigate("/academy/speakers/");
    } else if (successDelete) {
      dispatch({ type: SPEAKER_UPDATE_RESET });
      navigate("/academy/speakers/");
    } else if (successStatusUpdate) {
      dispatch({ type: SPEAKER_STATUS_UPDATE_RESET });
      dispatch(listSpeakerDetails(speakerId));
    } else if (successUpdateImage) {
      toastComponent.showToast(t("toasts.imageUpdated"), "success", 3000);

      dispatch(listSpeakerDetails(speakerId));
    } else {
      if (!speaker.firstName || speaker._id !== speakerId) {
        dispatch(listSpeakerDetails(speakerId));
      } else {
        setImage(speaker.image);
        setStatus(speaker.status);
        setFirstName(speaker.firstName);
        setLastName(speaker.lastName);
        setCourses(speaker.courses);

        if (speaker?.address[0]?.city) {
          setCity(speaker?.address[0]?.city);
        }

        if (speaker?.address[0]?.street) {
          setPlace(
            speaker?.address[0]?.street + `, ` + speaker?.address[0]?.zip || ""
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    navigate,
    speakerId,
    speaker,
    successUpdate,
    successStatusUpdate,
    successDelete,
    successUpdateImage,
  ]);

  const openEditSidePanel = () => {
    setEditSidePanelOpen(true);
  };

  const closeEditSidePanel = () => {
    setEditSidePanelOpen(false);
  };

  const handleUpdateStatus = (status) => {
    dispatch(updateSpeakerStatus(speakerId, status));
  };

  console.log("speaker", speaker);
  console.log("courses", courses);

  return (
    <>
      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanel}
        title={t("speaker.editSpeaker")}
        noBorder={true}
      >
        <EditSpeaker speakerId={speakerId} />
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <SinglePageTopBar
              backFunction={() => navigate(-1)}
              title={speaker.firstName + " " + speaker.lastName}
              type={t("course.speaker")}
              loading={loading}
            >
              {status && (
                <StatusButton
                  currentStatus={status}
                  onUpdateStatus={handleUpdateStatus}
                  options="active"
                  isBig
                />
              )}
              <MoreButton
                id={speakerId}
                isBig
                deleteHandler={deleteSpeakerHandler}
                editHandler={(e) => {
                  e.stopPropagation();
                  openEditSidePanel(speakerId);
                }}
              />
            </SinglePageTopBar>
            {!loading ? (
              <>
                <HStack
                  gap={0}
                  p="2.5rem 0rem 0rem 2rem"
                  alignItems="flex-start"
                  justifyContent="center"
                  w="100%"
                >
                  <SinglePageImageWrapper
                    id={speakerId}
                    image={speaker.image}
                    options="speaker"
                  />

                  <HStack
                    gap={0}
                    p="0rem 0rem 0rem 2rem"
                    w="calc(100% - 260px)"
                    alignItems="flex-start"
                  >
                    <>
                      <SpeakerInfo speaker={speaker} />
                      <SinglePagePieChart
                        label={t("speaker.speakerCourseStats")}
                        data={courses}
                        options="courses"
                        plotLabel={t("stats.totalCourses")}
                      />
                    </>
                  </HStack>
                </HStack>
              </>
            ) : (
              <Flex justify="center" align="center" w="100%" p={20}>
                <Spinner size="xl" color={primary} />
              </Flex>
            )}
            <Box p="0 2.5rem">
              <TabsComponentAlt
                activeTab={activeTab}
                tabs={tabsData}
                setActiveTab={setActiveTab}
                isTitleValid={true}
                disabledTabs={[]}
                isSinglePage
              >
                <TabPanels>
                  <TabPanel pb={4} px={0}>
                    <TabSpeakerCourses
                      title={
                        speaker.firstName &&
                        speaker.lastName &&
                        speaker.firstName[0] + ". " + speaker.lastName
                      }
                    />
                  </TabPanel>
                  <TabPanel pb={4} px={0}>
                    <TabSpeakerDescription
                      title={
                        speaker.firstName &&
                        speaker.lastName &&
                        speaker.firstName[0] + ". " + speaker.lastName
                      }
                    />
                  </TabPanel>
                </TabPanels>
              </TabsComponentAlt>
            </Box>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default SpeakerSingle;
