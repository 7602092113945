import React from "react";
import { Flex, Text, Button } from "@chakra-ui/react";
import {
  buttonYellowText,
  buttonYellow,
  buttonAltYellow,
  buttonYelloDark,
} from "../../utils/colors";

const IsFilterActive = ({ label, onClearFilters }) => {
  return (
    <Flex w="100%" justify="center" align="center" py={2} bg={buttonAltYellow}>
      <Text fontSize="xs" fontWeight="500" color={buttonYellowText}>
        {label}
      </Text>
      {onClearFilters && (
        <Button onClick={onClearFilters} size="sm" ml={2}>
          Clear Filters
        </Button>
      )}
    </Flex>
  );
};

export default IsFilterActive;
