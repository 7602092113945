import React, { useState, useEffect } from "react";
import { Flex, Heading, Text, Stack, Tooltip } from "@chakra-ui/react";
import {
  newBorder,
  textPrimary,
  textSecondary,
  backgroundLight,
} from "../../../../../utils/colors";
import Table from "../../../../../components/shared/Table";
import { columns } from "../../columns/ServiceListPatientPublicTreatmentPlanColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../../hooks/useIsMobile";

const ServiceListTreatmentPlanPublicMobile = ({
  loading,
  error,
  services,
  totalPrice,
}) => {
  // TRANSLATION
  const { t } = useTranslation();
  const translate = (key) => t(key);

  const [totalDiscount, setTotalDiscount] = useState(0);

  // MOBILE
  const isMobile = useIsMobile();

  // LOGS
  console.log("ServiceListTreatmentPlanPublic.js: services: ", services);

  useEffect(() => {
    const totalDiscountedPrice = services.reduce((totalDiscount, service) => {
      const originalPrice = service.service.price;
      const discountedPrice = service.price;
      const difference = originalPrice - discountedPrice;
      return totalDiscount + Math.max(difference, 0);
    }, 0);

    setTotalDiscount(totalDiscountedPrice);
  }, [services]);

  return (
    <Flex
      direction="column"
      w="100%"
      border={`1px solid ${newBorder}`}
      borderRadius="1rem"
      p="0 0 0rem"
    >
      <Heading
        fontSize={["0.875rem", "1rem", "1rem"]}
        fontWeight="600"
        color={textPrimary}
        p={["0.75rem 1.5rem", "1rem 1.5rem", "1rem 1.5rem"]}
        textAlign="center"
        bg={backgroundLight}
        borderBottom={`1px solid ${newBorder}`}
        borderTopLeftRadius="1rem"
        borderTopRightRadius="1rem"
      >
        {t("treatmentPlan.serviceList")}
      </Heading>

      <Flex direction="column">
        {services &&
          services.length > 0 &&
          services.map((service, index) => (
            <Flex
              key={index}
              align="center"
              justify="space-between"
              borderBottom={`1px solid ${newBorder}`}
              p="1rem 1.5rem"
            >
              <Stack gap={1}>
                <Text color={textPrimary} fontSize="sm" fontWeight="600" pr={8}>
                  {service.service.name}
                </Text>
                <Flex align="center">
                  {service.teeth && service.teeth.length > 0 ? (
                    service.teeth.length <= 11 ? (
                      <Flex align="center">
                        <Text
                          color={textSecondary}
                          fontSize="xs"
                          fontWeight="500"
                          mr={1}
                        >
                          {t("common.teeth")}:
                        </Text>
                        <Text
                          fontSize="xs"
                          color={textSecondary}
                          fontWeight="500"
                        >
                          {service.teeth.join(", ")}
                        </Text>
                      </Flex>
                    ) : (
                      <Tooltip label={service.teeth.join(", ")} fontSize="xs">
                        <Text
                          fontSize="xs"
                          color={textSecondary}
                          fontWeight="500"
                          cursor="help"
                        >
                          11+
                        </Text>
                      </Tooltip>
                    )
                  ) : null}
                </Flex>
              </Stack>
              <Text
                color={textPrimary}
                fontSize="15px"
                fontWeight="500"
                whiteSpace="nowrap"
              >
                {service.price} €
              </Text>
            </Flex>
          ))}
      </Flex>

      <Flex p="2rem 1.5rem" w="100%" justify="flex-start">
        <Flex direction="column" justify="flex-start" w="100%">
          <Flex align="center" justify="space-between" my={1}>
            <Text color={textSecondary} fontSize="15px" fontWeight="500" mr={6}>
              {t("service.serviceCount")}:
            </Text>
            <Text color={textPrimary} fontSize="15px" fontWeight="600">
              {services.length}
            </Text>
          </Flex>

          {/* Conditional rendering to hide the discount if it's 0 */}
          {totalDiscount > 0 && (
            <Flex align="center" justify="space-between" my={1}>
              <Text
                color={textSecondary}
                fontSize="15px"
                fontWeight="500"
                mr={6}
              >
                {t("service.discount")}:
              </Text>

              <Text color={textPrimary} fontSize="15px" fontWeight="600">
                -{totalDiscount} €
              </Text>
            </Flex>
          )}

          <Flex align="center" justify="space-between" my={1}>
            <Text color={textSecondary} fontSize="15px" fontWeight="500" mr={6}>
              {t("treatmentPlan.priceTotal")}:
            </Text>
            <Text color={textPrimary} fontSize="15px" fontWeight="600">
              {totalPrice} €
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ServiceListTreatmentPlanPublicMobile;
