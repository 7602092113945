import React, { useState, useEffect } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Button,
  Input,
  Flex,
  Text,
  Stack,
  Select,
  Heading,
  useDisclosure,
  IconButton,
  PopoverFooter,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  Icon,
} from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  newBorder,
  primary,
  secondary,
  white,
  buttonBlue,
  backgroundLight,
} from "../../../../../utils/colors";
import { FaXmark, FaCaretDown } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import useRoleCheck from "../../../../../hooks/useRoleCheck";

const PricePopover = ({ service, saveEditedService, isDisabled }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // GENERAL
  const { t } = useTranslation();

  // CONSTANTS
  const [isCaretUp, setIsCaretUp] = useState(false);

  //ROLES
  const isDoctor = useRoleCheck("employee_doctor");
  const isAdmin = useRoleCheck("admin");

  // Store the default price on component mount
  const [defaultPrice, setDefaultPrice] = useState(service.price);

  // 1. Initialize local state for input value and a flag for confirming the changes
  const [tempPrice, setTempPrice] = useState(service.price); // temporary price in the input
  const [isChanged, setIsChanged] = useState(false); // flag to check if price has been changed
  const [discount, setDiscount] = useState(0); // Discount in percentage
  const [selectedDiscount, setSelectedDiscount] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(0); // Discounted price

  const handlePriceChange = (e) => {
    let newPrice = e.target.value;
    setTempPrice(newPrice);
    setIsChanged(true);

    const totalPrice = newPrice * (1 - discount / 100);

    setDiscountedPrice(totalPrice.toFixed(2));
  };

  const handleConfirmClick = () => {
    let finalPrice = discount > 0 ? discountedPrice : tempPrice;
    saveEditedService({
      ...service,
      price: finalPrice,
      totalPrice: finalPrice * service.quantity,
      quantity: service.quantity,
    });
    setIsChanged(false);
    onClose(); // Close the popover after confirming the changes
  };

  const handleOpen = () => {
    onOpen();
    setIsCaretUp(true); // Rotate the caret up
  };

  const handleClose = () => {
    onClose();
    setIsCaretUp(false); // Rotate the caret down
  };

  const handleDiscountChange = (e) => {
    let newDiscount = e.target.value;
    setDiscount(newDiscount);
    const discountedPrice = tempPrice * (1 - newDiscount / 100);
    setDiscountedPrice(discountedPrice.toFixed(2));
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      //   w="initial"
      //   maxWidth="600px"
    >
      {/* Pass isOpen and onClose here */}
      <PopoverTrigger>
        <Button
          border={`0px solid ${newBorder}`}
          borderRadius="10rem"
          h="2rem"
          fontWeight="500"
          color={textPrimary}
          onClick={handleOpen}
          isDisabled={isDisabled || isDoctor}
          zIndex="0"
          bg={isOpen ? backgroundLight : white}
          alignItems="center"
          justifyContent="space-between"
          px={4}
          transition="0.2s all"
          size="sm"
          role="group"
          // minWidth="136px"
          textAlign="left"
          _hover={{
            bg: backgroundLight,
            px: 4,
            transition: "0.2s all",
          }}
        >
          {service.service.priceVariations === true ? (
            <Text fontSize="xs" color={textSecondary} fontWeight="00">
              {t("button.priceService")}:
              <Text as="span" color={textSecondary} ml={1}>
                {service.price} €
              </Text>
            </Text>
          ) : (
            <Text fontSize="xs" color={textSecondary} fontWeight="500">
              {t("button.priceService")}:
              <Text as="span" color={textSecondary} ml={1} fontSize="0.875rem">
                {service.price} €
              </Text>
            </Text>
          )}
          <Icon
            as={FaCaretDown}
            h="0.75rem"
            w={isOpen ? "0.75rem" : "0rem"}
            ml={1}
            opacity="0.5"
            transition="all 0.2s ease"
            color={textSecondary}
            transform={isOpen ? "rotate(180deg)" : "rotate(0)"} // Rotate caret when menu is open
            _groupHover={{
              opacity: 1,
              width: "0.75rem",
              transition: "all 0.2s ease",
            }}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        border="none"
        boxShadow="0px 4px 12px 3px rgba(0, 0, 0, 0.20)"
        borderRadius="1rem"
        bg={white}
      >
        <PopoverArrow />
        {/* <PopoverCloseButton /> */}
        <PopoverHeader borderBottom={`1px solid ${newBorder}`} p="1rem 1.5rem">
          <Flex w="100%" justify="space-between" align="center">
            <Heading fontSize="sm" fontWeight="500" color={textPrimary}>
              {t("service.setPrice")}
            </Heading>
            <IconButton
              h="1.5rem"
              w="1rem"
              icon={<FaXmark />}
              bg="none"
              onClick={handleClose}
              color={textSecondary}
            />
          </Flex>
        </PopoverHeader>
        <PopoverBody p="1rem 1.5rem 0rem">
          {/* <Flex align="center" mb={2}>
            <Text
              color={textSecondary}
              fontWeight="400"
              width="200px"
              fontSize="md"
            >
              {t("service.defaultServicePrice")}
            </Text>

            <Input
              isDisabled
              size="md"
              borderRadius="10rem"
              maxWidth="100px"
              textAlign="right"
              color={textPrimary}
              type="number"
              value={defaultPrice} // Display the temporary price if it's changed, otherwise show the service price
              onChange={handlePriceChange}
              placeholder=""
            />
          </Flex> */}

          <Flex align="center" mb={2}>
            <Text
              color={textSecondary}
              fontWeight="400"
              width="200px"
              fontSize="sm"
            >
              {t("service.discountPrice")} (€):
            </Text>
            <Input
              size="md"
              fontSize="sm"
              borderRadius="10rem"
              maxWidth="100px"
              textAlign="right"
              color={textPrimary}
              type="number"
              value={tempPrice} // Display the temporary price if it's changed, otherwise show the service price
              onChange={handlePriceChange}
              placeholder=""
            />
          </Flex>

          <Flex align="center" mb={4}>
            <Text
              color={textSecondary}
              fontWeight="400"
              width="200px"
              fontSize="sm"
            >
              {t("service.discount")} (%):
            </Text>
            <Input
              size="md"
              fontSize="sm"
              borderRadius="10rem"
              maxWidth="100px"
              textAlign="right"
              color={textPrimary}
              type="number"
              value={discount}
              onChange={handleDiscountChange}
            />
          </Flex>

          {/* <Flex align="center" mb={2}>
            <Text color={textSecondary} fontWeight="400" width="200px">
              {t("service.discount")}
            </Text>
            <InputGroup
              borderRadius="10rem"
              border={`1px solid ${newBorder}`}
              width="260px"
            >
              <Input
                borderTopLeftRadius="1rem"
                borderBottomLeftRadius="1rem"
                borderTopRightRadius="0rem"
                borderBottomRightRadius="0rem"
                bg={white}
                size="md"
                maxWidth="30px"
                textAlign="right"
                color={textPrimary}
                type="number"
                value={discount}
                onChange={handleDiscountChange}
                placeholder="0"
                border="none"
                p="0"
                isDisabled={selectedDiscount !== "other"} // Disable unless "Other" is selected
              />
              <InputRightAddon
                bg={white}
                children="%"
                p="0 0 0  0.25rem"
                w="20px"
                borderRadius="0"
                border="none"
                color={textSecondary}
              />
              <Select
                p="0"
                size="md"
                maxWidth="160px"
                color={textPrimary}
                value={selectedDiscount}
                border="none"
                onChange={handleDiscountSelectChange}
              >
                <option value="0">{t("service.discountNone")}</option>
                <option value="15">{t("service.discountFamily")}</option>
                <option value="10">{t("service.discountEmployees")}</option>
                <option value="other">{t("service.discountOther")}</option>
              </Select>
            </InputGroup>
          </Flex> */}

          <Flex
            direction="column"
            w="100%"
            borderTop={`1px solid ${newBorder}`}
            p="0.75rem 0"
          >
            <Flex
              align="center"
              mb={0}
              p="0rem 0"
              w="100%"
              justify="space-between"
            >
              <Text
                color={textSecondary}
                fontWeight="400"
                width="200px"
                fontSize="sm"
              >
                {t("service.defaultServicePrice")}:
              </Text>
              <Text color={textPrimary} fontWeight="500" fontSize="sm">
                {defaultPrice} €
              </Text>
            </Flex>
          </Flex>

          <Flex
            direction="column"
            w="100%"
            borderTop={`1px solid ${newBorder}`}
          >
            {/* <Flex align="center" mb={2}>
              <Text
                color={textSecondary}
                fontWeight="400"
                width="200px"
                fontSize="sm"
              >
                {t("service.defaultServicePrice")}:
              </Text>
              <Text color={textSecondary}>{defaultPrice} €</Text>
            </Flex>
            <Flex align="center" mb={2}>
              <Text
                color={textSecondary}
                fontWeight="400"
                width="200px"
                fontSize="sm"
              >
                Kaina:
              </Text>
              <Text color={textPrimary}>{tempPrice} €</Text>
            </Flex>
            <Flex align="center" mb={2}>
              <Text
                color={textSecondary}
                fontWeight="400"
                width="200px"
                fontSize="sm"
              >
                Nuolaida:
              </Text>
              <Text color={textPrimary}>-10%</Text>
            </Flex> */}

            <Flex
              align="flex-start"
              justify="space-between"
              mb={0}
              p="0.75rem 0"
              direction="column"
              w="100%"
            >
              {/* <Flex
                align="center"
                mb={0}
                p="0rem 0"
                w="100%"
                justify="space-between"
              >
                <Text
                  color={textSecondary}
                  fontWeight="400"
                  width="200px"
                  fontSize="md"
                >
                  {t("service.defaultServicePrice")}:
                </Text>
                <Text color={textSecondary}>{defaultPrice} €</Text>
              </Flex> */}
              <Flex
                align="center"
                mb={0}
                p="0rem 0"
                w="100%"
                justify="space-between"
              >
                <Text
                  color={textSecondary}
                  fontWeight="400"
                  width="200px"
                  fontSize="sm"
                >
                  {t("service.totalPriceDiscount")}:
                </Text>
                <Text color={textPrimary} fontWeight="500" fontSize="sm">
                  {discount > 0 ? `${discountedPrice} €` : `${tempPrice} €`}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </PopoverBody>
        <PopoverFooter p="1rem 1.5rem">
          <Flex w="100%" align="center" justify="flex-end">
            <Button
              variant="outline"
              size="sm"
              color={textSecondary}
              borderRadius="10rem"
              onClick={handleClose}
              p="1rem 1.5rem"
            >
              {t("button.cancel")}
            </Button>
            <Button
              size="sm"
              bg={primary}
              color={white}
              borderRadius="10rem"
              onClick={handleConfirmClick} // 4. Call the handleConfirmClick function when the "Confirm" button is clicked
              p="1rem 1.5rem"
              ml={2}
            >
              {t("button.apply")}
            </Button>
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default PricePopover;
