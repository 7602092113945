import React from "react";
import {
  Text,
  Image,
  HStack,
  Stack,
  Box,
  Icon,
  Avatar,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import {
  white,
  textSecondary,
  textPrimary,
  newBorder,
  backgroundLight,
  buttonBlue,
  buttonBlueText,
  buttonGreen,
  buttonGreenText,
  buttonRed,
  buttonRedText,
  greenVivid,
  buttonRedNew,
  newDarkBlue,
} from "../../../../utils/colors";
import ConfirmDeleteModal from "../../../../components/modals/ConfirmDeleteModal";
import { FaRegCircleCheck, FaRegCircle, FaRegCircleDot } from "react-icons/fa6";
import StatusButton from "../../../../components/buttons/StatusButton"; // Update the path accordingly
import MoreButton from "../../../../components/buttons/MoreButton";
import TooltipStatusEntry from "../../../../components/partials/TooltipStatusEntry"; // Update the path accordingly
import ColumnStatusCountEntry from "../../../../components/partials/ColumnStatusCountEntry";

export const columns = (
  dispatch,
  openEditSidePanelModal,
  translate,
  deleteAttendeeHandler,
  updateAttendeeStatus,
  page,
  pageSize,
  handleSortChange
) => {
  return [
    {
      Header: () => (
        <Box onClick={() => handleSortChange("customId")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.customId")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "customId",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { customId } = row.original;

        return (
          <Text
            maxWidth="50px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="11px"
            color={textSecondary}
            fontWeight="500"
          >
            {customId}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("lastName")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("attendee.columnAttendee")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "lastName",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { firstName, lastName, email, image } = row.original;

        const name = `${lastName} ${firstName}`;

        return (
          <HStack gap={2}>
            {image ? (
              <Box
                h="48px"
                w="48px"
                borderRadius="10rem"
                overflow="hidden"
                bg={backgroundLight}
              >
                <Image
                  h="48px"
                  w="48px"
                  src={image}
                  alt={name}
                  objectFit="cover"
                />
              </Box>
            ) : (
              <Avatar
                h="48px"
                w="48px"
                name={name}
                // bg="#dceeff" color="#3a74a9"
              />
            )}
            <Stack gap={0}>
              <Text
                fontSize="md"
                color={textPrimary}
                fontWeight="600"
                isTruncated
                maxWidth="200px"
              >
                {lastName} {firstName}
              </Text>
              <Text fontSize="sm" color={textSecondary} fontWeight="500">
                {email && email.toLowerCase()}
              </Text>
            </Stack>
          </HStack>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("client")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("attendee.columnClinic")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "client",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { client } = row.original;

        return (
          <div>
            {client ? (
              <Flex
                alignItems="center"
                justifyContent="flex-start"
                position="relative"
                pl="1rem"
              >
                <Box marginLeft="-0.75rem" key={client._id}>
                  <Tooltip label={client.clientName} key={client._id}>
                    <Avatar
                      size="sm"
                      src={client.image || ""}
                      name={client.clientName && client.clientName}
                      border={`1px solid ${newBorder}`}
                    />
                  </Tooltip>
                </Box>
              </Flex>
            ) : (
              // You can render a "No Data" component or Lottie animation here
              <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                -
              </Text>
            )}
          </div>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("city")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("speaker.columnCity")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "city",
      disableSortBy: true,
      Cell: ({ row }) => {
        const city =
          row.original.address && row.original.address.length > 0
            ? row.original.address[0].city
            : null;

        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {city ? city : "N/A"}
          </Text>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("phone")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("attendee.columnPhone")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "phone",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { phone } = row.original;

        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {phone}
          </Text>
        );
      },
    },
    {
      Header: translate("attendee.columnStats"),
      disableSortBy: false,
      accessor: (row) => {
        const { courses } = row;

        // Initialize counters for each status
        let registeredCount = 0;
        let confirmedCount = 0;
        let attendedCount = 0;
        let cancelledCount = 0;
        let notAttendedCount = 0;

        // Loop through the courses and count courses for each status
        courses.forEach((course) => {
          if (course.status === "registered") {
            registeredCount++;
          } else if (course.status === "attended") {
            attendedCount++;
          } else if (course.status === "not_attended") {
            notAttendedCount++;
          } else if (course.status === "cancelled") {
            cancelledCount++;
          } else if (course.status === "confirmed") {
            confirmedCount++;
          }
        });

        return (
          <Stack spacing={1}>
            <Tooltip
              p={4}
              borderRadius="1rem"
              bg={newDarkBlue}
              label={
                <Flex
                  direction="column"
                  justify="flex-start"
                  align="flex-start"
                >
                  <TooltipStatusEntry
                    bg={buttonBlue}
                    color={textPrimary}
                    label={translate("status.registered")}
                    count={registeredCount}
                    icon="FaRegCircleDot"
                  />

                  <TooltipStatusEntry
                    bg={buttonGreen}
                    color={buttonGreenText}
                    label={translate("status.confirmed")}
                    count={confirmedCount}
                    icon="FaCircleCheck"
                  />

                  <TooltipStatusEntry
                    bg={greenVivid}
                    color={textPrimary}
                    label={translate("status.attended")}
                    count={attendedCount}
                    icon="FaRegCircleCheck"
                  />

                  <TooltipStatusEntry
                    bg={buttonRed}
                    color={buttonRedText}
                    label={translate("status.cancelled")}
                    count={cancelledCount}
                    icon="FaCircleXmark"
                  />
                  <TooltipStatusEntry
                    bg={buttonRedNew}
                    color={white}
                    label={translate("status.notAttended")}
                    count={notAttendedCount}
                    icon="FaCircleExclamation"
                  />
                </Flex>
              }
              fontSize="md"
            >
              <HStack>
                <ColumnStatusCountEntry
                  bg={buttonBlue}
                  color={textPrimary}
                  count={registeredCount}
                  icon="FaRegCircleDot"
                />
                <Text
                  fontSize="0.875rem"
                  fontWeight="500"
                  lineHeight="1"
                  color={newBorder}
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  |
                </Text>
                <ColumnStatusCountEntry
                  bg={buttonGreen}
                  color={buttonGreenText}
                  count={confirmedCount}
                  icon="FaCircleCheck"
                />
                <Text
                  fontSize="0.875rem"
                  fontWeight="500"
                  lineHeight="1"
                  color={newBorder}
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  |
                </Text>
                <ColumnStatusCountEntry
                  bg={greenVivid}
                  color={textPrimary}
                  count={attendedCount}
                  icon="FaRegCircleCheck"
                />
              </HStack>
            </Tooltip>
          </Stack>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("common.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { status, _id } = row.original;

        const handleUpdateStatus = (newStatus) => {
          dispatch(updateAttendeeStatus(_id, newStatus));
        };

        return (
          <HStack gap={0} justify="flex-end">
            {status && (
              <StatusButton
                currentStatus={status}
                onUpdateStatus={handleUpdateStatus}
                options="active"
              />
            )}
            <MoreButton
              id={_id}
              deleteHandler={deleteAttendeeHandler}
              editHandler={(e) => {
                e.stopPropagation();
                openEditSidePanelModal(_id);
              }}
            />
          </HStack>
        );
      },
    },
  ];
};
