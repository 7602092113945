import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Button,
  HStack,
  Text,
  Stack,
  Heading,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, updateUser } from "../../../../actions/userActions";
import {
  newBorder,
  backgroundLight,
  textSecondary,
  textPrimary,
  white,
  primary,
  buttonRed,
  buttonRedText,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import dayjs from "dayjs";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlDatePicker from "../../../../components/input/FormControlDatePicker";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlAddress from "../../../../components/input/FormControlAddress";
import FormControlCountry from "../../../../components/input/FormControlCountry";

const EditPatient = ({ userId, close }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // CONSTANTS
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userID, setUserID] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneAlt, setPhoneAlt] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [gender, setGender] = useState("");
  const [personalCode, setPersonalCode] = useState("");
  const [status, setStatus] = useState("");
  const [address, setAddress] = useState("");

  const [ageType, setAgeType] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [birthDateError, setBirthDateError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [streetError, setStreetError] = useState("");
  const [zipError, setZipError] = useState("");
  const [personalCodeError, setPersonalCodeError] = useState("");
  const [cityError, setCityError] = useState("");

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { success } = userUpdate;

  // VALIDATION
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [birthDateTouched, setBirthDateTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [streetTouched, setStreetTouched] = useState(false);
  const [zipTouched, setZipTouched] = useState(false);
  const [personalCodeTouched, setPersonalCodeTouched] = useState(false);
  const [cityTouched, setCityTouched] = useState(false);
  const [countryTouched, setCountryTouched] = useState(false);

  const isFirstNameError = firstName === "";
  const isLastNameError = lastName === "";
  const isBirthDateError = birthDate === "";
  const isPersonalCodeError = personalCode === "";
  const isEmailError = email === "";
  const isPhoneError = phone.length < 4;
  const isPhoneShortError = phone.length < 6;
  const isStreetError = street === "";
  const isZipError = zip === "";
  const isCityError = city === "";
  const isCountryError = country === "";

  // FUNCTIONS

  const calculateAge = (birthDate) => {
    const birthday = dayjs(birthDate);
    const age = dayjs().diff(birthday, "year");
    return age;
  };

  // HANDLERS

  const handleScriptLoad = (loaded) => {
    setIsGoogleScriptLoaded(loaded);
  };

  // Handler for when an address is selected
  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateUser({
        _id: userId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        birthDate: birthDate,
        phone,
        phoneAlt,
        personalCode,
        status,
        street,
        zip,
        city,
        country,
        state,
        // profession,
        // licenseNumber,
        // iban,
        // bankName,
      })
    );

    close();
  };

  const closeHandler = () => {
    close();
    dispatch({ type: "USER_UPDATE_RESET" });
  };

  // USEEFFECT
  useEffect(() => {
    if (birthDate) {
      const age = calculateAge(birthDate);
      setAgeType(age >= 18 ? "adult" : "kid");
    }
  }, [birthDate]);

  useEffect(() => {
    if (user._id !== userId) {
      dispatch(getUserDetails(userId));
    } else {
      setUserID(user.userID);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setBirthDate(user.birthDate);
      setPersonalCode(user.personalCode);
      setEmail(user.email);
      setPhone(user.phone);
      setPhoneAlt(user.phoneAlt);
      setStatus(user.status);
      setStreet(user.address[0].street);
      setZip(user.address[0].zip);
      setCity(user.address[0].city);
      setCountry(user.address[0].country);
      setState(user.address[0].state);

      setAddress(user.address);
    }
  }, [dispatch, userId, user]);

  // console.log("firstName", firstName);
  // console.log("lastName", lastName);
  // console.log("image", image);
  // console.log("email", email);
  console.log("birthDate", birthDate);
  // console.log("role", role);
  // console.log("country", country);
  // console.log("street", street);
  // console.log("zip", zip);
  // console.log("city", city);
  // console.log("state", state);

  return (
    <>
      <Box position="relative">
        <form
          onSubmit={submitHandler}
          style={{
            height: "100%",
          }}
        >
          <Flex
            justify="space-between"
            align="center"
            p="1.5rem 2rem 1.5rem"
            borderBottom={`1px solid ${newBorder}`}
            mb="0rem"
            position="absolute"
            zIndex={2}
            bg={white}
            w="100%"
          >
            <Flex align="center">
              <IconButton
                bg="none"
                border={`1px solid ${newBorder}`}
                color={textSecondary}
                onClick={close}
                icon={<FaArrowRightFromBracket />}
                borderRadius="0.75rem"
                fontSize="0.75rem"
              />
              <Stack gap={0} ml={4}>
                <Flex align="center">
                  <Heading
                    fontSize="1.275rem"
                    color={textPrimary}
                    fontWeight="600"
                    mr={4}
                  >
                    {t("patient.editPatient")}
                  </Heading>

                  {/* <StatusBadge status={status} /> */}
                </Flex>

                <Text fontSize="sm" color={textSecondary} fontWeight="500">
                  {ageType === "adult" ? t("common.adult") : t("common.child")}
                </Text>
              </Stack>
            </Flex>
            <Stack gap={0}>
              <Flex align="center"></Flex>
            </Stack>
          </Flex>

          <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="7rem 0 0"
            >
              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("patient.details")}
              </Heading>
              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1.5rem"
              >
                <HStack gap={4} w="100%">
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.firstName")}
                    value={firstName}
                    placeholder={t("placeholder.firstName")}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setFirstNameTouched(true);
                    }}
                    isTouched={firstNameTouched}
                    isError={isFirstNameError}
                    w="50%"
                    errorMessage={t("formError.firstNameRequired")}
                  />

                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.lastName")}
                    value={lastName}
                    placeholder={t("placeholder.lastName")}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setLastNameTouched(true);
                    }}
                    isTouched={lastNameTouched}
                    isError={isLastNameError}
                    w="50%"
                    errorMessage={t("formError.lastNameRequired")}
                  />
                </HStack>

                <HStack gap={4} w="100%">
                  <FormControlDatePicker
                    type="date"
                    isRequired
                    label={t("form.birthDate")}
                    value={birthDate}
                    // value="1970-01-01T00:00:34.357Z"
                    // value="1970-01-01T00:00:33.246Z"
                    placeholder={t("placeholder.birthDate")}
                    onChange={(newValue) => {
                      setBirthDate(newValue);
                    }}
                    onOpen={() => {}}
                    onClose={() => setBirthDateTouched(true)}
                    isTouched={birthDateTouched}
                    isError={isBirthDateError}
                    w="50%"
                    errorMessage={t("formError.birthDateRequired")}
                  />
                  <FormControlDefault
                    type="number"
                    // isRequired
                    label={t("form.personalCode")}
                    value={personalCode}
                    placeholder={t("placeholder.personalCode")}
                    onChange={(e) => {
                      setPersonalCode(e.target.value);
                      setPersonalCodeTouched(true);
                    }}
                    isTouched={personalCodeTouched}
                    isError={isPersonalCodeError}
                    w="50%"
                    errorMessage={t("formError.personalCodeRequired")}
                  />
                </HStack>
              </Flex>
            </Flex>

            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="0 0 4rem"
            >
              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("patient.addressContactDetails")}
              </Heading>
              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1.5rem"
              >
                <HStack gap={4} w="100%">
                  <FormControlPhone
                    label={t("form.phone")}
                    value={phone}
                    onChange={(newPhone) => {
                      setPhone(newPhone);
                      setPhoneTouched(true);
                    }}
                    isRequired={true}
                    isTouched={phoneTouched}
                    isError={isPhoneError}
                    errorMessage={t("formError.phoneRequired")}
                    w="50%"
                    defaultCountry="lt"
                  />

                  <FormControlPhone
                    label={t("form.phoneAlt")}
                    value={phoneAlt}
                    onChange={(newPhone) => {
                      setPhoneAlt(newPhone);
                    }}
                    isRequired={false}
                    errorMessage={t("formError.phoneRequired")}
                    w="50%"
                    defaultCountry="lt"
                  />
                </HStack>

                <FormControlDefault
                  type="email"
                  // isRequired
                  label={t("form.email")}
                  value={email}
                  placeholder={t("placeholder.email")}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailTouched(true);
                  }}
                  isTouched={emailTouched}
                  isError={isEmailError}
                  w="100%"
                  errorMessage={t("formError.emailRequired")}
                />

                <HStack gap={4} w="100%">
                  <FormControlAddress
                    currentValue={street}
                    label={t("form.address")}
                    onAddressSelect={handleAddressSelect}
                    isRequired={true}
                    isTouched={streetTouched} // Assuming you have similar touch state for street
                    isError={isStreetError} // Assuming you have similar error state for street
                    setIsTouched={setStreetTouched}
                    errorMessage={t("formError.addressRequired")}
                    w="100%"
                    googleApiKey="AIzaSyCNQfHnntkLgj5Es5V8V0H2BfIetOOZ9yw"
                  />
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.zip")}
                    value={zip}
                    placeholder={t("placeholder.zip")}
                    onChange={(e) => {
                      setZip(e.target.value);
                      setZipTouched(true);
                    }}
                    isTouched={zipTouched}
                    isError={isZipError}
                    w="40%"
                    errorMessage={t("formError.zipRequired")}
                  />
                </HStack>

                <HStack gap={4} w="100%">
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.city")}
                    value={city}
                    placeholder={t("placeholder.city")}
                    onChange={(e) => {
                      setCity(e.target.value);
                      setCityTouched(true);
                    }}
                    isTouched={cityTouched}
                    isError={isCityError}
                    w="50%"
                    errorMessage={t("formError.cityRequired")}
                  />

                  <FormControlCountry
                    label={t("form.country")}
                    value={country}
                    isTouched={countryTouched}
                    isError={isCountryError}
                    onChange={(selectedOption) => {
                      setCountry(selectedOption ? selectedOption.value : "");
                      setCountryTouched(true);
                      if (selectedOption) setCityError("");
                    }}
                    isRequired={true}
                    w="50%"
                    errorMessage={t("formError.countryRequired")}
                  />
                </HStack>
              </Flex>
            </Flex>

            <Flex
              mt={4}
              justify="center"
              direction="column"
              borderTop={`1px solid ${newBorder}`}
              p="1.5rem 0"
              position="relative"
              zIndex={2}
              bg={white}
              bottom="0"
              w="100%"
            >
              <Flex justify="flex-end">
                <Button
                  minWidth="10rem"
                  mr={4}
                  bg={backgroundLight}
                  size="lg"
                  color={textSecondary}
                  fontSize="md"
                  borderRadius="10rem"
                  onClick={closeHandler}
                >
                  {t("common.cancel")}
                </Button>
                <Tooltip
                  p="0.5rem"
                  borderRadius="1rem"
                  bg={buttonRed}
                  label={
                    <Flex direction="column" justify="center" align="center">
                      {isFirstNameError && (
                        <Text
                          color={buttonRedText}
                          fontSize="xs"
                          fontWeight="500"
                        >
                          {t("formError.firstNameRequired")}
                        </Text>
                      )}
                      {isLastNameError && (
                        <Text
                          color={buttonRedText}
                          fontSize="xs"
                          fontWeight="500"
                        >
                          {t("formError.lastNameRequired")}
                        </Text>
                      )}
                      {isBirthDateError && (
                        <Text
                          color={buttonRedText}
                          fontSize="xs"
                          fontWeight="500"
                        >
                          {t("formError.birthDateRequired")}
                        </Text>
                      )}
                      {isPhoneShortError && (
                        <Text
                          color={buttonRedText}
                          fontSize="xs"
                          fontWeight="500"
                        >
                          {t("formError.phoneRequired")}
                        </Text>
                      )}
                      {isStreetError &&
                        isZipError &&
                        isCityError &&
                        isCountryError && (
                          <Text
                            color={buttonRedText}
                            fontSize="xs"
                            fontWeight="500"
                          >
                            {t("formError.addressRequired")}
                          </Text>
                        )}
                    </Flex>
                  }
                  isDisabled={
                    !isFirstNameError &&
                    !isLastNameError &&
                    !isBirthDateError &&
                    !isPhoneShortError &&
                    !isStreetError &&
                    !isZipError &&
                    !isCityError &&
                    !isCountryError
                  }
                >
                  <Button
                    minWidth="10rem"
                    type="submit"
                    size="lg"
                    bg={primary}
                    color={white}
                    fontSize="md"
                    borderRadius="10rem"
                    isDisabled={
                      !firstName.trim() ||
                      !lastName.trim() ||
                      !birthDate.trim() ||
                      isPhoneShortError ||
                      !street.trim() ||
                      !zip.trim() ||
                      !city.trim() ||
                      !country.trim()
                    }
                  >
                    {t("patient.editPatient")}
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default EditPatient;
