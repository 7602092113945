import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  Text,
  Flex,
  Icon,
  Avatar,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa6";
// import { listUsersEmployees } from "../../../../../actions/userActions";
import {
  textSecondary,
  textPrimary,
  backgroundLight,
  white,
  newBorder,
  buttonBlue,
  buttonBlueText,
  secondary,
  buttonRed,
  buttonRedText,
  backgroundGrey,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import { FaUserNurse, FaUser, FaRegUser } from "react-icons/fa6";

const UserPopover = ({ initialUser, options, onChange, isDisabled }) => {
  // TRANSLATIONS
  const { t } = useTranslation();

  // REDUX
  // const dispatch = useDispatch();
  // const userEmployeesList = useSelector((state) => state.userEmployeesList);
  // const { employees } = userEmployeesList;

  // CONSTANTS
  const [isOpen, setIsOpen] = useState(false);
  const menuButtonRef = React.useRef();
  const [caretVisible, setCaretVisible] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(initialUser || null);
  const [showEmployeeName, setShowEmployeeName] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);

  // HANDLERS

  const handleClearUser = () => {
    setSelectedEmployee({}); // Set selectedEmployee to null

    if (onChange) {
      onChange(null); // Call onChange prop with empty object
    }
  };

  const handleMenuOpen = () => {
    setIsOpen(true);
    setCaretVisible(true);
    setShowTooltip(false); // Hide tooltip when menu is opened
  };

  const handleMenuClose = () => {
    setIsOpen(false);
    setTimeout(() => setCaretVisible(false), 300); // Adjust time as needed
    setShowTooltip(false); // Hide tooltip when menu is closed
  };

  const handleEmployeeSelect = (employee) => {
    setShowEmployeeName(false); // Hide the name initially

    // Delay updating the selected employee and showing the name
    setTimeout(() => {
      setSelectedEmployee(employee);
      setShowEmployeeName(true);
      if (onChange) {
        onChange(employee);
      }
    }, 200); // Adjust the timeout duration as needed

    setShowTooltip(false);
  };

  const handleMouseEnter = () => {
    if (!isOpen) {
      setShowTooltip(true); // Show tooltip only if menu is not open
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false); // Hide tooltip on mouse leave
  };

  // FILTER
  const filteredOptions = options.filter(
    (employee) => employee._id !== (initialUser && initialUser._id)
  );

  // // USE EFFECT
  // useEffect(() => {
  //   if (!options) {
  //     dispatch(listUsersEmployees());
  //   }
  // }, [dispatch]);

  // This effect updates the selected employee when initialUser changes
  useEffect(() => {
    setSelectedEmployee(initialUser);
  }, [initialUser]);

  // useEffect(() => {
  //   const employee = options.find(emp => emp._id === initialUser);
  //   setSelectedEmployee(employee || {});
  // }, [initialUser, options]);

  // LOGS
  // console.log("initialUser,", initialUser);
  // console.log("options,", options);
  // console.log("employees,", employees);

  // console.log("selectedEmployee,", selectedEmployee);
  // console.log("showTooltip", showTooltip);

  // console.log("isDisabled", isDisabled);
  // console.log("initialUser", initialUser);
  // console.log("options", options);

  return (
    <Box p={0} borderRadius="0.75rem" opacity={isDisabled ? 0.5 : 1}>
      <Menu isLazy onOpen={handleMenuOpen} onClose={handleMenuClose}>
        <Tooltip
          label={t("popover.selectDoctor")}
          placement="bottom"
          bg={secondary}
          isOpen={showTooltip}
          hasArrow
          isDisabled={isDisabled ? false : showTooltip}
        >
          <MenuButton
            as={Button}
            ref={menuButtonRef}
            bg={white}
            color={textSecondary}
            border={`0px solid ${newBorder}`}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10.75rem"
            p="0rem 0.75rem"
            transition="0.2s all"
            size="sm"
            onClick={() => setIsOpen(!isOpen)}
            _hover={{ bg: backgroundLight }}
            _active={{ bg: backgroundLight }}
            cursor="pointer" // Add cursor style
            role="group"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            isDisabled={isDisabled}
          >
            <Flex align="center" justify="space-between">
              <Flex align="center">
                <Flex align="center">
                  <Flex
                    align="center"
                    justify="center"
                    w="1.25rem"
                    h="1.25rem"
                    bg={
                      selectedEmployee && selectedEmployee._id
                        ? buttonBlue
                        : backgroundLight
                    }
                    borderRadius="0.45rem"
                    mr={1}
                  >
                    <Icon
                      as={FaUserNurse}
                      h="0.75rem"
                      w="0.75rem"
                      color={
                        selectedEmployee && selectedEmployee._id
                          ? textSecondary
                          : backgroundGrey
                      }
                    />
                  </Flex>

                  {selectedEmployee && selectedEmployee._id ? (
                    <Text
                      color={textSecondary}
                      fontWeight="500"
                      whiteSpace="nowrap"
                      fontSize="0.875rem"
                      opacity={showEmployeeName ? 1 : 0}
                      transition="opacity 0.2s ease-in-out" // Transition for opacity
                    >
                      {selectedEmployee.lastName && selectedEmployee.lastName}

                      <Text
                        as="span"
                        color={textSecondary}
                        fontWeight="500"
                        ml={1}
                      >
                        {selectedEmployee.firstName &&
                          selectedEmployee.firstName[0] + "."}
                      </Text>
                    </Text>
                  ) : (
                    <Text
                      color={backgroundGrey}
                      fontWeight="500"
                      whiteSpace="nowrap"
                      fontSize="0.875rem"
                      opacity={showEmployeeName ? 1 : 0}
                      transition="opacity 0.2s ease-in-out" // Transition for opacity
                    >
                      {t("select.assignDoctor")}
                    </Text>
                  )}
                </Flex>
              </Flex>

              <Icon
                as={FaCaretDown}
                h="0.75rem"
                w={isOpen ? "0.75rem" : "0rem"}
                ml={1}
                opacity="0.5"
                transition="all 0.2s ease"
                transform={isOpen ? "rotate(180deg)" : "rotate(0)"} // Rotate caret when menu is open
                _groupHover={{
                  opacity: 1,
                  width: "0.75rem",
                  transition: "all 0.2s ease",
                }}
              />
            </Flex>
          </MenuButton>
        </Tooltip>
        <MenuList borderRadius="0.75em" p={0} m={0} overflow="hidden">
          {filteredOptions.map((employee) => (
            <MenuItem
              key={employee._id}
              onClick={() => handleEmployeeSelect(employee)}
              _hover={{ bg: backgroundLight, color: secondary }}
              borderBottom={`1px solid ${newBorder}`}
              _last={{ borderBottom: "none" }}
            >
              <Flex align="center" justify="space-between" w="100%">
                <Flex align="center">
                  <Flex
                    align="center"
                    justify="center"
                    w="1.5rem"
                    h="1.5rem"
                    bg={buttonBlue}
                    borderRadius="10rem"
                    mr={2}
                  >
                    <Text color={buttonBlueText} fontWeight="500" fontSize="xs">
                      {employee.firstName &&
                        employee.firstName[0] + employee.lastName[0]}
                    </Text>
                  </Flex>
                  <Stack gap={0}>
                    <Flex align="center">
                      <Text
                        color={textPrimary}
                        fontWeight="500"
                        fontSize="0.875rem"
                      >
                        {employee.lastName}
                        <Text
                          as="span"
                          color={textSecondary}
                          fontWeight="500"
                          ml={1}
                        >
                          {employee.firstName[0] + "."}
                        </Text>
                      </Text>
                    </Flex>

                    <Text color={textSecondary} fontWeight="500" fontSize="xs">
                      {/* {t("professions." + employee.profession)} */}
                      {/* {employee.profession} */}

                      {employee.profession && employee.profession.name}
                    </Text>
                  </Stack>
                </Flex>
              </Flex>
            </MenuItem>
          ))}
          {selectedEmployee && selectedEmployee._id && (
            <MenuItem
              onClick={handleClearUser}
              _hover={{ bg: backgroundLight, color: secondary }}
            >
              <Flex align="center" py={2}>
                <Icon
                  fontSize="0.75rem"
                  as={FaCaretDown}
                  color={textSecondary}
                  mr={2}
                />
                <Text fontSize="xs" fontWeight="500" color={textSecondary}>
                  {t("button.clear")}
                </Text>
              </Flex>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default UserPopover;
