import React from 'react';
import styled from '@emotion/styled';
import {
  Flex,
  Text,
  Avatar,
  Box,
  Icon,
  HStack,
  List,
  ListItem,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {
  textPrimary,
  textSecondary,
  white,
  newBorder,
  primary,
  buttonBlue,
  buttonBlueText,
} from '../../../../../utils/colors';
import { TbSquareRoundedX } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';

const CourseList = ({ courses, newlyAddedCourses, removeCourseHandler }) => {
  const t = useTranslation();

  return (
    <List
      mt="-0.5rem"
      // p="0.5rem 1rem"
      border={`0px dashed ${newBorder}`}
      borderRadius="0rem"
      overflowY="auto"
      p={0}
    >
      {courses.map((course, index) => (
        <ListItemas
          key={course._id}
          p="0.5rem 1rem"
          h="3rem"
          border={`1px solid ${newBorder}`}
          m="0.5rem 0"
          display="flex"
          alignItems="center"
          // bg={backgroundLight}
          borderRadius="0.75rem"
          className={
            newlyAddedCourses.includes(course._id) ? 'newly-added-attendee' : ''
          }
        >
          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <HStack gap={0}>
              <Avatar
                src={course.image}
                name={course.title}
                fontSize="xs"
                h="1.75rem"
                w="1.75rem"
                mr={2}
                bg={buttonBlue}
                color={buttonBlueText}
              />
              <Text fontSize="sm" fontWeight="500" color={textPrimary}>
                {course.title}
              </Text>
              <Text
                pl={2}
                fontSize="sm"
                fontWeight="500"
                color={textSecondary}
                textTransform="capitalize"
              >
                {dayjs(course.startDate).format('DD MMM')} -{' '}
                {dayjs(course.endDate).format('DD MMM')}
              </Text>
            </HStack>

            <HStack gap={0}>
              {newlyAddedCourses.includes(course._id) && (
                <Box
                  bg={primary}
                  color={white}
                  p="0.25rem 0.5rem"
                  borderRadius="10rem"
                  mr={2}
                  fontSize="xs"
                  fontWeight="600"
                >
                  {t('course.new')}
                </Box>
              )}
              <Icon
                as={TbSquareRoundedX}
                mr="0.5rem"
                color={textSecondary}
                transition="all 0.2s"
                onClick={() => removeCourseHandler(course._id)}
                _hover={{
                  color: textPrimary,
                  cursor: 'pointer',
                  transform: 'scale(1.2)',
                }}
              />
            </HStack>
          </Flex>
        </ListItemas>
      ))}
    </List>
  );
};

const ListItemas = styled(ListItem)`
  &.newly-added-attendee {
    /* background-color: #c1e6c8; */

    border: 1px solid #c1e6c8;
  }
`;

export default CourseList;
