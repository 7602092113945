import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { createAppointment } from "../../../../actions/appointmentActions";
import { backgroundLight } from "../../../../utils/colors";
import {
  Box,
  Flex,
  Heading,
  Button,
  FormLabel,
  IconButton,
  Text,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  textPrimary,
  secondary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";
import AppointmentPicker from "../../../../components/input/AppointmentPicker";
import { APPOINTMENT_CREATE_RESET } from "../../../../constants/appointmentContstants";
import FormControlPatient from "../../../../components/input/FormControlPatient";
import ServiceSelect from "../../../../components/select/ServiceSelect";
import ServiceSelectAppointments from "../../../../components/select/ServiceSelectAppointments";
import TabsComponentNew from "../../../../components/tabs/TabsComponentNew";
import ServiceList from "../../patients/sidepanels/partials/ServiceList";
import mongoose from "mongoose";

const CreateAppointment = ({ initialDate, close }) => {
  // TRANSLATION
  const { t } = useTranslation();

  // REDUX
  const dispatch = useDispatch();

  // CONSTANTS

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(startDate);
  const [patient, setPatient] = useState(null);
  const [services, setServices] = useState([]);

  const [addServiceModalOpen, setAddServiceModalOpen] = useState(true);
  const [appointmentDates, setAppointmentDates] = useState({});
  const [employees, setEmployees] = useState([]);

  // TABS
  const [activeTab, setActiveTab] = useState(0);
  const tabsData = [
    { label: t("appointment.createTabDate") },
    { label: t("appointment.createTabPatient") },
    { label: t("appointment.createTabServices") },
    { label: t("appointment.createTabNotes") },
  ];

  // HANDLERS

  const closeHandler = () => {
    close();
    dispatch({ type: APPOINTMENT_CREATE_RESET });
  };

  // LOGIC
  const addServiceToList = (selectedService) => {
    // Clone the selectedService to a new object
    const newService = {
      ...selectedService, // Spread to copy properties
      _id: mongoose.Types.ObjectId(), // Generate a new ObjectId
      appointment: null, // Set the appointment to null by default
      employee: null, // Set the employee to null by default
      order: services.length + 1, // Set the order based on the current list length
      paid: false, // You can set other default values here
      // Ensure price is copied by value, not by reference if it's an object
      price:
        typeof selectedService.price === "object"
          ? { ...selectedService.price }
          : selectedService.price,
      priceVariations: selectedService.priceVariations,
      totalPrice:
        selectedService.priceVariations === false ? 0 : selectedService.price,
      service: {
        ...selectedService.service, // Deep copy if 'service' is an object
        _id: selectedService.value,
        name: selectedService.label,
        serviceGroup: selectedService.serviceGroup,
        employee: null,
      },
      status: "uncompleted", // Set the initial status as needed
      teeth: [], // Set the initial teeth array as needed
      quantity: selectedService.priceVariations === true ? 1 : 0,
    };

    console.log("newService", newService);

    // setTotalTpPrice(totalTpPrice + newService.totalPrice);

    // Add the new service to the list of services
    setServices((prevServices) => [...prevServices, newService]);

    setAddServiceModalOpen(false);
  };

  // SUBMIT

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createAppointment(startDate, endDate));
  };

  // USE EFFECT
  useEffect(() => {
    if (initialDate) {
      setStartDate(new Date(initialDate));
      setEndDate(new Date(initialDate));
    }
  }, [initialDate]);

  // LOGS

  // console.log("initialDate", initialDate);
  // console.log("startDate", startDate);
  // console.log("endDate", endDate);

  return (
    <Box position="relative">
      <form
        onSubmit={submitHandler}
        style={{
          height: "100%",
        }}
      >
        <SidePanelHeader
          close={closeHandler}
          title={t("appointment.createAppointment")}
          subtitle="aa"
          // ageType={ageType}
        />

        <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
          <Flex
            direction="column"
            w="100%"
            align="flex-start"
            justify="center"
            p="7rem 0 0"
          >
            <TabsComponentNew
              activeTab={activeTab}
              tabs={tabsData}
              setActiveTab={setActiveTab}
              isTitleValid={true}
              disabledTabs={[]}
            >
              <TabPanels>
                <TabPanel pb={4} px={0}>
                  <Flex direction="column" w="100%">
                    <Heading
                      fontSize="1.125rem"
                      color={textPrimary}
                      fontWeight="600"
                      m="1rem 0"
                    >
                      {t("appointment.tabDateTitle")}
                    </Heading>

                    <Flex
                      direction="column"
                      w="100%"
                      bg={backgroundLight}
                      borderRadius="1rem"
                      p="1.5rem"
                    >
                      <AppointmentPicker
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                      />
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel pb={4} px={0}>
                  <Flex direction="column" w="100%">
                    <Heading
                      fontSize="1.125rem"
                      color={textPrimary}
                      fontWeight="600"
                      m="1rem 0"
                    >
                      {t("appointment.tabPatientTitle")}
                    </Heading>

                    <Flex
                      direction="column"
                      w="100%"
                      bg={backgroundLight}
                      borderRadius="1rem"
                      p="1.5rem"
                    >
                      <FormControlPatient
                        value={patient}
                        onChange={(selectedOption) => {
                          setPatient(selectedOption.value);
                        }}
                        isRequired={true} //
                        w="100%"
                      />
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel pb={4} px={0}>
                  <Flex direction="column" w="100%">
                    <Heading
                      fontSize="1.125rem"
                      color={textPrimary}
                      fontWeight="600"
                      m="1rem 0"
                    >
                      {t("appointment.tabServicesTitle")}
                    </Heading>

                    <Flex direction="column" w="100%">
                      <ServiceList
                        services={services}
                        addServiceToList={addServiceToList}
                        handleEmployeeChange={() => {}}
                        employeeOptions={[]}
                        employees={employees}
                        appointmentDates={appointmentDates}
                        saveEditedService={() => {}}
                        removeService={() => {}}
                        addServiceModalOpen={addServiceModalOpen}
                        setAddServiceModalOpen={setAddServiceModalOpen}
                        moveServiceUp={() => {}}
                        moveServiceDown={() => {}}
                        serviceStatusHandler={() => {}}
                        status="consulting"
                        isAppointment
                        w="100%"
                      />

                      {/* <ServiceSelectAppointments onChange={() => {}} /> */}
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel pb={4} px={0}>
                  <Flex direction="column" w="100%">
                    confirm info and notes
                  </Flex>
                </TabPanel>
              </TabPanels>
            </TabsComponentNew>
          </Flex>
          <Flex
            mt={4}
            justify="center"
            direction="column"
            borderTop={`1px solid ${newBorder}`}
            p="1.5rem 0"
            position="relative"
            zIndex={0}
            bg={white}
            bottom="0"
            w="100%"
          >
            <Flex justify="flex-end">
              <Button
                minWidth="10rem"
                mr={4}
                bg={backgroundLight}
                size="lg"
                color={textSecondary}
                fontSize="md"
                borderRadius="10rem"
                onClick={closeHandler}
              >
                {t("common.cancel")}
              </Button>
              <Button
                minWidth="10rem"
                type="submit"
                size="lg"
                bg={primary}
                color={white}
                fontSize="md"
                borderRadius="10rem"
                _hover={{
                  bg: secondary,
                }}
              >
                {t("appointment.createAppointment")}
              </Button>
            </Flex>
          </Flex>
        </Box>
      </form>
    </Box>
  );
};

export default CreateAppointment;
