import React, { useEffect, useState } from "react";
import {
  FormControl,
  Box,
  Spinner,
  Text,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import Select from "react-select";
import {
  white,
  newBorder,
  textPrimary,
  textSecondary,
  primary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { listUsersPatients } from "../../actions/userActions";
import dayjs from "dayjs";
import StatusBadge from "../../components/badges/StatusBadge";
import { tint } from "polished";

const FormControlPatient = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  currentPatient,
  w,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(400);
  const pageSizes = [4, 10, 25, 100];

  const [sortField, setSortField] = useState("lastName");
  const [sortOrder, setSortOrder] = useState("asc");

  const userPatientsList = useSelector((state) => state.userPatientsList);
  const { loading, error, patients, pages } = userPatientsList;

  const [patientOptions, setPatientOptions] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");

  const normalizeText = (text) => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const onChangeSearchTitle = (e) => {
    const searchTitle = normalizeText(e.target.value);
    setSearchTitle(searchTitle);
  };

  useEffect(() => {
    dispatch(
      listUsersPatients(searchTitle, page, pageSize, sortField, sortOrder)
    );
  }, [dispatch]);

  useEffect(() => {
    if (patients && patients.length > 0) {
      const transformedPatients = patients.map((group) => ({
        value: group._id,
        firstName: group.firstName,
        lastName: group.lastName,
        birthDate: group.birthDate,
        status: group.status,
      }));
      setPatientOptions(transformedPatients);
    }
  }, [patients]);

  useEffect(() => {
    if (currentPatient && patients && patients.length > 0) {
      const matchingPatientOption = patientOptions.find(
        (option) => option.value === currentPatient._id
      );
      // If a matching patient is found, set it as the value
      if (matchingPatientOption) {
        onChange(matchingPatientOption);
      }
    }
  }, [currentPatient, patientOptions, onChange]);

  // console.log("patientOptions:", patientOptions);
  // console.log("currentPatient:", currentPatient);

  // console.log("patients:", patients);
  // console.log("patientOptions:", patientOptions);

  // Custom rendering for options
  const formatOptionLabel = ({ firstName, lastName, birthDate, status }) => (
    <Flex align="center" justify="space-between" w="100%">
      <Flex align="center">
        <Text fontSize="md" fontWeight="500">
          {lastName}{" "}
          <Text as="span" fontWeight="400">
            {firstName}
          </Text>
        </Text>
        <Text ml={2} fontSize="sm">
          {dayjs(birthDate).format("YYYY-MM-DD")}
        </Text>
      </Flex>

      <StatusBadge isSmall options="user" status={status} ml={2} />
    </Flex>
  );

  if (error) return <p>Error: {error}</p>;
  if (loading)
    return (
      <Box>
        <Spinner size="lg" color={primary} />
      </Box>
    );

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      {/* <FormLabel fontSize="sm" position="relative">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel> */}

      <Select
        isSearchable
        value={patientOptions.find((option) => option.value === value)}
        onChange={onChange}
        options={patientOptions}
        placeholder={t("placeholder.selectPatient")}
        formatOptionLabel={formatOptionLabel}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            color: textSecondary,
            borderRadius: "1rem",
            backgroundColor: `${white}`,
            border: `1px solid ${newBorder}`,
            fontSize: "0.938rem",
            fontWeight: "500",
            height: "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
            ":hover": {
              cursor: "pointer",
            },
          }),
          option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : textPrimary, // Color of text in options
            backgroundColor: state.isSelected ? primary : white, // Background color of selected option
            ":hover": {
              backgroundColor: state.isSelected ? primary : tint(0.3, primary), // Background color when hovering
              color: "white",
              cursor: "pointer",
            },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            const color =
              state.data.value === "" ? "desired color" : "default color";

            return { ...provided, opacity, transition, color };
          },
        }}
        noOptionsMessage={() => t("placeholder.noOptions")}
      />
    </FormControl>
  );
};

export default FormControlPatient;
