import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import {
  newBorder,
  textPrimary,
  textSecondary,
  white,
} from "../../../utils/colors";
import { useTranslation } from "react-i18next";

const SidebarContainer = ({ userInfo, children }) => {
  const { t } = useTranslation();
  // console.log("userInfo in sidebar", userInfo);

  const clinicName = userInfo && userInfo.clinicName;

  return (
    <>
      <Box
        borderRight={`0px solid ${newBorder}`}
        // bg="green"
        h="100%"
        maxH="calc(100% - 150px)"

        // position="relative"
      >
        {children}
        <Flex
          w="100%"
          position="absolute"
          bottom={0}
          left={0}
          direction="column"
          // bg="blue"
          // pl={`calc(2rem + 20px)`}
          // pb={6}
          // pr={6}
          bg={white}
          p="1rem 2rem 1rem 2rem"
          borderTop={`1px solid ${newBorder}`}
        >
          <Text fontSize="xs" fontWeight="400" color={textSecondary}>
            {t("common.clinicName")}
          </Text>
          <Text
            fontSize="sm"
            fontWeight="500"
            color={textPrimary}
            ml={0}
            maxWidth="100%"
            isTruncated
          >
            {clinicName}
          </Text>
        </Flex>
      </Box>
    </>
  );
};

export default SidebarContainer;
