import dayjs from "dayjs";

export const calculateAge = (birthDate) => {
  const birthday = dayjs(birthDate);
  const age = dayjs().diff(birthday, "year");
  return age;
};

const calculateTotalServicesPrice = (services) => {
  return services.reduce((total, service) => {
    const pricePerService = Number(service.price);
    const quantity = Number(service.quantity);
    return total + pricePerService * quantity;
  }, 0);
};

export const calculateTotalPriceForService = (service) => {
  // console.log("service in calculation", service);

  const price = parseFloat(service.price || 0);

  // Only include teeth count in the price calculation if priceVariations is false
  if (service.service.priceVariations === false) {
    let teethString = service.teeth || "";

    // Ensure teethString is always a string
    if (typeof teethString !== "string") {
      teethString = teethString.toString();
    }

    // Split the teethString by comma and filter out any empty strings
    const teethArray = teethString
      .split(",")
      .filter((teeth) => teeth.trim() !== "")
      .map(Number);

    const totalPriceTeeth = price * teethArray.length;
    // console.log("totalPriceTeeth", totalPriceTeeth);
    return totalPriceTeeth;
    // Multiply the price by the number of teeth
  } else if (service.service.priceVariations === true) {
    const totalPrice = price;
    // console.log("totalPrice", totalPrice);
    return totalPrice;
  }
};

export const calculateTotalPriceForAllServices = (services) => {
  const total = services.reduce(
    (acc, service) => acc + calculateTotalPriceForService(service),
    0
  );
  return total;
};
