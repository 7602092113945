import { useEffect, useState } from "react";
import dayjs from "dayjs";
import locale from "dayjs/locale/lt";
import weekdayPlugin from "dayjs/plugin/weekday";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";
import {
  Icon,
  IconButton,
  Button,
  Flex,
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { backgroundLight, textSecondary } from "../../../../utils/colors";

const Calendar = ({ appointments }) => {
  const now = dayjs().locale({
    ...locale,
  });

  dayjs.extend(weekdayPlugin);
  dayjs.extend(objectPlugin);
  dayjs.extend(isTodayPlugin);

  const [currentMonth, setCurrentMonth] = useState(now);
  const [arrayOfDays, setArrayOfDays] = useState([]);

  const nextMonth = () => {
    const plus = currentMonth.add(1, "month");

    setCurrentMonth(plus);
  };

  const prevMonth = () => {
    const minus = currentMonth.subtract(1, "month");

    setCurrentMonth(minus);
  };

  const renderHeader = () => {
    const dateFormat = "MMMM YYYY";

    return (
      <>
        <Flex justify="space-between" align="center" p="1rem 2rem">
          <Flex align="center">
            <IconButton
              bg={backgroundLight}
              color={textSecondary}
              size="sm"
              icon={<FaChevronLeft />}
              onClick={() => prevMonth()}
            />
          </Flex>

          <Heading fontSize="lg" textTransform="capitalize" fontWeight="600">
            {currentMonth.format(dateFormat)}
          </Heading>

          <Flex align="center">
            <IconButton
              bg={backgroundLight}
              color={textSecondary}
              size="sm"
              icon={<FaChevronRight />}
              onClick={() => nextMonth()}
            />
          </Flex>
        </Flex>
      </>
    );
  };

  const renderDays = () => {
    const dateFormat = "dddd";
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {now.weekday(i).format(dateFormat)}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };

  const getAllDays = () => {
    let currentDate = currentMonth.startOf("month").weekday(0);
    const nextMonth = currentMonth.add(1, "month").month();

    let allDates = [];
    let weekDates = [];

    let weekCounter = 1;

    while (currentDate.weekday(0).toObject().months !== nextMonth) {
      const formated = formateDateObject(currentDate);

      weekDates.push(formated);

      if (weekCounter === 7) {
        allDates.push({ dates: weekDates });
        weekDates = [];
        weekCounter = 0;
      }

      weekCounter++;
      currentDate = currentDate.add(1, "day");
    }

    setArrayOfDays(allDates);
  };

  useEffect(() => {
    getAllDays();
  }, [currentMonth]);

  const renderCells = () => {
    const rows = [];
    let days = [];

    arrayOfDays.forEach((week, index) => {
      week.dates.forEach((d, i) => {
        const date = dayjs(new Date(d.year, d.month, d.day));
        days.push(
          <div
            className={`col cell ${
              !d.isCurrentMonth ? "disabled" : d.isCurrentDay ? "selected" : ""
            }`}
            key={i}
          >
            <span className="number">{d.day}</span>
            <span className="bg">{d.day}</span>
            {/* Call renderAppointments here */}
            <div className="appointments">{renderAppointments(date)}</div>
          </div>
        );
      });
      rows.push(
        <div className="row" key={index}>
          {days}
        </div>
      );
      days = [];
    });

    return <div className="body">{rows}</div>;
  };

  const renderAppointments = (date) => {
    const dailyAppointments = appointments.filter(
      (appointment) =>
        dayjs(appointment.startDate).format("YYYY-MM-DD") ===
        date.format("YYYY-MM-DD")
    );

    return (
      <div>
        {dailyAppointments.map((appointment, index) => (
          <div key={index}>
            {/* Render appointment details */}
            {appointment.patient} at{" "}
            {dayjs(appointment.startDate).format("HH:mm")}
          </div>
        ))}
      </div>
    );
  };

  const formateDateObject = (date) => {
    const clonedObject = { ...date.toObject() };

    const formatedObject = {
      day: clonedObject.date,
      month: clonedObject.months,
      year: clonedObject.years,
      isCurrentMonth: clonedObject.months === currentMonth.month(),
      isCurrentDay: date.isToday(),
    };

    return formatedObject;
  };

  return (
    <div className="calendar">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
};

export default Calendar;
