export const SERVICEGROUP_LIST_REQUEST = "SERVICEGROUP_LIST_REQUEST";
export const SERVICEGROUP_LIST_SUCCESS = "SERVICEGROUP_LIST_SUCCESS";
export const SERVICEGROUP_LIST_FAIL = "SERVICEGROUP_LIST_FAIL";

export const SERVICEGROUP_DETAILS_REQUEST = "SERVICEGROUP_DETAILS_REQUEST";
export const SERVICEGROUP_DETAILS_SUCCESS = "SERVICEGROUP_DETAILS_SUCCESS";
export const SERVICEGROUP_DETAILS_FAIL = "SERVICEGROUP_DETAILS_FAIL";

export const SERVICEGROUP_CREATE_REQUEST = "SERVICEGROUP_CREATE_REQUEST";
export const SERVICEGROUP_CREATE_SUCCESS = "SERVICEGROUP_CREATE_SUCCESS";
export const SERVICEGROUP_CREATE_FAIL = "SERVICEGROUP_CREATE_FAIL";
export const SERVICEGROUP_CREATE_RESET = "SERVICEGROUP_CREATE_RESET";

export const SERVICEGROUP_UPDATE_REQUEST = "SERVICEGROUP_UPDATE_REQUEST";
export const SERVICEGROUP_UPDATE_SUCCESS = "SERVICEGROUP_UPDATE_SUCCESS";
export const SERVICEGROUP_UPDATE_FAIL = "SERVICEGROUP_UPDATE_FAIL";
export const SERVICEGROUP_UPDATE_RESET = "SERVICEGROUP_UPDATE_RESET";

export const SERVICEGROUP_DELETE_REQUEST = "SERVICEGROUP_DELETE_REQUEST";
export const SERVICEGROUP_DELETE_SUCCESS = "SERVICEGROUP_DELETE_SUCCESS";
export const SERVICEGROUP_DELETE_FAIL = "SERVICEGROUP_DELETE_FAIL";
export const SERVICEGROUP_DELETE_RESET = "SERVICEGROUP_DELETE_RESET";

export const SERVICEGROUP_UPDATE_STATUS_REQUEST =
  "SERVICEGROUP_UPDATE_STATUS_REQUEST";
export const SERVICEGROUP_UPDATE_STATUS_SUCCESS =
  "SERVICEGROUP_UPDATE_STATUS_SUCCESS";
export const SERVICEGROUP_UPDATE_STATUS_FAIL =
  "SERVICEGROUP_UPDATE_STATUS_FAIL";
export const SERVICEGROUP_UPDATE_STATUS_RESET =
  "SERVICEGROUP_UPDATE_STATUS_RESET";

export const SERVICEGROUP_UPDATE_ISCONSULTING_REQUEST =
  "SERVICEGROUP_UPDATE_ISCONSULTING_REQUEST";
export const SERVICEGROUP_UPDATE_ISCONSULTING_SUCCESS =
  "SERVICEGROUP_UPDATE_ISCONSULTING_SUCCESS";
export const SERVICEGROUP_UPDATE_ISCONSULTING_FAIL =
  "SERVICEGROUP_UPDATE_ISCONSULTING_FAIL";
export const SERVICEGROUP_UPDATE_ISCONSULTING_RESET =
  "SERVICEGROUP_UPDATE_ISCONSULTING_RESET";
