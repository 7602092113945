import React, { useEffect } from "react";
import { Flex, Box, Heading, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import PieChart from "./PieChart";
import {
  FaRegCircle,
  FaClock,
  FaCircleXmark,
  FaRegCircleCheck,
  FaCircleCheck,
  FaCircleExclamation,
  FaRegCircleDot,
} from "react-icons/fa6";
import {
  backgroundLight,
  buttonLightPurple,
  buttonLightPurpleDark,
  buttonRed,
  buttonRedDark,
  buttonRedText,
  greenVivid,
  textSecondary,
  buttonBlue,
  buttonBlueDark,
  buttonBlueText,
  buttonGreen,
  buttonGreenDark,
  buttonGreenText,
  buttonPurple,
  buttonPurpleDark,
  buttonPurpleText,
  buttonRedNew,
  white,
  newBorder,
  textPrimary,
} from "../../utils/colors";

const SinglePagePieChart = ({ data, label, options, plotLabel }) => {
  const { t } = useTranslation();

  const [loadingChart, setLoadingChart] = React.useState(true);
  const [courseData, setCourseData] = React.useState([]);

  if (options === "attendees") {
    options = [
      {
        value: "registered",
        label: t("status.registered"),
        icon: FaRegCircleDot,
        bg: buttonBlue,
        bgDark: buttonBlue,
        color: textPrimary,
        borderColor: buttonBlue,
        bgHover: "#ddd",
      },
      {
        value: "attended",
        label: t("status.attended"),
        icon: FaRegCircleCheck,
        bg: greenVivid,
        bgDark: greenVivid,
        color: textPrimary,
        borderColor: greenVivid,
        bgHover: greenVivid,
      },
      {
        value: "cancelled",
        label: t("status.cancelled"),
        icon: FaCircleXmark,
        bg: buttonRed,
        bgDark: buttonRedDark,
        color: buttonRedText,
        borderColor: buttonRed,
        bgHover: "#dda3a3",
      },
      {
        value: "confirmed",
        label: t("status.confirmed"),
        icon: FaCircleCheck,
        bg: buttonGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },
      {
        value: "not_attended",
        label: t("status.notAttended"),
        icon: FaCircleExclamation,
        bg: buttonRedNew,
        bgDark: buttonPurpleDark,
        color: white,
        borderColor: buttonPurple,
        bgHover: "#9ebfc2",
      },
    ];
  } else if (options === "courses") {
    options = [
      {
        value: "draft",
        label: t("status.draft"),
        icon: FaRegCircle,
        bg: backgroundLight,
        bgDark: textSecondary,
        color: textSecondary,
        borderColor: newBorder,
        bgHover: "#ddd",
      },
      {
        value: "upcoming",
        label: t("status.upcoming"),
        icon: FaClock,
        bg: buttonLightPurple,
        bgDark: buttonLightPurpleDark,
        color: textPrimary,
        borderColor: buttonBlue,
        bgHover: "#b6cce1",
      },
      {
        value: "cancelled",
        label: t("status.cancelled"),
        icon: FaCircleXmark,
        bg: buttonRed,
        bgDark: buttonRedDark,
        color: buttonRedText,
        borderColor: buttonRed,
        bgHover: "#dda3a3",
      },
      {
        value: "completed",
        label: t("status.completed"),
        icon: FaRegCircleCheck,
        bg: greenVivid,
        bgDark: greenVivid,
        color: textPrimary,
        borderColor: greenVivid,
        bgHover: greenVivid,
      },
    ];
  }

  useEffect(() => {
    if (data && data.length > 0) {
      const statusCounts = options.map(
        (option) =>
          data.filter((course) => course.status === option.value).length
      );
      setCourseData(statusCounts);
    } else {
      setCourseData([0, 0, 0, 0, 0]);
    }
    setLoadingChart(false);
  }, [data]);

  console.log("data", data);
  console.log("courseData", courseData);

  return (
    <Box w="55%" pl={8} borderLeft={`1px solid ${newBorder}`}>
      <Heading fontSize="md" fontWeight="600" color={textPrimary} mt={2.5}>
        {label}
      </Heading>
      <Flex gap="0" w="100%" m="0 " p="1rem 0 0" justify="center">
        {/* {!loadingChart ? <CoursesPieChart data={courseData} /> : <Spinner />} */}
        {!loadingChart ? (
          <PieChart data={courseData} options={options} plotLabel={plotLabel} />
        ) : (
          <Spinner />
        )}
      </Flex>
    </Box>
  );
};

export default SinglePagePieChart;
