import React, { useState, useEffect } from "react";
import {
  Box,
  Icon,
  Button,
  Text,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Flex,
} from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa6";
import {
  primary,
  textSecondary,
  newBorder,
  white,
  backgroundLight,
  secondary,
  textPrimary,
} from "../../utils/colors";
import ReactCountryFlag from "react-country-flag";
import { shade } from "polished";
import { useTranslation } from "react-i18next";

const SubtaskPriceButton = ({ currentOption, onChangeOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuButtonRef = React.useRef();
  const { t } = useTranslation();

  const options = [
    {
      value: "one",
      label: t("task.one"),
    },

    {
      value: "quantity",
      label: t("task.qty"),
    },
  ];

  const selectedOption =
    options.find((option) => option.value === currentOption.value) ||
    options[0];

  const handleOptionChange = (option) => {
    onChangeOption(option);
    setIsOpen(false);
  };

  const handleMenuOpen = () => {
    setIsOpen(true);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
  };

  return (
    <Box p={0} borderRadius="0.875rem" ml={0} pl={2}>
      <Menu
        isLazy
        onOpen={handleMenuOpen}
        onClose={handleMenuClose}
        p="0"
        m="0"
      >
        <MenuButton
          as={Button}
          ref={menuButtonRef}
          bg={white}
          color={textSecondary}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="0.875rem"
          border={`1px solid ${newBorder}`}
          p="0rem 1rem"
          transition="0.2s all"
          onClick={() => setIsOpen(!isOpen)}
          _hover={{ bg: backgroundLight, color: textPrimary }}
          _active={{ bg: primary, color: white }}
          cursor="pointer" // Add cursor style
          role="group"
        >
          <Flex align="center">
            <Text fontSize="13px" fontWeight="600">
              {selectedOption.label}
            </Text>
            <Icon
              as={FaCaretDown}
              h="0.75rem"
              w={isOpen ? "0.75rem" : "0rem"}
              ml={1}
              opacity="0.5"
              transition="all 0.2s ease"
              transform={isOpen ? "rotate(180deg)" : "rotate(0)"} // Rotate caret when menu is open
              _groupHover={{
                opacity: 1,
                width: "0.75rem",
                transition: "all 0.2s ease",
              }}
            />
          </Flex>
        </MenuButton>

        <MenuList
          p={0}
          m={0}
          borderRadius="1rem"
          overflow="hidden"
          maxWidth="100px"
          w="100px"
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              py={2}
              px={4}
              borderBottom={`1px solid ${newBorder}`}
              color={option === currentOption ? primary : textSecondary}
              transition="0.2s all"
              onClick={() => onChangeOption(option)}
            >
              <Text fontSize="0.875rem" fontWeight="500" lineHeight="1">
                {option.label}
              </Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default SubtaskPriceButton;
