import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Avatar,
  Image,
  Text,
  Icon,
  Box,
  Flex,
} from "@chakra-ui/react";
import SearchBar from "../shared/SearchBar";
import dayjs from "dayjs";
import "dayjs/locale/lt"; // Import the Lithuanian locale
import "dayjs/locale/en";
import "dayjs/locale/lv";
import "dayjs/locale/pl";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userActions";
import {
  primary,
  textSecondary,
  white,
  newBorder,
  secondary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import LanguageButton from "../../components/buttons/LanguageButton";
import { FaCaretDown } from "react-icons/fa6";
import logo from "../../assets/icons/logo.svg";

export const Header = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const storedLanguage = localStorage.getItem("language");
  const initialLanguage = storedLanguage || "lt"; // Default to 'en'
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);

  const authLogin = useSelector((state) => state.authLogin);

  const { userInfo } = authLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  const profileHandler = () => {
    navigate("/profile");
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const initialLanguage = storedLanguage || "lt";
    setCurrentLanguage(initialLanguage);

    i18n.changeLanguage(initialLanguage);
    dayjs.locale(initialLanguage);
    // eslint-disable-next-line
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    dayjs.locale(lng);
    setCurrentLanguage(lng);
    console.log("Language changed to", lng);
  };

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    changeLanguage(selectedLanguage);

    console.log("handleChangeLanguage selectedLanguage", selectedLanguage);
  };

  const handleMenuClick = (language) => {
    changeLanguage(language);
    console.log("handleMenuClick change language", language);
  };

  const [menuOpen, setMenuOpen] = useState(false);

  // console.log("user", userInfo);
  // console.log("initialLanguage", initialLanguage);
  // console.log("currentLanguage", currentLanguage);
  // console.log("storedLanguage", storedLanguage);

  return (
    <>
      <Box
        position="fixed"
        top="0"
        right={["initial", 0, 0]}
        left={["0", "initial", "initial"]}
        w={["100%", "calc(100% - 280px)", "100%", "calc(100% - 280px)"]}
        h="80px"
        borderBottom={`1px solid ${newBorder}`}
        bg={white}
        zIndex={900}
      >
        <Flex
          w="100%"
          p="0 2rem"
          h="100%"
          align="center"
          justify="space-between"
        >
          <Flex display={["none", "flex", "flex"]}>
            <SearchBar.SearchBarHeader />
          </Flex>

          <Flex display={["flex", "none", "none"]}>
            <Image src={logo} h="1.25rem" w="initial" />
          </Flex>

          <Flex align="center">
            <Flex display={["none", "flex", "flex"]}>
              <LanguageButton
                currentLanguage={currentLanguage}
                languages={["en", "lt", "lv", "pl"]}
                onChangeLanguage={handleChangeLanguage}
                onMenuClick={handleMenuClick}
              />
            </Flex>

            <Menu isOpen={menuOpen} onClose={() => setMenuOpen(false)}>
              <MenuButton
                borderRadius="full"
                role="group"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <Flex align="center" ml="30px">
                  <Text
                    color={textSecondary}
                    fontWeight="600"
                    fontSize="sm"
                    mr={3}
                  >
                    {userInfo &&
                      userInfo.firstName &&
                      userInfo.lastName &&
                      userInfo.firstName[0] + ". " + userInfo.lastName}
                  </Text>
                  <Avatar
                    size="sm"
                    bg={secondary}
                    color={white}
                    name={`${userInfo.firstName} ${userInfo.lastName}`}
                  />
                  <Icon
                    as={FaCaretDown}
                    ml={1}
                    h="0.75rem"
                    w={menuOpen ? "0.75rem" : "0rem"}
                    opacity="0.5"
                    color={textSecondary}
                    transition="all 0.2s ease"
                    transform={menuOpen ? "rotate(180deg)" : "rotate(0)"} // Rotate caret when menu is open
                    _groupHover={{
                      opacity: 1,
                      width: "0.75rem",
                      transition: "all 0.2s ease",
                    }}
                  />
                </Flex>
              </MenuButton>

              <MenuList p={0} m={0} borderRadius="1rem" overflow="hidden">
                {userInfo ? (
                  <>
                    <Link to="/profile">
                      <MenuItem p="1rem 1rem" onClick={profileHandler}>
                        <Text color={textSecondary}>{t("common.profile")}</Text>
                      </MenuItem>
                    </Link>
                    <MenuItem p="1rem 1rem" onClick={logoutHandler}>
                      <Text color={textSecondary}>{t("common.logout")}</Text>
                    </MenuItem>
                  </>
                ) : (
                  <Link to="/login">
                    <MenuItem p="1rem 1rem">
                      <Text color={textSecondary}>
                        <i className="fas fa-user"></i> {t("common.signin")}
                      </Text>
                    </MenuItem>
                  </Link>
                )}
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
