import React from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import Select from "react-select";
import { newBorder, white, primary } from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { init } from "i18next";

const FormControlTaskType = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
}) => {
  const { t } = useTranslation();

  const options = [
    { value: "todo", label: t("task.taskStatusTodo") },
    { value: "pending", label: t("task.taskStatusPending") },
    { value: "done", label: t("task.taskStatusDone") },
  ];

  // Determine the initial selected value
  const initialValue = value
    ? options.find((option) => option.value === value)
    : options[0]; // Default to the first option if value is undefined or not in options

  // Find the option that matches the `value` prop or default to the first option
  const selectedValue = value
    ? options.find((option) => option.value === value)
    : options[0];

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize={isSmall ? "xs" : "sm"} position="relative">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>

      <Select
        isSearchable
        defaultValue={initialValue}
        value={options.find((option) => option.value === value)}
        onChange={onChange}
        options={options}
        placeholder={t("placeholder.taskStatus")}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            borderRadius: "1rem",
            backgroundColor: white,
            border: `1px solid ${newBorder}`,
            fontSize: isSmall ? "0.875rem" : "0.938rem",
            fontWeight: "500",
            height: isSmall ? "2.5rem" : "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition };
          },
        }}
      />
    </FormControl>
  );
};

export default FormControlTaskType;
