import React, { useRef, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { white, newBorder, textPrimary } from "../../utils/colors";
import {
  CountrySelector,
  PhoneInput,
  CountrySelectorDropdown,
  usePhoneInput,
} from "react-international-phone";
import "react-international-phone/style.css";

const FormControlPhone = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  defaultCountry,
}) => {
  const initialValue = value || ""; // The initial value of the phone input
  // The hook should be called inside the function component.
  const {
    inputValue,
    phone,
    country,
    setCountry,
    handlePhoneValueChange,
    inputRef,
  } = usePhoneInput({
    defaultCountry: defaultCountry || "us",
    value: initialValue || "",
    onChange: ({ phone, inputValue, country }) => {
      onChange(phone, inputValue, country); // Pass the new phone value and other details to the onChange handler
    },
  });

  // Function to set caret at the end of the input value
  const setCaretAtEnd = (e) => {
    const inputLength = e.target.value.length;
    e.target.setSelectionRange(inputLength, inputLength);
  };

  const formControlRef = useRef();

  useEffect(() => {
    const countrySelectButton = formControlRef.current?.querySelector(
      ".react-international-phone-country-selector-button"
    );
    if (countrySelectButton) {
      countrySelectButton.tabIndex = -1;
    }
  }, []);

  return (
    <>
      <Box w={w} ref={formControlRef}>
        <FormControl
          mb={4}
          isRequired={isRequired}
          isInvalid={isTouched && isError}
          w="100%"
          className={
            isError && isTouched
              ? "customPhone customPhone-error"
              : "customPhone"
          }
        >
          <FormLabel fontSize="sm" position="relative" color={textPrimary}>
            {label}
            {isError && isTouched && (
              <Box position="absolute" top={-1} right={0} zIndex={2}>
                <FormErrorMessage fontSize="xs">
                  {errorMessage}
                </FormErrorMessage>
              </Box>
            )}
          </FormLabel>

          <Flex tabIndex={-1} w="100%">
            <InputGroup
              size="lg"
              w="100%"
              bg={white}
              borderRadius="0.75rem"
              border={`1px solid ${newBorder}`}
              overflow="hidden"
            >
              <InputLeftElement className="customPhone" w="56px">
                <CountrySelector
                  selectedCountry={country.iso2}
                  onSelect={({ iso2 }) => setCountry(iso2)}
                  style={{
                    border: "none",
                    width: "100%",
                  }}
                />
              </InputLeftElement>

              <Input
                w="100%"
                bg="white"
                type="tel"
                color={textPrimary}
                value={inputValue}
                onChange={handlePhoneValueChange}
                defaultCountry={defaultCountry}
                ref={inputRef}
                size="lg"
                fontSize="0.938rem"
                fontWeight="500"
                border={`0px solid ${newBorder}`}
                borderRadius="0rem"
                onFocus={setCaretAtEnd}
                p="0 0 0 66px"
                // _focusVisible={{
                //   zIndex: "1",
                //   borderColor: "primary",
                //   boxShadow: `0 0 0 1px primary`,
                // }}
              />
            </InputGroup>
          </Flex>
        </FormControl>
      </Box>
    </>
  );
};

export default FormControlPhone;
