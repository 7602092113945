import React from "react";
import { IconButton } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { white, primary, text, border, textBlack } from "../../utils/colors";

const CloseIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.364 4.95L11.314 0L12.728 1.414L7.778 6.364L12.728 11.314L11.314 12.728L6.364 7.778L1.414 12.728L0 11.314L4.95 6.364L0 1.414L1.414 0L6.364 4.95Z"
      fill="#727190"
    />
  </svg>
);

const SidePanelAlt = ({ title, open, close, children, noBorder }) => {
  return (
    <>
      <AnimatePresence>
        {open && (
          <>
            <MotionBackdrop
              as={motion.div}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.2,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.1,
                },
              }}
              onClick={close}
            />
            <MotionContentWrapper
              initial={{
                scale: 1,
                x: "100vw",
              }}
              animate={{
                scale: 1,
                x: 0,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                scale: 0,
                x: "100vw",
                transition: {
                  duration: 0.1,
                },
              }}
            >
              <MotionContent
                initial={{
                  opacity: 0,
                  x: 100,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    duration: 0.1,
                  },
                }}
                className="modal-content"
              >
                <Content>{children}</Content>
              </MotionContent>
            </MotionContentWrapper>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

const MotionBackdrop = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`;

const MotionContentWrapper = styled(motion.div)`
  position: fixed;
  width: 600px;
  height: 1000px;
  background: ${white};
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1001;
  height: 100%;
  overflow: scroll;
`;

const MotionContent = styled(motion.div)`
  width: 100%;
  padding: 0rem 2rem;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 12px 2.5rem;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
  background: ${primary};
  color: ${white};
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  border: none;
  &:hover {
    background: ${primary};
    color: ${white};
  }
  .fa {
    margin-right: 8px;
  }
  &.close {
    background: ${white};
    color: ${text};
    border: 1px solid ${border};
    margin-right: 1rem;
    padding: 12px 1.5rem;
    &:hover {
      background: ${primary};
      color: ${white};
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 0rem 0rem;

  span {
    font-size: 18px;
    font-weight: 500;
    color: ${textBlack};
    letter-spacing: 0px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  border-bottom: ${(props) =>
    props.noBorder ? "none" : `1px solid ${border}`};

  padding: ${(props) => (props.noBorder ? "2rem 0 0rem" : "2rem 0 2rem")};
`;

const Content = styled.div`
  padding: 2rem 0rem 4rem;
  min-height: 100vh;
`;

export default SidePanelAlt;
