export const black = "#000000";
export const dark = "#0d0c22";
export const primary = "#8F8FFF";
export const secondary = "#3B3882";
export const secondaryLight = "#5fbceb";
export const white = "#FFFFFF";
export const textBlack = "#2A364E";
// export const backgroundLight = '#eaf3ff';
export const backgroundLight = "#f7f8fa";
export const backgroundGrey = "#DFDFDF";
export const backgroundSky = "#eaf3ff";
export const text = "#727190";
export const bg = "#fcfaf8";
export const border = "#E9EAEE";
export const grey = "#757980";

export const darkBlue = "#154ed4";
export const textGrey = "#939BB1";
export const bgLight = "#f8f8f8";
export const textLightBlue = "#8a9fb1";

export const newBorder = "#EEF0F4";

export const textPrimary = "#150A40";
export const textSecondary = "#727190";
export const fillGrey = "#EEF0F4";

export const buttonBlue = "#DCEEFF";
export const buttonBlueText = "#23629D";
export const buttonBlueDark = "#C7B5F6";
export const buttonBlueHover = "#A3CEF6";
export const buttonGreen = "#DAF8E7";
export const buttonGreenText = "#2D4637";
export const buttonGreenDark = "#90DEA7";
export const buttonRed = "#FFE6E9";
export const buttonRedText = "#FE4960";
export const buttonRedDark = "#EA8A93";
export const buttonYellow = "#FFEEDB";
export const buttonYellowText = "#CC6A00";
export const buttonYellowDark = "#CC6A88";
export const buttonCyan = "#D8F1FF";
export const buttonCyanText = "#1E2D36";
export const buttonCyanDark = "#9CCEF4";
export const buttonPurple = "#FED5E3";
export const buttonPurpleText = "#E52E6D";
export const buttonPurpleDark = "#E2648B";
export const buttonOrange = "#FFE1BF";
export const buttonOrangeText = "#150A40";
export const buttonOrangeDark = "#E2648B";
export const buttonGreenVivid = "#C6EE52";
export const buttonGreenVividText = "#150A40";
export const buttonGreenVividDark = "#9ec336";

export const placeholderColor = "#C6CBD4";

export const greenVivid = "#C6EE52";

export const buttonAltGrey = "#F7F8FA";
export const buttonAltPrimary = "#F3EDFF";
export const buttonAltGreen = "#DAF8E7";
export const buttonAltYellow = "#FFEEDB";
export const buttonAltSuccess = "#C6EE52";
export const buttonAltRed = "#FFE6E9";
export const buttonAltBlue = "#DCEEFF";

export const buttonAltYellowDark = "#CC6A00";

export const greenText = "#03A449";

export const buttonRedNew = "#FF8190";
export const newDarkBlue = "#222B3C";

export const buttonLightPurple = "#F3EDFF";
export const buttonLightPurpleText = "#171737";
export const buttonLightPurpleDark = "#C7B5F6";

export const tooltipPrimary = "#150A40";
