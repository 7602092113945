import React, { useState } from "react";
import {
  Flex,
  Heading,
  Text,
  Button,
  Stack,
  IconButton,
  Avatar,
  AvatarBadge,
} from "@chakra-ui/react"; // Add these imports
import { useDispatch } from "react-redux";
import { cancelAllTreatments } from "../../../../../actions/userActions";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import {
  white,
  backgroundLight,
  textPrimary,
  textSecondary,
  newBorder,
  buttonBlue,
  buttonBlueText,
  secondary,
} from "../../../../../utils/colors";
import StatusButton from "../../../../../components/buttons/StatusButton";
import MoreButton from "../../../../../components/buttons/MoreButton";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
import EditEmployee from "../../sidepanels/EditEmployee";

const EmployeeHeader = ({ userInfo, user, close, isAdmin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cancelAllTreatmentsHandler = () => {
    dispatch(cancelAllTreatments(user._id));
  };

  console.log("userInfo", userInfo);

  const isCurrentUser = userInfo._id === user._id;

  const [sidePanelModalOpen, setSidePanelModalOpen] = useState(false);

  const openSidePanelModalHandler = () => {
    setSidePanelModalOpen(true);
  };

  const closeSidePanelModal = () => {
    setSidePanelModalOpen(false);
  };

  return (
    <>
      {/* SIDEPANELS  */}
      <SidePanel
        isTop
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("patient.createTreatmentPlan")}
        noBorder={true}
      >
        <EditEmployee userId={user._id} close={closeSidePanelModal} />
      </SidePanel>

      <Flex
        align="center"
        justify="space-between"
        p="1.75rem 2rem"
        borderBottom={`1px solid ${newBorder}`}
        position="fixed"
        width="100%"
        bg={white}
        zIndex="1000"
      >
        <Flex align="center">
          <IconButton
            bg="none"
            border={`1px solid ${newBorder}`}
            color={textSecondary}
            onClick={close}
            icon={<FaArrowRightFromBracket />}
            borderRadius="0.75rem"
            fontSize="0.75rem"
          />
          <Avatar
            h="3rem"
            w="3rem"
            src={user.image}
            name={`${user.lastName} + ${user.firstName}`}
            ml={4}
          >
            {isCurrentUser && (
              <AvatarBadge borderColor="white" bg="green" boxSize="0.875em" />
            )}
          </Avatar>
          <Stack gap={2}>
            <Heading
              ml={2}
              color={textPrimary}
              fontSize="1.275rem"
              fontWeight="600"
              mb={0}
              lineHeight="1"
            >
              {user.lastName} {user.firstName}
            </Heading>
            <Text
              ml={2}
              color={textSecondary}
              fontSize="sm"
              fontWeight="500"
              lineHeight="1"
            >
              {user.profession && user.profession.name && user.profession.name}
            </Text>
          </Stack>
        </Flex>

        <Flex align="center">
          {/* <StatusButton
            isUnclickable
            currentStatus={user.status}
            options="user"
          /> */}

          <MoreButton
            id={user._id}
            editHandler={(e) => {
              e.stopPropagation();
              openSidePanelModalHandler();
            }}
            editButtonDisabled={!isAdmin}
            // cancelHandler={cancelAllTreatmentsHandler}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default EmployeeHeader;
