import React from 'react';
import { Box, Flex, Text, Button, Input } from '@chakra-ui/react';
import CitySelect from '../../../../../components/select/CitySelect';
import StatusSelect from '../../../../../components/select/StatusSelect';
import {
  textSecondary,
  white,
  newBorder,
  backgroundLight,
  buttonRed,
  buttonRedText,
  buttonBlue,
  buttonBlueText,
  buttonGreen,
  buttonGreenText,
} from '../../../../../utils/colors';
import {
  FaRegCircleCheck,
  FaRegCircleXmark,
  FaRegCircleDot,
  FaCircleHalfStroke,
} from 'react-icons/fa6';

const CourseFilters = ({
  searchCity,
  handleCitySelectChange,
  searchStatus,
  handleStatusSelectChange,
  filterStartDate,
  setFilterStartDate,
  filterEndDate,
  setFilterEndDate,
  handleApplyDateRange,
  handleClearFilters,
  isOpen,
  translate,
}) => {
  const statusOptions = [
    {
      value: 'draft',
      label: translate('status.draft'),
      icon: FaCircleHalfStroke,
      bg: backgroundLight,
      color: textSecondary,
      borderColor: newBorder,
      bgHover: '#ddd',
    },
    {
      value: 'upcoming',
      label: translate('status.upcoming'),
      icon: FaRegCircleDot,
      bg: buttonBlue,
      color: buttonBlueText,
      borderColor: buttonBlue,
      bgHover: '#b6cce1',
    },
    {
      value: 'cancelled',
      label: translate('status.cancelled'),
      icon: FaRegCircleXmark,
      bg: buttonRed,
      color: buttonRedText,
      borderColor: buttonRed,
      bgHover: '#dda3a3',
    },
    {
      value: 'completed',
      label: translate('status.completed'),
      icon: FaRegCircleCheck,
      bg: buttonGreen,
      color: buttonGreenText,
      borderColor: buttonGreen,
      bgHover: '#9ebfc2',
    },
  ];

  return (
    <>
      <Flex align="center">
        <Text color={textSecondary} mr={4} fontWeight="500">
          Filter by:
        </Text>
        <Box mr={4}>
          <CitySelect
            value={searchCity}
            onChange={handleCitySelectChange}
            newBorder={newBorder}
            textSecondary={textSecondary}
            white={white}
          />
        </Box>
        <Box mr={4}>
          <StatusSelect
            value={searchStatus}
            onChange={handleStatusSelectChange}
            options={statusOptions}
            translate={translate}
          />
        </Box>
        <Flex align="center">
          <Input
            mr={2}
            size="lg"
            type="date"
            maxWidth="170px"
            borderRadius="0.75rem"
            label="filterStartDate"
            fontSize="0.875rem"
            fontWeight="500"
            color={textSecondary}
            value={filterStartDate}
            name="filterStartDate"
            placeholder="enter filterStartDate"
            onChange={(e) => setFilterStartDate(e.target.value)}
          />
          <Input
            size="lg"
            mr={2}
            type="date"
            maxWidth="170px"
            borderRadius="0.75rem"
            fontSize="0.875rem"
            fontWeight="500"
            color={textSecondary}
            label="filterEndDate"
            value={filterEndDate}
            name="filterEndDate"
            placeholder="enter endDate"
            onChange={(e) => setFilterEndDate(e.target.value)}
          />
          <Button
            size="lg"
            bg={backgroundLight}
            color={textSecondary}
            fontSize="0.875rem"
            borderRadius="0.75rem"
            onClick={handleApplyDateRange}
            minWidth="120px"
          >
            Apply Date
          </Button>
        </Flex>
      </Flex>

      <Flex>
        <Button
          size="lg"
          bg={buttonRed}
          color={white}
          fontSize="0.875rem"
          borderRadius="0.75rem"
          onClick={handleClearFilters}
          minWidth="120px"
          ml={4} // Add some margin
        >
          Clear Filters
        </Button>
      </Flex>
    </>
  );
};

export default CourseFilters;
