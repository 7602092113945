import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Flex,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { TbCurrencyEuro } from "react-icons/tb";
import {
  textPrimary,
  textSecondary,
  newBorder,
  primary,
  backgroundLight,
  white,
} from "../../utils/colors";
import SubtaskPriceButton from "../buttons/SubtaskPriceButton";
import { addCourseTaskSubtask } from "../../actions/courseActions";
import { FaXmark } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const AddSubtask = ({
  isOpen,
  onToggle,
  maxAttendees,
  courseId,
  taskId,
  setSubtasks,
  totalPrice,
  setTotalPrice,
}) => {
  const { t } = useTranslation();

  // STATE
  const [subtaskTitle, setSubtaskTitle] = useState("");
  const [subtaskPrice, setSubtaskPrice] = useState("");
  const [subtaskPriceOption, setSubtaskPriceOption] = useState({
    value: "one",
    label: t("task.one"),
  });
  const [subtaskQty, setSubtaskQty] = useState("1");
  const [isQuantityOpen, setIsQuantityOpen] = useState(false);

  // REDUX
  const dispatch = useDispatch();
  const courseTaskSubtaskAdd = useSelector(
    (state) => state.courseTaskSubtaskAdd
  );
  const { loading, error, success, subtask } = courseTaskSubtaskAdd;

  // HANDLERS
  const handleChangeOption = (option) => {
    setSubtaskPriceOption(option);

    // Automatically toggle the visibility of the quantity input
    // based on the selected pricing option
    if (option.value === "quantity") {
      setIsQuantityOpen(true); // Show quantity input when 'quantity' is selected
    } else {
      setIsQuantityOpen(false); // Hide quantity input for other options
    }
  };

  const handleQuantityMaxAttendees = () => {
    setSubtaskQty(maxAttendees);
  };

  // SUBMIT
  const handleAddSubtask = async () => {
    if (!subtaskTitle.trim() || isNaN(subtaskPrice) || subtaskPrice <= 0) {
      // Handle invalid input appropriately
      console.error("Invalid input for subtask");
      return; // Prevent adding the subtask
    }

    await dispatch(
      addCourseTaskSubtask(
        courseId,
        taskId,
        subtaskTitle,
        subtaskPrice,
        subtaskQty
      )
    );

    setTotalPrice(totalPrice + subtaskPrice * subtaskQty); // Update total price

    setSubtaskTitle(""); // Reset title input field after adding
    setSubtaskPrice(""); // Reset price input field after adding
    setSubtaskQty(1); // Reset qty input field after adding
    onToggle(); // Close the input fields
  };

  useEffect(() => {
    if (success && subtaskTitle && subtaskPrice && subtaskQty) {
      console.log("Subtask ", subtask);

      const subtaskId = subtask.subtask?._id; // Access the _id like this

      if (subtaskId) {
        // Make sure subtaskId is not undefined
        setSubtasks((prev) => [
          ...prev,
          {
            title: subtaskTitle,
            price: subtaskPrice,
            quantity: subtaskQty,
            _id: subtaskId,
          },
        ]);

        console.log("ID HERE", subtaskId);
      }
    }
  }, [success, subtaskTitle, subtaskPrice, subtaskQty, subtask, setSubtasks]);

  // console.log("AddSubtask rendered");
  // console.log("subtaskPriceOption", subtaskPriceOption);
  // console.log("subtaskQty", subtaskQty);
  // console.log("subtaskPrice", subtaskPrice);
  // console.log("subtaskTitle", subtaskTitle);

  console.log("total price", totalPrice);

  return (
    <>
      {isOpen && (
        <Flex direction="column" w="100%">
          <Flex
            w="100%"
            align="center"
            justify="center"
            p="0rem 0 0"
            position="relative"
          >
            <Text
              fontSize="sm"
              color={textPrimary}
              fontWeight="600"
              mb="2"
              textAlign="center"
            >
              {t("task.addSubtask")}
            </Text>
            <Button
              position="absolute"
              right="0"
              size="xs"
              top="0"
              bg={white}
              border={`0px solid ${newBorder}`}
              onClick={onToggle}
              borderRadius="0.875rem"
              fontSize="13px"
              _hover={{ bg: backgroundLight }}
            >
              <Icon as={FaXmark} color={textSecondary} fontSize="xs" />
            </Button>
          </Flex>
          <Flex align="center" direction="column" w="100%">
            <Input
              placeholder={t("task.subtaskTitle")}
              value={subtaskTitle}
              onChange={(e) => setSubtaskTitle(e.target.value)}
              size="md"
              mb="2"
              border={`1px solid ${newBorder}`}
              borderRadius="0.875rem"
              w="100%"
              fontSize="sm"
            />
            <Flex align="center" justify="space-between" mb="2" w="100%">
              <InputGroup
                border={`1px solid ${newBorder}`}
                borderRadius="0.875rem"
                w="100%"
              >
                <InputLeftElement pointerEvents="none">
                  <Icon
                    as={TbCurrencyEuro}
                    color={textSecondary}
                    fontSize="sm"
                  />
                </InputLeftElement>
                <Input
                  pl="2rem"
                  placeholder={t("common.price")}
                  value={subtaskPrice} // Ensure you have a state for subtaskPrice
                  onChange={(e) => setSubtaskPrice(e.target.value)} // Ensure you have a handler for setting subtaskPrice
                  type="number"
                  size="md"
                  border={`0px solid ${newBorder}`}
                  borderRadius="0.875rem"
                  w="100%"
                  fontSize="sm"
                />
              </InputGroup>

              <SubtaskPriceButton
                currentOption={subtaskPriceOption}
                onChangeOption={handleChangeOption}
              />
            </Flex>

            {isQuantityOpen && (
              <Flex align="center" justify="space-between" w="100%" pb={4}>
                <Flex align="center">
                  <Button
                    mr={2}
                    size="xs"
                    onClick={() =>
                      setSubtaskQty((prev) =>
                        Math.max(0, parseInt(prev, 10) - 1).toString()
                      )
                    } // Decrement
                  >
                    -
                  </Button>
                  <InputGroup
                    w="110px"
                    border={`1px solid ${newBorder}`}
                    borderRadius="0.875rem"
                  >
                    <InputLeftElement pointerEvents="none">
                      <Text
                        fontSize="xs"
                        color={textSecondary}
                        fontWeight="500"
                      >
                        Qty
                      </Text>
                    </InputLeftElement>
                    <Input
                      placeholder=""
                      value={subtaskQty}
                      onChange={(e) => setSubtaskQty(e.target.value)}
                      type="number"
                      size="md"
                      border={`0px solid ${newBorder}`}
                      borderRadius="0.875rem"
                      w="100%"
                      fontSize="sm"
                    />
                  </InputGroup>
                  <Button
                    ml={2}
                    size="xs"
                    onClick={() =>
                      setSubtaskQty((prev) =>
                        (parseInt(prev, 10) + 1).toString()
                      )
                    } // Increment
                  >
                    +
                  </Button>
                </Flex>

                <Button
                  variant="link"
                  size="xs"
                  color={primary}
                  onClick={handleQuantityMaxAttendees}
                >
                  <Text
                    fontSize="xs"
                    color={primary}
                    fontWeight="500"
                    w="100%"
                    isTruncated={false}
                    whiteSpace="wrap"
                  >
                    {t("task.maxAttendees")}
                  </Text>
                </Button>
              </Flex>
            )}
          </Flex>

          <Button
            w="80%"
            size="md"
            fontSize="sm"
            onClick={handleAddSubtask}
            borderRadius="0.875rem"
            margin="0 auto 1rem"
            p="0 2rem"
          >
            {t("task.addSubtask")}
          </Button>
        </Flex>
      )}
      <Flex w="100%" align="center" justify="center" p="0rem 0 0.5rem">
        <Button
          size="sm"
          onClick={onToggle}
          bg={backgroundLight}
          color={textPrimary}
          borderRadius="0.875rem"
          fontSize="13px"
        >
          {isOpen ? (
            <Icon as={FaXmark} color={textSecondary} fontSize="xs" />
          ) : (
            t("task.addSubtask")
          )}
        </Button>
      </Flex>
    </>
  );
};

export default AddSubtask;
