import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  listServices,
  deleteService,
  updateServiceStatus,
} from "../../../../actions/serviceActions";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import { Box, Select, Input } from "@chakra-ui/react";
import ToastComponent from "../../../../components/partials/ToastComponent";
import { columns } from "../columns/ServiceListColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import {
  SERVICE_DELETE_RESET,
  SERVICE_UPDATE_RESET,
  SERVICE_CREATE_RESET,
} from "../../../../constants/serviceConstants";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import CreateService from "../sidepanels/CreateService";
import EditService from "../sidepanels/EditService";
import { listServiceGroups } from "../../../../actions/serviceGroupActions";
import {
  white,
  primary,
  textPrimary,
  textSecondary,
  newBorder,
  backgroundLight,
} from "../../../../utils/colors";
import IsFilterActive from "../../../../components/partials/IsFilterActive";
import ServicesStats from "../partials/ServicesStats";
import { SERVICE_UPDATE_STATUS_RESET } from "../../../../constants/serviceConstants";
import useRoleCheck from "../../../../hooks/useRoleCheck";

const ServiceList = () => {
  // GENERAL
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // ROLES
  const isDoctor = useRoleCheck("employee_doctor");
  const isAdmin = useRoleCheck("admin");

  const [selectedGroup, setSelectedGroup] = useState("");

  // PAGINATION
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [4, 10, 25, 100];

  const hardcodedServices = [];

  // SEARCH
  const [searchTitle, setSearchTitle] = useState("");

  // SIDEPANELS
  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);

  // CONSTANTS
  const [serviceId, setServiceId] = useState("");

  // REDUX
  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  const serviceList = useSelector((state) => state.serviceList);
  const {
    loading,
    error,
    services,
    pages,
    totalCount,
    countsByStatus,
    totalAveragePrice,
    totalAverageDuration,
    globalTotalAveragePrice,
    globalTotalAverageDuration,
  } = serviceList;

  const serviceCreate = useSelector((state) => state.serviceCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    service: createdService,
  } = serviceCreate;

  const serviceUpdate = useSelector((state) => state.serviceUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = serviceUpdate;

  const serviceDelete = useSelector((state) => state.serviceDelete);

  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = serviceDelete;

  const serviceGroupList = useSelector((state) => state.serviceGroupList);
  const { serviceGroups } = serviceGroupList;

  const serviceUpdateStatus = useSelector((state) => state.serviceUpdateStatus);
  const {
    loading: loadingUpdateStatus,
    error: errorUpdateStatus,
    success: successUpdateStatus,
  } = serviceUpdateStatus;

  // FILTERING
  const [isFilterActive, setIsFilterActive] = useState(false);

  const filterConfig = {
    patients: false,
    services: true,
  };

  const [filterParams, setFilterParams] = useState({
    minPrice: "",
    maxPrice: "",
    minDuration: "",
    maxDuration: "",
    status: [],
    serviceGroup: [],
  });

  // SORTING
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(
      listServices(
        searchTitle,
        1,
        pageSize,
        sortField,
        sortOrder,
        false,
        filterParams.minPrice,
        filterParams.maxPrice,
        filterParams.minDuration,
        filterParams.maxDuration,
        filterParams.status,
        filterParams.serviceGroup
      )
    );
  };

  const handleFilterApply = (filters) => {
    setFilterParams(filters);
    setPage(1);
    setIsFilterActive(true);
    localStorage.setItem("filterSettings_services", JSON.stringify(filters)); // Example for PatientList
    dispatch(
      listServices(
        searchTitle,
        1,
        pageSize,
        sortField,
        sortOrder,
        false, // Assuming `fetchAll` is false by default
        filters.minPrice,
        filters.maxPrice,
        filters.minDuration,
        filters.maxDuration,
        filters.status,
        filters.serviceGroup
      )
    );

    // console.log("filters", filters);
  };

  // FILTER INACTIVE
  // Function to filter services based on role
  const filterServicesByRole = (services) => {
    if (isAdmin) {
      // Admins see all services
      return services;
    } else {
      // Non-admins see only active services
      return services.filter((service) => service.status === "active");
    }
  };

  // HANDLERS

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const deleteServiceHandler = (serviceId) => {
    console.log("click");
    dispatch(deleteService(serviceId));
    toastComponent.showToast(t("toasts.serviceDelete"), "success", 3000);
    dispatch({ type: SERVICE_DELETE_RESET });

    if (services.length === 1) {
      setPage(page - 1);
    }
  };

  // USEEFFECT

  // useEffect(() => {
  //   dispatch(listServiceGroups({ fetchAll: true }));
  // }, [dispatch]);

  useEffect(() => {
    console.log("trying to access saved filters");
    const savedFilters = localStorage.getItem("filterSettings_services");
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      setFilterParams(filters);
      setIsFilterActive(true);
    }
    console.log("savedFilters", savedFilters);
  }, []);

  useEffect(() => {
    // USEEFFECT LOGIN
    if (!userInfo) {
      navigate("/login");
    }

    // USEEFFECT CREATE
    if (successCreate) {
      toastComponent.showToast(t("toasts.serviceCreate"), "success", 3000);
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");

      dispatch({ type: SERVICE_CREATE_RESET });

      dispatch(
        listServices(
          searchTitle,
          page,
          pageSize,
          sortField,
          sortOrder,
          false,
          filterParams.minPrice,
          filterParams.maxPrice,
          filterParams.minDuration,
          filterParams.maxDuration,
          filterParams.status,
          filterParams.serviceGroup
        )
      );
    }

    // USEEFFECT UPDATE
    else if (successUpdate) {
      toastComponent.showToast(t("toasts.servicesUpdate"), "success", 3000);
      closeEditSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch({ type: SERVICE_UPDATE_RESET });

      dispatch(
        listServices(
          searchTitle,
          page,
          pageSize,
          sortField,
          sortOrder,
          false,
          filterParams.minPrice,
          filterParams.maxPrice,
          filterParams.minDuration,
          filterParams.maxDuration,
          filterParams.status,
          filterParams.serviceGroup
        )
      );
    }
    // USEEFFECT UPDATE STATUS
    else if (successUpdateStatus) {
      toastComponent.showToast(
        t("toasts.serviceUpdateStatus"),
        "success",
        3000
      );
      dispatch({ type: SERVICE_UPDATE_STATUS_RESET });

      dispatch(listServiceGroups(searchTitle, page, pageSize));
    }
    // USEEFFECT MAIN
    else {
      dispatch(
        listServices(
          searchTitle,
          page,
          pageSize,
          sortField,
          sortOrder,
          false,
          filterParams.minPrice,
          filterParams.maxPrice,
          filterParams.minDuration,
          filterParams.maxDuration,
          filterParams.status,
          filterParams.serviceGroup
        )
      );
    }
  }, [
    dispatch,
    navigate,
    searchTitle,
    page,
    pageSize,
    successCreate,
    successUpdate,
    successDelete,
    successUpdateStatus,
    sortField,
    sortOrder,
    filterParams,
  ]);

  // SIDEPANEL HANDLERS

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = (value) => {
    setEditSidePanelOpen(true);
    setServiceId(value);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    setServiceId("");
    document.body.classList.remove("no-scroll");
  };
  const closeEditSidePanelModal = () => {
    setEditSidePanelOpen(false);
    setSidePanelOpen(false);
    setServiceId("");
    document.body.classList.remove("no-scroll");
  };

  const handleRowClick = (value) => {
    if (userInfo && userInfo.isAdmin) {
      openEditSidePanelModal(value);
    } else {
      // toastComponent.showToast(
      //   t("toasts.notAuthorized"), // Update this message to a suitable one
      //   "error",
      //   3000
      // );
      console.log("not authorized");
    }
  };

  // LOGS

  // console.log("services", services);
  // console.log("serviceGroups", serviceGroups);

  // console.log("globalTotalAveragePrice", globalTotalAveragePrice);
  // console.log("globalTotalAverageDuration", globalTotalAverageDuration);

  // console.log("totalAveragePrice", totalAveragePrice);
  // console.log("totalAverageDuration", totalAverageDuration);

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>{t("service.helmet")}</title>
        <meta name="description" content="Dentacasa Service List" />
      </Helmet>

      {/* SIDEPANELS  */}
      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("service.createService")}
        noBorder={true}
      >
        {isAdmin && <CreateService close={closeSidePanelModal} />}
      </SidePanel>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanelModal}
        title={t("service.editService")}
        noBorder={true}
      >
        {isAdmin && (
          <EditService close={closeEditSidePanelModal} serviceId={serviceId} />
        )}
      </SidePanel>

      {/* MAIN  */}
      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("service.services")}
                onChange={onChangeSearchTitle}
                placeholder={t("service.search")}
                searchName={searchTitle}
                buttonLabel={t("service.createService")}
                buttonHandler={openSidePanelModal}
                isFilter
                filterLabel={t("patient.filter")}
                filterHandler={handleFilterApply}
                isFilterActive={isFilterActive}
                setIsFilterActive={setIsFilterActive}
                filterConfig={filterConfig}
                savedFilters={filterParams}
                savedFiltersKey="filterSettings_services"
                isDisabledButton={!isAdmin}
              />
            </Box>

            <ServicesStats
              loading={loading}
              stats={countsByStatus}
              total={totalCount}
              averagePrice={totalAveragePrice}
              averageDuration={totalAverageDuration}
              globalTotalAverageDuration={globalTotalAverageDuration}
              globalTotalAveragePrice={globalTotalAveragePrice}
            />

            {isFilterActive && !loading && (
              <IsFilterActive label={t("filter.filterActive")} />
            )}

            <Table.Container
              columns={columns(
                dispatch,
                translate,
                deleteServiceHandler,
                openEditSidePanelModal,
                handleSortChange,
                updateServiceStatus,
                isAdmin
              )}
              // data={services}
              data={filterServicesByRole(services)}
              loading={loading}
              error={error}
              onRowClick={handleRowClick}
              navigate={navigate}
              navigateTo="/service-groups"
              noItemsButtonHandler={openSidePanelModal}
              noItemsTitle={t("noItems.noServices")}
              noItemsDescription={t("noItems.noServicesText")}
              noItemsButtonLabel={t("noItems.noServicesButton")}
              sortField={sortField}
              sortOrder={sortOrder}
            />
            <Table.Pagination
              loading={loading}
              page={page}
              pages={pages}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
              setPage={setPage}
              setPageSize={setPageSize}
              totalCount={services.length}
            />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default ServiceList;
