import {
  TREATMENTSTEP_LIST_REQUEST,
  TREATMENTSTEP_LIST_SUCCESS,
  TREATMENTSTEP_LIST_FAIL,
  TREATMENTSTEP_DETAILS_REQUEST,
  TREATMENTSTEP_DETAILS_SUCCESS,
  TREATMENTSTEP_DETAILS_FAIL,
  TREATMENTSTEP_CREATE_REQUEST,
  TREATMENTSTEP_CREATE_SUCCESS,
  TREATMENTSTEP_CREATE_FAIL,
  TREATMENTSTEP_UPDATE_REQUEST,
  TREATMENTSTEP_UPDATE_SUCCESS,
  TREATMENTSTEP_UPDATE_FAIL,
  TREATMENTSTEP_DELETE_REQUEST,
  TREATMENTSTEP_DELETE_SUCCESS,
  TREATMENTSTEP_DELETE_FAIL,
} from "../constants/treatmentStepConstants";

export const treatmentStepListReducer = (
  state = { treatmentSteps: [] },
  action
) => {
  switch (action.type) {
    case TREATMENTSTEP_LIST_REQUEST:
      return { loading: true, treatmentSteps: [] };
    case TREATMENTSTEP_LIST_SUCCESS:
      return {
        loading: false,
        treatmentSteps: action.payload.treatmentSteps,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case TREATMENTSTEP_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const treatmentStepDetailsReducer = (
  state = { treatmentStep: {} },
  action
) => {
  switch (action.type) {
    case TREATMENTSTEP_DETAILS_REQUEST:
      return { loading: true, ...state };
    case TREATMENTSTEP_DETAILS_SUCCESS:
      return { loading: false, treatmentStep: action.payload };
    case TREATMENTSTEP_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const treatmentStepCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TREATMENTSTEP_CREATE_REQUEST:
      return { loading: true };
    case TREATMENTSTEP_CREATE_SUCCESS:
      return { loading: false, success: true, treatmentStep: action.payload };
    case TREATMENTSTEP_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const treatmentStepUpdateReducer = (
  state = { treatmentStep: {} },
  action
) => {
  switch (action.type) {
    case TREATMENTSTEP_UPDATE_REQUEST:
      return { loading: true };
    case TREATMENTSTEP_UPDATE_SUCCESS:
      return { loading: false, success: true, treatmentStep: action.payload };
    case TREATMENTSTEP_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const treatmentStepDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TREATMENTSTEP_DELETE_REQUEST:
      return { loading: true };
    case TREATMENTSTEP_DELETE_SUCCESS:
      return { loading: false, success: true };
    case TREATMENTSTEP_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
