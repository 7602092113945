import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import {
  textSecondary,
  newBorder,
  white,
  backgroundLight,
  buttonBlue,
  buttonBlueText,
  buttonRed,
  buttonRedText,
  buttonGreen,
  buttonGreenText,
} from '../../utils/colors';
import {
  FaRegCircleCheck,
  FaRegCircleXmark,
  FaRegCircleDot,
  FaCircleHalfStroke,
} from 'react-icons/fa6';

const StatusSelect = ({ value, onChange, options, translate }) => {
  const { t } = useTranslation();

  const statusOptions = [
    {
      value: '',
      label: translate('placeholder.allStatuses'),
      icon: FaRegCircleDot,
      bg: backgroundLight,
      color: textSecondary,
      borderColor: newBorder,
      bgHover: '#ddd',
    },
    {
      value: 'draft',
      label: translate('status.draft'),
      icon: FaCircleHalfStroke,
      bg: backgroundLight,
      color: textSecondary,
      borderColor: newBorder,
      bgHover: '#ddd',
    },
    {
      value: 'upcoming',
      label: translate('status.upcoming'),
      icon: FaRegCircleDot,
      bg: buttonBlue,
      color: buttonBlueText,
      borderColor: buttonBlue,
      bgHover: '#b6cce1',
    },
    {
      value: 'cancelled',
      label: translate('status.cancelled'),
      icon: FaRegCircleXmark,
      bg: buttonRed,
      color: buttonRedText,
      borderColor: buttonRed,
      bgHover: '#dda3a3',
    },
    {
      value: 'completed',
      label: translate('status.completed'),
      icon: FaRegCircleCheck,
      bg: buttonGreen,
      color: buttonGreenText,
      borderColor: buttonGreen,
      bgHover: '#9ebfc2',
    },
  ];

  return (
    <Select
      isSearchable={false}
      value={statusOptions.find((option) => option.value === value)}
      onChange={onChange}
      options={statusOptions}
      placeholder={t('placeholder.allStatuses')}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? newBorder : newBorder,
          color: textSecondary,
          borderRadius: '1rem',
          backgroundColor: white,
          border: `1px solid ${newBorder}`,
          fontSize: '0.938rem',
          fontWeight: '500',
          height: '3rem',
          padding: '0 0.25rem',
          ':focus-visible': {
            borderColor: newBorder,
            boxShadow: `0 0 0 1px ${newBorder}`,
          },
        }),

        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
          const color = textSecondary;

          return { ...provided, opacity, transition, color };
        },
      }}
      components={{
        Option: CustomOption,
      }}
    />
  );
};

const CustomOption = ({ innerProps, label, data }) => (
  <div {...innerProps}>
    <data.icon
      style={{
        backgroundColor: data.bg,
        color: data.color,
        marginRight: '0.5rem',
        borderRadius: '50%',
        padding: '0.15rem',
        display: 'flex',
      }}
    />
    {label}
  </div>
);

export default StatusSelect;
