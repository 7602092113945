import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createTreatmentStep } from "../../../../actions/treatmentStepActions";
import { backgroundLight } from "../../../../utils/colors";
import {
  Button,
  FormControl,
  FormLabel,
  Flex,
  Input,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
} from "../../../../utils/colors";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import InputField from "../../../../components/input/InputField";
import { useTranslation } from "react-i18next";

const CreateTreatmentStep = () => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // TABS
  const [activeTab, setActiveTab] = useState(0);
  const tabsData = [{ label: t("treatmentStep.details") }];

  // CONSTANTS
  const [name, setName] = useState("");
  const [order, setOrder] = useState("");

  // HANDLERS

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createTreatmentStep(name, order));
  };

  // LOGS

  console.log("name", name);
  console.log("order", order);

  return (
    <form onSubmit={submitHandler}>
      <TabsComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabsData}
        isTitleValid={true}
        isProfessionValid={true}
        disabledTabs={[]}
      >
        <TabPanels m="1.5rem 0 0">
          <TabPanel p={0}>
            <FormControl mb={4}>
              <FormLabel fontSize="sm">{t("form.treatmentSetpName")}</FormLabel>
              <Input
                type="text" // Use type "time" for time input
                value={name}
                onChange={(e) => setName(e.target.value)}
                size="lg"
                fontSize="0.938rem"
                fontWeight="500"
                border={`1px solid ${newBorder}`}
                borderRadius="0.75rem"
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel fontSize="sm">{t("form.order")}</FormLabel>
              <Input
                type="text" // Use type "time" for time input
                value={order}
                onChange={(e) => setOrder(e.target.value)}
                size="lg"
                fontSize="0.938rem"
                fontWeight="500"
                border={`1px solid ${newBorder}`}
                borderRadius="0.75rem"
              />
            </FormControl>
          </TabPanel>
          <TabPanel p={0}>test</TabPanel>
        </TabPanels>
        <Flex
          mt="2rem"
          justify="flex-end"
          direction="column"
          borderTop={`1px solid ${newBorder}`}
          p="2rem 0"
        >
          <Flex justify="flex-end">
            <Button
              minWidth="10rem"
              type="submit"
              size="lg"
              bg={primary}
              color={white}
              fontSize="md"
              borderRadius="10rem"
            >
              {t("treatmentStep.createTreatmentStep")}
            </Button>

            {activeTab < 1 && (
              <Button
                minWidth="10rem"
                ml={4}
                bg={backgroundLight}
                size="lg"
                color={textSecondary}
                fontSize="md"
                borderRadius="10rem"
                onClick={() => setActiveTab(activeTab + 1)}
              >
                {t("common.continue")}
              </Button>
            )}
          </Flex>
        </Flex>
      </TabsComponent>
    </form>
  );
};

export default CreateTreatmentStep;
