import React from "react";
import { Box, Divider, Text, AbsoluteCenter } from "@chakra-ui/react";
import { textSecondary } from "../../../../../utils/colors";
import GoogleButton from "../../../../../components/buttons/GoogleButton";
import EmailButton from "../../../../../components/buttons/EmailButton";
import Agreement from "../../../partials/Agreement";
import { useTranslation } from "react-i18next";

const StepZero = ({ handleEmailButtonClick }) => {
  const { t } = useTranslation();

  return (
    <Box w="100%">
      <GoogleButton label={t("common.registerGoogle")} isDisabled />
      <Box position="relative" py={3}>
        <Divider />
        <AbsoluteCenter bg="white" px="8">
          <Text textStyle="sm" whiteSpace="nowrap" color={textSecondary}>
            {t("common.or")}
          </Text>
        </AbsoluteCenter>
      </Box>

      <EmailButton
        onClick={handleEmailButtonClick}
        label={t("common.continueWithEmail")}
      />

      <Agreement />
    </Box>
  );
};

export default StepZero;
