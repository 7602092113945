import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  useToast,
  HStack,
  Input,
  TabPanels,
  TabPanel,
  Text,
  Stack,
  Icon,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  InputGroup,
  InputLeftAddon,
  Circle,
  Tooltip,
  Heading,
} from "@chakra-ui/react";
import {
  newBorder,
  backgroundLight,
  textSecondary,
  white,
  primary,
  textPrimary,
  buttonRedText,
  backgroundSky,
} from "../../../../utils/colors";
import {
  updateCourse,
  listCourseDetails,
  addCourseSpeaker,
  removeCourseSpeaker,
} from "../../../../actions/courseActions";
import CitySelect from "../../../../components/select/CitySelect";
import MiniNoItems from "../../../../components/partials/MiniNoItems";
import { TbPlus } from "react-icons/tb";
import ImageUpload from "../../../../components/input/ImageUpload";
import { useTranslation } from "react-i18next";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import InputField from "../../../../components/input/InputField";
import MultiSpeakerSelectNoInactiveNoCurrent from "../../../../components/select/MultiSpeakerSelectNoInactiveNoCurrent";
import SpeakerList from "./partials/SpeakerList";
import { Editor } from "@tinymce/tinymce-react";
import ToastComponent from "../../../../components/partials/ToastComponent";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlAddress from "../../../../components/input/FormControlAddress";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import FormControlDatePickerRangeDouble from "../../../../components/input/FormControlDatePickerRangeDouble";
import FormControlTimePicker from "../../../../components/input/FormControlTimePicker";
import { shade } from "polished";
import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";

const EditCourse = ({ close, courseId }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();

  const tabsData = [
    { label: t("course.details") },
    { label: t("course.dateTimeLocation") },
    // { label: t('course.speakers') },
    // { label: t('course.tabDescription') },
    // { label: t('course.tabPricing') },
  ];

  const [showAddSpeaker, setShowAddSpeaker] = useState(false);

  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [maximumAttendees, setMaximumAttendees] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [speakers, setSpeakers] = useState([]);
  const [speakerId, setSpeakerId] = useState("");
  const [newlyAddedSpeakers] = useState([]);
  const [customSpeakers, setCustomSpeakers] = useState([]);
  const [updatedSpeakers, setUpdatedSpeakers] = useState([]);
  const [showCreateSpeaker] = useState(false);
  const [prices, setPrices] = useState([]);
  const [free, setFree] = useState(true);

  const [attendees, setAttendees] = useState([]);

  const courseDetails = useSelector((state) => state.courseDetails);
  const { course } = courseDetails;

  const courseSpeakerAdd = useSelector((state) => state.courseSpeakerAdd);
  const { success: successSpeakerAdd } = courseSpeakerAdd;

  const handleShowAddSpeaker = () => setShowAddSpeaker(!showAddSpeaker);
  const [createdSpeakerId] = useState(null);

  // TOUCHED
  const [titleTouched, setTitleTouched] = useState(false);
  const [startDateTouched, setStartDateTouched] = useState(false);
  const [endDateTouched, setEndDateTouched] = useState(false);

  // ERRORS
  const isTitleError = title === "";
  const isStartDateError = startDate === "";
  const isEndDateError = endDate === "";

  // VALIDATION
  const isTitleValid = title.length >= 1;
  const isStartDateValid = startDate !== "";
  const isEndDateValid = endDate !== "";

  // Determine if the Continue button should be disabled
  const isContinueDisabled =
    !isTitleValid || !isStartDateValid || !isEndDateValid;

  const updatePrice = (professionGroupId, newPrice) => {
    // Create a copy of the prices array
    const updatedPrices = [...prices];

    // Find the index of the profession group in the updatedPrices array
    const index = updatedPrices.findIndex(
      (price) => price.professionGroup._id === professionGroupId
    );

    if (index !== -1) {
      // Update the price for the specific profession group
      updatedPrices[index].price = newPrice;
      // Update the state with the new prices
      setPrices(updatedPrices);
    }
  };

  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    // Assuming startDate is already a Date object and startTime is a string in "HH:mm" format.
    let updatedStartDate = new Date(startDate);
    if (startTime) {
      const [hours, minutes] = startTime.split(":").map(Number);
      updatedStartDate.setHours(hours, minutes);
    }

    // If you're also modifying the end date in a similar manner, ensure to adjust it similarly.
    let updatedEndDate = new Date(endDate);
    // Assuming you're setting the end time to the end of the day or another specific time.
    // Adjust this as per your requirement. For example, to set it to the end of the day:
    updatedEndDate.setHours(23, 59, 59);

    const updatedCourse = {
      _id: courseId,
      title: title,
      maximumAttendees: maximumAttendees,
      image: image,
      location: location,
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      city: city,
      street: street,
      zip: zip,
      country: country,
      state: state,
      speakers: updatedSpeakers,
      description: description,
      free: free,
      prices: prices,
      attendees: attendees,
    };

    dispatch(updateCourse(updatedCourse));
  };

  const removeSpeakerHandler = (speakerId) => {
    dispatch(removeCourseSpeaker(courseId, speakerId));

    setUpdatedSpeakers(updatedSpeakers.filter((s) => s !== speakerId));
    setSpeakers(speakers.filter((s) => s._id !== speakerId));

    toastComponent.showToast(t("toasts.speakerRemove"), "success", 3000);
  };

  const addSpeakerHandleNew = (values) => {
    // Get the array of speaker IDs from the values array
    const speakerIds = values.map((speaker) => speaker.value);
    const speakerExists = speakerIds.some((speakerId) =>
      speakers.some((a) => a._id === speakerId)
    );

    if (speakerExists) {
      console.log("SPEAKER EXISTS");
      toastComponent.showToast(t("toasts.speakerError"), "error", 3000);
    } else {
      console.log("speakerIds", speakerIds);
      setUpdatedSpeakers([...updatedSpeakers, ...speakerIds]);

      console.log("after updatedSpeakers", updatedSpeakers);
      dispatch(addCourseSpeaker(courseId, speakerIds));
      toastComponent.showToast(t("toasts.speakerAdd"), "success", 3000);
    }
  };

  // Update the handler to work with date range
  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const formatTime = (date) => {
    if (!date) return "";

    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Pad single digits with leading zeros
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  };

  useEffect(() => {
    if (!course._id || course._id !== courseId) {
      dispatch(listCourseDetails(courseId));
    } else {
      setTitle(course.title);
      setImage(course.image);
      setLocation(course.location);
      setStartDate(new Date(course.startDate));
      setEndDate(new Date(course.endDate));
      const startTimeValue = formatTime(new Date(course.startDate));
      setStartTime(startTimeValue);
      setCity(course?.address[0]?.city || "");
      setStreet(course.address[0]?.street || "");
      setZip(course.address[0]?.zip || "");
      setCountry(course.address[0]?.country || "");
      setState(course.address[0]?.state || "");
      setMaximumAttendees(course.maximumAttendees);
      setSpeakers(course.speakers);
      setUpdatedSpeakers(course.speakers.map((speaker) => speaker._id));
      setDescription(course.description);
      setPrices(course.prices);
      setFree(course.free);
      setAttendees(course.attendees);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [dispatch, courseId, course]);

  useEffect(() => {
    if (createdSpeakerId) {
      console.log("createdSpeakerId", createdSpeakerId);
      setSpeakers([...speakers, createdSpeakerId._id]);
      setCustomSpeakers([
        ...customSpeakers,
        {
          label: createdSpeakerId.name,
          value: createdSpeakerId._id,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdSpeakerId]);

  useEffect(() => {
    if (successSpeakerAdd) {
      dispatch(listCourseDetails(courseId));
      setSpeakerId("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successSpeakerAdd, courseId, dispatch]);

  useEffect(() => {
    if (course._id === courseId) {
      setSpeakers(course.speakers);
    }
  }, [course, courseId]);

  // console.log("course", course);
  // console.log("prices", prices);
  // console.log("free", free);

  console.log("startDate", startDate);
  console.log("endDate", endDate);

  return (
    <>
      <Box position="relative">
        <form
          onSubmit={submitHandler}
          style={{
            height: "100%",
          }}
        >
          <SidePanelHeader close={close} title={t("course.editCourse")} />

          <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="7rem 0 4rem"
            >
              <TabsComponent
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabs={tabsData}
                isTitleValid={isTitleValid & isEndDateValid}
                isProfessionValid={true}
                disabledTabs={[]}
              >
                <TabPanels m="1.5rem 0 0">
                  <TabPanel p={0}>
                    {/* <Heading
                      fontSize="1.125rem"
                      color={textPrimary}
                      fontWeight="600"
                      m="1rem 0"
                    >
                      {t("course.details")}
                    </Heading> */}
                    <Flex
                      direction="column"
                      w="100%"
                      bg={white}
                      borderRadius="1rem"
                      p="0 1.5rem"
                    >
                      <FormControl mb={6}>
                        <FormLabel fontSize="sm">
                          {t("course.courseImage")}
                        </FormLabel>
                        <ImageUpload image={image} setImage={setImage} />
                      </FormControl>

                      <FormControlDefault
                        type="text"
                        isRequired
                        label={t("form.title")}
                        value={title}
                        placeholder={t("placeholder.title")}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setTitleTouched(true);
                        }}
                        onBlur={() => setTitleTouched(true)}
                        isTouched={titleTouched}
                        isError={isTitleError}
                        w="100%"
                        errorMessage={t("formError.titleRequired")}
                      />

                      <FormControlDatePickerRangeDouble
                        isRequired
                        type="date"
                        label={t("form.courseDate")}
                        placeholder={t("placeholder.startDate")}
                        onChange={(start, end) =>
                          handleDateRangeChange(start, end)
                        }
                        startDate={startDate}
                        endDate={endDate}
                        onOpen={() => {}}
                        w="50%"
                        // isTouched={startDate !== "" && endDate !== "" && !isEndDateValid}
                        startDateTouched={startDateTouched}
                        endDateTouched={endDateTouched}
                        isError={isStartDateError || isEndDateError}
                        errorMessage={t("formError.courseDateInvalid")}
                        setStartDateTouched={setStartDateTouched}
                        setEndDateTouched={setEndDateTouched}
                      />

                      <FormControlTimePicker
                        label={t("form.startTime")}
                        value={startTime}
                        onChange={(selectedOption) =>
                          setStartTime(selectedOption?.value)
                        }
                        placeholder={t("placeholder.selectStartTime")}
                      />

                      <FormControl mb={4}>
                        <FormLabel fontSize="sm">
                          {t("form.maximumAttendees")}
                        </FormLabel>
                        <Flex maxWidth="100%" m="0">
                          <Input
                            maxWidth="80px"
                            type="number"
                            placeholder={t("placeholder.maximumAttendees")}
                            value={maximumAttendees}
                            onChange={(e) =>
                              setMaximumAttendees(e.target.value)
                            }
                            size="lg"
                            fontSize="1rem"
                            fontWeight="500"
                            border={`1px solid ${newBorder}`}
                            borderRadius="0.75rem"
                            _focusVisible={{
                              zIndex: "1",
                              borderColor: primary,
                              boxShadow: `0 0 0 1px ${primary}`,
                            }}
                          />
                          <Flex px={8} w="100%" align="center">
                            <Slider
                              defaultValue={60}
                              min={0}
                              max={100}
                              step={1}
                              onChange={(val) => setMaximumAttendees(val)}
                              value={maximumAttendees}
                            >
                              <SliderTrack bg={backgroundLight}>
                                <Box position="relative" right={10} />
                                <SliderFilledTrack bg={primary} />
                              </SliderTrack>

                              <SliderThumb boxSize={6} />
                            </Slider>
                          </Flex>
                        </Flex>
                      </FormControl>
                    </Flex>
                  </TabPanel>

                  <TabPanel p={0}>
                    <FormControlDefault
                      type="text"
                      isRequired={false}
                      label={t("form.courseLocation")}
                      value={location}
                      placeholder={t("placeholder.courseLocation")}
                      onChange={(e) => {
                        setLocation(e.target.value);
                      }}
                      w="100%"
                    />

                    <HStack gap={4} w="100%">
                      <FormControlAddress
                        currentValue={street}
                        label={t("form.address")}
                        onAddressSelect={handleAddressSelect}
                        isRequired={false}
                        errorMessage={t("formError.addressRequired")}
                        w="100%"
                        googleApiKey="AIzaSyCNQfHnntkLgj5Es5V8V0H2BfIetOOZ9yw"
                      />

                      <FormControlDefault
                        type="text"
                        isRequired={false}
                        label={t("form.zip")}
                        value={zip}
                        placeholder={t("placeholder.zip")}
                        onChange={(e) => {
                          setZip(e.target.value);
                        }}
                        w="40%"
                        errorMessage={t("formError.zipRequired")}
                      />
                    </HStack>

                    <HStack gap={4} w="100%">
                      <FormControlDefault
                        type="text"
                        isRequired={false}
                        label={t("form.city")}
                        value={city}
                        placeholder={t("placeholder.city")}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                        w="50%"
                        errorMessage={t("formError.cityRequired")}
                      />

                      <FormControlCountry
                        label={t("form.country")}
                        value={country}
                        onChange={(selectedOption) => {
                          setCountry(
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                        isRequired={false}
                        w="50%"
                        errorMessage={t("formError.countryRequired")}
                      />
                    </HStack>
                  </TabPanel>
                </TabPanels>

                <Flex
                  mt={4}
                  direction="column"
                  borderTop={`1px solid ${newBorder}`}
                  p="2rem 0 0"
                >
                  <Flex justify="space-between">
                    <Tooltip
                      bg={white}
                      borderRadius="1rem"
                      p="1rem"
                      label={
                        <Flex
                          direction="column"
                          p="0rem"
                          justify="center"
                          textAlign="center"
                        >
                          {isTitleError && (
                            <Text
                              fontSize="13px"
                              color="#e53e3e"
                              whiteSpace="nowrap"
                            >
                              * {t("formError.titleRequired")}
                            </Text>
                          )}
                          {isStartDateError && (
                            <Text
                              fontSize="13px"
                              color="#e53e3e"
                              whiteSpace="nowrap"
                            >
                              * {t("formError.courseDateInvalid")}
                            </Text>
                          )}
                        </Flex>
                      }
                      isDisabled={!isContinueDisabled}
                      hasArrow
                      placement="top"
                      fontSize="md"
                    >
                      <Button
                        minWidth="10rem"
                        type="submit"
                        size="lg"
                        bg={primary}
                        color={white}
                        fontSize="md"
                        borderRadius="10rem"
                        isDisabled={isContinueDisabled}
                        _hover={{
                          bg: shade(0.3, primary),
                        }}
                        ml={4}
                      >
                        {t("course.updateCourseButtonLabel")}
                      </Button>
                    </Tooltip>

                    <Flex align="center">
                      {activeTab > 0 && (
                        <Button
                          minWidth="8rem"
                          mr={0}
                          bg={backgroundLight}
                          border={`1px solid ${newBorder}`}
                          size="lg"
                          color={textSecondary}
                          fontSize="md"
                          fontWeight="500"
                          borderRadius="10rem"
                          onClick={() => setActiveTab(activeTab - 1)}
                          isDisabled={isContinueDisabled}
                          _hover={{
                            bg: shade(0.1, backgroundLight),
                          }}
                        >
                          {t("common.back")}
                        </Button>
                      )}

                      {activeTab < 1 && (
                        <Tooltip
                          bg={white}
                          borderRadius="1rem"
                          p="1rem"
                          label={
                            <Flex
                              direction="column"
                              p="0rem"
                              justify="center"
                              textAlign="center"
                            >
                              {isTitleError && (
                                <Text
                                  fontSize="13px"
                                  color="#e53e3e"
                                  whiteSpace="nowrap"
                                >
                                  * {t("formError.titleRequired")}
                                </Text>
                              )}
                              {isStartDateError && (
                                <Text
                                  fontSize="13px"
                                  color="#e53e3e"
                                  whiteSpace="nowrap"
                                >
                                  * {t("formError.courseDateInvalid")}
                                </Text>
                              )}
                            </Flex>
                          }
                          isDisabled={!isContinueDisabled}
                          hasArrow
                          placement="top"
                          fontSize="md"
                        >
                          <Button
                            minWidth="8rem"
                            ml={4}
                            bg={backgroundLight}
                            border={`1px solid ${newBorder}`}
                            size="lg"
                            color={textSecondary}
                            fontSize="md"
                            fontWeight="500"
                            borderRadius="10rem"
                            onClick={() => setActiveTab(activeTab + 1)}
                            isDisabled={isContinueDisabled}
                            _hover={{
                              bg: shade(0.1, backgroundLight),
                            }}
                          >
                            {t("common.continue")}
                          </Button>
                        </Tooltip>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </TabsComponent>
            </Flex>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default EditCourse;
