import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  listUsersEmployees,
  deleteUser,
  updateUserStatus,
} from "../../../../actions/userActions";
import { useNavigate } from "react-router-dom";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import { Box, Button } from "@chakra-ui/react";
import ToastComponent from "../../../../components/partials/ToastComponent";
import { columns } from "../columns/EmployeeListColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import SidePanelLong from "../../../../components/sidepanel/SidePanelLong";
import { motion, AnimatePresence } from "framer-motion";
// import EmployeeSingleLong from "../single/EmployeeSingleLong";
import EditEmployee from "../sidepanels/EditEmployee";
import CreateEmployee from "../sidepanels/CreateEmployee";
import {
  USER_CREATE_EMPLOYEE_RESET,
  USER_UPDATE_STATUS_RESET,
} from "../../../../constants/userConstants";
import EmployeeStats from "../partials/EmployeeStats";
import IsFilterActive from "../../../../components/partials/IsFilterActive";
import EmployeeSingleLong from "../single/EmployeeSingleLong";
import useRoleCheck from "../../../../hooks/useRoleCheck";

const EmployeeList = () => {
  // GENERAL
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // ROLES
  const isDoctor = useRoleCheck("employee_doctor");
  const isAdmin = useRoleCheck("admin");

  // PAGINATION
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [4, 10, 25, 100];

  // FILTER
  const [searchTitle, setSearchTitle] = useState("");

  // SIDEPANELS
  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);
  const [isSidePanelLongOpen, setIsSidePanelLongOpen] = useState(false);
  const [createEmployeeSidePanel, setCreateEmployeeSidePanel] = useState(false);
  const [editEmployeeSidePanel, setEditEmployeeSidePanel] = useState(false);

  // CONSTANTS
  const [userId, setUserId] = useState("");

  // REDUX
  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  const userEmployeesList = useSelector((state) => state.userEmployeesList);
  const {
    loading,
    error,
    employees,
    pages,
    countsByStatus,
    globalTotalCount,
    countsByProfession,
  } = userEmployeesList;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  const userCreateEmployee = useSelector((state) => state.userCreateEmployee);
  const { success: successCreateEmployee } = userCreateEmployee;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { success: successUpdate } = userUpdate;

  const userUpdateStatus = useSelector((state) => state.userUpdateStatus);
  const { success: successUpdateStatus } = userUpdateStatus;

  const normalizeText = (text) => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  // FILTERING
  const [isFilterActive, setIsFilterActive] = useState(false);

  const filterConfig = {
    employees: true,
  };

  const [filterParams, setFilterParams] = useState({
    status: [],
    startDate: "",
    endDate: "",
    profession: [],
  });

  const handleFilterApply = (filters) => {
    setFilterParams(filters);
    setPage(1);
    setIsFilterActive(true);
    localStorage.setItem("filterSettings_employees", JSON.stringify(filters)); // Example for PatientList
    dispatch(
      listUsersEmployees(
        searchTitle,
        1,
        pageSize,
        sortField,
        sortOrder,
        filters.status,
        filters.startDate,
        filters.endDate,
        filters.profession
      )
    );
  };

  // SORTING
  const [sortField, setSortField] = useState("lastName");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);

    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(
      listUsersEmployees(
        searchTitle,
        1,
        pageSize,
        column,
        newSortOrder,
        filterParams.status,
        filterParams.startDate,
        filterParams.endDate,
        filterParams.profession
      )
    );
  };

  // FILTER INACTIVE
  // Function to filter employees based on role
  const filterEmployeesByRole = (employees) => {
    if (isAdmin) {
      // Admins see all employees
      return employees;
    } else {
      // Non-admins see only active employees
      return employees.filter((employee) => employee.status === "active");
    }
  };

  // HANDLERS

  const onChangeSearchTitle = (e) => {
    const searchTitle = normalizeText(e.target.value);
    setSearchTitle(searchTitle);
  };

  const deleteEmployeeHandler = (id) => {
    dispatch(deleteUser(id));
  };

  // SIDEPANEL HANDLERS

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = () => {
    setEditSidePanelOpen(true);

    document.body.classList.add("no-scroll");
  };

  const closeSidePanelLong = () => {
    setIsSidePanelLongOpen(false);
    setUserId("");
  };

  const openCreateEmployeeSidePanelModal = () => {
    setCreateEmployeeSidePanel(true);
    document.body.classList.add("no-scroll");
  };

  const closeCreateEmployeeSidePanelModal = () => {
    setCreateEmployeeSidePanel(false);
    document.body.classList.remove("no-scroll");
  };

  const openEditEmployeeSidePanelModal = (id) => {
    setEditEmployeeSidePanel(true);
    setUserId(id);
    document.body.classList.add("no-scroll");
  };

  const closeEditEmployeeSidePanelModal = () => {
    setEditEmployeeSidePanel(false);
    document.body.classList.remove("no-scroll");
  };

  // USEEFFECT

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }

    if (successDelete) {
      toastComponent.showToast(t("toasts.employeeDelete"), "success", 3000);
      dispatch({ type: "USER_DELETE_RESET" });
      dispatch(
        listUsersEmployees(searchTitle, page, pageSize, sortField, sortOrder)
      );
    } else if (successCreateEmployee) {
      toastComponent.showToast(t("toasts.employeeCreate"), "success", 3000);
      dispatch({ type: "USER_CREATE_EMPLOYEE_RESET" });
      dispatch(
        listUsersEmployees(searchTitle, page, pageSize, sortField, sortOrder)
      );
    } else if (successUpdateStatus) {
      toastComponent.showToast(
        t("toasts.employeeUpdateStatus"),
        "success",
        3000
      );
      dispatch({ type: "USER_UPDATE_STATUS_RESET" });
      dispatch(
        listUsersEmployees(searchTitle, page, pageSize, sortField, sortOrder)
      );
    } else if (successUpdate) {
      toastComponent.showToast(t("toasts.employeeUpdate"), "success", 3000);
      dispatch({ type: "USER_UPDATE_RESET" });
      dispatch(
        listUsersEmployees(searchTitle, page, pageSize, sortField, sortOrder)
      );
    } else {
      dispatch(
        listUsersEmployees(
          searchTitle,
          page,
          pageSize,
          sortField,
          sortOrder,
          filterParams.status,
          filterParams.startDate,
          filterParams.endDate,
          filterParams.profession
        )
      );
    }
  }, [
    dispatch,
    navigate,
    searchTitle,
    page,
    pageSize,
    successDelete,
    successCreateEmployee,
    successUpdate,
    successUpdateStatus,
    sortField,
    sortOrder,
    userInfo,
    filterParams,
  ]);

  const fakeEmployees = [];

  const loggedInUserId = userInfo._id;

  // LOGS

  console.log("employees", employees);
  console.log("userInfo", userInfo);

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>Employee List</title>
        <meta name="description" content="Dentacasa Employee List" />
      </Helmet>

      <AnimatePresence mode="wait">
        {isSidePanelLongOpen && (
          <SidePanelLong close={closeSidePanelLong}>
            <EmployeeSingleLong
              userId={userId}
              close={closeSidePanelLong}
              userInfo={userInfo}
            />
          </SidePanelLong>
        )}
      </AnimatePresence>

      {/* SIDEPANELS  */}

      <SidePanel
        open={createEmployeeSidePanel}
        close={closeCreateEmployeeSidePanelModal}
        title={t("employee.createEmployee")}
        noBorder={false}
      >
        {isAdmin && (
          <CreateEmployee
            defaultRole="employee"
            close={closeCreateEmployeeSidePanelModal}
          />
        )}
      </SidePanel>

      <SidePanel
        open={editEmployeeSidePanel}
        close={closeEditEmployeeSidePanelModal}
        title={t("employee.editEmployee")}
        noBorder={false}
      >
        {isAdmin && (
          <EditEmployee
            userId={userId}
            close={closeEditEmployeeSidePanelModal}
          />
        )}
      </SidePanel>

      {/* MAIN  */}
      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("employee.employees")}
                onChange={onChangeSearchTitle}
                placeholder={t("employee.search")}
                searchName={searchTitle}
                buttonLabel={t("employee.createEmployee")}
                buttonHandler={openCreateEmployeeSidePanelModal}
                isDisabledButton={!isAdmin}
                isFilter
                filterLabel={t("patient.filter")}
                filterHandler={handleFilterApply}
                isFilterActive={isFilterActive}
                setIsFilterActive={setIsFilterActive}
                filterConfig={filterConfig}
                // filterStatusOptions="user" // no need because it uses different form control specific to patient
                savedFilters={filterParams}
                savedFiltersKey="filterSettings_employees"
              />
            </Box>

            <EmployeeStats
              loading={loading}
              countsByStatus={countsByStatus}
              globalTotalCount={globalTotalCount}
              countsByProfession={countsByProfession}
            />

            {isFilterActive && !loading && (
              <IsFilterActive label={t("filter.filterActive")} />
            )}

            <Table.Container
              columns={columns(
                dispatch,
                translate,
                deleteEmployeeHandler,
                openEditEmployeeSidePanelModal,
                handleSortChange,
                updateUserStatus,
                isDoctor,
                isAdmin,
                loggedInUserId
              )}
              // data={employees}
              data={filterEmployeesByRole(employees)}
              loading={loading}
              error={error}
              // onRowClick={(userId) => {
              //   // setIsSidePanelLongOpen(true);

              //   openEditEmployeeSidePanelModal(userId);
              //   setUserId(userId);
              // }}
              onRowClick={(userId) => {
                setIsSidePanelLongOpen(true);
                setUserId(userId);
              }}
              noItemsTitle={t("noItems.noEmployees")}
              noItemsDescription={t("noItems.noEmployeesText")}
              noItemsButtonLabel={t("noItems.noEmployeesButton")}
              noItemsButtonHandler={openCreateEmployeeSidePanelModal}
              sortField={sortField}
              sortOrder={sortOrder}
            />
            <Table.Pagination
              loading={loading}
              page={page}
              pages={pages}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
              setPage={setPage}
              setPageSize={setPageSize}
              totalCount={employees.length}
            />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default EmployeeList;
