import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Icon,
} from "@chakra-ui/react";
import {
  white,
  newBorder,
  textPrimary,
  backgroundLight,
  primary,
  textSecondary,
} from "../../utils/colors";

import { TbDots } from "react-icons/tb";

const FormControlRangePicker = ({
  isRequired,
  label,
  isError,
  isTouched,
  w,
  errorMessage,
  isSmall,
  min,
  max,
  setMin,
  setMax,
  minFilter,
  maxFilter,
  step,
}) => {
  // This function will be called whenever the slider values change
  const handleChange = (values) => {
    setMin(values); // Call the passed function with both values
    setMax(values); // Same as setMin
  };

  return (
    <FormControl
      mb={8}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel
        fontSize={isSmall ? "xs" : "sm"}
        position="relative"
        color={textPrimary}
      >
        {label}

        {isError && isTouched && (
          <Box position="absolute" top={-1} right={0} zIndex={2}>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </Box>
        )}
      </FormLabel>
      <Box w="100%" p="0 1rem">
        <RangeSlider
          defaultValue={[min, max]}
          min={minFilter}
          max={maxFilter}
          step={step}
          onChange={handleChange}
          zIndex="0"
        >
          <RangeSliderTrack bg={backgroundLight}>
            <RangeSliderFilledTrack bg={primary} />
          </RangeSliderTrack>
          <RangeSliderThumb boxSize={6} index={0}>
            <Icon color={textSecondary} as={TbDots} />
            <Text
              fontSize="13px"
              color={textSecondary}
              fontWeight="500"
              position="absolute"
              top="100%"
              mt={2}
            >
              {min}
            </Text>
          </RangeSliderThumb>
          <RangeSliderThumb boxSize={6} index={1}>
            <Icon color={textSecondary} as={TbDots} />
            <Text
              fontSize="13px"
              color={textSecondary}
              fontWeight="500"
              position="absolute"
              top="100%"
              mt={2}
            >
              {max}
            </Text>
          </RangeSliderThumb>
        </RangeSlider>
      </Box>
    </FormControl>
  );
};

export default FormControlRangePicker;
