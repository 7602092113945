import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Button,
  Input,
  Flex,
  Text,
  Stack,
  Select,
  Heading,
  useDisclosure,
  IconButton,
  PopoverFooter,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  Box,
  Image,
  Link,
  Tooltip,
  Icon,
  Divider,
} from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  newBorder,
  primary,
  secondary,
  white,
} from "../../../../../utils/colors";
import { FaXmark } from "react-icons/fa6";
import { icons } from "../../../../../utils/icons";
import { NavLink } from "react-router-dom";
import ToothButton from "../../../../../components/buttons/ToothButton";
import { TbDental } from "react-icons/tb";

const TeethPopoverAlt = ({
  initialTeeth,
  service,
  saveEditedService,
  isEdit,
  isDisabled,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isChanged, setIsChanged] = useState(false); // flag to check if price has been changed

  const [teeth, setTeeth] = useState([]); // 2. Initialize local state for the selected teeth
  const [selectedTeeth, setSelectedTeeth] = useState([]);

  const handleToothClick = (toothNumber) => {
    // Update the selected teeth list
    const updatedSelectedTeeth = selectedTeeth.includes(toothNumber)
      ? selectedTeeth.filter((t) => t !== toothNumber) // Deselect if already selected
      : [...selectedTeeth, toothNumber]; // Select if not already selected

    setSelectedTeeth(updatedSelectedTeeth);

    // Save the updated list
    saveEditedService({
      ...service,
      teeth: updatedSelectedTeeth,
      quantity: updatedSelectedTeeth.length,
      price: service.price,
      totalPrice: updatedSelectedTeeth.length * service.price,
    });
  };

  const handleConfirmClick = () => {
    saveEditedService({
      ...service,
      teeth: selectedTeeth,
      quantity: selectedTeeth.length,
      price: selectedTeeth.length * service.price,
    });
    setIsChanged(false);
    onClose(); // 3. Close the popover after confirming the changes
  };

  const handleClose = () => {
    onClose();
  };

  const renderSelectedTeethButtons1 = () => {
    // Assuming teeth numbers 21 to 28
    const teethNumbers = Array.from({ length: 8 }, (_, i) => 18 - i);
    return teethNumbers.map((toothNumber) => (
      <Button
        key={toothNumber}
        colorScheme={selectedTeeth.includes(toothNumber) ? "blue" : "gray"}
        onClick={() => handleToothClick(toothNumber)}
        m={0}
        size="xs"
        p={0}
        // bg={selectedTeeth.includes(toothNumber) ? primary : "none"}
        bg="none"
        borderRadius="0"
        minWidth="20px"
        color={selectedTeeth.includes(toothNumber) ? textPrimary : "#DFDFDF"}
        // isDisabled={service.priceVariations === true}
        isDisabled={
          isDisabled || // Disable the button if isDisabled prop is true
          (isEdit
            ? service.priceVariations === true
            : service.priceVariations === true)
        }
        // _active={{ bg: "none", color: textPrimary }}
        // _focus={{ bg: "none", color: textPrimary }}
        _hover={{ bg: primary, color: white }}
      >
        {toothNumber}
      </Button>
    ));
  };

  const renderSelectedTeethButtons2 = () => {
    // Assuming teeth numbers 21 to 28
    const teethNumbers = Array.from({ length: 8 }, (_, i) => i + 21);
    return teethNumbers.map((toothNumber) => (
      <Button
        key={toothNumber}
        colorScheme={selectedTeeth.includes(toothNumber) ? "blue" : "gray"}
        onClick={() => handleToothClick(toothNumber)}
        m={0}
        size="xs"
        p={0}
        // bg={selectedTeeth.includes(toothNumber) ? primary : "none"}
        bg="none"
        borderRadius="0"
        minWidth="20px"
        color={selectedTeeth.includes(toothNumber) ? textPrimary : "#DFDFDF"}
        isDisabled={
          isDisabled || // Disable the button if isDisabled prop is true
          (isEdit
            ? service.priceVariations === true
            : service.priceVariations === true)
        }
        _hover={{ bg: primary, color: white }}
      >
        {toothNumber}
      </Button>
    ));
  };

  const renderSelectedTeethButtons3 = () => {
    // Assuming teeth numbers 48 to 41
    const teethNumbers = Array.from({ length: 8 }, (_, i) => 48 - i);
    return teethNumbers.map((toothNumber) => (
      <Button
        key={toothNumber}
        colorScheme={selectedTeeth.includes(toothNumber) ? "blue" : "gray"}
        onClick={() => handleToothClick(toothNumber)}
        m={0}
        size="xs"
        p={0}
        // bg={selectedTeeth.includes(toothNumber) ? primary : "none"}
        bg="none"
        borderRadius="0"
        minWidth="20px"
        color={selectedTeeth.includes(toothNumber) ? textPrimary : "#DFDFDF"}
        isDisabled={
          isDisabled || // Disable the button if isDisabled prop is true
          (isEdit
            ? service.priceVariations === true
            : service.priceVariations === true)
        }
        _hover={{ bg: primary, color: white }}
      >
        {toothNumber}
      </Button>
    ));
  };

  const renderSelectedTeethButtons4 = () => {
    // Assuming teeth numbers 21 to 28
    const teethNumbers = Array.from({ length: 8 }, (_, i) => 31 + i);
    return teethNumbers.map((toothNumber) => (
      <Button
        key={toothNumber}
        colorScheme={selectedTeeth.includes(toothNumber) ? "blue" : "gray"}
        onClick={() => handleToothClick(toothNumber)}
        m={0}
        size="xs"
        p={0}
        // bg={selectedTeeth.includes(toothNumber) ? primary : "none"}
        bg="none"
        borderRadius="0"
        minWidth="20px"
        color={selectedTeeth.includes(toothNumber) ? textPrimary : "#DFDFDF"}
        isDisabled={
          isDisabled || // Disable the button if isDisabled prop is true
          (isEdit
            ? service.priceVariations === true
            : service.priceVariations === true)
        }
        _hover={{ bg: primary, color: white }}
      >
        {toothNumber}
      </Button>
    ));
  };

  useEffect(() => {
    let teethNumbers;
    if (Array.isArray(initialTeeth) && initialTeeth.length > 0) {
      // If the first element is a string and contains a comma, we assume it's a comma-separated list.
      if (
        typeof initialTeeth[0] === "string" &&
        initialTeeth[0].includes(",")
      ) {
        teethNumbers = initialTeeth[0].split(",").map(Number);
      } else {
        // Otherwise, we map over the array and convert each string to a number.
        teethNumbers = initialTeeth.map(Number);
      }
    } else {
      // If initialTeeth is not an array, default to an empty array.
      teethNumbers = [];
    }
    setSelectedTeeth(teethNumbers);
  }, [initialTeeth]);

  // console.log("selectedTeeth", selectedTeeth);
  // console.log("teeth", teeth);

  // console.log("service", service);

  return (
    <>
      {/* <Flex>
        <Button
          key={1}
          colorScheme={selectedTeeth.includes(1) ? "blue" : "gray"}
          onClick={() => handleToothClick(1)}
          m={0}
          p={0}
          fontSize="sm"
          w="initial"
          size="xs"
          bg="none"
        >
          {1}
        </Button>
      </Flex> */}
      {/* <Box display="flex" flexWrap="wrap">
        {renderTeethButtonsAlt()}
      </Box> */}
      <Flex>
        <Flex direction="column">
          <Box display="flex" flexWrap="wrap">
            {renderSelectedTeethButtons1()}
          </Box>
          <Box bg={newBorder} w="160px" h="1px" mx={0} />
          <Box display="flex" flexWrap="wrap">
            {renderSelectedTeethButtons3()}
          </Box>
        </Flex>
        <Box bg={newBorder} h="48px" w="1px" mx={2} />
        <Flex direction="column">
          <Box display="flex" flexWrap="wrap">
            {renderSelectedTeethButtons2()}
          </Box>
          <Box bg={newBorder} w="160px" h="1px" mx={0} />
          <Box display="flex" flexWrap="wrap">
            {renderSelectedTeethButtons4()}
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default TeethPopoverAlt;
