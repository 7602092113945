import React, { useEffect, useState } from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import Select from "react-select";
import {
  white,
  newBorder,
  textPrimary,
  textSecondary,
  primary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { listServiceGroups } from "../../actions/serviceGroupActions";

const FormControlServiceGroup = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
}) => {
  //TRANSLATION
  const { t } = useTranslation();

  // REDUX
  const dispatch = useDispatch();

  const serviceGroupList = useSelector((state) => state.serviceGroupList);
  const { serviceGroups } = serviceGroupList;

  // CONSTANTS
  const [serviceGroupOptions, setServiceGroupOptions] = useState([]);

  // USE EFFECT LOAD SERVICE GROUPS
  useEffect(() => {
    // Dispatch the action with fetchAll set to true
    dispatch(listServiceGroups("", "", "", "", "", true));
  }, [dispatch]);

  // USE EFFECT TRANSFORM SERVICE GROUPS FOR REACT SELECT AND SET OPTIONS, EXCLUDE INACTIVE SERVICE GROUPS
  useEffect(() => {
    if (serviceGroups && serviceGroups.length > 0) {
      const activeServiceGroups = serviceGroups.filter(
        (group) => group.status !== "inactive"
      );
      const transformedServiceGroups = activeServiceGroups.map((group) => ({
        value: group._id,
        label: group.name,
        status: group.status,
      }));
      setServiceGroupOptions(transformedServiceGroups);
    }
  }, [serviceGroups]);

  // LOG
  console.log("serviceGroupOptions", serviceGroupOptions);

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize={isSmall ? "xs" : "sm"} position="relative">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>

      <Select
        isSearchable
        value={serviceGroupOptions.find((option) => option.value === value)}
        onChange={onChange}
        options={serviceGroupOptions}
        placeholder={t("placeholder.selectServiceGroup")}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            color: textSecondary,
            borderRadius: "1rem",
            backgroundColor: `${white}`,
            border: `1px solid ${newBorder}`,
            fontSize: isSmall ? "0.875rem" : "0.938rem",
            fontWeight: "500",
            height: isSmall ? "2.5rem" : "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
          }),

          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            const color =
              state.data.value === "" ? "desired color" : "default color";

            return { ...provided, opacity, transition, color };
          },
        }}
      />
    </FormControl>
  );
};

export default FormControlServiceGroup;
