import React, { useState, useEffect, useRef } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  Input,
  Flex,
  useToast,
  Text,
  Box,
  Heading,
  PopoverHeader,
  IconButton,
  PopoverBody,
  PopoverFooter,
  Icon,
  List,
  ListItem,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import {
  textSecondary,
  textPrimary,
  newBorder,
  white,
  primary,
  buttonBlue,
  buttonRed,
  buttonRedText,
} from "../../../../../utils/colors";
import {
  FaXmark,
  FaCalendarCheck,
  FaCalendar,
  FaRegCalendarCheck,
  FaRegCalendarXmark,
  FaRegCalendar,
} from "react-icons/fa6";
import dayjs from "dayjs"; // import dayjs
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

dayjs.extend(utc);
dayjs.extend(timezone);

const DatePopover3 = ({ initialDate, service, saveEditedService }) => {
  // Parse initialDate to Date object if it exists
  const initialDateObject = initialDate ? dayjs(initialDate).toDate() : "";

  const [date, setDate] = useState(initialDateObject);
  const [tempDate, setTempDate] = useState(date);
  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();

  const selectedTimeRef = useRef(null); // Create a ref for the selected time

  // GENERAL
  const { t } = useTranslation();

  // HANDLERS

  const openPopover = () => {
    setIsOpen(true);
    setTempDate(date); // Set the temporary date to the current date when opening the popover
  };

  const closePopover = () => {
    setIsOpen(false);
    setTempDate(date); // Reset the temporary date back to the actual date when closing without saving
  };

  const handleClose = () => {
    closePopover();
  };
  const handleDateChange = (newDate) => {
    if (newDate instanceof Date) {
      setTempDate(newDate);
    }
  };

  const saveDate = () => {
    if (tempDate) {
      const isoDate = dayjs(tempDate).tz().toISOString(); // Format the date to ISO 8601
      // console.log("ISO 8601 Date:", isoDate);

      saveEditedService({
        ...service,
        appointment: isoDate,
      });
      setIsOpen(false);
      toast({
        title: "Date saved.",
        description: "The new appointment date has been saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Invalid date.",
        description: "Please select a valid date before saving.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  //USE EFFECT

  useEffect(() => {
    if (initialDate) {
      const parsedDate = dayjs(initialDate).toDate(); // Parse ISO 8601 to Date object
      setDate(parsedDate);
      setTempDate(parsedDate);
    }

    if (isOpen) {
      // Timeout to allow the popover content to be rendered.
      setTimeout(() => {
        // Query the selected time list item in the DOM.
        const selectedTimeListItem = document.querySelector(
          ".react-datepicker__time-list-item--selected"
        );
        if (selectedTimeListItem) {
          // Scroll the selected time list item into view.
          selectedTimeListItem.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }
      }, 0);
    }
  }, [initialDate, isOpen]); // Add isOpen as a dependency

  // Get the current date at the start of the day
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to 00:00:00 to start from the beginning of the day

  // When rendering the DatePicker, pass a custom time slot class name and a ref to the selected time
  const renderTimeSlot = (time, selected) => {
    if (selected) {
      return (
        <ListItem
          height="30px"
          ref={selectedTimeRef}
          className="react-datepicker__time-list-item--selected"
        >
          {time}
        </ListItem>
      );
    } else {
      return (
        <ListItem height="30px" className="react-datepicker__time-list-item">
          {time}
        </ListItem>
      );
    }
  };

  // LOGS

  // console.log("initialDate", initialDate);
  // console.log("date", date);

  // console.log("tempDate", tempDate);

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={closePopover}
      placement="right"
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <Button
          onClick={openPopover}
          zIndex="0"
          borderRadius="10rem"
          bg={date ? white : white}
          align="center"
          px={date ? 0 : 0}
          transition="0.2s all"
          size="sm"
          _hover={{
            bg: buttonBlue,
            px: 4,
            transition: "0.2s all",
          }}
        >
          {/* <div></div> */}
          <Flex align="center">
            {date ? (
              <Icon
                as={FaRegCalendarCheck}
                fontSize="0.875rem"
                mr={2}
                position="relative"
                top="-1.5px"
                color={textSecondary}
              />
            ) : (
              <Icon
                as={FaRegCalendarXmark}
                fontSize="0.875rem"
                mr={2}
                position="relative"
                top="-1.5px"
                color={date ? textSecondary : buttonRedText}
              />
            )}
            <Text
              fontSize="0.875rem"
              color={date ? textSecondary : buttonRedText}
              position="relative"
              top="0px"
              fontWeight="500"
              textTransform="capitalize"
            >
              {date
                ? dayjs(date).format("MMMM DD, HH:mm")
                : t("button.noAppointment")}
            </Text>
          </Flex>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        border="none"
        boxShadow="0px 4px 12px 3px rgba(0, 0, 0, 0.20)"
        borderRadius="1rem"
        bg={white}
        w="initial"
      >
        <PopoverHeader borderBottom={`1px solid ${newBorder}`} p="1rem 1.5rem">
          <Flex w="100%" justify="space-between" align="center">
            <Heading fontSize="1rem" fontWeight="500" color={textPrimary}>
              {t("button.setAppointment")}
            </Heading>
            <IconButton
              h="1.5rem"
              w="1rem"
              icon={<FaXmark />}
              bg="none"
              onClick={handleClose}
              color={textSecondary}
            />
          </Flex>
        </PopoverHeader>
        <PopoverBody p="1.5rem 0.75rem 1rem">
          <Box className="popoverDatePicker">
            <DatePicker
              inline
              selected={tempDate}
              onChange={handleDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              dateFormat="yyyy-MM-dd'T'HH:mm" // Specify the format here
              timezones="Europe/Vilnius"
              timeIntervals={5}
              renderTimeSlot={renderTimeSlot}
              fontSize="0.875rem"
              minDate={today}
            />
          </Box>
        </PopoverBody>
        <PopoverFooter p="1rem 1.5rem">
          <Flex w="100%" align="center" justify="flex-end">
            <Button
              variant="outline"
              size="md"
              color={textSecondary}
              borderRadius="10rem"
              onClick={closePopover}
              p="1rem 1.5rem"
            >
              {t("button.cancel")}
            </Button>
            <Button
              size="md"
              bg={primary}
              color={white}
              borderRadius="10rem"
              onClick={saveDate} // 4. Call the handleConfirmClick function when the "Confirm" button is clicked
              p="1rem 1.5rem"
              ml={2}
            >
              {t("button.apply")}
            </Button>
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default DatePopover3;
