export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const USER_CREATE_EMPLOYEE_REQUEST = "USER_CREATE_EMPLOYEE_REQUEST";
export const USER_CREATE_EMPLOYEE_SUCCESS = "USER_CREATE_EMPLOYEE_SUCCESS";
export const USER_CREATE_EMPLOYEE_FAIL = "USER_CREATE_EMPLOYEE_FAIL";
export const USER_CREATE_EMPLOYEE_RESET = "USER_CREATE_EMPLOYEE_RESET";

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "USER_CREATE_FAIL";
export const USER_CREATE_RESET = "USER_CREATE_RESET";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const USER_DELETE_RESET = "USER_DELETE_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_UPDATE_STATUS_REQUEST = "USER_UPDATE_STATUS_REQUEST";
export const USER_UPDATE_STATUS_SUCCESS = "USER_UPDATE_STATUS_SUCCESS";
export const USER_UPDATE_STATUS_FAIL = "USER_UPDATE_STATUS_FAIL";
export const USER_UPDATE_STATUS_RESET = "USER_UPDATE_STATUS_RESET";

export const USER_PATIENTS_LIST_REQUEST = "USER_PATIENTS_LIST_REQUEST";
export const USER_PATIENTS_LIST_SUCCESS = "USER_PATIENTS_LIST_SUCCESS";
export const USER_PATIENTS_LIST_FAIL = "USER_PATIENTS_LIST_FAIL";
export const USER_PATIENTS_LIST_RESET = "USER_PATIENTS_LIST_RESET";

export const USER_EMPLOYEES_LIST_REQUEST = "USER_EMPLOYEES_LIST_REQUEST";
export const USER_EMPLOYEES_LIST_SUCCESS = "USER_EMPLOYEES_LIST_SUCCESS";
export const USER_EMPLOYEES_LIST_FAIL = "USER_EMPLOYEES_LIST_FAIL";

export const USER_ADD_NOTE_REQUEST = "USER_ADD_NOTE_REQUEST";
export const USER_ADD_NOTE_SUCCESS = "USER_ADD_NOTE_SUCCESS";
export const USER_ADD_NOTE_FAIL = "USER_ADD_NOTE_FAIL";
export const USER_ADD_NOTE_RESET = "USER_ADD_NOTE_RESET";

export const USER_LIST_NOTES_REQUEST = "USER_LIST_NOTES_REQUEST";
export const USER_LIST_NOTES_SUCCESS = "USER_LIST_NOTES_SUCCESS";
export const USER_LIST_NOTES_FAIL = "USER_LIST_NOTES_FAIL";
export const USER_LIST_NOTES_RESET = "USER_LIST_NOTES_RESET";

export const USER_CANCEL_ALL_TREATMENTS_REQUEST =
  "USER_CANCEL_ALL_TREATMENTS_REQUEST";
export const USER_CANCEL_ALL_TREATMENTS_SUCCESS =
  "USER_CANCEL_ALL_TREATMENTS_SUCCESS";
export const USER_CANCEL_ALL_TREATMENTS_FAIL =
  "USER_CANCEL_ALL_TREATMENTS_FAIL";
export const USER_CANCEL_ALL_TREATMENTS_RESET =
  "USER_CANCEL_ALL_TREATMENTS_RESET";

export const PATIENT_STATISTICS_REQUEST = "PATIENT_STATISTICS_REQUEST";
export const PATIENT_STATISTICS_SUCCESS = "PATIENT_STATISTICS_SUCCESS";
export const PATIENT_STATISTICS_FAIL = "PATIENT_STATISTICS_FAIL";
export const PATIENT_STATISTICS_RESET = "PATIENT_STATISTICS_RESET";

export const USER_EMPLOYEE_DETAILS_REQUEST = "USER_EMPLOYEE_DETAILS_REQUEST";
export const USER_EMPLOYEE_DETAILS_SUCCESS = "USER_EMPLOYEE_DETAILS_SUCCESS";
export const USER_EMPLOYEE_DETAILS_FAIL = "USER_EMPLOYEE_DETAILS_FAIL";
export const USER_EMPLOYEE_DETAILS_RESET = "USER_EMPLOYEE_DETAILS_RESET";

export const USER_DOCTOR_SERVICES_REQUEST = "USER_DOCTOR_SERVICES_REQUEST";
export const USER_DOCTOR_SERVICES_SUCCESS = "USER_DOCTOR_SERVICES_SUCCESS";
export const USER_DOCTOR_SERVICES_FAIL = "USER_DOCTOR_SERVICES_FAIL";
export const USER_DOCTOR_SERVICES_RESET = "USER_DOCTOR_SERVICES_RESET";

export const USER_ADDRESS_STATES_REQUEST = "USER_ADDRESS_STATES_REQUEST";
export const USER_ADDRESS_STATES_SUCCESS = "USER_ADDRESS_STATES_SUCCESS";
export const USER_ADDRESS_STATES_FAIL = "USER_ADDRESS_STATES_FAIL";
export const USER_ADDRESS_STATES_RESET = "USER_ADDRESS_STATES_RESET";

export const USER_SEND_TREATMENTPLAN_LIST_EMAIL_REQUEST =
  "USER_SEND_TREATMENTPLAN_LIST_EMAIL_REQUEST";
export const USER_SEND_TREATMENTPLAN_LIST_EMAIL_SUCCESS =
  "USER_SEND_TREATMENTPLAN_LIST_EMAIL_SUCCESS";
export const USER_SEND_TREATMENTPLAN_LIST_EMAIL_FAIL =
  "USER_SEND_TREATMENTPLAN_LIST_EMAIL_FAIL";
export const USER_SEND_TREATMENTPLAN_LIST_EMAIL_RESET =
  "USER_SEND_TREATMENTPLAN_LIST_EMAIL_RESET";

export const USER_PATIENTS_SIMPLE_LIST_REQUEST =
  "USER_PATIENTS_SIMPLE_LIST_REQUEST";
export const USER_PATIENTS_SIMPLE_LIST_SUCCESS =
  "USER_PATIENTS_SIMPLE_LIST_SUCCESS";
export const USER_PATIENTS_SIMPLE_LIST_FAIL = "USER_PATIENTS_SIMPLE_LIST_FAIL";
export const USER_PATIENTS_SIMPLE_LIST_RESET =
  "USER_PATIENTS_SIMPLE_LIST_RESET";
