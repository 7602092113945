import { useMemo } from "react";

const useNumberFormatter = (number) => {
  const formattedNumber = useMemo(() => {
    if (number !== undefined && number !== null) {
      return number.toLocaleString(); // Formats the number with commas
    }
    return "";
  }, [number]);

  return formattedNumber;
};

export default useNumberFormatter;
