import React from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import Select from "react-select";
import { newBorder, white, primary, textPrimary } from "../../utils/colors";
import { useTranslation } from "react-i18next";

const FormControlRolePermission = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
}) => {
  const { t } = useTranslation();
  const generateStateOptions = () => {
    let options = [];
    options.push({
      value: "employee_default",
      label: t("rolePermission.employee_default"),
    });
    options.push({
      value: "employee_doctor",
      label: t("rolePermission.employee_doctor"),
    });
    options.push({
      value: "employee_coordinator",
      label: t("rolePermission.employee_coordinator"),
    });
    options.push({
      value: "admin",
      label: t("rolePermission.admin"),
    });

    return options;
  };

  const stateOptions = generateStateOptions();

  // console.log("stateOptions", stateOptions);

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel
        fontSize={isSmall ? "xs" : "sm"}
        position="relative"
        color={textPrimary}
      >
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>

      <Select
        isMenuOpen
        isSearchable
        value={
          value ? stateOptions.find((option) => option.value === value) : null
        }
        onChange={onChange}
        options={stateOptions}
        placeholder={t("placeholder.selectAddressState")}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            borderRadius: "1rem",
            backgroundColor: white,
            border: `1px solid ${newBorder}`,
            fontSize: isSmall ? "0.875rem" : "0.938rem",
            fontWeight: "500",
            height: isSmall ? "2.5rem" : "3rem",
            padding: "0 0.25rem",
            zIndex: 9999,
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
          }),
          option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : "black", // Color of text in options
            backgroundColor: state.isSelected ? primary : white, // Background color of selected option
            ":hover": {
              backgroundColor: primary, // Background color when hovering
              color: "white",
            },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition };
          },
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999, // Use a high value to ensure it's on top of other elements
          }),
          menu: (base) => ({
            ...base,
            zIndex: 1, // Use a high value to ensure it's on top of other elements
            // You can add additional styles if needed
          }),
          menuPosition: "absolute",
        }}
        menuPortalTarget={document.body}
      />
    </FormControl>
  );
};

export default FormControlRolePermission;
