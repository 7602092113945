import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Input,
  Button,
  InputGroup,
  InputLeftElement,
  Icon,
} from "@chakra-ui/react";
import { TbCurrencyEuro } from "react-icons/tb";
import SubtaskPriceButton from "../buttons/SubtaskPriceButton";
import { useTranslation } from "react-i18next";

function EditSubtask({ subtask }) {
  const { t } = useTranslation();
  const [editedTitle, setEditedTitle] = useState(subtask.title);
  const [editedPrice, setEditedPrice] = useState(subtask.price);

  const handleSave = () => {
    console.log("Save subtask");
  };

  return (
    <Flex direction="column" w="100%">
      <Text
        fontSize="sm"
        color="textPrimary"
        fontWeight="600"
        mb="2"
        textAlign="center"
      >
        Edit Subtask
      </Text>
      <Input
        placeholder={t("task.subtaskTitle")}
        value={editedTitle}
        onChange={(e) => setEditedTitle(e.target.value)}
        size="md"
        mb="2"
        border="1px solid newBorder"
        borderRadius="0.875rem"
        w="100%"
        fontSize="sm"
      />
      <Flex align="center" justify="space-between" mb="2" w="100%">
        <InputGroup
          border="1px solid newBorder"
          borderRadius="0.875rem"
          w="100%"
        >
          <InputLeftElement pointerEvents="none">
            <Icon as={TbCurrencyEuro} color="textSecondary" fontSize="sm" />
          </InputLeftElement>
          <Input
            pl="2rem"
            placeholder="Price"
            value={editedPrice}
            onChange={(e) => setEditedPrice(e.target.value)}
            type="number"
            size="md"
            border="0px solid newBorder"
            borderRadius="0.875rem"
            w="100%"
            fontSize="sm"
          />
        </InputGroup>

        {/* <SubtaskPriceButton
          currentOption={subtaskPriceOption}
          onChangeOption={handleChangeOption}
        /> */}
      </Flex>

      <Button onClick={handleSave}>Save</Button>
    </Flex>
  );
}

export default EditSubtask;
