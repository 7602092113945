import {
  PROFESSION_LIST_REQUEST,
  PROFESSION_LIST_SUCCESS,
  PROFESSION_LIST_FAIL,
  PROFESSION_DETAILS_REQUEST,
  PROFESSION_DETAILS_SUCCESS,
  PROFESSION_DETAILS_FAIL,
  PROFESSION_CREATE_REQUEST,
  PROFESSION_CREATE_SUCCESS,
  PROFESSION_CREATE_FAIL,
  PROFESSION_CREATE_RESET,
  PROFESSION_UPDATE_REQUEST,
  PROFESSION_UPDATE_SUCCESS,
  PROFESSION_UPDATE_FAIL,
  PROFESSION_UPDATE_RESET,
  PROFESSION_DELETE_REQUEST,
  PROFESSION_DELETE_SUCCESS,
  PROFESSION_DELETE_FAIL,
  PROFESSION_UPDATE_STATUS_REQUEST,
  PROFESSION_UPDATE_STATUS_SUCCESS,
  PROFESSION_UPDATE_STATUS_FAIL,
  PROFESSION_UPDATE_STATUS_RESET,
} from "../constants/professionConstants";

export const professionListReducer = (
  state = {
    professions: [],
    countsByStatus: {},
    totalCount: 0,
    totalAveragePrice: 0,
    totalAverageDuration: 0,
    globalTotalAveragePrice: 0,
    globalTotalAverageDuration: 0,
  },
  action
) => {
  switch (action.type) {
    case PROFESSION_LIST_REQUEST:
      return { loading: true, professions: [] };
    case PROFESSION_LIST_SUCCESS:
      return {
        loading: false,
        professions: action.payload.professions,
        pages: action.payload.pages,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount,
        countsByStatus: action.payload.countsByStatus,
        totalAveragePrice: action.payload.totalAveragePrice,
        totalAverageDuration: action.payload.totalAverageDuration,
        globalTotalAveragePrice: action.payload.globalTotalAveragePrice,
        globalTotalAverageDuration: action.payload.globalTotalAverageDuration,
      };
    case PROFESSION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const professionDetailsReducer = (
  state = { profession: {} },
  action
) => {
  switch (action.type) {
    case PROFESSION_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PROFESSION_DETAILS_SUCCESS:
      return { loading: false, profession: action.payload };
    case PROFESSION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const professionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFESSION_CREATE_REQUEST:
      return { loading: true };
    case PROFESSION_CREATE_SUCCESS:
      return { loading: false, success: true, profession: action.payload };
    case PROFESSION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PROFESSION_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const professionUpdateReducer = (state = { profession: {} }, action) => {
  switch (action.type) {
    case PROFESSION_UPDATE_REQUEST:
      return { loading: true };
    case PROFESSION_UPDATE_SUCCESS:
      return { loading: false, success: true, profession: action.payload };
    case PROFESSION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PROFESSION_UPDATE_RESET:
      return { profession: {} };

    default:
      return state;
  }
};

export const professionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFESSION_DELETE_REQUEST:
      return { loading: true };
    case PROFESSION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PROFESSION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const professionUpdateStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFESSION_UPDATE_STATUS_REQUEST:
      return { loading: true };
    case PROFESSION_UPDATE_STATUS_SUCCESS:
      return { loading: false, success: true };
    case PROFESSION_UPDATE_STATUS_FAIL:
      return { loading: false, error: action.payload };
    case PROFESSION_UPDATE_STATUS_RESET:
      return {};
    default:
      return state;
  }
};
