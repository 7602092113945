import React from "react";
import {
  Text,
  Stack,
  HStack,
  Box,
  Flex,
  Switch,
  Button,
} from "@chakra-ui/react";
import { textSecondary, textPrimary } from "../../../../utils/colors";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";

export const columns = (
  dispatch,
  translate,
  deleteServiceHandler,
  openEditSidePanelModal,
  updateProfessionGroupStatus,
  handleSortChange,
  isAdmin
) => {
  return [
    // {
    //   Header: "#",
    //   id: "index",
    //   accessor: (_row, i) => {
    //     // Adjust the index based on the page and pageSize
    //     const adjustedIndex = (page - 1) * pageSize + i + 1;
    //     return (
    //       <Text
    //         overflow="hidden"
    //         whiteSpace="nowrap"
    //         fontSize="sm"
    //         color={textSecondary}
    //         fontWeight="500"
    //       >
    //         {adjustedIndex}
    //       </Text>
    //     );
    //   },
    // },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("name")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("serviceGroup.columnName")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "name",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { name } = row.original;

        return (
          <HStack gap={2}>
            <Stack gap={0} width="350px">
              <Text
                fontSize="md"
                color={textPrimary}
                fontWeight="600"
                isTruncated // This will add ellipsis
                noOfLines={1} // This will limit the text to two lines
                whiteSpace="nowrap"
                overflow="hidden"
                w="100%"
                maxWidth="100%"
              >
                {name}
              </Text>
            </Stack>
          </HStack>
        );
      },
    },
    // {
    //   Header: translate("serviceGroup.columnCustomId"),
    //   accessor: (row) => {
    //     const { customId } = row;
    //     return (
    //       <Text
    //         overflow="hidden"
    //         whiteSpace="nowrap"
    //         fontSize="sm"
    //         color={textSecondary}
    //       >
    //         {customId}
    //       </Text>
    //     );
    //   },
    // },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("professions")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>
              {translate("professionGroup.columnProfessionCount")}
            </Text>
          </Flex>
        </Box>
      ),
      accessor: "professions",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { professions } = row.original;

        if (!professions) {
          return (
            <Text
              maxWidth="10px"
              overflow="hidden"
              whiteSpace="nowrap"
              fontSize="sm"
              color={textSecondary}
              fontWeight="500"
            >
              0
            </Text>
          );
        }

        return (
          <Text
            maxWidth="10px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="sm"
            color={textSecondary}
            fontWeight="500"
          >
            {professions.length}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("defaultRolePermission")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>
              {translate("professionGroup.columnDefaultRolePermission")}
            </Text>
          </Flex>
        </Box>
      ),
      accessor: "defaultRolePermission",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { defaultRolePermission } = row.original;

        return (
          <HStack gap={2}>
            <Stack gap={0} maxWidth="350px" overflow="hidden">
              <Text
                fontSize="sm"
                color={textSecondary}
                fontWeight="500"
                isTruncated // This will add ellipsis
                noOfLines={1} // This will limit the text to two lines
                whiteSpace="nowrap"
                overflow="hidden"
                w="100%"
                maxWidth="100%"
              >
                {translate(`rolePermission.${defaultRolePermission}`)}
              </Text>
            </Stack>
          </HStack>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("patient.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { status, _id, name, professions } = row.original;

        // console.log("row", row.original);

        const hasProfessions = professions && professions.length > 0;

        const handleUpdateStatus = (newStatus) => {
          dispatch(updateProfessionGroupStatus(_id, newStatus));
        };

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
              options="active"
              isUnclickable={!isAdmin}
            />
            <MoreButton
              id={_id}
              deleteHandler={deleteServiceHandler}
              deleteName={name}
              warningLabel={
                professions &&
                translate("warning.professionGroupHasProfessions")
              }
              editHandler={(e) => {
                e.stopPropagation();
                openEditSidePanelModal(_id);
              }}
              deleteButtonDisabled={!isAdmin}
              editButtonDisabled={!isAdmin}
            />
          </HStack>
        );
      },
    },
  ];
};
