import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createService } from "../../../../actions/serviceActions";

import {
  Button,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Select,
  HStack,
  Switch,
  Box,
  Heading,
  Checkbox,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  textPrimary,
  backgroundLight,
} from "../../../../utils/colors";
import InputField from "../../../../components/input/InputField";
import { useTranslation } from "react-i18next";
import ServiceGroupSelect from "../../../../components/select/ServiceGroupSelect";
import dayjs from "dayjs";
import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlServiceGroup from "../../../../components/input/FormControlServiceGroup";
import FormControlDuration from "../../../../components/input/FormControlDuration";
import { SERVICE_CREATE_RESET } from "../../../../constants/serviceConstants";

const CreateService = ({ close }) => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // CONSTANTS

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");

  const [serviceGroup, setServiceGroup] = useState();
  const [duration, setDuration] = useState("");
  const [priceVariations, setPriceVariations] = useState(false);
  const [status, setStatus] = useState("active");

  // HANDLERS

  const closeHandler = () => {
    close();
    dispatch({ type: SERVICE_CREATE_RESET });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      createService(
        name,
        price,
        priceVariations,
        duration,
        serviceGroup,
        // products,
        status
      )
    );
  };

  const handlePriceVariationsChange = (e) => {
    setPriceVariations(e.target.checked); // Update based on checkbox state
  };

  // Custom input for minutes
  const customDuration = Math.round(duration / 60); // Convert back to minutes for display

  // TOUCHED
  const [nameTouched, setNameTouched] = useState(false);
  const [priceTouched, setPriceTouched] = useState(false);
  const [serviceGroupTouched, setServiceGroupTouched] = useState(false);
  const [durationTouched, setDurationTouched] = useState(false);

  // ERRORS
  const isNameError = name === "";
  const isPriceError = price === "";
  const isServiceGroupError = serviceGroup === "";
  const isDurationError = duration === "";

  // LOGS

  console.log("name", name);
  console.log("price", price);
  console.log("duration", duration);
  console.log("serviceGroup", serviceGroup);
  console.log("priceVariations", priceVariations);

  return (
    <Box position="relative">
      <form
        onSubmit={submitHandler}
        style={{
          height: "100%",
        }}
      >
        <SidePanelHeader close={close} title={t("service.createService")} />

        <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
          <Flex
            direction="column"
            w="100%"
            align="flex-start"
            justify="center"
            p="7rem 0 4rem"
          >
            <Heading
              fontSize="1.125rem"
              color={textPrimary}
              fontWeight="600"
              m="1rem 0"
            >
              {t("service.details")}
            </Heading>

            <Flex
              direction="column"
              w="100%"
              bg={backgroundLight}
              borderRadius="1rem"
              p="1.5rem"
            >
              <HStack gap={4} w="100%">
                <FormControlDefault
                  type="text"
                  isRequired
                  label={t("form.serviceName")}
                  value={name}
                  placeholder={t("placeholder.serviceName")}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameTouched(true);
                  }}
                  isTouched={nameTouched}
                  isError={isNameError}
                  w="50%"
                  errorMessage={t("formError.serviceNameRequired")}
                />
                <FormControlServiceGroup
                  label={t("form.serviceGroup")}
                  value={serviceGroup}
                  onChange={(selectedOption) => {
                    setServiceGroup(selectedOption.value);
                    setServiceGroupTouched(true);
                  }}
                  isRequired={true} //
                  isTouched={serviceGroupTouched}
                  isError={isServiceGroupError}
                  errorMessage={t("formError.serviceGroupRequired")}
                  w="50%"
                />
              </HStack>
              <HStack mb={4} gap={4} position="relative">
                <FormControlDefault
                  type="number"
                  isRequired
                  label={t("form.price")}
                  value={price}
                  placeholder={t("placeholder.price")}
                  onChange={(e) => {
                    setPrice(e.target.value);
                    setPriceTouched(true);
                  }}
                  isTouched={nameTouched}
                  isError={isNameError}
                  w="50%"
                  errorMessage={t("formError.priceRequired")}
                />
                <Box position="absolute" bottom="-1rem" left="0.5rem">
                  <Checkbox
                    size="sm"
                    colorScheme="green"
                    isChecked={priceVariations} // Connect to state
                    onChange={handlePriceVariationsChange} // Handle change
                  >
                    <Text fontSize="sm" color={textSecondary}>
                      {t("form.priceVariations")}
                    </Text>
                  </Checkbox>
                </Box>

                <FormControlDuration
                  label={t("form.duration")}
                  value={customDuration}
                  placeholder={t("placeholder.duration")}
                  onChange={(selectedOption) => {
                    setDuration(selectedOption.value);
                    setDurationTouched(true);
                  }}
                  isRequired={true}
                  isTouched={durationTouched}
                  isError={isDurationError}
                  errorMessage={t("formError.durationRequired")}
                  w="50%"
                />
              </HStack>
            </Flex>
          </Flex>

          <Flex
            mt={4}
            justify="center"
            direction="column"
            borderTop={`1px solid ${newBorder}`}
            p="1.5rem 0"
            position="relative"
            zIndex={0}
            bg={white}
            bottom="0"
            w="100%"
          >
            <Flex justify="flex-end">
              <Button
                minWidth="10rem"
                mr={4}
                bg={backgroundLight}
                size="lg"
                color={textSecondary}
                fontSize="md"
                borderRadius="10rem"
                onClick={closeHandler}
              >
                {t("common.cancel")}
              </Button>
              <Button
                minWidth="10rem"
                type="submit"
                size="lg"
                bg={primary}
                color={white}
                fontSize="md"
                borderRadius="10rem"
                // isDisabled={!firstName.trim() || !lastName.trim()}
              >
                {t("service.createService")}
              </Button>
            </Flex>
          </Flex>
        </Box>
      </form>
    </Box>
  );
};

export default CreateService;
