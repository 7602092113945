import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Switch,
  FormErrorMessage,
  Flex,
} from "@chakra-ui/react";
import { textPrimary } from "../../utils/colors";

const FormControlSwitch = ({
  label,
  isChecked,
  onChange,
  isTouched,
  isError,
  w,
  errorMessage,
  isSmall,
}) => {
  return (
    <FormControl
      mb={4}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="center"
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel
        htmlFor={label}
        mb="0"
        fontSize={isSmall ? "xs" : "sm"}
        color={textPrimary}
      >
        {label}
      </FormLabel>
      <Flex align="center">
        <Switch
          py={4}
          id={label}
          size={isSmall ? "md" : "lg"}
          isChecked={isChecked}
          onChange={onChange}
        />
      </Flex>
      {isError && isTouched && (
        <Box position="absolute" top={-1} right={0} zIndex={2}>
          <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
        </Box>
      )}
    </FormControl>
  );
};

export default FormControlSwitch;
