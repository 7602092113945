import React, { useState, useEffect } from "react";
import {
  Flex,
  Heading,
  Select,
  Text,
  Button,
  Grid,
  GridItem,
  Box,
  Spinner,
  Icon,
  Stack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  backgroundLight,
  newBorder,
  textPrimary,
  textSecondary,
  primary,
  white,
  buttonRedText,
} from "../../../../utils/colors";
import BoxUser from "./BoxUser";
import BoxTreatmentPlan from "./BoxTreatmentPlan";
import {
  getTreatmentPlanStatistics,
  getTreatmentPlanStatisticsEmployee,
} from "../../../../actions/statisticActions";
import useCountUp from "../../../../hooks/useCountUp";
import { FaCaretDown } from "react-icons/fa6";
import useRoleCheck from "../../../../hooks/useRoleCheck";

const DashboardTreatmentPlans = ({ title }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // ROLES
  const isAdmin = useRoleCheck("admin");
  const isDoctor = useRoleCheck("employee_doctor");

  const statisticTreatmentPlan = useSelector(
    (state) => state.statisticTreatmentPlan
  );
  const { loading, error, statisticTreatmentPlans } = statisticTreatmentPlan;

  const statisticTreatmentPlanEmployee = useSelector(
    (state) => state.statisticTreatmentPlanEmployee
  );
  const {
    loading: loadingEmployee,
    error: errorEmployee,
    statisticTreatmentPlansEmployee,
  } = statisticTreatmentPlanEmployee;

  // useEffect(() => {
  //   dispatch(getTreatmentPlanStatistics());
  //   dispatch(getTreatmentPlanStatisticsEmployee());
  // }, [dispatch]);
  useEffect(() => {
    dispatch(getTreatmentPlanStatistics());
    if (isDoctor) {
      dispatch(getTreatmentPlanStatisticsEmployee());
    }
  }, [dispatch]);

  // // Use the treatment plan data from Redux store
  // const { treatmentPlans } = statisticTreatmentPlans || {};

  // Determine which data set to use based on role
  const dataToUse = isDoctor
    ? statisticTreatmentPlansEmployee
    : statisticTreatmentPlans;
  const isLoading = isDoctor ? loadingEmployee : loading;

  const treatmentPlans = dataToUse?.treatmentPlans || {};

  // Animated counts
  const animatedTotalCount = useCountUp(treatmentPlans?.total || 0);
  const animatedCompletedServiceTotalPrice = useCountUp(
    treatmentPlans?.overallTotalCompletedServicesPrice || 0
  );
  const animatedTotalPrice = useCountUp(treatmentPlans?.overallTotalPrice || 0);
  const animatedTotalCountNoCancelled = useCountUp(treatmentPlans?.total || 0);

  // Animated counts for each status
  const animatedConsultingCount = useCountUp(
    treatmentPlans?.consulting?.count || 0
  );
  const animatedPlanningCount = useCountUp(
    treatmentPlans?.planning?.count || 0
  );
  const animatedApprovedCount = useCountUp(
    treatmentPlans?.approved?.count || 0?.overallTotalPrice
  );
  const animatedOngoingCount = useCountUp(treatmentPlans?.ongoing?.count || 0);
  const animatedCompletedCount = useCountUp(
    treatmentPlans?.completed?.count || 0
  );
  const animatedCancelledCount = useCountUp(
    treatmentPlans?.cancelled?.count || 0
  );

  // LOGS
  console.log("treatmentPlans:", treatmentPlans);

  if (error) return <Text>Error: {error}</Text>;

  return (
    <Flex
      w="100%"
      direction="column"
      p="0rem 1.5rem"
      border={`1px solid ${newBorder}`}
      borderRadius="1rem"
      mb={8}
    >
      <Flex w="100%" justify="space-between" align="center" py={4}>
        <Heading size="sm" fontWeight="600" color={textPrimary}>
          {isDoctor
            ? t("dashboard.treatmentPlansDoctor")
            : t("dashboard.treatmentPlans")}
        </Heading>
        <Button
          size="md"
          borderRadius="10rem"
          border={`1px solid ${newBorder}`}
          bg={white}
          color={textSecondary}
          fontWeight="500"
          fontSize="sm"
          isDisabled
        >
          {t("common.filter")}
          <Icon as={FaCaretDown} ml={1} h="0.75rem" w="0.6rem" />
        </Button>
      </Flex>

      <Flex w="100%" pt={4} pb={8} justify="space-between">
        <Flex w="30%" direction="column" align="center" justify="center">
          {!loading ? (
            <>
              <Stack gap={0} textAlign="center" mb={4}>
                <Heading
                  size="md"
                  fontSize="2rem"
                  fontWeight="600"
                  color={textPrimary}
                  m="0"
                >
                  {animatedTotalCountNoCancelled}
                </Heading>

                <Flex direction="column" align="center" justify="center">
                  <Text color={textSecondary} fontWeight="500" fontSize="xs">
                    {t("common.totalPlans")}{" "}
                  </Text>

                  <Text fontSize="xs" color={textSecondary} fontWeight="400">
                    ({t("common.excluding")}
                    {/* {animatedCancelledCount > 0 && (
                      <Text
                        as="span"
                        color={textSecondary}
                        fontWeight="500"
                        fontSize="xs"
                        ml={1}
                      >
                        {animatedCancelledCount}
                      </Text>
                    )} */}
                    <Text
                      as="span"
                      color={textSecondary}
                      fontWeight="400"
                      fontSize="xs"
                      ml={1}
                    >
                      {t("common.cancelledPlans")})
                    </Text>
                  </Text>
                </Flex>
              </Stack>
              <Stack gap={1} textAlign="center">
                <Heading
                  size="md"
                  fontSize="1.5rem"
                  fontWeight="600"
                  color={textPrimary}
                  m="0"
                >
                  € {animatedTotalPrice}
                </Heading>

                <Text color={textSecondary} fontWeight="500" fontSize="xs">
                  {isDoctor
                    ? t("common.totalServicesCompletedPrice")
                    : t("common.totalServicesPrice")}
                </Text>
              </Stack>
            </>
          ) : (
            <Spinner size="md" color={primary} />
          )}
          {/* <Button bg={primary} color={white} borderRadius="10rem" px={8}>
            {t("common.total")}
          </Button> */}

          <Flex
            w="66%"
            align="center"
            justify="center"
            mt={6}
            borderTop={`1px solid ${newBorder}`}
            py={2}
          >
            <Text color={textSecondary} fontWeight="500" fontSize="xs">
              € {animatedCompletedServiceTotalPrice}{" "}
              {t("common.totalServicesCompletedPrice")}
            </Text>
          </Flex>
        </Flex>
        <Flex w="70%">
          <Grid templateColumns="repeat(3, 1fr)" gap={2} w="100%">
            <GridItem colSpan={1}>
              <BoxTreatmentPlan
                loading={loading}
                status="consulting"
                count={animatedConsultingCount}
                totalPrice={treatmentPlans?.consulting?.totalPrice || 0}
                totalCompletedServicesPrice={
                  treatmentPlans?.consulting?.completedServicesTotalPrice || 0
                }
                isServices={isDoctor}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <BoxTreatmentPlan
                loading={loading}
                status="planning"
                count={animatedPlanningCount}
                totalPrice={treatmentPlans?.planning?.totalPrice || 0}
                totalCompletedServicesPrice={
                  treatmentPlans?.planning?.completedServicesTotalPrice || 0
                }
                isServices={isDoctor}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <BoxTreatmentPlan
                loading={loading}
                status="approved"
                count={animatedApprovedCount}
                totalPrice={treatmentPlans?.approved?.totalPrice || 0}
                totalCompletedServicesPrice={
                  treatmentPlans?.approved?.completedServicesTotalPrice || 0
                }
                isServices={isDoctor}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <BoxTreatmentPlan
                loading={loading}
                status="ongoing"
                count={animatedOngoingCount}
                totalPrice={treatmentPlans?.ongoing?.totalPrice || 0}
                totalCompletedServicesPrice={
                  treatmentPlans?.ongoing?.completedServicesTotalPrice || 0
                }
                isServices={isDoctor}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <BoxTreatmentPlan
                loading={loading}
                status="completed"
                count={animatedCompletedCount}
                totalPrice={treatmentPlans?.completed?.totalPrice || 0}
                totalCompletedServicesPrice={
                  treatmentPlans?.completed?.completedServicesTotalPrice || 0
                }
                isServices={isDoctor}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <BoxTreatmentPlan
                status="cancelled"
                loading={loading}
                count={animatedCancelledCount}
                totalPrice={treatmentPlans?.cancelled?.totalPrice || 0}
                totalCompletedServicesPrice={
                  treatmentPlans?.cancelled?.completedServicesTotalPrice || 0
                }
              />
            </GridItem>
          </Grid>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DashboardTreatmentPlans;
