import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  listTreatmentSteps,
  deleteTreatmentStep,
} from "../../../../actions/treatmentStepActions";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import { Box } from "@chakra-ui/react";
import ToastComponent from "../../../../components/partials/ToastComponent";
import { columns } from "../columns/TreatmentStepListColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import {
  TREATMENTSTEP_DELETE_RESET,
  TREATMENTSTEP_UPDATE_RESET,
  TREATMENTSTEP_CREATE_RESET,
} from "../../../../constants/treatmentStepConstants";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import CreateTreatmentStep from "../sidepanels/CreateTreatmentStep";
import EditTreatmentStep from "../sidepanels/EditTreatmentStep";

const TreatmentStepList = () => {
  // GENERAL
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // PAGINATION
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [4, 10, 25, 100];

  // FILTER
  const [searchTitle, setSearchTitle] = useState("");

  // SIDEPANELS
  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);

  // CONSTANTS
  const [treatmentStepId, setTreatmentStepId] = useState("");

  // REDUX
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const treatmentStepList = useSelector((state) => state.treatmentStepList);
  const { loading, error, treatmentSteps, pages } = treatmentStepList;

  const treatmentStepCreate = useSelector((state) => state.treatmentStepCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    treatmentStep: createdTreatmentStep,
  } = treatmentStepCreate;

  const treatmentStepUpdate = useSelector((state) => state.treatmentStepUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = treatmentStepUpdate;

  const treatmentStepDelete = useSelector((state) => state.treatmentStepDelete);

  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = treatmentStepDelete;
  // HANDLERS

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const deleteTreatmentStepHandler = (treatmentStepId) => {
    console.log("click");
    dispatch(deleteTreatmentStep(treatmentStepId));
    toastComponent.showToast(t("toasts.treatmentStepDelete"), "success", 3000);
    dispatch({ type: TREATMENTSTEP_DELETE_RESET });

    if (treatmentSteps.length === 1) {
      setPage(page - 1);
    }
  };

  // USEEFFECT

  useEffect(() => {
    // USEEFFECT LOGIN
    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    }

    // USEEFFECT CREATE
    if (successCreate) {
      toastComponent.showToast(
        t("toasts.treatmentStepCreate"),
        "success",
        3000
      );
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");

      dispatch({ type: TREATMENTSTEP_CREATE_RESET });

      dispatch(listTreatmentSteps(searchTitle, page, pageSize));
    }
    // USEEFFECT UPDATE
    else if (successUpdate) {
      toastComponent.showToast(
        t("toasts.treatmentStepsUpdate"),
        "success",
        3000
      );
      closeEditSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch({ type: TREATMENTSTEP_UPDATE_RESET });

      dispatch(listTreatmentSteps(searchTitle, page, pageSize));
    }
    // USEEFFECT MAIN
    else {
      dispatch(listTreatmentSteps(searchTitle, page, pageSize));
    }
  }, [
    dispatch,
    navigate,
    searchTitle,
    page,
    pageSize,
    successCreate,
    successUpdate,
    successDelete,
  ]);

  // SIDEPANEL HANDLERS

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = (value) => {
    setEditSidePanelOpen(true);
    setTreatmentStepId(value);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    setTreatmentStepId("");
    document.body.classList.remove("no-scroll");
  };
  const closeEditSidePanelModal = () => {
    setEditSidePanelOpen(false);
    setSidePanelOpen(false);
    setTreatmentStepId("");
    document.body.classList.remove("no-scroll");
  };

  // LOGS

  console.log("treatmentSteps", treatmentSteps);

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>Treatment Step List</title>
        <meta name="description" content="Dentacasa TreatmentStep List" />
      </Helmet>

      {/* SIDEPANELS  */}
      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("treatmentStep.createTreatmentStep")}
        noBorder={true}
      >
        <CreateTreatmentStep />
      </SidePanel>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanelModal}
        title={t("treatmentStep.editTreatmentStep")}
        noBorder={true}
      >
        <EditTreatmentStep treatmentStepId={treatmentStepId} />
      </SidePanel>

      {/* MAIN  */}
      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("treatmentStep.treatmentSteps")}
                onChange={onChangeSearchTitle}
                placeholder={t("treatmentStep.search")}
                searchName={searchTitle}
                buttonLabel={t("treatmentStep.createTreatmentStep")}
                buttonHandler={openSidePanelModal}
              ></Table.Top>
            </Box>

            <Table.Container
              columns={columns(
                dispatch,
                translate,
                deleteTreatmentStepHandler,
                openEditSidePanelModal,
                page,
                pageSize
              )}
              data={treatmentSteps}
              loading={loading}
              error={error}
              navigate={navigate}
              navigateTo="/treatmentStep-groups"
              onRowClick={
                userInfo && userInfo.isAdmin ? openEditSidePanelModal : null
              }
              // noItemsButtonHandler={openSidePanelModal}
              noItemsTitle={t("noItems.noTreatmentSteps")}
              noItemsDescription={t("noItems.noTreatmentStepsText")}
              noItemsButtonLabel={t("noItems.noTreatmentStepsButton")}
            />
            <Table.Pagination
              page={page}
              pages={pages}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default TreatmentStepList;
