import React from 'react';
import styled from 'styled-components';
// import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';

import { icons } from '../../../utils/icons';

const PanelNavigation = ({ buttonOnClick, buttonLabel }) => {
  return (
    <Wrapper>
      <div>
        {/* <Breadcrumbs maxItems={2} aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="#">
            Home
          </Link>
          <Link underline="hover" color="inherit" href="#">
            Catalog
          </Link>
          <Link underline="hover" color="inherit" href="#">
            Accessories
          </Link>
          <Link underline="hover" color="inherit" href="#">
            New Collection
          </Link>
          <Typography color="text.primary">Belts</Typography>
        </Breadcrumbs> */}
      </div>
      <div>
        <button
          label={buttonLabel}
          icon={icons.whiteplus}
          onClick={buttonOnClick}
        ></button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2.5rem;
`;

export default PanelNavigation;
