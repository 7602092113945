import React from "react";
import {
  Text,
  Avatar,
  Stack,
  HStack,
  Image,
  Circle,
  Box,
  Tooltip,
  Icon,
  Progress,
  Flex,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  textSecondary,
  textPrimary,
  buttonBlue,
  buttonBlueText,
  newBorder,
  buttonGreenText,
  buttonRedText,
  white,
  backgroundLight,
  buttonAltPrimary,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";

import { format } from "morgan";
import { FaCircleInfo, FaInfo } from "react-icons/fa6";
import { TbInfoCircle } from "react-icons/tb";

export const columns = (
  dispatch,
  translate,
  deleteServiceHandler,
  openEditSidePanelModal,
  handleSortChange,
  updateServiceStatus,
  isAdmin
) => {
  return [
    // {
    //   Header: "#",
    //   id: "index",
    //   accessor: (_row, i) => {
    //     // Adjust the index based on the page and pageSize
    //     const adjustedIndex = (page - 1) * pageSize + i + 1;
    //     return (
    //       <Text
    //         overflow="hidden"
    //         whiteSpace="nowrap"
    //         fontSize="sm"
    //         color={textSecondary}
    //         fontWeight="500"
    //       >
    //         {adjustedIndex}
    //       </Text>
    //     );
    //   },
    // },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("name")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("service.columnName")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "name",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { name } = row.original;

        // console.log("handleSortChange", handleSortChange);

        return (
          <HStack gap={2}>
            <Stack gap={0} maxWidth="350px" overflow="hidden">
              <Text
                fontSize="15px"
                color={textPrimary}
                fontWeight="600"
                isTruncated // This will add ellipsis
                noOfLines={1} // This will limit the text to two lines
                whiteSpace="nowrap"
                overflow="hidden"
                w="100%"
                maxWidth="100%"
              >
                {name}
              </Text>
            </Stack>
          </HStack>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("price")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("service.columnPrice")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "price",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { price, priceVariations } = row.original;

        // console.log("row.original", row.original);

        return (
          <>
            {priceVariations && priceVariations === true ? (
              <Text fontSize="sm" color={textSecondary} fontWeight="500">
                {price} €
              </Text>
            ) : (
              <Tooltip
                label={translate("tooltip.priceVariations")}
                placement="top"
                hasArrow
              >
                <Flex align="center">
                  <Text fontSize="sm" color={textSecondary} fontWeight="500">
                    {price} €
                  </Text>
                  {/* <Flex
                  ml={2}
                  h="20px"
                  w="20px"
                  bg={buttonBlue}
                  align="center"
                  justify="center"
                  textAlign="center"
                  borderRadius="10rem"
                >
                  <Icon
                    as={TbInfoCircle}
                    w="initial"
                    h="0.5rem"
                    color={buttonBlueText}
                  />
                </Flex> */}
                  <Icon
                    ml={2}
                    as={TbInfoCircle}
                    w="initial"
                    h="1rem"
                    color={primary}
                  />
                </Flex>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("duration")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("service.columnDuration")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "duration",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { duration } = row.original;

        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration - hours * 3600) / 60);
        const seconds = duration - hours * 3600 - minutes * 60;

        let formattedDuration = "";
        if (hours > 0) formattedDuration += `${hours}h `;
        if (minutes > 0) formattedDuration += `${minutes}min `;
        if (seconds > 0) formattedDuration += `${seconds}s`;

        return (
          <Text fontSize="sm" color={textSecondary} fontWeight="500">
            {formattedDuration.trim()}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("serviceGroup")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("service.columnServiceGroup")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "serviceGroup",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { serviceGroup } = row.original;

        const name = serviceGroup ? serviceGroup.name : "";

        // console.log("serviceGroup", serviceGroup);
        return (
          <Text fontSize="sm" color={textSecondary} fontWeight="500">
            {name}
          </Text>
        );
      },
    },
    // {
    //   Header: translate("service.columnServices"),
    //   accessor: (row) => {
    //     console.log("row", row);

    //     return (
    //       <Text fontSize="sm" color={textSecondary} fontWeight="500">
    //         {/* {services.length} */}
    //       </Text>
    //     );
    //   },
    // },
    // {
    //   Header: translate("service.columnCustomId"),
    //   accessor: "customId", // Use the key directly for sorting
    //   Cell: (
    //     { value } // Define how to render the cell
    //   ) => (
    //     <Text
    //       overflow="hidden"
    //       whiteSpace="nowrap"
    //       fontSize="sm"
    //       color={textSecondary}
    //       fontWeight="500"
    //     >
    //       {value}
    //     </Text>
    //   ),
    // },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("patient.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { status, _id } = row.original;

        const handleUpdateStatus = (newStatus) => {
          dispatch(updateServiceStatus(_id, newStatus));
        };

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
              options="active"
              isUnclickable={!isAdmin}
            />
            <MoreButton
              id={_id}
              deleteHandler={deleteServiceHandler}
              deleteButtonDisabled={!isAdmin}
              editHandler={(e) => {
                e.stopPropagation();
                openEditSidePanelModal(_id);
              }}
              editButtonDisabled={!isAdmin}
            />
          </HStack>
        );
      },
    },
  ];
};
