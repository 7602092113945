import React from "react";
import {
  Text,
  Avatar,
  Stack,
  HStack,
  Image,
  Icon,
  Circle,
  Tooltip,
  Box,
  Flex,
  AvatarBadge,
} from "@chakra-ui/react";
import {
  textSecondary,
  textPrimary,
  buttonBlue,
  buttonBlueText,
  primary,
  secondary,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";
import { TbCake } from "react-icons/tb";
import blank from "../../../../assets/icons/blank.svg";
import { shade } from "polished";

export const columns = (
  dispatch,
  translate,
  deleteEmployeeHandler,
  openEditEmployeeSidePanelModal,
  handleSortChange,
  updateUserStatus,
  isDoctor,
  isAdmin,
  loggedInUserId
) => {
  // Function to format phone number
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    // Example format: "+370 610 00129"
    return phoneNumber.replace(/(\+\d{3})(\d{3})(\d+)/, "$1 $2 $3");
  };

  // Function to check if the birthday is within a week
  const isBirthdayInAWeek = (birthDate) => {
    const today = dayjs();
    const birthdayThisYear = dayjs(birthDate).year(today.year());
    return (
      birthdayThisYear.isAfter(today) &&
      birthdayThisYear.isBefore(today.add(1, "week"))
    );
  };

  // Function to calculate upcoming age
  const calculateUpcomingAge = (birthDate) => {
    const thisYear = dayjs().year();
    const birthYear = dayjs(birthDate).year();
    return thisYear - birthYear;
  };

  const allColumns = [
    {
      Header: () => (
        <Box onClick={() => handleSortChange("userID")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("patient.columnID")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "userID",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { userID } = row.original;

        return (
          <Text
            maxWidth="50px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="xs"
            color={textSecondary}
            fontWeight="500"
          >
            {userID}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("lastName")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("employee.columnEmployee")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "lastName",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { firstName, lastName, image, profession, birthDate } =
          row.original;
        const name = `${firstName} ${lastName}`;
        const formattedBirthDate = dayjs(birthDate).format("YYYY MMMM DD");
        const upcomingAge = calculateUpcomingAge(birthDate);

        const isCurrentUser = loggedInUserId === row.original._id;

        // console.log("profession", profession);

        // console.log("birthDate", birthDate);
        // console.log(
        //   "isBirthdayInAWeek(birthDate)",
        //   isBirthdayInAWeek(birthDate)
        // );

        return (
          <HStack gap={2}>
            {image ? (
              <Image
                h="2rem"
                w="2rem"
                src={image}
                alt={name}
                borderRadius="10rem"
                objectFit="cover"
              />
            ) : (
              <Tooltip
                bg={shade(0.6, secondary)}
                p="0.75rem 0.75rem"
                borderRadius="1rem"
                label={
                  <>
                    <Text>
                      {translate("employee.currentlyLoggedIn")}
                      <Text as="span" ml={1} fontWeight="600">
                        {name}
                      </Text>
                    </Text>
                  </>
                }
                isDisabled={!isCurrentUser}
              >
                <Avatar size="sm" name={name} bg="#dceeff" color="#3a74a9">
                  {isCurrentUser && (
                    <AvatarBadge
                      borderColor="white"
                      bg={primary}
                      boxSize="1em"
                    />
                  )}
                </Avatar>
              </Tooltip>
            )}

            <Stack gap={0}>
              <Text
                fontSize="md"
                color={textPrimary}
                fontWeight="600"
                isTruncated // This will add ellipsis
                whiteSpace="nowrap"
                w="100%"
                maxWidth="260px"
              >
                {lastName}

                <Text as="span" fontWeight="400" ml={1}>
                  {firstName}
                </Text>
              </Text>
              <Text fontSize="xs" color={textSecondary} fontWeight="500">
                {profession && profession.name && profession.name}
              </Text>
            </Stack>
            {isBirthdayInAWeek(birthDate) && (
              <Tooltip
                label={
                  <Stack>
                    <Text
                      fontSize="xs"
                      fontWeight="500"
                      textTransform="capitalize"
                    >
                      {translate("employee.birthdayInWeek")}:{" "}
                      {formattedBirthDate}
                    </Text>
                    <Text fontSize="xs" fontWeight="500">
                      {translate("employee.turns")}: {upcomingAge}{" "}
                      {translate("employee.yearsOld")}
                    </Text>
                  </Stack>
                }
              >
                <Circle
                  ml={1}
                  h="18px"
                  w="18px"
                  bg={buttonBlue}
                  borderRadius="10rem"
                >
                  <Icon h="0.75rem" as={TbCake} color={buttonBlueText} />
                </Circle>
              </Tooltip>
            )}
          </HStack>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("phone")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("employee.columnContacts")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "phone",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { phone, email } = row.original;

        return (
          <Stack gap={0}>
            <Text
              fontSize="15px"
              color={textPrimary}
              fontWeight="500"
              isTruncated // This will add ellipsis
              noOfLines={2} // This will limit the text to two lines
              whiteSpace="nowrap"
              overflow="hidden"
              w="100%"
              maxWidth="200px"
            >
              {formatPhoneNumber(phone)}
            </Text>
            <Text
              fontSize="xs"
              color={textSecondary}
              fontWeight="500"
              maxWidth="300px"
              isTruncated
            >
              {email}
            </Text>
          </Stack>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("createdAt")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("employee.columnCreated")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "createdAt",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { createdAt } = row.original;

        return (
          <>
            {!isAdmin ? (
              <Image src={blank} size="sm" />
            ) : (
              <Text
                fontSize="sm"
                color={textSecondary}
                fontWeight="500"
                textTransform="capitalize"
              >
                {dayjs(createdAt).locale(translate("lang")).format("YY MMM DD")}
              </Text>
            )}
          </>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("rolePermission")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("employee.columnRolePermission")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "rolePermission",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { rolePermission } = row.original;

        // console.log("row", row);

        return (
          <>
            <Text
              fontSize="sm"
              color={textSecondary}
              fontWeight="500"
              textTransform="capitalize"
            >
              {translate(`rolePermission.${rolePermission}`)}
            </Text>
          </>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("patient.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { status, _id } = row.original;

        const handleUpdateStatus = (newStatus) => {
          dispatch(updateUserStatus(_id, newStatus));
        };

        const isCurrentUser = loggedInUserId === row.original._id;

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={status && status}
              onUpdateStatus={handleUpdateStatus}
              options="active"
              isUnclickable={!isAdmin}
            />
            <MoreButton
              id={_id}
              deleteHandler={deleteEmployeeHandler}
              deleteButtonDisabled={!isAdmin || isCurrentUser}
              editHandler={(e) => {
                e.stopPropagation();
                openEditEmployeeSidePanelModal(_id);
              }}
              editButtonDisabled={!isAdmin}
            />
          </HStack>
        );
      },
    },
  ];

  return isAdmin
    ? allColumns
    : allColumns.filter((column) => column.accessor !== "rolePermission");
};
