import React from "react";
import { Box } from "@chakra-ui/react";
import { textPrimary, white } from "../../../utils/colors";

const SidebarHeader = ({ isOpen, children }) => {
  return (
    <Box
      w="55%"
      minW="280px"
      maxW="280px"
      bg={white}
      color={textPrimary}
      position="fixed"
      top="0px"
      left={isOpen ? "0px" : "-280px"}
      zIndex={10}
      h="100%"
      p="0"
      maxH="100vh"
    >
      {children}
    </Box>
  );
};

export default SidebarHeader;
