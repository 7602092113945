import React, { useState } from "react";
import {
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
  HStack,
  Tooltip,
  Icon,
  Button,
  IconButton,
  Image,
} from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  white,
  newBorder,
} from "../../../../../utils/colors";
import dayjs from "dayjs";
import {
  TbDeviceMobileStar,
  TbDeviceMobile,
  TbMail,
  TbGenderAndrogyne,
  TbMapPin,
  TbCalendar,
  TbFingerprint,
  TbAddressBook,
  TbBuildingSkyscraper,
  TbEdit,
  TbLicense,
} from "react-icons/tb";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
import EditEmployee from "../../sidepanels/EditEmployee";
import useRoleCheck from "../../../../../hooks/useRoleCheck";
import blank from "../../../../../assets/icons/blank.svg";

const EmployeeDetails = ({ user, userId }) => {
  const { t } = useTranslation();

  //ROLES
  const isDoctor = useRoleCheck("employee_doctor");
  const isAdmin = useRoleCheck("admin");

  const [sidePanelModalOpen, setSidePanelModalOpen] = useState(false);

  const openSidePanelModalHandler = () => {
    setSidePanelModalOpen(true);
  };

  const closeSidePanelModal = () => {
    setSidePanelModalOpen(false);
  };

  console.log("user", user);

  return (
    <>
      {/* SIDEPANELS  */}
      <SidePanel
        isTop
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("patient.createTreatmentPlan")}
        noBorder={true}
      >
        <EditEmployee userId={userId} close={closeSidePanelModal} />
      </SidePanel>

      <Flex w="100%" direction="column" m="0rem 0 0">
        <Flex align="baseline">
          <Heading
            fontSize="1.125rem"
            color={textPrimary}
            fontWeight="600"
            m={0}
            p="0 0 0.75rem"
          >
            {t("employee.employeeDetails")}
          </Heading>
        </Flex>

        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={8}
          w="100%"
          m="0rem 0 0"
          p="1.5rem"
          pr="20%"
          border={`1px solid ${newBorder}`}
          borderRadius="1rem"
          position="relative"
        >
          <IconButton
            bg="none"
            justify="center"
            align="center"
            position="absolute"
            top="1rem"
            right="1rem"
            size="sm"
            border={`1px solid ${newBorder}`}
            onClick={openSidePanelModalHandler}
            isDisabled={!isAdmin}
          >
            <Icon
              as={TbEdit}
              variant="link"
              color={textSecondary}
              position="relative"
              top="1px"
            >
              {t("patient.detailsEdit")}
            </Icon>
          </IconButton>
          <GridItem colSpan={1}>
            <Tooltip
              label={
                <Flex align="center">
                  <Text color={white} fontSize="sm" fontWeight="400">
                    {t("patient.detailsPhone")}
                  </Text>
                  <Text color={white} fontSize="sm" fontWeight="500" ml={1}>
                    {user && user.phone ? user.phone : "-"}
                  </Text>
                </Flex>
              }
              placement="top-start"
            >
              <Flex align="center" mb={4}>
                <Icon
                  as={TbDeviceMobileStar}
                  h="1.25rem"
                  w="1.25rem"
                  color={textSecondary}
                  mr={2}
                />
                <Text
                  color={textSecondary}
                  fontSize="sm"
                  fontWeight="500"
                  lineHeight="1.2"
                >
                  {user && user.phone ? user.phone : "-"}
                </Text>
              </Flex>
            </Tooltip>

            <Tooltip
              label={
                <Flex align="center">
                  <Text color={white} fontSize="sm" fontWeight="400">
                    {t("patient.detailsPhoneAlternative")}
                  </Text>
                  <Text color={white} fontSize="sm" fontWeight="500" ml={1}>
                    {user && user.phoneAlt ? user.phoneAlt : "-"}
                  </Text>
                </Flex>
              }
              placement="top-start"
            >
              <Flex align="center" mb={4}>
                <Icon
                  as={TbDeviceMobile}
                  h="1.25rem"
                  w="1.25rem"
                  color={textSecondary}
                  mr={2}
                />
                <Text
                  color={textSecondary}
                  fontSize="sm"
                  fontWeight="500"
                  lineHeight="1.2"
                >
                  {user && user.phoneAlt ? user.phoneAlt : "-"}
                </Text>
              </Flex>
            </Tooltip>

            <Tooltip
              label={
                <Flex align="center">
                  <Text color={white} fontSize="sm" fontWeight="400">
                    {t("patient.detailsEmail")}
                  </Text>
                  <Text color={white} fontSize="sm" fontWeight="500" ml={1}>
                    {user && user.email ? user.email : "-"}
                  </Text>
                </Flex>
              }
              placement="top-start"
            >
              <Flex align="center">
                <Icon
                  as={TbMail}
                  h="1.25rem"
                  w="1.25rem"
                  color={textSecondary}
                  mr={2}
                />
                <Text
                  color={textSecondary}
                  fontSize="sm"
                  fontWeight="500"
                  lineHeight="1.2"
                  maxWidth="140px"
                  isTruncated
                >
                  {user && user.email ? user.email : "-"}
                </Text>
              </Flex>
            </Tooltip>
          </GridItem>
          <GridItem colSpan={1}>
            <Tooltip
              label={
                <Flex align="center">
                  <Text color={white} fontSize="sm" fontWeight="400">
                    {t("employee.detailsLicenseNumber")}
                  </Text>
                  <Text color={white} fontSize="sm" fontWeight="500" ml={1}>
                    {user && user.licenseNumber && user.licenseNumber
                      ? user.licenseNumber
                      : "-"}
                  </Text>
                </Flex>
              }
              placement="top-start"
            >
              <Flex align="center" mb={4}>
                <Icon
                  as={TbLicense}
                  h="1.25rem"
                  w="1.25rem"
                  color={textSecondary}
                  mr={2}
                />
                <Text
                  color={textSecondary}
                  fontSize="sm"
                  fontWeight="500"
                  lineHeight="1.2"
                >
                  {user && user.licenseNumber && user.licenseNumber
                    ? user.licenseNumber
                    : "-"}
                </Text>
              </Flex>
            </Tooltip>

            <Tooltip
              label={
                isAdmin && (
                  <Flex align="center">
                    <Text color={white} fontSize="sm" fontWeight="400">
                      {t("patient.detailsPersonalCode")}
                    </Text>
                    <Text color={white} fontSize="sm" fontWeight="500" ml={1}>
                      {user && user.personalCode ? user.personalCode : "-"}
                    </Text>
                  </Flex>
                )
              }
              placement="top-start"
            >
              <Flex align="center" mb={4}>
                <Icon
                  as={TbFingerprint}
                  h="1.25rem"
                  w="1.25rem"
                  color={textSecondary}
                  mr={2}
                />
                {isAdmin ? (
                  <Text
                    color={textSecondary}
                    fontSize="sm"
                    fontWeight="500"
                    lineHeight="1.2"
                  >
                    {user && user.personalCode ? user.personalCode : "-"}
                  </Text>
                ) : (
                  <Image src={blank} size="sm" />
                )}
              </Flex>
            </Tooltip>

            <Tooltip
              label={
                <Flex align="center">
                  <Text color={white} fontSize="sm" fontWeight="400">
                    {t("patient.detailsBirthdate")}
                  </Text>
                  <Text color={white} fontSize="sm" fontWeight="500" ml={1}>
                    {(user &&
                      user.birthDate &&
                      dayjs(user.birthDate).format("YYYY MMMM DD")) ||
                      "-"}
                  </Text>
                </Flex>
              }
              placement="top-start"
            >
              <Flex align="center">
                <Icon
                  as={TbCalendar}
                  h="1.25rem"
                  w="1.25rem"
                  color={textSecondary}
                  mr={2}
                />

                <Text
                  color={textSecondary}
                  fontSize="sm"
                  fontWeight="500"
                  whiteSpace="nowrap"
                >
                  {(user &&
                    user.birthDate &&
                    dayjs(user.birthDate).format("YYYY MMMM DD")) ||
                    "-"}
                </Text>
              </Flex>
            </Tooltip>
          </GridItem>

          <GridItem colSpan={1}>
            <Tooltip
              label={
                isAdmin && (
                  <Flex align="center">
                    <Text color={white} fontSize="sm" fontWeight="400">
                      {t("patient.detailsAddress")}
                    </Text>
                    <Text
                      color={white}
                      fontSize="sm"
                      fontWeight="500"
                      ml={1}
                      whiteSpace="nowrap"
                    >
                      {user && user.address && user.address.length > 0
                        ? user.address[0].street
                        : "-"}
                    </Text>
                  </Flex>
                )
              }
              placement="top-start"
            >
              <Flex align="center" mb={4}>
                <Icon
                  as={TbMapPin}
                  h="1.25rem"
                  w="1.25rem"
                  color={textSecondary}
                  mr={2}
                />
                {isAdmin ? (
                  <Text
                    color={textSecondary}
                    fontSize="sm"
                    fontWeight="500"
                    lineHeight="1.2"
                    whiteSpace="nowrap"
                  >
                    {user && user.address && user.address.length > 0
                      ? user.address[0].street
                      : "-"}
                  </Text>
                ) : (
                  <Image src={blank} size="sm" />
                )}
              </Flex>
            </Tooltip>

            <Tooltip
              label={
                isAdmin && (
                  <Flex align="center">
                    <Text color={white} fontSize="sm" fontWeight="400">
                      {t("patient.detailsCity")}
                    </Text>

                    <Flex align="center">
                      <Text color={white} fontSize="sm" fontWeight="500" ml={1}>
                        {user && user.address && user.address.length > 0
                          ? user.address[0].city
                          : "-"}
                        ,
                      </Text>
                      <Text color={white} fontSize="sm" fontWeight="500" ml={1}>
                        {user && user.address && user.address.length > 0
                          ? user.address[0].zip
                          : "-"}
                      </Text>
                    </Flex>
                  </Flex>
                )
              }
              placement="top-start"
            >
              <Flex align="center" mb={4}>
                <Icon
                  as={TbBuildingSkyscraper}
                  h="1.25rem"
                  w="1.25rem"
                  color={textSecondary}
                  mr={2}
                />
                {isAdmin ? (
                  <>
                    <Text
                      color={textSecondary}
                      fontSize="sm"
                      fontWeight="500"
                      lineHeight="1.2"
                      mr={1}
                    >
                      {user && user.address && user.address.length > 0
                        ? user.address[0].city
                        : "-"}
                      ,
                    </Text>
                    <Text
                      color={textSecondary}
                      fontSize="sm"
                      fontWeight="500"
                      lineHeight="1.2"
                    >
                      {user && user.address && user.address.length > 0
                        ? user.address[0].zip
                        : "-"}
                    </Text>
                  </>
                ) : (
                  <Image src={blank} size="sm" />
                )}
              </Flex>
            </Tooltip>

            <Tooltip
              label={
                isAdmin && (
                  <Flex align="center">
                    <Text color={white} fontSize="sm" fontWeight="400">
                      {t("patient.detailsCountry")}
                    </Text>
                    <Text color={white} fontSize="sm" fontWeight="500" ml={1}>
                      {user && user.address && user.address.length > 0
                        ? user.address[0].country
                        : "-"}
                    </Text>
                  </Flex>
                )
              }
              placement="top-start"
            >
              <Flex align="center">
                <Icon
                  as={TbMapPin}
                  h="1.25rem"
                  w="1.25rem"
                  color={textSecondary}
                  mr={2}
                />
                {isAdmin ? (
                  <Text color={textSecondary} fontSize="sm" fontWeight="500">
                    {user && user.address && user.address.length > 0
                      ? user.address[0].country
                      : "-"}
                  </Text>
                ) : (
                  <Image src={blank} size="sm" />
                )}
              </Flex>
            </Tooltip>
          </GridItem>
        </Grid>
      </Flex>
    </>
  );
};

export default EmployeeDetails;
