import axios from "axios";
import {
  TREATMENTSTEP_LIST_REQUEST,
  TREATMENTSTEP_LIST_SUCCESS,
  TREATMENTSTEP_LIST_FAIL,
  TREATMENTSTEP_DETAILS_REQUEST,
  TREATMENTSTEP_DETAILS_SUCCESS,
  TREATMENTSTEP_DETAILS_FAIL,
  TREATMENTSTEP_CREATE_REQUEST,
  TREATMENTSTEP_CREATE_SUCCESS,
  TREATMENTSTEP_CREATE_FAIL,
  TREATMENTSTEP_UPDATE_REQUEST,
  TREATMENTSTEP_UPDATE_SUCCESS,
  TREATMENTSTEP_UPDATE_FAIL,
  TREATMENTSTEP_DELETE_REQUEST,
  TREATMENTSTEP_DELETE_SUCCESS,
  TREATMENTSTEP_DELETE_FAIL,
} from "../constants/treatmentStepConstants";

import { logout } from "./userActions";

export const listTreatmentSteps =
  (keyword = "", pageNumber = "", pageSize = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: TREATMENTSTEP_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/treatment-steps?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        config
      );

      dispatch({
        type: TREATMENTSTEP_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TREATMENTSTEP_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listTreatmentStepDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: TREATMENTSTEP_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/treatment-steps/${id}`);

    dispatch({
      type: TREATMENTSTEP_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TREATMENTSTEP_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createTreatmentStep =
  (name, order) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TREATMENTSTEP_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/treatment-steps`,
        {
          name,
          order,
        },
        config
      );

      dispatch({
        type: TREATMENTSTEP_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TREATMENTSTEP_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response && error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

export const updateTreatmentStep = (step) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TREATMENTSTEP_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/treatment-steps/${step._id}`,
      step,
      config
    );

    dispatch({
      type: TREATMENTSTEP_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: TREATMENTSTEP_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TREATMENTSTEP_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (error.response && error.response.status === 401) {
      dispatch(logout());
    }
  }
};

export const deleteTreatmentStep = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TREATMENTSTEP_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/treatment-steps/${id}`, config);

    dispatch({
      type: TREATMENTSTEP_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: TREATMENTSTEP_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
