import { useToast } from "@chakra-ui/react";

const ToastComponent = () => {
  const toast = useToast();

  // Add an optional 'description' parameter, defaulting to undefined
  const showToast = (
    message,
    status = "success",
    duration = 2000,
    description = undefined
  ) => {
    toast({
      title: message,
      description, // This will be ignored if undefined
      status,
      duration,
      isClosable: true,
    });
  };

  return {
    showToast, // Return the showToast function
  };
};

export default ToastComponent;

// import { useToast, Box, Text } from "@chakra-ui/react";

// const ToastComponent = () => {
//   const toast = useToast();

//   const showToast = (
//     message,
//     status = "success",
//     duration = 2000,
//     description = undefined
//   ) => {
//     toast({
//       duration,
//       status,
//       isClosable: true,
//       position: "bottom-left",
//       render: () => (
//         <Box
//           color="white"
//           p={3}
//           bg={status === "success" ? "green.500" : "red.500"}
//         >
//           <Text fontSize="md">{message}</Text>
//           {description && <Text fontSize="xs">{description}</Text>}
//         </Box>
//       ),
//     });
//   };

//   return { showToast };
// };

// export default ToastComponent;
