import axios from "axios";
import {
  SERVICE_LIST_REQUEST,
  SERVICE_LIST_SUCCESS,
  SERVICE_LIST_FAIL,
  SERVICE_DETAILS_REQUEST,
  SERVICE_DETAILS_SUCCESS,
  SERVICE_DETAILS_FAIL,
  SERVICE_CREATE_REQUEST,
  SERVICE_CREATE_SUCCESS,
  SERVICE_CREATE_FAIL,
  SERVICE_UPDATE_REQUEST,
  SERVICE_UPDATE_SUCCESS,
  SERVICE_UPDATE_FAIL,
  SERVICE_DELETE_REQUEST,
  SERVICE_DELETE_SUCCESS,
  SERVICE_DELETE_FAIL,
  SERVICE_UPDATE_STATUS_REQUEST,
  SERVICE_UPDATE_STATUS_SUCCESS,
  SERVICE_UPDATE_STATUS_FAIL,
} from "../constants/serviceConstants";

import { logout } from "./userActions";

export const listServices =
  (
    keyword = "",
    pageNumber = "",
    pageSize = "",
    sortField = "",
    sortOrder = "",
    fetchAll = false,

    minPrice = "",
    maxPrice = "",
    minDuration = "",
    maxDuration = "",
    status = "",
    serviceGroup = ""
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_LIST_REQUEST });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      console.log("Received Params in Action:", {
        keyword,
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
        fetchAll,
        minPrice,
        maxPrice,
        minDuration,
        maxDuration,
        status,
        serviceGroup,
      });

      // Constructing the query string
      let query = `/api/services?keyword=${encodeURIComponent(keyword)}`;
      query += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      if (sortField) query += `&sortField=${encodeURIComponent(sortField)}`;
      if (sortOrder) query += `&sortOrder=${encodeURIComponent(sortOrder)}`;
      if (status) query += `&status=${encodeURIComponent(status)}`;
      if (serviceGroup && serviceGroup !== "")
        query += `&serviceGroup=${encodeURIComponent(serviceGroup)}`;
      if (minPrice && minPrice !== "")
        query += `&minPrice=${encodeURIComponent(minPrice)}`;
      if (maxPrice && maxPrice !== "")
        query += `&maxPrice=${encodeURIComponent(maxPrice)}`;
      if (minDuration && minDuration !== "")
        query += `&minDuration=${encodeURIComponent(minDuration)}`;
      if (maxDuration && maxDuration !== "")
        query += `&maxDuration=${encodeURIComponent(maxDuration)}`;

      if (fetchAll) query += `&all=${fetchAll}`;

      console.log("Query:", query);

      const { data } = await axios.get(query, config);

      dispatch({
        type: SERVICE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listServiceDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SERVICE_DETAILS_REQUEST });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/services/${id}`, config);

    dispatch({
      type: SERVICE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createService =
  (name, price, priceVariations, duration, serviceGroup, status) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: SERVICE_CREATE_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/services`,
        {
          name,
          price,
          priceVariations,
          duration,
          serviceGroup,
          status,
        },
        config
      );

      dispatch({
        type: SERVICE_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICE_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response && error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

export const updateService = (service) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERVICE_UPDATE_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/services/${service._id}`,
      service,
      config
    );

    dispatch({
      type: SERVICE_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: SERVICE_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SERVICE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (error.response && error.response.status === 401) {
      dispatch(logout());
    }
  }
};

export const deleteService = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERVICE_DELETE_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/services/${id}`, config);

    dispatch({
      type: SERVICE_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateServiceStatus =
  (serviceId, newStatus) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SERVICE_UPDATE_STATUS_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/services/${serviceId}/status`,
        { status: newStatus }, // Send the new status in the request body
        config
      );

      dispatch({
        type: SERVICE_UPDATE_STATUS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICE_UPDATE_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
