import styled from 'styled-components';

const TableText = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #727190;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  &.small {
    font-size: 13px;
  }
`;

export default TableText;
