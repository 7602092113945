import React, { useState } from "react";
import {
  Flex,
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  Button,
  Image,
  Icon,
} from "@chakra-ui/react";
import EmojiPicker, {
  EmojiStyle,
  Categories,
  EmojiClickData,
  Emoji,
} from "emoji-picker-react";
import { useTranslation } from "react-i18next";
import {
  primary,
  white,
  newBorder,
  backgroundLight,
  secondary,
} from "../../utils/colors";
import { FaFaceSmileBeam } from "react-icons/fa6";
import { tint, shade } from "polished";

const CommentInput = () => {
  const { t } = useTranslation();

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [inputValue, setInputValue] = useState("");

  function onClick(emojiData, event) {
    setInputValue(
      (inputValue) =>
        inputValue + (emojiData.isCustom ? emojiData.unified : emojiData.emoji)
    );
    setShowEmojiPicker(false);
  }

  return (
    <>
      <Flex m="0 auto" position="relative">
        <InputGroup
          bg={white}
          border={`1px solid ${newBorder}`}
          borderRadius="10rem"
          h="60px"
          w="450px"
        >
          <InputLeftElement
            h="100%"
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          >
            <Icon
              as={FaFaceSmileBeam}
              color={tint(0.5, primary)}
              transition="color 0.3s"
              _hover={{
                color: shade(0.5, primary),
                cursor: "pointer",
              }}
            />
          </InputLeftElement>

          <Input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={t("placeholder.comment")}
            h="100%"
            border="none"
            borderRadius="10rem"
            bg="none"
          />

          <InputRightElement h="100%" mr="1rem" w="100px">
            <Button
              bg={primary}
              color={white}
              onClick={() => {
                setInputValue("");
                setShowEmojiPicker(false);
              }}
              fontSize="xs"
              borderRadius="10rem"
              w="100%"
              transition="0.2s all"
              _hover={{
                bg: secondary,
              }}
            >
              {t("common.comment")}
            </Button>
          </InputRightElement>
        </InputGroup>

        {showEmojiPicker && (
          <EmojiPicker
            onEmojiClick={onClick}
            autoFocusSearch={false}
            emojiStyle={EmojiStyle.NATIVE}
            style={{
              position: "absolute",
              bottom: "70px",
              zIndex: 9999,
            }}
            // theme={Theme.AUTO}
            searchDisabled
            // skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
            height={350}
            width={350}
            // emojiVersion="0.6"
            // lazyLoadEmojis={true}
            // previewConfig={{
            //   defaultCaption: "Pick one!",
            //   defaultEmoji: "1f92a" // 🤪
            // }}
            // suggestedEmojisMode={SuggestionMode.RECENT}
            skinTonesDisabled
            // searchPlaceHolder="Filter"
            // defaultSkinTone={SkinTones.MEDIUM}
            // emojiStyle={EmojiStyle.NATIVE}
            // categories={[
            //   {
            //     name: "Activities",
            //     category: Categories.ACTIVITIES,
            //   },
            //   {
            //     name: "Flags",
            //     category: Categories.FLAGS,
            //   },
            // ]}
          />
        )}
      </Flex>
    </>
  );
};

export default CommentInput;
