import React from "react";
import { Flex, Text, Heading, Icon, Spinner, Tooltip } from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";
import {
  primary,
  white,
  textSecondary,
  textPrimary,
  newBorder,
  buttonAltGrey,
  buttonAltGreen,
  buttonAltPrimary,
  buttonAltSuccess,
  buttonAltRed,
  buttonAltYellow,
  buttonGreen,
  buttonGreenDark,
  buttonGreenText,
  buttonRed,
  buttonRedDark,
  buttonRedText,
  buttonYellow,
  buttonAltYellowDark,
  buttonYellowDark,
  buttonYellowText,
  buttonBlueDark,
  buttonBlueText,
  buttonBlue,
  buttonBlueHover,
  buttonCyan,
  buttonCyanDark,
  buttonCyanText,
  buttonPurple,
  buttonPurpleDark,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import {
  FaRegCircle,
  FaCircleCheck,
  FaClock,
  FaCircleXmark,
  FaCircleHalfStroke,
} from "react-icons/fa6";

const BoxTreatmentPlan = ({
  status,
  count,
  totalPrice,
  totalCompletedServicesPrice,
  loading,
  isServices,
}) => {
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // console.log("status:", status);
  // console.log("count:", count);

  const options = [
    {
      value: "draft",
      label: translate("status.draft"),
      icon: FaRegCircle,
      bg: buttonAltGrey,
      bgDark: textSecondary,
      color: textSecondary,
      borderColor: newBorder,
      bgHover: "#ddd",
    },
    {
      value: "approved",
      label: translate("status.treatmentPlanApproved"),
      icon: FaCircleCheck,
      bg: buttonAltGreen,
      bgDark: textPrimary,
      color: buttonGreenText,
      borderColor: buttonGreen,
      bgHover: "#9ebfc2",
    },

    {
      value: "consulting",
      label: translate("status.treatmentPlanConsulting"),
      icon: FaClock,
      bg: buttonAltPrimary,
      bgDark: textPrimary,
      color: textPrimary,
      borderColor: newBorder,
      bgHover: "#ddd",
    },
    {
      value: "planning",
      label: translate("status.treatmentPlanPlanning"),
      icon: FaClock,
      bg: buttonAltPrimary,
      bgDark: textPrimary,
      color: textPrimary,
      borderColor: buttonYellow,
      bgHover: "#9ebfc2",
    },
    {
      value: "completed",
      label: translate("status.treatmentPlanCompleted"),
      icon: FaCircleCheck,
      bg: buttonAltSuccess,
      bgDark: textPrimary,
      color: buttonGreenText,
      borderColor: buttonGreen,
      bgHover: "#9ebfc2",
    },
    {
      value: "cancelled",
      label: translate("status.treatmentPlanCancelled"),
      icon: FaCircleXmark,
      bg: buttonAltRed,
      bgDark: buttonRedDark,
      color: buttonRedText,
      borderColor: buttonRed,
      bgHover: "#dda3a3",
    },
    {
      value: "ongoing",
      label: translate("status.ongoing"),
      icon: FaCircleHalfStroke,
      bg: buttonAltYellow,
      bgDark: buttonAltYellowDark,
      color: textPrimary,
      borderColor: buttonAltYellow,
      bgHover: "#9ebfc2",
    },
  ];

  // Find the option object that matches the status
  const statusOption = options.find((option) => option.value === status);

  // Fallback style and icon if status is not found in options
  const fallbackStyle = {
    bg: buttonAltGrey,
    bgDark: textSecondary,
    color: textSecondary,
    icon: FaUser, // Default icon
    label: "Unknown", // Default label
  };

  // Determine if the opacity should be lowered (when count is 0)
  const isLowOpacity = count === 0;

  return (
    <Tooltip
      borderRadius="0.75rem"
      bg={white}
      label={
        <Flex direction="column">
          <Flex
            align="center"
            justify="space-between"
            p="0.5rem 0"
            borderBottom={`1px solid ${newBorder}`}
          >
            <Text fontSize="xs" fontWeight="500" color={textSecondary} mr={2}>
              {t("stats.totalServicesPrice")}:
            </Text>
            <Text fontSize="xs" fontWeight="500" color={textPrimary}>
              € {totalPrice}
            </Text>
          </Flex>

          <Flex align="center" justify="space-between" p="0.5rem 0">
            <Text fontSize="xs" fontWeight="500" color={textSecondary} mr={2}>
              {t("stats.totalCompletedServicesPrice")}:
            </Text>
            <Text fontSize="xs" fontWeight="500" color={textPrimary}>
              € {totalCompletedServicesPrice}
            </Text>
          </Flex>
        </Flex>
      }
    >
      <Flex
        w="100%"
        direction="column"
        borderRight={`1px solid ${newBorder}`}
        _last={{ borderRight: "none" }}
        bg={statusOption ? statusOption.bg : fallbackStyle.bg}
        borderRadius="1rem"
        p="1rem 1.5rem"
        opacity={isLowOpacity ? "0.5" : "1"}
      >
        <Flex w="100" justify="flex-start" align="center" m="0">
          <Flex
            bg={white}
            borderRadius="0.75rem"
            w="1.75rem"
            h="1.75rem"
            justify="center"
            align="center"
            textAlign="center"
          >
            <Icon
              as={statusOption ? statusOption.icon : fallbackStyle.icon}
              color={statusOption ? statusOption.bgDark : fallbackStyle.bgDark}
              w="initial"
              h="1rem"
              m="0 auto"
            />
          </Flex>
          {!loading ? (
            <Text ml={2} color={textPrimary} fontSize="sm" fontWeight="600">
              {statusOption ? statusOption.label : fallbackStyle.label}
            </Text>
          ) : (
            <Spinner size="md" color={primary} />
          )}
        </Flex>
        <Flex align="baseline" justify="space-between" w="100%" mt={6}>
          <Heading size="md" fontWeight="600" color={textPrimary}>
            {count}{" "}
            <Text as="span" color={textPrimary} fontWeight="500" fontSize="xs">
              {translate("common.plans")}
            </Text>
          </Heading>
          <Text
            color={textSecondary}
            fontSize="sm"
            fontWeight="500"
            whiteSpace="noWrap"
          >
            € {totalPrice}
            {isServices && (
              <Text ml={1} as="span" fontSize="xs">
                {translate("common.inServices")}
              </Text>
            )}
          </Text>
        </Flex>
      </Flex>
    </Tooltip>
  );
};

export default BoxTreatmentPlan;
