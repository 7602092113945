import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Switch,
  FormErrorMessage,
} from "@chakra-ui/react";
import { textPrimary } from "../../utils/colors";

const FormControlConsulting = ({
  label,
  value,
  onChange,
  isTouched,
  isError,
  w,
  errorMessage,
  isSmall,
  isDisabled,
}) => {
  return (
    <FormControl
      mb={4}
      isInvalid={isTouched && isError}
      w={w}
      isDisabled={isDisabled}
    >
      <FormLabel
        fontSize={isSmall ? "xs" : "sm"}
        position="relative"
        color={textPrimary}
      >
        {label}
        {isError && isTouched && (
          <Box position="absolute" top={-1} right={0} zIndex={2}>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </Box>
        )}
      </FormLabel>
      <Switch
        size={isSmall ? "md" : "lg"}
        colorScheme="blue"
        isChecked={value}
        onChange={onChange}
        fontSize={isSmall ? "sm" : "0.938rem"}
        fontWeight="500"
      />
    </FormControl>
  );
};

export default FormControlConsulting;
