import { Button, Box, Flex, Stack, Text, Icon } from "@chakra-ui/react";
import {
  white,
  textPrimary,
  textSecondary,
  primary,
  newBorder,
  buttonRed,
  buttonRedText,
  buttonBlue,
  buttonBlueText,
} from "../../utils/colors";
import { Fade, Slide } from "@chakra-ui/transition";
import { FaXmark, FaTrash } from "react-icons/fa6";
import { TbMailForward, TbTrash } from "react-icons/tb";
import { shade } from "polished";

const ActionsModal = ({
  isOpen,
  onClose,
  onSendEmail,
  onDelete,
  selectedCount,
  selectedItems,
  isLong,
  t,
}) => {
  return (
    <Slide
      direction="bottom"
      in={isOpen}
      style={{
        zIndex: 9000,
        width: isLong ? "80%" : "calc(80% - 280px)",
        left: "initial",
        right: isLong ? "10%" : "calc(10% + 2rem)",
        paddingRight: "0rem",
        bottom: isOpen ? "2rem" : "0rem",
        boxShadow: `1px 1px 10px rgba(0, 0, 0, 0.2)`,
        borderRadius: "1rem",
        overflow: "hidden",
        p: "0",
      }}
    >
      <Fade in={isOpen}>
        <Box position="relative" bottom="0" right="0" zIndex="overlay">
          <Flex
            bg={white}
            p={0}
            align="center"
            justify="space-between"
            borderRadius="md"
            w="100%"
            maxW="100%" // Adjust the width as needed, ensuring it doesn't go full width
            h="80px"
            // shadow="lg"
          >
            <Flex align="center" h="100%">
              <Flex
                bg={primary}
                align="center"
                justify="center"
                textAlign="center"
                h="100%"
                color={white}
                fontWeight="500"
                px={8}
              >
                {selectedCount}
              </Flex>
              <Stack gap="0" ml={3}>
                {/* <Text color={textSecondary} fontSize="sm" fontWeight="500">
                  {selectedCount > 1 ? "items" : "item"} selected
                </Text> */}
                <Text color={textPrimary} fontSize="md" fontWeight="500">
                  {t("common.selectedPlans")}
                </Text>
                {/* <Text
                  color={textSecondary}
                  fontSize="sm"
                  fontWeight="500"
                  ml={3}
                >
                  asffsa
                </Text> */}
                <Flex align="center">
                  {selectedItems.map((plan) => (
                    <Text
                      key={plan._id}
                      fontSize="xs"
                      fontWeight="500"
                      color={textSecondary}
                      mr={1}
                      borderRight={`1px solid ${newBorder}`}
                      pr={1}
                      _last={{ borderRight: "none" }}
                    >
                      {plan.treatmentPlanID}
                    </Text>
                  ))}
                </Flex>
              </Stack>
            </Flex>

            <Flex align="center" justify="flex-end">
              <Flex align="center" pr={8}>
                <Button
                  bg={buttonRed}
                  mr={4}
                  onClick={onDelete}
                  _hover={{
                    bg: shade(0.1, buttonRed),
                  }}
                >
                  <Icon
                    as={TbTrash}
                    color={buttonRedText}
                    fontSize="sm"
                    fontWeight="500"
                    mr={2}
                  />
                  <Text fontSize="sm" color={buttonRedText} fontWeight="600">
                    {t("button.deleteSelected")}
                  </Text>
                </Button>
                <Button
                  bg={buttonBlue}
                  onClick={onSendEmail}
                  _hover={{
                    bg: shade(0.1, buttonBlue),
                  }}
                >
                  <Icon
                    as={TbMailForward}
                    color={buttonBlueText}
                    fontSize="sm"
                    fontWeight="500"
                    mr={2}
                  />
                  <Text fontSize="sm" color={buttonBlueText} fontWeight="600">
                    {t("button.sendToPatientByEmail")}
                  </Text>
                </Button>
              </Flex>
              <Button
                h="80px"
                borderLeft={`1px solid ${newBorder}`}
                bg="none"
                onClick={onClose}
                borderRadius="0px"
                px={8}
              >
                <Icon as={FaXmark} color={textSecondary} fontWeight="500" />
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Fade>
    </Slide>
  );
};

export default ActionsModal;
