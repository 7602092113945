import React, { useState, useEffect } from "react";
import { Flex, Heading, Text } from "@chakra-ui/react";
import {
  newBorder,
  textPrimary,
  textSecondary,
  backgroundLight,
} from "../../../../../utils/colors";
import Table from "../../../../../components/shared/Table";
import { columns } from "../../columns/ServiceListPatientPublicTreatmentPlanColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../../hooks/useIsMobile";

const ServiceListTreatmentPlanPublic = ({
  loading,
  error,
  services,
  totalPrice,
}) => {
  // TRANSLATION
  const { t } = useTranslation();
  const translate = (key) => t(key);

  const [totalDiscount, setTotalDiscount] = useState(0);

  // MOBILE
  const isMobile = useIsMobile();

  // LOGS
  console.log("ServiceListTreatmentPlanPublic.js: services: ", services);

  useEffect(() => {
    const totalDiscountedPrice = services.reduce((totalDiscount, service) => {
      const originalPrice = service.service.price;
      const discountedPrice = service.price;
      const difference = originalPrice - discountedPrice;
      return totalDiscount + Math.max(difference, 0);
    }, 0);

    setTotalDiscount(totalDiscountedPrice);
  }, [services]);

  return (
    <Flex
      direction="column"
      w="100%"
      border={`1px solid ${newBorder}`}
      borderRadius="1rem"
      p="0 0 0rem"
    >
      <Heading
        fontSize={["0.875rem", "1rem", "1rem"]}
        fontWeight="600"
        color={textPrimary}
        p={["0.75rem 1.5rem", "1rem 1.5rem", "1rem 1.5rem"]}
        textAlign="center"
        bg={backgroundLight}
        borderBottom={`1px solid ${newBorder}`}
        borderTopLeftRadius="1rem"
        borderTopRightRadius="1rem"
      >
        {t("treatmentPlan.serviceList")}
      </Heading>

      <Table.Container
        columns={columns(translate)}
        data={services}
        loading={loading}
        error={error}
        // onRowClick={(userId) => {
        //   setIsSidePanelLongOpen(true);
        //   setUserId(userId);
        // }}
        // noItemsButtonHandler={openSidePanelModal}
        noItemsTitle={t("noItems.noPatients")}
        noItemsDescription={t("noItems.noPatientsText")}
        noItemsButtonLabel={t("noItems.noPatientsButton")}
        // noItemsButtonHandler={openCreatePatientSidePanelModal}
        isHeaderWhite
        onRowClick={(row) => console.log("row", row)}
        p="0 1rem"
      />

      <Flex p="2rem 3rem 3rem 3rem" w="100%" justify="flex-end">
        <Flex direction="column" justify="flex-end">
          <Flex align="center" justify="space-between">
            <Text color={textSecondary} fontSize="15px" fontWeight="500" mr={6}>
              {t("service.serviceCount")}:
            </Text>
            <Text color={textPrimary} fontSize="15px" fontWeight="600">
              {services.length}
            </Text>
          </Flex>

          <Flex align="center" justify="space-between" my={2}>
            <Text color={textSecondary} fontSize="15px" fontWeight="500" mr={6}>
              {t("service.discount")}:
            </Text>
            <Text color={textPrimary} fontSize="15px" fontWeight="600">
              -{totalDiscount} €
            </Text>
          </Flex>

          <Flex align="center" justify="space-between">
            <Text color={textSecondary} fontSize="15px" fontWeight="500" mr={6}>
              {t("treatmentPlan.priceTotal")}:
            </Text>
            <Text color={textPrimary} fontSize="15px" fontWeight="600">
              {totalPrice} €
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ServiceListTreatmentPlanPublic;
