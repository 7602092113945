import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TabPanels,
  TabPanel,
  HStack,
  Flex,
  useToast,
  Spinner,
  Text,
} from "@chakra-ui/react";
import {
  listAttendeeDetails,
  updateAttendeeStatus,
  deleteAttendee,
  updateAttendeeCourseStatus,
} from "../../../../actions/attendeeActions";
import Panel from "../../../../components/shared/Panel";
import { primary } from "../../../../utils/colors";
import TabsComponentAlt from "../../../../components/tabs/TabsComponentAlt";
import StatusButton from "../../../../components/buttons/StatusButton";
import SinglePageTopBar from "../../../../components/layout/SinglePageTopBar";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import EditAttendee from "../sidepanels/EditAttendee";
import {
  ATTENDEE_UPDATE_RESET,
  ATTENDEE_ACTIVE_STATUS_UPDATE_RESET,
  ATTENDEE_DETAILS_RESET,
} from "../../../../constants/attendeeConstants";
import { useTranslation } from "react-i18next";
import TabAttendeeCourses from "./tabs/TabAttendeeCourses";
import TabAttendeeFinance from "./tabs/TabAttendeeFinance";
import AttendeeInfo from "./partials/AttendeeInfo";
import AttendeeCharts from "./partials/AttendeeCharts";
import SinglePageImageWrapper from "../../../../components/partials/SinglePageImageWrapper";
import MoreButton from "../../../../components/buttons/MoreButton";
import SinglePagePieChart from "../../../../components/charts/SinglePagePieChart";
import ToastComponent from "../../../../components/partials/ToastComponent";

const AttendeeSingle = () => {
  // ID
  const attendeeId = useParams().id;

  // NAVIGATION
  const navigate = useNavigate();

  // TOAST
  const toast = useToast();
  const toastComponent = ToastComponent();

  //TRANSLATION
  const { t } = useTranslation();

  //TABS
  const tabsData = [
    { label: t("attendee.courses") },
    { label: t("attendee.tabFinance") },
  ];

  //STATE
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [status, setStatus] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [courses, setCourses] = useState([]);
  const [place, setPlace] = useState("");
  const [city, setCity] = useState("");
  const [image, setImage] = useState("");

  //REDUX
  const dispatch = useDispatch();

  const attendeeDetails = useSelector((state) => state.attendeeDetails);
  const { loading, attendee } = attendeeDetails;

  const attendeeUpdate = useSelector((state) => state.attendeeUpdate);
  const { success: successUpdate } = attendeeUpdate;

  const attendeeDelete = useSelector((state) => state.attendeeDelete);
  const { success: successDelete } = attendeeDelete;

  const attendeeStatusUpdate = useSelector(
    (state) => state.attendeeStatusUpdate
  );
  const { success: successStatusUpdate } = attendeeStatusUpdate;

  const attendeeAddCourse = useSelector((state) => state.attendeeAddCourse);
  const { success: successAddCourse } = attendeeAddCourse;

  const attendeeRemoveCourse = useSelector(
    (state) => state.attendeeRemoveCourse
  );
  const { success: successRemoveCourse } = attendeeRemoveCourse;

  const attendeeCourseStatusUpdate = useSelector(
    (state) => state.attendeeCourseStatusUpdate
  );
  const { success: successUpdateCourseStatus } = attendeeCourseStatusUpdate;

  const attendeeUpdateImage = useSelector((state) => state.attendeeUpdateImage);
  const { success: successUpdateImage } = attendeeUpdateImage;

  // HANDLERS

  const updateAttendeeCourseStatusHandler = (courseId, status) => {
    dispatch(updateAttendeeCourseStatus(attendeeId, courseId, status));
  };

  const deleteAttendeeHandler = (id) => {
    dispatch(deleteAttendee(id));
    toast({
      title: t("attendee.attendeeDeleted"),
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const openEditSidePanel = () => {
    setEditSidePanelOpen(true);
  };

  const closeEditSidePanel = () => {
    setEditSidePanelOpen(false);
  };

  const handleUpdateStatus = (status) => {
    dispatch(updateAttendeeStatus(attendeeId, status));
  };

  // USEEFFECT

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: ATTENDEE_UPDATE_RESET });
      setEditSidePanelOpen(false);

      dispatch(listAttendeeDetails(attendeeId));
    } else if (successDelete) {
      dispatch({ type: ATTENDEE_UPDATE_RESET });
      navigate("/academy/attendees/");
    } else if (successStatusUpdate) {
      dispatch({ type: ATTENDEE_ACTIVE_STATUS_UPDATE_RESET });
      dispatch(listAttendeeDetails(attendeeId));
    } else if (successUpdateImage) {
      toastComponent.showToast(t("toasts.imageUpdated"), "success", 3000);

      dispatch(listAttendeeDetails(attendeeId));
    } else {
      if (!attendee.firstName || attendee._id !== attendeeId) {
        dispatch(listAttendeeDetails(attendeeId));
      } else {
        setImage(attendee.image);
        setStatus(attendee.status);
        setFirstName(attendee.firstName);
        setLastName(attendee.lastName);
        setCourses(attendee.courses);

        if (attendee?.address[0]?.city) {
          setCity(attendee?.address[0]?.city);
        }

        if (attendee?.address[0]?.street) {
          setPlace(
            attendee?.address[0]?.street + `, ` + attendee?.address[0]?.zip ||
              ""
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    navigate,
    attendeeId,
    attendee,
    successUpdate,
    successUpdate,
    successDelete,
    successStatusUpdate,
    successUpdateImage,
  ]);

  return (
    <>
      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanel}
        title={t("attendee.editAttendee")}
        noBorder={true}
      >
        <EditAttendee attendeeId={attendeeId} />
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <SinglePageTopBar
              backFunction={() => navigate(-1)}
              title={attendee.lastName + " " + attendee.firstName}
              type={t("attendee.attendee")}
              loading={loading}
            >
              {status && (
                <StatusButton
                  currentStatus={status}
                  onUpdateStatus={handleUpdateStatus}
                  options="active"
                  isBig
                />
              )}

              <MoreButton
                id={attendeeId}
                isBig
                deleteHandler={deleteAttendeeHandler}
                editHandler={(e) => {
                  e.stopPropagation();
                  openEditSidePanel(attendeeId);
                }}
              />
            </SinglePageTopBar>
            {!loading ? (
              <>
                <HStack
                  gap={0}
                  p="2.5rem 0rem 0rem 2rem"
                  alignItems="flex-start"
                  justifyContent="center"
                  w="100%"
                >
                  <SinglePageImageWrapper
                    id={attendeeId}
                    borderRadius="2rem"
                    image={attendee.image}
                    options="attendee"
                  />

                  <HStack
                    gap={0}
                    p="0rem 0rem 0rem 2rem"
                    w="calc(100% - 260px)"
                    alignItems="flex-start"
                  >
                    <>
                      <AttendeeInfo attendee={attendee} />
                      <SinglePagePieChart
                        label={t("attendee.attendeeCourseStats")}
                        data={attendee.courses}
                        options="attendees"
                        plotLabel={t("stats.totalCourses")}
                      />
                    </>
                  </HStack>
                </HStack>
              </>
            ) : (
              <Flex justify="center" align="center" w="100%" p={20}>
                <Spinner size="xl" color={primary} />
              </Flex>
            )}
            <Box p="0 2.5rem">
              <TabsComponentAlt
                activeTab={activeTab}
                tabs={tabsData}
                setActiveTab={setActiveTab}
                isTitleValid={true}
                disabledTabs={[1, 2, 3]}
                isSinglePage
              >
                <TabPanels>
                  <TabPanel pb={4} px={0}>
                    <TabAttendeeCourses />
                  </TabPanel>
                  <TabPanel pb={4} px={0}>
                    {/* <TabAttendeeFinance /> */}
                  </TabPanel>
                </TabPanels>
              </TabsComponentAlt>
            </Box>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default AttendeeSingle;
