import React, { useState, useEffect } from "react";
import { getEmployeeDoctorStatistics } from "../../../../../actions/statisticActions";
import UserStats from "../../../../../components/widgets/UserStats";
import { useSelector, useDispatch } from "react-redux";
import { Flex, Heading, Spinner, Text, Tooltip, Icon } from "@chakra-ui/react";
import {
  backgroundLight,
  textPrimary,
  primary,
  white,
  textSecondary,
  newBorder,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import {
  TbDeviceMobileStar,
  TbDeviceMobile,
  TbMail,
  TbWallet,
  TbBuildingBank,
} from "react-icons/tb";

const EmployeeBankDetails = ({ loading, user }) => {
  const { t } = useTranslation();

  // LOGS
  console.log("user", user);

  return (
    <Flex direction="column" w="100%" mb={8}>
      <Heading
        fontSize="1.125rem"
        color={textPrimary}
        fontWeight="600"
        m={0}
        p="0 0 0.75rem"
      >
        {t("employee.bankDetails")}
      </Heading>

      <Flex
        w="100%"
        bg={white}
        border={`1px solid ${newBorder}`}
        direction="column"
        p="1.5rem"
        borderRadius="1rem"
        overflowY="auto" // Enable scrolling
        position="relative"
        pb="2.75rem"
      >
        <Flex
          w="100%"
          bg="none"
          direction="column"
          p="0rem"
          borderRadius="0rem"
        >
          {!loading ? (
            <>
              <Tooltip
                label={
                  <Flex align="center">
                    <Text color={white} fontSize="sm" fontWeight="400">
                      {t("employee.bankDetailsAccountNumber")}:
                    </Text>
                    <Text color={white} fontSize="sm" fontWeight="500" ml={1}>
                      {user.bankAccountDetails &&
                      user.bankAccountDetails.length > 0 &&
                      user.bankAccountDetails[0].iban
                        ? user.bankAccountDetails[0].iban
                        : "-"}
                    </Text>
                  </Flex>
                }
                placement="top-start"
              >
                <Flex align="center" mb={4}>
                  <Icon
                    as={TbWallet}
                    h="1.25rem"
                    w="1.25rem"
                    color={textSecondary}
                    mr={2}
                  />
                  <Text
                    color={textSecondary}
                    fontSize="sm"
                    fontWeight="500"
                    lineHeight="1.2"
                  >
                    {user.bankAccountDetails &&
                    user.bankAccountDetails.length > 0 &&
                    user.bankAccountDetails[0].iban
                      ? user.bankAccountDetails[0].iban
                      : "-"}
                  </Text>
                </Flex>
              </Tooltip>

              <Tooltip
                label={
                  <Flex align="center">
                    <Text color={white} fontSize="sm" fontWeight="400">
                      {t("employee.bankDetailsBankName")}:
                    </Text>
                    <Text color={white} fontSize="sm" fontWeight="500" ml={1}>
                      {user.bankAccountDetails &&
                      user.bankAccountDetails.length > 0 &&
                      user.bankAccountDetails[0].bankName
                        ? t(`bank.${user.bankAccountDetails[0].bankName}`)
                        : "-"}
                    </Text>
                  </Flex>
                }
                placement="top-start"
              >
                <Flex align="center" mb={4}>
                  <Icon
                    as={TbBuildingBank}
                    h="1.25rem"
                    w="1.25rem"
                    color={textSecondary}
                    mr={2}
                  />
                  <Text
                    color={textSecondary}
                    fontSize="sm"
                    fontWeight="500"
                    lineHeight="1.2"
                    textTransform="capitalize"
                  >
                    {user.bankAccountDetails &&
                    user.bankAccountDetails.length > 0 &&
                    user.bankAccountDetails[0].bankName
                      ? t(`bank.${user.bankAccountDetails[0].bankName}`)
                      : "-"}
                  </Text>
                </Flex>
              </Tooltip>
            </>
          ) : (
            <Flex
              w="100%"
              bg="none"
              borderRadius="1rem"
              justify="center"
              align="center"
              direction="column"
              p="2rem 0"
            >
              <Spinner color={primary} size="lg" />
              <Text color={textSecondary} fontSize="sm" pt={4}>
                {t("stats.loading")}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EmployeeBankDetails;
