import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listTreatmentPlanDetails,
  updateTreatmentPlan,
  downloadTreatmentPlanPdf,
} from "../../../../actions/treatmentPlanActions";
import { listUsersEmployees } from "../../../../actions/userActions";
import {
  Button,
  Flex,
  Text,
  Spinner,
  Box,
  Heading,
  Image,
  useToast,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  white,
  newBorder,
  textPrimary,
  textSecondary,
  buttonAltYellow,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import mongoose from "mongoose";
import Header from "./partials/Header";
import ServiceList from "./partials/ServiceList";
import { calculateTotalPriceForService } from "../../../../utils/calculations";
import { TREATMENTPLAN_LIST_RESET } from "../../../../constants/treatmentPlanConstants";
import {
  USER_DETAILS_RESET,
  USER_EMPLOYEE_DETAILS_RESET,
  USER_DOCTOR_SERVICES_RESET,
} from "../../../../constants/userConstants";
import { TREATMENTPLAN_DOWNLOAD_PDF_RESET } from "../../../../constants/treatmentPlanConstants";
import noServices from "../../../../assets/images/empty-box.png";

const EditPatientTreatmentPlan = ({ planId, close, currentUser }) => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast();

  // PDF

  // CONSTANTS
  const today = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(today);
  const [patient, setPatient] = useState({});
  const [employee, setEmployee] = useState(currentUser._id);
  const [status, setStatus] = useState("");
  const [coordinator, setCoordinator] = useState("");
  const [services, setServices] = useState([]);
  const [isFormValid, setIsFormValid] = useState(true);
  const [totalTpPrice, setTotalTpPrice] = useState(0);
  const [loadingDisplay, setLoadingDisplay] = useState(true);

  const [addServiceModalOpen, setAddServiceModalOpen] = useState(false);

  // REDUX
  const treatmentPlanDetails = useSelector(
    (state) => state.treatmentPlanDetails
  );
  const { loading, error, treatmentPlan } = useSelector(
    (state) => state.treatmentPlanDetails
  );

  const treatmentPlanUpdate = useSelector((state) => state.treatmentPlanUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = treatmentPlanUpdate;

  const userEmployeesList = useSelector((state) => state.userEmployeesList);
  const { employees } = userEmployeesList;

  const [employeeOptions, setEmployeeOptions] = useState([]);

  const [appointmentDates, setAppointmentDates] = useState({});

  const treatmentPlanDownloadPdf = useSelector(
    (state) => state.treatmentPlanDownloadPdf
  );
  const {
    loading: loadingDownloadPdf,
    error: errorDownloadPdf,
    pdf,
  } = treatmentPlanDownloadPdf;

  // FUNCTIONS
  const validateServices = (services) => {
    // Check if any service has a quantity of 0
    const isAnyServiceInvalid = services.some(
      (service) => service.quantity === 0
    );

    // If any service is invalid, set form validity to false
    setIsFormValid(!isAnyServiceInvalid);
  };

  const handleDownloadButtonClick = (planId) => {
    dispatch(downloadTreatmentPlanPdf(planId));
  };

  const handlePrintPdf = (planId) => {
    // Handle printing the PDF here
    console.log("Printing PDF for plan ID:", planId);
  };

  useEffect(() => {
    if (pdf) {
      const url = window.URL.createObjectURL(new Blob([pdf]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `TreatmentPlan-${planId}.pdf`); // Use a state or prop for `planId` if necessary
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();

      console.log("PDF downloaded successfully");

      dispatch({ type: TREATMENTPLAN_DOWNLOAD_PDF_RESET });
    } else if (errorDownloadPdf) {
      console.error("Failed to download PDF:", errorDownloadPdf);
      // Show error to the user
    }
  }, [pdf, errorDownloadPdf]); // Add dependencies to ensure the effect runs when they change

  // SERVICES CHECK LOGIC

  const updateTreatmentPlanStatusAddService = (updatedServices, status) => {
    console.log("updatedServices", updatedServices);
    console.log("status", status);

    const isNoServices = updatedServices.length === 0;

    // Check if there are no services first
    if (isNoServices) {
      setStatus("draft");
    } else {
      // Check if all services are consulting services
      const isAllConsulting = updatedServices.every(
        (service) =>
          service.service &&
          service.service.serviceGroup &&
          service.service.serviceGroup.isConsulting === true
      );

      if (isAllConsulting) {
        setStatus("consulting");
      } else {
        setStatus("planning");
      }
    }
  };
  const updateTreatmentPlanEditingService = (
    updatedServices,
    currentStatus
  ) => {
    // Check for any future appointments
    const isAnyAppointmentInFuture = updatedServices.some(
      (service) =>
        service.appointment && new Date(service.appointment) > new Date()
    );

    // const CONSULTING_SERVICE_GROUP_ID = "652e6f8db59577bc6c08cccd";

    // If the current status is 'approved' and there are future appointments,
    // change the status to 'ongoing'
    if (currentStatus === "approved" && isAnyAppointmentInFuture) {
      setStatus("ongoing");
    } else if (currentStatus === "ongoing" && isAnyAppointmentInFuture) {
      setStatus("ongoing");
    } else if (currentStatus === "planning") {
      setStatus("planning");
    } else if (currentStatus === "consulting") {
      setStatus("consulting");
    } else {
      setStatus("approved");
    }

    if (
      (currentStatus === "consulting" || currentStatus === "planning") &&
      isAnyAppointmentInFuture
    ) {
      const isAllConsulting = updatedServices.every(
        (service) =>
          service.service &&
          service.service.serviceGroup &&
          service.service.serviceGroup.isConsulting === true
      );

      if (isAllConsulting) {
        setStatus("consulting");
      } else {
        setStatus("planning");
      }
    }
  };

  const updateTreatmentPlanStatus = (services, currentStatus) => {
    const isAnyAppointmentInFuture = services.some(
      (service) =>
        service.appointment && new Date(service.appointment) > new Date()
    );

    const areAllServicesCompleted = services.every(
      (service) => service.status === "completed"
    );

    // If all services are completed, regardless of the current status,
    // the TP status should be 'completed'
    if (areAllServicesCompleted) {
      setStatus("completed");
    }
    // If any appointment is in the future and not all services are completed,
    // the status should be 'ongoing'
    else if (isAnyAppointmentInFuture) {
      setStatus("ongoing");
    } else if (currentStatus === "planning") {
      setStatus("planning");
    } else if (currentStatus === "consulting") {
      setStatus("consulting");
    }
    // If not all services are completed and no future appointments,
    // the status should fall back to 'approved'
    else {
      setStatus("approved");
    }
  };

  // HANDLERS

  const saveAndExit = (e) => {
    if (e) {
      e.preventDefault();
      submitHandler(e).then(() => {
        close();
      });
    } else {
      close();
    }
  };

  const exit = (e) => {
    e.preventDefault();
    close();
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    updateTreatmentPlanStatus(services, status);

    // Construct the updated plan object
    const updatedPlan = {
      _id: treatmentPlan._id,
      patient: patient._id,
      employee: employee,
      services: services,
      status: status, // Use the updated status
      coordinator: coordinator,
    };

    try {
      await dispatch(updateTreatmentPlan(updatedPlan));

      // Fetch the latest treatment plan details after a successful update
      dispatch(listTreatmentPlanDetails(planId));
      dispatch({ type: TREATMENTPLAN_LIST_RESET });
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: USER_EMPLOYEE_DETAILS_RESET });
      dispatch({ type: USER_DOCTOR_SERVICES_RESET });
    } catch (error) {
      // Handle error, e.g., show an error toast
      toast({
        title: "Error",
        description: "An error occurred while updating the treatment plan.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    close();
  };
  const handleCoordinatorChange = (newCoordinator) => {
    // Update the coordinator state here
    setCoordinator(newCoordinator);
  };

  // Callback function to update the employee for a service
  const handleEmployeeChange = (serviceId, selectedEmployeeId) => {
    const updatedServices = services.map((service) => {
      if (service._id === serviceId) {
        return {
          ...service,
          employee: employees.find(
            (employee) => employee._id === selectedEmployeeId
          ),
        };
      }
      return service;
    });
    setServices(updatedServices);
  };

  const addServiceToList = (selectedService) => {
    // Clone the selectedService to a new object
    const newService = {
      ...selectedService, // Spread to copy properties
      _id: mongoose.Types.ObjectId(), // Generate a new ObjectId
      appointment: null, // Set the appointment to null by default
      employee: null, // Set the employee to null by default
      order: services.length + 1, // Set the order based on the current list length
      paid: false, // You can set other default values here
      // Ensure price is copied by value, not by reference if it's an object
      price:
        typeof selectedService.price === "object"
          ? { ...selectedService.price }
          : selectedService.price,
      priceVariations: selectedService.priceVariations,
      totalPrice:
        selectedService.priceVariations === false ? 0 : selectedService.price,
      quantity: selectedService.priceVariations === false ? 0 : 1,
      service: {
        ...selectedService.service, // Deep copy if 'service' is an object
        _id: selectedService.value,
        name: selectedService.label,
        serviceGroup: selectedService.serviceGroup,
      },
      status: "uncompleted", // Set the initial status as needed
      teeth: [], // Set the initial teeth array as needed
    };
    // console.log("newService", newService);

    // Add the new service to the list of services
    setServices((prevServices) => [...prevServices, newService]);
    setTotalTpPrice(totalTpPrice + newService.totalPrice);

    // updateTreatmentPlanStatus([...services, newService], status);

    const updatedServices = [...services, newService];

    updateTreatmentPlanStatusAddService(updatedServices, status);
    // console.log("hello");
    setAddServiceModalOpen(false);
  };

  const serviceStatusHandler = (serviceId) => {
    // Find the index of the service with the matching ID
    const serviceIndex = services.findIndex(
      (service) => service._id === serviceId
    );

    if (serviceIndex !== -1) {
      // Toggle the status of the service
      const updatedServices = [...services];
      updatedServices[serviceIndex].status =
        services[serviceIndex].status === "uncompleted"
          ? "completed"
          : "uncompleted";

      setServices(updatedServices);
      // updateTreatmentPlanStatusAlt(updatedServices, status);
      updateTreatmentPlanStatus(updatedServices, status);
    }
  };

  const saveEditedService = (updatedService) => {
    console.log("Updated Service:", updatedService);
    const newServices = services.map((service) =>
      service._id === updatedService._id ? updatedService : service
    );

    // Reorder the services based on 'order' attribute
    newServices.sort((a, b) => a.order - b.order);

    // Adjust order for all services
    newServices.forEach((service, index) => {
      service.order = index + 1;
    });

    setServices(newServices);

    // console.log("newServices", newServices);

    // Calculate the total price for all services
    const totalServicesPrice = newServices.reduce((total, service) => {
      // Make sure to convert quantity to a number before calculation
      const quantity = Number(service.quantity);
      const price = Number(service.price);
      const totalPriceForService = quantity * price;
      return total + totalPriceForService;
    }, 0);

    setTotalTpPrice(totalServicesPrice);
    // console.log("totalServicesPrice", totalServicesPrice);

    updateTreatmentPlanEditingService(newServices, status);
  };

  const removeService = (serviceId) => {
    // Filter out the service to be removed and adjust the order of remaining services
    const updatedServices = services
      .filter((service) => service._id !== serviceId)
      .map((service, index) => ({ ...service, order: index + 1 }));

    setServices(updatedServices);

    // Recalculate the total price after removing a service
    const newTotalPrice = updatedServices.reduce((total, service) => {
      // Make sure to convert quantity and price to numbers for calculation
      const quantity = Number(service.quantity);
      const price = Number(service.price);
      return total + quantity * price;
    }, 0);

    setTotalTpPrice(newTotalPrice);

    // Update the treatment plan status based on the updated services
    updateTreatmentPlanStatusAddService(updatedServices, status);
  };

  const servicePaidHandler = (serviceId) => {
    // Find the index of the service with the matching ID
    const serviceIndex = services.findIndex(
      (service) => service._id === serviceId
    );

    if (serviceIndex !== -1) {
      // Toggle the status of the service
      const updatedServices = [...services];
      updatedServices[serviceIndex].paid = !services[serviceIndex].paid;

      setServices(updatedServices);
    }
  };

  const moveServiceUp = (serviceId) => {
    setServices((prevServices) => {
      const index = prevServices.findIndex(
        (service) => service._id === serviceId
      );
      if (index > 0) {
        const newServices = [...prevServices];
        // Swap the services
        [newServices[index], newServices[index - 1]] = [
          newServices[index - 1],
          newServices[index],
        ];
        // Swap their orders
        [newServices[index].order, newServices[index - 1].order] = [
          newServices[index - 1].order,
          newServices[index].order,
        ];
        return newServices;
      }
      return prevServices;
    });
  };

  const moveServiceDown = (serviceId) => {
    setServices((prevServices) => {
      const index = prevServices.findIndex(
        (service) => service._id === serviceId
      );
      if (index < prevServices.length - 1) {
        const newServices = [...prevServices];
        // Swap the services
        [newServices[index], newServices[index + 1]] = [
          newServices[index + 1],
          newServices[index],
        ];
        // Swap their orders
        [newServices[index].order, newServices[index + 1].order] = [
          newServices[index + 1].order,
          newServices[index].order,
        ];
        return newServices;
      }
      return prevServices;
    });
  };

  // USEEFFECTS

  // New useEffect hook
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoadingDisplay(false);
    }, 500); // CHANGE THIS TO HAVE A LONGER LOADING TIME

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    validateServices(services);
  }, [services]);

  useEffect(() => {
    dispatch(listUsersEmployees());
  }, [dispatch]);

  useEffect(() => {
    if (employees && employees.length > 0) {
      // Convert the employees to the format react-select expects
      const transformedEmployees = employees.map((employee) => ({
        value: employee._id,
        label: employee.firstName + " " + employee.lastName,
        image: employee.image,
        birthDate: employee.birthDate,
      }));
      setEmployeeOptions(transformedEmployees);
    }
  }, [employees]);

  useEffect(() => {
    if (!treatmentPlan || treatmentPlan._id !== planId) {
      dispatch(listTreatmentPlanDetails(planId));
    } else {
      setDate(treatmentPlan.date);
      setPatient(treatmentPlan.patient);
      setEmployee(treatmentPlan.employee);
      setServices(treatmentPlan.services);
      setStatus(treatmentPlan.status);
      setCoordinator(treatmentPlan.coordinator);
      setTotalTpPrice(treatmentPlan.totalPrice);
    }
  }, [dispatch, planId, treatmentPlan, successUpdate]);

  useEffect(() => {
    const initialAppointmentDates = {};
    services.forEach((service) => {
      if (service.appointment) {
        // Assuming the appointment is a Date object
        const date = new Date(service.appointment);
        // Format to 'YYYY-MM-DDThh:mm'
        const formattedDate = date.toISOString().substring(0, 16); // "16" to cut off seconds and milliseconds
        initialAppointmentDates[service._id] = formattedDate;
      } else {
        initialAppointmentDates[service._id] = "";
      }
    });
    setAppointmentDates(initialAppointmentDates);
  }, [services]);

  // LOGS
  // console.log("planId", planId);
  // console.log("treatmentPlan", treatmentPlan);

  // console.log("employee", employee);
  // console.log("plan", treatmentPlan);
  console.log("treatmentPlan", treatmentPlan);

  return (
    <>
      {loading || loadingDisplay ? (
        <Flex justify="center" align="center" h="100%">
          <Spinner color={primary} />
        </Flex>
      ) : error ? (
        <Flex
          direction="column"
          justify="center"
          align="center"
          margin="0 auto 2rem"
          p="0 0 0rem"
          w="100%"
          h="100%"
          borderRadius="0.75rem"

          // border={isSinglePage ? `1px dashed ${newBorder}` : "none"}
        >
          <Image w="initial" h="4rem" m="1rem 0 0" src={noServices} />
          <Text mt={4} color={textPrimary} fontSize="lg" fontWeight="600">
            {t("treatmentPlan.notFound")}
          </Text>
          <Text mt={1} color={textSecondary} fontSize="sm">
            {t("treatmentPlan.notFoundText")}
          </Text>
          <Button
            mt={4}
            onClick={close}
            borderRadius="10rem"
            fontSize="sm"
            color={textSecondary}
          >
            {t("button.close")}
          </Button>
        </Flex>
      ) : (
        <>
          <Box position="relative" h="100%" w="100%" overflow="scroll">
            <form
              onSubmit={submitHandler}
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <Header
                treatmentPlan={treatmentPlan}
                currentUser={currentUser}
                status={status}
                patient={patient}
                close={close}
                initialCoordinator={coordinator}
                employees={employees}
                handleCoordinatorChange={handleCoordinatorChange}
              />

              <Box w="100%">
                <Flex
                  direction="column"
                  w="100%"
                  align="flex-start"
                  justify="center"
                  p="7rem 2rem 0rem"
                >
                  <Flex w="100%" justify="space-between" align="center">
                    <Heading
                      fontSize="1.125rem"
                      color={textPrimary}
                      fontWeight="600"
                      m="1rem 0"
                    >
                      {t("service.services")}
                    </Heading>
                    <Text color={textSecondary} fontWeight="500" fontSize="md">
                      {t("stats.totalPrice")}:{" "}
                      <Text
                        as="span"
                        color={textPrimary}
                        fontSize="md"
                        fontWeight="500"
                      >
                        {totalTpPrice} €
                      </Text>
                    </Text>
                  </Flex>

                  <ServiceList
                    loading={loading}
                    error={error}
                    services={services}
                    addServiceToList={addServiceToList}
                    serviceStatusHandler={serviceStatusHandler}
                    removeService={removeService}
                    calculateTotalPriceForService={
                      calculateTotalPriceForService
                    }
                    saveEditedService={saveEditedService}
                    servicePaidHandler={servicePaidHandler}
                    handleEmployeeChange={handleEmployeeChange}
                    employeeOptions={employeeOptions}
                    employees={employees}
                    appointmentDates={appointmentDates}
                    moveServiceUp={moveServiceUp}
                    moveServiceDown={moveServiceDown}
                    addServiceModalOpen={addServiceModalOpen}
                    setAddServiceModalOpen={setAddServiceModalOpen}
                    isEdit
                    status={status}
                    handleCoordinatorChange={handleCoordinatorChange}
                    initialCoordinator={coordinator}
                    w="70%"
                  />
                </Flex>
              </Box>

              <Flex
                mt={4}
                justify="center"
                direction="column"
                borderTop={`1px solid ${newBorder}`}
                p="1.5rem 2rem"
                position="relative"
                zIndex={2}
                bg={white}
                bottom="0"
                // left="33px"
                // width="calc(100% - 67px)"
                w="100%"
              >
                <Flex justify="space-between" align="center">
                  <Flex align="center"></Flex>

                  <Flex align="center">
                    <Flex align="center" mr={8}>
                      <Text
                        color={textSecondary}
                        fontSize="sm"
                        fontWeight="500"
                        mr={2}
                      >
                        {t("treatmentPlan.lastUpdated")}:
                      </Text>
                      <Text
                        color={textPrimary}
                        fontSize="sm"
                        fontWeight="500"
                        mr={2}
                        textTransform="capitalize"
                      >
                        {dayjs(treatmentPlan.updatedAt).format(
                          "MMMM DD, HH:mm"
                        )}
                      </Text>
                    </Flex>

                    {!isFormValid && (
                      <Text
                        color="red.500"
                        fontWeight="500"
                        fontSize="sm"
                        textAlign="center"
                        mr={8}
                      >
                        {t("service.errorQuantityZero")}
                      </Text>
                    )}

                    <Flex align="center" justify="flex-end">
                      {/* <Button
                        onClick={() => handlePrintPdf(treatmentPlan._id)}
                        bg="blue.500" // Example color, adjust as needed
                        color="white"
                        _hover={{ bg: "blue.600" }} // Example hover color, adjust as needed
                      >
                        {t("common.printPdf")}
                      </Button> */}

                      <Button
                        mr={4}
                        onClick={() =>
                          handleDownloadButtonClick(treatmentPlan._id)
                        }
                        bg={buttonAltYellow}
                        color={textPrimary}
                        border={`1px solid ${newBorder}`}
                        borderRadius="10rem"
                        fontWeight="500"
                        fontSize="md"
                        size="lg"
                        role="group"
                        _hover={{
                          bg: secondary,
                          color: white,
                        }}
                        isDisabled={false}
                      >
                        {loadingDownloadPdf ? (
                          <Flex align="center">
                            <Spinner
                              color={textPrimary}
                              _groupHover={{
                                color: white,
                              }}
                              size="sm"
                              mr={2}
                            />
                            {t("common.downloading")}
                          </Flex>
                        ) : (
                          t("common.downloadPdf")
                        )}
                      </Button>

                      <Button
                        mr={4}
                        bg={white}
                        color={textSecondary}
                        border={`1px solid ${newBorder}`}
                        borderRadius="10rem"
                        fontWeight="500"
                        fontSize="md"
                        size="lg"
                        onClick={exit}
                      >
                        {t("button.cancel")}
                      </Button>
                      <Button
                        bg={primary}
                        color={white}
                        onClick={saveAndExit}
                        borderRadius="10rem"
                        fontWeight="500"
                        fontSize="md"
                        size="lg"
                        _hover={{
                          bg: secondary,
                        }}
                        isDisabled={
                          !isFormValid ||
                          services.length === 0 ||
                          status === "cancelled"
                        }
                      >
                        {t("button.saveAndExit")}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </form>
          </Box>
        </>
      )}
    </>
  );
};

export default EditPatientTreatmentPlan;
