import React from "react";
import {
  Text,
  Avatar,
  Stack,
  HStack,
  Image,
  Circle,
  Box,
  Tooltip,
  Icon,
  Progress,
  Flex,
  Button,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  textSecondary,
  textPrimary,
  buttonBlue,
  buttonBlueText,
  newBorder,
  buttonGreenText,
  buttonRedText,
  white,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";
import {
  FaRegNoteSticky,
  FaRegCircleCheck,
  FaRegCircle,
  FaRegCircleDot,
} from "react-icons/fa6";
import { format } from "morgan";
import { deleteTreatmentPlan } from "../../../../actions/treatmentPlanActions";
import {
  TREATMENTPLAN_DELETE_RESET,
  TREATMENTPLAN_APPROVE_RESET,
  TREATMENTPLAN_CANCEL_RESET,
} from "../../../../constants/treatmentPlanConstants";

export const serviceColumns = (
  dispatch,
  translate,
  updateTreatmentPlanStatus,
  approveTreatmentPlan,
  services,
  userId,
  cancelTreatmentPlan
) => {
  const deleteTreatmentPlanHandler = (treatmentPlanId) => {
    console.log("deleteTreatmentPlanHandler", treatmentPlanId);
    dispatch(deleteTreatmentPlan(treatmentPlanId));
    dispatch({ type: TREATMENTPLAN_DELETE_RESET });
  };

  const updateTreatmentPlanStatusHandler = (treatmentPlanId, status) => {
    console.log("updateTreatmentPlanStatusHandler", treatmentPlanId, status);
    dispatch(updateTreatmentPlanStatus(treatmentPlanId, status));
  };

  const approveTreatmentPlanHandler = (treatmentPlanId) => {
    console.log("approveTreatmentPlanHandler", treatmentPlanId);
    dispatch(approveTreatmentPlan(treatmentPlanId));
    dispatch({ type: TREATMENTPLAN_APPROVE_RESET });
  };

  const cancelTreatmentPlanHandler = (treatmentPlanId, userId) => {
    console.log("cancelTreatmentPlanHandler", treatmentPlanId);
    dispatch(cancelTreatmentPlan(treatmentPlanId));
    dispatch({ type: TREATMENTPLAN_CANCEL_RESET });
  };

  // console.log("plans", plans);
  // console.log("userId", userId);

  return [
    {
      Header: translate("employeeServices.tpId"),
      accessor: (row) => {
        const treatmentPlanID = row.treatmentPlanID;

        return (
          <Text
            maxWidth="60px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="11px"
            fontWeight="500"
            color={textSecondary}
          >
            {treatmentPlanID}
          </Text>
        );
      },
    },

    {
      Header: translate("employee.columnPatient"),
      accessor: (row) => {
        const { patient } = row;

        const name = patient
          ? patient.lastName + " " + patient.firstName
          : "N/A";

        return (
          <Flex align="center" h="100%">
            <Stack gap={0}>
              <Tooltip label={name} placement="top">
                <Flex align="center">
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={textPrimary}
                    lineHeight={1}
                    isTruncated
                    maxWidth="80px" // Adjust the width as needed
                  >
                    {patient.lastName}
                  </Text>
                  <Text
                    as="span"
                    color={textSecondary}
                    fontWeight="500"
                    fontSize="sm"
                    ml={1}
                  >
                    {patient.firstName[0]}.
                  </Text>
                </Flex>
              </Tooltip>
              <Text
                fontSize="13px"
                color={textSecondary}
                textTransform="capitalize"
                lineHeight={1}
              >
                {patient.birthDate}
              </Text>
            </Stack>
          </Flex>
        );
      },
    },

    {
      Header: translate("service.service"),
      accessor: (row) => {
        const { service, treatmentPlanId } = row;

        // console.log("row", row);

        // console.log("treatmentPlanId", treatmentPlanId);

        return (
          <Flex align="center" h="100%">
            <Tooltip label={service.name} placement="top">
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textPrimary}
                whiteSpace="nowrap"
                isTruncated
                maxWidth="160px"
              >
                {service.name}
                {/* <Text as="span" color={textSecondary} fontWeight="500" ml={1}>
                aa
              </Text> */}
              </Text>
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      Header: translate("service.quantity"),
      accessor: (row) => {
        const { service, quantity } = row;

        console.log("row", row);

        // console.log("row", row);

        // console.log("treatmentPlanId", treatmentPlanId);

        return (
          <Flex align="center" h="100%">
            <Tooltip label={service.name} placement="top">
              <Text fontSize="13px" fontWeight="500" color={textSecondary}>
                {quantity}
                {/* <Text as="span" color={textSecondary} fontWeight="500" ml={1}>
                aa
              </Text> */}
              </Text>
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      Header: translate("patient.columnDate"),
      disableSortBy: false,
      accessor: (row) => {
        const { appointment } = row;

        const isCurrentYear = dayjs().year() === dayjs(appointment).year();

        if (appointment === null) {
          return (
            <HStack gap={2}>
              <Stack gap={0} overflow="hidden">
                <Text
                  fontSize="13px"
                  color={textSecondary}
                  fontWeight="500"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  isTruncated
                  textTransform="capitalize"
                >
                  -
                </Text>
              </Stack>
            </HStack>
          );
        }

        return (
          <HStack gap={2}>
            <Stack gap={0} overflow="hidden">
              {appointment && (
                <Text
                  fontSize="13px"
                  color={textSecondary}
                  fontWeight="500"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  isTruncated
                  textTransform="capitalize"
                >
                  {dayjs(appointment).format("MMM D : HH:mm")}
                </Text>
              )}
              {!isCurrentYear && (
                <Text
                  fontSize="xs"
                  color={textSecondary}
                  fontWeight="500"
                  isTruncated
                  noOfLines={1}
                  whiteSpace="normal"
                  overflow="hidden"
                  w="100%"
                  maxWidth="100px"
                >
                  {dayjs(appointment).format("YYYY")}
                </Text>
              )}
            </Stack>
          </HStack>
        );
      },
    },

    {
      Header: translate("patient.columnAction"),
      accessor: (row) => {
        const { status, _id } = row;

        const handleUpdateStatus = (status) => {
          updateTreatmentPlanStatusHandler(_id, status);
        };

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              isUnclickable
              isSmall
              currentStatus={status && status}
              onUpdateStatus={handleUpdateStatus}
              options="service"
            />

            {/* <Button
              onClick={handleApproveClick}
              size="sm"
              colorScheme="green"
              isDisabled={[
                "ongoing",
                "completed",
                "draft",
                "cancelled",
              ].includes(status)}
            >
              Approve
            </Button> */}
            {/* 
            <MoreButton
              isSmall
              id={_id}
              viewHandler={() => console.log("viewHandler")}
              viewLabel={translate("treatmentPlan.viewPatient")}
            /> */}
          </HStack>
        );
      },
    },
  ];
};
