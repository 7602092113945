import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Tooltip,
  Icon,
  Box,
  Heading,
  IconButton,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  TbCalendarEvent,
  TbUserBolt,
  TbDeviceMobile,
  TbMail,
  TbMapPin,
  TbBuilding,
} from "react-icons/tb";
import {
  textPrimary,
  textSecondary,
  white,
  backgroundLight,
  newBorder,
} from "../../utils/colors";
import { icons } from "../../utils/icons";
import { useTranslation } from "react-i18next";

const SingleInfo = ({ data }) => {
  const { t } = useTranslation();

  const [place, setPlace] = useState("");
  const [city, setCity] = useState("");

  useEffect(() => {
    if (data?.address) {
      setPlace(data?.address[0]?.street + `, ` + data?.address[0]?.zip || "");
      setCity(data?.address[0]?.city || "");
    }
  }, [data]);

  const MiniSliderArrowBackIcon = () => (
    <img src={icons.miniSliderArrowBack} alt="<" />
  );
  const MiniSliderArrowForwardIcon = () => (
    <img src={icons.miniSliderArrowForward} alt=">" />
  );

  console.log("data in single info", data);

  const infoArray = [
    {
      label: "Birthdate",
      value: (
        <Flex alignItems="center">
          <Icon as={TbCalendarEvent} mr="0.5rem" color={textSecondary} />
          <Text fontSize="1rem" color={textPrimary} fontWeight="500">
            {`${dayjs(data.birthDate).format("D MMMM YYYY")} (${dayjs().diff(
              data.birthDate,
              "years"
            )}m.)`}
          </Text>
        </Flex>
      ),
    },
    {
      label: "Role",
      value: (
        <Tooltip
          p="1rem"
          label={
            <Flex alignItems="center">
              <Icon as={TbUserBolt} mt="-2px" mr="0.5rem" color={white} />
              <Text fontSize="1rem" color={white} fontWeight="500">
                {data.profession?.name || "-"}
              </Text>
            </Flex>
          }
          placement="top"
        >
          <Flex alignItems="center">
            <Icon as={TbUserBolt} mr="0.5rem" color={textSecondary} />
            <Text fontSize="1rem" color={textPrimary} fontWeight="500">
              {data.profession?.name || "-"}
            </Text>
          </Flex>
        </Tooltip>
      ),
    },
    {
      label: "Phone",
      value: (
        <Tooltip
          p="1rem"
          label={
            <Flex alignItems="center">
              <Icon as={TbDeviceMobile} mt="-2px" mr="0.5rem" color={white} />
              <Text fontSize="1rem" color={white} fontWeight="500">
                {data.phone || "-"}
              </Text>
            </Flex>
          }
          placement="top"
        >
          <Flex alignItems="center">
            <Icon as={TbDeviceMobile} mr="0.5rem" color={textSecondary} />
            <Text fontSize="1rem" color={textPrimary} fontWeight="500">
              {data.phone || "-"}
            </Text>
          </Flex>
        </Tooltip>
      ),
    },
    {
      label: "Email",
      value: (
        <Tooltip
          p="1rem"
          label={
            <Flex alignItems="center">
              <Icon as={TbMail} mt="-2px" mr="0.5rem" color={white} />
              <Text fontSize="1rem" color={white} fontWeight="500">
                {data.email || "-"}
              </Text>
            </Flex>
          }
          placement="top"
        >
          <Flex alignItems="center">
            <Icon as={TbMail} mr="0.5rem" color={textSecondary} />
            <Text fontSize="1rem" color={textPrimary} fontWeight="500">
              {data.email || "-"}
            </Text>
          </Flex>
        </Tooltip>
      ),
    },
    {
      label: "Address",
      value: (
        <Tooltip
          p="1rem"
          label={
            <Flex alignItems="center">
              <Icon as={TbMapPin} mt="-2px" mr="0.5rem" color={white} />
              <Text fontSize="1rem" color={white} fontWeight="500">
                {place || "-"}
              </Text>
            </Flex>
          }
          placement="top"
        >
          <Flex alignItems="center">
            <Icon as={TbMapPin} mr="0.5rem" color={textSecondary} />
            <Text fontSize="1rem" color={textPrimary} fontWeight="500">
              {place || "-"}
            </Text>
          </Flex>
        </Tooltip>
      ),
    },
    {
      label: "City",
      value: (
        <Tooltip
          p="1rem"
          label={
            <Flex alignItems="center">
              <Icon as={TbBuilding} mt="-2px" mr="0.5rem" color={white} />
              <Text fontSize="1rem" color={white} fontWeight="500">
                {city || "-"}
              </Text>
            </Flex>
          }
          placement="top"
        >
          <Flex alignItems="center">
            <Icon as={TbBuilding} mr="0.5rem" color={textSecondary} />
            <Text fontSize="1rem" color={textPrimary} fontWeight="500">
              {city || "-"}
            </Text>
          </Flex>
        </Tooltip>
      ),
    },
  ];

  return (
    <Box w="45%" pr={8}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading fontSize="md" fontWeight="600" color={textPrimary}>
          {t("patient.patientDetails")}
        </Heading>
        <Box>
          {/* <Button
                        variant="link"
                        color={primary}
                        fontSize="sm"
                        fontWeight="600"
                        mr={2}
                        opacity="0.8"
                      >
                        {t('button.edit')}
                      </Button> */}
          <IconButton
            h="2.5rem"
            w="1.5rem"
            bg={white}
            border={`1px solid ${newBorder}`}
            icon={<MiniSliderArrowBackIcon />}
            borderRadius="10.5rem"
            opacity="0.8"
            _hover={{
              cursor: "not-allowed",
              background: backgroundLight,
            }}
          />
          <IconButton
            h="2.5rem"
            w="1.5rem"
            bg={white}
            border={`1px solid ${newBorder}`}
            icon={<MiniSliderArrowForwardIcon />}
            borderRadius="10.5rem"
            ml="0.5rem"
            opacity="0.5"
            _hover={{
              cursor: "not-allowed",
              background: backgroundLight,
            }}
          />
        </Box>
      </Flex>
      <Box>
        {infoArray.map((item, index) => (
          <Flex key={index} alignItems="center">
            {item.value && (
              <Flex p="0.35rem 0" alignItems="center">
                {item.value}
              </Flex>
            )}
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default SingleInfo;
