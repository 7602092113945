import axios from "axios";
import {
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_DETAILS_RESET,
  USER_LIST_FAIL,
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_UPDATE_STATUS_REQUEST,
  USER_UPDATE_STATUS_SUCCESS,
  USER_UPDATE_STATUS_FAIL,
  USER_PATIENTS_LIST_REQUEST,
  USER_PATIENTS_LIST_SUCCESS,
  USER_PATIENTS_LIST_FAIL,
  USER_EMPLOYEES_LIST_REQUEST,
  USER_EMPLOYEES_LIST_SUCCESS,
  USER_EMPLOYEES_LIST_FAIL,
  USER_ADD_NOTE_REQUEST,
  USER_ADD_NOTE_SUCCESS,
  USER_ADD_NOTE_FAIL,
  USER_LIST_NOTES_REQUEST,
  USER_LIST_NOTES_SUCCESS,
  USER_LIST_NOTES_FAIL,
  USER_CANCEL_ALL_TREATMENTS_REQUEST,
  USER_CANCEL_ALL_TREATMENTS_SUCCESS,
  USER_CANCEL_ALL_TREATMENTS_FAIL,
  PATIENT_STATISTICS_REQUEST,
  PATIENT_STATISTICS_FAIL,
  PATIENT_STATISTICS_SUCCESS,
  USER_CREATE_EMPLOYEE_REQUEST,
  USER_CREATE_EMPLOYEE_SUCCESS,
  USER_CREATE_EMPLOYEE_FAIL,
  USER_CREATE_EMPLOYEE_RESET,
  USER_EMPLOYEE_DETAILS_REQUEST,
  USER_EMPLOYEE_DETAILS_SUCCESS,
  USER_EMPLOYEE_DETAILS_FAIL,
  USER_EMPLOYEE_DETAILS_RESET,
  USER_DOCTOR_SERVICES_REQUEST,
  USER_DOCTOR_SERVICES_SUCCESS,
  USER_DOCTOR_SERVICES_FAIL,
  USER_DOCTOR_SERVICES_RESET,
  USER_ADDRESS_STATES_REQUEST,
  USER_ADDRESS_STATES_SUCCESS,
  USER_ADDRESS_STATES_FAIL,
  USER_SEND_TREATMENTPLAN_LIST_EMAIL_REQUEST,
  USER_SEND_TREATMENTPLAN_LIST_EMAIL_SUCCESS,
  USER_SEND_TREATMENTPLAN_LIST_EMAIL_FAIL,
  USER_PATIENTS_SIMPLE_LIST_REQUEST,
  USER_PATIENTS_SIMPLE_LIST_SUCCESS,
  USER_PATIENTS_SIMPLE_LIST_FAIL,
  USER_PATIENTS_SIMPLE_LIST_RESET,
} from "../constants/userConstants";
import { ORDER_LIST_MY_RESET } from "../constants/orderConstants";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/users/login",
      { email, password },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    console.log("Login data:", data);
    console.log("Dispatching USER_LOGIN_SUCCESS with payload:", data);

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("cartItems");
  localStorage.removeItem("shippingAddress");
  localStorage.removeItem("paymentMethod");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: ORDER_LIST_MY_RESET });
  dispatch({ type: USER_LIST_RESET });
  document.location.href = "/login";
};

export const createUser =
  (
    tenantID,
    firstName,
    lastName,
    email,
    birthDate,
    phone,
    phoneAlt,
    gender,
    child,
    personalCode,
    role,
    street,
    zip,
    city,
    country,
    state,
    iban,
    bankName,
    licenseNumber,
    status
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_CREATE_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/users/patients/create`,
        {
          tenantID,
          firstName,
          lastName,
          email,
          birthDate,
          phone,
          phoneAlt,
          gender,
          child,
          personalCode,
          role,

          street,
          zip,
          city,
          country,
          state,
          iban,
          bankName,
          licenseNumber,
          status,
        },
        config
      );

      dispatch({
        type: USER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

export const register =
  (firstName, lastName, image, email, password, role) => async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "/api/users",
        { firstName, lastName, image, email, password, role },
        config
      );

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/users/${id}`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const getUserEmployeeDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_EMPLOYEE_DETAILS_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/users/employee/${id}`, config);

    dispatch({
      type: USER_EMPLOYEE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_EMPLOYEE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/users/profile`, user, config);

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    });
  }
};

export const listUsers =
  (keyword = "", pageNumber = "", pageSize = "", role = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: USER_LIST_REQUEST });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      console.log("listUsers token", userInfo.token);

      // Include the role parameter in the URL query string
      const url = `/api/users?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}&role=${role}`;

      const { data } = await axios.get(url, config);

      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/users/${id}`, config);

    dispatch({ type: USER_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/users/${user._id}`, user, config);

    dispatch({ type: USER_UPDATE_SUCCESS });

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });

    dispatch({ type: USER_DETAILS_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const updateUserStatus =
  (userId, newStatus) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_STATUS_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/users/${userId}/status`,
        { status: newStatus }, // Send the new status in the request body
        config
      );

      dispatch({
        type: USER_UPDATE_STATUS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_UPDATE_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listUsersPatients =
  (
    searchTerm = "",
    page = 1,
    pageSize = 10,
    sortField = "",
    sortOrder = "",
    minAge = "",
    maxAge = "",
    state = "",
    status = "",
    startDate = "",
    endDate = ""
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_PATIENTS_LIST_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Constructing the query string
      let query = `/api/users/patients?`;
      query += `searchTerm=${encodeURIComponent(searchTerm)}`;
      query += `&pageNumber=${page}`;
      query += `&pageSize=${pageSize}`;
      if (sortField) query += `&sortField=${sortField}`;
      if (sortOrder) query += `&sortOrder=${sortOrder}`;
      if (minAge) query += `&minAge=${encodeURIComponent(minAge)}`;
      if (maxAge) query += `&maxAge=${encodeURIComponent(maxAge)}`;
      if (state) query += `&state=${encodeURIComponent(state)}`;
      if (status) query += `&status=${encodeURIComponent(status)}`;
      if (startDate) query += `&startDate=${encodeURIComponent(startDate)}`;
      if (endDate) query += `&endDate=${encodeURIComponent(endDate)}`;

      const { data } = await axios.get(query, config);

      dispatch({
        type: USER_PATIENTS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_PATIENTS_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listUsersEmployees =
  (
    searchTerm = "",
    page = 1,
    pageSize = 10,
    sortField = "",
    sortOrder = "",
    status = "",
    startDate = "",
    endDate = "",
    profession = ""
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_EMPLOYEES_LIST_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Constructing the query string
      let query = `/api/users/employees?`;
      query += `searchTerm=${encodeURIComponent(searchTerm)}`;
      query += `&pageNumber=${page}`;
      query += `&pageSize=${pageSize}`;
      if (sortField) query += `&sortField=${sortField}`;
      if (sortOrder) query += `&sortOrder=${sortOrder}`;

      if (status) query += `&status=${encodeURIComponent(status)}`;
      if (startDate) query += `&startDate=${encodeURIComponent(startDate)}`;
      if (endDate) query += `&endDate=${encodeURIComponent(endDate)}`;
      if (profession) query += `&profession=${encodeURIComponent(profession)}`;

      // console.log("listUsersEmployees query", query);

      const { data } = await axios.get(query, config);

      // console.log("listUsersEmployees data", data);

      dispatch({
        type: USER_EMPLOYEES_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_EMPLOYEES_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addNoteToUser =
  (userId, noteText) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_ADD_NOTE_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/users/${userId}/add-note`,
        { text: noteText },
        config
      );

      dispatch({
        type: USER_ADD_NOTE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_ADD_NOTE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listNotesOfUser = (userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_NOTES_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/users/${userId}/notes`, config);

    dispatch({
      type: USER_LIST_NOTES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_NOTES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const cancelAllTreatments = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_CANCEL_ALL_TREATMENTS_REQUEST });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.put(`/api/users/${userId}/cancel-treatment`, {}, config);

    dispatch({
      type: USER_CANCEL_ALL_TREATMENTS_SUCCESS,
    });

    // Optionally, you might want to update user details after cancellation
    // dispatch(getUserDetails(userId));
  } catch (error) {
    dispatch({
      type: USER_CANCEL_ALL_TREATMENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getPatientStatistics = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PATIENT_STATISTICS_REQUEST });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get("/api/users/patients/stats", config);

    dispatch({
      type: PATIENT_STATISTICS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_STATISTICS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createEmployee =
  (
    firstName,
    lastName,
    email,
    birthDate,
    phone,
    phoneAlt,
    gender,
    child,
    personalCode,
    role,
    profession,
    street,
    zip,
    city,
    state,
    country,
    iban,
    bankName,
    licenseNumber,
    rolePermission,
    password
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_CREATE_EMPLOYEE_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      let sendPassword = null;
      if (password === "") {
        sendPassword = null;
      } else {
        sendPassword = password;
      }

      // Prepare the data payload
      const dataPayload = {
        firstName,
        lastName,
        email,
        birthDate,
        phone,
        phoneAlt,
        gender,
        child,
        personalCode,
        role,
        profession,
        street,
        zip,
        city,
        state,
        country,
        iban,
        bankName,
        licenseNumber,
        rolePermission,
        password: sendPassword,
      };

      // Make the API call
      const { data } = await axios.post(
        `/api/users/create-employee`,
        dataPayload,
        config
      );

      dispatch({
        type: USER_CREATE_EMPLOYEE_SUCCESS,
        payload: data,
      });

      console.log("USER_CREATE_EMPLOYEE_SUCCESS data", data);
    } catch (error) {
      dispatch({
        type: USER_CREATE_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

export const listDoctorServices = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DOCTOR_SERVICES_REQUEST });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Use the doctorId in the request URL
    const { data } = await axios.get(
      `/api/users/employee/${userId}/services`,
      config
    );

    dispatch({
      type: USER_DOCTOR_SERVICES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DOCTOR_SERVICES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllAddressStates = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_ADDRESS_STATES_REQUEST });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/users/address-states`, config);

    dispatch({
      type: USER_ADDRESS_STATES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ADDRESS_STATES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const sendTreatmentPlanListEmail =
  (userId) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_SEND_TREATMENTPLAN_LIST_EMAIL_REQUEST });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/users/${userId}/send-treatment-plans`,
        {}, // You can pass an empty object if the POST request doesn't require a body
        config
      );

      dispatch({
        type: USER_SEND_TREATMENTPLAN_LIST_EMAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_SEND_TREATMENTPLAN_LIST_EMAIL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// export const listPatientsSimple = () => async (dispatch, getState) => {
//   try {
//     dispatch({ type: USER_PATIENTS_SIMPLE_LIST_REQUEST });

//     const {
//       authLogin: { userInfo },
//     } = getState();

//     const config = {
//       headers: {
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     };

//     const { data } = await axios.get(`/api/users/patients-simple`, config);

//     dispatch({
//       type: USER_PATIENTS_SIMPLE_LIST_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: USER_PATIENTS_SIMPLE_LIST_FAIL,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };

export const listPatientsSimple =
  (search = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: USER_PATIENTS_SIMPLE_LIST_REQUEST });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/users/patients-simple${search ? `?search=${search}}` : ""}`,
        config
      );

      dispatch({
        type: USER_PATIENTS_SIMPLE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_PATIENTS_SIMPLE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
