import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

const useGenerateTreatmentPlanPdf = () => {
  const generatePdf = (treatmentPlan, viewOnly = false) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 20;

    // Start the table slightly lower down the page
    const startY = 55;

    // Set styles for the document
    const baseColor = [150, 150, 150]; // Grey color
    const borderColor = [220, 220, 220]; // Light grey border color

    // Draw a rounded rectangle
    doc.setDrawColor(...baseColor);
    doc.setFillColor(245, 245, 245); // Light grey fill
    doc.roundedRect(15, 20, 180, 12, 3, 3, "FD"); // Replace 'FD' with 'S' to not fill

    // Add clinic logo and text
    // doc.addImage(logo, 'JPEG', 20, 22, 10, 10); // add your logo image here
    doc.setFontSize(10);
    doc.setTextColor(...baseColor);
    doc.setFont("helvetica", "bold");
    doc.text("Tarptautinis Implantologijos Centras", 35, 27);
    doc.setFontSize(8);
    doc.text("Kalvariju g. 272a, Vilnius", 35, 32);

    // Draw a separator line
    doc.setDrawColor(...borderColor);
    doc.line(15, 34, 195, 34); // x1, y1, x2, y2

    // Add treatment plan details
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");
    doc.text(`Treatment Plan TP-${treatmentPlan.id}`, 20, 45);
    doc.setFontSize(9);
    doc.text(`Patient: ${treatmentPlan.patientName}`, 20, 50);
    // Add more details...

    // Generate the table with autoTable
    autoTable(doc, {
      startY: startY,
      theme: "plain",
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.1,
        lineColor: [220, 220, 220], // Light grey lines
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.1,
        lineColor: [220, 220, 220], // Light grey lines
        cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245],
      },
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: "auto" },
        2: { cellWidth: "auto" },
        3: { cellWidth: "auto" },
        4: { cellWidth: "auto" },
        // Set dashed borders for cells here if possible
      },
      //  Define head and body based on treatmentPlan data
      head: [["Service Name", "Date", "Quantity", "Price", "Total"]],
      body: treatmentPlan.services.map((service) => [
        service.name,
        service.date,
        service.quantity,
        service.price,
        service.total,
      ]),
    });

    // Now that we have the final Y position of the autoTable, draw a rounded rectangle around it
    const tableHeight = doc.lastAutoTable.finalY - startY;
    doc.roundedRect(margin, startY, pageWidth - 2 * margin, tableHeight, 3, 3);

    // Footer with total price
    const finalY = doc.lastAutoTable.finalY || 80;
    doc.setTextColor(...baseColor);
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(
      `Total Price: ${treatmentPlan.totalPrice.toFixed(2)} €`,
      20,
      finalY + 10
    );

    // Footer with generated date and responsible person
    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(
      `Generated on: ${new Date().toLocaleDateString()}`,
      20,
      finalY + 20
    );
    // doc.text(`Responsible Person: ${treatmentPlan.responsiblePerson}`, 20, finalY + 25);

    // Save or display the PDF
    if (viewOnly) {
      window.open(doc.output("bloburl"), "_blank");
    } else {
      doc.save("treatment-plan.pdf");
    }
  };

  return generatePdf;
};

export default useGenerateTreatmentPlanPdf;
