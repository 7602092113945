import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Flex,
  Icon,
  Box,
  List,
  ListItem,
  useToast,
  Tooltip,
  IconButton,
  Select,
  Button,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import {
  textSecondary,
  textPrimary,
  newBorder,
  white,
  primary,
  buttonBlue,
  buttonBlueText,
  buttonBlueHover,
  buttonBlueDark,
  buttonRed,
  buttonRedText,
  buttonRedDark,
  backgroundLight,
  secondary,
  backgroundGrey,
} from "../../utils/colors";
import {
  FaRegCalendarXmark,
  FaRegCalendar,
  FaCaretDown,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa6";
import dayjs from "dayjs"; // import dayjs
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

dayjs.extend(utc);
dayjs.extend(timezone);

// Custom hook to handle click outside
function useClickOutside(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

const DatePopover = ({
  initialDate,
  service,
  saveEditedService,
  isDisabled,
}) => {
  // TRANSLATION
  const { t } = useTranslation();

  // CONSTANTS

  const initialDateObject = initialDate ? dayjs(initialDate).toDate() : "";
  const [date, setDate] = useState(initialDateObject);
  const [tempDate, setTempDate] = useState(date);
  const [prevDate, setPrevDate] = useState(null); // State to track the previous date

  const [isOpen, setIsOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const toast = useToast();

  // Generate a range of years from current year - 5 to current year + 5
  const currentYear = dayjs().year();
  const years = Array.from({ length: 11 }, (_, i) => currentYear - 5 + i);
  const months = [
    t("months.january"),
    t("months.february"),
    t("months.march"),
    t("months.april"),
    t("months.may"),
    t("months.june"),
    t("months.july"),
    t("months.august"),
    t("months.september"),
    t("months.october"),
    t("months.november"),
    t("months.december"),
  ];

  // HANDLERS

  const handleDateChange = (newDate) => {
    if (newDate instanceof Date) {
      const newDateFormat = dayjs(newDate).format("YYYY-MM-DD");
      const prevDateFormat = prevDate
        ? dayjs(prevDate).format("YYYY-MM-DD")
        : null;
      const timeIsDefault = dayjs(newDate).format("HH:mm") === "00:00";

      if (newDateFormat !== prevDateFormat) {
        // If the date part has changed, reset the time to 08:00
        newDate = dayjs(newDate)
          .hour(8)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate();
      }

      setDate(newDate); // Update the date state
      setPrevDate(newDate); // Update the previous date state

      const isoDate = dayjs(newDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      saveEditedService({
        ...service,
        appointment: isoDate,
      });

      if (!timeIsDefault && newDateFormat !== prevDateFormat) {
        setIsOpen(true);
      }
    }
  };

  const handleMouseEnter = () => {
    if (!isOpen) {
      setShowTooltip(true); // Show tooltip only if date picker is not open
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false); // Hide tooltip on mouse leave
  };

  const handleClearDate = () => {
    setDate(null); // Clear the date
    saveEditedService({
      ...service,
      appointment: null, // Set appointment to null when cleared
    });
  };

  const roundToNearestInterval = (date, interval) => {
    const start = dayjs(date);
    const remainder = start.minute() % interval;
    return remainder < interval / 2
      ? start.subtract(remainder, "minute")
      : start.add(interval - remainder, "minute");
  };

  const handleSetToday = () => {
    let now = new Date();
    now = roundToNearestInterval(now, 5).toDate(); // Convert to JavaScript Date object

    setDate(now); // Set the date to today
    setPrevDate(now); // Set the previous date to today
    const isoDate = dayjs(now).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    saveEditedService({
      ...service,
      appointment: isoDate,
    });
  };

  // Custom Input for DatePicker
  const CustomInput = React.forwardRef(({ onClick }, ref) => (
    <Input
      onClick={() => {
        onClick(); // Call the onClick function passed by DatePicker
        setIsOpen(true); // Open the date picker when the input is clicked
      }}
      //   ref={ref}
      readOnly
      fontSize="sm"
      fontWeight="500"
      className={date ? "datePopoverDatepicker" : "datePopoverDatepickerNoDate"}
      value={
        date ? dayjs(date).format("MMMM DD, HH:mm") : t("button.noAppointment")
      }
      size="sm"
      w="100%"
    />
  ));

  // Ref for the entire popover
  const popoverRef = useRef(null);

  // Close the date picker if a click is detected outside
  const handleClose = () => setIsOpen(false);

  // Use the custom hook
  useClickOutside(popoverRef, handleClose);

  //USE EFFECT
  useEffect(() => {
    if (date === null) {
      setIsOpen(false); // Close the popover if the date is null
    }
  }, [date]);

  useEffect(() => {
    // Hide tooltip when date picker is open
    if (isOpen) {
      setShowTooltip(false);
    }

    if (initialDate) {
      const parsedDate = dayjs(initialDate).toDate(); // Parse ISO 8601 to Date object
      setDate(parsedDate);
      setTempDate(parsedDate);
    } else {
    }

    if (isOpen) {
      // Timeout to allow the popover content to be rendered.
      setTimeout(() => {
        // Query the selected time list item in the DOM.
        const selectedTimeListItem = document.querySelector(
          ".react-datepicker__time-list-item--selected"
        );
        if (selectedTimeListItem) {
          // Scroll the selected time list item into view.
          selectedTimeListItem.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }
      }, 0);
    }
  }, [initialDate, isOpen]); // Add isOpen as a dependency

  // Get the current date at the start of the day
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to 00:00:00 to start from the beginning of the day

  // LOGS
  console.log("date", date);
  // console.log("initialDate", initialDate);
  // console.log("isDisabled", isDisabled);

  return (
    <>
      <Tooltip
        label={t("popover.selectAppointment")}
        placement="bottom"
        bg={secondary}
        isOpen={isDisabled ? false : showTooltip}
        hasArrow
      >
        <Flex
          ref={popoverRef}
          align="center"
          borderRadius="10.75rem"
          p="0rem 0.5rem"
          transition="0.2s all"
          role="group"
          _hover={{
            background: backgroundLight,
            cursor: "pointer",
          }}
          className={`appointmentPopoverDatepicker ${
            isDisabled ? "isDisabled" : ""
          }`}
          w={initialDate === null ? "8rem" : "11.25rem"}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Flex
            align="center"
            justify="center"
            w="1.25rem"
            h="1.25rem"
            bg={date ? buttonBlue : backgroundLight}
            borderRadius="0.45rem"
            mr={1.5}
            onClick={() => setIsOpen(!isOpen)}
            flexShrink={0}
          >
            <Icon
              as={date ? FaRegCalendar : FaRegCalendarXmark}
              boxSize={3}
              color={date ? textSecondary : backgroundGrey}
            />
          </Flex>
          <Box className="popoverDatePicker">
            <DatePicker
              selected={date}
              onChange={handleDateChange}
              showTimeSelect
              customInput={<CustomInput />} // Use the custom input
              timeFormat="HH:mm"
              dateFormat="yyyy-MM-dd'T'HH:mm" // Specify the format here
              timezones="Europe/Vilnius"
              timeIntervals={5}
              fontSize="0.875rem"
              minDate={today}
              open={isDisabled ? false : isOpen}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <Flex
                  m="1rem auto 1rem"
                  align="center"
                  justify="center"
                  w="100%"
                  px={5}
                >
                  <IconButton
                    size="xs"
                    fontSize="xs"
                    icon={<FaAngleLeft />}
                    bg="none"
                    onClick={decreaseMonth}
                    color={textSecondary}
                    isDisabled={prevMonthButtonDisabled}
                  />
                  <Flex align="center" mx={2}>
                    <Select
                      fontSize="sm"
                      size="sm"
                      color={textSecondary}
                      minWidth="40px"
                      maxWidth="80px"
                      borderRadius="0.75rem"
                      value={dayjs(date).year()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>

                    <Select
                      ml={1}
                      fontSize="sm"
                      size="sm"
                      color={textSecondary}
                      minWidth="100px"
                      maxWidth="200px"
                      borderRadius="0.75rem"
                      value={months[dayjs(date).month()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </Flex>

                  <IconButton
                    size="xs"
                    fontSize="xs"
                    icon={<FaAngleRight />}
                    bg="none"
                    onClick={increaseMonth}
                    color={textSecondary}
                    isDisabled={nextMonthButtonDisabled}
                  />
                </Flex>
              )}
            >
              <Flex
                justify="flex-end"
                mt={0}
                pt="1rem"
                pb={0}
                px={6}
                w="100%"
                borderTop={`1px solid ${newBorder}`}
              >
                <Button
                  size="sm"
                  fontSize="xs"
                  colorScheme="blue"
                  onClick={handleSetToday}
                  mr={2}
                  bg={buttonBlue}
                  color={buttonBlueText}
                  _hover={{
                    bg: buttonBlueHover,
                    color: buttonBlueText,
                  }}
                >
                  {t("button.today")}
                </Button>
                <Button
                  size="sm"
                  fontSize="xs"
                  colorScheme="red"
                  onClick={handleClearDate}
                  bg={buttonRed}
                  color={buttonRedText}
                  _hover={{
                    bg: buttonRedDark,
                    color: white,
                  }}
                >
                  {t("button.clear")}
                </Button>
              </Flex>
            </DatePicker>
          </Box>
          <Icon
            as={FaCaretDown}
            h="0.75rem"
            w={isOpen ? "0.75rem" : "0rem"}
            ml={0}
            opacity="0.5"
            transition="all 0.2s ease"
            transform={isOpen ? "rotate(180deg)" : "rotate(0)"} // Rotate caret when menu is open
            _groupHover={{
              opacity: 1,
              width: "0.75rem",
              transition: "all 0.2s ease",
            }}
            color={textSecondary}
          />
        </Flex>
      </Tooltip>
    </>
  );
};

export default DatePopover;
