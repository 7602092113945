import React from "react";
import {
  Text,
  Avatar,
  Stack,
  HStack,
  Image,
  Circle,
  Box,
  Tooltip,
  Icon,
  Progress,
  Flex,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  textSecondary,
  textPrimary,
  buttonBlue,
  buttonBlueText,
  newBorder,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";
import { FaRegNoteSticky } from "react-icons/fa6";
import { format } from "morgan";

export const columns = (dispatch, translate, deleteAppointmentHandler) => {
  return [
    // {
    //   Header: "#",
    //   id: "index",
    //   accessor: (_row, i) => {
    //     return (
    //       <Text
    //         maxWidth="10px"
    //         overflow="hidden"
    //         whiteSpace="nowrap"
    //         fontSize="sm"
    //         color={textSecondary}
    //       >
    //         {i + 1}
    //       </Text>
    //     );
    //   },
    // },
    {
      Header: translate("appointment.columnRoom"),
      disableSortBy: false,
      accessor: (row) => {
        // const { room } = row;

        return (
          <Circle
            h="2.5rem"
            w="2.5rem"
            bg={buttonBlue}
            color={buttonBlueText}
            fontSize="0.875rem"
            fontWeight="500"
          >
            OP1
          </Circle>
        );
      },
    },
    // {
    //   Header: translate("appointment.columnDate"),
    //   disableSortBy: false,
    //   accessor: (row) => {
    //     const { date } = row;

    //     // Use dayjs to format the date
    //     const formattedDate = dayjs(date).format("MMMM DD");

    //     return (
    //       <Stack gap={0}>
    //         <Text
    //           fontSize="sm"
    //           color={textSecondary}
    //           fontWeight="500"
    //           textTransform="capitalize"
    //         >
    //           {formattedDate}
    //         </Text>
    //       </Stack>
    //     );
    //   },
    // },
    {
      Header: translate("appointment.columnTime"),
      disableSortBy: false,
      accessor: (row) => {
        const { date, startTime, endTime } = row;

        const formattedDate = dayjs(date).format("MMMM DD");

        // Use dayjs to format the date
        const formattedStartTime = dayjs(startTime).format("HH:mm");
        const formattedEndTime = dayjs(endTime).format("HH:mm");

        const duration = dayjs(endTime).diff(dayjs(startTime), "minute");

        return (
          <Stack gap={0}>
            <Flex w="100%" color={textSecondary} fontWeight="500">
              <Text
                fontSize="sm"
                textTransform="capitalize"
                whiteSpace="nowrap"
              >
                {formattedStartTime} - {formattedEndTime}
              </Text>
              <Text ml={1} fontSize="xs">
                ({duration} min)
              </Text>
            </Flex>

            <Text
              fontSize="xs"
              color={textSecondary}
              fontWeight="500"
              textTransform="capitalize"
            >
              {formattedDate}
            </Text>
          </Stack>
        );
      },
    },
    {
      Header: translate("appointment.columnPatient"),
      disableSortBy: false,
      accessor: (row) => {
        const { patient } = row;

        if (!patient) {
          return null; // Handle the case where patient is undefined
        }

        const name = patient.firstName + " " + patient.lastName;
        const birthDate = dayjs(patient.birthDate).format("YYYY-MM-DD");

        return (
          <HStack gap={2}>
            <Stack gap={0} maxWidth="200px" overflow="hidden">
              <Text
                fontSize="sm"
                color={textPrimary}
                fontWeight="600"
                isTruncated // This will add ellipsis
                noOfLines={2} // This will limit the text to two lines
                whiteSpace="normal"
                overflow="hidden"
                w="100%"
                maxWidth="200px"
              >
                {name}
              </Text>
              {birthDate && (
                <Text
                  fontSize="xs"
                  color={textSecondary}
                  fontWeight="500"
                  isTruncated
                  noOfLines={1}
                  whiteSpace="normal"
                  overflow="hidden"
                  w="100%"
                  maxWidth="200px"
                >
                  {birthDate}
                </Text>
              )}
            </Stack>
          </HStack>
        );
      },
    },

    {
      Header: translate("appointment.columnSupplies"),
      disableSortBy: false,
      accessor: (row) => {
        return (
          <Stack gap={0}>
            <Text fontSize="sm" color={textSecondary} fontWeight="500">
              100%
            </Text>
            <Progress
              className="attendeesTotal"
              w="80px"
              value={80}
              mt="0.25rem"
              color={primary}
              size="sm"
              height="4px"
              borderRadius="0.5rem"
            />
          </Stack>
        );
      },
    },
    {
      Header: translate("appointment.columnEmployee"),
      accessor: (row) => {
        const { employee } = row;

        if (!employee) {
          return null; // Handle the case where employee is undefined
        }

        return (
          <div>
            <Flex alignItems="center">
              <Avatar
                h="1.75rem"
                w="1.75rem"
                src={employee.image || ""}
                name={employee.firstName}
                border={`1px solid ${newBorder}`}
              />
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textSecondary}
                ml={2}
                isTruncated // This will add ellipsis
                noOfLines={1} // This will limit the text to two lines
                whiteSpace="normal"
              >
                {employee.firstName}
              </Text>
            </Flex>
          </div>
        );
      },
    },
    {
      Header: translate("appointment.columnNotes"),
      disableSortBy: false,
      accessor: (row) => {
        const { notes } = row;

        if (!notes) {
          return null; // Handle the case where notes is undefined
        }

        return (
          <Box>
            <Tooltip placement="top" label={notes}>
              <Stack direction="row" alignItems="center">
                <Icon as={FaRegNoteSticky} color={textSecondary} />
              </Stack>
            </Tooltip>
          </Box>
        );
      },
    },

    {
      Header: "Status",
      accessor: (row) => {
        const { status, _id } = row;

        const handleUpdateStatus = (newStatus) => {
          // dispatch(updateSpeakerStatus(_id, newStatus));
        };

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
              options="appointment"
            />
            <MoreButton
              id={_id}
              deleteHandler={deleteAppointmentHandler}
              editHandler={(e) => {
                e.stopPropagation();
                // openEditSidePanelModal(_id);
              }}
            />
          </HStack>
        );
      },
    },
  ];
};
