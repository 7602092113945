import {
  TENANT_REGISTER_REQUEST,
  TENANT_REGISTER_SUCCESS,
  TENANT_REGISTER_FAIL,
  TENANT_REGISTER_RESET,
  TENANT_VERIFY_REQUEST,
  TENANT_VERIFY_SUCCESS,
  TENANT_VERIFY_FAIL,
  TENANT_VERIFY_RESET,
  TENANT_ALREADY_VERIFIED,
  TENANT_ALREADY_VERIFIED_RESET,
  TENANT_ALREADY_EXISTS,
  TENANT_ALREADY_EXISTS_RESET,
  TENANT_DETAILS_REQUEST,
  TENANT_DETAILS_SUCCESS,
  TENANT_DETAILS_FAIL,
  TENANT_DETAILS_RESET,
  TENANT_UPDATE_REQUEST,
  TENANT_UPDATE_SUCCESS,
  TENANT_UPDATE_FAIL,
  TENANT_UPDATE_RESET,
  TENANT_UPDATE_ADMIN_USER_REQUEST,
  TENANT_UPDATE_ADMIN_USER_SUCCESS,
  TENANT_UPDATE_ADMIN_USER_FAIL,
  TENANT_UPDATE_ADMIN_USER_RESET,
  TENANT_UPDATE_TYPE_REQUEST,
  TENANT_UPDATE_TYPE_SUCCESS,
  TENANT_UPDATE_TYPE_FAIL,
  TENANT_UPDATE_TYPE_RESET,
  TENANT_UPDATE_OPERATING_ROOMS_REQUEST,
  TENANT_UPDATE_OPERATING_ROOMS_SUCCESS,
  TENANT_UPDATE_OPERATING_ROOMS_FAIL,
  TENANT_UPDATE_OPERATING_ROOMS_RESET,
  TENANT_UPDATE_IMAGE_REQUEST,
  TENANT_UPDATE_IMAGE_SUCCESS,
  TENANT_UPDATE_IMAGE_FAIL,
  TENANT_UPDATE_IMAGE_RESET,
} from "../constants/tenantConstants";

export const tenantRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case TENANT_REGISTER_REQUEST:
      return { loading: true };
    case TENANT_REGISTER_SUCCESS:
      return { loading: false, success: true, tenantInfo: action.payload };
    case TENANT_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case TENANT_REGISTER_RESET:
      return {};
    case TENANT_ALREADY_EXISTS:
      return { loading: false, tenantExists: true };
    case TENANT_ALREADY_EXISTS_RESET:
      return { ...state, tenantExists: false };
    default:
      return state;
  }
};

export const tenantVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case TENANT_VERIFY_REQUEST:
      return { loading: true };
    case TENANT_VERIFY_SUCCESS:
      return { loading: false, success: true, verificationSuccess: true };
    case TENANT_VERIFY_FAIL:
      return { loading: false, error: action.payload };
    case TENANT_VERIFY_RESET:
      return {};
    case TENANT_ALREADY_VERIFIED:
      return { loading: false, alreadyVerified: true };
    case TENANT_ALREADY_VERIFIED_RESET:
      return {};
    default:
      return state;
  }
};

export const tenantDetailsReducer = (state = { tenant: {} }, action) => {
  switch (action.type) {
    case TENANT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case TENANT_DETAILS_SUCCESS:
      return { loading: false, success: true, tenant: action.payload };
    case TENANT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case TENANT_DETAILS_RESET:
      return { tenant: {} };
    default:
      return state;
  }
};

export const tenantUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TENANT_UPDATE_REQUEST:
      return { loading: true };
    case TENANT_UPDATE_SUCCESS:
      return { loading: false, success: true, tenant: action.payload };
    case TENANT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case TENANT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const tenantUpdateAdminUserReducer = (state = {}, action) => {
  switch (action.type) {
    case TENANT_UPDATE_ADMIN_USER_REQUEST:
      return { loading: true };
    case TENANT_UPDATE_ADMIN_USER_SUCCESS:
      return { loading: false, success: true, tenant: action.payload };
    case TENANT_UPDATE_ADMIN_USER_FAIL:
      return { loading: false, error: action.payload };
    case TENANT_UPDATE_ADMIN_USER_RESET:
      return {};
    default:
      return state;
  }
};

export const tenantUpdateTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case TENANT_UPDATE_TYPE_REQUEST:
      return { loading: true };
    case TENANT_UPDATE_TYPE_SUCCESS:
      return { loading: false, success: true, tenant: action.payload };
    case TENANT_UPDATE_TYPE_FAIL:
      return { loading: false, error: action.payload };
    case TENANT_UPDATE_TYPE_RESET:
      return {};
    default:
      return state;
  }
};

export const tenantUpdateOperatingRoomsReducer = (state = {}, action) => {
  switch (action.type) {
    case TENANT_UPDATE_OPERATING_ROOMS_REQUEST:
      return { loading: true };
    case TENANT_UPDATE_OPERATING_ROOMS_SUCCESS:
      return { loading: false, success: true, tenant: action.payload };
    case TENANT_UPDATE_OPERATING_ROOMS_FAIL:
      return { loading: false, error: action.payload };
    case TENANT_UPDATE_OPERATING_ROOMS_RESET:
      return {};
    default:
      return state;
  }
};

export const tenantUpdateImageReducer = (state = {}, action) => {
  switch (action.type) {
    case TENANT_UPDATE_IMAGE_REQUEST:
      return { loading: true };
    case TENANT_UPDATE_IMAGE_SUCCESS:
      return { loading: false, success: true, tenant: action.payload };
    case TENANT_UPDATE_IMAGE_FAIL:
      return { loading: false, error: action.payload };
    case TENANT_UPDATE_IMAGE_RESET:
      return {};
    default:
      return state;
  }
};
