import React from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import noServices from "../../assets/images/empty-box.png";
import { Flex, Image, Text } from "@chakra-ui/react";
import { textSecondary, textPrimary } from "../../utils/colors";

// Assuming options are passed as a prop now
const PieChart = ({ data, options, plotLabel }) => {
  const { t } = useTranslation();

  // Extract colors based on the order in data
  const colors = options.map((option) => option.bg);

  // Check if all data values are 0
  const allZeros = data.every((value) => value === 0);

  // No need to modify colors for zeros, as each status color is now directly mapped
  const modifiedColors = data.map(
    (value, index) => (value === 0 ? "#D3D3D3" : colors[index]) // Using grey for 0 values
  );

  const chartOptions = {
    chart: {
      sparkline: { enabled: true },
    },
    animations: {
      enabled: true,
      easing: "easeinout",
      dynamicAnimation: {
        speed: 350,
      },
    },
    colors: modifiedColors,
    stroke: { width: 0 },
    legend: { show: false },
    dataLabels: { enabled: false },
    labels: options.map((option) => t(option.label)),
    states: {
      hover: {
        filter: {
          type: "darken", // Apply a filter effect on hover
          value: 0.95, // Intensity of the hover effect
        },
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.9,
        donut: {
          size: "80%",
          labels: {
            show: true,
            name: {
              offsetY: 25,
              fontSize: "0.875rem",
              color: textSecondary, // Custom color for name labels
            },
            value: {
              offsetY: -15,
              fontWeight: 500,
              fontSize: "30px",
              color: textPrimary, // Custom color for value labels
              formatter: (value) => `${value}`,
            },
            total: {
              show: true,
              fontSize: "1rem",
              label: plotLabel,
              fontWeight: 500,
              color: textSecondary, // Custom color for total label
              formatter: (value) =>
                `${value.globals.seriesTotals.reduce(
                  (total, num) => total + num
                )}`,
            },
          },
        },
        expandOnClick: true,
      },
    },

    tooltip: {
      enabled: true,
      theme: "dark", // can be light or dark
      style: {
        fontSize: "14px",
      },
      fillSeriesColor: false, // Determines if tooltip background color should match the series color
      marker: {
        show: false, // Hides the marker next to the tooltip text
      },
      x: {
        show: false, // Hides the x-value in the tooltip
      },
    },
  };

  console.log("data", data);
  console.log("options", options);

  return (
    <>
      {allZeros ? (
        <Flex justify="center" align="center" direction="column" p="3rem 0">
          <Image h="4rem" w="auto" m="0 auto" src={noServices} />
          <Text fontSize="sm" color={textSecondary} m="1rem 0 0">
            {t("common.noData")}
          </Text>
        </Flex>
      ) : (
        <Chart
          className="custom-chart"
          type="donut"
          height={180}
          series={data}
          options={chartOptions}
        />
      )}
    </>
  );
};

export default PieChart;
