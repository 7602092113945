import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../../../../actions/userActions";
import { backgroundLight } from "../../../../utils/colors";
import {
  Button,
  Flex,
  Text,
  Box,
  Heading,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  white,
  newBorder,
  textSecondary,
  textPrimary,
  tooltipPrimary,
  buttonRed,
  buttonRedText,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlDatePicker from "../../../../components/input/FormControlDatePicker";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlAddress from "../../../../components/input/FormControlAddress";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import { calculateAge } from "../../../../utils/calculations";
import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";
import useIsMobile from "../../../../hooks/useIsMobile";

const CreatePatient = ({ error, defaultRole, close }) => {
  //GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast();

  // CONSTANTS
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(defaultRole || "patient");
  const [birthDate, setBirthDate] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneAlt, setPhoneAlt] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [gender, setGender] = useState("other");
  const [personalCode, setPersonalCode] = useState("");
  const [status, setStatus] = useState("new");
  const [child, setChild] = useState(false);
  const [iban, setIban] = useState("");
  const [bankName, setBankName] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");

  // AGE
  const [ageType, setAgeType] = useState("adult");

  // REDUX
  const userInfo = useSelector((state) => state.authLogin.userInfo); // Assuming your state structure
  const tenantID = userInfo?.tenantID; // Get tenantID from userInfo

  // TOUCHED
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [birthDateTouched, setBirthDateTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [streetTouched, setStreetTouched] = useState(false);
  const [zipTouched, setZipTouched] = useState(false);
  const [personalCodeTouched, setPersonalCodeTouched] = useState(false);
  const [cityTouched, setCityTouched] = useState(false);
  const [countryTouched, setCountryTouched] = useState(false);

  // ERRORS
  const isFirstNameError = firstName === "";
  const isLastNameError = lastName === "";
  const isBirthDateError = birthDate === "";
  const isPersonalCodeError = personalCode === "";
  const isEmailError = email === "";
  const isPhoneError = phone.length < 4;
  const isPhoneShortError = phone.length < 6;
  const isStreetError = street === "";
  const isZipError = zip === "";
  const isCityError = city === "";
  const isCountryError = country === "";

  // FUNCTIONS

  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  // HANDLERS

  const closeHandler = () => {
    close();
    dispatch({ type: "USER_CREATE_RESET" });
  };

  // SUBMIT

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      createUser(
        tenantID,
        firstName,
        lastName,
        email,
        birthDate,
        phone,
        phoneAlt,
        gender,
        child,
        personalCode,
        role,
        street,
        zip,
        city,
        country,
        state,
        iban,
        bankName,
        licenseNumber,
        status
      )
    );
  };

  // USE EFFECT

  useEffect(() => {
    if (birthDate) {
      const age = calculateAge(birthDate);
      setAgeType(age >= 18 ? "adult" : "kid");
    }

    if (ageType === "kid") {
      setChild(true);
    }
  }, [birthDate, ageType]);

  // UseEffect to update gender based on personalCode
  useEffect(() => {
    const firstChar = personalCode.charAt(0);
    if (firstChar === "3" || firstChar === "5") {
      setGender("male");
    } else if (firstChar === "4" || firstChar === "6") {
      setGender("female");
    }
  }, [personalCode]); // Dependency array includes personalCode

  // LOGS

  // console.log("personalCode:", personalCode);
  // console.log("street:", street);
  console.log("birthDate:", birthDate);

  const isMobile = useIsMobile();

  return (
    <>
      <Box position="relative">
        <form
          onSubmit={submitHandler}
          style={{
            height: "100%",
          }}
        >
          <SidePanelHeader
            close={closeHandler}
            title={t("patient.createPatient")}
            ageType={ageType}
            status={status}
          />

          <Box
            w="100%"
            h="calc(100vh - 60px)"
            overflow="scroll"
            p={["0 1.5rem", "0 2rem", "0 2rem"]}
          >
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="7rem 0 0"
            >
              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("patient.details")}
              </Heading>

              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1.5rem"
              >
                <Flex
                  gap={["0rem", "2rem", "2rem"]}
                  w="100%"
                  direction={["column", "row", "row"]}
                >
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.firstName")}
                    value={firstName}
                    placeholder={t("placeholder.firstName")}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setFirstNameTouched(true);
                    }}
                    isTouched={firstNameTouched}
                    isError={isFirstNameError}
                    w={["100%", "50%", "50%"]}
                    errorMessage={t("formError.firstNameRequired")}
                  />

                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.lastName")}
                    value={lastName}
                    placeholder={t("placeholder.lastName")}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setLastNameTouched(true);
                    }}
                    isTouched={lastNameTouched}
                    isError={isLastNameError}
                    w={["100%", "50%", "50%"]}
                    errorMessage={t("formError.lastNameRequired")}
                  />
                </Flex>

                <Flex
                  gap={["0rem", "2rem", "2rem"]}
                  w="100%"
                  direction={["column", "row", "row"]}
                >
                  <FormControlDatePicker
                    type="date"
                    isRequired
                    label={t("form.birthDate")}
                    // value={birthDate}
                    // value="1970-01-01T00:00:34.357Z"
                    placeholder={t("placeholder.birthDate")}
                    onChange={(newValue) => {
                      setBirthDate(newValue);
                    }}
                    onOpen={() => {}}
                    onClose={() => setBirthDateTouched(true)}
                    isTouched={birthDateTouched}
                    isError={isBirthDateError}
                    w={["100%", "50%", "50%"]}
                    errorMessage={t("formError.birthDateRequired")}
                  />

                  <FormControlDefault
                    type="number"
                    // isRequired
                    label={t("form.personalCode")}
                    value={personalCode}
                    placeholder={t("placeholder.personalCode")}
                    onChange={(e) => {
                      setPersonalCode(e.target.value);
                      // setPersonalCodeTouched(true);
                    }}
                    isTouched={personalCodeTouched}
                    // isError={isPersonalCodeError}
                    w={["100%", "50%", "50%"]}
                    errorMessage={t("formError.personalCodeRequired")}
                  />
                </Flex>
              </Flex>
            </Flex>

            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="0 0 4rem"
            >
              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {isMobile
                  ? t("patient.addressContactDetailsMobile")
                  : t("patient.addressContactDetails")}
              </Heading>
              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1.5rem"
              >
                <Flex
                  gap={["0rem", "2rem", "2rem"]}
                  w="100%"
                  direction={["column", "row", "row"]}
                >
                  <FormControlPhone
                    label={t("form.phone")}
                    value={phone}
                    onChange={(newPhone) => {
                      setPhone(newPhone);
                      setPhoneTouched(true);
                    }}
                    isRequired={true}
                    isTouched={phoneTouched}
                    isError={isPhoneError}
                    errorMessage={t("formError.phoneRequired")}
                    w={["100%", "50%", "50%"]}
                    defaultCountry="lt"
                  />

                  <FormControlPhone
                    label={t("form.phoneAlt")}
                    value={phoneAlt}
                    onChange={(newPhone) => {
                      setPhoneAlt(newPhone);
                    }}
                    isRequired={false}
                    errorMessage={t("formError.phoneRequired")}
                    w={["100%", "50%", "50%"]}
                    defaultCountry="lt"
                  />
                </Flex>

                <FormControlDefault
                  type="email"
                  // isRequired
                  label={t("form.email")}
                  value={email}
                  placeholder={t("placeholder.email")}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    // setEmailTouched(true);
                  }}
                  isTouched={emailTouched}
                  // isError={isEmailError}
                  w="100%"
                  errorMessage={t("formError.emailRequired")}
                />

                <Flex
                  gap={["0rem", "2rem", "2rem"]}
                  w="100%"
                  direction={["column", "row", "row"]}
                >
                  <FormControlAddress
                    label={t("form.address")}
                    onAddressSelect={handleAddressSelect}
                    isRequired={true}
                    isTouched={streetTouched} // Assuming you have similar touch state for street
                    isError={isStreetError} // Assuming you have similar error state for street
                    setIsTouched={setStreetTouched}
                    errorMessage={t("formError.addressRequired")}
                    w="100%"
                    googleApiKey="AIzaSyCNQfHnntkLgj5Es5V8V0H2BfIetOOZ9yw"
                  />

                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.zip")}
                    value={zip}
                    placeholder={t("placeholder.zip")}
                    onChange={(e) => {
                      setZip(e.target.value);
                      setZipTouched(true);
                    }}
                    isTouched={zipTouched}
                    isError={isZipError}
                    w={["100%", "40%", "40%"]}
                    errorMessage={t("formError.zipRequired")}
                  />
                </Flex>

                <Flex
                  gap={["0rem", "2rem", "2rem"]}
                  w="100%"
                  direction={["column", "row", "row"]}
                >
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.city")}
                    value={city}
                    placeholder={t("placeholder.city")}
                    onChange={(e) => {
                      setCity(e.target.value);
                      setCityTouched(true);
                    }}
                    isTouched={cityTouched}
                    isError={isCityError}
                    w={["100%", "50%", "50%"]}
                    errorMessage={t("formError.cityRequired")}
                  />

                  <FormControlCountry
                    label={t("form.country")}
                    value={country}
                    isTouched={countryTouched}
                    isError={isCountryError}
                    onChange={(selectedOption) => {
                      setCountry(selectedOption ? selectedOption.value : "");
                      setCountryTouched(true);
                    }}
                    isRequired={true}
                    w={["100%", "50%", "50%"]}
                    errorMessage={t("formError.countryRequired")}
                  />
                </Flex>
              </Flex>
            </Flex>

            <Flex
              mt={["2rem 0 0", "2rem 0 0", "0rem 0 0"]}
              justify="center"
              direction="column"
              borderTop={`1px solid ${newBorder}`}
              p="1.5rem 0"
              position="relative"
              zIndex={0}
              bg={white}
              bottom="0"
              w="100%"
            >
              <Flex justify="flex-end">
                <Button
                  minWidth="10rem"
                  mr={4}
                  bg={backgroundLight}
                  size="lg"
                  color={textSecondary}
                  fontSize="md"
                  borderRadius="10rem"
                  onClick={closeHandler}
                >
                  {t("common.cancel")}
                </Button>

                <Tooltip
                  p="0.5rem"
                  borderRadius="1rem"
                  bg={buttonRed}
                  label={
                    <Flex direction="column" justify="center" align="center">
                      {isFirstNameError && (
                        <Text
                          color={buttonRedText}
                          fontSize="xs"
                          fontWeight="500"
                        >
                          {t("formError.firstNameRequired")}
                        </Text>
                      )}
                      {isLastNameError && (
                        <Text
                          color={buttonRedText}
                          fontSize="xs"
                          fontWeight="500"
                        >
                          {t("formError.lastNameRequired")}
                        </Text>
                      )}
                      {isBirthDateError && (
                        <Text
                          color={buttonRedText}
                          fontSize="xs"
                          fontWeight="500"
                        >
                          {t("formError.birthDateRequired")}
                        </Text>
                      )}
                      {isPhoneShortError && (
                        <Text
                          color={buttonRedText}
                          fontSize="xs"
                          fontWeight="500"
                        >
                          {t("formError.phoneRequired")}
                        </Text>
                      )}
                      {isStreetError &&
                        isZipError &&
                        isCityError &&
                        isCountryError && (
                          <Text
                            color={buttonRedText}
                            fontSize="xs"
                            fontWeight="500"
                          >
                            {t("formError.addressRequired")}
                          </Text>
                        )}
                    </Flex>
                  }
                  isDisabled={
                    !isFirstNameError &&
                    !isLastNameError &&
                    !isBirthDateError &&
                    !isPhoneShortError &&
                    !isStreetError &&
                    !isZipError &&
                    !isCityError &&
                    !isCountryError
                  }
                >
                  <Button
                    minWidth="10rem"
                    type="submit"
                    size="lg"
                    bg={primary}
                    color={white}
                    fontSize="md"
                    borderRadius="10rem"
                    isDisabled={
                      !firstName.trim() ||
                      !lastName.trim() ||
                      !birthDate.trim() ||
                      isPhoneShortError ||
                      !street.trim() ||
                      !zip.trim() ||
                      !city.trim() ||
                      !country.trim()
                    }
                    _hover={{
                      bg: secondary,
                    }}
                  >
                    {t("patient.createPatient")}
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default CreatePatient;
