import React, { useEffect, useState } from "react";
import {
  Flex,
  Button,
  Input,
  Icon,
  InputGroup,
  InputLeftElement,
  Heading,
} from "@chakra-ui/react";
import { columns } from "../../columns/EmployeeTreatmentPlanColumns";
import {
  newBorder,
  backgroundLight,
  textSecondary,
  textPrimary,
  white,
  primary,
} from "../../../../../utils/colors";
import dayjs from "dayjs";
import TableContainerAlt from "../../../../../components/table/TableContainerAlt";
import { FaSistrix } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import SidePanelTop from "../../../../../components/sidepanel/SidePanelTop";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
// import EditPatientTreatmentPlan from "../../sidepanels/EditPatientTreatmentPlan";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTreatmentPlanStatus,
  approveTreatmentPlan,
  cancelTreatmentPlan,
} from "../../../../../actions/treatmentPlanActions";
import { TREATMENTPLAN_DETAILS_RESET } from "../../../../../constants/treatmentPlanConstants";
import { doctorColumns } from "../../columns/EmployeeDoctorTreatmentPlansColumns";
import { coordinatorColumns } from "../../columns/EmployeeCoordinatorTreatmentPlansColumns";
import { serviceColumns } from "../../columns/EmployeeServiceColumns";
import DropdownButton from "../../../../../components/buttons/DropdownButton";
import EditPatientTreatmentPlan from "../../../patients/sidepanels/EditPatientTreatmentPlan";

const EmployeePlans = ({
  loading,
  loadingDoctorServices,
  error,
  plans,
  services,
  buttonHandler,
  userInfo,
  patientId,
  dispatch,
  profession,
  showDoctorPlans,
  setShowDoctorPlans,
}) => {
  //TRANSLATION
  const { t } = useTranslation();
  const translate = (key) => t(key);

  const userId = userInfo._id;

  const [searchTerm, setSearchTerm] = useState("");
  const [sidePanelModalOpen, setSidePanelModalOpen] = useState(false);
  const [planId, setPlanId] = useState("");

  const [dropdownLabel, setDropdownLabel] = useState("");

  // Determine which column configuration to use
  const columnsToUse = showDoctorPlans ? serviceColumns : coordinatorColumns;

  // REDUX
  const treatmentPlanUpdateStatus = useSelector(
    (state) => state.treatmentPlanUpdateStatus
  );
  const { success: successUpdate } = treatmentPlanUpdateStatus;

  const treatmentPlanApprove = useSelector(
    (state) => state.treatmentPlanApprove
  );
  const { success: successApprove } = treatmentPlanApprove;

  // HANDLERS

  const openSidePanelModalHandler = () => {
    setSidePanelModalOpen(true);
  };

  const closeSidePanelModal = () => {
    setSidePanelModalOpen(false);
    setPlanId(null);
    dispatch({ type: TREATMENTPLAN_DETAILS_RESET });
  };

  // DROPDOWN BUTTON
  const initialDropdownLabel = t("employee.doctorTreatmentPlansButton");

  const dropdownOptions = [
    t("employee.doctorTreatmentPlansButton"),
    t("employee.coordinatorTreatmentPlansButton"),
  ];

  const handleDropdownSelect = (option) => {
    // Check if the option is equal to the doctor treatment plans label
    if (option === t("employee.doctorTreatmentPlansButton")) {
      setShowDoctorPlans(true);
    } else if (option === t("employee.coordinatorTreatmentPlansButton")) {
      setShowDoctorPlans(false);
    }
  };

  // USE EFFECT
  useEffect(() => {
    if (showDoctorPlans) {
      setDropdownLabel(t("employee.doctorTreatmentPlansButton"));
    } else {
      setDropdownLabel(t("employee.coordinatorTreatmentPlansButton"));
    }
  }, [showDoctorPlans, t]); // Add t to the dependency array if using i18n

  // LOGS
  console.log("planId", planId);
  console.log("plans", plans);
  // console.log("planId", planId);
  console.log("dropdownLabel", dropdownLabel);

  return (
    <>
      {/* SIDEPANELS  */}
      <SidePanel
        isTop
        isLong
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("patient.createTreatmentPlan")}
        noBorder={true}
      >
        <EditPatientTreatmentPlan
          planId={planId}
          close={closeSidePanelModal}
          currentUser={userInfo}
        />
      </SidePanel>

      <Flex w="100%" direction="column" m="2.5rem 0 0">
        <Heading
          fontSize="1.125rem"
          color={textPrimary}
          fontWeight="600"
          p="0 0 0.75rem"
        >
          {showDoctorPlans
            ? t("employee.doctorTreatmentPlans")
            : t("employee.coordinatorTreatmentPlans")}
        </Heading>
        <Flex
          w="100%"
          direction="column"
          p="0 0rem 0 0"
          bg={white}
          borderRadius="0rem"
        >
          <Flex
            w="100%"
            align="center"
            justify="space-between"
            p="0rem 0rem"
            bg={white}
            borderTopLeftRadius="1rem"
            mb="1rem"
            borderTopRightRadius="1rem"
          >
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={FaSistrix} color="gray.600" />}
              />
              <Input
                bg={white}
                borderRadius="10.75rem"
                h="2.5rem"
                placeholder={t("treatmentPlan.search")}
                value={searchTerm}
                border={`1px solid ${newBorder}`}
                maxWidth="460px"
                fontSize="sm"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
            <Button
              fontSize="sm"
              borderRadius="10rem"
              bg={white}
              border={`1px solid ${newBorder}`}
              color={textSecondary}
              p="1rem 1.5rem"
              ml={4}
            >
              {t("common.filter")}
            </Button>

            <Flex justify="center" pl={2}>
              <DropdownButton
                initialLabel={dropdownLabel}
                items={dropdownOptions}
                onSelectItem={handleDropdownSelect}
              />
            </Flex>
          </Flex>
          <Flex
            bg={white}
            w="100%"
            p="0 0rem 0rem"
            border={`1px solid ${newBorder}`}
            borderRadius="1rem"
            overflow="hidden"
          >
            {!showDoctorPlans ? (
              <TableContainerAlt
                columns={columnsToUse(
                  dispatch,
                  translate,
                  updateTreatmentPlanStatus,
                  approveTreatmentPlan,
                  plans,
                  userId,
                  cancelTreatmentPlan
                )}
                data={plans}
                loading={loading}
                error={error}
                isSinglePage={true}
                onRowClick={(row) => {
                  openSidePanelModalHandler();
                  setPlanId(row._id);
                }}
                noItemsTitle={t("noItems.noEmployeeTreatmentPlans")}
                noItemsDescription={t("noItems.noEmployeeTreatmentPlansText")}
                // noItemsButtonLabel={t("noItems.noEmployeeTreatmentPlansButton")}
                // noItemsButtonHandler={buttonHandler}
              />
            ) : (
              <TableContainerAlt
                columns={columnsToUse(
                  dispatch,
                  translate,
                  updateTreatmentPlanStatus,
                  approveTreatmentPlan,
                  services,
                  userId,
                  cancelTreatmentPlan
                )}
                data={services}
                loading={loadingDoctorServices}
                error={error}
                isSinglePage={true}
                onRowClick={(row) => {
                  openSidePanelModalHandler();
                  setPlanId(row.treatmentPlanId);
                }}
                noItemsTitle={t("noItems.noEmployeeServices")}
                noItemsDescription={t("noItems.noEmployeeServicesText")}
                // noItemsButtonLabel={t("noItems.noEmployeeServicesButton")}
                // noItemsButtonHandler={buttonHandler}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default EmployeePlans;
