import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  Text,
  Icon,
} from "@chakra-ui/react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  newBorder,
  white,
  primary,
  buttonAltBlue,
  buttonBlueDark,
  buttonBlueText,
  buttonAltGrey,
  textSecondary,
  buttonAltGreen,
  buttonGreenDark,
  buttonGreenText,
  buttonAltPrimary,
  buttonAltYellow,
  buttonYellowDark,
  buttonYellow,
  buttonAltRed,
  buttonRedDark,
  buttonRedText,
  buttonAltSuccess,
  buttonGreenVividDark,
  textPrimary,
  buttonBlue,
  buttonRed,
  buttonOrange,
  buttonOrangeDark,
  buttonOrangeText,
  buttonPurple,
  buttonPurpleDark,
  buttonPurpleText,
  buttonCyan,
  buttonCyanDark,
  buttonCyanText,
  buttonGreenVivid,
  buttonGreenVividText,
  buttonGreen,
  buttonYellowText,
  backgroundLight,
  text,
} from "../../utils/colors";
import {
  FaRegCircleDot,
  FaRegCircle,
  FaCircleCheck,
  FaClock,
  FaCircleHalfStroke,
  FaCircleXmark,
} from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const FormControlStatus = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
  options,
  isMulti,
}) => {
  const { t } = useTranslation();
  const translate = (key) => t(key);

  const animatedComponents = makeAnimated();

  if (options === "user") {
    options = [
      {
        label: t("status.userGroupGeneral"),
        options: [
          {
            value: "new",
            label: translate("status.patientNew"),
            icon: FaRegCircleDot,
            bg: buttonAltBlue,
            bgDark: buttonBlueDark,
            color: buttonBlueText,
            borderColor: buttonBlue,
            bgHover: "#b6cce1",
          },
          {
            value: "inactive",
            label: translate("status.patientInactive"),
            icon: FaRegCircle,
            bg: buttonAltGrey,
            bgDark: textSecondary,
            color: text,
            borderColor: buttonAltGrey,
            bgHover: "#ddd",
          },

          {
            value: "active",
            label: translate("status.patientActive"),
            icon: FaCircleCheck,
            bg: buttonAltGreen,
            bgDark: buttonGreenDark,
            color: buttonGreenText,
            borderColor: buttonAltGreen,
            bgHover: "#9ebfc2",
          },
        ],
      },
      {
        label: t("status.userGroupTreatment"),
        options: [
          {
            value: "consulting",
            label: translate("status.patientConsulting"),
            icon: FaClock,
            bg: buttonAltPrimary,
            bgDark: buttonBlueDark,
            color: textPrimary,
            borderColor: newBorder,
            bgHover: "#ddd",
          },
          {
            value: "planningTreatment",
            label: translate("status.patientPlanningTreatment"),
            icon: FaClock,
            bg: buttonAltPrimary,
            bgDark: buttonYellowDark,
            color: textPrimary,
            borderColor: buttonYellow,
            bgHover: "#9ebfc2",
          },
          {
            value: "approvedTreatment",
            label: translate("status.patientApprovedTreatment"),
            icon: FaCircleCheck,
            bg: buttonAltGreen,
            bgDark: buttonGreenDark,
            color: buttonGreenText,
            borderColor: buttonAltGreen,
            bgHover: "#9ebfc2",
          },
          {
            value: "ongoingTreatment",
            label: translate("status.patientOngoingTreatment"),
            icon: FaCircleHalfStroke,
            bg: buttonAltYellow,
            bgDark: buttonYellowDark,
            color: textPrimary,
            borderColor: buttonAltYellow,
            bgHover: "#9ebfc2",
          },

          {
            value: "completedTreatment",
            label: translate("status.patientCompletedTreatment"),
            icon: FaCircleCheck,
            bg: buttonAltSuccess,
            bgDark: buttonGreenVividDark,
            color: textPrimary,
            borderColor: buttonAltSuccess,
            bgHover: "#dda3a3",
          },
          {
            value: "cancelledTreatment",
            label: translate("status.patientCancelledTreatment"),
            icon: FaCircleXmark,
            bg: buttonAltRed,
            bgDark: buttonRedDark,
            color: buttonRedText,
            borderColor: buttonRed,
            bgHover: "#dda3a3",
          },
        ],
      },
    ];
  } else if (options === "treatment") {
    options = [
      {
        value: "consulting",
        label: translate("status.treatmentPlanConsulting"),
        icon: FaClock,
        bg: buttonAltPrimary,
        bgDark: buttonBlueDark,
        color: textPrimary,
        borderColor: newBorder,
        bgHover: "#ddd",
      },
      {
        value: "planning",
        label: translate("status.treatmentPlanPlanning"),
        icon: FaClock,
        bg: buttonAltPrimary,
        bgDark: buttonYellowDark,
        color: textPrimary,
        borderColor: buttonYellow,
        bgHover: "#9ebfc2",
      },
      {
        value: "approved",
        label: translate("status.treatmentPlanApproved"),
        icon: FaCircleCheck,
        bg: buttonAltGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },
      {
        value: "ongoing",
        label: translate("status.ongoing"),
        icon: FaCircleHalfStroke,
        bg: buttonAltYellow,
        bgDark: buttonYellowDark,
        color: textPrimary,
        borderColor: buttonAltYellow,
        bgHover: "#9ebfc2",
      },
      {
        value: "completed",
        label: translate("status.treatmentPlanCompleted"),
        icon: FaCircleCheck,
        bg: buttonAltSuccess,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },
      {
        value: "cancelled",
        label: translate("status.treatmentPlanCancelled"),
        icon: FaCircleXmark,
        bg: buttonAltRed,
        bgDark: buttonRedDark,
        color: buttonRedText,
        borderColor: buttonRed,
        bgHover: "#dda3a3",
      },

      {
        value: "draft",
        label: translate("status.draft"),
        icon: FaRegCircle,
        bg: buttonAltGrey,
        bgDark: textSecondary,
        color: textSecondary,
        borderColor: newBorder,
        bgHover: "#ddd",
      },
    ];
  } else {
    options = [
      {
        value: "inactive",
        label: translate("status.inactive"),
        icon: FaRegCircle,
        bg: buttonAltGrey,
        bgDark: textSecondary,
        color: text,
        borderColor: buttonAltGrey,
        bgHover: "#ddd",
      },

      {
        value: "active",
        label: translate("status.active"),
        icon: FaCircleCheck,
        bg: buttonAltGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonAltGreen,
        bgHover: "#9ebfc2",
      },
    ];
  }

  // Custom rendering for options and selected values
  const formatOptionLabel = (option, { context }) => (
    <Flex
      align="center"
      w="initial"
      // bg={context === "menu" ? option.bg : "inherit"}
    >
      {option.icon && (
        <Flex
          align="center"
          justify="center"
          mr={context === "menu" ? 2 : 1}
          bg={context === "menu" ? option.bg : "inherit"}
          w="20px"
          h="20px"
          borderRadius="2rem"
          flexShrink="0"
        >
          <Icon
            h="0.6rem"
            w="0.6rem"
            as={option.icon}
            color={context === "menu" ? option.color : "inherit"}
            flexShrink="0"
          />
        </Flex>
      )}

      <Text
        fontSize={context === "menu" ? "13px" : "xs"}
        color={context === "menu" ? textPrimary : option.color}
      >
        {option.label}
      </Text>
    </Flex>
  );

  // console.log("value", value);

  // Custom styles for react-select
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: isError ? "red" : state.isFocused ? newBorder : newBorder,
      borderRadius: "1rem",
      backgroundColor: white,
      border: `1px solid ${newBorder}`,
      fontSize: isSmall ? "0.875rem" : "0.938rem",
      fontWeight: "500",
      minHeight: "2.5rem",
      height: isMulti ? "initial" : isSmall ? "2.5rem" : "3rem",
      padding: "0 0rem",
      ":focus-visible": {
        borderColor: primary,
        boxShadow: `0 0 0 1px ${newBorder}`,
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      color: textPrimary,
      ":hover": {
        bg: "red",
      },
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? primary : null,
      color: state.isFocused ? white : state.data.color,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.bg,
      borderRadius: "0.75rem",
      fontSize: "0.938rem",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: state.data.color,
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      ":hover": {
        backgroundColor: state.data.bgDark,
        color: state.data.color,
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontSize: "11px",
      color: textSecondary,
      fontWeight: "500",
    }),
  };

  // Function to check if the options are grouped
  const areOptionsGrouped = (options) =>
    options.some((option) => option.options);

  // Function to find the option object for a given value
  const findOption = (valueToFind, options, isGrouped) => {
    if (isGrouped) {
      for (const group of options) {
        const foundOption = group.options.find(
          (opt) => opt.value === valueToFind
        );
        if (foundOption) return foundOption;
      }
    } else {
      return options.find((opt) => opt.value === valueToFind);
    }
    return null;
  };

  // Determine if options are grouped
  const isGrouped = areOptionsGrouped(options);

  // Map each value to its corresponding option object
  const selectedOption = Array.isArray(value)
    ? value
        .map((val) => findOption(val, options, isGrouped))
        .filter((opt) => opt !== null)
    : findOption(value, options, isGrouped);

  // console.log("options", options);
  // console.log("value", value);

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize={isSmall ? "xs" : "sm"} position="relative">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>

      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        formatOptionLabel={formatOptionLabel}
        isSearchable
        value={selectedOption}
        isMulti={isMulti}
        onChange={onChange}
        options={options}
        placeholder={t("placeholder.selectStatus")}
        styles={customStyles}
        noOptionsMessage={() => t("placeholder.noOptions")}
      />
    </FormControl>
  );
};

export default FormControlStatus;
