import Wrapper from './SidebarWrapper';
import Container from './SidebarContainer';
import Header from './SidebarHeader';
import Routing from './SidebarRouting';

const Sidebar = {
  Wrapper,
  Container,
  Header,
  Routing,
};

export default Sidebar;
