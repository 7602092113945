import React, { useState, useEffect } from "react";
import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  HStack,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { TbPlus, TbMinus } from "react-icons/tb";
import {
  white,
  buttonRed,
  buttonRedText,
  buttonGreen,
  buttonGreenText,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { shade } from "polished";

const FormControlOperatingRooms = ({
  label,
  initialValue,
  onSave,
  isRequired,
  isError,
  errorMessage,
  isTouched,
  isSmall,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);
  const [isEdited, setIsEdited] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    const intValue = parseInt(newValue, 10);
    if (!isNaN(intValue) && intValue >= 1) {
      setValue(newValue);
    } else if (newValue === "") {
      setValue(newValue); // Allow the field to be empty temporarily
    }
    setIsEdited(true);
  };

  const handleBlur = () => {
    if (value === "") {
      setValue("1"); // Reset to 1 if the field is left empty
    }
  };

  const increment = () => {
    setValue((prevValue) => (parseInt(prevValue, 10) || 0) + 1);
    setIsEdited(true);
  };

  const decrement = () => {
    setValue((prevValue) => Math.max(1, (parseInt(prevValue, 10) || 2) - 1));
    setIsEdited(true);
  };

  const handleSave = () => {
    onSave(parseInt(value, 10) || 1);
    setIsEdited(false);
  };

  const handleCancel = () => {
    setValue(initialValue); // Reset to the last saved initialValue
    setIsEdited(false); // Hide save and cancel buttons
  };

  return (
    <FormControl isRequired={isRequired} isInvalid={isError}>
      <FormLabel fontSize={isSmall ? "xs" : "sm"} position="relative">
        {label}
        {isError && isTouched && (
          <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
        )}
      </FormLabel>
      <HStack>
        <IconButton
          icon={<TbMinus />}
          aria-label="Decrement"
          onClick={decrement}
          borderRadius="0.875rem"
          fontSize="sm"
        />
        <Input
          bg={white}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          width="100px"
          textAlign="center"
          fontSize="0.938rem"
          borderRadius="0.875rem"
        />
        <IconButton
          icon={<TbPlus />}
          aria-label="Increment"
          onClick={increment}
          borderRadius="0.875rem"
          fontSize="sm"
        />
        {isEdited && (
          <Flex ml={6} gap={2}>
            <Button
              onClick={handleSave}
              borderRadius="0.875rem"
              bg={buttonGreen}
              color={buttonGreenText}
              fontSize="sm"
              _hover={{
                bg: shade(0.1, buttonGreen),
              }}
            >
              {t("button.save")}
            </Button>
            <Button
              colorScheme="red"
              onClick={handleCancel}
              borderRadius="0.875rem"
              bg={buttonRed}
              color={buttonRedText}
              fontSize="sm"
              _hover={{
                bg: shade(0.1, buttonRed),
              }}
            >
              {t("button.cancel")}
            </Button>
          </Flex>
        )}
      </HStack>
      {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormControlOperatingRooms;
