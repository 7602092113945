import React from "react";
import {
  Text,
  Stack,
  HStack,
  Box,
  Flex,
  Switch,
  Button,
} from "@chakra-ui/react";
import { textSecondary, textPrimary } from "../../../../utils/colors";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";

export const columns = (
  dispatch,
  translate,
  deleteServiceHandler,
  openEditSidePanelModal,
  updateServiceGroupStatus,
  handleSortChange,
  updateServiceGroupIsConsulting,
  isAdmin
) => {
  return [
    // {
    //   Header: "#",
    //   id: "index",
    //   accessor: (_row, i) => {
    //     // Adjust the index based on the page and pageSize
    //     const adjustedIndex = (page - 1) * pageSize + i + 1;
    //     return (
    //       <Text
    //         overflow="hidden"
    //         whiteSpace="nowrap"
    //         fontSize="sm"
    //         color={textSecondary}
    //         fontWeight="500"
    //       >
    //         {adjustedIndex}
    //       </Text>
    //     );
    //   },
    // },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("name")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("serviceGroup.columnName")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "name",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { name } = row.original;

        return (
          <HStack gap={2}>
            <Stack gap={0} width="350px">
              <Text
                fontSize="md"
                color={textPrimary}
                fontWeight="600"
                isTruncated // This will add ellipsis
                noOfLines={1} // This will limit the text to two lines
                whiteSpace="nowrap"
                overflow="hidden"
                w="100%"
                maxWidth="100%"
              >
                {name}
              </Text>
            </Stack>
          </HStack>
        );
      },
    },
    // {
    //   Header: translate("serviceGroup.columnCustomId"),
    //   accessor: (row) => {
    //     const { customId } = row;
    //     return (
    //       <Text
    //         overflow="hidden"
    //         whiteSpace="nowrap"
    //         fontSize="sm"
    //         color={textSecondary}
    //       >
    //         {customId}
    //       </Text>
    //     );
    //   },
    // },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("services")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("serviceGroup.columnServiceCount")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "services",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { services } = row.original;

        if (!services) {
          return (
            <Text
              maxWidth="40px"
              overflow="hidden"
              whiteSpace="nowrap"
              fontSize="sm"
              color={textSecondary}
              fontWeight="500"
            >
              0
            </Text>
          );
        }

        return (
          <Text
            maxWidth="40px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="sm"
            color={textSecondary}
            fontWeight="500"
          >
            {services.length}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("consulting")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("serviceGroup.columnConsulting")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "consulting",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { _id, isConsulting } = row.original;

        const handleIsConsultingChange = (e) => {
          // Pass the correct service group ID and new consulting status
          dispatch(updateServiceGroupIsConsulting(_id, e.target.checked));
        };

        const handleCellClick = (e) => {
          e.stopPropagation(); // Prevent row click
        };

        return (
          <Flex align="center" onClick={handleCellClick}>
            <Switch
              size="sm"
              isChecked={isConsulting}
              onChange={!isAdmin ? null : handleIsConsultingChange}
              isDisabled={!isAdmin}
            />
          </Flex>
        );
      },
    },
    // {
    //   Header: translate("serviceGroup.columnTreatmentStep"),
    //   accessor: (row) => {
    //     const { treatmentStep } = row;

    //     const name = treatmentStep ? treatmentStep.name : "";

    //     return (
    //       <Text fontSize="sm" color={textSecondary} fontWeight="500">
    //         {name}
    //       </Text>
    //     );
    //   },
    // },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("patient.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { status, _id, name, services } = row.original;

        // console.log("row", row.original);

        const hasServices = services && services.length > 0;

        const handleUpdateStatus = (newStatus) => {
          dispatch(updateServiceGroupStatus(_id, newStatus));
        };

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
              options="active"
              isUnclickable={!isAdmin}
            />
            <MoreButton
              id={_id}
              deleteHandler={deleteServiceHandler}
              deleteName={name}
              warningLabel={
                hasServices && translate("warning.serviceGroupHasServices")
              }
              editHandler={(e) => {
                e.stopPropagation();
                openEditSidePanelModal(_id);
              }}
              deleteButtonDisabled={!isAdmin}
              editButtonDisabled={!isAdmin}
            />
          </HStack>
        );
      },
    },
  ];
};
