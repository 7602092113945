import React from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  newBorder,
  white,
  primary,
  buttonAltBlue,
  buttonBlueDark,
  buttonBlueText,
  buttonAltGrey,
  textSecondary,
  buttonAltGreen,
  buttonGreenDark,
  buttonGreenText,
  buttonAltPrimary,
  buttonAltYellow,
  buttonYellowDark,
  buttonYellow,
  buttonAltRed,
  buttonRedDark,
  buttonRedText,
  buttonAltSuccess,
  buttonGreenVividDark,
  textPrimary,
  buttonBlue,
  buttonRed,
  text,
} from "../../utils/colors";
import {
  FaRegCircleDot,
  FaRegCircle,
  FaCircleCheck,
  FaClock,
  FaCircleHalfStroke,
  FaCircleXmark,
} from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const FormControlStatusPatient = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
}) => {
  const { t } = useTranslation();
  const translate = (key) => t(key);
  const animatedComponents = makeAnimated();

  const options = [
    {
      value: "new",
      label: translate("status.patientNew"),
      icon: FaRegCircleDot,
      bg: buttonAltBlue,
      bgDark: buttonBlueDark,
      color: buttonBlueText,
      borderColor: buttonBlue,
      bgHover: "#b6cce1",
    },
    {
      value: "consulting",
      label: translate("status.patientConsulting"),
      icon: FaClock,
      bg: buttonAltPrimary,
      bgDark: buttonBlueDark,
      color: textPrimary,
      borderColor: newBorder,
      bgHover: "#ddd",
    },
    {
      value: "planningTreatment",
      label: translate("status.patientPlanningTreatment"),
      icon: FaClock,
      bg: buttonAltPrimary,
      bgDark: buttonYellowDark,
      color: textPrimary,
      borderColor: buttonYellow,
      bgHover: "#9ebfc2",
    },
    {
      value: "approvedTreatment",
      label: translate("status.patientApprovedTreatment"),
      icon: FaCircleCheck,
      bg: buttonAltGreen,
      bgDark: buttonGreenDark,
      color: buttonGreenText,
      borderColor: buttonAltGreen,
      bgHover: "#9ebfc2",
    },
    {
      value: "ongoingTreatment",
      label: translate("status.patientOngoingTreatment"),
      icon: FaCircleHalfStroke,
      bg: buttonAltYellow,
      bgDark: buttonYellowDark,
      color: textPrimary,
      borderColor: buttonAltYellow,
      bgHover: "#9ebfc2",
    },

    {
      value: "completedTreatment",
      label: translate("status.patientCompletedTreatment"),
      icon: FaCircleCheck,
      bg: buttonAltSuccess,
      bgDark: buttonGreenVividDark,
      color: textPrimary,
      borderColor: buttonAltSuccess,
      bgHover: "#dda3a3",
    },
    {
      value: "cancelledTreatment",
      label: translate("status.patientCancelledTreatment"),
      icon: FaCircleXmark,
      bg: buttonAltRed,
      bgDark: buttonRedDark,
      color: buttonRedText,
      borderColor: buttonRed,
      bgHover: "#dda3a3",
    },
    {
      value: "active",
      label: translate("status.patientActive"),
      icon: FaCircleCheck,
      bg: buttonAltGreen,
      bgDark: buttonGreenDark,
      color: buttonGreenText,
      borderColor: buttonAltGreen,
      bgHover: "#9ebfc2",
    },
    {
      value: "inactive",
      label: translate("status.patientInactive"),
      icon: FaRegCircle,
      bg: buttonAltGrey,
      bgDark: textSecondary,
      color: text,
      borderColor: buttonAltGrey,
      bgHover: "#ddd",
    },
  ];

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize={isSmall ? "xs" : "lg"} position="relative">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>

      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        value={options.filter((option) => value.includes(option.value))}
        // defaultValue={options}
        placeholder={t("placeholder.selectStatus")}
        isMulti
        options={options}
        onChange={onChange}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            borderRadius: "1rem",
            backgroundColor: white,
            border: `1px solid ${newBorder}`,
            fontSize: isSmall ? "0.875rem" : "0.938rem",
            fontWeight: "500",
            height: isSmall ? "2.5rem" : "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition };
          },
        }}
      />
    </FormControl>
  );
};

export default FormControlStatusPatient;
