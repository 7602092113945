import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Flex,
  Box,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  white,
  primary,
  newBorder,
  textSecondary,
} from "../../../../../utils/colors";
import {
  listCourseDetails,
  updateCourseDescription,
} from "../../../../../actions/courseActions";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
import { Editor } from "@tinymce/tinymce-react";
import ToastComponent from "../../../../../components/partials/ToastComponent";
import { COURSE_DESCRIPTION_UPDATE_RESET } from "../../../../../constants/courseConstants";
import SidePanelHeader from "../../../../../components/sidepanel/SidePanelHeader";

const TabCourseDescription = ({ title }) => {
  const courseId = useParams().id;
  const toast = useToast();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [editDescriptionSidePanelOpen, setEditDescriptionSidePanelOpen] =
    useState(false);
  const [description, setDescription] = useState(""); // State variable to store the description

  const courseDetails = useSelector((state) => state.courseDetails);
  const { course } = courseDetails;

  const courseDescriptionUpdate = useSelector(
    (state) => state.courseDescriptionUpdate
  );
  const { success: successUpdateDescription } = courseDescriptionUpdate;

  const openEditDescriptionSidePanel = () => {
    setEditDescriptionSidePanelOpen(true);
  };

  const closeEditDescriptionSidePanel = () => {
    setEditDescriptionSidePanelOpen(false);
  };

  const updateDescriptionHandler = () => {
    dispatch(updateCourseDescription(courseId, description));
    closeEditDescriptionSidePanel();
  };

  useEffect(() => {
    if (!course || course._id !== courseId) {
      dispatch(listCourseDetails(courseId));
    } else if (successUpdateDescription) {
      toastComponent.showToast(
        t("toasts.courseDescriptionUpdated"),
        "success",
        3000
      );
      dispatch({ type: COURSE_DESCRIPTION_UPDATE_RESET });
      dispatch(listCourseDetails(courseId));
    } else {
      setDescription(course.description);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, course, courseId, successUpdateDescription]);

  return (
    <>
      <SidePanel
        open={editDescriptionSidePanelOpen}
        close={closeEditDescriptionSidePanel}
        title={t("course.editCourseDescription")}
      >
        <Box position="relative">
          <SidePanelHeader
            close={closeEditDescriptionSidePanel}
            title={t("course.editCourseDescription")}
          />

          <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="7rem 0 4rem"
            >
              <Flex
                direction="column"
                w="100%"
                bg={white}
                borderRadius="1rem"
                p="0rem 1.5rem"
              >
                <FormControl>
                  <FormLabel fontSize="sm">
                    {" "}
                    {t("course.courseDescription")}:
                  </FormLabel>
                  <Editor
                    apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                    value={description} // Use your state variable here
                    onEditorChange={(content) => setDescription(content)} // Update your state variable
                  />
                </FormControl>
              </Flex>
            </Flex>
            <Flex
              mt={4}
              justify="flex-end"
              borderTop={`1px solid ${newBorder}`}
              p="2rem 0"
            >
              <Button
                minWidth="10rem"
                type="submit"
                size="lg"
                bg={primary}
                color={white}
                fontSize="md"
                borderRadius="10rem"
                onClick={updateDescriptionHandler}
              >
                {t("button.update")}
              </Button>
            </Flex>
          </Box>
        </Box>
      </SidePanel>

      <Box p="0rem 0rem">
        <Flex align="center" justify="space-between" p="2rem 0">
          <Stack gap={1}>
            <Heading fontSize="xl" fontWeight="600">
              {t("course.courseDescription")}
            </Heading>
            <Text color={textSecondary} fontSize="sm">
              {title}
            </Text>
          </Stack>
          <Button
            bg={primary}
            color={white}
            size="lg"
            borderRadius="10rem"
            fontSize="1rem"
            onClick={openEditDescriptionSidePanel}
          >
            {t("course.editCourseDescription")}
          </Button>
        </Flex>

        <Flex
          p="1.5rem 2rem"
          border={`1px dashed ${newBorder}`}
          borderRadius="1rem"
        >
          <Box
            minHeight="200px"
            dangerouslySetInnerHTML={{
              __html: course.description,
            }}
          />
        </Flex>
      </Box>
    </>
  );
};

export default TabCourseDescription;
