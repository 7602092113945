import React from 'react';
import {
  Flex,
  Heading,
  HStack,
  IconButton,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { newBorder, textPrimary, textSecondary } from '../../utils/colors';
import { icons } from '../../utils/icons';

const ArrowBackIcon = () => <img src={icons.arrowBack} alt="back" />;

const SinglePageTopBar = ({
  backFunction,
  title,
  type,
  editFunction,
  children,
  loading,
}) => {
  return (
    <Flex
      w="100%"
      p="1.5rem 2rem"
      justifyContent="space-between"
      alignItems="center"
      borderBottom={`1px solid ${newBorder}`}
    >
      {!loading ? (
        <>
          <HStack gap="0" justify="space-between" w="100%">
            <HStack gap={0} alignItems="center">
              <IconButton
                icon={<ArrowBackIcon />}
                onClick={backFunction}
                border={`1px solid ${newBorder}`}
                borderRadius="10rem"
                bg="transparent"
                h="2.5rem"
                w="2.5rem"
                mr={4}
              />
              <Flex direction="column">
                <Heading
                  fontSize="1.25rem"
                  fontWeight="600"
                  color={textPrimary}
                >
                  {title}
                </Heading>
                <Text
                  fontSize="0.875rem"
                  fontWeight="600"
                  color={textSecondary}
                >
                  {type}
                </Text>
              </Flex>
            </HStack>
            <HStack gap={0}>{children}</HStack>
          </HStack>
        </>
      ) : (
        <Spinner size="xl" />
      )}
    </Flex>
  );
};

export default SinglePageTopBar;
