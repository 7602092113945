import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { listAttendees } from "../../actions/attendeeActions";

import OptionsOutsideSelect from "./OptionsOutsideSelect";

const MultiAttendeeSelect2 = ({
  currentOptions,
  value,
  onChange,
  isSelectedHidden,
}) => {
  const dispatch = useDispatch();

  const attendeeList = useSelector((state) => state.attendeeList);
  const { attendees } = attendeeList;

  const [attendeeOptions, setAttendeeOptions] = useState([]);

  useEffect(() => {
    dispatch(listAttendees());
    console.log("Multi Attendees select attendees loaded", attendees);
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (attendees && attendees.length > 0) {
      const activeAttendees = attendees.filter(
        (attendee) => attendee.status === "active"
      );

      console.log("currentOptions", currentOptions);
      console.log("attendees", attendees); // Add this line
      console.log("activeAttendees", activeAttendees);

      // Transform currentOptions to match options structure
      const transformedCurrentOptions = currentOptions.map((option) => ({
        value: option.attendee._id,
        label: option.attendee.firstName + " " + option.attendee.lastName,
        image: option.attendee.image,
      }));

      console.log("transformedCurrentOptions", transformedCurrentOptions);
      console.log("attendee Options 3", attendeeOptions); // Add this line

      // Create options for active speakers, excluding transformedCurrentOptions
      const options = activeAttendees
        .filter(
          (s) =>
            !transformedCurrentOptions.some(
              (current) => current.value === s._id
            )
        )
        .map((s) => ({
          value: s._id,
          label: s.firstName + " " + s.lastName,
          image: s.image,
        }));

      setAttendeeOptions([...options]);
    }
  }, [attendees, currentOptions]);

  console.log("attendeeOptions", attendeeOptions);

  return (
    <>
      <div>
        <OptionsOutsideSelect
          onChange={onChange}
          isMulti
          options={attendeeOptions}
          value={value}
          title="Selected Attendees"
          isSelectedHidden={isSelectedHidden}
        />
      </div>
    </>
  );
};

export default MultiAttendeeSelect2;
