import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCourse } from "../../../../actions/courseActions";
import { listProfessionGroups } from "../../../../actions/professionGroupActions";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Input,
  HStack,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  TabPanels,
  TabPanel,
  Text,
  Stack,
  Circle,
  InputGroup,
  InputLeftAddon,
  Tooltip,
  Heading,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  backgroundLight,
  buttonRedText,
  textPrimary,
  backgroundSky,
} from "../../../../utils/colors";
import ImageUpload from "../../../../components/input/ImageUpload";
import { useTranslation } from "react-i18next";
import InputField from "../../../../components/input/InputField";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import MultiSpeakerSelectNoInactive from "../../../../components/select/MultiSpeakerSelectNoInactive";
import { Editor } from "@tinymce/tinymce-react";
import FormControlDatePickerRangeDouble from "../../../../components/input/FormControlDatePickerRangeDouble";
import FormControlTimePicker from "../../../../components/input/FormControlTimePicker";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import FormControlAddress from "../../../../components/input/FormControlAddress";
import { shade } from "polished";
import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";

const CreateCourse = ({ close }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const tabsData = [
    { label: t("course.details") },
    { label: t("course.dateTimeLocation") },
    // { label: t("course.speakers") },
    // { label: t("course.tabDescription") },
    // { label: t("course.tabPricing") },
    // { label: t('course.attendees') },
  ];

  const [activeTab, setActiveTab] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [image, setImage] = useState("");
  const [maximumAttendees, setMaximumAttendees] = useState(80);
  const [title, setTitle] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [street, setStreet] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  const [free, setFree] = useState(true);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [location, setLocation] = useState("");
  const [attendees] = useState([]);
  const [description, setDescription] = useState("");
  const [speakers, setSpeakers] = useState([]);
  const [sliderValue, setSliderValue] = useState(maximumAttendees); // Initialize with 0
  const sliderRef = useRef(null);

  const professionGroupList = useSelector((state) => state.professionGroupList);
  const { professionGroups } = professionGroupList;

  // TOUCHED
  const [titleTouched, setTitleTouched] = useState(false);
  const [startDateTouched, setStartDateTouched] = useState(false);
  const [endDateTouched, setEndDateTouched] = useState(false);

  // ERRORS
  const isTitleError = title === "";
  const isStartDateError = startDate === "";
  const isEndDateError = endDate === "";

  // VALIDATION
  const isTitleValid = title.length >= 1;
  const isStartDateValid = startDate !== "";
  const isEndDateValid = endDate !== "";

  // IS CONTINUE DISABLED
  const isContinueDisabled =
    !isTitleValid || !isStartDateValid || !isEndDateValid;

  useEffect(() => {
    dispatch(listProfessionGroups());
  }, [dispatch]);

  // Call the function to update prices when the course type changes
  useEffect(() => {
    // updatePricesBasedOnCourseType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [free, professionGroups]);

  console.log("professionGroups", professionGroups);

  const [prices, setPrices] = useState([]); // Initialize with an empty array

  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  const updatePrice = (professionGroupId, value) => {
    const updatedPrices = [...prices];
    const index = updatedPrices.findIndex(
      (price) => price.professionGroup === professionGroupId
    );

    if (index !== -1) {
      if (value === "") {
        updatedPrices[index].price = 0;
      } else {
        updatedPrices[index].price = parseInt(value);
      }
    } else {
      if (value === "") {
        updatedPrices.push({ professionGroup: professionGroupId, price: 0 });
      } else {
        updatedPrices.push({
          professionGroup: professionGroupId,
          price: parseInt(value),
        });
      }
    }

    setPrices(updatedPrices);

    console.log("Prices:", prices);
  };

  const handleSetStartTimeToDefault = () => {
    setStartTime("08:00");
  };

  // Update the handler to work with date range
  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  // SUBMIT

  const submitHandler = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    try {
      // Combine startDate and startTime
      const startDateTime = new Date(startDate);
      const [startHours, startMinutes] = startTime.split(":").map(Number);
      startDateTime.setHours(startHours, startMinutes, 0);

      // Assuming you want the end date to be inclusive and end at the end of the day
      // Adjust this as necessary for your application's needs
      const endDateTime = new Date(endDate);
      endDateTime.setHours(23, 59, 59); // Set to the end of the selected end date

      // Use startDateTime and endDateTime in your createCourse action as needed
      await dispatch(
        createCourse(
          title,
          description,
          image,
          location,
          startDateTime,
          endDateTime,
          maximumAttendees,
          attendees,
          speakers,
          city,
          zip,
          street,
          country,
          state,
          prices,
          free
        )
      );
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  // CUSTOM SPEAKERS
  const [customSpeakers, setCustomSpeakers] = useState([]);

  const customSpeakersHandle = (values) => {
    setCustomSpeakers(values);
    setSpeakers(
      values.map((speaker) => {
        return speaker.value;
      })
    );
  };

  console.log("startDate", startDate);
  console.log("endDate", endDate);
  console.log("startTime", startTime);

  return (
    <>
      <Box position="relative">
        <form
          onSubmit={submitHandler}
          style={{
            height: "100%",
          }}
        >
          <SidePanelHeader close={close} title={t("course.createCourse")} />

          <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="7rem 0 4rem"
            >
              <TabsComponent
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabs={tabsData}
                isTitleValid={!isContinueDisabled}
                isProfessionValid={true}
                disabledTabs={[]}
              >
                <TabPanels m="1.5rem 0 0" w="100%">
                  <TabPanel p={0} w="100%">
                    {/* <Heading
                      fontSize="1.125rem"
                      color={textPrimary}
                      fontWeight="600"
                      m="1rem 0"
                    >
                      {t("course.details")}
                    </Heading> */}

                    <Flex
                      direction="column"
                      w="100%"
                      bg={white}
                      borderRadius="1rem"
                      p="0 1.5rem"
                    >
                      <FormControl mb={4}>
                        <FormLabel fontSize="sm">
                          {t("course.courseImage")}
                        </FormLabel>
                        <ImageUpload image={image} setImage={setImage} />
                      </FormControl>

                      <FormControlDefault
                        type="text"
                        isRequired
                        label={t("form.title")}
                        value={title}
                        placeholder={t("placeholder.title")}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setTitleTouched(true);
                        }}
                        onBlur={() => setTitleTouched(true)}
                        isTouched={titleTouched}
                        isError={isTitleError}
                        w="100%"
                        errorMessage={t("formError.titleRequired")}
                      />

                      <FormControlDatePickerRangeDouble
                        isRequired
                        type="date"
                        label={t("form.courseDate")}
                        placeholder={t("placeholder.startDate")}
                        onChange={(start, end) =>
                          handleDateRangeChange(start, end)
                        }
                        startDate={startDate}
                        endDate={endDate}
                        onOpen={() => {}}
                        w="50%"
                        setDefaultStartTime={handleSetStartTimeToDefault}
                        // isTouched={startDate !== "" && endDate !== "" && !isEndDateValid}
                        startDateTouched={startDateTouched}
                        endDateTouched={endDateTouched}
                        isError={isStartDateError || isEndDateError}
                        errorMessage={t("formError.courseDateInvalid")}
                        setStartDateTouched={setStartDateTouched}
                        setEndDateTouched={setEndDateTouched}
                      />

                      <FormControlTimePicker
                        label={t("form.startTime")}
                        value={startTime}
                        onChange={(selectedOption) =>
                          setStartTime(selectedOption.value)
                        }
                        placeholder={t("placeholder.selectStartTime")}
                      />
                      <FormControl mb={4}>
                        <FormLabel fontSize="sm">
                          {t("form.maximumAttendees")}
                        </FormLabel>
                        <Flex maxWidth="100%" m="0">
                          <Input
                            maxWidth="80px"
                            type="number"
                            placeholder={t("placeholder.maximumAttendees")}
                            value={maximumAttendees}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value);
                              console.log("New Input Value:", newValue); // Add this line
                              setMaximumAttendees((prevMaximumAttendees) => {
                                setSliderValue(newValue);
                                return newValue;
                              });
                            }}
                            size="lg"
                            fontSize="1rem"
                            fontWeight="500"
                            border={`1px solid ${newBorder}`}
                            borderRadius="0.75rem"
                            _focusVisible={{
                              zIndex: "1",
                              borderColor: primary,
                              boxShadow: "none",
                            }}
                          />
                          <Flex px={8} w="100%" align="center">
                            <Slider
                              ref={sliderRef}
                              min={0}
                              max={100}
                              step={1}
                              value={maximumAttendees}
                              focusThumbOnChange={false}
                              onChange={(val) => {
                                console.log("Slider Value:", val); // Add this line
                                setMaximumAttendees((prevMaximumAttendees) => {
                                  setSliderValue(val);
                                  console.log(
                                    "Updated maximumAttendees:",
                                    prevMaximumAttendees
                                  );
                                  console.log("Updated sliderValue:", val);
                                  return val;
                                });
                              }}
                            >
                              <SliderTrack bg={backgroundLight}>
                                <Box position="relative" right={10} />
                                <SliderFilledTrack bg={primary} />
                              </SliderTrack>

                              <SliderThumb boxSize={6} />
                            </Slider>
                          </Flex>
                        </Flex>
                      </FormControl>
                    </Flex>
                  </TabPanel>
                  <TabPanel p={0}>
                    <FormControlDefault
                      type="text"
                      isRequired={false}
                      label={t("form.courseLocation")}
                      value={location}
                      placeholder={t("placeholder.courseLocation")}
                      onChange={(e) => {
                        setLocation(e.target.value);
                      }}
                      w="100%"
                    />

                    <HStack gap={4} w="100%">
                      <FormControlAddress
                        label={t("form.address")}
                        onAddressSelect={handleAddressSelect}
                        isRequired={false}
                        errorMessage={t("formError.addressRequired")}
                        w="100%"
                        googleApiKey="AIzaSyCNQfHnntkLgj5Es5V8V0H2BfIetOOZ9yw"
                      />

                      <FormControlDefault
                        type="text"
                        isRequired={false}
                        label={t("form.zip")}
                        value={zip}
                        placeholder={t("placeholder.zip")}
                        onChange={(e) => {
                          setZip(e.target.value);
                        }}
                        w="40%"
                        errorMessage={t("formError.zipRequired")}
                      />
                    </HStack>

                    <HStack gap={4} w="100%">
                      <FormControlDefault
                        type="text"
                        isRequired={false}
                        label={t("form.city")}
                        value={city}
                        placeholder={t("placeholder.city")}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                        w="50%"
                        errorMessage={t("formError.cityRequired")}
                      />

                      <FormControlCountry
                        label={t("form.country")}
                        value={country}
                        onChange={(selectedOption) => {
                          setCountry(
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                        isRequired={false}
                        w="50%"
                        errorMessage={t("formError.countryRequired")}
                      />
                    </HStack>
                  </TabPanel>
                  <TabPanel p={0}>
                    <FormControl mt={4}>
                      <FormLabel fontSize="sm">
                        <Flex justify="space-between">
                          {t("course.addNewSpeaker")}
                        </Flex>
                      </FormLabel>

                      <Flex w="100%" mb={10} flexDirection="column">
                        <Box w="100%">
                          <MultiSpeakerSelectNoInactive
                            onChange={customSpeakersHandle}
                            value={customSpeakers}
                            placeholderText={t("select.speaker")}
                            title={t("selected.speakers")}
                          />
                        </Box>
                      </Flex>
                    </FormControl>
                  </TabPanel>
                </TabPanels>

                <Flex
                  mt={4}
                  direction="column"
                  borderTop={`1px solid ${newBorder}`}
                  p="2rem 0 0"
                >
                  <Flex justify="space-between">
                    <Tooltip
                      bg={white}
                      borderRadius="1rem"
                      p="1rem"
                      label={
                        <Flex
                          direction="column"
                          p="0rem"
                          justify="center"
                          textAlign="center"
                        >
                          {isTitleError && (
                            <Text
                              fontSize="13px"
                              color="#e53e3e"
                              whiteSpace="nowrap"
                            >
                              * {t("formError.titleRequired")}
                            </Text>
                          )}
                          {isStartDateError && (
                            <Text
                              fontSize="13px"
                              color="#e53e3e"
                              whiteSpace="nowrap"
                            >
                              * {t("formError.courseDateInvalid")}
                            </Text>
                          )}
                        </Flex>
                      }
                      isDisabled={!isContinueDisabled}
                      hasArrow
                      placement="top"
                      fontSize="md"
                    >
                      <Button
                        minWidth="10rem"
                        type="submit"
                        size="lg"
                        bg={primary}
                        color={white}
                        fontSize="md"
                        borderRadius="10rem"
                        isDisabled={isContinueDisabled}
                        _hover={{
                          bg: shade(0.3, primary),
                        }}
                        ml={4}
                      >
                        {t("course.buttonLabel")}
                      </Button>
                    </Tooltip>

                    <Flex align="center">
                      {activeTab > 0 && (
                        <Button
                          minWidth="8rem"
                          mr={0}
                          bg={backgroundLight}
                          border={`1px solid ${newBorder}`}
                          size="lg"
                          color={textSecondary}
                          fontSize="md"
                          fontWeight="500"
                          borderRadius="10rem"
                          onClick={() => setActiveTab(activeTab - 1)}
                          isDisabled={isContinueDisabled}
                          _hover={{
                            bg: shade(0.1, backgroundLight),
                          }}
                        >
                          {t("common.back")}
                        </Button>
                      )}

                      {activeTab < 1 && (
                        <Tooltip
                          bg={white}
                          borderRadius="1rem"
                          p="1rem"
                          label={
                            <Flex
                              direction="column"
                              p="0rem"
                              justify="center"
                              textAlign="center"
                            >
                              {isTitleError && (
                                <Text
                                  fontSize="13px"
                                  color="#e53e3e"
                                  whiteSpace="nowrap"
                                >
                                  * {t("formError.titleRequired")}
                                </Text>
                              )}
                              {isStartDateError && (
                                <Text
                                  fontSize="13px"
                                  color="#e53e3e"
                                  whiteSpace="nowrap"
                                >
                                  * {t("formError.courseDateInvalid")}
                                </Text>
                              )}
                            </Flex>
                          }
                          isDisabled={!isContinueDisabled}
                          hasArrow
                          placement="top"
                          fontSize="md"
                        >
                          <Button
                            minWidth="8rem"
                            ml={4}
                            bg={backgroundLight}
                            border={`1px solid ${newBorder}`}
                            size="lg"
                            color={textSecondary}
                            fontSize="md"
                            fontWeight="500"
                            borderRadius="10rem"
                            onClick={() => setActiveTab(activeTab + 1)}
                            isDisabled={isContinueDisabled}
                            _hover={{
                              bg: shade(0.1, backgroundLight),
                            }}
                          >
                            {t("common.continue")}
                          </Button>
                        </Tooltip>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </TabsComponent>
            </Flex>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default CreateCourse;
