import React, { useState, useEffect } from "react";
import { Flex, Heading, Text, Button } from "@chakra-ui/react"; // Add these imports
import { useTable, useSortBy, useRowSelect } from "react-table";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Box,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import Lottie from "lottie-react"; // Make sure to import Lottie if you're using it
import {
  bgLight,
  backgroundLight,
  textLightBlue,
  primary,
  white,
  textPrimary,
  newBorder,
  textSecondary,
} from "../../../utils/colors"; // Assuming colors
import noItemsFound from "../../../assets/lottie/no-items-found.json"; // Assuming you have a lottie file
import noServices from "../../../assets/images/empty-box.png";
import { useTranslation } from "react-i18next";
import ActionsModal from "../../../components/modals/ActionsModal"; // Assuming you have an ActionsModal component

const TableContainerMultiSelect = ({
  navigate,
  loading,
  error,
  isSinglePage,
  columns,
  data,
  className,
  navigateTo,
  noItemsTitle,
  noItemsDescription,
  noItemsButtonHandler,
  noItemsButtonLabel,
  noItemsButtonDisabled,
  onRowClick,
  sortField,
  sortOrder,
  isHeaderWhite,
  noItemsAlt,
  selectedPlanIds,
  setSelectedPlanIds,
  isSmall,
  deleteMultiHandler,
  sendMultiHanlder,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  // Define a state variable to track if the component is in the initial loading state
  const [initialLoading, setInitialLoading] = useState(true);
  // MULTI SELECT

  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const deselectAll = () => {
    // Deselect all rows internally in React Table
    rows.forEach((row) => {
      toggleRowSelected(row.id, false); // Assuming row.id is the correct identifier for your rows
    });
    // Clear the external state
    setSelectedPlanIds([]);
  };

  const { t } = useTranslation();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleRowSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: sortField, desc: sortOrder === "desc", pageIndex: 0 }],
      },

      getRowId: (row) => row._id,
    },
    useSortBy,
    useRowSelect, // This will add selection functionality
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // This dynamically adds the selection column
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Box h="100%" w="100%">
              <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
            </Box>
          ),
          Cell: ({ row }) => (
            <div>
              <input type="checkbox" {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const handleClearSelection = () => {
    setIsActionsModalOpen(false);
    deselectAll();
  };

  // USE EFFECT

  useEffect(() => {
    setSelectedItems(data.filter((plan) => selectedPlanIds.includes(plan._id)));
  }, [selectedPlanIds]);

  useEffect(() => {
    if (selectedPlanIds && selectedPlanIds.length > 0) {
      setIsActionsModalOpen(true);
    } else {
      setIsActionsModalOpen(false);
    }
  }, [selectedPlanIds]);

  useEffect(() => {
    const selectedIds = selectedFlatRows.map((row) => row.original._id);
    // Check if selectedIds and selectedPlanIds are different before updating
    if (
      selectedIds.length !== selectedPlanIds.length ||
      !selectedIds.every((id, index) => id === selectedPlanIds[index])
    ) {
      console.log("Updating selected IDs:", selectedIds);
      setSelectedPlanIds(selectedIds);
    }
  }, [selectedFlatRows, selectedPlanIds, setSelectedPlanIds]);

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <TriangleDownIcon aria-label="sorted descending" />
      ) : (
        <TriangleUpIcon aria-label="sorted ascending" />
      )
    ) : null;
  };

  const navigateFunction = (value) => {
    if (value) {
      navigate(`${navigateTo}/${value}`);
    }
  };

  useEffect(() => {
    // If `loading` is `false`, it means the data has been loaded (either successfully or with an error)
    if (!loading) {
      setInitialLoading(false);
    }
  }, [loading]);

  if (initialLoading) {
    // If the component is in the initial loading state, you can return a loader or null
    return (
      <Flex justify="center" align="center" w="100%" p={20}>
        <Spinner size="xl" color={primary} />
      </Flex>
    );
  }

  const noItemsAlternative = () => {
    return (
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        margin="0 auto 2rem"
        p="0 0 0rem"
        w="100%"
        borderRadius="0.75rem"

        // border={isSinglePage ? `1px dashed ${newBorder}` : "none"}
      >
        <Image h="initial" w="3rem" m="1rem 0 0" src={noServices} />
        <Text mt={2} color={textSecondary} fontSize="sm">
          {t("common.noData")}
        </Text>
      </Flex>
    );
  };

  const noItemsDefault = () => {
    return (
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        margin="0 auto 4rem"
        p="0 0 4rem"
        w="100%"
        borderRadius="0.75rem"
        border={isSinglePage ? `1px dashed ${newBorder}` : "none"}
      >
        {!isSinglePage && (
          <Box
            w="100%"
            h="3rem"
            bg={backgroundLight}
            borderBottom={`1px solid ${newBorder}`}
          ></Box>
        )}
        {/* <Image src={images.noData} alt="no Data" /> */}
        <Flex
          maxWidth="600px"
          margin="0 auto"
          overflow="hidden"
          h={isSinglePage ? "160px" : "260px"}
          justifyContent="center"
          alignItems="baseline"
        >
          <Lottie
            style={{
              width: "100%",
              height: "100%",
            }}
            animationData={noItemsFound}
            loop={true}
          />
        </Flex>

        <Heading
          fontSize="1.375rem"
          fontWeight="600"
          color={textPrimary}
          margin="1.5rem 0 0.5rem"
        >
          {noItemsTitle}
        </Heading>
        <Text color={textSecondary}>{noItemsDescription}</Text>

        {noItemsButtonHandler && (
          <Button
            size="lg"
            fontSize="md"
            borderRadius="10rem"
            bg={primary}
            color={white}
            m="2rem 0 0"
            onClick={noItemsButtonHandler}
            isDisabled={noItemsButtonDisabled}
          >
            {noItemsButtonLabel}
          </Button>
        )}
      </Flex>
    );
  };

  // You can add a new condition here to handle the case when the data length is zero
  if (!data || (data.length === 0 && !loading)) {
    return <>{noItemsAlt ? noItemsAlternative() : noItemsDefault()}</>;
  }

  const handleRowClick = (e, rowId) => {
    // Exclude clicks on input elements like switches or buttons
    if (!["INPUT", "BUTTON"].includes(e.target.tagName)) {
      onRowClick(rowId);
    }
  };

  return (
    <>
      <ActionsModal
        isOpen={isActionsModalOpen}
        onClose={handleClearSelection}
        onDelete={deleteMultiHandler}
        onSendEmail={sendMultiHanlder}
        selectedCount={selectedPlanIds.length}
        selectedItems={selectedItems.map((plan) => ({
          _id: plan._id,
          treatmentPlanID: plan.treatmentPlanID,
        }))}
        isLong
        t={t}
      />

      {/* <Button onClick={deselectAll}>Deselect All</Button> */}
      {loading ? (
        <Flex justify="center" align="center" w="100%" p={20}>
          <Spinner size="xl" color={primary} />
        </Flex>
      ) : error ? (
        <Flex justify="center" align="center" w="100%" p={20}>
          <Text variant="danger">{error}</Text>
        </Flex>
      ) : (
        <Table
          {...getTableProps()}
          className={className}
          borderBottom={`0px solid ${newBorder}`}
        >
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    bg={isHeaderWhite ? white : bgLight}
                    color={textLightBlue}
                    fontSize="0.65rem"
                    fontWeight="600"
                    borderBottom={
                      isHeaderWhite ? "none" : `1px solid ${newBorder}`
                    }
                    p={isHeaderWhite ? 2 : 3}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <Flex align="center">
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  onClick={(e) => handleRowClick(e, row.original._id)}
                  _hover={{ bg: backgroundLight, cursor: "pointer" }}
                  borderBottom={`1px solid ${newBorder}`}
                  _last={{ borderBottom: "none" }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td p={4} {...cell.getCellProps()} border="none">
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
    </>
  );
};

export default TableContainerMultiSelect;
