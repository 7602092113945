import {
  PROFESSIONGROUP_LIST_REQUEST,
  PROFESSIONGROUP_LIST_SUCCESS,
  PROFESSIONGROUP_LIST_FAIL,
  PROFESSIONGROUP_DETAILS_REQUEST,
  PROFESSIONGROUP_DETAILS_SUCCESS,
  PROFESSIONGROUP_DETAILS_FAIL,
  PROFESSIONGROUP_CREATE_REQUEST,
  PROFESSIONGROUP_CREATE_SUCCESS,
  PROFESSIONGROUP_CREATE_FAIL,
  PROFESSIONGROUP_CREATE_RESET,
  PROFESSIONGROUP_UPDATE_REQUEST,
  PROFESSIONGROUP_UPDATE_SUCCESS,
  PROFESSIONGROUP_UPDATE_FAIL,
  PROFESSIONGROUP_UPDATE_RESET,
  PROFESSIONGROUP_DELETE_REQUEST,
  PROFESSIONGROUP_DELETE_SUCCESS,
  PROFESSIONGROUP_DELETE_FAIL,
  PROFESSIONGROUP_UPDATE_STATUS_REQUEST,
  PROFESSIONGROUP_UPDATE_STATUS_SUCCESS,
  PROFESSIONGROUP_UPDATE_STATUS_FAIL,
  PROFESSIONGROUP_UPDATE_STATUS_RESET,
  PROFESSIONGROUP_UPDATE_ISCONSULTING_REQUEST,
  PROFESSIONGROUP_UPDATE_ISCONSULTING_SUCCESS,
  PROFESSIONGROUP_UPDATE_ISCONSULTING_FAIL,
  PROFESSIONGROUP_UPDATE_ISCONSULTING_RESET,
} from "../constants/professionGroupConstants";

export const professionGroupListReducer = (
  state = { professionGroups: [], countsByStatus: {}, globalTotalCount: 0 },
  action
) => {
  switch (action.type) {
    case PROFESSIONGROUP_LIST_REQUEST:
      return { loading: true, professionGroups: [] };
    case PROFESSIONGROUP_LIST_SUCCESS:
      return {
        loading: false,
        professionGroups: action.payload.professionGroups,
        pages: action.payload.pages,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        countsByStatus: action.payload.countsByStatus,
        globalTotalCount: action.payload.globalTotalCount,
      };
    case PROFESSIONGROUP_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const professionGroupDetailsReducer = (
  state = { professionGroup: {} },
  action
) => {
  switch (action.type) {
    case PROFESSIONGROUP_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PROFESSIONGROUP_DETAILS_SUCCESS:
      return { loading: false, professionGroup: action.payload };
    case PROFESSIONGROUP_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const professionGroupCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFESSIONGROUP_CREATE_REQUEST:
      return { loading: true };
    case PROFESSIONGROUP_CREATE_SUCCESS:
      return { loading: false, success: true, professionGroup: action.payload };
    case PROFESSIONGROUP_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PROFESSIONGROUP_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const professionGroupUpdateReducer = (
  state = { professionGroup: {} },
  action
) => {
  switch (action.type) {
    case PROFESSIONGROUP_UPDATE_REQUEST:
      return { loading: true };
    case PROFESSIONGROUP_UPDATE_SUCCESS:
      return { loading: false, success: true, professionGroup: action.payload };
    case PROFESSIONGROUP_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PROFESSIONGROUP_UPDATE_RESET:
      return { professionGroup: {} };
    default:
      return state;
  }
};

export const professionGroupDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFESSIONGROUP_DELETE_REQUEST:
      return { loading: true };
    case PROFESSIONGROUP_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PROFESSIONGROUP_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const professionGroupUpdateStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case PROFESSIONGROUP_UPDATE_STATUS_REQUEST:
      return { loading: true };
    case PROFESSIONGROUP_UPDATE_STATUS_SUCCESS:
      return { loading: false, success: true };
    case PROFESSIONGROUP_UPDATE_STATUS_FAIL:
      return { loading: false, error: action.payload };
    case PROFESSIONGROUP_UPDATE_STATUS_RESET:
      return {};
    default:
      return state;
  }
};

export const professionGroupUpdateIsConsultingReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case PROFESSIONGROUP_UPDATE_ISCONSULTING_REQUEST:
      return { loading: true };
    case PROFESSIONGROUP_UPDATE_ISCONSULTING_SUCCESS:
      return { loading: false, success: true };
    case PROFESSIONGROUP_UPDATE_ISCONSULTING_FAIL:
      return { loading: false, error: action.payload };
    case PROFESSIONGROUP_UPDATE_ISCONSULTING_RESET:
      return {};
    default:
      return state;
  }
};
