import React, { useState, useEffect, useRef } from "react";
import Select, { components } from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { listServices } from "../../actions/serviceActions";
import {
  white,
  textPrimary,
  textSecondary,
  newBorder,
  primary,
  backgroundLight,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { Flex, Box, Avatar, Image, Text, Spinner } from "@chakra-ui/react";

const ServiceSelect = ({ services, onChange }) => {
  // TRANSLATION
  const { t } = useTranslation();

  // REDUX
  const dispatch = useDispatch();

  const serviceList = useSelector((state) => state.serviceList);
  const { loading, error } = serviceList;

  // CONSTANTS

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [serviceOptions, setServiceOptions] = useState([]);

  const bottomRef = useRef(null);

  // HANDLERS
  const handleChange = (selectedOption) => {
    if (onChange) {
      onChange(selectedOption);
    }
    // Reset the value of the select after a change
    setValue(null);
  };

  // USE EFFECT
  useEffect(() => {
    dispatch(listServices("", "", "", "", "", true));

    console.log("Services select loaded");
  }, [dispatch]);

  // USE EFFECT TRANSFORM SERVICES FOR REACT SELECT AND SET OPTIONS, EXCLUDE INACTIVE SERVICES
  useEffect(() => {
    if (!loading && Array.isArray(services) && services.length > 0) {
      // Filter out inactive services
      const activeServices = services.filter(
        (service) => service.status !== "inactive"
      );

      const groupedOptions = activeServices.reduce((groups, service) => {
        const groupLabel = service.serviceGroup.name;
        if (!groups[groupLabel]) {
          groups[groupLabel] = [];
        }
        groups[groupLabel].push({
          value: service._id,
          label: service.name,
          image: service.image,
          price: service.price,
          priceVariations: service.priceVariations,
          serviceGroup: service.serviceGroup,
          customId: service.customId,
          status: service.status,
        });
        return groups;
      }, {});

      setServiceOptions(
        Object.keys(groupedOptions).map((groupLabel) => ({
          label: groupLabel,
          options: groupedOptions[groupLabel],
        }))
      );
    }

    if (!loading && services.length > 0) {
      // Scroll to the bottom of the page after setting service options
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, services]);

  // Custom Option component to display the image
  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <Flex
          align="center"
          w="100%"
          p="1rem 1.5rem"
          role="group"
          _hover={{
            bg: primary,
            color: white,
            cursor: "pointer",
          }}
        >
          {/* <Avatar
            w="1.75rem"
            h="1.75rem"
            src={props.data.image}
            name={props.data.label}
            border={`1px solid ${newBorder}`}
            mr={2}
          /> */}

          <Flex align="center" justify="space-between" w="100%">
            <Flex align="baseline">
              <Text
                color={textPrimary}
                _groupHover={{
                  color: white,
                }}
                fontWeight="500"
                fontSize="15px"
                mr={2}
              >
                {props.data.label}
              </Text>

              <Text
                color={textSecondary}
                fontSize="sm"
                fontWeight="400"
                mr={2}
                w="40px"
                _groupHover={{
                  color: white,
                }}
              >
                {props.data.customId}
              </Text>
            </Flex>
            <Text
              color={textSecondary}
              fontSize="md"
              fontWeight="400"
              _groupHover={{
                color: white,
              }}
            >
              {props.data.price} €
            </Text>
          </Flex>
        </Flex>
      </components.Option>
    );
  };

  console.log("Services", services);
  console.log("Service Options", serviceOptions);

  return (
    <>
      {loading ? (
        <Box>
          <Box w="100%">
            <Spinner size="lg" color={primary} />
          </Box>
        </Box>
      ) : (
        <Box w="100%">
          <Box w="100%">
            <Select
              menuIsOpen
              autoFocus
              width="100%"
              onChange={handleChange}
              isSearchable
              value={services.find((option) => option.value === value) || null}
              // onChange={onChange}
              options={serviceOptions}
              placeholder={t("select.service")}
              components={{ Option: CustomOption }}
              styles={{
                menu: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: "1rem",
                  overflow: "hidden",
                  border: "none",
                  position: "relative",
                  boxShadow: `0 0 0 1px ${newBorder}`,
                  zIndex: "0",
                  padding: "0",
                  margin: "1rem 0 0",
                  color: textPrimary,
                }),

                menuList: (baseStyles) => ({
                  ...baseStyles,
                  padding: "0rem 0rem 0",
                }),
                group: (baseStyles) => ({
                  ...baseStyles,
                  padding: "0",
                  margin: "0",
                }),
                groupHeading: (baseStyles) => ({
                  ...baseStyles,
                  fontSize: "12px",
                  fontWeight: "500",
                  textTransform: "normal",
                  backgroundColor: white,
                  borderBottom: `1px solid ${newBorder}`,
                  padding: "0.5rem 1.5rem",
                  margin: "0",
                  color: textSecondary,
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: "0.875rem",

                  padding: "0px 0px",
                  borderBottom: `1px solid ${newBorder}`,
                  margin: "0",
                  backgroundColor: state.hover ? "white" : "white",
                }),

                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? newBorder : newBorder,
                  color: textSecondary,
                  borderRadius: "10rem",
                  backgroundColor: `${white}`,
                  border: `1px solid ${newBorder}`,
                  fontSize: "0.938rem",
                  fontWeight: "500",
                  height: "3rem",
                  width: "100%",
                  minWidth: "500px",
                  padding: "0 0.25rem",
                  ":focus-visible": {
                    borderColor: primary,
                    boxShadow: `0 0 0 1px ${newBorder}`,
                  },
                }),

                singleValue: (baseStyles, provided, state) => {
                  const opacity = state.isDisabled ? 0.5 : 1;
                  const transition = "opacity 300ms";

                  // Custom styles
                  return {
                    ...provided,
                    opacity,
                    transition,
                    color:
                      state.data.value === "" ? "desired color" : textPrimary, // Custom color based on condition

                    // Add any other styles you want to customize
                  };
                },
              }}
            />
          </Box>
          <div ref={bottomRef} />
        </Box>
      )}
    </>
  );
};

export default ServiceSelect;
