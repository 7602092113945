import React from "react";
import {
  Text,
  Avatar,
  Stack,
  HStack,
  Image,
  Circle,
  Box,
  Tooltip,
  Icon,
  Progress,
  Flex,
  Button,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  textSecondary,
  textPrimary,
  buttonBlue,
  buttonBlueText,
  newBorder,
  buttonGreenText,
  buttonRed,
  buttonRedText,
  white,
  greenVivid,
  buttonAltSuccess,
  darkBlue,
  buttonGreen,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";
import { format } from "morgan";
import {
  TREATMENTPLAN_DELETE_RESET,
  TREATMENTPLAN_APPROVE_RESET,
  TREATMENTPLAN_CANCEL_RESET,
} from "../../../../constants/treatmentPlanConstants";
import { FaEye, FaCircleXmark, FaCircleCheck } from "react-icons/fa6";
import { TbEye } from "react-icons/tb";
import { shade } from "polished";

export const columns = (
  dispatch,
  translate,
  approveTreatmentPlanPatientPublic,
  handleApprove,
  handleCancel,
  hasApprovedPlan,
  openSidePanelLong
) => {
  // console.log("plans", plans);
  // console.log("userId", userId);
  console.log("hasApprovedPlan", hasApprovedPlan);

  return [
    {
      Header: translate("patient.columnID"),
      accessor: (row) => {
        const treatmentPlanID = row.treatmentPlanID;

        return (
          // <Stack gap={0}>
          //   <Text
          //     fontSize="sm"
          //     fontWeight="500"
          //     color={textPrimary}
          //     whiteSpace="nowrap"
          //   >
          //     Gydymo planas{" "}
          //     {/* <Text as="span" color={textSecondary} fontSize="xs">
          //       ({treatmentPlanID})
          //     </Text> */}
          //   </Text>
          //   {/* <Text color={textSecondary} fontSize="xs" fontWeight="500">
          //     {treatmentPlanID}
          //   </Text> */}
          //   {/* <Text
          //     maxWidth="60px"
          //     overflow="hidden"
          //     whiteSpace="nowrap"
          //     fontSize="xs"
          //     fontWeight="500"
          //     color={textSecondary}
          //   >
          //     {treatmentPlanID}
          //   </Text> */}
          // </Stack>
          <Text
            maxWidth="60px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="xs"
            fontWeight="500"
            color={textSecondary}
          >
            {treatmentPlanID}
          </Text>
        );
      },
    },
    {
      Header: translate("patient.columnCreatedAt"),
      disableSortBy: false,
      accessor: (row) => {
        const { createdAt } = row;
        const isCurrentYear = dayjs().year() === dayjs(createdAt).year();
        return (
          <HStack gap={2}>
            <Stack gap={0} overflow="hidden">
              {createdAt && (
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  isTruncated
                  textTransform="capitalize"
                >
                  {dayjs(createdAt).format("YYYY MMMM D")}
                </Text>
              )}
              {!isCurrentYear && (
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  isTruncated
                  noOfLines={1}
                  whiteSpace="normal"
                  overflow="hidden"
                  w="100%"
                  maxWidth="100px"
                >
                  {dayjs(createdAt).format("YYYY")}
                </Text>
              )}
            </Stack>
          </HStack>
        );
      },
    },
    {
      Header: translate("treatmentPlan.columnPrice"),
      disableSortBy: false,
      accessor: (row) => {
        const { totalPrice } = row;

        return (
          <HStack gap={2}>
            <Stack gap={0} overflow="hidden">
              {totalPrice && (
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  isTruncated
                  textTransform="capitalize"
                >
                  {totalPrice.toFixed(2)} €
                </Text>
              )}
            </Stack>
          </HStack>
        );
      },
    },
    {
      Header: translate("treatmentPlan.columnServices"),
      disableSortBy: false,
      accessor: (row) => {
        const { services } = row;

        return (
          <HStack gap={2}>
            <Stack gap={0} overflow="hidden">
              <Text
                fontSize="sm"
                color={textSecondary}
                fontWeight="500"
                whiteSpace="nowrap"
                overflow="hidden"
                isTruncated
                textTransform="capitalize"
              >
                {services.length}
              </Text>
            </Stack>
          </HStack>
        );
      },
    },
    // {
    //   Header: translate("patient.columnStatus"),
    //   disableSortBy: false,
    //   accessor: (row) => {
    //     const { patient, status } = row;

    //     const tenantID = row.patient.tenantID;
    //     const userId = row.patient._id;

    //     const handleApprove2 = (treatmentPlanId) => {
    //       console.log("treatmentPlanId", treatmentPlanId);
    //       dispatch(
    //         approveTreatmentPlanPatientPublic(tenantID, userId, treatmentPlanId)
    //       );
    //     };

    //     return (
    //       <Flex align="center" pr={0} borderRight={`0px solid ${newBorder}`}>
    //         <StatusButton
    //           // onUpdateStatus={() => {
    //           //   console.log("onUpdateStatus");
    //           // }}

    //           currentStatus={status}
    //           options="treatmentPublic"
    //           isToggle
    //           isUnclickable
    //           toggleHandler={() => handleApprove2(row._id)}
    //         />
    //       </Flex>
    //     );
    //   },
    // },
    {
      Header: translate("common.columnAction"),
      disableSortBy: false,
      accessor: (row) => {
        const { patient, status } = row;

        // console.log("row", row);

        const tenantID = row.patient.tenantID;
        const userId = row.patient._id;
        const treatmentPlanId = row._id;

        // console.log("tenantID", tenantID);
        // console.log("userId", userId);
        // console.log("treatmentPlanId", treatmentPlanId);

        return (
          <Flex
            align="center"
            justify="flex-end"
            borderRight={`0px solid ${newBorder}`}
            role="group"
          >
            <Tooltip
              p="0.5rem"
              bg={secondary}
              borderRadius="1rem"
              label={translate("button.cancel")}
              isDisabled={
                status === "cancelled" ||
                status === "approved" ||
                hasApprovedPlan
              }
            >
              <Button
                size="sm"
                bg={buttonRed}
                w="2.5rem"
                h="2.5rem"
                borderRadius="0.75rem"
                _hover={{
                  bg: shade(0.1, buttonRed),
                }}
                onClick={() => handleCancel(row)}
                isDisabled={
                  status === "cancelled" ||
                  status === "approved" ||
                  hasApprovedPlan
                }
              >
                <Icon
                  as={FaCircleXmark}
                  color={buttonRedText}
                  pointerEvents="none"
                />
              </Button>
            </Tooltip>
            <Tooltip
              p="0.5rem"
              bg={secondary}
              borderRadius="1rem"
              label={translate("button.view")}
            >
              <Button
                size="sm"
                bg={buttonBlue}
                w="2.5rem"
                h="2.5rem"
                borderRadius="0.75rem"
                mx={2}
                _hover={{
                  bg: shade(0.1, buttonBlue),
                }}
                onClick={() => openSidePanelLong(row._id)}
              >
                <Icon
                  as={TbEye}
                  w="1.125rem"
                  h="1.125rem"
                  color={buttonBlueText}
                  pointerEvents="none"
                />
              </Button>
            </Tooltip>
            <StatusButton
              // onUpdateStatus={() => {
              //   console.log("onUpdateStatus");
              // }}

              currentStatus={status}
              options="treatmentPublic"
              isToggle={
                (status === "planning" || status === "consulting") &&
                !hasApprovedPlan
              }
              isUnclickable
              toggleHandler={() => handleApprove(row)}
              isLong
              isTooltip={
                (status === "planning" || status === "consulting") &&
                !hasApprovedPlan
              }
              tooltipLabel={translate("button.approve")}
            />
          </Flex>
        );
      },
    },
  ];
};
