import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  listUsers,
  deleteUser,
  updateUserStatus,
} from "../../../../actions/userActions"; // Adjust paths and action names
import { useNavigate } from "react-router-dom";
import { Select, useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import { Box } from "@chakra-ui/react";
import ToastComponent from "../../../../components/partials/ToastComponent";
import { columns } from "../columns/UserListColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import {
  USER_DELETE_RESET,
  USER_UPDATE_RESET,
  USER_CREATE_RESET,
  USER_UPDATE_STATUS_RESET,
} from "../../../../constants/userConstants"; // Adjust constants
import SidePanel from "../../../../components/sidepanel/SidePanel";
import CreateUser from "../sidepanels/CreateUser";
import EditUser from "../sidepanels/EditUser";

const UserList = () => {
  // GENERAL
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // PAGINATION
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [4, 10, 25, 100];

  // FILTER
  const [searchTitle, setSearchTitle] = useState("");

  // SIDEPANELS
  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);

  // CONSTANTS
  const [userId, setUserId] = useState("");
  const [selectedRole, setSelectedRole] = useState("admin");

  // REDUX
  const userLogin = useSelector((state) => state.authLogin);
  const { userInfo } = userLogin;

  const userList = useSelector((state) => state.userList);
  const { loading, error, users, pages } = userList;

  const userCreate = useSelector((state) => state.userCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    user: createdUser,
  } = userCreate;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate;

  const userDelete = useSelector((state) => state.userDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = userDelete;

  const userUpdateStatus = useSelector((state) => state.userUpdateStatus);
  const {
    loading: loadingUpdateStatus,
    error: errorUpdateStatus,
    success: successUpdateStatus,
  } = userUpdateStatus;

  // HANDLERS

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const deleteUserHandler = (userId) => {
    console.log("click");
    dispatch(deleteUser(userId));
    toastComponent.showToast(t("toasts.userDelete"), "success", 3000);
    dispatch({ type: USER_DELETE_RESET });

    if (users.length === 1) {
      setPage(page - 1);
    }
  };

  // USEEFFECT

  useEffect(() => {
    // USEEFFECT LOGIN
    // if (!userInfo || !userInfo.isAdmin) {
    //   navigate("/login");
    // }

    // USEEFFECT CREATE
    if (successCreate) {
      toastComponent.showToast(t("toasts.userRegister"), "success", 3000);
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");

      dispatch({ type: USER_CREATE_RESET });

      dispatch(listUsers(searchTitle, page, pageSize, selectedRole));
    }
    // USEEFFECT UPDATE
    else if (successUpdate) {
      toastComponent.showToast(t("toasts.usersUpdate"), "success", 3000);
      closeEditSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch({ type: USER_UPDATE_RESET });

      dispatch(listUsers(searchTitle, page, pageSize, selectedRole));
    }
    // USEEFFECT UPDATE STATUS
    else if (successUpdateStatus) {
      toastComponent.showToast(t("toasts.userStatusUpdate"), "success", 3000);
      closeEditSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch({ type: USER_UPDATE_STATUS_RESET });

      dispatch(listUsers(searchTitle, page, pageSize, selectedRole));
    }

    // USEEFFECT MAIN
    else {
      dispatch(listUsers(searchTitle, page, pageSize, selectedRole));
    }
  }, [
    dispatch,
    navigate,
    searchTitle,
    page,
    pageSize,
    successCreate,
    successUpdate,
    successDelete,
    successUpdateStatus,
    selectedRole,
  ]);

  // SIDEPANEL HANDLERS

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = (value) => {
    setEditSidePanelOpen(true);
    setUserId(value);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    setUserId("");
    document.body.classList.remove("no-scroll");
  };
  const closeEditSidePanelModal = () => {
    setEditSidePanelOpen(false);
    setSidePanelOpen(false);
    setUserId("");
    document.body.classList.remove("no-scroll");
  };

  // LOGS

  console.log("users", users);

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>User List</title>
        <meta name="description" content="Dentacasa User List" />
      </Helmet>

      {/* SIDEPANELS  */}
      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("user.createUser")}
        noBorder={true}
      >
        <CreateUser />
      </SidePanel>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanelModal}
        title={t("user.editUser")}
        noBorder={true}
      >
        <EditUser userId={userId} />
      </SidePanel>

      {/* MAIN  */}
      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("user.users")}
                onChange={onChangeSearchTitle}
                placeholder={t("user.search")}
                searchName={searchTitle}
                buttonLabel={t("user.createUser")}
                buttonHandler={openSidePanelModal}
              >
                {/* Role filter dropdown */}
                <Select onChange={handleRoleChange} value={selectedRole}>
                  <option value="">All Roles</option>
                  <option value="admin">Admin</option>
                  <option value="employee">Employee</option>
                  <option value="patient">Patient</option>
                </Select>
              </Table.Top>
            </Box>

            <Table.Container
              columns={columns(
                dispatch,
                translate,
                deleteUserHandler,
                openEditSidePanelModal,
                updateUserStatus,
                page,
                pageSize
              )}
              data={users}
              loading={loading}
              error={error}
              navigate={navigate}
              navigateTo="/service-groups"
              // noItemsButtonHandler={openSidePanelModal}
              noItemsTitle={t("noItems.noUsers")}
              noItemsDescription={t("noItems.noUsersText")}
              noItemsButtonLabel={t("noItems.noUsersButton")}
            />
            <Table.Pagination
              page={page}
              pages={pages}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
              setPage={setPage}
              setPageSize={setPageSize}
              totalCount={users.length}
            />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default UserList;
