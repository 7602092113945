import React from 'react';
import { Box, HStack, Image } from '@chakra-ui/react';
import { backgroundLight, newBorder } from '../../utils/colors';

import { icons } from '../../utils/icons';

const SinglePageImageWrapper = ({ image }) => {
  return (
    <HStack gap={0} p="0rem 0rem">
      {image ? (
        <Box
          h="260px"
          w="260px"
          borderRadius="100rem"
          overflow="hidden"
          p="0.5rem"
          border={`1px solid ${newBorder}`}
        >
          <Image
            src={image}
            objectFit="cover"
            h="100%"
            w="100%"
            borderRadius="100rem"
            overflow="hidden"
          />
        </Box>
      ) : (
        <Box
          h="260px"
          w="260px"
          borderRadius="100rem"
          overflow="hidden"
          p="0.5rem"
          border={`1px solid ${newBorder}`}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg={backgroundLight}
        >
          <Image src={icons.noImage} />
        </Box>
      )}
    </HStack>
  );
};

export default SinglePageImageWrapper;
