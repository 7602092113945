import React, { forwardRef, useRef } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  IconButton,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from "@chakra-ui/react";
import { white, newBorder, textPrimary } from "../../utils/colors";
import PasswordInput from "./PasswordInput";

import { HiEye, HiEyeOff } from "react-icons/hi";

const FormControlPassword = forwardRef(
  (
    {
      type,
      isRequired,
      label,
      value,
      placeholder,
      onChange,
      isTouched,
      isError,
      w,
      errorMessage,
      isSmall,
      // Add any other props you need
    },
    ref
  ) => {
    const { isOpen, onToggle } = useDisclosure();
    const inputRef = useRef(null);
    const mergeRef = useMergeRefs(inputRef, ref);
    const onClickReveal = () => {
      onToggle();
      if (inputRef.current) {
        inputRef.current.focus({ preventScroll: true });
      }
    };

    return (
      <FormControl
        mb={4}
        isRequired={isRequired}
        isInvalid={isTouched && isError}
        w={w}
      >
        <FormLabel
          fontSize={isSmall ? "xs" : "sm"}
          position="relative"
          color={textPrimary}
        >
          {label}
          {isError && isTouched && (
            <Box position="absolute" top={-1} right={0} zIndex={2}>
              <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
            </Box>
          )}
        </FormLabel>
        <InputGroup align="center">
          <Input
            value={value}
            onChange={onChange}
            bg={white}
            placeholder={placeholder}
            id="password"
            ref={mergeRef}
            name="password"
            type={isOpen ? "text" : "password"}
            autoComplete="new-password"
            color={textPrimary}
            required
            size="lg"
            // h="3.5rem"
            border={`1px solid ${newBorder}`}
            borderRadius="0.75rem"
            boxShadow="none !important"
            fontSize="0.938rem"
            fontWeight="500"
          />
          <InputRightElement h="100%" w="3.5rem">
            <IconButton
              tabIndex={-1}
              variant="text"
              aria-label={isOpen ? "Mask password" : "Reveal password"}
              icon={isOpen ? <HiEyeOff /> : <HiEye />}
              onClick={onClickReveal}
              color={textPrimary}
            />
          </InputRightElement>
        </InputGroup>

        {/* <Input
        type={type}
        bg={white}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        size={isSmall ? "md" : "lg"}
        fontSize={isSmall ? "sm" : "0.938rem"}
        fontWeight="500"
        color={textPrimary}
        border={`1px solid ${newBorder}`}
        borderRadius="0.75rem"
        boxShadow="none !important"
      /> */}
      </FormControl>
    );
  }
);

export default FormControlPassword;
