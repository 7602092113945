import React from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import Select from "react-select";
import { newBorder, white, primary, textPrimary } from "../../utils/colors";
import { useTranslation } from "react-i18next";

const FormControlBank = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
}) => {
  const { t } = useTranslation();

  const generateBanksOptions = () => {
    // Define the profession options
    const bankNames = [
      { value: "siauliu", label: t("bank.siauliu") },
      { value: "seb", label: t("bank.seb") },
      { value: "swedbank", label: t("bank.swedbank") },
      { value: "dnb", label: t("bank.dnb") },
      { value: "nordea", label: t("bank.nordea") },
      { value: "citadele", label: t("bank.citadele") },
      { value: "luminor", label: t("bank.luminor") },
      { value: "paysera", label: t("bank.paysera") },
      { value: "revolut", label: t("bank.revolut") },
      { value: "paypal", label: t("bank.paypal") },
      { value: "other", label: t("bank.other") },
      { value: "none", label: t("bank.none") },
    ];

    return bankNames;
  };

  const banksOptions = generateBanksOptions();

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize="sm" position="relative" color={textPrimary}>
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>

      <Select
        isSearchable
        value={banksOptions.find((option) => option.value === value)}
        onChange={onChange}
        options={banksOptions}
        placeholder={t("placeholder.selectBank")}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            borderRadius: "1rem",
            backgroundColor: white,
            border: `1px solid ${newBorder}`,
            fontSize: "0.938rem",
            fontWeight: "500",
            height: "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
          }),
          option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : "black", // Color of text in options
            backgroundColor: state.isSelected ? primary : white, // Background color of selected option
            ":hover": {
              backgroundColor: primary, // Background color when hovering
              color: "white",
            },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition };
          },
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999, // Use a high value to ensure it's on top of other elements
          }),
          menu: (base) => ({
            ...base,
            zIndex: 1, // Use a high value to ensure it's on top of other elements
            // You can add additional styles if needed
          }),
          menuPosition: "absolute",
        }}
        menuPortalTarget={document.body}
      />
    </FormControl>
  );
};

export default FormControlBank;
