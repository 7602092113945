import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  IconButton,
  Select,
} from "@chakra-ui/react";
import {
  textPrimary,
  newBorder,
  primary,
  textSecondary,
} from "../../utils/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { t } from "i18next";
import dayjs from "dayjs";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const FormControlDatePicker = ({
  type,
  isRequired,
  label,
  value,
  placeholder,
  onChange,
  isTouched,
  isError,
  w,
  errorMessage,
  onOpen,
  onClose,
  isSmall,
  minDate,
  className,
  maxDate,
}) => {
  // CONSTANTS

  //   const [date, setDate] = useState(new Date());

  // console.log("value", value);

  // Parse initial value to Date object if it exists
  const initialValue = value ? dayjs(value).toDate() : null;

  // console.log("initialValue", initialValue);

  const [date, setDate] = useState(initialValue);

  if (maxDate) {
    const maxDate = dayjs(maxDate).toDate();
  } else {
    const maxDate = null;
  }

  // Generate a range of years from 1990 to current year
  const currentYear = dayjs().year();
  const years = Array.from(
    { length: currentYear - 1900 + 1 },
    (_, i) => i + 1900
  );
  const months = [
    t("months.january"),
    t("months.february"),
    t("months.march"),
    t("months.april"),
    t("months.may"),
    t("months.june"),
    t("months.july"),
    t("months.august"),
    t("months.september"),
    t("months.october"),
    t("months.november"),
    t("months.december"),
  ];

  // HANDLERS

  const handleValueChange = (date) => {
    setDate(date);
    if (date) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      console.log("Formatted Date:", formattedDate);
      if (onChange) {
        onChange(formattedDate); // Call onChange only if it's defined
      }
    } else {
      if (onChange) {
        onChange(""); // Or null, depending on how you want to handle the cleared state
      }
    }
  };

  const handleCalendarOpen = () => {
    // onOpen(); // Call onOpen handler passed from parent component
  };

  const handleCalendarClose = () => {
    // if (date) {
    //   onClose();
    // }
  };

  // USE EFFECT
  useEffect(() => {
    // Update the date whenever the value prop changes
    if (value) {
      const newDate = dayjs(value).toDate();
      setDate(newDate);
    } else {
      setDate(null);
    }
  }, [value]);

  // LOGS
  // console.log("isError", isError);
  // console.log("isTouched", isTouched);

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
      className={`customDatePicker ${className}`}
    >
      <FormLabel
        fontSize={isSmall ? "xs" : "sm"}
        position="relative"
        color={textPrimary}
      >
        {label}
        {isError && isTouched && (
          <Box position="absolute" top={-1} right={0} zIndex={2}>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </Box>
        )}
      </FormLabel>
      <Box className="popoverDatePicker singleDatepicker">
        <DatePicker
          selected={date}
          minDate={minDate ? new Date(minDate) : null}
          onChange={handleValueChange}
          dateFormat="yyyy-MM-dd" // Specify the format here
          fontSize={isSmall ? "sm" : "0.938rem"}
          placeholderText={t("placeholder.regDate")}
          maxDate={maxDate}
          onCalendarOpen={handleCalendarOpen}
          onCalendarClose={
            () => console.log("Calendar closed") // Call onCalendarClose handler passed from parent component
          }
          className={`react-datepicker__input-container ${
            isError && isTouched
              ? "react-datepicker__input-container--invalid"
              : ""
          } ${isSmall ? "isSmall" : ""}`}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <Flex
              m="1rem auto 1rem"
              align="center"
              justify="center"
              w="100%"
              px={5}
            >
              <IconButton
                size="xs"
                fontSize="xs"
                icon={<FaAngleLeft />}
                bg="none"
                onClick={decreaseMonth}
                color={textSecondary}
                isDisabled={prevMonthButtonDisabled}
              />
              <Flex align="center" mx={2}>
                <Select
                  fontSize="sm"
                  size="sm"
                  color={textSecondary}
                  minWidth="40px"
                  maxWidth="80px"
                  borderRadius="0.75rem"
                  value={dayjs(date).year()}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>

                <Select
                  ml={1}
                  fontSize="sm"
                  size="sm"
                  color={textSecondary}
                  minWidth="100px"
                  maxWidth="200px"
                  borderRadius="0.75rem"
                  value={months[dayjs(date).month()]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </Flex>

              <IconButton
                size="xs"
                fontSize="xs"
                icon={<FaAngleRight />}
                bg="none"
                onClick={increaseMonth}
                color={textSecondary}
                isDisabled={nextMonthButtonDisabled}
              />
            </Flex>
          )}
        />
      </Box>
    </FormControl>
  );
};

export default FormControlDatePicker;
