import React from "react";
import { Flex, Stack, Text, Heading } from "@chakra-ui/react";
import { textSecondary, newBorder } from "../../../../../utils/colors";
import StatusBadge from "../../../../../components/badges/StatusBadge";
import dayjs from "dayjs";
import useIsMobile from "../../../../../hooks/useIsMobile";

const PublicTreatmentPlanInfo = ({ t, treatmentPlan }) => {
  const isMobile = useIsMobile();

  return (
    <Flex direction="column">
      <Flex align="center">
        <Heading fontSize="1.125rem" fontWeight="600">
          {t("treatmentPlan.treatmentPlan")}
        </Heading>
        <Flex bg={newBorder} h="16px" w="1px" mx="0.5rem" />
        <Text fontSize="1.125rem" fontWeight="600" mr={4}>
          {treatmentPlan &&
            treatmentPlan.treatmentPlanID &&
            treatmentPlan.treatmentPlanID}
        </Text>
        <StatusBadge
          status={treatmentPlan.status}
          options="treatmentPublic"
          isSmall
        />
      </Flex>

      <Flex direction="column" m="1rem 0 0">
        <Flex align="center">
          <Text fontSize="13px" color={textSecondary} fontWeight="500">
            {t("patient.patient")}:
          </Text>
          <Text fontSize="13px" ml={2} fontWeight="500">
            {treatmentPlan &&
              treatmentPlan.patient &&
              treatmentPlan.patient.firstName +
                " " +
                treatmentPlan.patient.lastName}
          </Text>
        </Flex>

        <Flex align="center">
          <Text fontSize="13px" color={textSecondary} fontWeight="500">
            {t("form.phone")}:
          </Text>
          <Text fontSize="13px" ml={2} fontWeight="500">
            {treatmentPlan &&
              treatmentPlan.patient &&
              treatmentPlan.patient.phone}
          </Text>
        </Flex>

        <Flex align="center">
          <Text fontSize="13px" color={textSecondary} fontWeight="500">
            {t("form.email")}:
          </Text>
          <Text fontSize="13px" ml={2} fontWeight="500">
            {treatmentPlan &&
              treatmentPlan.patient &&
              treatmentPlan.patient.email}
          </Text>
        </Flex>

        {isMobile && (
          <Flex align="center">
            <Text fontSize="13px" color={textSecondary} fontWeight="500">
              {t("treatmentPlan.updatedAt")}:
            </Text>
            <Text
              fontSize="13px"
              ml={2}
              fontWeight="500"
              textTransform="capitalize"
            >
              {treatmentPlan && treatmentPlan?.updatedAt
                ? dayjs(treatmentPlan?.updatedAt).format("YYYY MMMM DD")
                : "N/A"}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default PublicTreatmentPlanInfo;
