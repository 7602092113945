import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Function to require and load JSON files dynamically
const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext);

// Load translations dynamically from JSON files
const resources = {
  en: {
    translation: require("./locales/en/en.json"),
  },
  lt: {
    translation: require("./locales/lt/lt.json"),
  },
  ko: {
    translation: require("./locales/ko/ko.json"),
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "lt", // default language
  interpolation: {
    escapeValue: false, // react already escapes values for XSS
  },
});

export default i18n;
