import React from "react";
import {
  Flex,
  Box,
  Button,
  FormControl,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import {
  dark,
  white,
  newBorder,
  textPrimary,
  textSecondary,
  primary,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import Agreement from "../../../partials/Agreement";
import VerificationInputs from "../../../../../components/input/VerificationInputs";

const VerificationStep = ({
  onVerify,
  verificationCode,
  setVerificationCode,
  isLoading,
  email,
}) => {
  const { t } = useTranslation();

  return (
    <Box mt={0} w="100%">
      <Box w="100%" mb={12}>
        <Heading
          fontSize="1.875rem"
          fontWeight="700"
          color={textPrimary}
          mb={3}
        >
          {t("common.enterVerificationCode")}
        </Heading>
        <Text color={textSecondary} fontWeight="500" w="100%" fontSize="sm">
          {t("common.verificationCodeSent")}{" "}
          <Text as="span" fontWeight="500" color={textPrimary}>
            {email}
          </Text>
        </Text>
      </Box>

      <VerificationInputs length={6} onComplete={setVerificationCode} />

      {/* <FormControl mb={6}>
        <Input
          id="verificationCode"
          type="text"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          bg={white}
          autoComplete="off"
          size="lg"
          h="3.5rem"
          fontSize="1rem"
          fontWeight="500"
          border={`1px solid ${newBorder}`}
          borderRadius="1rem"
          // placeholder={t("placeholder.email")}
          mb={4}
        />
      </FormControl> */}

      <Button
        onClick={onVerify}
        isLoading={isLoading}
        h="3.5rem"
        borderRadius="10rem"
        bg={dark}
        color={white}
        border={`1px solid ${dark}`}
        textAlign="center"
        w="100%"
        _hover={{
          bg: "rgba(13, 12, 34, 0.8)",
          color: white,
          border: `rgba(13, 12, 34, 0.8)`,
        }}
      >
        {t("common.verify")}
      </Button>

      <Flex pt={10} direction="column" w="100%">
        <Text fontSize="sm" color={textSecondary} textAlign="center">
          {t("common.verificationNotReceived")}
          <Text
            ml={1}
            size="sm"
            as="span"
            fontWeight="500"
            color={primary}
            cursor="pointer"
            onClick={() => {}}
          >
            {t("common.resendCode")}
          </Text>
        </Text>
      </Flex>

      {/* <Agreement /> */}
    </Box>
  );
};

export default VerificationStep;
