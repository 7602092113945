import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const useAuthNavigate = () => {
  let navigate = useNavigate();
  const userInfo = useSelector((state) => state.authLogin.userInfo);

  // Check if user is logged in and redirect if necessary
  useEffect(() => {
    if (userInfo && window.location.pathname === "/login") {
      navigate("/dashboard");
    }
  }, [userInfo, navigate]);
};

export default useAuthNavigate;
