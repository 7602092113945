import React from "react";
import {
  Text,
  Image,
  HStack,
  Progress,
  Box,
  Avatar,
  Tooltip,
  Flex,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  white,
  textSecondary,
  textPrimary,
  newBorder,
  primary,
  buttonBlueText,
  buttonGreenText,
  buttonRedText,
  buttonYellowText,
  buttonRedNew,
  newDarkBlue,
  buttonBlue,
  buttonGreen,
  buttonRed,
  greenVivid,
} from "../../../../utils/colors";
import StatusButton from "../../../../components/buttons/StatusButton"; // Update the path accordingly
import MoreButton from "../../../../components/buttons/MoreButton";
import TooltipStatusEntry from "../../../../components/partials/TooltipStatusEntry"; // Update the path accordingly

export const columns = (
  dispatch,
  openEditSidePanelModal,
  translate,
  updateCourseStatus,
  deleteCourseHandler,
  page,
  pageSize,
  handleSortChange
) => {
  return [
    {
      Header: () => (
        <Box onClick={() => handleSortChange("customId")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.customId")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "customId",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { customId } = row.original;

        return (
          <Text
            maxWidth="50px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="11px"
            color={textSecondary}
            fontWeight="500"
          >
            {customId}
          </Text>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("title")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("course.courseName")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "title",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { title, image } = row.original;

        return (
          <HStack gap={0}>
            {image ? (
              <Box
                h="48px"
                w="48px"
                borderRadius="0.75rem"
                overflow="hidden"
                border={`1px solid ${newBorder}`}
              >
                <Image h="100%" w="100%" objectFit="cover" src={image} />
              </Box>
            ) : (
              <Avatar
                size="md"
                name={title}
                borderRadius="0.75rem"
                border={`1px solid ${newBorder}`}
              />
            )}
            <VStack ml={4} gap={0}>
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textPrimary}
                maxWidth="180px"
                isTruncated // This will add ellipsis
                noOfLines={2} // This will limit the text to two lines
                whiteSpace="normal"
                overflow="hidden"
                w="100%"
              >
                {title}
              </Text>
              <Progress
                className="attendeesTotal"
                w="180px"
                value={80}
                mt="0.25rem"
                color={primary}
                size="sm"
                height="4px"
                borderRadius="0.5rem"
              />
            </VStack>
          </HStack>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("city")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.city")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "city",
      disableSortBy: true,
      Cell: ({ row }) => {
        const rowOriginal = row.original;

        const city =
          rowOriginal.address.length > 0 ? rowOriginal.address[0].city : null;

        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {city ? city : "-"}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("speaker")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.speaker")}</Text>
          </Flex>
        </Box>
      ),
      disableSortBy: true,
      accessor: "speaker",
      Cell: ({ row }) => {
        const { speakers } = row.original;

        console.log("speakers", speakers);

        return (
          <div>
            {speakers.length > 1 ? (
              <Flex
                alignItems="center"
                justifyContent="flex-start"
                position="relative"
                pl="1rem"
              >
                {speakers.map((speaker) => (
                  <Box marginLeft="-0.75rem" key={speaker._id}>
                    <Tooltip
                      bg={newDarkBlue}
                      p={4}
                      borderRadius="1rem"
                      label={
                        <Text fontSize="sm" fontWeight="500" color={white}>
                          {speaker.firstName + " " + speaker.lastName}
                        </Text>
                      }
                      key={speaker._id}
                    >
                      <Avatar
                        w="1.75rem"
                        h="1.75rem"
                        src={speaker.image || ""}
                        name={speaker.firstName + " " + speaker.lastName}
                        border={`1px solid ${newBorder}`}
                      />
                    </Tooltip>
                  </Box>
                ))}
              </Flex>
            ) : speakers.length === 1 ? (
              <Flex alignItems="center">
                <Avatar
                  h="1.75rem"
                  w="1.75rem"
                  src={speakers[0].image || ""}
                  name={speakers.firstName + " " + speakers[0].lastName}
                  border={`1px solid ${newBorder}`}
                />
                <Text
                  fontSize="sm"
                  fontWeight="500"
                  color={textSecondary}
                  ml={2}
                  isTruncated // This will add ellipsis
                  noOfLines={1} // This will limit the text to two lines
                  whiteSpace="normal"
                >
                  {speakers &&
                    speakers.firstName &&
                    speakers[0].firstName[0] + "." + speakers[0].lastName}
                </Text>
              </Flex>
            ) : (
              // You can render a "No Data" component or Lottie animation here
              <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                -
              </Text>
            )}
          </div>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("totalAttendees")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.attendees")}</Text>
          </Flex>
        </Box>
      ),
      disableSortBy: true,
      accessor: "totalAttendees",
      Cell: ({ row }) => {
        const { attendees, maximumAttendees } = row.original;

        const attendeesCount = attendees.length;
        const registeredCount = attendees.filter(
          (attendee) => attendee.status === "registered"
        ).length;

        const attendedCount = attendees.filter(
          (attendee) => attendee.status === "attended"
        ).length;

        const cancelledCount = attendees.filter(
          (attendee) => attendee.status === "cancelled"
        ).length;

        const notAttendedCount = attendees.filter(
          (attendee) => attendee.status === "notAttended"
        ).length;

        const confirmedCount = attendees.filter(
          (attendee) => attendee.status === "confirmed"
        ).length;

        return (
          <Tooltip
            p={4}
            borderRadius="1rem"
            bg={newDarkBlue}
            label={
              <Flex direction="column" justify="flex-start" align="flex-start">
                <TooltipStatusEntry
                  bg={buttonBlue}
                  color={textPrimary}
                  label={translate("status.registered")}
                  count={registeredCount}
                  icon="FaRegCircleDot"
                />

                <TooltipStatusEntry
                  bg={buttonGreen}
                  color={buttonGreenText}
                  label={translate("status.confirmed")}
                  count={confirmedCount}
                  icon="FaCircleCheck"
                />

                <TooltipStatusEntry
                  bg={greenVivid}
                  color={textPrimary}
                  label={translate("status.attended")}
                  count={attendedCount}
                  icon="FaRegCircleCheck"
                />

                <TooltipStatusEntry
                  bg={buttonRed}
                  color={buttonRedText}
                  label={translate("status.cancelled")}
                  count={cancelledCount}
                  icon="FaCircleXmark"
                />
                <TooltipStatusEntry
                  bg={buttonRedNew}
                  color={white}
                  label={translate("status.notAttended")}
                  count={notAttendedCount}
                  icon="FaCircleExclamation"
                />
              </Flex>
            }
            aria-label="Attendees"
          >
            <Flex direction="column">
              <Flex fontSize="sm" mb="0.25rem">
                <Text fontWeight="500" color={textPrimary}>
                  {attendeesCount}
                </Text>
                {maximumAttendees > 0 ? (
                  <Text fontWeight="500" color={textSecondary}>
                    /{maximumAttendees}
                  </Text>
                ) : (
                  <Text fontWeight="500" color={textSecondary}>
                    /-
                  </Text>
                )}
              </Flex>
              {attendeesCount > 0 && (
                <Progress
                  className="attendeesTotal"
                  value={attendeesCount}
                  max={maximumAttendees}
                  size="sm"
                  height="2px"
                  borderRadius="0.5rem"
                  maxW="40px"
                />
              )}
            </Flex>
          </Tooltip>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("startDate")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("course.courseDate")}</Text>
          </Flex>
        </Box>
      ),
      disableSortBy: true,
      accessor: "startDate",
      Cell: ({ row }) => {
        const { startDate, endDate } = row.original;

        if (
          startDate &&
          endDate &&
          dayjs(startDate).isValid() &&
          dayjs(endDate).isValid()
        ) {
          if (dayjs(startDate).isSame(endDate, "day")) {
            return (
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textSecondary}
                textTransform="capitalize"
              >
                {dayjs(startDate).format("DD MMMM")}
              </Text>
            );
          } else {
            return (
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textSecondary}
                textTransform="capitalize"
              >
                {`${dayjs(startDate).format("DD MMM")} - ${dayjs(
                  endDate
                ).format("DD MMM")}`}
              </Text>
            );
          }
        } else if (startDate && dayjs(startDate).isValid()) {
          // Display only the startDate
          return (
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textSecondary}
              textTransform="capitalize"
            >
              {dayjs(startDate).format("DD MMM")}
            </Text>
          );
        } else {
          return (
            <Text fontSize="sm" fontWeight="500" color={textSecondary}>
              -
            </Text>
          );
        }
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("common.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { status, _id } = row.original;

        const handleUpdateStatus = (newStatus) => {
          dispatch(updateCourseStatus(_id, newStatus));
        };

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
              options="course"
            />
            <MoreButton
              id={_id}
              deleteHandler={deleteCourseHandler}
              editHandler={(e) => {
                e.stopPropagation();
                openEditSidePanelModal(_id);
              }}
            />
          </HStack>
        );
      },
    },
  ];
};
