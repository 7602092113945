import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTreatmentPlan } from "../../../../actions/treatmentPlanActions";
import { backgroundLight } from "../../../../utils/colors";
import {
  Button,
  Flex,
  Box,
  Heading,
  Text,
  IconButton,
  Stack,
  HStack,
  useToast,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  white,
  newBorder,
  textPrimary,
  textSecondary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import StatusBadge from "../../../../components/badges/StatusBadge";
import ServiceList from "./partials/ServiceList";
import mongoose from "mongoose";
import FormControlCoordinator from "../../../../components/input/FormControlCoordinator";
import { listUsersEmployees } from "../../../../actions/userActions";
import FormControlPatientSimple from "../../../../components/input/FormControlPatientSimple";
import FormControlPatientTest from "../../../../components/input/FormControlPatientTest";
import CommentInput from "../../../../components/input/CommentInput";

const CreatePatientTreatmentPlan = ({ currentPatient, close, currentUser }) => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast();

  // CONSTANTS
  const [employee, setEmployee] = useState(currentUser._id);
  const [services, setServices] = useState([]);
  const [status, setStatus] = useState("draft");
  const [coordinator, setCoordinator] = useState("");
  const [addServiceModalOpen, setAddServiceModalOpen] = useState(true);
  const [isFormValid, setIsFormValid] = useState(true);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [patient, setPatient] = useState(currentPatient);

  const [currentEmployees, setCurrentEmployees] = useState([]);

  const [totalTpPrice, setTotalTpPrice] = useState(0);
  const [appointmentDates, setAppointmentDates] = useState({});

  // Additional states for errors
  const [patientError, setPatientError] = useState(false);
  const [coordinatorError, setCoordinatorError] = useState(false);
  const [showServiceList, setShowServiceList] = useState(false);

  // REDUX
  const userEmployeesList = useSelector((state) => state.userEmployeesList);
  const { employees } = userEmployeesList;

  // FUNCTIONS
  const validateServices = (services) => {
    // Check if any service has a quantity of 0
    const isAnyServiceInvalid = services.some(
      (service) => service.quantity === 0
    );

    // If any service is invalid, set form validity to false
    setIsFormValid(!isAnyServiceInvalid);
  };

  // SERVICES CHECK LOGIC
  const updateTreatmentPlanStatus = (services) => {
    if (services.length === 0) {
      setStatus("draft");
      return;
    }

    const areAllServicesCompleted = services.every(
      (service) => service.status === "completed"
    );

    const isAnyServiceUncompleted = services.some(
      (service) => service.status === "uncompleted"
    );

    const isAllConsulting = services.every(
      (service) => service.service.serviceGroup.isConsulting === true
    );

    if (areAllServicesCompleted) {
      setStatus("completed");
    } else if (isAnyServiceUncompleted) {
      setStatus(isAllConsulting ? "consulting" : "planning");
    } else {
      setStatus("planning");
    }
  };

  // HANDLERS

  // Callback function to update the employee for a service
  const handleEmployeeChange = (serviceId, selectedEmployeeId) => {
    const updatedServices = services.map((service) => {
      if (service._id === serviceId) {
        return {
          ...service,
          employee: selectedEmployeeId
            ? employees.find((employee) => employee._id === selectedEmployeeId)
            : null,
        };
      }
      return service;
    });
    setServices(updatedServices);
  };

  const addServiceToList = (selectedService) => {
    // Clone the selectedService to a new object
    const newService = {
      ...selectedService, // Spread to copy properties
      _id: mongoose.Types.ObjectId(), // Generate a new ObjectId
      appointment: null, // Set the appointment to null by default
      employee: null, // Set the employee to null by default
      order: services.length + 1, // Set the order based on the current list length
      paid: false, // You can set other default values here
      // Ensure price is copied by value, not by reference if it's an object
      price:
        typeof selectedService.price === "object"
          ? { ...selectedService.price }
          : selectedService.price,
      priceVariations: selectedService.priceVariations,
      totalPrice:
        selectedService.priceVariations === false ? 0 : selectedService.price,
      service: {
        ...selectedService.service, // Deep copy if 'service' is an object
        _id: selectedService.value,
        name: selectedService.label,
        serviceGroup: selectedService.serviceGroup,
        employee: null,
      },
      status: "uncompleted", // Set the initial status as needed
      teeth: [], // Set the initial teeth array as needed
      quantity: selectedService.priceVariations === true ? 1 : 0,
    };

    console.log("newService", newService);

    setTotalTpPrice(totalTpPrice + newService.totalPrice);

    // Add the new service to the list of services
    setServices((prevServices) => [...prevServices, newService]);

    updateTreatmentPlanStatus([...services, newService]);

    setAddServiceModalOpen(false);
  };

  const saveEditedService = (updatedService) => {
    console.log("Updated Service:", updatedService);
    const newServices = services.map((service) =>
      service._id === updatedService._id ? updatedService : service
    );

    // Reorder the services based on 'order' attribute
    newServices.sort((a, b) => a.order - b.order);

    // Adjust order for all services
    newServices.forEach((service, index) => {
      service.order = index + 1;
    });

    setTotalTpPrice(
      newServices.reduce((total, service) => total + service.totalPrice, 0)
    );
    setServices(newServices);
  };

  const removeService = (serviceId) => {
    const updatedServices = services
      .filter((service) => service._id !== serviceId)
      .map((service, index) => ({ ...service, order: index + 1 }));

    setServices(updatedServices);
    setTotalTpPrice(
      updatedServices.reduce((total, service) => total + service.totalPrice, 0)
    );

    updateTreatmentPlanStatus(updatedServices);
  };

  const serviceStatusHandler = (serviceId) => {
    // Find the index of the service with the matching ID
    const serviceIndex = services.findIndex(
      (service) => service._id === serviceId
    );

    if (serviceIndex !== -1) {
      // Toggle the status of the service
      const updatedServices = [...services];
      updatedServices[serviceIndex].status =
        services[serviceIndex].status === "uncompleted"
          ? "completed"
          : "uncompleted";

      setServices(updatedServices);
      updateTreatmentPlanStatus(updatedServices, status);
    }
  };

  const moveServiceUp = (serviceId) => {
    setServices((prevServices) => {
      const index = prevServices.findIndex(
        (service) => service._id === serviceId
      );
      if (index > 0) {
        const newServices = [...prevServices];
        // Swap the services
        [newServices[index], newServices[index - 1]] = [
          newServices[index - 1],
          newServices[index],
        ];
        // Swap their orders
        [newServices[index].order, newServices[index - 1].order] = [
          newServices[index - 1].order,
          newServices[index].order,
        ];
        return newServices;
      }
      return prevServices;
    });
  };

  const moveServiceDown = (serviceId) => {
    setServices((prevServices) => {
      const index = prevServices.findIndex(
        (service) => service._id === serviceId
      );
      if (index < prevServices.length - 1) {
        const newServices = [...prevServices];
        // Swap the services
        [newServices[index], newServices[index + 1]] = [
          newServices[index + 1],
          newServices[index],
        ];
        // Swap their orders
        [newServices[index].order, newServices[index + 1].order] = [
          newServices[index + 1].order,
          newServices[index].order,
        ];
        return newServices;
      }
      return prevServices;
    });
  };

  // SUBMIT HANDLER

  const submitHandler = (e) => {
    e.preventDefault();

    // Reset errors
    setPatientError(false);
    setCoordinatorError(false);

    let hasError = false;

    // Validate patient
    if (!patient) {
      setPatientError(true);
      hasError = true;
    }

    // Validate coordinator
    if (!coordinator) {
      setCoordinatorError(true);
      hasError = true;
    }

    if (!patient || !coordinator) {
      // Update showServiceList state based on patient and coordinator selection
      setShowServiceList(false);
      return; // Exit the function to prevent form submission
    }

    // Prevent submission if there are errors
    if (hasError) {
      return;
    }
    // Check if there are any services added
    if (services.length === 0) {
      toast({
        title: "Cannot Submit",
        description: "Please add at least one service to the treatment plan.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return; // Exit the function without closing the modal
    }

    updateTreatmentPlanStatus(services);

    dispatch(
      createTreatmentPlan({
        patient: currentPatient ? currentPatient._id : patient,
        employee: employee,
        services: services,
        status: status,
        coordinator: coordinator,
      })
    );
    // dispatch({ type: TREATMENTPLAN_LIST_RESET });
    // dispatch({ type: USER_DETAILS_RESET });
    // dispatch({ type: USER_EMPLOYEE_DETAILS_RESET });
    // dispatch({ type: USER_DOCTOR_SERVICES_RESET });

    close();
  };

  // USE EFFECT

  useEffect(() => {
    setShowServiceList(!!patient && !!coordinator);
  }, [patient, coordinator]);

  useEffect(() => {
    dispatch(
      listUsersEmployees(
        "", // searchTerm
        1, // page
        10, // pageSize
        "lastName", // sortField
        "asc" // sortOrder: 'asc' for ascending or 'desc' for descending
        // ... other parameters if needed
      )
    );
    setCurrentEmployees(employees);
  }, [dispatch]);

  useEffect(() => {
    console.log("loaded");
    validateServices(services);
  }, [services]);

  useEffect(() => {
    const initialAppointmentDates = {};
    services.forEach((service) => {
      if (service.appointment) {
        // Assuming the appointment is a Date object
        const date = new Date(service.appointment);
        // Format to 'YYYY-MM-DDThh:mm'
        const formattedDate = date.toISOString().substring(0, 16); // "16" to cut off seconds and milliseconds
        initialAppointmentDates[service._id] = formattedDate;
      } else {
        initialAppointmentDates[service._id] = "";
      }
    });
    setAppointmentDates(initialAppointmentDates);
  }, [services]);

  // LOGS

  return (
    <>
      <Box position="relative" h="100%">
        <form onSubmit={submitHandler}>
          {/* Error message for patient */}
          {patientError && (
            <Text color="red.500" m="1rem 0">
              {t("patient.errorSelectPatient")}
            </Text>
          )}

          {/* Error message for coordinator */}
          {coordinatorError && (
            <Text color="red.500" m="1rem 0">
              {t("treatmentPlan.errorSelectCoordinator")}
            </Text>
          )}

          <Flex
            justify="space-between"
            align="center"
            p="1.5rem 2rem 1.5rem"
            borderBottom={`1px solid ${newBorder}`}
            mb="0rem"
            position="absolute"
            zIndex={2}
            bg={white}
            w="100%"
            top="0px"
            width="calc(100%)"
          >
            <Flex align="center">
              <IconButton
                bg="none"
                border={`1px solid ${newBorder}`}
                color={textSecondary}
                onClick={close}
                icon={<FaArrowRightFromBracket />}
                borderRadius="0.75rem"
                fontSize="0.75rem"
              />
              <Stack gap={0} ml={4}>
                <Flex align="center">
                  <Heading
                    fontSize="1.275rem"
                    color={textPrimary}
                    fontWeight="600"
                    mr={4}
                  >
                    {t("patient.createPatientTreatmentPlan")}
                  </Heading>

                  <StatusBadge status={status} options="treatment" />
                </Flex>

                <Text fontSize="sm" color={textSecondary} fontWeight="500">
                  {!currentPatient ? (
                    <Text
                      color={textSecondary}
                      fontWeight="500"
                      fontSize="sm"
                      as="span"
                      mr={1}
                    >
                      {t("patient.patient")}
                    </Text>
                  ) : (
                    <Text color={textSecondary} fontWeight="500" fontSize="sm">
                      <Text
                        color={textSecondary}
                        fontWeight="500"
                        fontSize="sm"
                        as="span"
                        mr={1}
                      >
                        {t("patient.patient")}:
                      </Text>

                      {currentPatient.firstName + " " + currentPatient.lastName}
                    </Text>
                  )}
                </Text>
              </Stack>
            </Flex>

            <Flex direction="column" justify="center" align="flex-end">
              <Text color={textSecondary} fontWeight="500" fontSize="xs">
                {t("patient.createdBy")}:
              </Text>
              <Flex align="center">
                <Text fontWeight="500" fontSize="sm" color={textPrimary}>
                  {currentUser.lastName} {currentUser.firstName[0] + "."}
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Box
            w="100%"
            h="calc(100vh - 157px)"
            overflow="scroll"
            p={!currentPatient ? "0rem 2rem 4rem" : "0rem 2rem 0rem 2rem"}
          >
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="6rem 0 0"
            >
              <HStack spacing={4} w="100%">
                {!currentPatient && (
                  <Flex w="50%" direction="column">
                    <Heading
                      fontSize="1.125rem"
                      color={textPrimary}
                      fontWeight="600"
                      m="1rem 0"
                    >
                      {t("treatmentPlan.selectPatient")}
                    </Heading>

                    <FormControlPatientTest
                      onChange={(selectedOption) => {
                        console.log(
                          "Selected patient ID:",
                          selectedOption.value
                        );
                        // Update your state or perform other actions based on the selected patient
                        // For example:
                        setPatient(selectedOption.value);
                      }}
                    />
                  </Flex>
                )}

                <Flex w="50%" direction="column">
                  <Heading
                    fontSize="1.125rem"
                    color={textPrimary}
                    fontWeight="600"
                    m="1rem 0"
                  >
                    {t("treatmentPlan.selectCoordinator")}
                  </Heading>
                  <FormControlCoordinator
                    value={coordinator}
                    onChange={(selectedOption) => {
                      setCoordinator(selectedOption.value);
                    }}
                    isRequired={true} //
                    w="100%"
                  />
                </Flex>
              </HStack>
            </Flex>

            {showServiceList && (
              <>
                <Flex
                  direction="column"
                  w="100%"
                  align="flex-start"
                  justify="center"
                  p="0rem 0rem 0rem"
                >
                  {services && services.length > 0 && (
                    <Flex w="100%" justify="space-between" align="center">
                      <Heading
                        fontSize="1.125rem"
                        color={textPrimary}
                        fontWeight="600"
                        m="1rem 0"
                      >
                        {t("service.services")}
                      </Heading>
                      <Text
                        color={textSecondary}
                        fontWeight="500"
                        fontSize="md"
                      >
                        {t("stats.totalPrice")}:
                        <Text
                          ml={1}
                          as="span"
                          color={textPrimary}
                          fontSize="md"
                          fontWeight="500"
                        >
                          {totalTpPrice} €
                        </Text>
                      </Text>
                    </Flex>
                  )}

                  <Flex
                    direction="column"
                    w="100%"
                    bg={white}
                    borderRadius="0rem"
                    p="0rem 0 0rem"
                  >
                    <ServiceList
                      services={services}
                      addServiceToList={addServiceToList}
                      handleEmployeeChange={handleEmployeeChange}
                      employeeOptions={employeeOptions}
                      employees={employees}
                      appointmentDates={appointmentDates}
                      saveEditedService={saveEditedService}
                      removeService={removeService}
                      addServiceModalOpen={addServiceModalOpen}
                      setAddServiceModalOpen={setAddServiceModalOpen}
                      moveServiceUp={moveServiceUp}
                      moveServiceDown={moveServiceDown}
                      serviceStatusHandler={serviceStatusHandler}
                      status={status}
                      w="70%"
                    />
                  </Flex>
                </Flex>
              </>
            )}
            {showServiceList && services.length > 0 && (
              <Flex w="100%" m="0 0 4rem" direction="column">
                <Heading
                  fontSize="1.125rem"
                  color={textPrimary}
                  fontWeight="600"
                  m="1rem 0"
                >
                  {t("treatmentPlan.notes")}
                </Heading>
                <Flex
                  bg={backgroundLight}
                  p="1rem"
                  borderRadius="1rem"
                  w="100%"
                  m="0 auto"
                >
                  <CommentInput />
                </Flex>
              </Flex>
            )}
          </Box>

          <Flex
            mt={0}
            justify="center"
            direction="column"
            borderTop={`1px solid ${newBorder}`}
            p="1.5rem 2rem"
            position="relative"
            zIndex={0}
            bg={white}
            bottom="0"
            w="100%"
          >
            <Flex align="center" justify="flex-end">
              {!isFormValid && (
                <Text
                  color="red.500"
                  fontWeight="500"
                  fontSize="sm"
                  textAlign="center"
                  mr={8}
                >
                  {t("service.errorQuantityZero")}
                </Text>
              )}

              <Flex align="center" justify="flex-end">
                <Button
                  minWidth="10rem"
                  mr={4}
                  bg={backgroundLight}
                  size="lg"
                  color={textSecondary}
                  fontSize="md"
                  borderRadius="10rem"
                  onClick={close}
                  _hover={{
                    bg: "#F4DEDB",
                    color: textPrimary,
                  }}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  minWidth="10rem"
                  type="submit"
                  size="lg"
                  bg={primary}
                  color={white}
                  fontSize="md"
                  borderRadius="10rem"
                  isDisabled={!isFormValid || services.length === 0}
                  _hover={{
                    bg: secondary,
                  }}
                >
                  {t("patient.createPatientTreatmentPlan")}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </form>
      </Box>
    </>
  );
};

export default CreatePatientTreatmentPlan;
