import React, { useState } from "react";
import {
  Text,
  Image,
  HStack,
  Stack,
  Avatar,
  Flex,
  Tooltip,
  Box,
  Input,
} from "@chakra-ui/react";
import {
  textSecondary,
  textPrimary,
  white,
  newDarkBlue,
} from "../../../../utils/colors";
import MoreButton from "../../../../components/buttons/MoreButton";
// import StatusButtonAttendees from '../../../../components/buttons/StatusButtonAttendees';
import StatusButton from "../../../../components/buttons/StatusButton";
import ToggleButton from "../../../../components/buttons/ToggleButton";
import CoursePriceButton from "../../../../components/buttons/CoursePriceButton";
import PricePopover from "../../../../components/popovers/PricePopover";

export const columns = (
  translate,
  removeAttendeeHandler,
  updateCourseAttendeeStatusHandler,
  updateCourseAttendeePaidStatusHandler,
  handleSortChange,
  updateCourseAttendeePriceHandler,
  isOpen,
  setIsOpen
) => {
  return [
    {
      Header: () => (
        <Box onClick={() => handleSortChange("customId")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.customId")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "customId",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { customId } = row.original.attendee;

        return (
          <Text
            maxWidth="50px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="11px"
            color={textSecondary}
            fontWeight="500"
          >
            {customId}
          </Text>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("lastName")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("attendee.attendee")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "lastName",
      disableSortBy: true,
      Cell: ({ row }) => {
        const attendee = row.original.attendee;

        const name = attendee.firstName + " " + attendee.lastName;

        return (
          <Stack spacing={1}>
            <HStack gap={2}>
              {attendee.image ? (
                <Image
                  w="2.5rem"
                  h="2.5rem"
                  src={attendee.image}
                  alt={name}
                  borderRadius="10rem"
                  objectFit="cover"
                />
              ) : (
                <Avatar
                  h="2.5rem"
                  w="2.5rem"
                  name={name}
                  // bg="#dceeff" color="#3a74a9"
                />
              )}
              <Stack spacing={0}>
                <Text
                  fontSize="md"
                  color={textPrimary}
                  fontWeight="600"
                  isTruncated
                  maxWidth="200px"
                >
                  {name}
                </Text>
                {attendee.profession && (
                  <Text
                    fontSize="sm"
                    color={textSecondary}
                    fontWeight="500"
                    isTruncated
                    noOfLines={1}
                    whiteSpace="normal"
                    overflow="hidden"
                    w="100%"
                    maxWidth="200px"
                  >
                    {attendee.profession.name}
                  </Text>
                )}
              </Stack>
            </HStack>
          </Stack>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("clientName")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("attendee.columnClient")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "clientName",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { client } = row.original.attendee;

        const clientName = (client && client.clientName) || null;

        return (
          <Tooltip
            p={4}
            borderRadius="1rem"
            bg={newDarkBlue}
            label={<Text color={white}>{clientName ? clientName : "-"}</Text>}
          >
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textSecondary}
              isTruncated
              maxWidth="150px"
            >
              {clientName ? clientName : "-"}
            </Text>
          </Tooltip>
        );
      },
    },

    // {
    //   Header: translate('attendee.clinics'),
    //   disableSortBy: false,
    //   accessor: (row) => {
    //     const { attendee } = row;
    //     console.log('attendee', attendee);

    //     const clinics = attendee.clinics || [];

    //     const formatClinicName = (name) => {
    //       const parts = name.split(' ');
    //       if (parts.length > 1) {
    //         const firstNameInitial = parts[0].charAt(0);
    //         const lastName = parts[parts.length - 1];
    //         return `${firstNameInitial}. ${lastName}`;
    //       }
    //       return parts[0];
    //     };

    //     return (
    //       <div>
    //         {clinics ? (
    //           <Flex
    //             alignItems="center"
    //             justifyContent="flex-start"
    //             position="relative"
    //             pl="1rem"
    //           >
    //             {clinics.map((clinic) => (
    //               <Box marginLeft="-0.75rem" key={clinic._id}>
    //                 <Tooltip label={clinic.name} key={clinic._id}>
    //                   <Avatar
    //                     size="xs"
    //                     src={clinic.image || ''}
    //                     name={clinic.name}
    //                     border={`1px solid ${newBorder}`}
    //                   />
    //                 </Tooltip>
    //               </Box>
    //             ))}
    //           </Flex>
    //         ) : clinics.length === 1 ? (
    //           <Flex alignItems="center">
    //             <Avatar
    //               h="1.5rem"
    //               w="1.5rem"
    //               src={clinics[0].image || ''}
    //               name={clinics[0].name}
    //               border={`1px solid ${newBorder}`}
    //             />
    //             <Text
    //               fontSize="sm"
    //               fontWeight="500"
    //               color={textSecondary}
    //               ml={2}
    //             >
    //               {formatClinicName(clinics[0].name) || '-'}
    //             </Text>
    //           </Flex>
    //         ) : (
    //           <Text fontSize="sm" fontWeight="500" color={textSecondary}>
    //             N/A
    //           </Text>
    //         )}
    //       </div>
    //     );
    //   },
    // },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("city")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.city")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "city",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { address } = row.original.attendee;

        const city = address && address[0] ? address[0].city : null;

        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {city ? city : "-"}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("price")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.price")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "price",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { price, registerPrice } = row.original;

        console.log("row", row.original);

        console.log("price", price);

        const handleUpdatePrice = (newPrice) => {
          console.log("Updating price:", newPrice);
          updateCourseAttendeePriceHandler(row.original.attendee._id, newPrice);
        };

        return (
          <>
            {price ? (
              <>
                <PricePopover
                  price={price}
                  defaultPrice={registerPrice}
                  onChange={handleUpdatePrice}
                />
              </>
            ) : (
              <PricePopover
                price={price}
                defaultPrice={registerPrice}
                onChange={handleUpdatePrice}
              />
            )}
          </>
        );
      },
    },
    // {
    //   Header: translate("attendee.price"),
    //   accessor: (row) => {
    //     const prices = row;
    //     const attendee = row.attendee;

    //     // Check if attendee has a professionGroup
    //     if (attendee.profession && attendee.profession.professionGroup) {
    //       const attendeeProfessionGroupId = attendee.profession.professionGroup;

    //       // Find the corresponding price for the attendee's profession group
    //       const matchingPrice = prices.find(
    //         (price) => price.professionGroup._id === attendeeProfessionGroupId
    //       );

    //       // Display the price if found, or a default value if not found
    //       const priceToDisplay = matchingPrice ? (
    //         <Text fontSize="sm" fontWeight="500" color={textSecondary}>
    //           {matchingPrice.price} €
    //         </Text>
    //       ) : (
    //         <Text fontSize="sm" fontWeight="500" color={textSecondary}>
    //           N/A
    //         </Text>
    //       );

    //       return priceToDisplay;
    //     } else {
    //       // Handle the case where attendee.professionGroup or _id is undefined
    //       return (
    //         <Text fontSize="sm" fontWeight="500" color={textSecondary}>
    //           N/A
    //         </Text>
    //       );
    //     }
    //   },
    // },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("paid")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("attendee.payment")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "paid",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { paid } = row.original;

        const attendee = row.original.attendee;

        const { _id } = attendee;

        const attendeeId = _id;

        // console.log("attendeeId", attendeeId);

        const handleUpdatePaidStatus = (newStatus) => {
          console.log("Updating paid status:", newStatus);
          console.log("newStatus", newStatus);
          updateCourseAttendeePaidStatusHandler(attendeeId, newStatus);
        };

        return (
          <Flex>
            {/* <Text fontSize="sm" fontWeight="500" color={textSecondary}>
              {paid ? 'Paid' : 'Not paid'}
            </Text> */}
            <ToggleButton
              initialStatus={paid} // Pass the initial status (true or false)
              onToggle={(newStatus) => handleUpdatePaidStatus(newStatus)} // Pass the callback function to handle the toggle
            />
          </Flex>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("common.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        // Access the full data object for the row
        const { status } = row.original;
        const { _id } = row.original.attendee; // Assuming `attendee` contains the `status` and `_id`

        // console.log("row", row);

        const handleUpdateStatus = (newStatus) => {
          updateCourseAttendeeStatusHandler(_id, newStatus);
        };

        return (
          <HStack gap={0} justify="flex-end">
            {/* <StatusButtonAttendees
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
            /> */}
            <StatusButton
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
              options="attendees"
            />
            <MoreButton
              id={_id}
              deleteHandler={removeAttendeeHandler}
              deleteLabel={translate("course.removeAttendee")}
            />
          </HStack>
        );
      },
    },
  ];
};
