import React from "react";
import Chart from "react-apexcharts";
import { textSecondary } from "../../utils/colors";
import { useTranslation } from "react-i18next";
import noServices from "../../assets/images/empty-box.png";
import { Flex, Image, Text } from "@chakra-ui/react";

const CoursesPieChart = ({ data }) => {
  const { t } = useTranslation();
  const colors = ["#cbb4fb", "#8dd0f8", "#f4598b", "#f98491", "#75e1a2"];

  // Check if all data values are 0
  const allZeros = data.every((value) => value === 0);

  // Change the colors to gray for data values that are 0
  const modifiedColors = data.map((value, index) =>
    value === 0 ? "#D3D3D3" : colors[index]
  );

  const options = {
    chart: {
      sparkline: { enabled: true },
    },
    animations: {
      enabled: true,
      easing: "easeinout",
      dynamicAnimation: {
        speed: 350,
      },
    },
    colors: modifiedColors,
    stroke: { width: 0 },
    legend: { show: false },
    dataLabels: { enabled: false },
    labels: [
      t("status.registered"),
      t("status.attended"),
      t("status.notAttended"),
      t("status.cancelled"),
      t("status.confirmed"),
    ],
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.9,
        donut: {
          size: "80%",
          labels: {
            show: true,
            name: {
              offsetY: 25,
              fontSize: "0.875rem",
            },
            value: {
              offsetY: -15,
              fontWeight: 500,
              fontSize: 30,

              formatter: (value) => `${value}`,
            },
            total: {
              show: true,
              fontSize: "1rem",
              label: t("stats.totalCourses"),
              fontWeight: 500,
              color: textSecondary,
              formatter: (value) =>
                `${value.globals.seriesTotals.reduce(
                  (total, num) => total + num
                )}`,
            },
          },
        },
      },
    },
    tooltip: {
      enabled: true,
      theme: "dark", // This can be "dark" or "light"
      style: {
        backgroundColor: "#333", // Custom background color
        fontSize: "12px",
      },
      // You can further customize the tooltip styles here
      // For example, to change font size, color etc.
      x: {
        show: true,
      },
      y: {
        formatter: (val) => `${val}`, // Customize as needed
      },
      marker: {
        show: true,
      },
    },
  };

  console.log("data", data);

  return (
    <>
      {allZeros ? (
        <Flex justify="center" align="center" direction="column" p="3rem 0">
          <Image h="4rem" w="auto" m="0rem 0 0" src={noServices} />
          <Text fontSize="sm" color={textSecondary} m="1rem 0 0">
            {t("common.noData")}
          </Text>
        </Flex>
      ) : (
        <Chart type="donut" height={200} series={data} options={options} />
      )}
    </>
  );
};

export default CoursesPieChart;
