import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useToast,
  Button,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserEmployeeDetails,
  cancelAllTreatments,
  listDoctorServices,
} from "../../../../actions/userActions";
import { primary, textPrimary } from "../../../../utils/colors";
import { useNavigate } from "react-router-dom";
import ToastComponent from "../../../../components/partials/ToastComponent";
import { useTranslation } from "react-i18next";
import EmployeeHeader from "./partials/EmployeeHeader";
import EmployeeDetails from "./partials/EmployeeDetails";
import EmployeePlans from "./partials/EmployeePlans";
import { USER_DETAILS_RESET } from "../../../../constants/userConstants";
import useRoleCheck from "../../../../hooks/useRoleCheck";
import UserStats from "../../../../components/widgets/UserStats";
import EmployeeDoctorStats from "./partials/EmployeeDoctorStats";
import EmployeeBankDetails from "./partials/EmployeeBankDetails";

const EmployeeSingleLong = ({ userId, close, userInfo }) => {
  // GENERAL
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const toast = useToast();
  const [loadingDisplay, setLoadingDisplay] = useState(true);

  // ROLES
  const isAdmin = useRoleCheck("admin");
  const isDoctor = useRoleCheck("employee_doctor");

  // State for Switch
  const [showDoctorPlans, setShowDoctorPlans] = useState(true); // default to true or based on the available data

  // CONSTANTS

  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState("");
  const [doctorTreatmentPlans, setDoctorTreatmentPlans] = useState([]);
  const [coordinatorTreatmentPlans, setCoordinatorTreatmentPlans] = useState(
    []
  );
  const [profession, setProfession] = useState("");
  const [services, setServices] = useState([]);

  // REDUX
  const userEmployeeDetails = useSelector((state) => state.userEmployeeDetails);
  const { loading, error, user } = userEmployeeDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate;

  const userDoctorServices = useSelector((state) => state.userDoctorServices);
  const {
    loading: loadingDoctorServices,
    error: errorDoctorServices,
    doctorServices,
  } = userDoctorServices;

  // // FILTER TREATMENT PLANS
  // const filteredTreatmentPlans = employeeTreatmentPlans.filter((plan) => {
  //   // Assuming each treatment plan has an 'employee' field with 'firstName' and 'lastName'
  //   const employeeName = `${plan.employee.firstName} ${plan.employee.lastName}`;
  //   return employeeName.toLowerCase().includes(searchTerm.toLowerCase());
  // });

  // HANDLERS

  // USEEFFECT
  useEffect(() => {
    if (!user || user._id !== userId) {
      dispatch(getUserEmployeeDetails(userId));
      dispatch(listDoctorServices(userId));
      setDoctorTreatmentPlans([]);
      setCoordinatorTreatmentPlans([]);
      setStatus("");
      setProfession("");
    } else {
      setDoctorTreatmentPlans(user.doctorTreatmentPlans);
      setCoordinatorTreatmentPlans(user.coordinatorTreatmentPlans);
      setStatus(user.status);
      setProfession(user.profession);
      setServices(doctorServices);
      if (user.rolePermission === "employee_coordinator") {
        setShowDoctorPlans(false);
      }
    }
  }, [dispatch, userId, user]);

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_DETAILS_RESET });
      dispatch(getUserEmployeeDetails(userId));
    }
  }, [successUpdate]);

  // New useEffect hook
  useEffect(() => {
    async function fetchData() {
      try {
        setLoadingDisplay(true);
        const employeeDetailsPromise = dispatch(getUserEmployeeDetails(userId));
        const doctorServicesPromise = dispatch(listDoctorServices(userId));

        await Promise.all([employeeDetailsPromise, doctorServicesPromise]);
      } catch (error) {
        // Handle any errors here
        console.error("Error loading data", error);
      } finally {
        setLoadingDisplay(false);
      }
    }

    fetchData();
  }, [dispatch, userId]);

  // Determine whether to show the switch button
  // const showSwitchButton =
  //   services.length > 0 && coordinatorTreatmentPlans.length > 0;

  // LOGS
  // console.log("user", user);
  // console.log("services ", services);

  console.log("user", user);

  return (
    <>
      {loading || loadingDoctorServices || loadingDisplay ? (
        <Spinner size="lg" color={primary} />
      ) : (
        <>
          <Box w="100%" h="100%" p="0">
            {user && (
              <Flex direction="column" w="100%" h="100%" overflowY="scroll">
                <EmployeeHeader
                  userInfo={userInfo}
                  user={user}
                  close={close}
                  isAdmin={isAdmin}
                />

                <Flex w="100%" justify="space-between" p="105px 2rem 4rem 2rem">
                  <Flex
                    w="calc(100% - 350px)"
                    direction="column"
                    p="2rem 2rem 0 0rem"
                  >
                    <EmployeeDetails user={user} userId={userId} />

                    {/* Conditional Rendering for Switch Button */}

                    <EmployeePlans
                      loading={loading}
                      loadingDoctorServices={loadingDoctorServices}
                      plans={coordinatorTreatmentPlans}
                      services={services}
                      //   buttonHandler={openCreatePatientTreatmentPlanSidePanel}
                      userInfo={userInfo}
                      //   deleteTreatmentPlan={deleteTreatmentPlan}
                      dispatch={dispatch}
                      profession={user.profession}
                      showDoctorPlans={showDoctorPlans}
                      setShowDoctorPlans={setShowDoctorPlans}
                    />
                  </Flex>

                  <Flex w="350px" direction="column" p="2rem 0rem 0 0rem">
                    {/* <PatientNotes userId={userId} />
                    <PatientAppointments /> */}

                    {isAdmin && (
                      <EmployeeBankDetails loading={loading} user={user} />
                    )}

                    {/* Check if user.doctorTreatmentPlans is not an empty array */}
                    {/* <EmployeeDoctorStats employeeId={userId} />} */}
                    {/* <PatientStats /> */}
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default EmployeeSingleLong;
