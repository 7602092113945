import React, { useState } from "react";
import {
  Text,
  Avatar,
  HStack,
  Image,
  Circle,
  Box,
  Icon,
  Flex,
  Stack,
  Tooltip,
  Progress,
  Input,
  InputLeftAddon,
  InputRightAddon,
  InputGroup,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  textSecondary,
  textPrimary,
  backgroundLight,
  buttonBlue,
  buttonBlueText,
  newBorder,
  white,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";
import { TbCalendar, TbCake, TbClock, TbCheck } from "react-icons/tb";

export const columns = (dispatch, translate, isAdmin) => {
  // Function to format phone number
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    // Example format: "+370 610 00129"
    return phoneNumber.replace(/(\+\d{3})(\d{3})(\d+)/, "$1 $2 $3");
  };

  // Function to check if the birthday is within a week
  const isBirthdayInAWeek = (birthDate) => {
    const today = dayjs();
    const birthdayThisYear = dayjs(birthDate).year(today.year());
    return (
      birthdayThisYear.isAfter(today) &&
      birthdayThisYear.isBefore(today.add(1, "week"))
    );
  };

  // Function to calculate upcoming age
  const calculateUpcomingAge = (birthDate) => {
    const thisYear = dayjs().year();
    const birthYear = dayjs(birthDate).year();
    return thisYear - birthYear;
  };

  return [
    {
      Header: () => (
        <Box>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("employee.columnEmployee")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "lastName",
      disableSortBy: false,
      Cell: ({ row }) => {
        const { firstName, lastName, image, profession, birthDate } =
          row.original;
        const name = `${firstName} ${lastName}`;
        const formattedBirthDate = dayjs(birthDate).format("YYYY MMMM DD");
        const upcomingAge = calculateUpcomingAge(birthDate);

        // console.log("birthDate", birthDate);
        // console.log(
        //   "isBirthdayInAWeek(birthDate)",
        //   isBirthdayInAWeek(birthDate)
        // );

        return (
          <HStack gap={2}>
            {image ? (
              <Image
                h="2rem"
                w="2rem"
                src={image}
                alt={name}
                borderRadius="10rem"
                objectFit="cover"
              />
            ) : (
              <>
                <Avatar size="sm" name={name} bg="#dceeff" color="#3a74a9" />
              </>
            )}

            <Stack gap={0}>
              <Text
                fontSize="md"
                color={textPrimary}
                fontWeight="600"
                isTruncated // This will add ellipsis
                whiteSpace="nowrap"
                w="100%"
                width="260px"
              >
                {lastName}

                <Text as="span" fontWeight="400" ml={1}>
                  {firstName}
                </Text>
              </Text>
              <Text
                fontSize="xs"
                color={textSecondary}
                fontWeight="500"
                whiteSpace="nowrap"
              >
                {profession && profession.name}
              </Text>
            </Stack>
            {isBirthdayInAWeek(birthDate) && (
              <Tooltip
                label={
                  <Stack>
                    <Text
                      fontSize="xs"
                      fontWeight="500"
                      textTransform="capitalize"
                    >
                      {translate("employee.birthdayInWeek")}:{" "}
                      {formattedBirthDate}
                    </Text>
                    <Text fontSize="xs" fontWeight="500">
                      {translate("employee.turns")}: {upcomingAge}{" "}
                      {translate("employee.yearsOld")}
                    </Text>
                  </Stack>
                }
              >
                <Circle
                  ml={1}
                  h="18px"
                  w="18px"
                  bg={buttonBlue}
                  borderRadius="10rem"
                >
                  <Icon h="0.75rem" as={TbCake} color={buttonBlueText} />
                </Circle>
              </Tooltip>
            )}
          </HStack>
        );
      },
    },
    {
      Header: () => (
        <Box>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("employee.columnCompletedServices")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "totalPrice",
      disableSortBy: false,
      Cell: ({ row }) => {
        const { completedServicesCount, totalRevenue } = row.original;

        return (
          <Flex align="center" pr={0}>
            {/* <Icon as={TbMessageCircle} fontSize="lg" color="#989EA7" /> */}
            <Stack gap={0} textAlign="left" mr={0}>
              <Text fontSize="sm" color={textSecondary} fontWeight="500">
                {completedServicesCount}
              </Text>
            </Stack>

            <Text
              borderLeft={`1px solid ${newBorder}`}
              pl={4}
              ml={4}
              fontSize="15px"
              color={textPrimary}
              fontWeight="500"
              whiteSpace="nowrap"
            >
              {totalRevenue} €
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: () => (
        <Box>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("employee.columnNeto")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "percentage",
      disableSortBy: false,
      Cell: ({ row }) => {
        const { totalRevenue } = row.original;
        const [percentage, setPercentage] = useState(2);
        const neto = totalRevenue * (percentage / 100);

        const handlePercentageChange = (e) => {
          const newPercentage = e.target.value;
          setPercentage(newPercentage);
        };

        return (
          <Flex align="center" justify="space-between">
            <InputGroup
              size="sm"
              width="80px"
              borderRadius="10rem"
              overflow="hidden"
              border={`1px solid ${newBorder}`}
              _focus={{ outline: "none" }}
            >
              <Input
                type="number"
                value={percentage}
                onChange={handlePercentageChange}
                w="75px"
                textAlign="center"
                fontSize="sm"
                color={textPrimary}
                fontWeight="500"
                border="none"
                borderRadius="10rem"
                bg={white}
                _focus={{ border: "none", outline: "none" }}
              />
              <InputRightAddon
                children="%"
                border="none"
                color={textSecondary}
                textAlign="center"
                fontWeight="500"
                _focus={{ border: "none", outline: "none" }}
              />
            </InputGroup>
            <Text ml={2} color={textPrimary} fontSize="15px" fontWeight="500">
              {neto.toFixed(2)} €
            </Text>
          </Flex>
          //   <Text
          //     overflow="hidden"
          //     whiteSpace="nowrap"
          //     fontSize="sm"
          //     fontWeight="500"
          //     color={textPrimary}
          //     textTransform="capitalize"
          //   >
          //     2%
          //   </Text>
        );
      },
    },
    // {
    //   Header: () => (
    //     <Box>
    //       <Flex align="center" cursor="pointer">
    //         <Text mr={2}>{translate("employee.columnNeto")}</Text>
    //       </Flex>
    //     </Box>
    //   ),
    //   accessor: "neto",
    //   disableSortBy: true,
    //   Cell: ({ row }) => {
    //     return (
    //       <Text
    //         overflow="hidden"
    //         whiteSpace="nowrap"
    //         fontSize="sm"
    //         fontWeight="500"
    //         color={textPrimary}
    //         textTransform="capitalize"
    //       >
    //         1,611.74 €
    //       </Text>
    //     );
    //   },
    // },
    {
      Header: () => (
        <Box w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("patient.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: false,
      Cell: ({ row }) => {
        const [status, setStatus] = useState("paid");

        const handleUpdateStatus = (newStatus) => {
          setStatus(newStatus);
          // Additional logic if needed
        };

        return (
          <HStack gap={0} justify="flex-end">
            {isAdmin && (
              <StatusButton
                currentStatus={status}
                onUpdateStatus={handleUpdateStatus}
                options="payment"
                isRounded
                isSmall
                // isUnclickable={!isAdmin}
              />
            )}
            {/* <MoreButton
              //   id={_id}
              // deleteHandler={deleteTreatmentPlanHandler}
              //   viewHandler={openPatientSingleLong}
              viewLabel={translate("treatmentPlan.viewPatient")}
              //   viewId={patient._id}
              editHandler={(e) => {
                e.stopPropagation();
                // openEditSidePanelModal(_id);
              }}
            /> */}
          </HStack>
        );
      },
    },
  ];
};
