import React from "react";
import { Tabs, TabList, Tab, TabIndicator } from "@chakra-ui/react";
import {
  backgroundLight,
  primary,
  textPrimary,
  textSecondary,
  newBorder,
  white,
} from "../../utils/colors";
import { shade } from "polished";

const TabsComponentAlt = ({
  activeTab,
  setActiveTab,
  tabs,
  isTitleValid,
  children,
  disabledTabs,
  isSinglePage,
}) => {
  return (
    <Tabs
      index={activeTab}
      onChange={(index) => setActiveTab(index)}
      position="relative"
      variant="unstyled"
      p={isSinglePage ? "2rem 0 0" : "0 0 0rem"}
    >
      <TabList
        bg={isSinglePage ? backgroundLight : backgroundLight}
        borderRadius="0.875rem 0.875rem 0 0"
        h="3.5rem"
        borderBottom={isSinglePage ? `1px solid ${newBorder}` : "none"}
        w="100%"
        overflow="hidden"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            fontSize={isSinglePage ? "15px" : "sm"}
            fontWeight="500"
            p={isSinglePage ? "0.5rem 1.5rem" : "0 1rem"}
            color={textSecondary}
            isDisabled={
              // Check if the current index is in the disabledTabs array or if the title or profession is not valid
              (index !== 0 && !isTitleValid) || disabledTabs.includes(index)
            }
            _selected={{
              color: textPrimary,
              bg: shade(0, backgroundLight),
            }}
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>
      <TabIndicator
        m="-1.5px auto 0"
        height="2px"
        borderRadius="1rem"
        bg={primary}
      />
      {children}
    </Tabs>
  );
};

export default TabsComponentAlt;
