import React from "react";
import {
  Text,
  Stack,
  HStack,
  Circle,
  Box,
  Tooltip,
  Icon,
  Progress,
  Flex,
  IconButton,
  Badge,
  Button,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  textSecondary,
  textPrimary,
  buttonBlue,
  buttonBlueText,
  newBorder,
  buttonGreenText,
  buttonRed,
  buttonRedText,
  white,
  backgroundLight,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";
import {
  FaRegNoteSticky,
  FaRegCircleCheck,
  FaRegCircle,
  FaRegCircleDot,
} from "react-icons/fa6";
import { format } from "morgan";
import _ from "lodash";
import StatusBadge from "../../../../components/badges/StatusBadge";
import {
  TbCake,
  TbFingerprint,
  TbMessageCircle,
  TbMessage,
  TbMessage2Off,
} from "react-icons/tb";
import useNumberFormatter from "../../../../hooks/useNumberFormatter";

export const columns = (
  dispatch,
  translate,
  deleteTreatmentPlanHandler,
  handleSortChange,
  openPatientSingleLong,
  openSidePanelModalHandler,
  isAdmin,
  selectedPlanIds,
  setSelectedPlanIds
) => [
  {
    Header: () => (
      <Flex onClick={() => handleSortChange("treatmentPlanID")} h="100%">
        <Flex align="center" cursor="pointer">
          <Text mr={2}>{translate("patient.columnID")}</Text>
        </Flex>
      </Flex>
    ),
    accessor: "treatmentPlanID",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { treatmentPlanID } = row.original;

      return (
        <Text
          maxWidth="60px"
          overflow="hidden"
          whiteSpace="nowrap"
          fontSize="xs"
          fontWeight="500"
          color={textSecondary}
        >
          {treatmentPlanID}
        </Text>
      );
    },
  },
  {
    Header: () => (
      <Flex onClick={() => handleSortChange("patient.lastName")} h="100%">
        <Flex align="center" cursor="pointer">
          <Text mr={2}>{translate("treatmentPlan.columnPatient")}</Text>
        </Flex>
      </Flex>
    ),
    accessor: "patient.lastName",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { patient } = row.original;

      const birthDate = patient && patient.birthDate;
      const userID = patient && patient.userID;
      const age = dayjs().year() - dayjs(birthDate).year();

      return (
        <Stack gap={1} pr={0} borderRight={`1px solid ${newBorder}`}>
          <HStack gap={2}>
            <Text
              fontSize="md"
              color={textPrimary}
              fontWeight="600"
              isTruncated // This will add ellipsis
              whiteSpace="nowrap"
              w="100%"
              maxWidth="260px"
            >
              {patient && patient.lastName}
              {/* {firstName} */}
              <Text as="span" fontWeight="400">
                {" "}
                {/* {lastName} */}
                {patient && patient.firstName}
              </Text>
            </Text>
          </HStack>
          <Flex align="center">
            <Flex align="center">
              <Circle size="18px" bg={white} color={textSecondary} mr={1}>
                <Icon as={TbCake} fontSize="sm" />
              </Circle>
              <Text fontSize="xs" color={textSecondary} fontWeight="500">
                {age} {translate("patient.yearsOld")}
              </Text>
            </Flex>
            <Flex align="center" ml={2}>
              <Circle size="18px" bg={white} color={textSecondary} mr={1}>
                <Icon as={TbFingerprint} fontSize="sm" />
              </Circle>
              <Text fontSize="xs" color={textSecondary} fontWeight="500">
                {userID}
              </Text>
            </Flex>
          </Flex>
        </Stack>
      );
    },
  },

  {
    Header: () => (
      <Flex onClick={() => handleSortChange("totalPrice")} h="100%">
        <Flex align="center" cursor="pointer">
          <Text mr={2}>{translate("patient.columnServices")}</Text>
        </Flex>
      </Flex>
    ),
    accessor: "totalPrice",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { services, totalPrice } = row.original;

      const formattedPrice = useNumberFormatter(totalPrice);

      // Count total number of services
      const totalServices = services.length;

      // Count the number of completed services
      const completedServices = services.reduce((acc, service) => {
        return acc + (service.status === "completed" ? 1 : 0);
      }, 0);

      const servicesTotal = services.reduce((acc, service) => {
        return acc + service.price;
      }, 0);

      return (
        <Flex
          align="center"
          pr={6}
          borderRight={`1px solid ${newBorder}`}
          justify="space-between"
          maxWidth="140px"
        >
          {/* <Icon as={TbMessageCircle} fontSize="lg" color="#989EA7" /> */}
          <Stack gap={0} textAlign="left" mr={0}>
            <Text fontSize="sm" color={textSecondary} fontWeight="500">
              <Text as="span" color={textPrimary} fontWeight="500">
                {completedServices}
              </Text>
              /{totalServices}
            </Text>
            <Progress
              className="attendeesTotal"
              w="40px"
              value={(completedServices / totalServices) * 100}
              mt="0.25rem"
              color={primary}
              size="sm"
              height="3px"
              borderRadius="0.5rem"
            />
          </Stack>

          <Text
            ml={4}
            fontSize="sm"
            color={textSecondary}
            fontWeight="500"
            whiteSpace="nowrap"
            textAlign="right"
          >
            {formattedPrice} €
          </Text>
        </Flex>
      );
    },
  },
  // {
  //   Header: "GP Kaina",
  //   disableSortBy: false,
  //   accessor: (row) => {
  //     const { services } = row;

  //     const servicesTotal = services.reduce((acc, service) => {
  //       return acc + service.price;
  //     }, 0);

  //     // console.log("servicesTotal", servicesTotal);
  //     return (
  //       <Flex align="center">
  //         <Text
  //           fontSize="sm"
  //           color={textPrimary}
  //           fontWeight="500"
  //           whiteSpace="nowrap"
  //         >
  //           {servicesTotal} €
  //         </Text>
  //       </Flex>
  //     );
  //   },
  // },
  {
    Header: () => (
      <Flex onClick={() => handleSortChange("createdAt")} h="100%">
        <Flex align="center" cursor="pointer">
          <Text mr={2}>{translate("treatmentPlan.columnCreated")}</Text>
        </Flex>
      </Flex>
    ),
    accessor: "createdAt",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { createdAt, employee } = row.original;

      const createdAtDate = dayjs(createdAt).format("MMMM DD");

      const name = employee.firstName[0] + "." + " " + employee.lastName;
      const initials = employee.firstName[0] + employee.lastName[0];

      return (
        <Stack gap={0}>
          <Text
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="sm"
            fontWeight="500"
            color={textPrimary}
            textTransform="capitalize"
          >
            {createdAtDate}
          </Text>
          <Flex>
            {/* <Circle size="20px" bg={buttonBlue} color={textSecondary} mr={1}>
              <Text fontSize="13px" color={buttonBlueText} fontWeight="500">
                {initials}
              </Text>
            </Circle> */}
            <Text
              fontSize="xs"
              color={textSecondary}
              fontWeight="500"
              whiteSpace="nowrap"
            >
              {name}
            </Text>
          </Flex>
        </Stack>
      );
    },
  },
  {
    Header: () => (
      <Flex onClick={() => handleSortChange("notes")} h="100%">
        <Flex align="center" cursor="pointer">
          <Text mr={2}>{translate("treatmentPlan.columnNotes")}</Text>
        </Flex>
      </Flex>
    ),
    accessor: "patient",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { patient } = row.original;

      const notes = patient && patient.notes;

      // console.log("patient", patient);

      const notesCount = patient && patient.notesCount;

      // Function to format notes for display in tooltip
      const formatNotesForDisplay = (notes) => {
        // Get the last 'maxNotesToShow' notes
        const recentNotes = notes.slice(0, 1);

        return recentNotes.map((note, index) => (
          <Flex
            key={note._id}
            w="100%"
            bg={white}
            direction="column"
            p="1rem"
            borderRadius="1rem"
            my={2}
          >
            <Flex
              direction="column"
              align="center"
              justify="center"
              w="100%"
              p="0rem 0 0rem"
            >
              <Flex align="center" justify="space-between" w="100%">
                <Flex align="center">
                  <Circle
                    justify="center"
                    align="center"
                    size="22px"
                    bg={backgroundLight}
                  >
                    <Text color={textSecondary} fontWeight="600" fontSize="xs">
                      {note.author.firstName[0] + note.author.lastName[0]}
                    </Text>
                  </Circle>

                  <Text
                    color={textSecondary}
                    fontWeight="500"
                    fontSize="xs"
                    ml={2}
                  >
                    {note.author.firstName +
                      " " +
                      note.author.lastName[0] +
                      "."}
                  </Text>
                </Flex>
                <Text color={textSecondary} fontWeight="500" fontSize="xs">
                  {dayjs().from(dayjs(note.createdAt), true)}
                </Text>
              </Flex>
              <Flex
                w="100%"
                p="0.75rem 0 0"
                borderBottom={`0px solid ${newBorder}`}
              >
                <Text
                  color={textPrimary}
                  fontWeight="400"
                  fontSize="13px"
                  noOfLines={2}
                >
                  {note.text}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        ));
      };

      return (
        <>
          <Flex position="relative">
            {notesCount > 0 ? (
              <Tooltip
                closeDelay={300}
                bg={backgroundLight}
                borderRadius="1rem"
                overflow="hidden"
                label={
                  <Flex
                    h="100%"
                    borderRadius="1rem"
                    minWidth="300px"
                    direction="column"
                  >
                    <Text
                      color={textPrimary}
                      fontSize="xs"
                      fontWeight="500"
                      p="1rem 0.5rem 0.25rem"
                    >
                      {translate("treatmentPlan.recentNotes")}
                    </Text>
                    {notes && (
                      <Flex direction="column" p="0 0.5rem">
                        {formatNotesForDisplay(notes)}
                      </Flex>
                    )}

                    {/* <Flex align="center" justify="center" w="100%" p="1rem 0">
                      <Button
                        size="sm"
                        fontSize="xs"
                        bg={primary}
                        color={white}
                      >
                        {translate("treatmentPlan.viewAllNotes")}
                      </Button>
                    </Flex> */}
                  </Flex>
                }
                placement="top"
                hasArrow
              >
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => console.log("clicked")}
                >
                  <Icon
                    as={TbMessage}
                    w="initial"
                    h="1.25rem"
                    color="#989EA7"
                    pointerEvents="none"
                  />
                </Button>
              </Tooltip>
            ) : (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => console.log("clicked")}
              >
                <Icon
                  as={TbMessage2Off}
                  w="initial"
                  h="1.25rem"
                  color="#989EA7"
                  pointerEvents="none"
                />
              </Button>
            )}

            {notesCount > 0 && (
              <Badge
                position="absolute"
                top="-3px"
                size="xs"
                h="initial"
                w="1.25rem"
                fontWeight="500"
                color={white}
                bg={
                  notesCount > 0
                    ? "#FF7F7F"
                    : notesCount === 0
                    ? buttonRed
                    : "#FF7F7F"
                }
                borderRadius="10rem"
                textAlign="center"
                pointerEvents="none"
                left="26px"
              >
                {notesCount}
              </Badge>
            )}
          </Flex>
        </>
      );
    },
  },
  {
    Header: () => (
      <Flex onClick={() => handleSortChange("status")} w="100%" h="100%">
        <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
          <Text mr={2}>{translate("patient.columnStatus")}</Text>
        </Flex>
      </Flex>
    ),
    accessor: "status",
    disableSortBy: true,
    Cell: ({ row }) => {
      const { status, _id, patient } = row.original;

      const handleUpdateStatus = (newStatus) => {
        // dispatch(updateSpeakerStatus(_id, newStatus));
      };

      // console.log("patient", patient);

      return (
        <HStack gap={0} justify="flex-end">
          <StatusButton
            isUnclickable
            currentStatus={status}
            onUpdateStatus={handleUpdateStatus}
            options="treatment"
            isRounded
          />
          <MoreButton
            id={_id}
            deleteHandler={deleteTreatmentPlanHandler}
            viewHandler={openPatientSingleLong}
            viewLabel={translate("treatmentPlan.viewPatient")}
            viewId={patient && patient._id}
            editHandler={(e) => {
              e.stopPropagation();
              openSidePanelModalHandler(_id);
            }}
            deleteButtonDisabled={!isAdmin}
          />
        </HStack>
      );
    },
  },
];
