import React, { useState, useEffect } from "react";
import { Flex, Box, Heading, Select, Text, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  backgroundLight,
  newBorder,
  textPrimary,
  primary,
  buttonAltPrimary,
  buttonAltYellow,
  white,
  buttonAltSuccess,
  buttonAltGreen,
} from "../../../../utils/colors";
import BoxUser2 from "./BoxUser2";
import useCountUp from "../../../../hooks/useCountUp";
import dayjs from "dayjs";
import {
  FaFile,
  FaMoneyCheckDollar,
  FaClock,
  FaCircleCheck,
} from "react-icons/fa6"; // Import the icons

const TreatmentPlansStats = ({ loading, countsByStatus, globalTotalCount }) => {
  // GENERAL
  const { t } = useTranslation();

  //CONSTANTS

  const [globalTotal, setGlobalTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalCompleted, setTotalCompleted] = useState(0);
  const [totalConsulting, setTotalConsulting] = useState(0);
  const [totalPlanning, setTotalPlanning] = useState(0);
  const [totalApproved, setTotalApproved] = useState(0);
  const [totalOngoing, setTotalOngoing] = useState(0);

  // FORMAT DURATION
  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    let formattedDuration = "";
    if (hours > 0) formattedDuration += `${hours}h `;
    if (minutes > 0 || hours > 0) formattedDuration += `${minutes}min`;

    return formattedDuration.trim();
  };

  // USE EFFECT

  useEffect(() => {
    if (countsByStatus) {
      setGlobalTotal(globalTotalCount || 0);

      setTotal(countsByStatus.total || 0);
      setTotalCompleted(countsByStatus.completed || 0);
      setTotalConsulting(countsByStatus.consulting || 0);
      setTotalPlanning(countsByStatus.planning || 0);
      setTotalApproved(countsByStatus.approved || 0);
      setTotalOngoing(countsByStatus.ongoing || 0);
    }
  }, [countsByStatus, globalTotalCount]);

  const animatedGlobalTotal = useCountUp(globalTotalCount);
  const animatedTotal = useCountUp(total);
  const animatedTotalCompleted = useCountUp(totalCompleted);
  const animatedTotalConsulting = useCountUp(totalConsulting);
  const animatedTotalPlanning = useCountUp(totalPlanning);
  const animatedTotalApproved = useCountUp(totalApproved);
  const animatedTotalOngoing = useCountUp(totalOngoing);

  // LOGS

  // console.log("globalTotal", globalTotal);
  // console.log("total", total);
  // console.log("totalCompleted", totalCompleted);

  return (
    <Box w="100%" px="2rem" mb={6}>
      <Flex
        w="100%"
        direction="column"
        p="0rem 0rem"
        border={`1px solid ${newBorder}`}
        borderRadius="1rem"
      >
        <Flex w="100%" pt={4} pb={4}>
          <BoxUser2
            loading={loading}
            label={t("dashboard.treatmentPlansTotal")}
            data={animatedTotal}
            of={animatedGlobalTotal > 0 && animatedGlobalTotal}
            color={primary}
            iconColor={white}
            icon={FaFile}
          />
          <BoxUser2
            loading={loading}
            label={t("dashboard.treatmentPlansConsultingPlanning")}
            data={animatedTotalConsulting + animatedTotalPlanning}
            color={buttonAltPrimary}
            iconColor={textPrimary}
            icon={FaClock}
          />
          <BoxUser2
            loading={loading}
            label={t("dashboard.treatmentPlansApprovedOngoing")}
            data={animatedTotalApproved + animatedTotalOngoing}
            color={`linear-gradient(45deg, ${buttonAltGreen} 50%, ${buttonAltYellow} 50%)`}
            icon={FaCircleCheck}
            iconColor={textPrimary}
          />
          <BoxUser2
            loading={loading}
            label={t("dashboard.treatmentPlansCompleted")}
            data={animatedTotalCompleted}
            color={buttonAltSuccess}
            icon={FaCircleCheck}
            iconColor={textPrimary}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default TreatmentPlansStats;
