import React from "react";
import {
  Flex,
  Box,
  Heading,
  Text,
  IconButton,
  Stack,
  Avatar,
  Tooltip,
} from "@chakra-ui/react";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import StatusBadge from "../../../../../components/badges/StatusBadge";
import {
  textPrimary,
  textSecondary,
  newBorder,
  white,
} from "../../../../../utils/colors";
import { useSelector } from "react-redux";
import StatusButton from "../../../../../components/buttons/StatusButton";
import { useTranslation } from "react-i18next";
import CoordinatorPopover from "./CoordinatorPopover";
import UserPopover from "./UserPopover";

const Header = ({
  treatmentPlan,
  currentUser,
  status,
  patient,
  close,
  initialCoordinator,
  employees,
  setCoordinator,
  handleCoordinatorChange,
}) => {
  // GENERAL
  const { t } = useTranslation();
  // console.log("status", status);
  return (
    <Flex
      justify="space-between"
      align="center"
      p="1.5rem 2rem 1.5rem"
      borderBottom={`1px solid ${newBorder}`}
      mb="1rem"
      position="fixed"
      bg={white}
      w="100%"
      zIndex={1}
    >
      <Flex align="center">
        <IconButton
          bg="none"
          border={`1px solid ${newBorder}`}
          color={textSecondary}
          onClick={close}
          icon={<FaArrowRightFromBracket />}
          borderRadius="0.75rem"
          fontSize="0.75rem"
        />
        <Stack gap={0} ml={4}>
          <Flex align="center">
            <Heading
              fontSize="1.275rem"
              color={textPrimary}
              fontWeight="600"
              m={0}
            >
              {treatmentPlan
                ? t("treatmentPlan.treatmentPlan")
                : "Patient Status"}
            </Heading>
            {treatmentPlan ? (
              <Box w="1px" h="1.5rem" bg="#CBCDD0" mx="0.5rem" />
            ) : null}
            <Heading
              fontSize="1.275rem"
              color={textPrimary}
              fontWeight="600"
              mr={4}
            >
              {treatmentPlan ? treatmentPlan.treatmentPlanID : ""}
            </Heading>

            <StatusBadge status={status} options="treatment" />
          </Flex>
          <Text color={textSecondary} fontWeight="500" fontSize="sm">
            <Text
              color={textSecondary}
              fontWeight="500"
              fontSize="sm"
              as="span"
              mr={1}
            >
              {t("patient.patient")}:
            </Text>

            {patient.firstName + " " + patient.lastName}
          </Text>
        </Stack>
      </Flex>
      {/* <StatusButton currentStatus={status} options="treatment" /> */}
      <Flex justify="center" align="center">
        <Flex justify="center" align="center" mr={4}>
          <Text color={textSecondary} fontWeight="500" fontSize="xs" mr={1}>
            {t("treatmentPlan.coordinator")}:
          </Text>
          <UserPopover
            initialUser={initialCoordinator}
            options={employees}
            onChange={handleCoordinatorChange}
            isDisabled={status === "cancelled" || status === "completed"}
          />
          {/* 
          <CoordinatorPopover
            initialCoordinator={initialCoordinator}
            employees={employees}
            onCoordinatorChange={handleCoordinatorChange}
          /> */}
        </Flex>

        <Flex direction="column" justify="center" align="flex-end">
          <Text color={textSecondary} fontWeight="500" fontSize="xs">
            {t("patient.createdBy")}:
          </Text>
          <Flex align="center">
            <Text fontWeight="500" fontSize="sm" color={textPrimary}>
              {currentUser.lastName} {currentUser.firstName[0] + "."}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
