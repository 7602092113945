import React, { useState, useEffect } from "react";
import { Flex, Box, Heading, Select, Text, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  backgroundLight,
  newBorder,
  textPrimary,
  primary,
  white,
} from "../../../../utils/colors";
import BoxUser2 from "./BoxUser2";
import useCountUp from "../../../../hooks/useCountUp";
import dayjs from "dayjs";
import {
  FaFile,
  FaMoneyCheckDollar,
  FaClock,
  FaFileCircleXmark,
} from "react-icons/fa6"; // Import the icons

const ServicesStats = ({
  loading,
  stats,
  total,
  averagePrice,
  averageDuration,
  globalTotalAveragePrice,
  globalTotalAverageDuration,
}) => {
  // GENERAL
  const { t } = useTranslation();

  //CONSTANTS

  const [globalTotal, setGlobalTotal] = useState(0);

  const [totalAveragePrice, setTotalAveragePrice] = useState(0);
  const [totalAverageDuration, setTotalAverageDuration] = useState(0);
  const [totalServices, setTotalServices] = useState(0);
  const [inactiveServices, setInactiveServices] = useState(0);
  const [globalAveragePrice, setGlobalAveragePrice] = useState(0);
  const [globalAverageDuration, setGlobalAverageDuration] = useState(0);

  const animatedTotalAveragePrice = useCountUp(totalAveragePrice);
  const animatedTotalAverageDuration = useCountUp(totalAverageDuration);
  const animatedTotalServices = useCountUp(totalServices);
  const animatedInactiveServices = useCountUp(inactiveServices);
  const animatedGlobalAveragePrice = useCountUp(globalAveragePrice);
  const animatedGlobalAverageDuration = useCountUp(globalAverageDuration);

  // FORMAT DURATION
  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    let formattedDuration = "";
    if (hours > 0) formattedDuration += `${hours}h `;
    if (minutes > 0 || hours > 0) formattedDuration += `${minutes}min`;

    return formattedDuration.trim();
  };

  // USE EFFECT

  useEffect(() => {
    if (stats) {
      setGlobalTotal(total || 0);

      setTotalServices(stats.total || 0);
      setTotalAveragePrice(averagePrice || 0);
      setTotalAverageDuration(averageDuration || 0);
      setInactiveServices(stats.inactive || 0);
      setGlobalAveragePrice(globalTotalAveragePrice || 0);
      setGlobalAverageDuration(globalTotalAverageDuration || 0);
    }
  }, [stats, total, averagePrice, averageDuration, loading]);

  // LOGS
  // console.log("stats", stats);
  // console.log("total", total);
  // console.log("averagePrice", averagePrice);
  // console.log("averagePrice", averagePrice);
  // console.log("averageDuration", averageDuration);
  // console.log("globalTotalAveragePrice", globalTotalAveragePrice);
  // console.log("globalTotalAverageDuration", globalTotalAverageDuration);

  return (
    <Box w="100%" px="2rem" mb={6}>
      <Flex
        w="100%"
        direction="column"
        p="0rem 0rem"
        border={`1px solid ${newBorder}`}
        borderRadius="1rem"
      >
        <Flex w="100%" pt={4} pb={4}>
          <BoxUser2
            loading={loading}
            label={t("dashboard.servicesTotal")}
            data={animatedTotalServices}
            of={globalTotal > 0 && globalTotal}
            color={primary}
            icon={FaFile}
          />
          <BoxUser2
            loading={loading}
            label={t("dashboard.servicesAveragePrice")}
            data={animatedGlobalAveragePrice}
            // data={animatedTotalAveragePrice}
            // of={animatedGlobalAveragePrice}
            color="#A0D1FF"
            icon={FaMoneyCheckDollar}
            isPrice
          />
          <BoxUser2
            loading={loading}
            label={t("dashboard.servicesAverageDuration")}
            data={formatDuration(animatedGlobalAverageDuration)}
            // data={formatDuration(animatedTotalAverageDuration)}
            // of={formatDuration(animatedGlobalAverageDuration)}
            color="#65C790"
            icon={FaClock}
          />
          <BoxUser2
            loading={loading}
            label={t("dashboard.servicesInactive")}
            data={animatedInactiveServices}
            color="#D6D6D7"
            icon={FaFileCircleXmark}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default ServicesStats;
