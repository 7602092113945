import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Icon,
  Flex,
  Text,
} from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa6";
import {
  newBorder,
  textSecondary,
  white,
  backgroundLight,
  primary,
} from "../../utils/colors";

const DropdownButton = ({
  initialLabel,
  items,
  onSelectItem,
  iconComponent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(initialLabel);

  const handleMenuOpen = () => setIsOpen(true);
  const handleMenuClose = () => setIsOpen(false);

  const handleMenuItemClick = (item) => {
    onSelectItem(item);
    setSelectedLabel(item); // Update the label with the selected item
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedLabel(initialLabel);
  }, [initialLabel]);

  console.log("initialLabel", initialLabel);
  console.log("selectedLabel", selectedLabel);

  return (
    <Box p={0} borderRadius="0.75rem">
      <Menu isLazy onOpen={handleMenuOpen} onClose={handleMenuClose}>
        <MenuButton
          as={Button}
          bg={white}
          color={textSecondary}
          border={`1px solid ${newBorder}`}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10.75rem"
          p="0rem 1rem"
          transition="0.2s all"
          _hover={{ bg: backgroundLight }}
          _active={{ bg: backgroundLight }}
          cursor="pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Flex align="center">
            {iconComponent && <Box mr={2}>{iconComponent}</Box>}
            <Text fontSize="0.875rem" color={textSecondary} fontWeight="600">
              {selectedLabel}
            </Text>
            <Icon
              as={FaCaretDown}
              ml={1}
              h="0.75rem"
              w="0.75rem"
              transition="all 0.2s ease"
              transform={isOpen ? "rotate(180deg)" : "rotate(0)"}
            />
          </Flex>
        </MenuButton>
        <MenuList
          p={0}
          m={0}
          borderRadius="1rem"
          maxWidth="100px"
          w="100px"
          overflow="hidden"
        >
          {items.map((item, index) => (
            <MenuItem
              key={index}
              py={2}
              px={4}
              borderBottom={`1px solid ${newBorder}`}
              color={textSecondary}
              fontSize="sm"
              _hover={{ bg: primary, color: "white" }}
              onClick={() => handleMenuItemClick(item)}
            >
              {item}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default DropdownButton;
