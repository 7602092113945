import React from "react";
import { Box, PinInput, PinInputField, HStack } from "@chakra-ui/react";
import { textPrimary, textSecondary } from "../../utils/colors";

const VerificationInputs = ({ length, onComplete }) => {
  const handleChange = (value) => {
    if (value.length === length) {
      onComplete(value);
      console.log("completed", value);
    }
  };

  return (
    <Box w="100%" mb={6}>
      <HStack justify="flex-start" w="100%">
        <PinInput
          color={textSecondary}
          placeholder=""
          onChange={handleChange}
          onComplete={onComplete}
        >
          {Array.from({ length }).map((_, index) => (
            <PinInputField
              color={textPrimary}
              fontWeight="500"
              borderRadius="1rem"
              fontSize="1.5rem"
              h="4rem"
              w="100%"
              key={index}
            />
          ))}
        </PinInput>
      </HStack>
    </Box>
  );
};

export default VerificationInputs;
