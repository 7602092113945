import React from "react";
import { Button, Image, Text } from "@chakra-ui/react";
import google from "../../assets/icons/landing/google.svg";
import { dark, white, newBorder } from "../../utils/colors";

const GoogleButton = ({ label, isWhite, isDisabled }) => {
  return (
    <Button
      mt={4}
      mb={4}
      h="3.5rem"
      borderRadius="10rem"
      bg={isWhite ? white : dark}
      color={isWhite ? dark : white}
      border={isWhite ? `1px solid ${newBorder}` : `1px solid ${dark}`}
      textAlign="center"
      w="100%"
      _hover={{
        bg: isWhite ? dark : "rgba(13, 12, 34, 0.8)",
        color: white,
        border: `rgba(13, 12, 34, 0.8)`,
      }}
      isDisabled={isDisabled}
    >
      <Image src={google} h="1.25rem" w="initial" mr={2} />
      <Text>{label}</Text>
    </Button>
  );
};

export default GoogleButton;
