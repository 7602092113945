import React from "react";
import {
  Text,
  Avatar,
  Stack,
  HStack,
  Image,
  Circle,
  Box,
  Tooltip,
  Icon,
  Progress,
  Flex,
  Button,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  textSecondary,
  textPrimary,
  buttonBlue,
  buttonBlueText,
  newBorder,
  buttonGreenText,
  buttonRedText,
  white,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";
import {
  FaRegNoteSticky,
  FaRegCircleCheck,
  FaRegCircle,
  FaRegCircleDot,
} from "react-icons/fa6";
import { format } from "morgan";
import { deleteTreatmentPlan } from "../../../../actions/treatmentPlanActions";
import {
  TREATMENTPLAN_DELETE_RESET,
  TREATMENTPLAN_APPROVE_RESET,
  TREATMENTPLAN_CANCEL_RESET,
} from "../../../../constants/treatmentPlanConstants";

export const coordinatorColumns = (
  dispatch,
  translate,
  updateTreatmentPlanStatus,
  approveTreatmentPlan,
  plans,
  userId,
  cancelTreatmentPlan
) => {
  const deleteTreatmentPlanHandler = (treatmentPlanId) => {
    console.log("deleteTreatmentPlanHandler", treatmentPlanId);
    dispatch(deleteTreatmentPlan(treatmentPlanId));
    dispatch({ type: TREATMENTPLAN_DELETE_RESET });
  };

  const updateTreatmentPlanStatusHandler = (treatmentPlanId, status) => {
    console.log("updateTreatmentPlanStatusHandler", treatmentPlanId, status);
    dispatch(updateTreatmentPlanStatus(treatmentPlanId, status));
  };

  const approveTreatmentPlanHandler = (treatmentPlanId) => {
    console.log("approveTreatmentPlanHandler", treatmentPlanId);
    dispatch(approveTreatmentPlan(treatmentPlanId));
    dispatch({ type: TREATMENTPLAN_APPROVE_RESET });
  };

  const cancelTreatmentPlanHandler = (treatmentPlanId, userId) => {
    console.log("cancelTreatmentPlanHandler", treatmentPlanId);
    dispatch(cancelTreatmentPlan(treatmentPlanId));
    dispatch({ type: TREATMENTPLAN_CANCEL_RESET });
  };

  // console.log("plans", plans);
  // console.log("userId", userId);

  return [
    {
      Header: translate("patient.columnID"),
      accessor: (row) => {
        const treatmentPlanID = row.treatmentPlanID;

        return (
          <Text
            maxWidth="60px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="12px"
            fontWeight="500"
            color={textSecondary}
          >
            {treatmentPlanID}
          </Text>
        );
      },
    },

    {
      Header: translate("employee.columnPatient"),
      accessor: (row) => {
        const { patient } = row;

        const name = patient
          ? patient.lastName + " " + patient.firstName
          : "N/A";

        const nameAlt = patient
          ? patient.lastName + " " + patient.firstName
          : "N/A";

        return (
          <Flex align="center" h="100%">
            <Avatar
              h="1.5rem"
              w="1.5rem"
              name={patient ? name : "N/A"}
              src={patient.image ? patient.image : null}
              bg="#dceeff"
              fontSize="0.5rem"
              color="#3a74a9"
            />

            <Stack gap={0} ml={2}>
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textPrimary}
                whiteSpace="nowrap"
                lineHeight={1}
                isTruncated
                maxWidth="150px"
              >
                {patient.lastName}
                <Text as="span" color={textSecondary} fontWeight="500" ml={1}>
                  {patient.firstName[0]}.
                </Text>
              </Text>
              <Text
                fontSize="13px"
                color={textSecondary}
                textTransform="capitalize"
                lineHeight={1}
              >
                {patient.birthDate}
              </Text>
            </Stack>
          </Flex>
        );
      },
    },

    {
      Header: translate("patient.columnServices"),
      disableSortBy: false,
      accessor: (row) => {
        const { services } = row;

        console.log("services", services);

        // Count total number of services
        const totalServices = services.length;

        // Count the number of completed services
        const completedServices = services.reduce((acc, service) => {
          return acc + (service.status === "completed" ? 1 : 0);
        }, 0);

        // Calculate the total cost of services
        const servicesTotal = services.reduce((acc, service) => {
          const price = Number(service.price || 0);
          const quantity = Number(service.quantity || 0);
          return acc + price * quantity;
        }, 0);

        return (
          <Flex align="center" pr={0} borderRight={`0px solid ${newBorder}`}>
            {/* <Icon as={TbMessageCircle} fontSize="lg" color="#989EA7" /> */}
            <Stack gap={0} textAlign="left" mr={4}>
              <Text fontSize="sm" color={textSecondary} fontWeight="500">
                <Text as="span" color={textPrimary} fontWeight="500">
                  {completedServices}
                </Text>
                /{totalServices}
              </Text>
              <Progress
                className="attendeesTotal"
                w="40px"
                value={(completedServices / totalServices) * 100}
                mt="0.25rem"
                color={primary}
                size="sm"
                height="3px"
                borderRadius="0.5rem"
              />
            </Stack>

            <Text
              fontSize="sm"
              color={textSecondary}
              fontWeight="500"
              whiteSpace="nowrap"
            >
              {servicesTotal.toFixed(2)} €
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: translate("patient.columnDate"),
      disableSortBy: false,
      accessor: (row) => {
        const { createdAt } = row;

        const isCurrentYear = dayjs().year() === dayjs(createdAt).year();

        return (
          <HStack gap={2}>
            <Stack gap={0} overflow="hidden">
              {createdAt && (
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  isTruncated
                  textTransform="capitalize"
                >
                  {dayjs(createdAt).format("MMMM D")}
                </Text>
              )}
              {!isCurrentYear && (
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  isTruncated
                  noOfLines={1}
                  whiteSpace="normal"
                  overflow="hidden"
                  w="100%"
                  maxWidth="100px"
                >
                  {dayjs(createdAt).format("YYYY")}
                </Text>
              )}
            </Stack>
          </HStack>
        );
      },
    },
    // {
    //   Header: "old",
    //   disableSortBy: false,
    //   accessor: (row) => {
    //     const { services } = row;

    //     // Count total number of services
    //     const totalServices = services.length;

    //     // Count the number of completed services
    //     const completedServices = services.reduce((acc, service) => {
    //       return acc + (service.status === "completed" ? 1 : 0);
    //     }, 0);

    //     return (
    //       <Stack gap={0} textAlign="left">
    //         <Text fontSize="sm" color={textSecondary} fontWeight="500">
    //           <Text as="span" color={textPrimary} fontWeight="500">
    //             {completedServices}
    //           </Text>
    //           /{totalServices}
    //         </Text>
    //         <Progress
    //           className="attendeesTotal"
    //           w="100%"
    //           value={(completedServices / totalServices) * 100}
    //           mt="0.25rem"
    //           color={primary}
    //           size="sm"
    //           height="3px"
    //           borderRadius="0.5rem"
    //         />
    //       </Stack>
    //     );
    //   },
    // },
    // {
    //   Header: translate("patient.columnPrice"),
    //   disableSortBy: false,
    //   accessor: (row) => {
    //     const { services } = row;

    //     const servicesTotal = services.reduce((acc, service) => {
    //       return acc + service.price;
    //     }, 0);

    //     // console.log("servicesTotal", servicesTotal);

    //     return (
    //       <Text
    //         fontSize="sm"
    //         color={textPrimary}
    //         fontWeight="500"
    //         whiteSpace="nowrap"
    //       >
    //         {servicesTotal} €
    //       </Text>
    //     );
    //   },
    // },
    // {
    //   Header: "a",
    //   accessor: (row) => {
    //     const { services } = row;

    //     console.log("row", row);

    //     return (
    //       <Flex
    //         align="center"
    //         pr={0}
    //         borderRight={`1px solid ${newBorder}`}
    //       ></Flex>
    //     );
    //   },
    // },
    {
      Header: translate("patient.columnAction"),
      accessor: (row) => {
        const { status, _id } = row;

        const handleUpdateStatus = (status) => {
          updateTreatmentPlanStatusHandler(_id, status);
        };

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              isUnclickable
              isSmall
              currentStatus={status && status}
              onUpdateStatus={handleUpdateStatus}
              options="treatment"
            />

            {/* <Button
              onClick={handleApproveClick}
              size="sm"
              colorScheme="green"
              isDisabled={[
                "ongoing",
                "completed",
                "draft",
                "cancelled",
              ].includes(status)}
            >
              Approve
            </Button> */}
            {/* 
            <MoreButton
              isSmall
              id={_id}
              viewHandler={() => console.log("viewHandler")}
              viewLabel={translate("treatmentPlan.viewPatient")}
            /> */}
          </HStack>
        );
      },
    },
  ];
};
