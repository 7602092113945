import React, { useEffect } from "react";
import styled from "@emotion/styled";
import {
  Select,
  Box,
  HStack,
  Text,
  Flex,
  Button,
  Icon,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import {
  primary,
  white,
  textPrimary,
  textSecondary,
} from "../../../utils/colors";
import { useTranslation } from "react-i18next";

import { TbChevronsLeft, TbChevronLeft, TbChevronRight } from "react-icons/tb";

const TablePagination = ({
  loading,
  page,
  pages,
  pageSize,
  pageSizes,
  setPage,
  setPageSize,
  totalCount,
}) => {
  const { t } = useTranslation();

  const canPreviousPage = page > 1;

  const canNextPage = page < pages;

  const gotoPage = (page) => {
    setPage(page);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const goToLastPage = () => {
    gotoPage(pages);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="2rem 1.5rem"
      >
        <Box maxW="70%">
          <Flex alignItems="center">
            <Text color={textSecondary} fontSize="sm" mr={4}>
              {t("common.show")}
            </Text>
            <Select
              variant="filled"
              onChange={handlePageSizeChange}
              value={pageSize}
              size="md"
              borderRadius="10rem"
              fontSize="0.875rem"
              fontWeight="500"
              maxW="5rem"
            >
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
            <Flex
              fontSize="sm"
              color={textSecondary}
              ml={4}
              whiteSpace="nowrap"
            >
              {!loading ? (
                <Text as="span">
                  {t("common.itemsPerPage")} {t("common.from")}{" "}
                  {totalCount * pages}
                </Text>
              ) : (
                <Spinner color={primary} />
              )}
            </Flex>
          </Flex>
        </Box>
        <HStack spacing={2}>
          <IconButton
            w="40px"
            onClick={() => gotoPage(1)}
            isDisabled={!canPreviousPage}
            size="md"
            borderRadius="10rem"
          >
            <Icon
              as={TbChevronsLeft}
              color={textSecondary}
              transition="all 0.2s"
              _hover={{
                color: textPrimary,
                cursor: "pointer",
                transform: "scale(1.2)",
              }}
              style={{
                pointerEvents: canPreviousPage ? "auto" : "none",
              }}
            />
          </IconButton>

          {page !== 1 && (
            <IconButton
              w="40px"
              onClick={() => previousPage()}
              isDisabled={!canPreviousPage}
              size="md"
              borderRadius="10rem"
            >
              <Icon
                as={TbChevronLeft}
                color={textSecondary}
                transition="all 0.2s"
                _hover={{
                  color: textPrimary,
                  cursor: "pointer",
                  transform: "scale(1.2)",
                }}
                style={{
                  pointerEvents: canPreviousPage ? "auto" : "none",
                }}
              />
            </IconButton>
          )}

          {page - 1 > 0 && (
            <Buttonas
              onClick={() => gotoPage(page - 1)}
              isDisabled={!canPreviousPage}
              size="md"
              borderRadius="10rem"
              color={textSecondary}
            >
              <Text fontSize="0.875rem">{page - 1}</Text>
            </Buttonas>
          )}
          <Buttonas
            onClick={() => gotoPage(page)}
            className="active"
            size="md"
            borderRadius="10rem"
            color={textSecondary}
          >
            <Text fontSize="0.875rem">{page}</Text>
          </Buttonas>
          {page < pages && (
            <Buttonas
              onClick={() => gotoPage(page + 1)}
              isDisabled={!canNextPage}
              size="md"
              borderRadius="10rem"
              color={textSecondary}
            >
              <Text fontSize="0.875rem">{page + 1}</Text>
            </Buttonas>
          )}

          <IconButton
            w="40px"
            onClick={() => nextPage()}
            isDisabled={!canNextPage}
            size="md"
            borderRadius="10rem"
          >
            <Icon
              as={TbChevronRight}
              color={textSecondary}
              transition="all 0.2s"
              _hover={{
                color: textPrimary,
                cursor: "pointer",
                transform: "scale(1.2)",
              }}
              style={{
                pointerEvents: canNextPage ? "auto" : "none",
              }}
            />
          </IconButton>

          {page !== pages && (
            <Buttonas
              onClick={() => goToLastPage()}
              isDisabled={!canNextPage}
              size="md"
              borderRadius="10rem"
              color={textSecondary}
            >
              <Text fontSize="0.875rem">{pages}</Text>
            </Buttonas>
          )}
        </HStack>
      </Flex>
    </>
  );
};

const Buttonas = styled(Button)`
  width: 40px;

  &.active {
    background: ${primary};
    color: ${white};
  }
`;

export default TablePagination;
