import React, { useEffect, useState } from "react";
import { FormControl, Box, Spinner, Text, Flex, Icon } from "@chakra-ui/react";
import Select from "react-select";
import {
  white,
  newBorder,
  textPrimary,
  textSecondary,
  primary,
  backgroundLight,
  secondary,
  buttonGreen,
  buttonGreenText,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  listUsersEmployees,
  listUsersPatients,
} from "../../actions/userActions";
import dayjs from "dayjs";
import StatusBadge from "../../components/badges/StatusBadge";
import { tint } from "polished";
import { TbUser } from "react-icons/tb";

const FormControlCoordinator = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(400);
  const pageSizes = [4, 10, 25, 100];

  const [sortField, setSortField] = useState("lastName");
  const [sortOrder, setSortOrder] = useState("asc");

  const userEmployeesList = useSelector((state) => state.userEmployeesList);
  const { loading, error, employees, pages } = userEmployeesList;

  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");

  const [loggedInUser, setLoggedInUser] = useState({});

  const normalizeText = (text) => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const onChangeSearchTitle = (e) => {
    const searchTitle = normalizeText(e.target.value);
    setSearchTitle(searchTitle);
  };

  useEffect(() => {
    dispatch(
      listUsersEmployees(searchTitle, page, pageSize, sortField, sortOrder)
    );
  }, [dispatch]);

  useEffect(() => {
    if (employees && employees.length > 0) {
      const activeEmployees = employees.filter(
        (employee) => employee.status === "active"
      );

      const transformedPatients = activeEmployees.map((group) => ({
        value: group._id,
        firstName: group.firstName,
        lastName: group.lastName,
        birthDate: group.birthDate,
        status: group.status,
        profession: group.profession,
      }));
      setEmployeeOptions(transformedPatients);
    }
  }, [employees]);

  // console.log("user id in userinfo", userInfo._id);
  // console.log("value", value);

  useEffect(() => {
    if (userInfo._id) {
      setLoggedInUser(userInfo._id);
    }
  }, [userInfo]);

  // console.log("employees:", employees);
  // console.log("employeeOptions:", employeeOptions);

  // Custom rendering for options
  const formatOptionLabel = ({
    value: optionValue,
    firstName,
    lastName,
    profession,
    status,
  }) => (
    <Flex align="center" justify="space-between" w="100%">
      <Flex align="center">
        {loggedInUser === optionValue && (
          <Flex
            align="center"
            justify="center"
            borderRadius="10rem"
            bg={primary}
            mr={1}
            w="1.5rem"
            h="1.5rem"
          >
            <Icon as={TbUser} fontSize="0.75rem" />
          </Flex>
        )}

        <Text fontSize="md" fontWeight="500">
          {lastName}{" "}
          <Text as="span" fontWeight="400">
            {firstName}
          </Text>
        </Text>
        {/* <Text ml={2} fontSize="sm" color={textSecondary}>
          {dayjs(birthDate).format("YYYY-MM-DD")}
        </Text> */}
      </Flex>
      {/* <Text ml={1} fontSize="xs">
        - {profession.name}
      </Text> */}

      {profession && (
        <Box borderRadius="10rem" bg={backgroundLight} p="0.25rem 0.5rem">
          <Text fontSize="xs" color={textPrimary} fontWeight="500">
            {profession.name}
          </Text>
        </Box>
      )}
      {/* <StatusBadge isSmall options="user" status={status} ml={2} /> */}
    </Flex>
  );

  if (error) return <p>Error: {error}</p>;
  if (loading)
    return (
      <Box>
        <Spinner size="lg" color={primary} />
      </Box>
    );

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      {/* <FormLabel fontSize="sm" position="relative">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel> */}

      <Select
        isSearchable
        value={employeeOptions.find((option) => option.value === value)}
        onChange={onChange}
        options={employeeOptions}
        placeholder={t("placeholder.selectCoordinator")}
        formatOptionLabel={formatOptionLabel}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            color: textSecondary,
            borderRadius: "1rem",
            backgroundColor: `${white}`,
            border: `1px solid ${newBorder}`,
            fontSize: "0.938rem",
            fontWeight: "500",
            height: "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
            ":hover": {
              cursor: "pointer",
            },
          }),
          option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : textPrimary, // Color of text in options
            backgroundColor: state.isSelected ? primary : white, // Background color of selected option
            ":hover": {
              backgroundColor: state.isSelected ? primary : tint(0.3, primary), // Background color when hovering
              color: "white",
              cursor: "pointer",
            },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            const color =
              state.data.value === "" ? "desired color" : "default color";

            return { ...provided, opacity, transition, color };
          },
        }}
        noOptionsMessage={() => t("placeholder.noOptions")}
      />
    </FormControl>
  );
};

export default FormControlCoordinator;
