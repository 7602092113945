import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
} from "@chakra-ui/react";
import Select from "react-select";
import { newBorder, white, primary } from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getAllAddressStates } from "../../actions/userActions";
import { textPrimary, textSecondary } from "../../utils/colors";

const FormControlAddressState = ({
  isMulti,
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
}) => {
  //TRANSLATION
  const { t } = useTranslation();

  // CONSTANTS

  // REDUX
  const dispatch = useDispatch();
  const userAddressStates = useSelector((state) => state.userAddressStates);
  const { loading, error, addressStates } = userAddressStates;

  useEffect(() => {
    // Dispatch the action with fetchAll set to true
    dispatch(getAllAddressStates());
  }, [dispatch]);

  // Convert fetched states into format for react-select
  const stateOptions = addressStates.map((state) => ({
    value: state,
    label: state,
  }));

  // Map each value to its corresponding option object
  const selectedOption = Array.isArray(value)
    ? value.map((val) => stateOptions.find((opt) => opt.value === val))
    : [];

  // Custom styles for react-select
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: isError ? "red" : state.isFocused ? newBorder : newBorder,
      borderRadius: "1rem",
      backgroundColor: white,
      border: `1px solid ${newBorder}`,
      fontSize: isSmall ? "0.875rem" : "0.938rem",
      fontWeight: "500",
      minHeight: "2.5rem",
      height: isMulti ? "initial" : isSmall ? "2.5rem" : "3rem",
      padding: "0 0rem",
      ":focus-visible": {
        borderColor: primary,
        boxShadow: `0 0 0 1px ${newBorder}`,
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      color: textPrimary,
      ":hover": {
        bg: "red",
      },
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? primary : null,
      color: state.isFocused ? white : state.data.color,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: primary,
      color: white,
      borderRadius: "0.75rem",
      fontSize: "0.938rem",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: state.data.color,
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      ":hover": {
        backgroundColor: state.data.bgDark,
        color: state.data.color,
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontSize: "11px",
      color: textSecondary,
      fontWeight: "500",
    }),
  };

  // console.log("addressStates", addressStates);

  // console.log("stateOptions", stateOptions);

  // Loading and error handling can be implemented as needed
  if (loading) return <Spinner color={primary} size="lg" />;
  if (error) return <div>Error: {error}</div>;

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize={isSmall ? "xs" : "lg"} position="relative">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>
      <Select
        isMulti
        isSearchable
        value={selectedOption}
        onChange={onChange}
        options={stateOptions}
        placeholder={t("placeholder.selectAddressState")}
        styles={customStyles}
      />
    </FormControl>
  );
};

export default FormControlAddressState;
