import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createAttendee } from "../../../../actions/attendeeActions";
// import { listClinics } from "../../../../actions/clinicActions";
import { backgroundLight } from "../../../../utils/colors";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Image,
  Tooltip,
  HStack,
  Text,
  TabPanel,
  TabPanels,
  Heading,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  textPrimary,
} from "../../../../utils/colors";
import Select from "react-select";
import CitySelect from "../../../../components/select/CitySelect";
import ProfessionSelect from "../../../../components/select/ProfessionSelect";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import ImageUpload from "../../../../components/input/ImageUpload";
import MultiCourseSelect from "../../../../components/select/MultiCourseSelect";
import InputField from "../../../../components/input/InputField";
import { useTranslation } from "react-i18next";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlDatePicker from "../../../../components/input/FormControlDatePicker";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlAddress from "../../../../components/input/FormControlAddress";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import FormControlProfession from "../../../../components/input/FormControlProfession";
import FormControlClient from "../../../../components/input/FormControlClient";
import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";

import { shade } from "polished";

const CreateAttendee = ({ close }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tabsData = [
    { label: t("attendee.tabDetails") },
    // { label: t("attendee.tabCourses") },
    // { label: t("attendee.tabClinics") },
  ];

  const [activeTab, setActiveTab] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [profession, setProfession] = useState("");
  const [courseId, setCourseId] = useState("");
  const [client, setClient] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [street, setStreet] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  const [selectedProfession, setSelectedProfession] = useState(null);
  // const [isProfessionValid, setIsProfessionValid] = useState(false);

  let [description] = React.useState("");

  const [clinicOptions, setClinicOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);

  // const clinicList = useSelector((state) => state.clinicList);
  // const { clinics } = clinicList;

  const [courses, setCourses] = useState([]);
  const [customCourses, setCustomCourses] = useState([]);

  // TOUCHED
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [professionTouched, setProfessionTouched] = useState(false);

  // EMAIL VALIDATION
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // ERRORS
  const isFirstNameError = firstName === "";
  const isLastNameError = lastName === "";
  const isEmailError = email === "";
  const isProfessionError = profession === "";

  // VALIDATION
  const isFirstNameValid = firstName.length >= 1;
  const isLastNameValid = lastName.length >= 1;
  const isEmailValid = isValidEmail(email);
  const isProfessionValid = !!selectedProfession;

  // Determine if the Continue button should be disabled
  const isContinueDisabled =
    !isFirstNameValid ||
    !isLastNameValid ||
    !isEmailValid ||
    !isProfessionValid;

  // SUBMIT

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      createAttendee(
        firstName,
        lastName,
        // image,
        phone,
        email,
        birthDate,
        client,
        courses,
        street,
        zip,
        city,
        country,
        state,
        profession
      )
    );
  };

  // HANDLERS

  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  const handleProfessionChange = (selectedOption) => {
    setSelectedProfession(selectedOption.value);
    setProfession(selectedOption.value);
  };

  const customCoursesHandle = (values) => {
    setCustomCourses(values);
    setCourses(
      values.map((course) => {
        return course.value;
      })
    );
  };

  const formatOptionLabel = ({ label, value, image }) => (
    <Flex alignItems="center" p="0.5rem 0" m="0">
      {image && (
        <Box w="20px" h="20px" borderRadius="10rem" overflow="hidden">
          <Image
            w="100%"
            h="100%"
            objectFit="cover"
            src={image}
            alt={label}
            flexShrink="0"
          />
        </Box>
      )}
      <Text pl={2} fontSize="15px">
        {label}
      </Text>
    </Flex>
  );

  // Use this useEffect to dispatch listSpeakers only when the component mounts
  // useEffect(() => {
  //   dispatch(listClinics());
  // }, [dispatch]);

  // Use this useEffect to update the speakerOptions when the speakers change
  // useEffect(() => {
  //   if (clinics && clinics.length > 0) {
  //     const options = clinics.map((s) => ({
  //       value: s._id,
  //       label: s.name,
  //       image: s.image, // Assuming image URL is stored in s.image
  //     }));
  //     setClinicOptions([...options]);
  //   }

  //   if (courses && courses.length > 0) {
  //     const options = courses.map((s) => ({
  //       value: s._id,
  //       label: s.name,
  //       image: s.image, // Assuming image URL is stored in s.image
  //     }));
  //     setCourseOptions([{ value: "", label: "Select course" }, ...options]);
  //   }
  // }, [clinics, courses]);

  console.log("profession", profession);
  console.log("selectedProfession", selectedProfession);

  console.log("client", client);

  console.log("phone", phone);

  return (
    <Box position="relative">
      <form
        onSubmit={submitHandler}
        style={{
          height: "100%",
        }}
      >
        <SidePanelHeader close={close} title={t("attendee.createAttendee")} />

        <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
          <Flex
            direction="column"
            w="100%"
            align="flex-start"
            justify="center"
            p="7rem 0 4rem"
          >
            <Heading
              fontSize="1.125rem"
              color={textPrimary}
              fontWeight="600"
              m="1rem 0"
            >
              {t("attendee.details")}
            </Heading>

            <Flex
              direction="column"
              w="100%"
              bg={backgroundLight}
              borderRadius="1rem"
              p="1rem 1.5rem"
            >
              {/* <FormControl mb={4}>
              <FormLabel fontSize="sm">{t('attendee.attendeeImage')}</FormLabel>
              <ImageUpload image={image} setImage={setImage} />
            </FormControl> */}

              <HStack gap={4} w="100%">
                <FormControlDefault
                  type="text"
                  isRequired
                  label={t("form.firstName")}
                  value={firstName}
                  placeholder={t("placeholder.firstName")}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setFirstNameTouched(true);
                  }}
                  onBlur={() => setFirstNameTouched(true)}
                  isTouched={firstNameTouched}
                  isError={isFirstNameError}
                  w="50%"
                  errorMessage={t("formError.firstNameRequired")}
                />

                <FormControlDefault
                  type="text"
                  isRequired
                  label={t("form.lastName")}
                  value={lastName}
                  placeholder={t("placeholder.lastName")}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setLastNameTouched(true);
                  }}
                  onBlur={() => setLastNameTouched(true)}
                  isTouched={lastNameTouched}
                  isError={isLastNameError}
                  w="50%"
                  errorMessage={t("formError.lastNameRequired")}
                />
              </HStack>

              <FormControlDefault
                type="email"
                label={t("form.email")}
                isRequired
                value={email}
                placeholder={t("placeholder.email")}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailTouched(true);
                }}
                onBlur={() => setEmailTouched(true)}
                isTouched={emailTouched}
                isError={!isEmailValid}
                w="100%"
                errorMessage={t("formError.emailInvalid")}
              />
              <FormControlProfession
                label={t("form.profession")}
                value={profession}
                placeholder={t("select.profession")}
                onChange={handleProfessionChange}
                setIsTouched={() => setProfessionTouched(true)}
                isRequired={true}
                isTouched={professionTouched}
                isError={isProfessionError}
                errorMessage={t("formError.professionRequired")}
                w="100%"
                onProfessionChange={handleProfessionChange}
              />

              <HStack gap={4} w="100%">
                <FormControlDatePicker
                  type="date"
                  isRequired={false}
                  label={t("form.birthDate")}
                  placeholder={t("placeholder.birthDate")}
                  onChange={(newValue) => {
                    setBirthDate(newValue);
                  }}
                  onOpen={() => {}}
                  w="50%"
                  errorMessage={t("formError.birthDateRequired")}
                />

                <FormControlPhone
                  label={t("form.phone")}
                  value={phone}
                  onChange={(newPhone) => {
                    setPhone(newPhone);
                  }}
                  errorMessage={t("formError.phoneRequired")}
                  w="50%"
                  defaultCountry="lt"
                />
              </HStack>

              <HStack gap={4} w="100%">
                <FormControlAddress
                  label={t("form.address")}
                  onAddressSelect={handleAddressSelect}
                  isRequired={false}
                  errorMessage={t("formError.addressRequired")}
                  w="100%"
                  googleApiKey="AIzaSyCNQfHnntkLgj5Es5V8V0H2BfIetOOZ9yw"
                />

                <FormControlDefault
                  type="text"
                  isRequired={false}
                  label={t("form.zip")}
                  value={zip}
                  placeholder={t("placeholder.zip")}
                  onChange={(e) => {
                    setZip(e.target.value);
                  }}
                  w="40%"
                  errorMessage={t("formError.zipRequired")}
                />
              </HStack>

              <HStack gap={4} w="100%">
                <FormControlDefault
                  type="text"
                  isRequired={false}
                  label={t("form.city")}
                  value={city}
                  placeholder={t("placeholder.city")}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  w="50%"
                  errorMessage={t("formError.cityRequired")}
                />

                <FormControlCountry
                  label={t("form.country")}
                  value={country}
                  onChange={(selectedOption) => {
                    setCountry(selectedOption ? selectedOption.value : "");
                  }}
                  isRequired={false}
                  w="50%"
                  errorMessage={t("formError.countryRequired")}
                />
              </HStack>

              {/* <FormControlClient
                value={client}
                label={t("attendee.client")}
                placeholder={t("select.client")}
                onChange={(selectedOption) => setClient(selectedOption.value)}
                isRequired={false}
                w="100%"
              /> */}

              {/* <FormControl mt={4}>
              <>
                <FormLabel fontSize="sm">
                  <Flex justify="space-between">
                    {t("speaker.addNewCourse")}
                  </Flex>
                </FormLabel>

                <Flex w="100%" mb={10} flexDirection="column">
                  <Box w="100%">
                    <MultiCourseSelect
                      onChange={customCoursesHandle}
                      value={customCourses}
                      placeholderText={t("select.course")}
                      title={t("selected.courses")}
                    />
                  </Box>
                </Flex>
              </>
            </FormControl> */}

              {/*      
            <FormControl>
              <FormLabel fontSize="sm">Course</FormLabel>
              <Select
                isSearchable
                formatOptionLabel={formatOptionLabel} // Add this line
                value={courseOptions.find(
                  (option) => option.value === courseId
                )}
                onChange={(selectedOption) => setCourseId(selectedOption.value)}
                options={courseOptions}
                placeholder="Select Course"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? newBorder : newBorder,
                    color: textSecondary,
                    borderRadius: "1rem",
                    backgroundColor: `${white}`,
                    border: `1px solid ${newBorder}`,
                    fontSize: "0.938rem",
                    fontWeight: "500",
                    height: "3rem",
                    padding: "0 0.25rem",
                  }),
                  singleValue: (provided, state) => {
                    const opacity = state.isDisabled ? 0.5 : 1;
                    const transition = "opacity 300ms";

                    // check if the value is null (placeholder)
                    const color =
                      state.data.value === ""
                        ? "desired color"
                        : "default color";

                    return { ...provided, opacity, transition, color };
                  },
                }}
              />
            </FormControl>
        */}
            </Flex>
          </Flex>

          <Flex
            mt={4}
            direction="column"
            borderTop={`1px solid ${newBorder}`}
            p="2rem 0 3rem"
          >
            <Flex justify="flex-end">
              <Tooltip
                bg={white}
                borderRadius="1rem"
                p="1rem"
                label={
                  <Flex
                    direction="column"
                    p="0rem"
                    justify="center"
                    textAlign="center"
                  >
                    {isFirstNameError && (
                      <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                        * {t("formError.firstNameRequired")}
                      </Text>
                    )}
                    {isLastNameError && (
                      <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                        * {t("formError.lastNameRequired")}
                      </Text>
                    )}
                    {!isEmailValid && (
                      <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                        * {t("formError.emailInvalid")}
                      </Text>
                    )}
                    {isProfessionError && (
                      <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                        * {t("formError.professionRequired")}
                      </Text>
                    )}
                  </Flex>
                }
                isDisabled={!isContinueDisabled}
                hasArrow
                placement="top"
                fontSize="md"
              >
                <Button
                  minWidth="10rem"
                  type="submit"
                  size="lg"
                  bg={primary}
                  color={white}
                  fontSize="md"
                  borderRadius="10rem"
                  isDisabled={isContinueDisabled}
                  _hover={{
                    bg: shade(0.1, primary),
                  }}
                  ml={4}
                >
                  {t("attendee.createAttendee")}
                </Button>
              </Tooltip>

              <Flex align="center">
                {activeTab > 0 && (
                  <Button
                    minWidth="8rem"
                    mr={0}
                    bg={backgroundLight}
                    border={`1px solid ${newBorder}`}
                    size="lg"
                    color={textSecondary}
                    fontSize="md"
                    fontWeight="500"
                    borderRadius="10rem"
                    onClick={() => setActiveTab(activeTab - 1)}
                    isDisabled={isContinueDisabled}
                    _hover={{
                      bg: shade(0.1, backgroundLight),
                    }}
                  >
                    {t("common.back")}
                  </Button>
                )}

                {activeTab < 0 && (
                  <Tooltip
                    bg={white}
                    borderRadius="1rem"
                    p="1rem"
                    label={
                      <Flex
                        direction="column"
                        p="0rem"
                        justify="center"
                        textAlign="center"
                      >
                        {isFirstNameError && (
                          <Text
                            fontSize="13px"
                            color="#e53e3e"
                            whiteSpace="nowrap"
                          >
                            * {t("formError.firstNameRequired")}
                          </Text>
                        )}
                        {isLastNameError && (
                          <Text
                            fontSize="13px"
                            color="#e53e3e"
                            whiteSpace="nowrap"
                          >
                            * {t("formError.lastNameRequired")}
                          </Text>
                        )}
                        {!isEmailValid && (
                          <Text
                            fontSize="13px"
                            color="#e53e3e"
                            whiteSpace="nowrap"
                          >
                            * {t("formError.emailInvalid")}
                          </Text>
                        )}
                        {isProfessionError && (
                          <Text
                            fontSize="13px"
                            color="#e53e3e"
                            whiteSpace="nowrap"
                          >
                            * {t("formError.professionRequired")}
                          </Text>
                        )}
                      </Flex>
                    }
                    isDisabled={!isContinueDisabled}
                    hasArrow
                    placement="top"
                    fontSize="md"
                  >
                    <Button
                      minWidth="8rem"
                      ml={4}
                      bg={backgroundLight}
                      border={`1px solid ${newBorder}`}
                      size="lg"
                      color={textSecondary}
                      fontSize="md"
                      fontWeight="500"
                      borderRadius="10rem"
                      onClick={() => setActiveTab(activeTab + 1)}
                      isDisabled={isContinueDisabled}
                      _hover={{
                        bg: shade(0.1, backgroundLight),
                      }}
                    >
                      {t("common.continue")}
                    </Button>
                  </Tooltip>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </form>
    </Box>
  );
};

export default CreateAttendee;
