import {
  SPEAKER_LIST_REQUEST,
  SPEAKER_LIST_SUCCESS,
  SPEAKER_LIST_FAIL,
  SPEAKER_DETAILS_REQUEST,
  SPEAKER_DETAILS_SUCCESS,
  SPEAKER_DETAILS_FAIL,
  SPEAKER_DETAILS_RESET,
  SPEAKER_UPDATE_REQUEST,
  SPEAKER_UPDATE_SUCCESS,
  SPEAKER_UPDATE_FAIL,
  SPEAKER_UPDATE_RESET,
  SPEAKER_CREATE_REQUEST,
  SPEAKER_CREATE_SUCCESS,
  SPEAKER_CREATE_FAIL,
  SPEAKER_CREATE_RESET,
  SPEAKER_DELETE_REQUEST,
  SPEAKER_DELETE_SUCCESS,
  SPEAKER_DELETE_FAIL,
  SPEAKER_DELETE_RESET,
  SPEAKER_ADD_COURSE_REQUEST,
  SPEAKER_ADD_COURSE_SUCCESS,
  SPEAKER_ADD_COURSE_FAIL,
  SPEAKER_ADD_COURSE_RESET,
  SPEAKER_REMOVE_COURSE_REQUEST,
  SPEAKER_REMOVE_COURSE_SUCCESS,
  SPEAKER_REMOVE_COURSE_FAIL,
  SPEAKER_REMOVE_COURSE_RESET,
  SPEAKER_STATUS_UPDATE_REQUEST,
  SPEAKER_STATUS_UPDATE_SUCCESS,
  SPEAKER_STATUS_UPDATE_FAIL,
  SPEAKER_STATUS_UPDATE_RESET,
  SPEAKER_DESCRIPTION_UPDATE_REQUEST,
  SPEAKER_DESCRIPTION_UPDATE_SUCCESS,
  SPEAKER_DESCRIPTION_UPDATE_FAIL,
  SPEAKER_DESCRIPTION_UPDATE_RESET,
  SPEAKER_COURSE_LIST_REQUEST,
  SPEAKER_COURSE_LIST_SUCCESS,
  SPEAKER_COURSE_LIST_FAIL,
  SPEAKER_IMAGE_UPDATE_REQUEST,
  SPEAKER_IMAGE_UPDATE_SUCCESS,
  SPEAKER_IMAGE_UPDATE_FAIL,
  SPEAKER_IMAGE_UPDATE_RESET,
} from "../constants/speakerConstants";

export const speakerListReducer = (state = { speakers: [] }, action) => {
  switch (action.type) {
    case SPEAKER_LIST_REQUEST:
      return { loading: true, speakers: [] };
    case SPEAKER_LIST_SUCCESS:
      return {
        loading: false,
        speakers: action.payload.speakers,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case SPEAKER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const speakerDetailsReducer = (state = { speaker: {} }, action) => {
  switch (action.type) {
    case SPEAKER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case SPEAKER_DETAILS_SUCCESS:
      return { loading: false, speaker: action.payload };
    case SPEAKER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case SPEAKER_DETAILS_RESET:
      return { speaker: {} };
    default:
      return state;
  }
};

export const speakerUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SPEAKER_UPDATE_REQUEST:
      return { loading: true };
    case SPEAKER_UPDATE_SUCCESS:
      return { loading: false, success: true, speaker: action.payload };
    case SPEAKER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SPEAKER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const speakerCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SPEAKER_CREATE_REQUEST:
      return { loading: true };
    case SPEAKER_CREATE_SUCCESS:
      return { loading: false, success: true, speaker: action.payload };
    case SPEAKER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case SPEAKER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const speakerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SPEAKER_DELETE_REQUEST:
      return { loading: true };
    case SPEAKER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SPEAKER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case SPEAKER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const speakerAddCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case SPEAKER_ADD_COURSE_REQUEST:
      return { loading: true };
    case SPEAKER_ADD_COURSE_SUCCESS:
      return { loading: false, success: true };
    case SPEAKER_ADD_COURSE_FAIL:
      return { loading: false, error: action.payload, course: action.payload };
    case SPEAKER_ADD_COURSE_RESET:
      return {};
    default:
      return state;
  }
};

export const speakerRemoveCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case SPEAKER_REMOVE_COURSE_REQUEST:
      return { loading: true };
    case SPEAKER_REMOVE_COURSE_SUCCESS:
      return { loading: false, success: true };
    case SPEAKER_REMOVE_COURSE_FAIL:
      return { loading: false, error: action.payload };
    case SPEAKER_REMOVE_COURSE_RESET:
      return {};
    default:
      return state;
  }
};

export const speakerStatusUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SPEAKER_STATUS_UPDATE_REQUEST:
      return { loading: true };
    case SPEAKER_STATUS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case SPEAKER_STATUS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SPEAKER_STATUS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const speakerDescriptionUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SPEAKER_DESCRIPTION_UPDATE_REQUEST:
      return { loading: true };
    case SPEAKER_DESCRIPTION_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case SPEAKER_DESCRIPTION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SPEAKER_DESCRIPTION_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const speakerCoursesListReducer = (state = { courses: [] }, action) => {
  switch (action.type) {
    case SPEAKER_COURSE_LIST_REQUEST:
      return { loading: true, courses: [] };
    case SPEAKER_COURSE_LIST_SUCCESS:
      return { loading: false, courses: action.payload };
    case SPEAKER_COURSE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const speakerImageUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SPEAKER_IMAGE_UPDATE_REQUEST:
      return { loading: true };
    case SPEAKER_IMAGE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case SPEAKER_IMAGE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SPEAKER_IMAGE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
