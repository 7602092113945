import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from "@chakra-ui/react";
import { textPrimary, newBorder } from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { forwardRef, useRef } from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";

export const PasswordInput = forwardRef((props, ref) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);
  const mergeRef = useMergeRefs(inputRef, ref);
  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };

  const { t } = useTranslation();

  return (
    <InputGroup align="center">
      <Input
        id="password"
        ref={mergeRef}
        name="password"
        type={isOpen ? "text" : "password"}
        placeholder={t("placeholder.password")}
        autoComplete="new-password"
        border={["none", "none", `1px solid ${newBorder}`]}
        fontSize="1rem"
        fontWeight="500"
        color={textPrimary}
        bg={["rgba(255, 255, 255, 0.6)", "none", "none"]}
        required
        size="lg"
        h="3.5rem"
        borderRadius={["10rem", "10rem", "1rem"]}
        {...props}
      />
      <InputRightElement h="3.5rem" w="3.5rem">
        <IconButton
          tabIndex={-1}
          variant="text"
          aria-label={isOpen ? "Mask password" : "Reveal password"}
          icon={isOpen ? <HiEyeOff /> : <HiEye />}
          onClick={onClickReveal}
          color={textPrimary}
        />
      </InputRightElement>
    </InputGroup>
  );
});

export default PasswordInput;
