import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Flex,
  Heading,
  Text,
  Image,
  useToast,
  Spinner,
  Stack,
  Button,
} from "@chakra-ui/react";
import { columns } from "../columns/PatientPlansPublicColumns";
import {
  newBorder,
  backgroundLight,
  textSecondary,
  textPrimary,
  white,
  primary,
  buttonAltPrimary,
  buttonAltGrey,
  buttonAltGreen,
  buttonAltRed,
  buttonAltYellow,
  buttonAltSuccess,
  buttonGreenDark,
  buttonGreenText,
  buttonRed,
  buttonRedDark,
  buttonRedText,
  buttonYellowDark,
  buttonYellow,
  buttonBlueDark,
  buttonGreen,
} from "../../../../utils/colors";
import TableContainerAlt from "../../../../components/table/TableContainerAlt";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import {
  listPublicPatientTreatmentPlanList,
  approveTreatmentPlanPatientPublic,
  cancelTreatmentPlanPatientPublic,
} from "../../../../actions/publicActions";
import logo from "../../../../assets/icons/logo.svg";
import VerificationInputs from "../../../../components/input/VerificationInputs";
import { debounce } from "lodash";
import TenantPublicInfo from "./partials/TenantPublicInfo";
import ViewPatientTreatmentPlanPublic from "../sidepanels/ViewPatientTreatmentPlanPublic";
import SidePanelLong from "../../../../components/sidepanel/SidePanelLong";
import { AnimatePresence } from "framer-motion";
import ToastComponent from "../../../../components/partials/ToastComponent";
import {
  PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_RESET,
  PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_RESET,
} from "../../../../constants/publicConstants";
import ConfirmApprovePublicModal from "../../../../components/modals/ConfirmApprovePublicModal";
import ConfirmCancelPublicModal from "../../../../components/modals/ConfirmCancelPublicModal";
import LanguageButton from "../../../../components/buttons/LanguageButton";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";
import useIsMobile from "../../../../hooks/useIsMobile";
import StatusButtonMobile from "../../../../components/buttons/StatusButtonMobile";
import { tint, shade } from "polished";
import {
  FaRegCircle,
  FaCircleCheck,
  FaClock,
  FaCircleXmark,
  FaCircleHalfStroke,
} from "react-icons/fa6";

const PatientPlansPublic = () => {
  // LANGUAGE
  const { i18n } = useTranslation();

  // ID
  const { tenantId, patientId } = useParams();

  // MOBILE
  const isMobile = useIsMobile();

  //GENERAL
  const { t } = useTranslation();
  const translate = (key) => t(key);

  const toast = useToast();
  const toastComponent = ToastComponent();

  // STATE

  const [isPinVerified, setIsPinVerified] = useState(false);

  const [treatmentPlans, setTreatmentPlans] = useState([]);
  const [patientPinCode, setPatientPinCode] = useState("");

  const [planId, setPlanId] = useState("");
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [isSidePanelLongOpen, setIsSidePanelLongOpen] = useState(false);

  const [reason, setReason] = useState("");
  const [reasonComment, setReasonComment] = useState("");
  const [hasApprovedPlan, setHasApprovedPlan] = useState(false);

  // LANGUAGE
  const storedLanguage = localStorage.getItem("language");
  const initialLanguage = storedLanguage || "lt"; // Default to 'en'
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);

  // Add new state for modal visibility and selected treatment plan ID
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedTreatmentPlan, setSelectedTreatmentPlan] = useState(null);

  const [selectedCancelTreatmentPlan, setSelectedCancelTreatmentPlan] =
    useState(null);

  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false);

  // REDUX
  const dispatch = useDispatch();
  const publicPatientTreatmentPlanList = useSelector(
    (state) => state.publicPatientTreatmentPlanList
  );
  const { loading, error, patientInfo } = publicPatientTreatmentPlanList;

  const publicPatientApproveTreatmentPlan = useSelector(
    (state) => state.publicPatientApproveTreatmentPlan
  );
  const {
    loading: loadingApprove,
    error: errorApprove,
    success: successApprove,
  } = publicPatientApproveTreatmentPlan;

  const publicPatientCancelTreatmentPlan = useSelector(
    (state) => state.publicPatientCancelTreatmentPlan
  );
  const {
    loading: loadingCancel,
    error: errorCancel,
    success: successCancel,
  } = publicPatientCancelTreatmentPlan;

  // VERIFICATION

  const verifyPinCode = (inputPinCode) => {
    console.log("Verifying PIN code", { inputPinCode, patientPinCode });
    if (inputPinCode === patientPinCode) {
      setIsPinVerified(true);
      sessionStorage.setItem("patientPinCode", inputPinCode); // Save the PIN code to sessionStorage
      toast({
        title: t("toasts.correctPinCode"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      console.log("Invalid PIN code, showing toast");

      toast({
        title: t("toasts.incorrectPinCode"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Wrap your verifyPinCode function with debounce
  const verifyPinCodeDebounced = debounce((inputPinCode) => {
    verifyPinCode(inputPinCode);
  }, 0); // Adjust the delay as needed

  // HANDLERS

  const openSidePanelLong = (id) => {
    setIsSidePanelLongOpen(true);
    setPlanId(id);
    window.document.body.style.overflow = "hidden";
  };

  const closeSidePanelLong = () => {
    setIsSidePanelLongOpen(false);
    setPlanId("");
    window.document.body.style.overflow = "auto";
  };

  const handleApprove = (treatmentPlan) => {
    console.log("Treatment Plan ID for approval:", treatmentPlan);
    // Set the selected treatment plan ID and open the confirmation modal
    setSelectedTreatmentPlan(treatmentPlan);
    setIsConfirmModalOpen(true);
  };

  const confirmApprove = () => {
    if (selectedTreatmentPlan) {
      dispatch(
        approveTreatmentPlanPatientPublic(
          tenantId,
          patientId,
          selectedTreatmentPlan._id // Assuming _id is the property name
        )
      );
      // Close the modal after confirming
      setIsConfirmModalOpen(false);
    }
  };

  const handleCancel = (treatmentPlan) => {
    console.log("Treatment Plan ID for cancellation:", treatmentPlan);
    // Set the selected treatment plan ID and open the confirmation modal
    setSelectedCancelTreatmentPlan(treatmentPlan);
    setIsConfirmCancelModalOpen(true);
  };

  // Close modal without action
  const closeModal = () => {
    setIsConfirmModalOpen(false);
    setSelectedTreatmentPlan(null);
  };

  const confirmCancel = () => {
    if (selectedCancelTreatmentPlan) {
      dispatch(
        cancelTreatmentPlanPatientPublic(
          tenantId,
          patientId,
          selectedCancelTreatmentPlan._id,
          reason,
          reasonComment
        )
      );
      // Close the modal after confirming
      setIsConfirmCancelModalOpen(false);

      console.log("I NEED TO LOOK AT THIS", selectedCancelTreatmentPlan._id);
    }
  };

  const closeCancelModal = () => {
    setIsConfirmCancelModalOpen(false);
    setSelectedCancelTreatmentPlan(null);
    setReason("");
    setReasonComment("");
  };

  // LANGUAGE HANDLERS
  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const initialLanguage = storedLanguage || "lt";
    setCurrentLanguage(initialLanguage);

    i18n.changeLanguage(initialLanguage);
    dayjs.locale(initialLanguage);
    // eslint-disable-next-line
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    dayjs.locale(lng);
    setCurrentLanguage(lng);
    console.log("Language changed to", lng);
  };

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    changeLanguage(selectedLanguage);

    console.log("handleChangeLanguage selectedLanguage", selectedLanguage);
  };

  const handleMenuClick = (language) => {
    changeLanguage(language);
    console.log("handleMenuClick change language", language);
  };

  // USE EFFECT

  useEffect(() => {
    dispatch(listPublicPatientTreatmentPlanList(tenantId, patientId));
  }, [dispatch, tenantId, patientId, successApprove, successCancel]);

  useEffect(() => {
    console.log("Tenant ID:", tenantId, "Patient ID:", patientId);
    const storedPinCode = sessionStorage.getItem("patientPinCode");
    if (storedPinCode) {
      console.log("Stored PIN Code Found:", storedPinCode);
      setPatientPinCode(storedPinCode);
      setIsPinVerified(true);
    } else {
      console.log("No Stored PIN Code Found");
      setIsPinVerified(false);
    }
  }, [tenantId, patientId]);

  useEffect(() => {
    if (patientInfo) {
      setPatientPinCode(patientInfo.pinCode);
      setTreatmentPlans(patientInfo.treatmentPlans);
    }
  }, [patientInfo, dispatch, tenantId, patientId]);

  useEffect(() => {
    if (successApprove) {
      toastComponent.showToast(t("toasts.approvedByPatient"), "success", 3000);
      dispatch({
        type: PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_RESET,
      });
      closeSidePanelLong();
    } else if (errorApprove) {
      toast({
        title: "Error",
        description: errorApprove,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else if (successCancel) {
      toastComponent.showToast(t("toasts.cancelledByPatient"), "success", 3000);
      dispatch({ type: PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_RESET });
      closeSidePanelLong();
    } else if (errorCancel) {
      toast({
        title: "Error",
        description: errorCancel,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [successApprove, errorApprove, successCancel, errorCancel]);

  useEffect(() => {
    if (patientInfo && patientInfo.treatmentPlans) {
      const approvedExists = patientInfo.treatmentPlans.some(
        (plan) => plan.status === "approved" || plan.status === "ongoing"
      );
      setHasApprovedPlan(approvedExists);
    }
  }, [patientInfo]);

  // // Example validation before passing data to `useTable`
  // if (!treatmentPlans || !Array.isArray(treatmentPlans)) {
  //   // Handle the case where treatmentPlans is not ready or not an array
  //   return (
  //     <Flex>
  //       <Spinner />
  //     </Flex>
  //   ); // or some other placeholder content
  // }

  // LOGS
  // console.log("selectedTreatmentPlan", selectedTreatmentPlan);
  // console.log("selectedCancelTreatmentPlan", selectedCancelTreatmentPlan);

  const options = [
    {
      value: "draft",
      label: translate("status.draft"),
      icon: FaRegCircle,
      bg: tint(0.6, buttonAltGrey),
      bgBorder: tint(0.3, buttonAltGrey),
      bgDark: textSecondary,
      color: textSecondary,
      borderColor: newBorder,
      bgHover: "#ddd",
    },
    {
      value: "approved",
      label: translate("status.treatmentPlanApproved"),
      icon: FaCircleCheck,
      bg: tint(0.6, buttonAltGreen),
      bgBorder: tint(0.3, buttonAltGreen),
      bgDark: buttonGreenDark,
      color: buttonGreenText,
      borderColor: buttonGreen,
      bgHover: "#9ebfc2",
    },

    {
      value: "consulting",
      label: translate("status.waitingForApprovalPublic"),
      icon: FaClock,
      bg: tint(0.6, buttonAltPrimary),
      bgBorder: tint(0.3, buttonAltPrimary),
      bgDark: buttonBlueDark,
      color: textPrimary,
      borderColor: newBorder,
      bgHover: shade(0.8, buttonAltPrimary),
    },
    {
      value: "planning",
      label: translate("status.waitingForApprovalPublic"),
      icon: FaClock,
      bg: tint(0.6, buttonAltPrimary),
      bgBorder: tint(0.3, buttonAltPrimary),
      bgDark: buttonYellowDark,
      color: textPrimary,
      borderColor: buttonYellow,
      bgHover: "#9ebfc2",
    },
    {
      value: "completed",
      label: translate("status.treatmentPlanCompleted"),
      icon: FaCircleCheck,
      bg: tint(0.6, buttonAltSuccess),
      bgBorder: tint(0.3, buttonAltSuccess),
      bgDark: buttonGreenDark,
      color: buttonGreenText,
      borderColor: buttonGreen,
      bgHover: "#9ebfc2",
    },
    {
      value: "cancelled",
      label: translate("status.treatmentPlanCancelled"),
      icon: FaCircleXmark,
      bg: tint(0.6, buttonAltRed),
      bgBorder: tint(0.3, buttonAltRed),
      bgDark: buttonRedDark,
      color: buttonRedText,
      borderColor: buttonRed,
      bgHover: "#dda3a3",
    },
    {
      value: "ongoing",
      label: translate("status.ongoing"),
      icon: FaCircleHalfStroke,
      bg: tint(0.6, buttonAltYellow),
      bgBorder: tint(0.3, buttonAltYellow),
      bgDark: buttonYellowDark,
      color: textPrimary,
      borderColor: buttonAltYellow,
      bgHover: "#9ebfc2",
    },
  ];

  console.log("Treatment Plans:", treatmentPlans);

  // Function to get style based on status
  const getStatusStyle = (status) => {
    const statusOption = options.find((option) => option.value === status);
    return statusOption ? statusOption.bg : tint(0.6, buttonAltPrimary); // default background if status not found
  };

  // Function to get style based on status
  const getStatusStyleBorder = (status) => {
    const statusOption = options.find((option) => option.value === status);
    return statusOption ? statusOption.bgBorder : tint(0.3, buttonAltPrimary); // default background if status not found
  };

  return (
    <>
      <Flex
        w="100%"
        h={["100%", "100%", "100%"]}
        minH={["100vh", "100%", "100%"]}
        bg={white}
        overflow="hidden"
        position={["fixed", "relative", "relative"]}
        direction={["column", "row", "row"]}
      >
        <AnimatePresence mode="wait">
          {isSidePanelLongOpen && (
            <SidePanelLong close={closeSidePanelLong}>
              <ViewPatientTreatmentPlanPublic
                tenantId={tenantId}
                patientId={patientId}
                planId={planId}
                close={closeSidePanelLong}
                handleApprove={handleApprove}
                hasApprovedPlan={hasApprovedPlan}
                handleCancel={handleCancel}
              />
            </SidePanelLong>
          )}
        </AnimatePresence>

        <ConfirmApprovePublicModal
          isOpen={isConfirmModalOpen}
          onClose={closeModal}
          confirmAction={confirmApprove}
          treatmentPlanDetails={selectedTreatmentPlan}
        />

        <ConfirmCancelPublicModal
          isOpen={isConfirmCancelModalOpen}
          onClose={closeCancelModal}
          confirmAction={confirmCancel}
          treatmentPlanDetails={selectedCancelTreatmentPlan}
          setReason={setReason}
          setReasonComment={setReasonComment}
          reason={reason}
          reasonComment={reasonComment}
        />

        <Flex
          w="100%"
          position={["fixed", "absolute", "absolute"]}
          top="0"
          left={["0%", "50%", "50%"]}
          h={["66px", "80px", "80px"]}
          bg={white}
          align="center"
          justify={["space-between", "center", "center"]}
          maxW={["100%", "1200px", "1200px"]}
          m={["0 auto 0", "0 auto", "0 auto"]}
          p={["0 1.5rem", "0", "0"]}
          transform={["translateX(0)", "translateX(-50%)", "translateX(-50%)"]}
        >
          <Image src={logo} h={["1.1rem", "1.36rem", "1.36rem"]} w="auto" />
          <Flex
            align="center"
            position={["relative", "absolute", "absolute"]}
            right="0px"
          >
            <LanguageButton
              currentLanguage={currentLanguage}
              languages={["en", "lt", "lv", "pl"]}
              onChangeLanguage={handleChangeLanguage}
              onMenuClick={handleMenuClick}
            />
          </Flex>
        </Flex>

        <Flex
          bg={backgroundLight}
          w="100%"
          maxW="1200px"
          m={["66px auto 0", "80px auto", "80px auto"]}
          borderRadius={["0rem", "2.5rem", "2.5rem"]}
          p={["2rem 1rem 12rem", "4rem 0 4rem", "4rem 0 4rem"]}
          align="center"
          direction="column"
          overflowX="hidden"
        >
          <Heading
            fontSize="1.25rem"
            color={textPrimary}
            fontWeight="600"
            textAlign="center"
            p="0 0 1.5rem"
          >
            {t("patient.publicPlansTitle")}
          </Heading>
          <Flex
            bg={white}
            maxW={["100%", "800px", "800px"]}
            w="100%"
            p={["1.5rem 1.5rem", "2rem", "2rem"]}
            borderRadius="2rem"
            direction="column"
            m="0 auto"
          >
            <TenantPublicInfo tenantID={tenantId} />
            <Flex align="center" m="1.5rem 0 0">
              <Text fontSize="15px" color={textSecondary} fontWeight="500">
                {t("patient.patient")}:
                <Text as="span" color={textPrimary} fontWeight="600" ml={1}>
                  {patientInfo?.firstName} {patientInfo?.lastName}
                </Text>
              </Text>
            </Flex>

            {isPinVerified ? (
              <Flex w="100%" direction="column" m="1.5rem 0 0">
                {!isMobile && (
                  <Heading
                    fontSize="1.25rem"
                    color={textPrimary}
                    fontWeight="600"
                    p="0 0 0rem"
                    textAlign="center"
                  >
                    {t("patient.treatmentPlans")}
                  </Heading>
                )}

                <Flex
                  w="100%"
                  direction="column"
                  p={["0rem 0 0rem", "1rem 0 4rem", "1rem 0 4rem"]}
                  bg={white}
                  borderRadius="0rem"
                >
                  {!isMobile ? (
                    <Flex
                      bg={white}
                      w="100%"
                      p="0 0rem 0rem"
                      border={`1px solid ${newBorder}`}
                      borderRadius="1rem"
                      overflow="hidden"
                    >
                      <TableContainerAlt
                        columns={columns(
                          dispatch,
                          translate,
                          approveTreatmentPlanPatientPublic,
                          handleApprove,
                          handleCancel,
                          hasApprovedPlan,
                          openSidePanelLong
                        )}
                        data={
                          treatmentPlans && treatmentPlans.length > 0
                            ? treatmentPlans
                            : []
                        }
                        loading={loading}
                        error={error}
                        isSinglePage={false}
                        // onRowClick={(row) => {
                        //   openSidePanelModalHandler();
                        //   setPlanId(row._id);
                        // }}
                        onRowClick={(row) => {
                          openSidePanelLong(row._id);
                        }}
                        noItemsTitle={t("noItems.noPatientTreatmentPlans")}
                        noItemsDescription={t(
                          "patient.noPublicTreatmentPlansText"
                        )}
                        noItemsButtonLabel={t(
                          "noItems.noPatientTreatmentPlansButton"
                        )}
                        // noItemsButtonHandler={buttonHandler}
                      />
                    </Flex>
                  ) : (
                    <Flex direction="column" w="100%">
                      {treatmentPlans &&
                        treatmentPlans.length > 0 &&
                        treatmentPlans.map((plan) => (
                          <Flex
                            key={plan._id}
                            w="100%"
                            border={`1px solid ${getStatusStyleBorder(
                              plan.status
                            )}`}
                            direction="column"
                            borderRadius="1rem"
                            m="0 0 1rem"
                            p="1.25rem 1rem"
                            bg={getStatusStyle(plan.status)}
                            onClick={() => {
                              openSidePanelLong(plan._id);
                            }}
                          >
                            <Flex
                              w="100%"
                              align="center"
                              justify="space-between"
                            >
                              <Stack
                                gap={1}
                                justifyContent="flex-start"
                                textAlign="left"
                              >
                                <Text
                                  fontSize="15px"
                                  color={textPrimary}
                                  fontWeight="500"
                                >
                                  {t("treatmentPlan.treatmentPlan")} -{" "}
                                  <Text as="span" fontWeight="600">
                                    {plan.treatmentPlanID}
                                  </Text>
                                </Text>
                                <Text
                                  fontSize="15px"
                                  color={textSecondary}
                                  fontWeight="500"
                                  textTransform="capitalize"
                                >
                                  {dayjs(plan.createdAt).format(
                                    "MMMM DD, YYYY"
                                  )}
                                </Text>
                              </Stack>
                              <Stack
                                gap={0}
                                justifyContent="flex-end"
                                textAlign="right"
                              >
                                {/* <Text
                                  fontSize="15px"
                                  color={textPrimary}
                                  fontWeight="500"
                                >
                                  {t(`status.${plan.status}`)}
                                </Text> */}

                                <Text
                                  fontSize="15px"
                                  color={textPrimary}
                                  fontWeight="600"
                                  textTransform="capitalize"
                                >
                                  {plan.totalPrice} €
                                </Text>
                              </Stack>
                            </Flex>

                            <Flex align="center" w="100%" p="1rem 0 0">
                              <StatusButtonMobile
                                currentStatus={plan.status}
                                options="treatmentPublic"
                                isUnclickable
                              />
                            </Flex>

                            {/* <Button
                              m="1rem 0 0"
                              fontSize="15px"
                              color={textPrimary}
                              fontWeight="600"
                              cursor="pointer"
                              onClick={() => openSidePanelLong(plan._id)}
                              borderRadius="10rem"
                            >
                              {t("button.view")}
                            </Button> */}
                          </Flex>
                        ))}
                    </Flex>
                  )}
                </Flex>
              </Flex>
            ) : (
              <>
                <Flex w="100%" direction="column" align="center" m="2.5rem 0 0">
                  <Heading
                    fontSize="1.75rem"
                    color={textPrimary}
                    fontWeight="700"
                    m="0 0  0.5rem"
                  >
                    {t("patient.verifyPinCode")}
                  </Heading>
                  <Text
                    fontSize="15px"
                    color={textSecondary}
                    fontWeight="500"
                    m="0 auto 2rem"
                    maxW="300px"
                    textAlign="center"
                  >
                    {t("patient.enterPinCode")}
                    <Text as="span" color={primary} ml={1}>
                      {patientInfo?.email}
                    </Text>
                  </Text>
                  <Flex justify="center" align="center" maxW="300px" w="100%">
                    <VerificationInputs
                      length={4}
                      onComplete={verifyPinCodeDebounced}
                    />
                  </Flex>
                </Flex>
              </>
            )}
            <Flex
              justify="center"
              align="center"
              w="100%"
              borderTop={`1px solid ${newBorder}`}
              m="1rem 0 0"
              p="2rem 0 2rem"
              direction="column"
            >
              <Text
                fontSize="sm"
                color={textSecondary}
                fontWeight="500"
                textAlign="center"
                maxW="600px"
                m="0 auto"
                lineHeight="1.5"
              >
                {isPinVerified
                  ? t("patient.approveInstructions")
                  : t("patient.verifyBottomText")}
              </Text>
              <Text
                fontSize="sm"
                color={textPrimary}
                fontWeight="500"
                m="1rem 0 0"
              >
                {t("patient.thankYou")}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default PatientPlansPublic;
