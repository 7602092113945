import React, { useState, useEffect, useRef } from "react";
import {
  Flex,
  Heading,
  Text,
  Image,
  Button,
  Avatar,
  Box,
  Circle,
  Input,
  Spinner,
  InputGroup,
  Textarea,
  InputRightElement,
} from "@chakra-ui/react";
import {
  addNoteToUser,
  listNotesOfUser,
} from "../../../../../actions/userActions";
import {
  textPrimary,
  textSecondary,
  backgroundLight,
  white,
  primary,
  secondary,
  newBorder,
  buttonBlue,
  buttonBlueText,
} from "../../../../../utils/colors";
import AppointmentCard from "../../../../../components/cards/AppointmentCard";
import noServices from "../../../../../assets/images/empty-box.png";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import lt from "dayjs/locale/lt";
import en from "dayjs/locale/en";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import { useDispatch, useSelector } from "react-redux";
import useOnclickOutside from "react-cool-onclickoutside";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

// Define custom relative time strings
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few sec",
    m: "1min",
    mm: "%dmin",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1m",
    MM: "%dm",
    y: "1y",
    yy: "%dy",
  },
});

// Define custom relative time strings
dayjs.updateLocale("lt", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few sec",
    m: "prieš 1min",
    mm: "prieš %dmin.",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1m",
    MM: "%dm",
    y: "1y",
    yy: "%dy",
  },
});

const PatientNotes = ({ userId }) => {
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language);

  // Ref for the suggestions box
  const ref = useOnclickOutside(() => {
    setAddingNote(false);
  });

  const textAreaRef = useRef();
  const notesEndRef = useRef(null);
  const [noteText, setNoteText] = useState("");
  const dispatch = useDispatch();

  const [addingNote, setAddingNote] = useState(false);

  // FUNCTIONS

  //   useOnclickOutside(textAreaRef, () => {
  //     setAddingNote(false);
  //   });

  // Reference for the scrollable container
  const scrollableContainerRef = useRef(null);

  // Scroll to the bottom of the notes container
  const scrollToBottom = () => {
    const scrollableContainer = scrollableContainerRef.current;
    if (scrollableContainer) {
      scrollableContainer.scrollTop = scrollableContainer.scrollHeight;
    }
  };

  // REDUX

  const userListNotes = useSelector((state) => state.userListNotes);
  const { notes, loading, error } = userListNotes;

  const userAddNote = useSelector((state) => state.userAddNote);
  const { success: successAddNote } = userAddNote;

  // USE EFFECT

  // Fetch the notes
  useEffect(() => {
    // console.log("userId", userId);

    if (successAddNote) {
      dispatch(listNotesOfUser(userId));
    } else {
      dispatch(listNotesOfUser(userId));
    }
  }, [dispatch, userId, successAddNote]);

  // Scroll to the bottom whenever the notes update
  useEffect(() => {
    scrollToBottom();
  }, [notes, addingNote]);

  const handleAddNote = () => {
    if (noteText.trim()) {
      dispatch(addNoteToUser(userId, noteText.trim()));
      setNoteText(""); // Clear the input field
    }
  };

  // LOGS
  // console.log("notes", notes);
  // console.log("loading", loading);
  // console.log("error", error);
  // console.log("addingNote", addingNote);

  return (
    <>
      <Flex direction="column" w="100%" mb={8}>
        <Heading
          fontSize="1.125rem"
          color={textPrimary}
          fontWeight="600"
          m={0}
          p="0 0 0.75rem"
        >
          {t("patient.notes")}
        </Heading>

        <Box>
          {notes && notes.length > 0 ? (
            <>
              <Flex
                w="100%"
                bg={backgroundLight}
                direction="column"
                p="1rem"
                borderRadius="1rem"
                maxH="280px" // Set a maximum height
                ref={scrollableContainerRef}
                overflowY="auto" // Enable scrolling
                position="relative"
              >
                <Flex
                  w="100%"
                  bg="none"
                  direction="column"
                  p="0rem"
                  borderRadius="1rem"
                >
                  {notes.map((note) => (
                    <Flex
                      key={note._id}
                      w="100%"
                      bg={white}
                      direction="column"
                      p="1rem"
                      borderRadius="1rem"
                      mb={4}
                    >
                      <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        w="100%"
                        p="0rem 0 0rem"
                      >
                        <Flex align="center" justify="space-between" w="100%">
                          <Flex align="center">
                            <Circle
                              justify="center"
                              align="center"
                              size="22px"
                              bg={backgroundLight}
                            >
                              <Text
                                color={textSecondary}
                                fontWeight="600"
                                fontSize="xs"
                              >
                                {note.author.firstName[0] +
                                  note.author.lastName[0]}
                              </Text>
                            </Circle>

                            <Text
                              color={textSecondary}
                              fontWeight="500"
                              fontSize="xs"
                              ml={2}
                            >
                              {note.author.firstName +
                                " " +
                                note.author.lastName[0] +
                                "."}
                            </Text>
                          </Flex>
                          <Text
                            color={textSecondary}
                            fontWeight="500"
                            fontSize="xs"
                          >
                            {dayjs().from(dayjs(note.createdAt), true)}
                          </Text>
                        </Flex>
                        <Flex
                          w="100%"
                          p="0.75rem 0 0"
                          borderBottom={`0px solid ${newBorder}`}
                        >
                          <Text
                            color={textPrimary}
                            fontWeight="400"
                            fontSize="13px"
                            noOfLines={2}
                          >
                            {note.text}
                          </Text>
                        </Flex>
                      </Flex>
                      <div ref={notesEndRef} />
                    </Flex>
                  ))}
                </Flex>
                {/* New Note Input Field */}
                <Flex
                  p="0rem"
                  align="center"
                  justify="space-between"
                  w="100%"
                  position="relative"
                  bottom="0"
                >
                  <InputGroup size="md">
                    <Textarea
                      ref={ref}
                      onClick={() => setAddingNote(true)}
                      bg={white}
                      color={textPrimary}
                      value={noteText}
                      onChange={(e) => setNoteText(e.target.value)}
                      placeholder={t("patient.notesCreate")}
                      size="md"
                      fontSize="md"
                      resize="none" // This prevents manual resizing, remove if you want users to resize
                      minH={addingNote ? "5rem" : "3rem"} // Minimum height before typing
                      maxH="5rem" // Maximum height after typing
                      borderRadius={addingNote ? "1.5rem" : "10rem"}
                      lineHeight={1.8}
                      _focus={{
                        boxShadow: "0 0 0 2px " + primary,
                      }}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        w="initial"
                        ml={2}
                        mt={addingNote ? "2.25rem" : "0.5rem"}
                        mr={2}
                        bg={primary}
                        color={white}
                        fontWeight="600"
                        onClick={handleAddNote}
                        borderRadius="10.75rem"
                        p="0rem 2rem"
                        fontSize="sm"
                        _hover={{
                          bg: secondary,
                        }}
                      >
                        {t("common.comment")}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex
              w="100%"
              bg={backgroundLight}
              direction="column"
              p="1rem"
              borderRadius="1rem"
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                w="100%"
                p="1rem 0"
                bg={white}
                borderRadius="1rem"
              >
                <Image mt="2rem" mb="1rem" h="auto" w="3rem" src={noServices} />
                <Text color={textSecondary} fontWeight="400" mb="1rem">
                  {t("patient.notesNone")}
                </Text>
                <Flex
                  p="1rem 1.5rem 0"
                  align="center"
                  justify="space-between"
                  w="100%"
                  position="relative"
                  bottom="0"
                >
                  <InputGroup size="md">
                    <Textarea
                      ref={ref}
                      onClick={() => setAddingNote(true)}
                      bg={white}
                      fontSize="md"
                      color={textPrimary}
                      value={noteText}
                      onChange={(e) => setNoteText(e.target.value)}
                      placeholder={t("patient.notesCreate")}
                      size="md"
                      resize="none" // This prevents manual resizing, remove if you want users to resize
                      minH={addingNote ? "5rem" : "3rem"} // Minimum height before typing
                      maxH="5rem" // Maximum height after typing
                      borderRadius={addingNote ? "1.5rem" : "10rem"}
                      lineHeight={1.8}
                      _focus={{
                        boxShadow: "0 0 0 2px " + primary,
                      }}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        w="initial"
                        ml={2}
                        mt={addingNote ? "2.25rem" : "0.5rem"}
                        mr={2}
                        bg={primary}
                        color={white}
                        fontWeight="600"
                        onClick={handleAddNote}
                        borderRadius="10.75rem"
                        p="0rem 2rem"
                        fontSize="sm"
                        _hover={{
                          bg: secondary,
                        }}
                      >
                        {t("common.comment")}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Flex>
              </Flex>
            </Flex>
          )}
        </Box>
      </Flex>
    </>
  );
};

export default PatientNotes;
