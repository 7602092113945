import React, { useState, useEffect } from "react";
import {
  Flex,
  Heading,
  Select,
  Text,
  Button,
  Grid,
  GridItem,
  Box,
  Spinner,
  Stack,
  Avatar,
  Icon,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  backgroundLight,
  newBorder,
  textPrimary,
  textSecondary,
  primary,
  white,
} from "../../../../utils/colors";
import BoxUser from "./BoxUser";
import BoxTreatmentPlan from "./BoxTreatmentPlan";
import {
  getCoordinatorStatistics,
  getCoordinatorUserStatistics,
} from "../../../../actions/statisticActions";
import useCountUp from "../../../../hooks/useCountUp";
import { FaCaretDown } from "react-icons/fa6";
import Table from "../../../../components/shared/Table";
import { columns } from "../columns/DashboardCoordinatorListColumns"; // Adjust the path according to your file structure
import useRoleCheck from "../../../../hooks/useRoleCheck";
import dayjs from "dayjs";

const DashboardTreatmentCoordinator = ({ title }) => {
  // TRANSLATION
  const { t } = useTranslation();
  const translate = (key) => t(key);

  //ROLES
  const isDoctor = useRoleCheck("employee_doctor");
  const isAdmin = useRoleCheck("admin");

  const currentMonth = dayjs().format("MMMM YYYY");

  // REDUX
  const dispatch = useDispatch();

  const statisticCoordinator = useSelector(
    (state) => state.statisticCoordinator
  );
  const { loading, error, statisticCoordinators } = statisticCoordinator;

  const statisticCoordinatorUser = useSelector(
    (state) => state.statisticCoordinatorUser
  );
  const {
    loading: loadingCoordinatorUser,
    error: errorCoordinatorUser,
    statisticCoordinatorsUser,
  } = statisticCoordinatorUser;

  // CONSTANTS

  const [coordinators, setCoordinators] = useState([]);

  // USE EFFECT

  useEffect(() => {
    if (!isAdmin) {
      dispatch(getCoordinatorUserStatistics());
    } else {
      dispatch(getCoordinatorStatistics());
    }
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (statisticCoordinators && statisticCoordinators.length > 0) {
      setCoordinators(statisticCoordinators);
    } else if (
      statisticCoordinatorsUser &&
      statisticCoordinatorsUser.length > 0
    ) {
      setCoordinators(statisticCoordinatorsUser);
    }
  }, [dispatch, statisticCoordinators, statisticCoordinatorsUser]);
  // // Check if the data is still loading or if there's an error
  if (loading) return <Spinner size="md" color={primary} />;
  if (error) return <Text>Error: {error}</Text>;

  // // LOGS
  console.log("statisticCoordinatorsUser:", statisticCoordinatorsUser);
  console.log("statisticCoordinators:", statisticCoordinators);

  return (
    <Flex
      w="100%"
      direction="column"
      p="0rem 1.5rem"
      border={`1px solid ${newBorder}`}
      borderRadius="1rem"
      mb={8}
    >
      <Flex w="100%" justify="space-between" align="center" py={4}>
        <Heading size="sm" fontWeight="600" color={textPrimary}>
          {!isAdmin
            ? t("dashboard.treatmentCoordinatorsDoctor")
            : t("dashboard.treatmentCoordinators")}
          <Text
            as="span"
            ml={2}
            fontSize="sm"
            color={textSecondary}
            fontWeight="500"
            textTransform="capitalize"
          >
            - {currentMonth}
          </Text>
        </Heading>
        <Button
          size="md"
          borderRadius="10rem"
          border={`1px solid ${newBorder}`}
          bg={white}
          color={textSecondary}
          fontWeight="500"
          fontSize="sm"
          isDisabled
        >
          {t("common.filter")}
          <Icon as={FaCaretDown} ml={1} h="0.75rem" w="0.6rem" />
        </Button>
      </Flex>

      <Table.Container
        columns={columns(dispatch, translate, isAdmin)}
        data={coordinators}
        loading={loading}
        error={error}
        // navigate={navigate}
        // navigateTo="/treatment-plans"
        // noItemsButtonHandler={openSidePanelModal}
        onRowClick={(planId) => {
          console.log("planId", planId);
        }}
        noItemsTitle={t("noItems.noTreatmentPlans")}
        noItemsDescription={t("noItems.noTreatmentPlansText")}
        noItemsButtonLabel={t("noItems.noTreatmentPlansButton")}
        // sortField={sortField}
        // sortOrder={sortOrder}
        isHeaderWhite
        className="narrow"
        noItemsAlt
      />

      <Flex w="100%" pt={4} pb={8} justify="space-between"></Flex>
    </Flex>
  );
};

export default DashboardTreatmentCoordinator;
