import React, { useState, useEffect } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  Input,
  Flex,
  useToast,
  Text,
  Box,
  Heading,
  PopoverHeader,
  IconButton,
  PopoverBody,
  PopoverFooter,
  Icon,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import {
  textSecondary,
  textPrimary,
  newBorder,
  white,
  primary,
  buttonBlue,
  buttonRed,
  buttonRedText,
  backgroundLight,
} from "../../../../../utils/colors";
import { FaXmark, FaCaretDown } from "react-icons/fa6";
import dayjs from "dayjs"; // import dayjs
import { useTranslation } from "react-i18next";
import ToastComponent from "../../../../../components/partials/ToastComponent";
import { FaPlus, FaMinus } from "react-icons/fa6";

const QuantityPopover = ({
  totalPrice,
  initialQty,
  service,
  saveEditedService,
  isDisabled,
}) => {
  // CONSTANTS
  const [qty, setQty] = useState(initialQty || "");
  const [tempQty, setTempQty] = useState(qty);
  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();
  const [isCaretUp, setIsCaretUp] = useState(false);

  // TOAST
  const toastComponent = ToastComponent();

  // TRANSLATION
  const { t } = useTranslation();

  // USE EFFECT
  useEffect(() => {
    setQty(initialQty);
    setTempQty(initialQty);
  }, [initialQty]);

  // HANDLERS
  const openPopover = () => {
    setIsOpen(true);
    setTempQty(tempQty); // Set the temporary date to the current date when opening the popover
  };

  const closePopover = () => {
    setIsOpen(false);
    setTempQty(tempQty); // Reset the temporary date back to the actual date when closing without saving
  };

  const handleClose = () => {
    closePopover();
  };

  const incrementQty = () => {
    setTempQty((prevQty) => (prevQty ? prevQty + 1 : 1));
  };

  const decrementQty = () => {
    setTempQty((prevQty) => (prevQty && prevQty > 1 ? prevQty - 1 : 1));
  };

  const saveQuantity = () => {
    if (tempQty && tempQty > 0) {
      // check if the date is valid using dayjs
      setQty(tempQty);
      saveEditedService({
        ...service,
        quantity: tempQty,
        totalPrice: tempQty * service.price,
      });

      setIsOpen(false);
      toastComponent.showToast(t("toasts.quantityUpdate"), "success", 3000);
    } else {
      toastComponent.showToast(t("toasts.quantityUpdateFail"), "danger", 3000);
    }
  };

  // console.log("date", date);

  console.log("isDisabled", isDisabled);

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={closePopover}
      placement="right"
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <Button
          onClick={service.priceVariations === false ? null : openPopover}
          zIndex="0"
          borderRadius="10rem"
          bg={isOpen ? backgroundLight : white}
          alignItems="center"
          justifyContent="space-between"
          px={qty ? 4 : 4}
          transition="0.2s all"
          size="sm"
          role="group"
          w="90px"
          textAlign="left"
          _hover={{
            bg: backgroundLight,
            px: 4,
            transition: "0.2s all",
          }}
          isDisabled={isDisabled}
        >
          <Flex align="center" justify="flex-start" w="100%">
            <Text fontSize="xs" color={textSecondary} fontWeight="500">
              {t("service.quantity")}
            </Text>
            <Text
              fontSize="0.875rem"
              color={textSecondary}
              fontWeight="500"
              ml={1}
            >
              {qty}
            </Text>
            <Icon
              as={FaCaretDown}
              h="0.75rem"
              w={isOpen ? "0.75rem" : "0rem"}
              ml={1}
              opacity="0.5"
              transition="all 0.2s ease"
              color={textSecondary}
              transform={isOpen ? "rotate(180deg)" : "rotate(0)"} // Rotate caret when menu is open
              _groupHover={{
                opacity: 1,
                width: "0.75rem",
                transition: "all 0.2s ease",
              }}
            />
          </Flex>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        border="none"
        boxShadow="0px 4px 12px 3px rgba(0, 0, 0, 0.20)"
        borderRadius="1rem"
        bg={white}
      >
        <PopoverHeader borderBottom={`1px solid ${newBorder}`} p="1rem 1.5rem">
          <Flex w="100%" justify="space-between" align="center">
            <Heading fontSize="sm" fontWeight="500" color={textPrimary}>
              {t("button.setQuantity")}
            </Heading>
            <IconButton
              h="1.5rem"
              w="1rem"
              icon={<FaXmark />}
              bg="none"
              onClick={handleClose}
              color={textSecondary}
            />
          </Flex>
        </PopoverHeader>
        <PopoverBody p="1.5rem 1.5rem">
          <Flex align="center" justify="center">
            <IconButton
              aria-label="Decrement"
              icon={<FaMinus />}
              onClick={decrementQty}
              isDisabled={tempQty <= 1}
              bg={backgroundLight}
              size="sm"
              borderRadius="10rem"
              // Additional styling can be added here
            />
            <Input
              color={textSecondary}
              placeholder="Enter quantity"
              size="md"
              type="number"
              maxWidth="100px"
              value={tempQty}
              onChange={(e) => setTempQty(Number(e.target.value))}
              mx={2} // Adds margin to the left and right of the input
              textAlign="center" // Centers the text in the input
              borderRadius="10rem"
              // Additional styling can be added here
            />

            <IconButton
              aria-label="Increment"
              icon={<FaPlus />}
              onClick={incrementQty}
              bg={backgroundLight}
              size="sm"
              borderRadius="10rem"
              // Additional styling can be added here
            />
          </Flex>
        </PopoverBody>
        <PopoverFooter p="1rem 1.5rem">
          <Flex w="100%" align="center" justify="flex-end">
            <Button
              variant="outline"
              size="md"
              fontSize="sm"
              color={textSecondary}
              borderRadius="10rem"
              onClick={closePopover}
              p="1rem 1.5rem"
            >
              {t("button.cancel")}
            </Button>
            <Button
              size="md"
              fontSize="sm"
              bg={primary}
              color={white}
              borderRadius="10rem"
              onClick={saveQuantity} // 4. Call the handleConfirmClick function when the "Confirm" button is clicked
              p="1rem 1.5rem"
              ml={2}
            >
              {t("button.apply")}
            </Button>
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default QuantityPopover;
