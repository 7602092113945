import React, { useEffect, useState } from "react";
import { Flex, Box, Spinner, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetails,
  cancelAllTreatments,
} from "../../../../actions/userActions";
import { primary } from "../../../../utils/colors";
import ToastComponent from "../../../../components/partials/ToastComponent";
import { useTranslation } from "react-i18next";
import PatientHeader from "./partials/PatientHeader";
import PatientStatus from "./partials/PatientStatus";
import PatientDetails from "./partials/PatientDetails";
import PatientPlans from "./partials/PatientPlans";
import PatientAppointments from "./partials/PatientAppointments";
import PatientNotes from "./partials/PatientNotes";

import SidePanelTop from "../../../../components/sidepanel/SidePanelTop";
import CreatePatientTreatmentPlan from "../sidepanels/CreatePatientTreatmentPlan";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import SidePanelAlt from "../../../../components/sidepanel/SidePanelAlt";
import EditPatientTreatmentPlan from "../sidepanels/EditPatientTreatmentPlan";
import {
  TREATMENTPLAN_DELETE_RESET,
  TREATMENTPLAN_CREATE_RESET,
  TREATMENTPLAN_UPDATE_RESET,
  TREATMENTPLAN_APPROVE_RESET,
  TREATMENTPLAN_DETAILS_RESET,
  TREATMENTPLAN_CANCEL_RESET,
  TREATMENTPLAN_SEND_EMAIL_RESET,
  TREATMENTPLAN_UPDATE_STATUS_RESET,
  TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_RESET,
} from "../../../../constants/treatmentPlanConstants";
import {
  USER_PATIENTS_LIST_RESET,
  USER_SEND_TREATMENTPLAN_LIST_EMAIL_RESET,
} from "../../../../constants/userConstants";
import useRoleCheck from "../../../../hooks/useRoleCheck";
import { motion } from "framer-motion";
import PatientTreatmentPlansShare from "./partials/PatientTreatmentPlansShare";

const PatientSingleLong = ({
  userId,
  close,
  setUserId,
  openSidePanelModal,
  openEditSidePanelModal,
  userInfo,
  refreshPatientList,
}) => {
  // GENERAL
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const toast = useToast();
  const [loadingDisplay, setLoadingDisplay] = useState(true);

  // ROLES
  const isDoctor = useRoleCheck("employee_doctor");
  const isAdmin = useRoleCheck("admin");

  // CONSTANTS
  const [treatmentPlans, setTreatmentPlans] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [approvedTreatmentPlan, setApprovedTreatmentPlan] = useState({});
  const [status, setStatus] = useState("");

  const [sidePanelModalOpen, setSidePanelModalOpen] = useState(false);
  const [editPatientSidePanel, setEditPatientSidePanel] = useState(false);

  const filteredTreatmentPlans = treatmentPlans.filter((plan) => {
    // Assuming each treatment plan has an 'employee' field with 'firstName' and 'lastName'
    const employeeName = `${plan.employee.firstName} ${plan.employee.lastName}`;
    return employeeName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  //  SIDEPANELS
  const [
    createPatientTreatmentPlanSidePanel,
    setCreatePatientTreatmentPlanSidePanel,
  ] = useState(false);

  // REDUX
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { success } = userUpdate;

  const treatmentPlanUpdate = useSelector((state) => state.treatmentPlanUpdate);
  const { success: successTreatmentPlanUpdate } = treatmentPlanUpdate;

  const treatmentPlanCreate = useSelector((state) => state.treatmentPlanCreate);
  const { success: successTreatmentPlanCreate } = treatmentPlanCreate;

  const treatmentPlanDelete = useSelector((state) => state.treatmentPlanDelete);
  const { success: successTreatmentPlanDelete } = treatmentPlanDelete;

  const treatmentPlanUpdateStatus = useSelector(
    (state) => state.treatmentPlanUpdateStatus
  );
  const { success: successUpdateStatus } = treatmentPlanUpdateStatus;

  const treatmentPlanUpdateAvailablePublic = useSelector(
    (state) => state.treatmentPlanUpdateAvailablePublic
  );
  const { success: successUpdateAvailablePublic } =
    treatmentPlanUpdateAvailablePublic;

  const treatmentPlanApprove = useSelector(
    (state) => state.treatmentPlanApprove
  );
  const {
    loadingApprove,
    error: errorApprove,
    success: successApprove,
  } = treatmentPlanApprove;

  const treatmentPlanCancel = useSelector((state) => state.treatmentPlanCancel);
  const { success: successCancel } = treatmentPlanCancel;

  const userSendTreatmentPlanListEmail = useSelector(
    (state) => state.userSendTreatmentPlanListEmail
  );
  const {
    loading: loadingSendTreatmentPlanListEmail,
    error: errorSendTreatmentPlanListEmail,
    success: successSendTreatmentPlanListEmail,
  } = userSendTreatmentPlanListEmail;

  const userAddNote = useSelector((state) => state.userAddNote);
  const { success: successAddNote } = userAddNote;

  const userCancelAllTreatments = useSelector(
    (state) => state.userCancelAllTreatments
  );
  const { success: successCancelAllTreatments } = userCancelAllTreatments;

  const treatmentPlanSendEmail = useSelector(
    (state) => state.treatmentPlanSendEmail
  );
  const {
    loading: loadingSendEmail,
    error: errorSendEmail,
    success: successSendEmail,
  } = treatmentPlanSendEmail;

  // HANDLERS

  const openCreatePatientTreatmentPlanSidePanel = () => {
    console.log("here");
    setCreatePatientTreatmentPlanSidePanel(true);
    console.log(
      "createPatientTreatmentPlanSidePanel",
      createPatientTreatmentPlanSidePanel
    );
  };

  const closeCreatePatientTreatmentPlanSidePanel = () => {
    setCreatePatientTreatmentPlanSidePanel(false);
  };

  const openSidePanelModalHandler = () => {
    setSidePanelModalOpen(true);
  };

  const closeSidePanelModal = () => {
    setSidePanelModalOpen(false);
  };

  const deleteTreatmentPlan = () => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteTreatmentPlan());
      if (refreshPatientList) {
        refreshPatientList();
      }
    }
  };

  // Animation variants
  const headerVariants = {
    hidden: { opacity: 0, x: 0 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { type: "tween", stiffness: 100 },
    },
  };

  const fetchUserDetails = () => {
    dispatch(getUserDetails(userId));
  };

  // USEEFFECT
  useEffect(() => {
    if (!user || user._id !== userId) {
      fetchUserDetails();
      setTreatmentPlans([]);
      setStatus("");
    } else {
      setTreatmentPlans(user.treatmentPlans);
      setStatus(user.status);
      const approvedTreatmentPlan = user.treatmentPlans.find(
        (plan) => plan.status === "approved"
      );
      setApprovedTreatmentPlan(approvedTreatmentPlan);
    }
  }, [dispatch, userId, user]);

  useEffect(() => {
    if (successTreatmentPlanDelete) {
      toastComponent.showToast(
        t("toasts.treatmentPlanDelete"),
        "success",
        3000
      );
      dispatch({ type: TREATMENTPLAN_DELETE_RESET });
    } else if (successTreatmentPlanCreate) {
      toastComponent.showToast(
        t("toasts.treatmentPlanCreate"),
        "success",
        3000
      );
      dispatch({ type: TREATMENTPLAN_CREATE_RESET });
    } else if (successTreatmentPlanUpdate) {
      toastComponent.showToast(
        t("toasts.treatmentPlanUpdate"),
        "success",
        3000
      );
      dispatch({ type: TREATMENTPLAN_UPDATE_RESET });
    } else if (successUpdateStatus) {
      toastComponent.showToast(
        t("toasts.treatmentPlanUpdateStatus"),
        "success",
        3000
      );
      dispatch({ type: TREATMENTPLAN_UPDATE_STATUS_RESET });
    } else if (successApprove) {
      toastComponent.showToast(
        t("toasts.treatmentPlanApprove"),
        "success",
        3000
      );
      dispatch({ type: TREATMENTPLAN_APPROVE_RESET });
    } else if (successCancel) {
      toastComponent.showToast(
        t("toasts.treatmentPlanCancel"),
        "success",
        3000
      );
      dispatch({ type: TREATMENTPLAN_CANCEL_RESET });
    } else if (successSendEmail) {
      toastComponent.showToast(
        t("toasts.treatmentPlanSendEmail"),
        "success",
        3000
      );
      dispatch({ type: TREATMENTPLAN_SEND_EMAIL_RESET });
    } else if (successUpdateAvailablePublic) {
      // toastComponent.showToast(
      //   t("toasts.treatmentPlanUpdateAvailablePublic"),
      //   "success",
      //   3000,
      //   t("toasts.treatmentPlanUpdateAvailablePublicDescription")
      // );
      dispatch({ type: TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_RESET });
    } else if (successSendTreatmentPlanListEmail) {
      toastComponent.showToast(
        t("toasts.patientSendTreatmentPlanList"),
        "success",
        3000
      );
      dispatch({ type: USER_SEND_TREATMENTPLAN_LIST_EMAIL_RESET });
    }

    fetchUserDetails();
  }, [
    successTreatmentPlanDelete,
    successTreatmentPlanCreate,
    successTreatmentPlanUpdate,
    successUpdateStatus,
    successApprove,
    successCancel,
    successSendEmail,
    successUpdateAvailablePublic,
    successSendTreatmentPlanListEmail,
    dispatch,
  ]);

  useEffect(() => {
    if (errorApprove) {
      toast({
        title: t("toasts.treatmentPlanApproveError"),
        description: errorApprove,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      dispatch({ type: TREATMENTPLAN_APPROVE_RESET });
    } else if (errorSendEmail) {
      toastComponent.showToast(
        t("toasts.treatmentPlanSendEmailError"),
        "error",
        3000
      );
      dispatch({ type: TREATMENTPLAN_SEND_EMAIL_RESET });
    } else if (errorSendTreatmentPlanListEmail) {
      toastComponent.showToast(
        t("toasts.patientSendTreatmentPlanListError"),
        "error",
        3000
      );
      dispatch({ type: USER_SEND_TREATMENTPLAN_LIST_EMAIL_RESET });
    }
  }, [errorApprove, errorSendEmail, errorSendTreatmentPlanListEmail, dispatch]);

  // New useEffect hook
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoadingDisplay(false);
    }, 500); // CHANGE THIS TO HAVE A LONGER LOADING TIME

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeout);
  }, []);

  // LOGS
  // console.log("user", user);
  // console.log("userId", userId);
  // console.log("user status", status);

  // console.log("errorApprove", errorApprove);
  // console.log("successApprove", successApprove);

  return (
    <>
      <SidePanel
        isTop
        isLong
        open={createPatientTreatmentPlanSidePanel}
        close={closeCreatePatientTreatmentPlanSidePanel}
        title={t("patient.createPatientTreatmentPlan")}
        noBorder={true}
      >
        <CreatePatientTreatmentPlan
          close={closeCreatePatientTreatmentPlanSidePanel}
          currentPatient={user}
          currentUser={userInfo}
        />
      </SidePanel>

      {loadingDisplay || loading || loadingSendTreatmentPlanListEmail ? (
        <Flex justify="center" align="center" h="100%">
          <Spinner color={primary} />
        </Flex>
      ) : (
        <>
          <Box
            w="100%"
            h="100%"
            p="0"
            as={motion.div}
            initial="hidden"
            animate="visible"
            variants={headerVariants}
          >
            {user && user.treatmentPlans && (
              <Flex direction="column" w="100%" h="100%" overflowY="scroll">
                <PatientHeader
                  user={user}
                  close={close}
                  cancelAllTreatments={cancelAllTreatments}
                  isDoctor={isDoctor}
                />

                <Flex
                  w="100%"
                  justify="space-between"
                  p={[
                    "105px 1.5rem 4rem 1.5rem",
                    "105px 2rem 4rem 2rem",
                    "105px 2rem 4rem 2rem",
                  ]}
                  direction={["column", "row", "row"]}
                >
                  <Flex
                    w={["100%", "calc(100% - 350px)", "calc(100% - 350px)"]}
                    direction="column"
                    p={[
                      "2rem 0rem 0 0rem",
                      "2rem 2rem 0 0rem",
                      "2rem 2rem 0 0rem",
                    ]}
                  >
                    <PatientStatus
                      user={user}
                      status={user.status}
                      plan={approvedTreatmentPlan}
                    />

                    <PatientDetails user={user} userId={userId} />

                    <PatientPlans
                      plans={filteredTreatmentPlans}
                      buttonHandler={openCreatePatientTreatmentPlanSidePanel}
                      userInfo={userInfo}
                      deleteTreatmentPlan={deleteTreatmentPlan}
                      dispatch={dispatch}
                      loading={loading}
                      successUpdateAvailablePublic={
                        successUpdateAvailablePublic
                      }
                    />
                  </Flex>

                  <Flex w="350px" direction="column" p="2rem 0rem 0 0rem">
                    <PatientTreatmentPlansShare user={user} />
                    <PatientNotes userId={userId} />
                    <PatientAppointments />
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default PatientSingleLong;
