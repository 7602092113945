import axios from "axios";
import {
  PUBLIC_PATIENT_TREATMENT_PLAN_LIST_REQUEST,
  PUBLIC_PATIENT_TREATMENT_PLAN_LIST_SUCCESS,
  PUBLIC_PATIENT_TREATMENT_PLAN_LIST_FAIL,
  PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_REQUEST,
  PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_SUCCESS,
  PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_FAIL,
  PUBLIC_TENANT_DETAILS_REQUEST,
  PUBLIC_TENANT_DETAILS_SUCCESS,
  PUBLIC_TENANT_DETAILS_FAIL,
  PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_REQUEST,
  PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_SUCCESS,
  PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_FAIL,
  PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_REQUEST,
  PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_SUCCESS,
  PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_FAIL,
  PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_REQUEST,
  PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_SUCCESS,
  PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_FAIL,
} from "../constants/publicConstants";

export const listPublicPatientTreatmentPlanList =
  (tenantId, userId) => async (dispatch) => {
    try {
      dispatch({ type: PUBLIC_PATIENT_TREATMENT_PLAN_LIST_REQUEST });

      const { data } = await axios.get(
        `/api/public/tenant/${tenantId}/users/${userId}/treatment-plans`
      );

      console.log(data);
      console.log("tenantId: ", tenantId);
      console.log("userId: ", userId);

      dispatch({
        type: PUBLIC_PATIENT_TREATMENT_PLAN_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PUBLIC_PATIENT_TREATMENT_PLAN_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listPublicPatientTreatmentPlanDetails =
  (tenantId, userId, treatmentPlanId) => async (dispatch) => {
    try {
      dispatch({ type: PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_REQUEST });

      const { data } = await axios.get(
        `/api/public/tenant/${tenantId}/users/${userId}/treatment-plans/${treatmentPlanId}`
      );

      dispatch({
        type: PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_SUCCESS,
        payload: data,
      });

      console.log(data);
    } catch (error) {
      dispatch({
        type: PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listPublicTenantDetails = (tenantId) => async (dispatch) => {
  try {
    dispatch({ type: PUBLIC_TENANT_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/public/tenant/${tenantId}`);

    dispatch({
      type: PUBLIC_TENANT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PUBLIC_TENANT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const approveTreatmentPlanPatientPublic =
  (tenantId, userId, treatmentPlanId) => async (dispatch) => {
    try {
      dispatch({ type: PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_REQUEST });

      const { data } = await axios.put(
        `/api/public/tenant/${tenantId}/users/${userId}/treatment-plans/${treatmentPlanId}/approve`
      );

      console.log(data);

      dispatch({
        type: PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const cancelTreatmentPlanPatientPublic =
  (tenantId, userId, treatmentPlanId, cancelledReason, cancelledComment) =>
  async (dispatch) => {
    try {
      dispatch({ type: PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_REQUEST });

      const { data } = await axios.put(
        `/api/public/tenant/${tenantId}/users/${userId}/treatment-plans/${treatmentPlanId}/cancel`,
        { cancelledReason, cancelledComment } // Send these in the request body
      );

      console.log(data);

      dispatch({
        type: PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const downloadTreatmentPlanPdfPublic =
  (tenantId, userId, treatmentPlanId) => async (dispatch) => {
    try {
      dispatch({
        type: PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_REQUEST,
      });

      const config = {
        responseType: "blob", // Important for handling binary data like PDF
      };

      const { data } = await axios.get(
        `/api/public/tenant/${tenantId}/users/${userId}/treatment-plans/${treatmentPlanId}/generate-download-pdf`,
        config // Pass the configuration object to the request
      );

      console.log("data: ", data);

      dispatch({
        type: PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_SUCCESS,
        payload: data,
      });

      console.log("data: ", data);

      // Code to trigger the browser to download the PDF file
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `treatment-plan-${treatmentPlanId}.pdf`); // or any other name you want
      document.body.appendChild(link);
      link.click();

      return { success: true, payload: data };
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_FAIL,
        payload: errorMessage,
      });

      // Return an object indicating failure and the error message
      return { success: false, error: errorMessage };
    }
  };
