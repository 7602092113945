import React from "react";
import Tabs from "../Tabs";
import { Flex } from "@chakra-ui/react";

const SidebarRouting = ({ menuItems }) => {
  return (
    <Flex flexGrow="1" w="100%" h="100%">
      <Tabs.Container tabs={menuItems} />
    </Flex>
  );
};

export default SidebarRouting;
