import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listServiceGroupDetails,
  updateServiceGroup,
} from "../../../../actions/serviceGroupActions";
import { backgroundLight } from "../../../../utils/colors";
import { Box, Heading, Button, Flex } from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  textPrimary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlSwitch from "../../../../components/input/FormControlSwitch";
import FormControlTreatmentStep from "../../../../components/input/FormControlTreatmentStep";
import { SERVICEGROUP_UPDATE_RESET } from "../../../../constants/serviceGroupConstants";

const EditServiceGroup = ({ close, serviceGroupId }) => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // CONSTANTS

  const [customId, setCustomId] = useState("");
  const [name, setName] = useState("");
  const [isConsulting, setIsConsulting] = useState(false);
  const [treatmentStep, setTreatmentStep] = useState("");
  const [status, setStatus] = useState("active");

  // REDUX
  const serviceGroupDetails = useSelector((state) => state.serviceGroupDetails);
  const { serviceGroup } = serviceGroupDetails;

  const serviceGroupUpdate = useSelector((state) => state.serviceGroupUpdate);
  const { success } = serviceGroupUpdate;

  // USEEFFECT
  useEffect(() => {
    if (serviceGroup && serviceGroup._id !== serviceGroupId) {
      dispatch(listServiceGroupDetails(serviceGroupId));
    } else {
      // setCustomId(serviceGroup.customId);
      setName(serviceGroup.name);
      setIsConsulting(serviceGroup.isConsulting);
      // setTreatmentStep(serviceGroup.treatmentStep);
      setStatus(serviceGroup.status);
    }
  }, [dispatch, serviceGroupId, serviceGroup]);

  // HANDLERS
  const closeHandler = () => {
    close();
    dispatch({ type: SERVICEGROUP_UPDATE_RESET });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateServiceGroup({
        _id: serviceGroupId,
        name: name,
        isConsulting: isConsulting,
      })
    );
  };

  // TOUCHED
  const [nameTouched, setNameTouched] = useState(false);
  const [treatmentStepTouched, setTreatmentStepTouched] = useState(false);

  // ERRORS
  const isNameError = name === "";
  const isTreatmentStepError = treatmentStep === "";

  // LOGS

  console.log("serviceGroup", serviceGroup);

  return (
    <Box position="relative">
      <form
        onSubmit={submitHandler}
        style={{
          height: "100%",
        }}
      >
        <SidePanelHeader
          close={close}
          title={t("serviceGroup.editServiceGroup")}
        />

        <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
          <Flex
            direction="column"
            w="100%"
            align="flex-start"
            justify="center"
            p="7rem 0 4rem"
          >
            <Heading
              fontSize="1.125rem"
              color={textPrimary}
              fontWeight="600"
              m="1rem 0"
            >
              {t("serviceGroup.details")}
            </Heading>

            <Flex
              direction="column"
              w="100%"
              bg={backgroundLight}
              borderRadius="1rem"
              p="1.5rem"
            >
              <FormControlDefault
                type="text"
                isRequired
                label={t("form.serviceGroupName")}
                value={name}
                placeholder={t("placeholder.serviceGroupName")}
                onChange={(e) => {
                  setName(e.target.value);
                  setNameTouched(true);
                }}
                isTouched={nameTouched}
                isError={isNameError}
                w="100%"
                errorMessage={t("formError.serviceGroupNameRequired")}
              />

              <FormControlSwitch
                label={t("form.isConsulting")}
                isChecked={isConsulting}
                onChange={(e) => {
                  setIsConsulting(e.target.checked);
                }}
                isTouched={true}
                w="100%"
              />

              {/* <FormControlTreatmentStep
                label={t("form.treatmentStep")}
                value={treatmentStep}
                onChange={(selectedOption) => {
                  setTreatmentStep(selectedOption.value);
                  setTreatmentStepTouched(true);
                }}
                isRequired={true}
                isTouched={treatmentStepTouched}
                isError={isTreatmentStepError}
                errorMessage={t("formError.treatmentStepRequired")}
                w="100%"
              /> */}
            </Flex>
          </Flex>

          <Flex
            mt={4}
            justify="center"
            direction="column"
            borderTop={`1px solid ${newBorder}`}
            p="1.5rem 0"
            position="relative"
            zIndex={0}
            bg={white}
            bottom="0"
            w="100%"
          >
            <Flex justify="flex-end">
              <Button
                minWidth="10rem"
                mr={4}
                bg={backgroundLight}
                size="lg"
                color={textSecondary}
                fontSize="md"
                borderRadius="10rem"
                onClick={closeHandler}
              >
                {t("common.cancel")}
              </Button>
              <Button
                minWidth="10rem"
                type="submit"
                size="lg"
                bg={primary}
                color={white}
                fontSize="md"
                borderRadius="10rem"
                // isDisabled={!firstName.trim() || !lastName.trim()}
              >
                {t("serviceGroup.editServiceGroup")}
              </Button>
            </Flex>
          </Flex>
        </Box>
      </form>
    </Box>
  );
};

export default EditServiceGroup;
