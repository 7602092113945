import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  listAppointments,
  deleteAppointment,
  createAppointment,
} from "../../../../actions/appointmentActions";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import { Box } from "@chakra-ui/react";
import ToastComponent from "../../../../components/partials/ToastComponent";
import { columns } from "../columns/AppointmentListColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import { APPOINTMENT_DELETE_RESET } from "../../../../constants/appointmentContstants";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import CreateAppointment from "../sidepanels/CreateAppointment";
import Calendar from "../partials/Calendar";
import SingleDayCalendar from "../partials/SingleDayCalendar";

const AppointmentList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  const [appointmentId, setAppointmentId] = useState("");

  const [searchTitle, setSearchTitle] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [4, 10, 25, 100];

  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);

  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  const appointmentList = useSelector((state) => state.appointmentList);
  const { loading, error, appointments, pages } = appointmentList;

  const appointmentCreate = useSelector((state) => state.appointmentCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    appointment: createdAppointment,
  } = appointmentCreate;

  const appointmentDelete = useSelector((state) => state.appointmentDelete);

  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = appointmentDelete;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }

    if (successCreate) {
      toastComponent.showToast(t("toasts.appointmentCreate"), "success", 3000);
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");

      dispatch(listAppointments(searchTitle, page, pageSize));
    } else {
      dispatch(listAppointments(searchTitle, page, pageSize));
    }
  }, [
    dispatch,
    navigate,
    searchTitle,
    page,
    pageSize,
    successCreate,
    successDelete,
  ]);

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    setAppointmentId("");
    document.body.classList.remove("no-scroll");
  };

  console.log("appointments", appointments);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.appointments")}</title>
        <meta name="description" content="Dentacasa Appointment List" />
      </Helmet>

      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("appointment.createAppointment")}
        noBorder={true}
      >
        <CreateAppointment
          initialDate={new Date(new Date().setDate(new Date().getDate() + 2))}
        />
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("appointment.appointments")}
                onChange={onChangeSearchTitle}
                placeholder={t("appointment.search")}
                searchName={searchTitle}
                buttonLabel={t("appointment.createAppointment")}
                buttonHandler={openSidePanelModal}
              ></Table.Top>
            </Box>

            <SingleDayCalendar appointments={appointments} />

            <Calendar appointments={appointments} />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default AppointmentList;
