import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TabPanels,
  TabPanel,
  HStack,
  Flex,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { getUserDetails, deleteUser } from "../../../../actions/userActions";
import Panel from "../../../../components/shared/Panel";
import { primary } from "../../../../utils/colors";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import StatusButton from "../../../../components/buttons/StatusButton";
import SinglePageTopBar from "../../../../components/layout/SinglePageTopBar";
import SidePanel from "../../../../components/sidepanel/SidePanel";
// import EditPatient from "../sidepanels/EditPatient";
import { USER_UPDATE_RESET } from "../../../../constants/userConstants";
import { useTranslation } from "react-i18next";
// import TabAttendeeCourses from "./tabs/TabAttendeeCourses";
// import AttendeeInfo from "./partials/AttendeeInfo";
// import AttendeeCharts from "./partials/AttendeeCharts";
import SinglePageImageWrapper from "../../../../components/partials/SinglePageImageWrapper";
import MoreButton from "../../../../components/buttons/MoreButton";
import SingleInfo from "../../../../components/partials/SingleInfo";

const PatientSingle = () => {
  const userId = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();

  const tabsData = [{ label: t("patient.tabTreatmentPlans") }];

  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [phone, setPhone] = useState("");
  const [profession, setProfession] = useState([]);
  const [status, setStatus] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [place, setPlace] = useState("");

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { success: successUpdate } = userUpdate;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  const deleteUserHandler = (id) => {
    dispatch(deleteUser(id));
    toast({
      title: t("patient.patientDeleted"),
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET });
      navigate("/patients/list");
    } else if (successDelete) {
      dispatch({ type: USER_UPDATE_RESET });
      navigate("/patients/list");
    } else {
      if (!user.firstName || user._id !== userId) {
        dispatch(getUserDetails(userId));
      } else {
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setImage(user.image);
        setEmail(user.email);
        setBirthDate(user.birthDate);
        setPhone(user.phone);
        setProfession(user.profession);
        setStatus(user.status);

        if (user?.address[0]?.city) {
          setCity(user?.address[0]?.city);
        }

        if (user?.address[0]?.street) {
          setPlace(
            user?.address[0]?.street + `, ` + user?.address[0]?.zip || ""
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, userId, user, successUpdate, successDelete]);

  const openEditSidePanel = () => {
    setEditSidePanelOpen(true);
  };

  const closeEditSidePanel = () => {
    setEditSidePanelOpen(false);
  };

  console.log("user", user);

  return (
    <>
      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanel}
        title={t("patient.editPatient")}
        noBorder={true}
      >
        {/* <EditPatient patientId={patientId} /> */}
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <SinglePageTopBar
              backFunction={() => navigate(-1)}
              title={firstName}
              type={t("patient.patient")}
              loading={loading}
            >
              <StatusButton
                currentStatus={status}
                // onUpdateStatus={handleUpdateStatus}
                options="active"
                isBig
              />
              <MoreButton
                id={userId}
                isBig
                deleteHandler={deleteUserHandler}
                editHandler={(e) => {
                  e.stopPropagation();
                  openEditSidePanel(userId);
                }}
              />
            </SinglePageTopBar>
            {!loading ? (
              <>
                <HStack
                  gap={0}
                  p="2.5rem 2rem 2.5rem"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  w="100%"
                >
                  <SinglePageImageWrapper image={image} />

                  <HStack
                    gap={0}
                    p="0rem 0rem 0rem 2rem"
                    w="calc(100% - 260px)"
                    alignItems="flex-start"
                  >
                    <>
                      <SingleInfo data={user} />
                      {/*   <AttendeeCharts attendee={attendee} /> */}
                    </>
                  </HStack>
                </HStack>
              </>
            ) : (
              <Flex justify="center" align="center" w="100%" p={20}>
                <Spinner size="xl" color={primary} />
              </Flex>
            )}
            <Box p="0 2.5rem">
              <TabsComponent
                activeTab={activeTab}
                tabs={tabsData}
                setActiveTab={setActiveTab}
                isTitleValid={true}
                disabledTabs={[2, 3]}
                isSinglePage
              >
                <TabPanels>
                  <TabPanel pb={4} px={0}>
                    {/* <TabAttendeeCourses /> */}
                  </TabPanel>
                </TabPanels>
              </TabsComponent>
            </Box>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default PatientSingle;
