import React, { useState, useEffect } from "react";
import {
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
  HStack,
  Tooltip,
  Icon,
  Button,
  IconButton,
  Image,
  Box,
  useToast,
} from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  white,
  newBorder,
  backgroundLight,
  secondary,
} from "../../../../utils/colors";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import {
  TbDeviceMobileStar,
  TbDeviceMobile,
  TbMail,
  TbGenderAndrogyne,
  TbMapPin,
  TbCalendar,
  TbFingerprint,
  TbAddressBook,
  TbBuildingSkyscraper,
  TbEdit,
  TbBriefcase,
  TbBuildingHospital,
  TbFingerprintScan,
  TbReceiptTax,
  TbRegexOff,
  TbBuildingBank,
  TbLink,
  TbGridScan,
  TbUser,
} from "react-icons/tb";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import SinglePageImageWrapper from "../../../../components/partials/SinglePageImageWrapper";
import EditImage from "./EditImage";
import useRoleCheck from "../../../../hooks/useRoleCheck";
import blank from "../../../../assets/icons/blank.svg";
import noImage from "../../../../assets/icons/no-image.svg";
import InfoEntryTooltip from "./InfoEntryTooltip";
import LogoUpload from "../../../../components/input/LogoUpload";
import { updateTenantImage } from "../../../../actions/tenantActions";
import { TENANT_UPDATE_IMAGE_RESET } from "../../../../constants/tenantConstants";

const TenantInfo = ({ tenant, editHandler }) => {
  const { t } = useTranslation();
  const toast = useToast();

  // STATE
  const [logo, setLogo] = useState(null);

  // ROLES
  const isDoctor = useRoleCheck("employee_doctor");
  const isAdmin = useRoleCheck("admin");

  const [sidePanelModalOpen, setSidePanelModalOpen] = useState(false);

  const openSidePanelModalHandler = () => {
    setSidePanelModalOpen(true);
  };

  const closeSidePanelModal = () => {
    setSidePanelModalOpen(false);
  };

  // REDUX
  const dispatch = useDispatch();

  const tenantUpdateImage = useSelector((state) => state.tenantUpdateImage);
  const { success: successUpdateImage } = tenantUpdateImage;

  const updateLogoHandler = (image) => {
    dispatch(updateTenantImage({ image: image }));
  };

  // console.log("user in patient details", user);

  const companyName = tenant && tenant.companyName ? tenant.companyName : "-";
  const clinicName = tenant && tenant.clinicName ? tenant.clinicName : "-";
  const companyCode = tenant && tenant.companyCode ? tenant.companyCode : "-";
  const vatCode = tenant && tenant.vatCode ? tenant.vatCode : "-";
  const image = tenant && tenant.image ? tenant.image : null;
  const phone = tenant && tenant.phone ? tenant.phone : "-";
  const phoneAlt = tenant && tenant.phoneAlt ? tenant.phoneAlt : "-";
  const email = tenant && tenant.email ? tenant.email : "-";
  const street =
    tenant && tenant.address && tenant.address.length > 0
      ? tenant.address[0].street
      : "-";
  const city =
    tenant && tenant.address && tenant.address.length > 0
      ? tenant.address[0].city
      : "-";
  const zip =
    tenant && tenant.address && tenant.address.length > 0
      ? tenant.address[0].zip
      : "-";
  const country =
    tenant && tenant.address && tenant.address.length > 0
      ? tenant.address[0].country
      : "-";
  const iban =
    tenant && tenant.bankAccountDetails && tenant.bankAccountDetails.length > 0
      ? tenant.bankAccountDetails[0].iban
      : "-";

  const website = tenant && tenant.website ? tenant.website : "-";

  useEffect(() => {
    if (tenant && tenant.image) {
      setLogo(tenant.image);
    }
  }, [tenant]);

  useEffect(() => {
    if (successUpdateImage) {
      toast({
        title: t("toasts.logoUploadSuccess"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      dispatch({ type: TENANT_UPDATE_IMAGE_RESET });
    }
  }, [successUpdateImage]);

  console.log("tenant", tenant);

  return (
    <>
      {/* SIDEPANELS  */}
      <SidePanel
        isTop
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={image ? t("common.editImage") : t("common.addImage")}
        noBorder={true}
      >
        <EditImage
          id={tenant._id}
          currentImage={image}
          close={closeSidePanelModal}
        />
      </SidePanel>

      <Flex
        w="100%"
        justify="space-between"
        p="2rem"
        direction="column"
        m="0rem 0 0"
      >
        <Flex align="baseline">
          <Heading
            fontSize="1.125rem"
            color={textPrimary}
            fontWeight="600"
            m={0}
            p="0 0 0.75rem"
          >
            {t("tenant.companyDetails")}
          </Heading>
        </Flex>

        <Flex gap="2rem">
          <LogoUpload
            image={logo}
            setImage={setLogo}
            onUpload={updateLogoHandler}
          />

          <Flex w="100%" direction="column" m="0rem 0 0">
            <Grid
              templateColumns="repeat(3, 1fr)"
              gap={8}
              w="100%"
              m="0rem 0 0"
              p="1.5rem"
              pr="4rem"
              border={`1px solid ${newBorder}`}
              borderRadius="1rem"
              position="relative"
              justify="flex-start"
              align="flex-start"
            >
              <IconButton
                bg="none"
                justify="center"
                align="center"
                position="absolute"
                top="1rem"
                right="1rem"
                size="sm"
                border={`1px solid ${newBorder}`}
                onClick={editHandler}
                isDisabled={isDoctor}
              >
                <Icon
                  as={TbEdit}
                  variant="link"
                  color={textSecondary}
                  position="relative"
                  top="1px"
                >
                  {t("patient.detailsEdit")}
                </Icon>
              </IconButton>
              <GridItem
                colSpan={1}
                borderRight={`1px solid ${newBorder}`}
                justify="flex-start"
                align="flex-start"
                h="100%"
              >
                <InfoEntryTooltip
                  textLabel={t("tenant.companyName")}
                  label={companyName}
                  isDisabled={isDoctor}
                  icon={TbBriefcase}
                />

                <InfoEntryTooltip
                  textLabel={t("tenant.clinicName")}
                  label={clinicName}
                  isDisabled={isDoctor}
                  icon={TbBuildingHospital}
                />

                <InfoEntryTooltip
                  textLabel={t("tenant.companyCode")}
                  label={companyCode}
                  isDisabled={isDoctor}
                  icon={TbFingerprintScan}
                />

                <InfoEntryTooltip
                  textLabel={t("tenant.vatCode")}
                  label={vatCode}
                  isDisabled={isDoctor}
                  icon={TbGridScan}
                />
                <InfoEntryTooltip
                  textLabel={t("tenant.iban")}
                  label={iban}
                  isDisabled={isDoctor}
                  icon={TbBuildingBank}
                />
              </GridItem>
              <GridItem
                colSpan={1}
                borderRight={`1px solid ${newBorder}`}
                justify="flex-start"
                align="flex-start"
                h="100%"
              >
                <InfoEntryTooltip
                  label={phone}
                  isDisabled={isDoctor}
                  icon={TbDeviceMobile}
                  textLabel={t("tenant.phone")}
                />

                <InfoEntryTooltip
                  label={phoneAlt}
                  isDisabled={isDoctor}
                  icon={TbDeviceMobileStar}
                  textLabel={t("tenant.phoneAlt")}
                />
                <InfoEntryTooltip
                  label={email}
                  isDisabled={isDoctor}
                  icon={TbMail}
                  textLabel={t("tenant.email")}
                />
                <InfoEntryTooltip
                  label={website}
                  isDisabled={isDoctor}
                  icon={TbLink}
                  textLabel={t("tenant.website")}
                />
              </GridItem>

              <GridItem
                colSpan={1}
                justify="flex-start"
                align="flex-start"
                h="100%"
              >
                <InfoEntryTooltip
                  label={street}
                  isDisabled={isDoctor}
                  icon={TbMapPin}
                />
                <InfoEntryTooltip
                  label={`${city}, ${zip}`}
                  isDisabled={isDoctor}
                  icon={TbAddressBook}
                />

                <InfoEntryTooltip
                  label={country}
                  isDisabled={isDoctor}
                  icon={TbBuildingSkyscraper}
                />
                {/* <InfoEntryTooltip
                  label="Rytis Ražanauskas"
                  isDisabled={isDoctor}
                  icon={TbUser}
                /> */}
              </GridItem>
            </Grid>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default TenantInfo;
