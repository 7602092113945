import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Stack,
  Flex,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  buttonRedText,
  newBorder,
  backgroundLight,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { FaXmark } from "react-icons/fa6";

const ConfirmDeleteModal = ({
  isOpen,
  onClose,
  confirmAction,
  deleteName,
  warningLabel,
  deleteTitle,
}) => {
  const { t } = useTranslation();

  console.log("deleteName", deleteName);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        textAlign="center"
        p="0rem 0"
        borderRadius="1.75rem"
        overflow="hidden"
      >
        <Flex
          align="center"
          justify="center"
          w="100%"
          p="1rem 2rem"
          borderBottom={`1px solid ${newBorder}`}
          position="relative"
        >
          <Text fontSize="1.125rem" fontWeight="600" color={textPrimary}>
            {deleteTitle
              ? deleteTitle
              : t("common.deleteItemConfirmationTitle")}
          </Text>
          <IconButton
            variant="ghost"
            position="absolute"
            right="1rem"
            size="sm"
            borderRadius="0.875rem"
            icon={<FaXmark />}
            onClick={onClose}
          />
        </Flex>

        <ModalBody
          p="2rem 2rem"
          color={textSecondary}
          fontWeight="500"
          fontSize="1rem"
        >
          <Stack gap={0.5}>
            <Text fontSize="md">{t("common.deleteStart")}</Text>
            {deleteName && (
              <Text fontWeight="600" fontSize="md" ml={1} color={textPrimary}>
                {deleteName}?
              </Text>
            )}
            {warningLabel && (
              <Text
                mt={3}
                as="span"
                fontSize="sm"
                fontWeight="500"
                color={buttonRedText}
              >
                {warningLabel}
              </Text>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderTop={`1px solid ${newBorder}`}
          gap={4}
        >
          <Button
            colorScheme="red"
            size="lg"
            fontSize="md"
            p="0 2rem"
            borderRadius="10rem"
            onClick={confirmAction}
          >
            {t("common.yes")}
          </Button>
          <Button
            variant="outline"
            size="lg"
            fontSize="md"
            p="0 2rem"
            borderRadius="10rem"
            color={textSecondary}
            onClick={onClose}
            _hover={{
              bg: backgroundLight,
              color: textPrimary,
            }}
          >
            {t("common.no")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmDeleteModal;
