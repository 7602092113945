import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  listTreatmentPlans,
  deleteTreatmentPlan,
} from "../../../../actions/treatmentPlanActions";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import { Box, Button } from "@chakra-ui/react";
import ToastComponent from "../../../../components/partials/ToastComponent";
import { columns } from "../columns/TreatmentPlanListColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import {
  TREATMENTPLAN_CREATE_RESET,
  TREATMENTPLAN_DELETE_RESET,
  TREATMENTPLAN_UPDATE_RESET,
  TREATMENTPLAN_DETAILS_RESET,
  TREATMENTPLAN_CANCEL_RESET,
  TREATMENTPLAN_APPROVE_RESET,
} from "../../../../constants/treatmentPlanConstants";
import {
  USER_DETAILS_RESET,
  USER_EMPLOYEE_DETAILS_RESET,
  USER_DOCTOR_SERVICES_RESET,
} from "../../../../constants/userConstants";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import SidePanelLong from "../../../../components/sidepanel/SidePanelLong.js";
import { motion, AnimatePresence } from "framer-motion";
import EditPatientTreatmentPlan from "../../patients/sidepanels/EditPatientTreatmentPlan";
import PatientSingleLong from "../../patients/single/PatientSingleLong";
import CreatePatientTreatmentPlan from "../../patients/sidepanels/CreatePatientTreatmentPlan";
import TreatmentPlansStats from "../partials/TreatmentPlansStats";
import useRoleCheck from "../../../../hooks/useRoleCheck.js";
import ActionsModal from "../../../../components/modals/ActionsModal";
import IsFilterActive from "../../../../components/partials/IsFilterActive";
import CreateTreatmentPlan from "../sidepanels/CreateTreatmentPlan";

const TreatmentPlanList = () => {
  // GENERAL
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // ROLES
  const isAdmin = useRoleCheck("admin");

  // PAGINATION
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [4, 10, 25, 100];

  // FILTER
  const [searchTitle, setSearchTitle] = useState("");

  // MULTI SELECT
  const [selectedPlanIds, setSelectedPlanIds] = useState([]);
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);

  // Call this function when you want to open the modal,
  // e.g., when treatment plans are selected
  const handleOpenActionsModal = () => {
    setIsActionsModalOpen(true);
  };

  // Call this function to close the modal
  const handleCloseActionsModal = () => {
    setIsActionsModalOpen(false);
  };

  // SORTING
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";

    if (sortField !== column || sortOrder !== newSortOrder) {
      // Only dispatch the action if sorting parameters change
      console.log(`Sorting ${column} in ${newSortOrder} order`);

      setSortField(column);
      setSortOrder(newSortOrder);

      dispatch(
        listTreatmentPlans(
          searchTitle,
          1,
          pageSize,
          column,
          newSortOrder,
          false,
          filterParams.employeeId,
          filterParams.status,
          filterParams.startDate,
          filterParams.endDate,
          filterParams.minPrice,
          filterParams.maxPrice
        )
      );
    }
  };

  // FILTERING
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [filtersInitialized, setFiltersInitialized] = useState(false);

  const filterConfig = {
    patients: false,
    services: false,
    treatmentPlans: true,
  };

  const [filterParams, setFilterParams] = useState({
    employeeId: [],
    status: [],
    startDate: "",
    endDate: "",
    minPrice: "",
    maxPrice: "",
  });
  const handleFilterApply = (filters) => {
    setFilterParams(filters);
    setIsFilterActive(true);
    setPage(1);
    localStorage.setItem(
      "filterSettings_treatmentPlans",
      JSON.stringify(filters)
    ); // Example for PatientList
    dispatch(
      listTreatmentPlans(
        searchTitle,
        1,
        pageSize,
        sortField,
        sortOrder,
        false,
        filters.employeeId,
        filters.status,
        filters.startDate,
        filters.endDate,
        filters.minPrice,
        filters.maxPrice
      )
    );

    console.log("filters", filters);
  };

  // SIDEPANELS
  const [patientSingleSidePanel, setPatientSingleSidePanel] = useState(false);
  const [sidePanelModalOpen, setSidePanelModalOpen] = useState(false);
  const [isSidePanelLongOpen, setIsSidePanelLongOpen] = useState(false);
  const [
    createPatientTreatmentPlanSidePanel,
    setCreatePatientTreatmentPlanSidePanel,
  ] = useState(false);

  // CONSTANTS
  const [planId, setPlanId] = useState("");
  const [treatmentPlanId, setTreatmentPlanId] = useState("");
  const [patientId, setPatientId] = useState("");
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState("");

  // REDUX
  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  const treatmentPlanList = useSelector((state) => state.treatmentPlanList);
  const {
    loading,
    error,
    treatmentPlans,
    pages,
    countsByStatus,
    globalTotalCount,
  } = treatmentPlanList;

  const treatmentPlanCreate = useSelector((state) => state.treatmentPlanCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    treatmentPlan: createdTreatmentPlan,
  } = treatmentPlanCreate;

  const treatmentPlanUpdate = useSelector((state) => state.treatmentPlanUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = treatmentPlanUpdate;

  const treatmentPlanDelete = useSelector((state) => state.treatmentPlanDelete);

  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = treatmentPlanDelete;

  const treatmentPlanCancel = useSelector((state) => state.treatmentPlanCancel);

  const {
    loading: loadingCancel,
    error: errorCancel,
    success: successCancel,
  } = treatmentPlanCancel;

  const treatmentPlanApprove = useSelector(
    (state) => state.treatmentPlanApprove
  );

  const {
    loading: loadingApprove,
    error: errorApprove,
    success: successApprove,
  } = treatmentPlanApprove;

  // HANDLERS

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  // USEEFFECT

  const deleteTreatmentPlanHandler = (treatmentPlanId) => {
    dispatch(deleteTreatmentPlan(treatmentPlanId));
  };

  useEffect(() => {
    const savedFiltersStr = localStorage.getItem(
      "filterSettings_treatmentPlans"
    );
    if (savedFiltersStr) {
      const savedFilters = JSON.parse(savedFiltersStr);
      setFilterParams(savedFilters);
      setIsFilterActive(true);
      console.log("Saved filters applied", savedFilters);
    } else {
      console.log("No saved filters found");
    }
    setFiltersInitialized(true); // Indicate that filter initialization is complete
  }, []);

  const fetchTreatmentPlans = () => {
    dispatch(
      listTreatmentPlans(
        searchTitle,
        page,
        pageSize,
        sortField,
        sortOrder,
        false,
        filterParams.employeeId,
        filterParams.status,
        filterParams.startDate,
        filterParams.endDate,
        filterParams.minPrice,
        filterParams.maxPrice
      )
    );
  };

  useEffect(() => {
    if (userInfo && filtersInitialized) {
      fetchTreatmentPlans();
    } else if (!userInfo) {
      navigate("/login");
    }
  }, [
    dispatch,
    userInfo,
    filtersInitialized,
    searchTitle,
    page,
    pageSize,
    sortField,
    sortOrder,
    filterParams,
    successApprove,
    successCancel,
    successCreate,
    successDelete,
    successUpdate,
  ]);

  useEffect(() => {
    if (successCreate) {
      // toastComponent.showToast(
      //   t("toasts.treatmentPlanCreate"),
      //   "success",
      //   3000
      // );
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: TREATMENTPLAN_CREATE_RESET });
      dispatch({ type: USER_EMPLOYEE_DETAILS_RESET });
      dispatch({ type: USER_DOCTOR_SERVICES_RESET });
    } else if (successCancel) {
      // toastComponent.showToast(
      //   t("toasts.treatmentPlanCancel"),
      //   "success",
      //   3000
      // );
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");

      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: TREATMENTPLAN_CANCEL_RESET });
      dispatch({ type: USER_EMPLOYEE_DETAILS_RESET });
      dispatch({ type: USER_DOCTOR_SERVICES_RESET });
    } else if (successDelete) {
      // toastComponent.showToast(
      //   t("toasts.treatmentPlanDelete"),
      //   "success",
      //   3000
      // );
      closeSidePanelModal();

      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: TREATMENTPLAN_DELETE_RESET });
      dispatch({ type: USER_EMPLOYEE_DETAILS_RESET });
      dispatch({ type: USER_DOCTOR_SERVICES_RESET });
    } else if (successApprove) {
      // toastComponent.showToast(
      //   t("toasts.treatmentPlanApprove"),
      //   "success",
      //   3000
      // );
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");

      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: TREATMENTPLAN_APPROVE_RESET });
      dispatch({ type: USER_EMPLOYEE_DETAILS_RESET });
      dispatch({ type: USER_DOCTOR_SERVICES_RESET });
    } else if (successUpdate) {
      // toastComponent.showToast(
      //   t("toasts.treatmentPlanUpdate"),
      //   "success",
      //   3000
      // );
      // closeEditSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch({ type: TREATMENTPLAN_UPDATE_RESET });
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: USER_EMPLOYEE_DETAILS_RESET });
      dispatch({ type: USER_DOCTOR_SERVICES_RESET });
    }

    // fetchTreatmentPlans();
  }, [
    successCreate,
    successUpdate,
    successDelete,
    successCancel,
    successApprove,
    dispatch,
  ]);

  // SIDEPANEL HANDLERS

  const openPatientSingleLong = (patientId) => {
    setPatientSingleSidePanel(true);
    setUserId(patientId);
    console.log("openPatientSingleLong called with userId:", userId);
    document.body.classList.add("no-scroll");
  };
  const closePatientSingleLong = () => {
    setPatientSingleSidePanel(false);
    document.body.classList.remove("no-scroll");
  };

  const closeSidePanelLong = () => {
    setIsSidePanelLongOpen(false);
    setUserId("");
  };

  const openSidePanelModalHandler = (planId) => {
    setSidePanelModalOpen(true);
    setPlanId(planId);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelModalOpen(false);
    setPlanId(null);
    document.body.classList.remove("no-scroll");
    dispatch({ type: TREATMENTPLAN_DETAILS_RESET });
  };

  const openCreatePatientTreatmentPlanSidePanel = () => {
    console.log("here");
    document.body.classList.add("no-scroll");
    setCreatePatientTreatmentPlanSidePanel(true);
    console.log(
      "createPatientTreatmentPlanSidePanel",
      createPatientTreatmentPlanSidePanel
    );
  };

  const closeCreatePatientTreatmentPlanSidePanel = () => {
    setCreatePatientTreatmentPlanSidePanel(false);
    document.body.classList.remove("no-scroll");
  };

  // LOGS

  // console.log("userId in TreatmentPlanList", userId);

  // console.log("patientSingleSidePanel", patientSingleSidePanel);
  // console.log("treatmentPlans", treatmentPlans);

  // console.log("selectedPlanIds", selectedPlanIds);

  // console.log("filtersInitialized", filtersInitialized);
  // console.log("filterParams", filterParams);
  // console.log("treatmentPlans", treatmentPlans);
  // console.log(
  //   "localStorage",
  //   localStorage.getItem("filterSettings_treatmentPlans")
  // );

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>Treatment Plan List</title>
        <meta name="description" content="Dentacasa Treatment Plan List" />
      </Helmet>
      {/* SIDEPANELS  */}
      <SidePanel
        isLong
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("patient.createTreatmentPlan")}
        noBorder={true}
      >
        <EditPatientTreatmentPlan
          planId={planId}
          close={closeSidePanelModal}
          currentUser={userInfo}
        />
      </SidePanel>

      <AnimatePresence mode="wait">
        {patientSingleSidePanel && (
          <SidePanelLong close={closePatientSingleLong}>
            <PatientSingleLong
              userId={userId}
              close={closePatientSingleLong}
              setUserId={setUserId}
              openSidePanelModal={openPatientSingleLong}
              openEditSidePanelModal={console.log(
                "openEditSidePanelModal called from PatientSingleLong"
              )}
              userInfo={userInfo}
              refreshPatientList={console.log(
                "refreshPatientList called from PatientSingleLong"
              )}
            />
            <Box>hello</Box>
          </SidePanelLong>
        )}
      </AnimatePresence>
      <SidePanel
        isLong
        open={createPatientTreatmentPlanSidePanel}
        close={closeCreatePatientTreatmentPlanSidePanel}
        title={t("patient.createPatientTreatmentPlan")}
        noBorder={true}
      >
        <CreatePatientTreatmentPlan
          close={closeCreatePatientTreatmentPlanSidePanel}
          currentUser={userInfo}
        />

        {/* <CreateTreatmentPlan
          close={closeCreatePatientTreatmentPlanSidePanel}
          currentUser={userInfo}
        /> */}
      </SidePanel>
      {/* MAIN  */}
      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("treatmentPlan.treatmentPlans")}
                onChange={onChangeSearchTitle}
                placeholder={t("treatmentPlan.search")}
                searchName={searchTitle}
                buttonLabel={t("treatmentPlan.createTreatmentPlan")}
                buttonHandler={openCreatePatientTreatmentPlanSidePanel}
                isFilter
                filterLabel={t("patient.filter")}
                filterHandler={handleFilterApply}
                isFilterActive={isFilterActive}
                setIsFilterActive={setIsFilterActive}
                filterConfig={filterConfig}
                filterStatusOptions="treatment"
                savedFilters={filterParams}
                savedFiltersKey="filterSettings_treatmentPlans"
              />
            </Box>

            <TreatmentPlansStats
              loading={loading}
              countsByStatus={countsByStatus}
              globalTotalCount={globalTotalCount}
            />

            {isFilterActive && !loading && (
              <IsFilterActive label={t("filter.filterActive")} />
            )}

            <Table.Container
              columns={columns(
                dispatch,
                translate,
                deleteTreatmentPlanHandler,
                handleSortChange,
                openPatientSingleLong,
                openSidePanelModalHandler,
                isAdmin,
                selectedPlanIds,
                setSelectedPlanIds
              )}
              data={treatmentPlans}
              loading={loading}
              error={error}
              navigate={navigate}
              navigateTo="/treatment-plans"
              // noItemsButtonHandler={openSidePanelModal}
              onRowClick={(planId) => {
                openSidePanelModalHandler(planId);
              }}
              noItemsTitle={t("noItems.noTreatmentPlans")}
              noItemsDescription={t("noItems.noTreatmentPlansText")}
              noItemsButtonLabel={t("noItems.noTreatmentPlansButton")}
              noItemsButtonHandler={openCreatePatientTreatmentPlanSidePanel}
              sortField={sortField}
              sortOrder={sortOrder}
              selectedPlanIds={selectedPlanIds}
              setSelectedPlanIds={setSelectedPlanIds}
            />

            {/* <Button
              onClick={() => {
                console.log("selectedPlanIds", selectedPlanIds);
                handleOpenActionsModal();
              }}
            >
              Close modal
            </Button> */}

            <Table.Pagination
              loading={loading}
              page={page}
              pages={pages}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
              setPage={setPage}
              setPageSize={setPageSize}
              totalCount={treatmentPlans.length}
            />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default TreatmentPlanList;
