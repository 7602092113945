import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import OptionsOutsideSelect from "./OptionsOutsideSelect";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import { listSpeakers } from "../../actions/speakerActions";
import { primary } from "../../utils/colors";

const MultiSpeakerSelectMain = ({
  currentOptions,
  value,
  onChange,
  isSelectedHidden,
  placeholderText,
  title,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const speakerList = useSelector((state) => state.speakerList);
  const { loading, error, speakers } = speakerList;

  const [speakerOptions, setSpeakerOptions] = useState([]);

  useEffect(() => {
    dispatch(listSpeakers("", "", "", ""));
  }, []);

  useEffect(() => {
    if (speakers && speakers.length > 0) {
      const activeSpeakers = speakers.filter(
        (speaker) => speaker.status === "active"
      );

      const options = activeSpeakers.map((s) => ({
        value: s._id,
        label: `${s.firstName} ${s.lastName}`, // Concatenate first name and last name for label
        image: s.image,
      }));

      // Adjusted filter logic
      const filteredOptions = options.filter(
        (option) =>
          !currentOptions.some(
            (currentOption) => currentOption._id === option.value
          )
      );

      setSpeakerOptions([...filteredOptions]);
    }
  }, [speakers, currentOptions]);

  console.log("currentOptions", currentOptions);
  console.log("speakers", speakers);
  console.log("speakerOptions", speakerOptions);

  if (loading) {
    console.log("loading", loading);
    return (
      <Flex w="100%">
        <Spinner size="lg" color={primary} />
      </Flex>
    );
  }

  if (error) {
    console.log("error", error);
    return <Text>{error}</Text>;
  }

  return (
    <>
      <div>
        <OptionsOutsideSelect
          onChange={onChange}
          isMulti
          options={speakerOptions}
          value={value}
          title={title}
          isSelectedHidden={isSelectedHidden}
          placeholderText={placeholderText}
        />
      </div>
    </>
  );
};

export default MultiSpeakerSelectMain;
