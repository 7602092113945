import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
  HStack,
  Button,
  useToast,
} from "@chakra-ui/react";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import {
  backgroundLight,
  newBorder,
  textPrimary,
  textSecondary,
  white,
  primary,
} from "../../../../utils/colors";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlAddress from "../../../../components/input/FormControlAddress";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlBank from "../../../../components/input/FormControlBank";
import { useDispatch, useSelector } from "react-redux";
import { updateTenant } from "../../../../actions/tenantActions";

const EditTenantDetails = ({ close, tenant }) => {
  // TRANSLATION
  const { t } = useTranslation();

  // TOAST
  const toast = useToast();

  // STATE
  const [companyName, setCompanyName] = useState("");
  const [clinicName, setClinicName] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [vatCode, setVatCode] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneAlt, setPhoneAlt] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [iban, setIban] = useState("");
  const [bankName, setBankName] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [adminUser, setAdminUser] = useState("");

  // REDUX
  const dispatch = useDispatch();

  // STATE TOUCHED
  const [companyNameTouched, setCompanyNameTouched] = useState(false);

  // VALIDATION
  const isCompanyNameError = companyName === "";

  // HANDLERS
  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  const closeHandler = () => {
    close();
  };

  // SUBMIT
  const submitHandler = (e) => {
    e.preventDefault();
    console.log("submitting");

    dispatch(
      updateTenant({
        clinicName,
        companyName,
        companyCode,
        vatCode,
        phone,
        phoneAlt,
        street,
        zip,
        city,
        country,
        state,
        iban,
        bankName,
        website,
      })
    );

    close();

    console.log("submitted", updateTenant);

    // close();
  };

  // USEEFFECT
  useEffect(() => {
    if (tenant) {
      setCompanyName(tenant.companyName);
      setClinicName(tenant.clinicName);
      setCompanyCode(tenant.companyCode);
      setVatCode(tenant.vatCode);
      setPhone(tenant.phone);
      setPhoneAlt(tenant.phoneAlt);
      setEmail(tenant.email);
      setWebsite(tenant.website);
      setIban(
        tenant.bankAccountDetails.length > 0
          ? tenant.bankAccountDetails[0].iban
          : ""
      );
      setBankName(tenant.bankAccountDetails.length > 0 ? tenant.bankName : "");
      setStreet(tenant.address.length > 0 ? tenant.address[0].street : "");
      setZip(tenant.address.length > 0 ? tenant.address[0].zip : "");
      setCity(tenant.address.length > 0 ? tenant.address[0].city : "");
      setCountry(tenant.address.length > 0 ? tenant.address[0].country : "");
      setState(tenant.address.length > 0 ? tenant.address[0].state : "");
    }
  }, [tenant]);

  // console.log("tenant", tenant);
  // console.log("companyName", companyName);
  // console.log("clinicName", clinicName);
  // console.log("companyCode", companyCode);
  // console.log("vatCode", vatCode);
  // console.log("phone", phone);
  // console.log("phoneAlt", phoneAlt);
  // console.log("email", email);
  // console.log("website", website);
  // console.log("iban", iban);
  // console.log("bankName", bankName);
  // console.log("street", street);
  // console.log("zip", zip);
  // console.log("city", city);
  // console.log("country", country);
  // console.log("state", state);
  console.log("adminUser", adminUser);

  return (
    <>
      <Box position="relative">
        <form
          onSubmit={submitHandler}
          style={{
            height: "100%",
          }}
        >
          <Flex
            justify="space-between"
            align="center"
            p="1.5rem 2rem 1.5rem"
            borderBottom={`1px solid ${newBorder}`}
            mb="0rem"
            position="absolute"
            zIndex={2}
            bg={white}
            w="100%"
          >
            <Flex align="center">
              <IconButton
                bg="none"
                border={`1px solid ${newBorder}`}
                color={textSecondary}
                onClick={close}
                icon={<FaArrowRightFromBracket />}
                borderRadius="0.75rem"
                fontSize="0.75rem"
              />
              <Stack gap={0} ml={4}>
                <Flex align="center">
                  <Heading
                    fontSize="1.275rem"
                    color={textPrimary}
                    fontWeight="600"
                    mr={4}
                  >
                    {t("tenant.editCompanyDetails")}
                  </Heading>

                  {/* <StatusBadge status={status} /> */}
                </Flex>

                <Text fontSize="sm" color={textSecondary} fontWeight="500">
                  {tenant.clinicName}
                </Text>
              </Stack>
            </Flex>
            <Stack gap={0}>
              <Flex align="center"></Flex>
            </Stack>
          </Flex>

          <Box
            w="100%"
            h="calc(100vh - 60px)"
            overflow="scroll"
            p="0 2rem 0rem"
          >
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="7rem 0 0"
            >
              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("tenant.companyDetails")}
              </Heading>
              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1.5rem"
              >
                <HStack gap={4} w="100%">
                  <FormControlDefault
                    label={t("tenant.companyName")}
                    value={companyName}
                    type="text"
                    placeholder={t("tenant.clinicName")}
                    name="companyName"
                    required={true}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      // setCompanyNameTouched(true);
                    }}
                  />
                  <FormControlDefault
                    label={t("tenant.clinicName")}
                    value={clinicName}
                    type="text"
                    placeholder={t("tenant.clinicName")}
                    name="clinicName"
                    isRequired={true}
                    isTouched={companyNameTouched}
                    isError={isCompanyNameError}
                    setIsTouched={setCompanyNameTouched}
                    errorMessage={t("formError.companyNameRequired")}
                    onChange={(e) => {
                      setClinicName(e.target.value);
                      // setClinicNameTouched(true);
                    }}
                  />
                </HStack>
                <HStack gap={4} w="100%">
                  <FormControlDefault
                    label={t("tenant.companyCode")}
                    value={companyCode}
                    type="text"
                    placeholder={t("tenant.companyCode")}
                    name="companyCode"
                    required={true}
                    onChange={(e) => {
                      setCompanyCode(e.target.value);
                      // setCompanyCodeTouched(true);
                    }}
                  />
                  <FormControlDefault
                    label={t("tenant.vatCode")}
                    value={vatCode}
                    type="text"
                    placeholder={t("tenant.vatCode")}
                    name="vatCode"
                    required={true}
                    onChange={(e) => {
                      setVatCode(e.target.value);
                      // setVatCodeTouched(true);
                    }}
                  />
                </HStack>
              </Flex>

              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("tenant.contactDetails")}
              </Heading>
              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1.5rem"
              >
                <HStack gap={4} w="100%">
                  <FormControlPhone
                    label={t("form.phone")}
                    value={phone}
                    onChange={(newPhone) => {
                      setPhone(newPhone);
                      //   setPhoneTouched(true);
                    }}
                    isRequired={true}
                    // isTouched={phoneTouched}
                    // isError={isPhoneError}
                    errorMessage={t("formError.phoneRequired")}
                    w="50%"
                  />

                  <FormControlPhone
                    label={t("form.phoneAlt")}
                    value={phoneAlt}
                    onChange={(newPhone) => {
                      setPhoneAlt(newPhone);
                    }}
                    isRequired={false}
                    errorMessage={t("formError.phoneRequired")}
                    w="50%"
                  />
                </HStack>
                <HStack gap={4} w="100%">
                  {/* <FormControlDefault
                    label={t("tenant.email")}
                    value={email}
                    type="text"
                    placeholder={t("tenant.email")}
                    name="email"
                    required={true}
                    w="50%"
                    isDisabled
                  /> */}
                  <FormControlDefault
                    label={t("tenant.website")}
                    value={website}
                    type="text"
                    placeholder={t("tenant.website")}
                    name="phone"
                    required={true}
                    w="100%"
                    onChange={(e) => {
                      setWebsite(e.target.value);
                      //   setWebsiteTouched(true);
                    }}
                  />
                </HStack>
                <HStack gap={4} w="100%">
                  <FormControlAddress
                    currentValue={street}
                    label={t("form.address")}
                    onAddressSelect={handleAddressSelect}
                    // isTouched={streetTouched} // Assuming you have similar touch state for street
                    // isError={isStreetError} // Assuming you have similar error state for street
                    // setIsTouched={setStreetTouched}
                    // errorMessage={t("formError.addressRequired")}
                    w="100%"
                    googleApiKey="AIzaSyCNQfHnntkLgj5Es5V8V0H2BfIetOOZ9yw"
                  />
                  <FormControlDefault
                    type="text"
                    label={t("form.zip")}
                    value={zip}
                    placeholder={t("placeholder.zip")}
                    onChange={(e) => {
                      setZip(e.target.value);
                      //   setZipTouched(true);
                    }}
                    // isTouched={zipTouched}
                    // isError={isZipError}
                    w="40%"
                    errorMessage={t("formError.zipRequired")}
                  />
                </HStack>

                <HStack gap={4} w="100%">
                  <FormControlDefault
                    type="text"
                    label={t("form.city")}
                    value={city}
                    placeholder={t("placeholder.city")}
                    onChange={(e) => {
                      setCity(e.target.value);
                      //   setCityTouched(true);
                    }}
                    // isTouched={cityTouched}
                    // isError={isCityError}
                    w="50%"
                    errorMessage={t("formError.cityRequired")}
                  />

                  <FormControlCountry
                    label={t("form.country")}
                    value={country}
                    // isTouched={countryTouched}
                    // isError={isCountryError}
                    onChange={(selectedOption) => {
                      setCountry(selectedOption ? selectedOption.value : "");
                      //   setCountryTouched(true);
                    }}
                    w="50%"
                    errorMessage={t("formError.countryRequired")}
                  />
                </HStack>
              </Flex>

              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("tenant.bankDetails")}
              </Heading>
              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1.5rem"
              >
                <HStack gap={4} w="100%">
                  <FormControlDefault
                    type="text"
                    // isRequired
                    label={t("form.iban")}
                    value={iban}
                    placeholder={t("placeholder.iban")}
                    onChange={(e) => {
                      setIban(e.target.value);
                    }}
                    // isTouched={firstNameTouched}
                    // isError={isFirstNameError}
                    w="50%"
                    // errorMessage={t("formError.firstNameRequired")}
                  />
                  <FormControlBank
                    label={t("form.bankName")}
                    value={bankName}
                    placeholder={t("placeholder.bankName")}
                    onChange={(selectedOption) => {
                      setBankName(selectedOption.value);
                    }}
                    w="50%"
                  />{" "}
                </HStack>
              </Flex>
            </Flex>
            <Flex
              mt={4}
              justify="center"
              direction="column"
              borderTop={`1px solid ${newBorder}`}
              p="1.5rem 0"
              position="relative"
              zIndex={2}
              bg={white}
              bottom="0"
              w="100%"
              m="4rem 0 0"
            >
              <Flex justify="flex-end">
                <Button
                  minWidth="10rem"
                  mr={4}
                  bg={backgroundLight}
                  size="lg"
                  color={textSecondary}
                  fontSize="md"
                  borderRadius="10rem"
                  onClick={closeHandler}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  minWidth="10rem"
                  type="submit"
                  size="lg"
                  bg={primary}
                  color={white}
                  fontSize="md"
                  borderRadius="10rem"
                  onClick={submitHandler}
                >
                  {t("tenant.saveEditTenant")}
                </Button>
              </Flex>
            </Flex>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default EditTenantDetails;
