import React, { useState, useEffect } from "react";
import { Flex, Box, Heading, Select, Text, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  backgroundLight,
  newBorder,
  textPrimary,
  primary,
} from "../../../../utils/colors";
import BoxUser from "./BoxUser";
import useCountUp from "../../../../hooks/useCountUp";

const EmployeeStats = ({
  loading,
  countsByStatus,
  globalTotalCount,
  countsByProfession,
}) => {
  // GENERAL
  const { t } = useTranslation();

  //CONSTANTS

  const [globalTotal, setGlobalTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [inactive, setInactive] = useState(0);
  const [doctors, setDoctors] = useState(0);
  const [treatmentCoordinators, setTreatmentCoordinators] = useState(0);

  const animatedGlobalTotal = useCountUp(globalTotal);
  const animatedTotal = useCountUp(total);
  const animatedInactive = useCountUp(inactive);
  const animatedDoctors = useCountUp(doctors);
  const animatedCoordinators = useCountUp(treatmentCoordinators);

  // USE EFFECT

  useEffect(() => {
    if (globalTotalCount) {
      setGlobalTotal(globalTotalCount || 0);
    }
    if (countsByStatus) {
      setTotal(countsByStatus.total || 0);
      setInactive(countsByStatus.inactive || 0);
    }
    if (countsByProfession) {
      setDoctors(countsByProfession.doctor || 0);
      setTreatmentCoordinators(countsByProfession.treatmentCoordinator || 0);
    }
  }, [countsByStatus, globalTotalCount, countsByProfession]);

  // LOGS
  // console.log("countsByStatus", countsByStatus);
  // console.log("globalTotalCount", globalTotalCount);
  // console.log("countsByProfession", countsByProfession);

  return (
    <Box w="100%" px="2rem" mb={6}>
      <Flex
        w="100%"
        direction="column"
        p="0rem 0rem"
        border={`1px solid ${newBorder}`}
        borderRadius="1rem"
      >
        <Flex w="100%" pt={4} pb={4}>
          <BoxUser
            loading={loading}
            label={t("dashboard.employeesTotal")}
            data={animatedTotal}
            of={animatedGlobalTotal > 0 && animatedGlobalTotal}
            color={primary}
          />
          <BoxUser
            loading={loading}
            label={t("dashboard.employeesDoctors")}
            data={animatedDoctors}
            color="#A0D1FF"
          />
          <BoxUser
            loading={loading}
            label={t("dashboard.employeesCoordinators")}
            data={animatedCoordinators}
            color="#65C790"
          />
          <BoxUser
            loading={loading}
            label={t("dashboard.employeesInactive")}
            data={animatedInactive}
            color="#D6D6D7"
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default EmployeeStats;
