// IMPORT START
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createProfessionGroup } from "../../../../actions/professionGroupActions";
import { backgroundLight } from "../../../../utils/colors";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Flex,
  Button,
  HStack,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  textPrimary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import TreatmentStepSelect from "../../../../components/select/TreatmentStepSelect";
import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlSwitch from "../../../../components/input/FormControlSwitch";
import FormControlRolePermission from "../../../../components/input/FormControlRolePermission";
import { PROFESSIONGROUP_CREATE_RESET } from "../../../../constants/professionGroupConstants";

const CreateProfessionGroup = ({ close }) => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // FORM
  const [name, setName] = useState("");
  const [defaultRolePermission, setDefaultRolePermission] =
    useState("employee_default");

  // HANDLERS
  const closeHandler = () => {
    close();
    dispatch({ type: PROFESSIONGROUP_CREATE_RESET });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createProfessionGroup(name, defaultRolePermission));
  };

  // TOUCHED
  const [nameTouched, setNameTouched] = useState(false);
  const [defaultRolePermissionTouched, setDefaultRolePermissionTouched] =
    useState(false);

  // ERRORS
  const isNameError = name === "";
  const isDefaultRolePermissionError = defaultRolePermission === "";

  // LOGS

  console.log("name", name);
  console.log("defaultRolePermission", defaultRolePermission);

  return (
    <>
      <Box position="relative">
        <form
          onSubmit={submitHandler}
          style={{
            height: "100%",
          }}
        >
          <SidePanelHeader
            close={close}
            title={t("professionGroup.createProfessionGroup")}
          />

          <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="7rem 0 4rem"
            >
              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("professionGroup.details")}
              </Heading>

              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1.5rem"
              >
                <HStack spacing={4} w="100%">
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.professionGroupName")}
                    value={name}
                    placeholder={t("placeholder.professionGroupName")}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameTouched(true);
                    }}
                    isTouched={nameTouched}
                    isError={isNameError}
                    w="50%"
                    errorMessage={t("formError.professionGroupNameRequired")}
                  />

                  <FormControlRolePermission
                    isRequired
                    label={t("form.defaultRolePermission")}
                    value={defaultRolePermission}
                    onChange={(selectedOption) => {
                      setDefaultRolePermission(selectedOption.value);
                      setDefaultRolePermissionTouched(true);
                    }}
                    isTouched={defaultRolePermissionTouched}
                    isError={isDefaultRolePermissionError}
                    w="50%"
                    errorMessage={t("formError.defaultRolePermissionRequired")}
                  />
                </HStack>
              </Flex>
            </Flex>

            <Flex
              mt={4}
              justify="center"
              direction="column"
              borderTop={`1px solid ${newBorder}`}
              p="1.5rem 0"
              position="relative"
              zIndex={0}
              bg={white}
              bottom="0"
              w="100%"
            >
              <Flex justify="flex-end">
                <Button
                  minWidth="10rem"
                  mr={4}
                  bg={backgroundLight}
                  size="lg"
                  color={textSecondary}
                  fontSize="md"
                  borderRadius="10rem"
                  onClick={closeHandler}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  minWidth="10rem"
                  type="submit"
                  size="lg"
                  bg={primary}
                  color={white}
                  fontSize="md"
                  borderRadius="10rem"
                  // isDisabled={!firstName.trim() || !lastName.trim()}
                >
                  {t("professionGroup.createProfessionGroup")}
                </Button>
              </Flex>
            </Flex>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default CreateProfessionGroup;
