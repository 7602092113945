import React, { useEffect, useState } from "react";
import {
  Flex,
  Button,
  Input,
  Icon,
  InputGroup,
  InputLeftElement,
  Heading,
  Stack,
  Text,
  useToast,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spinner,
  Slide,
  Fade,
} from "@chakra-ui/react";
import { columns } from "../../columns/PatientTreatmentPlanColumns";
import {
  newBorder,
  backgroundLight,
  textSecondary,
  textPrimary,
  white,
  primary,
} from "../../../../../utils/colors";
import dayjs from "dayjs";
import TableContainerAlt from "../../../../../components/table/TableContainerAlt";
import { FaSistrix } from "react-icons/fa6";

import { useTranslation } from "react-i18next";
import SidePanelTop from "../../../../../components/sidepanel/SidePanelTop";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
import EditPatientTreatmentPlan from "../../sidepanels/EditPatientTreatmentPlan";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTreatmentPlanStatus,
  approveTreatmentPlan,
  cancelTreatmentPlan,
  deleteTreatmentPlan,
  sendTreatmentPlanEmail,
  updateTreatmentPlanAvailablePublic,
} from "../../../../../actions/treatmentPlanActions";
import {
  TREATMENTPLAN_DETAILS_RESET,
  TREATMENTPLAN_DELETE_RESET,
} from "../../../../../constants/treatmentPlanConstants";
import useRoleCheck from "../../../../../hooks/useRoleCheck";
import Table from "../../../../../components/shared/Table";
// import ActionsModal from "../../../../../components/modals/ActionsModal";

const PatientPlans = ({
  loading,
  error,
  plans,
  buttonHandler,
  userInfo,
  patientId,
  dispatch,
  successUpdateAvailablePublic,
}) => {
  //GENERAL
  const { t } = useTranslation();
  const translate = (key) => t(key);
  const toast = useToast(); // Make sure to call useToast() at the top of your component

  const [searchTerm, setSearchTerm] = useState("");
  const [sidePanelModalOpen, setSidePanelModalOpen] = useState(false);
  const [planId, setPlanId] = useState("");

  // MULTI SELECT
  const [selectedPlanIds, setSelectedPlanIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState({ message: "", type: "" });
  const [isSendingModalOpen, setIsSendingModalOpen] = useState(false);
  const [sendingStatus, setSendingStatus] = useState({ message: "", type: "" });

  const handleDeleteSelectedPlans = () => {
    setIsDeleteModalOpen(true); // Show the modal
    const messageCustom = t("common.deletingSelectedPlans");
    setDeleteStatus({ message: messageCustom, type: "loading" });

    selectedPlanIds.forEach((id, index) => {
      dispatch(deleteTreatmentPlan(id));

      // Optional: Delay the closing of the modal to ensure user sees the message
      const timer = setTimeout(() => {
        setIsDeleteModalOpen(false); // Close the modal after actions are dispatched
        clearTimeout(timer);
        dispatch({ type: TREATMENTPLAN_DELETE_RESET });
        toast({
          title: t("toasts.selectedPlansDeleted"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }, 1500 + index * 500); // Adjust time as needed
    });
  };

  const handleSendEmailSelectedPlans = () => {
    setIsSendingModalOpen(true); // Show the modal
    const messageCustom = t("common.sendingEmailPatientSelectedPlans");
    setSendingStatus({ message: messageCustom, type: "loading" });

    selectedPlanIds.forEach((id) => {
      dispatch(sendTreatmentPlanEmail(id));

      // Optional: Delay the closing of the modal to ensure user sees the message
      const timer = setTimeout(() => {
        setIsSendingModalOpen(false); // Close the modal after actions are dispatched
        clearTimeout(timer);
      }, 3500); // Adjust time as needed
    });
    dispatch({ type: TREATMENTPLAN_DELETE_RESET });
  };

  // ROLES
  const isDoctor = useRoleCheck("employee_doctor");
  const isAdmin = useRoleCheck("admin");

  // REDUX
  const treatmentPlanUpdateStatus = useSelector(
    (state) => state.treatmentPlanUpdateStatus
  );
  const { success: successUpdate } = treatmentPlanUpdateStatus;

  const treatmentPlanApprove = useSelector(
    (state) => state.treatmentPlanApprove
  );
  const { success: successApprove } = treatmentPlanApprove;

  const treatmentPlanSendEmail = useSelector(
    (state) => state.treatmentPlanSendEmail
  );
  const {
    loading: loadingSendEmail,
    error: errorSendEmail,
    success: successSendEmail,
  } = treatmentPlanSendEmail;

  const treatmentPlanDelete = useSelector((state) => state.treatmentPlanDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = treatmentPlanDelete;

  // HANDLERS

  const openSidePanelModalHandler = (id) => {
    setSidePanelModalOpen(true);
    setPlanId(id);
  };

  const closeSidePanelModal = () => {
    setSidePanelModalOpen(false);
    setPlanId(null);
    dispatch({ type: TREATMENTPLAN_DETAILS_RESET });
  };

  const userId = userInfo._id;

  useEffect(() => {
    if (loadingDelete) {
    } else if (errorDelete) {
      toast({
        title: "Error",
        description: "Couldn't delete the selected plans.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteModalOpen(false); // Close the modal on error
    } else if (successDelete) {
      setIsDeleteModalOpen(false); // Close the modal on success
    }
  }, [loadingDelete, errorDelete, successDelete, toast]);

  // USE EFFECT

  useEffect(() => {
    setSelectedItems(
      plans.filter((plan) => selectedPlanIds.includes(plan._id))
    );
  }, [selectedPlanIds]);

  useEffect(() => {
    if (selectedPlanIds && selectedPlanIds.length > 0) {
      setIsActionsModalOpen(true);
    } else {
      setIsActionsModalOpen(false);
    }
  }, [selectedPlanIds]);

  // console.log("planId", planId);
  // console.log("selectedPlanIds", selectedPlanIds);
  // console.log("selectedItems", selectedItems);
  // console.log("treatmentPlans", plans);
  // console.log("actionModalOpen", isActionsModalOpen);

  return (
    <>
      <Slide
        direction="bottom"
        in={isDeleteModalOpen}
        style={{
          zIndex: 9000,
          width: "80%",
          left: "initial",
          right: "10%",
          paddingRight: "0rem",
          bottom: isDeleteModalOpen ? "2rem" : "0rem",
          boxShadow: `1px 1px 10px rgba(0, 0, 0, 0.2)`,
          borderRadius: "1rem",
          overflow: "hidden",
          p: "0",
        }}
      >
        <Fade in={isDeleteModalOpen}>
          <Box position="relative" bottom="0" right="0" zIndex="overlay">
            <Flex
              bg={white}
              p={0}
              align="center"
              justify="space-between"
              borderRadius="md"
              w="100%"
              maxW="100%" // Adjust the width as needed, ensuring it doesn't go full width
              h="80px"
              // shadow="lg"
            >
              <Flex align="center" justify="center" w="100%">
                {deleteStatus.type === "loading" ? (
                  <Flex align="center">
                    <Spinner mr={3} />{" "}
                    <Text color={textSecondary} fontSize="sm" fontWeight="600">
                      {deleteStatus.message}...
                    </Text>
                  </Flex>
                ) : (
                  <Text color={textSecondary} fontSize="sm" fontWeight="600">
                    {deleteStatus.message}
                  </Text>
                )}
              </Flex>
            </Flex>
          </Box>
        </Fade>
      </Slide>

      <Slide
        direction="bottom"
        in={isSendingModalOpen}
        style={{
          zIndex: 9000,
          width: "80%",
          left: "initial",
          right: "10%",
          paddingRight: "0rem",
          bottom: isSendingModalOpen ? "2rem" : "0rem",
          boxShadow: `1px 1px 10px rgba(0, 0, 0, 0.2)`,
          borderRadius: "1rem",
          overflow: "hidden",
          p: "0",
        }}
      >
        <Fade in={isSendingModalOpen}>
          <Box position="relative" bottom="0" right="0" zIndex="overlay">
            <Flex
              bg={white}
              p={0}
              align="center"
              justify="space-between"
              borderRadius="md"
              w="100%"
              maxW="100%" // Adjust the width as needed, ensuring it doesn't go full width
              h="80px"
              // shadow="lg"
            >
              <Flex align="center" justify="center" w="100%">
                {sendingStatus.type === "loading" ? (
                  <Flex align="center">
                    <Spinner mr={3} />{" "}
                    <Text color={textSecondary} fontSize="sm" fontWeight="600">
                      {sendingStatus.message}
                    </Text>
                  </Flex>
                ) : (
                  <Text color={textSecondary} fontSize="sm" fontWeight="600">
                    {sendingStatus.message}
                  </Text>
                )}
              </Flex>
            </Flex>
          </Box>
        </Fade>
      </Slide>

      {/* SIDEPANELS  */}
      <SidePanel
        isTop
        isLong
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("patient.createTreatmentPlan")}
        noBorder={true}
      >
        <EditPatientTreatmentPlan
          // userId={userId}
          planId={planId}
          close={closeSidePanelModal}
          currentUser={userInfo}
          patientId={patientId}
        />
      </SidePanel>

      <Flex w="100%" direction="column" m="2.5rem 0 0">
        <Heading
          fontSize="1.125rem"
          color={textPrimary}
          fontWeight="600"
          p="0 0 0.75rem"
        >
          {t("patient.treatmentPlans")}
        </Heading>
        <Flex
          w="100%"
          direction="column"
          p="0 0rem 0 0"
          bg={white}
          borderRadius="0rem"
        >
          <Flex
            w="100%"
            align="center"
            justify="space-between"
            p="0rem 0rem"
            bg={white}
            borderTopLeftRadius="1rem"
            mb="1rem"
            borderTopRightRadius="1rem"
          >
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={FaSistrix} color="gray.600" />}
              />
              <Input
                bg={white}
                borderRadius="10.75rem"
                h="2.5rem"
                placeholder={t("treatmentPlan.search")}
                value={searchTerm}
                border={`1px solid ${newBorder}`}
                maxWidth="460px"
                fontSize="sm"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>

            <Button
              fontSize="sm"
              borderRadius="10rem"
              bg={primary}
              color={white}
              p="1rem 2.5rem"
              ml={4}
              onClick={buttonHandler}
            >
              {t("common.createNew")}
            </Button>
          </Flex>
          <Flex
            bg={white}
            w="100%"
            p="0 0rem 0rem"
            border={plans.length > 0 ? `1px solid ${newBorder}` : "none"}
            borderRadius="1rem"
            overflow="hidden"
          >
            <Table.Container
              // className="multi"
              columns={columns(
                dispatch,
                translate,
                updateTreatmentPlanStatus,
                approveTreatmentPlan,
                cancelTreatmentPlan,
                deleteTreatmentPlan,
                sendTreatmentPlanEmail,
                isDoctor,
                isAdmin,
                updateTreatmentPlanAvailablePublic,
                successUpdateAvailablePublic
              )}
              data={plans}
              loading={loading}
              error={error}
              // navigate={navigate}
              navigateTo="/treatment-plans"
              // noItemsButtonHandler={openSidePanelModal}
              onRowClick={(planId) => {
                openSidePanelModalHandler(planId);
              }}
              noItemsTitle={t("noItems.noTreatmentPlans")}
              noItemsDescription={t("noItems.noTreatmentPlansText")}
              noItemsButtonLabel={t("noItems.noTreatmentPlansButton")}
              // noItemsButtonHandler={openCreatePatientTreatmentPlanSidePanel}
              // sortField={sortField}
              // sortOrder={sortOrder}
              selectedPlanIds={selectedPlanIds}
              setSelectedPlanIds={setSelectedPlanIds}
              isSmall
              deleteMultiHandler={handleDeleteSelectedPlans}
              sendMultiHanlder={handleSendEmailSelectedPlans}
            />

            {/* <TableContainerAlt
                columns={columns(
                  dispatch,
                  translate,
                  updateTreatmentPlanStatus,
                  approveTreatmentPlan,
                  cancelTreatmentPlan,
                  deleteTreatmentPlan,
                  sendTreatmentPlanEmail,
                  isDoctor,
                  isAdmin
                )}
                data={plans}
                loading={loading}
                error={error}
                isSinglePage={true}
                onRowClick={(row) => {
                  openSidePanelModalHandler();
                  setPlanId(row._id);
                }}
                noItemsTitle={t("noItems.noPatientTreatmentPlans")}
                noItemsDescription={t("noItems.noPatientTreatmentPlansText")}
                noItemsButtonLabel={t("noItems.noPatientTreatmentPlansButton")}
                noItemsButtonHandler={buttonHandler}
              /> */}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default PatientPlans;
