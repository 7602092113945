import React from "react";
import { Box, FormControl, FormLabel, Input, Button } from "@chakra-ui/react";
import { white, newBorder, dark } from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import PasswordInput from "../../../../../components/input/PasswordInput";
import { motion } from "framer-motion";
import Agreement from "../../../partials/Agreement";

const Step1Form = ({
  clinicName,
  handleClinicNameChange,
  email,
  handleEmailChange,
  password,
  handlePasswordChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        as={motion.div}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        mt={12}
      >
        <FormControl>
          <FormLabel fontSize="1rem" htmlFor="clinicName">
            {t("form.clinicName")}
          </FormLabel>
          <Input
            id="clinicName"
            type="text"
            value={clinicName}
            bg={white}
            onChange={handleClinicNameChange}
            size="lg"
            h="3.5rem"
            fontSize="1rem"
            fontWeight="500"
            border={`1px solid ${newBorder}`}
            borderRadius="1rem"
            // placeholder={t("placeholder.clinicName")}
            mb={4}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize="1rem" htmlFor="email">
            {t("form.email")}
          </FormLabel>
          <Input
            id="email"
            type="email"
            value={email}
            bg={white}
            autoComplete="off"
            onChange={handleEmailChange}
            size="lg"
            h="3.5rem"
            fontSize="1rem"
            fontWeight="500"
            border={`1px solid ${newBorder}`}
            borderRadius="1rem"
            // placeholder={t("placeholder.email")}
            mb={4}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize="1rem" htmlFor="password">
            {t("form.password")}
          </FormLabel>
          <PasswordInput value={password} onChange={handlePasswordChange} />
        </FormControl>
      </Box>

      <Button
        type="submit"
        mt={8}
        mb={4}
        h="3.5rem"
        borderRadius="10rem"
        bg={dark}
        color={white}
        border={`1px solid ${dark}`}
        textAlign="center"
        w="100%"
        _hover={{
          bg: "rgba(13, 12, 34, 0.8)",
          color: white,
          border: `rgba(13, 12, 34, 0.8)`,
        }}
      >
        {t("common.register")}
      </Button>

      <Agreement />
    </>
  );
};

export default Step1Form;
