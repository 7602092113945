import React from "react";
import { Flex, Text, Icon } from "@chakra-ui/react";
import { FaRegCircleDot } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { white } from "../../utils/colors";
import { shade } from "polished";
import * as Icons from "react-icons/fa6";
import { textPrimary } from "../../utils/colors";

const ColumnStatusCountEntry = ({ bg, color, icon, count }) => {
  // console.log("icon", icon);
  const IconComponent = Icons[icon];

  if (!IconComponent) {
    console.error(`Icon ${icon} not found in 'react-icons/tb'`);
    return null; // Or handle this case as needed
  }

  return (
    <Flex align="center" justify="center" p="0.3rem 0">
      <Flex
        align="center"
        justify="center"
        bg={bg}
        h="1.125rem"
        w="1.125rem"
        mr={2}
        borderRadius="0.5rem"
      >
        <IconComponent color={color} size="10px" />
      </Flex>
      <Text
        fontSize="0.875rem"
        fontWeight="500"
        lineHeight="1"
        color={textPrimary}
        style={{
          pointerEvents: "none",
        }}
      >
        {count}
      </Text>
    </Flex>
  );
};

export default ColumnStatusCountEntry;
