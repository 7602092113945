// IMPORT START
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createServiceGroup } from "../../../../actions/serviceGroupActions";
import { backgroundLight } from "../../../../utils/colors";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Flex,
  Button,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  textPrimary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import TreatmentStepSelect from "../../../../components/select/TreatmentStepSelect";
import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlSwitch from "../../../../components/input/FormControlSwitch";
import { SERVICEGROUP_CREATE_RESET } from "../../../../constants/serviceGroupConstants";

const CreateServiceGroup = ({ close }) => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // FORM
  const [customId, setCustomId] = useState("");
  const [name, setName] = useState("");
  const [isConsulting, setIsConsulting] = useState(false);
  const [treatmentStep, setTreatmentStep] = useState("");

  // HANDLERS
  const closeHandler = () => {
    close();
    dispatch({ type: SERVICEGROUP_CREATE_RESET });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createServiceGroup(name, isConsulting));
  };

  // TOUCHED
  const [nameTouched, setNameTouched] = useState(false);

  // ERRORS
  const isNameError = name === "";

  // LOGS

  console.log("customId", customId);
  console.log("name", name);
  console.log("treatmentStep", treatmentStep);
  console.log("isConsulting", isConsulting);

  return (
    <>
      <Box position="relative">
        <form
          onSubmit={submitHandler}
          style={{
            height: "100%",
          }}
        >
          <SidePanelHeader
            close={close}
            title={t("serviceGroup.createServiceGroup")}
          />

          <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="7rem 0 4rem"
            >
              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("serviceGroup.details")}
              </Heading>

              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1.5rem"
              >
                <FormControlDefault
                  type="text"
                  isRequired
                  label={t("form.serviceGroupName")}
                  value={name}
                  placeholder={t("placeholder.serviceGroupName")}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameTouched(true);
                  }}
                  isTouched={nameTouched}
                  isError={isNameError}
                  w="100%"
                  errorMessage={t("formError.serviceGroupNameRequired")}
                />

                <FormControlSwitch
                  label={t("form.isConsulting")}
                  isChecked={isConsulting}
                  onChange={() => setIsConsulting(!isConsulting)}
                  isTouched={true}
                  isError={false}
                  w="100%"
                />

                {/* <FormControl mb={4}>
                  <FormLabel fontSize="sm">{t("form.treatmentStep")}</FormLabel>
                  <TreatmentStepSelect
                    value={treatmentStep}
                    onChange={(selectedOption) => {
                      setTreatmentStep(selectedOption.value);
                    }}
                  />
                </FormControl> */}
              </Flex>
            </Flex>

            <Flex
              mt={4}
              justify="center"
              direction="column"
              borderTop={`1px solid ${newBorder}`}
              p="1.5rem 0"
              position="relative"
              zIndex={0}
              bg={white}
              bottom="0"
              w="100%"
            >
              <Flex justify="flex-end">
                <Button
                  minWidth="10rem"
                  mr={4}
                  bg={backgroundLight}
                  size="lg"
                  color={textSecondary}
                  fontSize="md"
                  borderRadius="10rem"
                  onClick={closeHandler}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  minWidth="10rem"
                  type="submit"
                  size="lg"
                  bg={primary}
                  color={white}
                  fontSize="md"
                  borderRadius="10rem"
                  // isDisabled={!firstName.trim() || !lastName.trim()}
                >
                  {t("serviceGroup.createServiceGroup")}
                </Button>
              </Flex>
            </Flex>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default CreateServiceGroup;
