import React from "react";
import { Button, Text } from "@chakra-ui/react";
import { primary, textPrimary, white, newBorder } from "../../utils/colors";

const EmailButton = ({ onClick, label }) => {
  return (
    <Button
      mt={4}
      mb={4}
      h="3.5rem"
      borderRadius="10rem"
      bg={white}
      color={textPrimary}
      border={`1px solid ${newBorder}`}
      textAlign="center"
      w="100%"
      _hover={{
        bg: primary,
        color: white,
        border: primary,
      }}
      onClick={onClick} // Add the onClick prop here
    >
      <Text>{label}</Text>
    </Button>
  );
};

export default EmailButton;
