import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listPublicPatientTreatmentPlanDetails } from "../../../../actions/publicActions";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  Text,
  Spinner,
  Box,
  Heading,
  useToast,
  IconButton,
  Stack,
  Grid,
  GridItem,
  Image,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  white,
  newBorder,
  textPrimary,
  textSecondary,
  buttonAltYellow,
  backgroundLight,
  buttonGreen,
  buttonGreenText,
  buttonAltSuccess,
  buttonRed,
  buttonRedText,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import mongoose from "mongoose";
import Header from "./partials/Header";
import ServiceList from "./partials/ServiceList";
import { calculateTotalPriceForService } from "../../../../utils/calculations";
import useGenerateTreatmentPlanPdf from "../../../../hooks/useGenerateTreatmentPlanPdf";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import TenantPublicContactInfo from "../single/partials/TenantPublicContactInfo";
import PublicTreatmentPlanInfo from "./partials/PublicTreatmentPlanInfo";
import ServiceListTreatmentPlanPublic from "./partials/ServiceListTreatmentPlanPublic";
import noServices from "../../../../assets/images/empty-box.png";
import StatusButton from "../../../../components/buttons/StatusButton";
import StatusButtonMobile from "../../../../components/buttons/StatusButtonMobile";
import { FaCircleCheck } from "react-icons/fa6";
import { shade } from "polished";
import useIsMobile from "../../../../hooks/useIsMobile";
import StatusBadge from "../../../../components/badges/StatusBadge";
import ServiceListTreatmentPlanPublicMobile from "./partials/ServiceListTreatmentPlanPublicMobile";
import { downloadTreatmentPlanPdfPublic } from "../../../../actions/publicActions";
import { PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_RESET } from "../../../../constants/publicConstants";

const ViewPatientTreatmentPlanPublic = ({
  tenantId,
  patientId,
  planId,
  close,
  handleApprove,
  hasApprovedPlan,
  handleCancel,
}) => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();

  // PDF
  const generatePdf = useGenerateTreatmentPlanPdf();

  // MOBILE
  const isMobile = useIsMobile();

  // CONSTANTS
  const today = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(today);
  const [patient, setPatient] = useState({});
  const [employee, setEmployee] = useState("");
  const [status, setStatus] = useState("");
  const [coordinator, setCoordinator] = useState("");
  const [services, setServices] = useState([]);
  const [isFormValid, setIsFormValid] = useState(true);
  const [totalTpPrice, setTotalTpPrice] = useState(0);
  const [loadingDisplay, setLoadingDisplay] = useState(true);

  const [addServiceModalOpen, setAddServiceModalOpen] = useState(false);

  // REDUX
  const publicPatientTreatmentPlanDetails = useSelector(
    (state) => state.publicPatientTreatmentPlanDetails
  );
  const { loading, error, treatmentPlan } = useSelector(
    (state) => state.publicPatientTreatmentPlanDetails
  );

  const publicPatientDownloadPdfTreatmentPlan = useSelector(
    (state) => state.publicPatientDownloadPdfTreatmentPlan
  );
  const {
    loading: loadingDownloadPdf,
    error: errorDownloadPdf,
    pdf,
  } = useSelector((state) => state.publicPatientDownloadPdfTreatmentPlan);

  // HANDLERS
  // Function to handle closing of the modal
  const handleClose = () => {
    close(); // Your existing close function
    navigate(-1);
  };

  const handleDownloadButtonClick = (planId) => {
    console.log("pdf", pdf);
    dispatch(downloadTreatmentPlanPdfPublic(tenantId, patientId, planId));

    console.log("Downloading PDF...");
    console.log("tenantId", tenantId);
    console.log("patientId", patientId);
    console.log("planId", planId);
  };

  // USEEFFECTS

  useEffect(() => {
    if (planId) {
      dispatch(
        listPublicPatientTreatmentPlanDetails(tenantId, patientId, planId)
      );
    }
  }, [dispatch, tenantId, patientId, planId]);

  // New useEffect hook
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoadingDisplay(false);
    }, 500); // CHANGE THIS TO HAVE A LONGER LOADING TIME

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    // Push a new entry onto the history stack
    window.history.pushState({ modalOpen: true }, "");

    // Event listener for the popstate event
    const handlePopState = (event) => {
      // If the state indicates our modal is open, close it
      if (event.state?.modalOpen) {
        handleClose();
      }
    };

    window.addEventListener("popstate", handlePopState);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("popstate", handlePopState);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  useEffect(() => {
    if (pdf) {
      const url = window.URL.createObjectURL(new Blob([pdf]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `TreatmentPlan-${planId}.pdf`); // Use a state or prop for `planId` if necessary
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();

      console.log("PDF downloaded successfully");

      dispatch({ type: PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_RESET });
    } else if (errorDownloadPdf) {
      console.error("Failed to download PDF:", errorDownloadPdf);
      // Show error to the user
    }
  }, [pdf, errorDownloadPdf]); // Add dependencies to ensure the effect runs when they change

  // LOGS
  // console.log("tenantId", tenantId);
  // console.log("patientId", patientId);
  // console.log("planId", planId);
  // console.log("treatmentPlan", treatmentPlan);
  // console.log("status", treatmentPlan.status);

  return (
    <>
      {loading || loadingDisplay ? (
        <Flex justify="center" align="center" h="100%">
          <Spinner color={primary} />
        </Flex>
      ) : error ? (
        <Flex
          direction="column"
          justify="center"
          align="center"
          margin="0 auto 2rem"
          p="0 0 0rem"
          w="100%"
          h="100%"
          borderRadius="0.75rem"

          // border={isSinglePage ? `1px dashed ${newBorder}` : "none"}
        >
          <Image w="initial" h="4rem" m="1rem 0 0" src={noServices} />
          <Text mt={4} color={textPrimary} fontSize="lg" fontWeight="600">
            {t("treatmentPlan.notFound")}
          </Text>
          <Text mt={1} color={textSecondary} fontSize="sm">
            {t("treatmentPlan.notFoundText")}
          </Text>
        </Flex>
      ) : (
        <>
          <Box position="relative" h="100%" w="100%" overflow="scroll">
            <form
              //   onSubmit={submitHandler}
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <Flex
                justify="space-between"
                align="center"
                p={["1rem 2rem", "1.5rem 2rem 1.5rem", "1.5rem 2rem 1.5rem"]}
                borderBottom={`1px solid ${newBorder}`}
                mb="1rem"
                h={["initial", "initial", "initial"]}
                position="fixed"
                bg={white}
                w="100%"
                zIndex={1}
              >
                <Flex align="center">
                  <IconButton
                    bg="none"
                    border={`1px solid ${newBorder}`}
                    color={textSecondary}
                    onClick={close}
                    icon={<FaArrowRightFromBracket />}
                    borderRadius="0.75rem"
                    fontSize="0.75rem"
                  />

                  <Stack gap={0} ml={4}>
                    {!isMobile ? (
                      <Flex align="center">
                        <Heading
                          fontSize="1.125rem"
                          color={textPrimary}
                          fontWeight="600"
                          m={0}
                        >
                          {t("treatmentPlan.viewTreatmentPlanPublic")}
                        </Heading>
                      </Flex>
                    ) : (
                      <>
                        <Flex align="center">
                          <Heading fontSize="1rem" fontWeight="600">
                            {t("treatmentPlan.treatmentPlan")}
                          </Heading>
                          <Flex bg={newBorder} h="16px" w="1px" mx="0.5rem" />
                          <Text fontSize="rem" fontWeight="600" mr={4}>
                            {treatmentPlan &&
                              treatmentPlan.treatmentPlanID &&
                              treatmentPlan.treatmentPlanID}
                          </Text>
                        </Flex>
                        <Flex align="center">
                          <Text
                            color={textSecondary}
                            fontSize="sm"
                            fontWeight="500"
                          >
                            {t("patient.patient")}:
                            <Text
                              as="span"
                              color={textPrimary}
                              ml={2}
                              fontWeight="500"
                            >
                              {treatmentPlan &&
                                treatmentPlan.patient &&
                                treatmentPlan.patient.firstName +
                                  " " +
                                  treatmentPlan.patient.lastName}
                            </Text>
                          </Text>

                          {/* <StatusBadge
                            status={treatmentPlan.status}
                            options="treatmentPublic"
                            isSmall
                          /> */}
                        </Flex>
                      </>
                    )}
                  </Stack>
                </Flex>
                {!isMobile && (
                  <Flex align="center" justify="flex-end" gap={4}>
                    <Button
                      mr={4}
                      onClick={() =>
                        handleDownloadButtonClick(treatmentPlan._id)
                      }
                      bg={buttonAltYellow}
                      color={textPrimary}
                      border={`1px solid ${newBorder}`}
                      borderRadius="10rem"
                      fontWeight="500"
                      fontSize="md"
                      size="lg"
                      role="group"
                      _hover={{
                        bg: secondary,
                        color: white,
                      }}
                      isDisabled={false}
                    >
                      {loadingDownloadPdf ? (
                        <Flex align="center">
                          <Spinner
                            color={textPrimary}
                            _groupHover={{
                              color: white,
                            }}
                            size="sm"
                            mr={2}
                          />
                          {t("common.downloading")}
                        </Flex>
                      ) : (
                        t("common.downloadPdf")
                      )}
                    </Button>

                    <Tooltip
                      p="0.5rem"
                      bg={secondary}
                      borderRadius="1rem"
                      color={white}
                      label={t("treatmentPlan.hasApprovedPlan")}
                      isDisabled={
                        status === "cancelled" ||
                        status === "approved" ||
                        status === "ongoing" ||
                        status === "planning" ||
                        status === "consulting" ||
                        !hasApprovedPlan
                      }
                    >
                      <Button
                        borderRadius="10rem"
                        bg={buttonGreen}
                        color={buttonGreenText}
                        fontSize="sm"
                        size="lg"
                        fontWeight="500"
                        _hover={{
                          bg: buttonAltSuccess,
                          color: textPrimary,
                        }}
                        onClick={() => handleApprove(treatmentPlan)}
                        isDisabled={
                          hasApprovedPlan ||
                          treatmentPlan?.status === "cancelled"
                        }
                      >
                        <Icon as={FaCircleCheck} mr={2} fontSize="0.75rem" />
                        {t("treatmentPlan.approve")}
                      </Button>
                    </Tooltip>
                    {/* <StatusButton
                    isUnclickable
                    currentStatus={treatmentPlan?.status}
                    options="treatmentPublic"
                  /> */}
                    {/* <Button
                    mr={4}
                    onClick={() =>
                      generatePdf(treatmentPlan, totalTpPrice, true)
                    }
                    bg={buttonAltYellow}
                    color={textPrimary}
                    border={`1px solid ${newBorder}`}
                    borderRadius="10rem"
                    fontWeight="500"
                    fontSize="md"
                    size="lg"
                    _hover={{
                      bg: secondary,
                      color: white,
                    }}
                    isDisabled={true}
                  >
                    {t("common.downloadPdf")}
                  </Button> */}
                  </Flex>
                )}
              </Flex>

              <Box w="100%">
                <Flex
                  direction="column"
                  w="100%"
                  align="flex-start"
                  justify="center"
                  p={["66px 1.5rem 0rem", "7rem 2rem 2rem", "7rem 2rem 2rem"]}
                >
                  <Flex
                    border={`0px dashed ${newBorder}`}
                    p={[
                      "1rem 0rem 4rem",
                      "1rem 3.5rem 4rem",
                      "1rem 3.5rem 4rem",
                    ]}
                    borderRadius="1rem"
                    w="100%"
                    direction="column"
                  >
                    {/* <Flex
                      align="center"
                      justify="space-between"
                      w="100%"
                      borderBottom={`1px solid ${newBorder}`}
                      p="0.5rem 0 1.5rem"
                    >
                      <TenantPublicInfo tenantID={tenantId} />
                      <Stack gap={0.5} textAlign="right">
                        <Text
                          fontSize="xs"
                          color={textSecondary}
                          fontWeight="500"
                        >
                          {t("patient.createdBy")}:
                        </Text>
                        <Text fontSize="13px" ml={2} fontWeight="500">
                          Natalija Bykova
                        </Text>
                      </Stack>
                    </Flex> */}

                    <Flex
                      justify={[
                        "space-between",
                        "space-between",
                        "space-between",
                      ]}
                      w="100%"
                      m="0rem 0 1rem"
                      direction={["column", "row", "row"]}
                    >
                      {!isMobile ? (
                        <>
                          <PublicTreatmentPlanInfo
                            t={t}
                            treatmentPlan={treatmentPlan}
                          />
                          <Stack gap={0.5} textAlign="right">
                            <Text
                              fontSize="xs"
                              color={textSecondary}
                              fontWeight="500"
                            >
                              {t("treatmentPlan.updatedAt")}:
                            </Text>
                            <Text
                              fontSize="13px"
                              ml={2}
                              fontWeight="500"
                              textTransform="capitalize"
                            >
                              {treatmentPlan?.updatedAt
                                ? dayjs(treatmentPlan?.updatedAt).format(
                                    "YYYY MMMM DD"
                                  )
                                : "N/A"}
                            </Text>
                          </Stack>
                        </>
                      ) : (
                        <>
                          <Flex m="1rem 0 0">
                            <StatusButtonMobile
                              isUnclickable
                              currentStatus={treatmentPlan?.status}
                              options="treatmentPublic"
                            />
                          </Flex>
                        </>
                      )}
                    </Flex>

                    {isMobile ? (
                      <Flex w="100%" p="1rem 0 1rem">
                        <ServiceListTreatmentPlanPublicMobile
                          services={treatmentPlan?.services}
                          loading={loading}
                          error={error}
                          totalPrice={treatmentPlan?.totalPrice}
                        />
                      </Flex>
                    ) : (
                      <Flex w="100%" p="1rem 0 1rem">
                        <ServiceListTreatmentPlanPublic
                          services={treatmentPlan?.services}
                          loading={loading}
                          error={error}
                          totalPrice={treatmentPlan?.totalPrice}
                        />
                      </Flex>
                    )}

                    <Flex
                      w="100%"
                      align="center"
                      justify="center"
                      p="1rem"
                      m={["1rem 0 2rem", "2rem 0 3rem", "2rem 0 3rem"]}
                      direction="column"
                    >
                      <Text
                        fontSize="sm"
                        fontWeight="500"
                        color={textSecondary}
                        maxW={["100%", "56%", "56%"]}
                        m="0 auto"
                        textAlign="center"
                      >
                        {t("treatmentPlan.publicApproveText")}
                      </Text>

                      <Tooltip
                        p="0.5rem"
                        bg={secondary}
                        borderRadius="1rem"
                        color={white}
                        label={t("treatmentPlan.hasApprovedPlan")}
                        isDisabled={
                          status === "cancelled" ||
                          status === "approved" ||
                          status === "ongoing" ||
                          status === "planning" ||
                          status === "consulting" ||
                          !hasApprovedPlan
                        }
                      >
                        <Button
                          borderRadius="10rem"
                          bg={buttonGreen}
                          w={["80%", "initial", "initial"]}
                          color={buttonGreenText}
                          fontSize="md"
                          size="lg"
                          mt="1rem"
                          _hover={{
                            bg: buttonAltSuccess,
                            color: textPrimary,
                          }}
                          onClick={() => handleApprove(treatmentPlan)}
                          isDisabled={
                            hasApprovedPlan ||
                            treatmentPlan?.status === "cancelled"
                          }
                        >
                          <Icon as={FaCircleCheck} mr={2} />
                          {t("treatmentPlan.approve")}
                        </Button>
                      </Tooltip>

                      {isMobile && (
                        <>
                          <Button
                            mt="1rem"
                            w="80%"
                            borderRadius="10rem"
                            bg={buttonRed}
                            color={buttonRedText}
                            fontSize="md"
                            size="lg"
                            fontWeight="500"
                            _hover={{
                              bg: shade(0.1, buttonRed),
                              color: shade(0.1, buttonRedText),
                            }}
                            onClick={() => handleCancel(treatmentPlan)}
                            isDisabled={
                              hasApprovedPlan ||
                              treatmentPlan.status === "cancelled"
                            }
                          >
                            <Icon
                              as={FaCircleCheck}
                              mr={2}
                              fontSize="0.75rem"
                            />
                            {t("treatmentPlan.cancel")}
                          </Button>

                          <Button
                            m="2rem 0 0"
                            onClick={() =>
                              handleDownloadButtonClick(treatmentPlan._id)
                            }
                            bg={buttonAltYellow}
                            color={textPrimary}
                            border={`1px solid ${newBorder}`}
                            borderRadius="10rem"
                            fontWeight="500"
                            fontSize="md"
                            size="lg"
                            w="60%"
                            role="group"
                            _hover={{
                              bg: secondary,
                              color: white,
                            }}
                            isDisabled={false}
                          >
                            {loadingDownloadPdf ? (
                              <Flex align="center">
                                <Spinner
                                  color={textPrimary}
                                  _groupHover={{
                                    color: white,
                                  }}
                                  size="sm"
                                  mr={2}
                                />
                                {t("common.downloading")}
                              </Flex>
                            ) : (
                              t("common.downloadPdf")
                            )}
                          </Button>
                        </>
                      )}
                    </Flex>

                    <Flex gap={4} w="100%" direction={["column", "row", "row"]}>
                      <Flex w={["100%", "50%", "50%"]}>
                        <Flex
                          w="100%"
                          border={`1px solid ${newBorder}`}
                          borderRadius="1rem"
                          direction="column"
                          overflow="hidden"
                        >
                          <Flex
                            w="100%"
                            bg={backgroundLight}
                            p="1rem 1rem 1rem"
                          >
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textPrimary}
                            >
                              {t("treatmentPlan.notes")}:
                            </Text>
                          </Flex>

                          <Flex w="100%" bg={white} p="1rem 1rem 1rem">
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                            >
                              {t("treatmentPlan.noNotes")}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>

                      <Flex w={["100%", "50%", "50%"]}>
                        <Flex
                          w="100%"
                          border={`1px solid ${newBorder}`}
                          borderRadius="1rem"
                          direction="column"
                          overflow="hidden"
                        >
                          <Flex w="100%" bg={backgroundLight} p="1rem">
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textPrimary}
                            >
                              {t("treatmentPlan.notesContact")}:
                            </Text>
                          </Flex>

                          <Flex w="100%" bg={white} p="1rem" direction="column">
                            {/* <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={textSecondary}
                            >
                              {t("treatmentPlan.notesContactText")}:
                            </Text> */}
                            <TenantPublicContactInfo tenantID={tenantId} />
                            <Stack
                              m="1rem 0 0"
                              gap={2}
                              // borderTop={`1px solid ${newBorder}`}
                              // m="1.5rem 0 0"
                              // p="1.5rem 0 1rem"
                            >
                              <Flex align="center">
                                <Text
                                  color={textSecondary}
                                  fontSize="sm"
                                  fontWeight="500"
                                  mr={1}
                                >
                                  {t("professions.treatmentCoordinator")}:
                                </Text>
                                <Text
                                  color={textPrimary}
                                  fontSize="sm"
                                  fontWeight="500"
                                >
                                  {treatmentPlan?.coordinator?.firstName +
                                    " " +
                                    treatmentPlan?.coordinator?.lastName || "-"}
                                </Text>
                              </Flex>
                              {/* <Flex align="center">
                                <Text
                                  color={textSecondary}
                                  fontSize="sm"
                                  fontWeight="500"
                                  mr={1}
                                >
                                  {t("form.phone")}:
                                </Text>
                                <Text
                                  color={textPrimary}
                                  fontSize="sm"
                                  fontWeight="500"
                                >
                                  {treatmentPlan?.coordinator?.phone || "-"}
                                </Text>
                              </Flex>
                              <Flex align="center">
                                <Text
                                  color={textSecondary}
                                  fontSize="sm"
                                  fontWeight="500"
                                  mr={1}
                                >
                                  {t("form.email")}:
                                </Text>
                                <Text
                                  color={textPrimary}
                                  fontSize="sm"
                                  fontWeight="500"
                                >
                                  {treatmentPlan?.coordinator?.email || "-"}
                                </Text>
                              </Flex> */}
                            </Stack>
                          </Flex>
                        </Flex>
                      </Flex>

                      {/* {isMobile && (
                        <>
                          <Button
                            borderRadius="10rem"
                            bg={buttonRed}
                            color={buttonRedText}
                            fontSize="md"
                            size="lg"
                            fontWeight="500"
                            _hover={{
                              bg: shade(0.1, buttonRed),
                              color: shade(0.1, buttonRedText),
                            }}
                            onClick={() => handleCancel(treatmentPlan)}
                            isDisabled={
                              hasApprovedPlan ||
                              treatmentPlan.status === "cancelled"
                            }
                          >
                            <Icon
                              as={FaCircleCheck}
                              mr={2}
                              fontSize="0.75rem"
                            />
                            {t("treatmentPlan.cancel")}
                          </Button>

                          <Button
                            borderRadius="10rem"
                            bg={buttonGreen}
                            color={buttonGreenText}
                            fontSize="md"
                            size="lg"
                            fontWeight="500"
                            _hover={{
                              bg: buttonAltSuccess,
                              color: textPrimary,
                            }}
                            onClick={() => handleApprove(treatmentPlan)}
                            isDisabled={
                              hasApprovedPlan ||
                              treatmentPlan?.status === "cancelled"
                            }
                          >
                            <Icon
                              as={FaCircleCheck}
                              mr={2}
                              fontSize="0.75rem"
                            />
                            {t("treatmentPlan.approve")}
                          </Button>
                        </>
                      )} */}
                    </Flex>
                  </Flex>
                </Flex>
              </Box>

              <Flex
                mt={0}
                justify="center"
                direction="column"
                borderTop={[
                  `0px solid ${newBorder}`,
                  `1px solid ${newBorder}`,
                  `1px solid ${newBorder}`,
                ]}
                p={["0rem 2rem", "2.5rem 2rem", "2.5rem 2rem"]}
                position="relative"
                zIndex={2}
                bg={white}
                bottom="0"
                // left="33px"
                // width="calc(100% - 67px)"
                w="100%"
              >
                <Flex justify="space-between" align="center">
                  <Flex align="center"></Flex>

                  <Flex
                    align="center"
                    justify={["center", "flex-end", "flex-end"]}
                    gap={4}
                    direction={["column", "row", "row"]}
                    w="100%"
                    p={["0 0 4rem", "0", "0"]}
                  >
                    <Button
                      bg={white}
                      color={textSecondary}
                      border={`1px solid ${newBorder}`}
                      borderRadius="10rem"
                      fontWeight="500"
                      fontSize="md"
                      size="lg"
                      onClick={close}
                    >
                      {t("button.close")}
                    </Button>

                    {!isMobile && (
                      <>
                        <Button
                          mr={0}
                          onClick={() =>
                            handleDownloadButtonClick(treatmentPlan._id)
                          }
                          bg={buttonAltYellow}
                          color={textPrimary}
                          border={`1px solid ${newBorder}`}
                          borderRadius="10rem"
                          fontWeight="500"
                          fontSize="md"
                          size="lg"
                          role="group"
                          _hover={{
                            bg: secondary,
                            color: white,
                          }}
                          isDisabled={false}
                        >
                          {loadingDownloadPdf ? (
                            <Flex align="center">
                              <Spinner
                                color={textPrimary}
                                _groupHover={{
                                  color: white,
                                }}
                                size="sm"
                                mr={2}
                              />
                              {t("common.downloading")}
                            </Flex>
                          ) : (
                            t("common.downloadPdf")
                          )}
                        </Button>

                        <Tooltip
                          p="0.5rem"
                          bg={secondary}
                          borderRadius="1rem"
                          color={white}
                          label={t("treatmentPlan.cantCancel")}
                          isDisabled={true}
                        >
                          <Button
                            borderRadius="10rem"
                            bg={buttonRed}
                            color={buttonRedText}
                            fontSize="md"
                            size="lg"
                            fontWeight="500"
                            _hover={{
                              bg: shade(0.1, buttonRed),
                              color: shade(0.1, buttonRedText),
                            }}
                            onClick={() => handleCancel(treatmentPlan)}
                            isDisabled={
                              hasApprovedPlan ||
                              treatmentPlan.status === "cancelled"
                            }
                          >
                            <Icon
                              as={FaCircleCheck}
                              mr={2}
                              fontSize="0.75rem"
                            />
                            {t("treatmentPlan.cancel")}
                          </Button>
                        </Tooltip>
                        <Tooltip
                          p="0.5rem"
                          bg={secondary}
                          borderRadius="1rem"
                          color={white}
                          label={t("treatmentPlan.hasApprovedPlan")}
                          isDisabled={
                            status === "cancelled" ||
                            status === "approved" ||
                            status === "ongoing" ||
                            status === "planning" ||
                            status === "consulting" ||
                            !hasApprovedPlan
                          }
                        >
                          <Button
                            borderRadius="10rem"
                            bg={buttonGreen}
                            color={buttonGreenText}
                            fontSize="md"
                            size="lg"
                            fontWeight="500"
                            _hover={{
                              bg: buttonAltSuccess,
                              color: textPrimary,
                            }}
                            onClick={() => handleApprove(treatmentPlan)}
                            isDisabled={
                              hasApprovedPlan ||
                              treatmentPlan?.status === "cancelled"
                            }
                          >
                            <Icon
                              as={FaCircleCheck}
                              mr={2}
                              fontSize="0.75rem"
                            />
                            {t("treatmentPlan.approve")}
                          </Button>
                        </Tooltip>

                        {/* <Button
                        mr={4}
                        onClick={() =>
                          generatePdf(treatmentPlan, totalTpPrice, true)
                        }
                        bg={buttonAltYellow}
                        color={textPrimary}
                        border={`1px solid ${newBorder}`}
                        borderRadius="10rem"
                        fontWeight="500"
                        fontSize="md"
                        size="lg"
                        _hover={{
                          bg: secondary,
                        }}
                        isDisabled={false}
                      >
                        {t("common.downloadPdf")}
                      </Button> */}
                      </>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </form>
          </Box>
        </>
      )}
    </>
  );
};

export default ViewPatientTreatmentPlanPublic;
