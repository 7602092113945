import React from 'react';
import styled from 'styled-components';

const TableDoubleText = ({ first, second }) => {
  return (
    <>
      <Wrapper>
        <ProductName>{first}</ProductName>
        <ProductDescription>{second}</ProductDescription>
      </Wrapper>
    </>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const ProductName = styled.h6`
  font-size: 16px;
  font-weight: 600;
  color: #2a364e;
  margin-bottom: 2px;
  letter-spacing: 0px;
  text-transform: none;
  white-space: nowrap;
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 0 2px;
  padding: 0;
`;

const ProductDescription = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #91a5b6;
  margin: 0;
  letter-spacing: 0px;
  white-space: nowrap;
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 0.1px;
`;

export default TableDoubleText;
