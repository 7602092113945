export const TREATMENTPLAN_LIST_REQUEST = "TREATMENTPLAN_LIST_REQUEST";
export const TREATMENTPLAN_LIST_SUCCESS = "TREATMENTPLAN_LIST_SUCCESS";
export const TREATMENTPLAN_LIST_FAIL = "TREATMENTPLAN_LIST_FAIL";
export const TREATMENTPLAN_LIST_RESET = "TREATMENTPLAN_LIST_RESET";

export const TREATMENTPLAN_DETAILS_REQUEST = "TREATMENTPLAN_DETAILS_REQUEST";
export const TREATMENTPLAN_DETAILS_SUCCESS = "TREATMENTPLAN_DETAILS_SUCCESS";
export const TREATMENTPLAN_DETAILS_FAIL = "TREATMENTPLAN_DETAILS_FAIL";
export const TREATMENTPLAN_DETAILS_RESET = "TREATMENTPLAN_DETAILS_RESET";

export const TREATMENTPLAN_CREATE_REQUEST = "TREATMENTPLAN_CREATE_REQUEST";
export const TREATMENTPLAN_CREATE_SUCCESS = "TREATMENTPLAN_CREATE_SUCCESS";
export const TREATMENTPLAN_CREATE_FAIL = "TREATMENTPLAN_CREATE_FAIL";
export const TREATMENTPLAN_CREATE_RESET = "TREATMENTPLAN_CREATE_RESET";

export const TREATMENTPLAN_UPDATE_REQUEST = "TREATMENTPLAN_UPDATE_REQUEST";
export const TREATMENTPLAN_UPDATE_SUCCESS = "TREATMENTPLAN_UPDATE_SUCCESS";
export const TREATMENTPLAN_UPDATE_FAIL = "TREATMENTPLAN_UPDATE_FAIL";
export const TREATMENTPLAN_UPDATE_RESET = "TREATMENTPLAN_UPDATE_RESET";

export const TREATMENTPLAN_DELETE_REQUEST = "TREATMENTPLAN_DELETE_REQUEST";
export const TREATMENTPLAN_DELETE_SUCCESS = "TREATMENTPLAN_DELETE_SUCCESS";
export const TREATMENTPLAN_DELETE_FAIL = "TREATMENTPLAN_DELETE_FAIL";
export const TREATMENTPLAN_DELETE_RESET = "TREATMENTPLAN_DELETE_RESET";

export const TREATMENTPLAN_UPDATE_SERVICES_ORDER_REQUEST =
  "TREATMENTPLAN_UPDATE_SERVICES_ORDER_REQUEST";
export const TREATMENTPLAN_UPDATE_SERVICES_ORDER_SUCCESS =
  "TREATMENTPLAN_UPDATE_SERVICES_ORDER_SUCCESS";
export const TREATMENTPLAN_UPDATE_SERVICES_ORDER_FAIL =
  "TREATMENTPLAN_UPDATE_SERVICES_ORDER_FAIL";
export const TREATMENTPLAN_UPDATE_SERVICES_ORDER_RESET =
  "TREATMENTPLAN_UPDATE_SERVICES_ORDER_RESET";

export const TREATMENTPLAN_UPDATE_STATUS_REQUEST =
  "TREATMENTPLAN_UPDATE_STATUS_REQUEST";
export const TREATMENTPLAN_UPDATE_STATUS_SUCCESS =
  "TREATMENTPLAN_UPDATE_STATUS_SUCCESS";
export const TREATMENTPLAN_UPDATE_STATUS_FAIL =
  "TREATMENTPLAN_UPDATE_STATUS_FAIL";
export const TREATMENTPLAN_UPDATE_STATUS_RESET =
  "TREATMENTPLAN_UPDATE_STATUS_RESET";

export const TREATMENTPLAN_APPROVE_REQUEST = "TREATMENTPLAN_APPROVE_REQUEST";
export const TREATMENTPLAN_APPROVE_SUCCESS = "TREATMENTPLAN_APPROVE_SUCCESS";
export const TREATMENTPLAN_APPROVE_FAIL = "TREATMENTPLAN_APPROVE_FAIL";
export const TREATMENTPLAN_APPROVE_RESET = "TREATMENTPLAN_APPROVE_RESET";

export const TREATMENTPLAN_CANCEL_REQUEST = "TREATMENTPLAN_CANCEL_REQUEST";
export const TREATMENTPLAN_CANCEL_SUCCESS = "TREATMENTPLAN_CANCEL_SUCCESS";
export const TREATMENTPLAN_CANCEL_FAIL = "TREATMENTPLAN_CANCEL_FAIL";
export const TREATMENTPLAN_CANCEL_RESET = "TREATMENTPLAN_CANCEL_RESET";

export const TREATMENTPLAN_STATISTICS_REQUEST =
  "TREATMENTPLAN_STATISTICS_REQUEST";
export const TREATMENTPLAN_STATISTICS_FAIL = "TREATMENTPLAN_STATISTICS_FAIL";
export const TREATMENTPLAN_STATISTICS_SUCCESS =
  "TREATMENTPLAN_STATISTICS_SUCCESS";
export const TREATMENTPLAN_STATISTICS_RESET = "TREATMENTPLAN_STATISTICS_RESET";

export const TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_REQUEST =
  "TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_REQUEST";
export const TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_FAIL =
  "TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_FAIL";
export const TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_SUCCESS =
  "TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_SUCCESS";
export const TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_RESET =
  "TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_RESET";

export const TREATMENTPLAN_SEND_EMAIL_REQUEST =
  "TREATMENTPLAN_SEND_EMAIL_REQUEST";
export const TREATMENTPLAN_SEND_EMAIL_SUCCESS =
  "TREATMENTPLAN_SEND_EMAIL_SUCCESS";
export const TREATMENTPLAN_SEND_EMAIL_FAIL = "TREATMENTPLAN_SEND_EMAIL_FAIL";
export const TREATMENTPLAN_SEND_EMAIL_RESET = "TREATMENTPLAN_SEND_EMAIL_RESET";

export const TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_REQUEST =
  "TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_REQUEST";
export const TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_SUCCESS =
  "TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_SUCCESS";
export const TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_FAIL =
  "TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_FAIL";
export const TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_RESET =
  "TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_RESET";

export const TREATMENTPLAN_DOWNLOAD_PDF_REQUEST =
  "TREATMENTPLAN_DOWNLOAD_PDF_REQUEST";
export const TREATMENTPLAN_DOWNLOAD_PDF_SUCCESS =
  "TREATMENTPLAN_DOWNLOAD_PDF_SUCCESS";
export const TREATMENTPLAN_DOWNLOAD_PDF_FAIL =
  "TREATMENTPLAN_DOWNLOAD_PDF_FAIL";
export const TREATMENTPLAN_DOWNLOAD_PDF_RESET =
  "TREATMENTPLAN_DOWNLOAD_PDF_RESET";
