// GoogleApiContext.js
import React, { createContext, useState, useEffect } from "react";

export const GoogleApiContext = createContext();

export const GoogleApiProvider = ({ children, googleApiKey }) => {
  const [isApiLoaded, setApiLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&callback=initGoogleApi`;
    script.async = true;
    window.initGoogleApi = () => {
      setApiLoaded(true);
      delete window.initGoogleApi;
    };
    document.head.appendChild(script);
  }, [googleApiKey]);

  return (
    <GoogleApiContext.Provider value={{ isApiLoaded }}>
      {children}
    </GoogleApiContext.Provider>
  );
};
