import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Image,
  Flex,
  Link,
  Avatar,
  Heading,
  Icon,
  HStack,
  Button,
} from "@chakra-ui/react";
import {
  primary,
  textPrimary,
  textSecondary,
  backgroundLight,
  greenVivid,
  newBorder,
  buttonGreen,
  buttonGreenText,
  buttonGreenDark,
  white,
} from "../../../../../utils/colors";
import {
  buttonBlue,
  buttonBlueText,
  buttonYellow,
  buttonYellowText,
  buttonYellowDark,
  buttonRed,
  buttonRedText,
  buttonRedDark,
} from "../../../../../utils/colors";
import StatusBadge from "../../../../../components/badges/StatusBadge";
import { useTranslation } from "react-i18next";
import StatusButton from "../../../../../components/buttons/StatusButton";
import {
  FaRegCircle,
  FaCircleHalfStroke,
  FaCircleXmark,
  FaClock,
  FaCircleCheck,
  FaCircleExclamation,
  FaCaretDown,
  FaCircleXmarkFill,
} from "react-icons/fa6";
import useIsMobile from "../../../../../hooks/useIsMobile";

const steps = [
  {
    title: "Konsultacija",
    incompleteIcon: FaRegCircle,
    completeIcon: FaCircleCheck,
    activeIcon: FaCircleXmark,
  },
  {
    title: "Sudarytas gydymo planas",
    incompleteIcon: FaRegCircle,
    completeIcon: FaCircleCheck,
    activeIcon: FaCircleHalfStroke,
  },
  {
    title: "Paskirtas vizitas",
    incompleteIcon: FaRegCircle,
    completeIcon: FaCircleCheck,
    activeIcon: FaCircleHalfStroke,
  },
  {
    title: "Baigtas gydymas",
    incompleteIcon: FaRegCircle,
    completeIcon: FaCircleCheck,
    activeIcon: FaCircleHalfStroke,
  },
];

const PatientStatus = ({ user, plan, status }) => {
  // GENERAL
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // CONSTANTS
  const [activeStep, setActiveStep] = useState(0);
  const [statusText, setStatusText] = useState("");

  const isMobile = useIsMobile();

  // STEPS
  const updateStepAndText = () => {
    let newActiveStep = 0;
    let newText = "";

    const allCompleted = user.treatmentPlans.every(
      (plan) => plan.status === "completed"
    );
    const noTreatmentPlans = user.treatmentPlans.length === 0;

    const userCancelledTreatment = user.status === "cancelledTreatment";

    if (noTreatmentPlans) {
      newText = t("patient.statusTextNoTreatmentPlans");
    } else if (user.status === "inactive") {
      newText = t("patient.statusTextCompletedServicesNoTreatmentPlan");
      newActiveStep = 3; // All completed, show last step
    } else if (user.status === "consulting") {
      newText = t("patient.statusTextOnlyConsultingServices");
      newActiveStep = 1;
    } else if (user.status === "approvedTreatment") {
      newText = t("patient.statusTextApprovedTreatmentPlan");
      newActiveStep = 2;
    } else if (user.status === "ongoingTreatment") {
      newText = t("patient.statusTextOnGoingTreatment");
      newActiveStep = 3;
    } else if (user.status === "cancelledTreatment") {
      newText = t("patient.statusTextCancelledTreatment");
      newActiveStep = 0;
    } else {
      newText = t("patient.statusTextApproveTreatmentPlan");
      newActiveStep = 1;
      // "Treatment Plan has to be approved. Approve treatment plan";
    }

    setActiveStep(newActiveStep);
    setStatusText(newText);
  };

  const noTreatmentPlans = user.treatmentPlans.length === 0;

  const allCompleted = user.treatmentPlans.every(
    (plan) => plan.status === "completed"
  );

  // USE EFFECT
  useEffect(() => {
    updateStepAndText();
  }, [user.status, user.treatmentPlans]);

  // LOGS

  // console.log("status", status);
  // console.log("statusText", statusText);
  // console.log("activeStep", activeStep);

  return (
    <>
      <Flex direction="column" w="100%">
        <Flex w="100%" justify="space-between" align="center" p="0 0rem 0 0">
          <Flex align="center" justify="space-between" w="100%" p="0 0 0.75rem">
            <Flex align="center">
              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                mr={0}
                whiteSpace="nowrap"
              >
                {/* {plan ? "Active Treatment Plan" : "Patient Status"} */}
                {t("patient.patientStatus")}
              </Heading>
              {plan ? (
                <Box w="1px" h="1.5rem" bg="#CBCDD0" mx="0.5rem" />
              ) : null}
              <Heading
                fontSize="1rem"
                color={textPrimary}
                fontWeight="600"
                m={0}
                whiteSpace="nowrap"
              >
                {plan ? plan.treatmentPlanID : ""}
              </Heading>
              <Box bg={newBorder} h="20px" w="1px" mx={2} />
              {!isMobile && (
                <Text color={textSecondary} fontWeight="500" fontSize="sm">
                  {statusText}
                </Text>
              )}

              {/* <StatusBadge status={status} /> */}
            </Flex>
          </Flex>
        </Flex>
        <Box
          bg={white}
          border={`1px solid ${newBorder}`}
          borderRadius="1rem"
          p="1rem 1.5rem 1rem"
        >
          <Flex w="100%" justify="space-between" align="center">
            <Stepper
              size="lg"
              index={activeStep}
              colorScheme="blue"
              w="100%"
              p="0rem 0 0"
              display={["none", "flex", "flex "]}
            >
              {steps.map((step, index) => (
                <Step
                  key={index}
                  // onClick={() => setActiveStep(index)}
                >
                  {/* <StepIcon color={primary} as={Image} src={step.icon} /> */}

                  <StepStatus
                    complete={
                      <Flex align="center" justify="center" color="#42CE7C">
                        <Icon opacity="1" as={step.completeIcon} />
                      </Flex>
                    }
                    incomplete={
                      <Flex align="center" justify="center" color="#CBCDD0">
                        <Icon opacity="1" as={step.incompleteIcon} />
                      </Flex>
                    }
                    active={
                      <Flex
                        align="center"
                        justify="center"
                        color={
                          noTreatmentPlans ||
                          user.status === "cancelledTreatment"
                            ? "#FE4960"
                            : "#42CE7C"
                        }
                      >
                        {!noTreatmentPlans ? (
                          <Icon
                            opacity="1"
                            as={
                              allCompleted ? step.completeIcon : step.activeIcon // If all completed, show complete icon, else show active icon
                            }
                          />
                        ) : (
                          <Icon opacity="1" as={step.activeIcon} />
                        )}
                      </Flex>
                    }
                  />
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
            <Flex m={["0 0 0 0rem", "0 0 0 2rem", "0 0 0 2rem"]}>
              <StatusButton
                currentStatus={user.status}
                options="user"
                isUnclickable
              />
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default PatientStatus;
