import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { listUsersPatients, deleteUser } from "../../../../actions/userActions";
import { useNavigate } from "react-router-dom";
import Table from "../../../../components/shared/Table";
import { Box, Text, Flex } from "@chakra-ui/react";
import ToastComponent from "../../../../components/partials/ToastComponent";
import { columns } from "../columns/PatientListColumns"; // Adjust the path according to your file structure
import { useTranslation } from "react-i18next";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import SidePanelLong from "../../../../components/sidepanel/SidePanelLong";
import { AnimatePresence } from "framer-motion";
import PatientSingleLong from "../single/PatientSingleLong";
import EditPatient from "../sidepanels/EditPatient";
import CreatePatient from "../sidepanels/CreatePatient";
import {
  USER_CREATE_RESET,
  USER_UPDATE_RESET,
  USER_DELETE_RESET,
} from "../../../../constants/userConstants";
import PatientStats from "../partials/PatientStats";
import { white } from "../../../../utils/colors";
import IsFilterActive from "../../../../components/partials/IsFilterActive";
import useRoleCheck from "../../../../hooks/useRoleCheck";
import useIsMobile from "../../../../hooks/useIsMobile";

const PatientList = () => {
  // GENERAL
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  const isMobile = useIsMobile();

  // ROLES
  const isDoctor = useRoleCheck("employee_doctor");
  const isAdmin = useRoleCheck("admin");

  // SEARCH
  const [searchTitle, setSearchTitle] = useState("");

  // PAGINATION
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [4, 10, 25, 100];

  //SORTING
  const [sortField, setSortField] = useState("lastName");
  const [sortOrder, setSortOrder] = useState("asc");

  // SIDEPANELS
  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);
  const [isSidePanelLongOpen, setIsSidePanelLongOpen] = useState(false);
  const [createPatientSidePanel, setCreatePatientSidePanel] = useState(false);
  const [editPatientSidePanel, setEditPatientSidePanel] = useState(false);

  // CONSTANTS
  const [userId, setUserId] = useState("");

  // REDUX
  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  const userPatientsList = useSelector((state) => state.userPatientsList);
  const { loading, error, patients, pages, countsByStatus, totalCount } =
    userPatientsList;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  const userCreate = useSelector((state) => state.userCreate);
  const { error: errorCreate, success: successCreate } = userCreate;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { success: successUpdate } = userUpdate;

  const treatmentPlanApprove = useSelector(
    (state) => state.treatmentPlanApprove
  );
  const { success: successTreatmentPlanApprove } = treatmentPlanApprove;

  const treatmentPlanUpdate = useSelector((state) => state.treatmentPlanUpdate);
  const { success: successTreatmentPlanUpdate } = treatmentPlanUpdate;

  const treatmentPlanCreate = useSelector((state) => state.treatmentPlanCreate);
  const { success: successTreatmentPlanCreate } = treatmentPlanCreate;

  const treatmentPlanDelete = useSelector((state) => state.treatmentPlanDelete);
  const { success: successTreatmentPlanDelete } = treatmentPlanDelete;

  const treatmentPlanUpdateStatus = useSelector(
    (state) => state.treatmentPlanUpdateStatus
  );
  const { success: successTreatmentPlanUpdateStatus } =
    treatmentPlanUpdateStatus;

  const treatmentPlanCancel = useSelector((state) => state.treatmentPlanCancel);
  const { success: successTreatmentPLanCancel } = treatmentPlanCancel;

  const normalizeText = (text) => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  // FILTERING
  const [isFilterActive, setIsFilterActive] = useState(false);

  const [filtersInitialized, setFiltersInitialized] = useState(false);

  const filterConfig = {
    patients: true,
  };

  const [filterParams, setFilterParams] = useState({
    minAge: "",
    maxAge: "",
    startDate: "",
    endDate: "",
    status: [],
    state: [],
  });

  const handleFilterApply = (filters) => {
    setFilterParams(filters);
    setPage(1);
    setIsFilterActive(true);
    localStorage.setItem("filterSettings_patients", JSON.stringify(filters)); // Example for PatientList
    dispatch(
      listUsersPatients(
        searchTitle,
        1,
        pageSize,
        sortField,
        sortOrder,
        filters.minAge,
        filters.maxAge,
        filters.state,
        filters.status,
        filters.startDate,
        filters.endDate
      )
    );
  };

  // SORTING

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);

    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(
      listUsersPatients(
        searchTitle,
        1,
        pageSize,
        column,
        newSortOrder,
        filterParams.minAge,
        filterParams.maxAge,
        filterParams.state,
        filterParams.status,
        filterParams.startDate,
        filterParams.endDate
      )
    );
  };

  // HANDLERS

  const onChangeSearchTitle = (e) => {
    const searchTitle = normalizeText(e.target.value);
    setSearchTitle(searchTitle);
  };

  const deletePatientHandler = (id) => {
    dispatch(deleteUser(id));
  };

  // REFRESH PATIENT LIST FUNCTION

  // SIDEPANEL HANDLERS

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = () => {
    setEditSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelLong = () => {
    setIsSidePanelLongOpen(false);
    setUserId("");
    document.body.classList.remove("no-scroll");
  };

  const openCreatePatientSidePanelModal = () => {
    setCreatePatientSidePanel(true);
    document.body.classList.add("no-scroll");
  };

  const closeCreatePatientSidePanelModal = () => {
    setCreatePatientSidePanel(false);
    document.body.classList.remove("no-scroll");
  };

  const openEditPatientSidePanelModal = (id) => {
    setEditPatientSidePanel(true);
    setUserId(id);
    document.body.classList.add("no-scroll");
    console.log("body class");
  };

  const closeEditPatientSidePanelModal = () => {
    setEditPatientSidePanel(false);
    document.body.classList.remove("no-scroll");
  };

  useEffect(() => {
    const savedFiltersStr = localStorage.getItem("filterSettings_patients");
    if (savedFiltersStr) {
      const savedFilters = JSON.parse(savedFiltersStr);
      setFilterParams(savedFilters);
      setIsFilterActive(true);
      console.log("Saved filters applied", savedFilters);
    } else {
      console.log("No saved filters found");
    }
    setFiltersInitialized(true); // Indicate that filter initialization is complete
  }, []);

  const fetchPatientsList = () => {
    dispatch(
      listUsersPatients(
        searchTitle,
        page,
        pageSize,
        sortField,
        sortOrder,
        filterParams.minAge,
        filterParams.maxAge,
        filterParams.state,
        filterParams.status,
        filterParams.startDate,
        filterParams.endDate
      )
    );
  };

  useEffect(() => {
    // Redirect if not logged in
    if (!userInfo) {
      navigate("/login");
      return;
    }

    // Fetch patient list if filters are initialized or on successful CRUD operations
    if (
      filtersInitialized ||
      successDelete ||
      successCreate ||
      successUpdate ||
      successTreatmentPlanApprove ||
      successTreatmentPlanUpdate ||
      successTreatmentPlanCreate ||
      successTreatmentPlanDelete ||
      successTreatmentPlanUpdateStatus ||
      successTreatmentPLanCancel
    ) {
      fetchPatientsList();

      // Reset CRUD operation flags to avoid refetching due to these flags remaining true
      if (successDelete) {
        toastComponent.showToast(t("toasts.patientDelete"), "success", 3000);
        dispatch({ type: USER_DELETE_RESET });
      }
      if (successCreate) {
        toastComponent.showToast(t("toasts.patientCreate"), "success", 3000);
        dispatch({ type: USER_CREATE_RESET });
        closeCreatePatientSidePanelModal();
      }
      if (successUpdate) {
        toastComponent.showToast(t("toasts.patientUpdate"), "success", 3000);
        dispatch({ type: USER_UPDATE_RESET });
        closeCreatePatientSidePanelModal();
      }
    }
  }, [
    userInfo,
    searchTitle,
    page,
    pageSize,
    filtersInitialized,
    successDelete,
    successCreate,
    successUpdate,
    successTreatmentPlanApprove,
    successTreatmentPlanUpdate,
    successTreatmentPlanCreate,
    successTreatmentPlanDelete,
    successTreatmentPlanUpdateStatus,
    successTreatmentPLanCancel,
    navigate,
    dispatch,
  ]);

  useEffect(() => {
    if (errorCreate) {
      toastComponent.showToast(
        t("toasts.patientEmailAlreadyExists"),
        "error",
        3000
      );
      dispatch({ type: USER_CREATE_RESET });
    }
  }, [errorCreate, dispatch]);

  // LOGS

  // console.log("isAdmin", isAdmin);
  // console.log("isDoctor:", isDoctor);
  // console.log("filter", filterParams);
  // console.log("patients", patients);
  // console.log("localstorage", localStorage.getItem("filterSettings_patients"));

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>Patient List</title>
        <meta name="description" content="Dentacasa Patient List" />
      </Helmet>

      <AnimatePresence mode="wait">
        {isSidePanelLongOpen && (
          <SidePanelLong close={closeSidePanelLong}>
            <PatientSingleLong
              userId={userId}
              close={closeSidePanelLong}
              setUserId={setUserId}
              openSidePanelModal={openSidePanelModal}
              openEditSidePanelModal={openEditSidePanelModal}
              userInfo={userInfo}
            />
          </SidePanelLong>
        )}
      </AnimatePresence>

      {/* SIDEPANELS  */}

      <SidePanel
        open={createPatientSidePanel}
        close={closeCreatePatientSidePanelModal}
        title={t("patient.createPatient")}
        noBorder={false}
      >
        {!isDoctor && (
          <CreatePatient
            defaultRole="patient"
            close={closeCreatePatientSidePanelModal}
          />
        )}
      </SidePanel>

      <SidePanel
        open={editPatientSidePanel}
        close={closeEditPatientSidePanelModal}
        title={t("patient.editPatient")}
        noBorder={false}
      >
        {!isDoctor && (
          <EditPatient userId={userId} close={closeEditPatientSidePanelModal} />
        )}
      </SidePanel>

      {/* MAIN  */}
      <Box w="100%" minH="100%" pt="80px">
        <Flex
          direction="column"
          w="100%"
          p={["1rem 1rem 6rem", "2rem 2rem 6rem", "2rem 2rem 6rem"]}
        >
          <Flex
            direction="column"
            w="100%"
            h="100%"
            bg={white}
            borderRadius={["2rem", "2rem", "2rem"]}
            overflow="hidden"
          >
            <Box px={8}>
              <Table.Top
                title={t("patient.patients")}
                onChange={onChangeSearchTitle}
                placeholder={t("patient.search")}
                searchName={searchTitle}
                buttonLabel={t("patient.createPatient")}
                buttonHandler={openCreatePatientSidePanelModal}
                isDisabledButton={isDoctor}
                isFilter
                filterLabel={t("patient.filter")}
                filterHandler={handleFilterApply}
                isFilterActive={isFilterActive}
                setIsFilterActive={setIsFilterActive}
                filterConfig={filterConfig}
                filterStatusOptions="user" // no need because it uses different form control specific to patient
                savedFilters={filterParams}
                savedFiltersKey="filterSettings_patients"
              />
            </Box>

            {/* <PatientStats
              loading={loading}
              stats={countsByStatus}
              total={totalCount}
            /> */}

            {!isMobile && (
              <PatientStats
                loading={loading}
                countsByStatus={countsByStatus}
                globalTotalCount={totalCount}
              />
            )}

            {isFilterActive && !loading && (
              <IsFilterActive label={t("filter.filterActive")} />
            )}

            <Table.Container
              className={isMobile ? "sticky" : null}
              columns={columns(
                translate,
                deletePatientHandler,
                openEditPatientSidePanelModal,
                handleSortChange,
                isDoctor,
                isAdmin,
                isMobile
              )}
              data={patients}
              loading={loading}
              error={error}
              onRowClick={(userId) => {
                setIsSidePanelLongOpen(true);
                setUserId(userId);
                document.body.classList.add("no-scroll");
              }}
              // noItemsButtonHandler={openSidePanelModal}
              noItemsTitle={t("noItems.noPatients")}
              noItemsDescription={t("noItems.noPatientsText")}
              noItemsButtonLabel={t("noItems.noPatientsButton")}
              noItemsButtonHandler={openCreatePatientSidePanelModal}
              noItemsButtonDisabled={isDoctor}
              sortField={sortField}
              sortOrder={sortOrder}
            />
            <Table.Pagination
              loading={loading}
              page={page}
              pages={pages}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
              setPage={setPage}
              setPageSize={setPageSize}
              totalCount={patients.length}
            />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default PatientList;
