import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";
import {
  textSecondary,
  buttonBlue,
  buttonBlueText,
  buttonBlueDark,
  buttonGreen,
  buttonGreenText,
  buttonGreenDark,
  buttonRed,
  buttonRedText,
  buttonRedDark,
  buttonYellow,
  buttonYellowText,
  buttonYellowDark,
  white,
  backgroundLight,
  newBorder,
  buttonOrange,
  buttonOrangeText,
  buttonOrangeDark,
  greenVivid,
  buttonLightPurple,
  buttonLightPurpleDark,
  textPrimary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import {
  FaRegCircleDot,
  FaRegCircle,
  FaCircleHalfStroke,
  FaCircleXmark,
  FaClock,
  FaCircleCheck,
  FaCircleExclamation,
  FaCaretDown,
  FaRegCircleCheck,
} from "react-icons/fa6";

const StatusBadgeAlt = ({ status, options, onChange, isSmall, noText }) => {
  const { t } = useTranslation();
  const translate = (key) => t(key);

  if (options === "paid") {
    options = [
      {
        value: "unpaid",
        label: translate("status.unpaid"),
        icon: FaCircleXmark,
        bg: buttonOrange,
        bgDark: buttonOrangeDark,
        color: buttonOrangeText,
        borderColor: buttonOrange,
        bgHover: "#dda3a3",
      },
      {
        value: "paid",
        label: translate("status.paid"),
        icon: FaCircleCheck,
        bg: buttonGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },
    ];
  } else if (options === "course") {
    options = [
      {
        value: "draft",
        label: translate("status.draft"),
        icon: FaRegCircle,
        bg: backgroundLight,
        bgDark: textSecondary,
        color: textSecondary,
        borderColor: newBorder,
        bgHover: "#ddd",
      },
      {
        value: "upcoming",
        label: translate("status.upcoming"),
        icon: FaClock,
        bg: buttonLightPurple,
        bgDark: buttonLightPurpleDark,
        color: textPrimary,
        borderColor: buttonBlue,
        bgHover: "#b6cce1",
      },
      {
        value: "cancelled",
        label: translate("status.cancelled"),
        icon: FaCircleXmark,
        bg: buttonRed,
        bgDark: buttonRedDark,
        color: buttonRedText,
        borderColor: buttonRed,
        bgHover: "#dda3a3",
      },
      {
        value: "completed",
        label: translate("status.completed"),
        icon: FaRegCircleCheck,
        bg: greenVivid,
        bgDark: greenVivid,
        color: textPrimary,
        borderColor: greenVivid,
        bgHover: greenVivid,
      },
    ];
  } else {
    options = [
      {
        value: "new",
        label: translate("status.new"),
        icon: FaClock,
        bg: buttonYellow,
        bgDark: buttonYellowDark,
        color: buttonYellowText,
        borderColor: buttonYellow,
        bgHover: "#b6cce1",
      },
      {
        value: "cancelled",
        label: translate("status.cancelled"),
        icon: FaCircleXmark,
        bg: buttonRed,
        bgDark: buttonRedDark,
        color: buttonRedText,
        borderColor: buttonRed,
        bgHover: "#dda3a3",
      },
      {
        value: "completed",
        label: translate("status.completed"),
        icon: FaCircleCheck,
        bg: buttonGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },
      {
        value: "planning",
        label: translate("status.planning"),
        icon: FaClock,
        bg: buttonYellow,
        bgDark: buttonYellowDark,
        color: buttonYellowText,
        borderColor: buttonYellow,
        bgHover: "#b6cce1",
      },
      {
        value: "approved",
        label: translate("status.approved"),
        icon: FaCircleCheck,
        bg: buttonGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },
      {
        value: "consulting",
        label: "Consulting",
        icon: FaCircleHalfStroke,
        bg: buttonBlue,
        bgDark: buttonBlueDark,
        color: buttonBlueText,
        borderColor: newBorder,
        bgHover: "#ddd",
      },
      {
        value: "draft",
        label: translate("status.draft"),
        icon: FaRegCircle,
        bg: backgroundLight,
        bgDark: textSecondary,
        color: textSecondary,
        borderColor: newBorder,
        bgHover: "#ddd",
      },
      {
        value: "ongoing",
        label: translate("status.ongoing"),
        icon: FaCircleCheck,
        bg: buttonGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },
    ];
  }

  const currentStatusOption = options.find((option) => option.value === status);

  return (
    <Box>
      {status && (
        <Flex align="center" onClick={() => onChange && onChange(status)}>
          <Flex
            align="center"
            justify="center"
            bg={currentStatusOption ? currentStatusOption.bg : white}
            borderRadius="10rem"
            p={isSmall ? "0.1rem 0.75rem" : "0.15rem 0.75rem"}
            h="1.125rem"
            w="1.125rem"
          >
            <Icon
              h={isSmall ? "0.6rem" : "0.75rem"}
              w={isSmall ? "0.6rem" : "0.75rem"}
              color={
                currentStatusOption ? currentStatusOption.color : textSecondary
              }
              as={
                currentStatusOption ? currentStatusOption.icon : FaRegCircleDot
              }
            />
          </Flex>

          {!noText && (
            <Text
              ml={isSmall ? 1 : 2}
              color={textSecondary}
              fontWeight="500"
              fontSize={isSmall ? "xs" : "sm"}
            >
              {currentStatusOption && currentStatusOption.label}
            </Text>
          )}
        </Flex>
      )}
    </Box>
  );
};

export default StatusBadgeAlt;
