import React from "react";
import { IconButton } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { white, primary, text, border, textBlack } from "../../utils/colors";

const SidePanelAlt = ({ title, open, close, children, noBorder }) => {
  return (
    <>
      <AnimatePresence>
        {open && (
          <>
            <MotionBackdrop
              as={motion.div}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.2,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.1,
                },
              }}
              onClick={close}
            />
            <MotionContentWrapper
              initial={{
                scale: 1,
                x: "100vw",
              }}
              animate={{
                scale: 1,
                x: 0,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                scale: 0,
                x: "100vw",
                transition: {
                  duration: 0.1,
                },
              }}
            >
              <MotionContent
                initial={{
                  opacity: 0,
                  x: 100,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    duration: 0.1,
                  },
                }}
                className="modal-content"
              >
                <Content>{children}</Content>
              </MotionContent>
            </MotionContentWrapper>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

const MotionBackdrop = styled.div`
  position: fixed;
  height: calc(100vh + 30px);
  width: 100vw;
  top: -30px;
  left: -300px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1001;
`;

const MotionContentWrapper = styled(motion.div)`
  position: fixed;
  width: 820px;
  height: 1000px;
  background: ${white};
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1002;
  height: 100%;
  overflow: scroll;
  /* border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem; */
  border-radius: 2rem;
`;

const MotionContent = styled(motion.div)`
  width: 100%;
  padding: 0rem 2rem;
`;

const Content = styled.div`
  padding: 2rem 0rem 4rem;
  min-height: 100vh;
`;

export default SidePanelAlt;
