import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import { Box, Text, Flex, IconButton, VStack } from "@chakra-ui/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

dayjs.extend(durationPlugin);

const SingleDayCalendar = ({ appointments }) => {
  const [currentDay, setCurrentDay] = useState(dayjs("2024-03-29"));
  const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {
    generateTimeSlots();
  }, [currentDay]);

  // Generates time slots for the current day
  const generateTimeSlots = () => {
    const startOfDay = dayjs(currentDay).startOf("day");
    const slots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        // Change interval if necessary
        slots.push(startOfDay.add(hour, "hours").add(minute, "minutes"));
      }
    }
    setTimeSlots(slots);
  };

  // Calculate the grid position and height for each appointment
  const calculateAppointmentStyles = (appointment) => {
    const start = dayjs(appointment.startDate);
    const end = dayjs(appointment.endDate);
    const duration = dayjs.duration(end.diff(start)).asMinutes();
    const startOfDay = dayjs(currentDay).startOf("day");
    const minutesFromStartOfDay = start.diff(startOfDay, "minutes");
    const rowHeightInMinutes = 30; // Assuming each row represents 30 minutes
    const top = (minutesFromStartOfDay / rowHeightInMinutes) * 100; // Top position in percentage
    const height = (duration / rowHeightInMinutes) * 100; // Height in percentage

    return {
      position: "absolute",
      top: `${top}%`,
      height: `${height}%`,
      left: 0,
      right: 0,
      background: "blue",
      padding: "4px",
      borderRadius: "4px",
    };
  };

  const navigateDay = (direction) => {
    setCurrentDay(currentDay.add(direction, "day"));
  };

  return (
    <Box
      border="1px"
      borderColor="gray.200"
      borderRadius="lg"
      p={4}
      position="relative"
      minHeight="100vh"
    >
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <IconButton
          aria-label="Previous Day"
          icon={<FaChevronLeft />}
          onClick={() => navigateDay(-1)}
        />
        <Text fontWeight="bold">{currentDay.format("dddd, MMMM D, YYYY")}</Text>
        <IconButton
          aria-label="Next Day"
          icon={<FaChevronRight />}
          onClick={() => navigateDay(1)}
        />
      </Flex>
      <VStack spacing={0} align="stretch" position="relative">
        {timeSlots.map((time, index) => (
          <Box
            key={index}
            borderBottom="1px"
            borderColor="gray.100"
            p={0}
            position="relative"
            height={`${100 / (24 * 2)}%`}
          >
            <Text fontSize="sm">{time.format("HH:mm")}</Text>
          </Box>
        ))}
        {appointments.map((appointment, index) => (
          <Box key={index} style={calculateAppointmentStyles(appointment)}>
            <Text fontSize="xs">{appointment.patient}</Text>
            <Text fontSize="xs">
              {dayjs(appointment.startDate).format("HH:mm")} -{" "}
              {dayjs(appointment.endDate).format("HH:mm")}
            </Text>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default SingleDayCalendar;
