import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Text,
  Flex,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { TbEdit, TbTrashX, TbDotsVertical } from "react-icons/tb";
import {
  textSecondary,
  white,
  newBorder,
  primary,
  backgroundLight,
} from "../../utils/colors";
import ConfirmDeleteModal from "../../components/modals/ConfirmDeleteModal";
import ConfirmCancelModal from "../../components/modals/ConfirmCancelModal";
import ConfirmApproveModal from "../../components/modals/ConfirmApproveModal";
import { useTranslation } from "react-i18next";
import {
  TbClipboardCheck,
  TbCheckbox,
  TbCheckList,
  TbEye,
} from "react-icons/tb";

const MoreButton = React.forwardRef(
  (
    {
      id,
      deleteHandler,
      editHandler,
      approveHandler,
      disabledApproveStatus,
      disabledCancelStatus,
      cancelHandler,
      cancelButtonDisabled,
      deleteLabel,
      editLabel,
      isBig,
      isSmall,
      deleteName,
      warningLabel,
      viewHandler,
      viewLabel,
      viewId,
      viewIcon,
      editButtonDisabled,
      deleteButtonDisabled,
      upHandler,
      downHandler,
      isDisabled,
      deleteTitle,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false); // State to control delete confirmation modal
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false); // State to control delete confirmation modal
    const [showApproveConfirmation, setShowApproveConfirmation] =
      useState(false); // State to control delete confirmation modal

    const btnRef2 = useRef();

    const handleClickOutside = (event) => {
      if (btnRef2.current && !btnRef2.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleDeleteConfirmation = () => {
      setShowDeleteConfirmation(true);
      setIsOpen(false); // Close the menu after clicking "Delete"
    };

    const handleCancelConfirmation = () => {
      setShowCancelConfirmation(true);
      setIsOpen(false); // Close the menu after clicking "Delete"
    };

    const handleApproveConfirmation = () => {
      setShowApproveConfirmation(true);
      setIsOpen(false); // Close the menu after clicking "Delete"
    };

    const handleOpenPatient = () => {
      viewHandler(viewId);
      setIsOpen(false); // Close the menu after clicking "Delete"
    };

    // console.log("isDisabled", isDisabled);

    return (
      <Box ref={ref} p={0} borderRadius="0.75rem" ml={2}>
        <Menu isLazy isDisabled={true}>
          <MenuButton
            type="button"
            bg={white}
            color={textSecondary}
            border={`1px solid ${newBorder}`}
            display="flex"
            alignItems="center"
            justifyContent="center"
            h={isBig ? "3rem" : isSmall ? "2rem" : "2.5rem"}
            w={isBig ? "3rem" : isSmall ? "2rem" : "2.5rem"}
            borderRadius={isSmall ? "0.5rem" : "0.75rem"}
            p={0}
            m="0"
            transition="0.2s all"
            _hover={{
              bg: backgroundLight,
            }}
            // style={{
            //   opacity: isDisabled ? "0.4" : "1",
            //   cursor: isDisabled ? "not-allowed" : "pointer",
            // }}
            _disabled={{
              opacity: 0.4,
              cursor: "not-allowed",
              bg: white,
              color: textSecondary,
            }}
            disabled={isDisabled}
          >
            <Icon
              as={TbDotsVertical}
              h={isBig ? "1.25rem" : isSmall ? "1rem" : "1.25rem"}
              w={isBig ? "1.25rem" : isSmall ? "1rem" : "1.25rem"}
              bg="none"
              mt={1}
              p={0}
            />
          </MenuButton>
          <MenuList p={0} m={0} borderRadius="1rem" overflow="hidden">
            {viewHandler && (
              <MenuItem
                py={4}
                px={4}
                color={textSecondary}
                transition="0.2s all"
                borderBottom={`1px solid ${newBorder}`}
                _hover={{ bg: primary, color: white }}
                onClick={handleOpenPatient}
              >
                <Icon
                  as={viewIcon || TbEye}
                  mr="0.5rem"
                  h="1rem"
                  w="1rem"
                  style={{
                    pointerEvents: "none",
                  }}
                />
                <Text
                  fontSize="0.875rem"
                  fontWeight="500"
                  lineHeight="1"
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  {viewLabel || t("button.view")}
                </Text>
              </MenuItem>
            )}

            {approveHandler && (
              <MenuItem
                py={4}
                px={4}
                color={textSecondary}
                transition="0.2s all"
                borderBottom={`1px solid ${newBorder}`}
                _hover={{ bg: primary, color: white }}
                onClick={handleApproveConfirmation}
                isDisabled={disabledApproveStatus}
              >
                <Icon
                  as={TbCheckbox}
                  mr="0.5rem"
                  h="1rem"
                  w="1rem"
                  style={{
                    pointerEvents: "none",
                  }}
                />
                <Text
                  fontSize="0.875rem"
                  fontWeight="500"
                  lineHeight="1"
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  {deleteLabel || t("button.approve")}
                </Text>
              </MenuItem>
            )}

            {cancelHandler && (
              <MenuItem
                py={4}
                px={4}
                color={textSecondary}
                transition="0.2s all"
                borderBottom={`1px solid ${newBorder}`}
                _hover={{ bg: primary, color: white }}
                onClick={handleCancelConfirmation}
                isDisabled={disabledCancelStatus || cancelButtonDisabled}
              >
                <Icon
                  as={TbCheckbox}
                  mr="0.5rem"
                  h="1rem"
                  w="1rem"
                  style={{
                    pointerEvents: "none",
                  }}
                />
                <Text
                  fontSize="0.875rem"
                  fontWeight="500"
                  lineHeight="1"
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  {deleteLabel || t("button.cancelTreatment")}
                </Text>
              </MenuItem>
            )}

            {upHandler && (
              <MenuItem
                py={4}
                px={4}
                color={textSecondary}
                transition="0.2s all"
                borderBottom={`1px solid ${newBorder}`}
                _hover={{ bg: primary, color: white }}
                onClick={upHandler}
                isDisabled={disabledCancelStatus || cancelButtonDisabled}
              >
                <Icon
                  as={TbCheckbox}
                  mr="0.5rem"
                  h="1rem"
                  w="1rem"
                  style={{
                    pointerEvents: "none",
                  }}
                />
                <Text
                  fontSize="0.875rem"
                  fontWeight="500"
                  lineHeight="1"
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  {t("button.upTreatment")}
                </Text>
              </MenuItem>
            )}

            {downHandler && (
              <MenuItem
                py={4}
                px={4}
                color={textSecondary}
                transition="0.2s all"
                borderBottom={`1px solid ${newBorder}`}
                _hover={{ bg: primary, color: white }}
                onClick={downHandler}
                isDisabled={disabledCancelStatus || cancelButtonDisabled}
              >
                <Icon
                  as={TbCheckbox}
                  mr="0.5rem"
                  h="1rem"
                  w="1rem"
                  style={{
                    pointerEvents: "none",
                  }}
                />
                <Text
                  fontSize="0.875rem"
                  fontWeight="500"
                  lineHeight="1"
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  {t("button.downTreatment")}
                </Text>
              </MenuItem>
            )}

            {editHandler && (
              <MenuItem
                py={4}
                px={4}
                borderBottom={`1px solid ${newBorder}`}
                color={textSecondary}
                transition="0.2s all"
                _hover={{ bg: primary, color: white }}
                onClick={editHandler}
                isDisabled={editButtonDisabled}
              >
                <Icon as={TbEdit} mr="0.5rem" h="1rem" w="1rem" />

                <Text fontSize="0.875rem" fontWeight="500" lineHeight="1">
                  {editLabel || t("button.edit")}
                </Text>
              </MenuItem>
            )}
            {deleteHandler && (
              <MenuItem
                py={4}
                px={4}
                color={textSecondary}
                transition="0.2s all"
                borderBottom={`1px solid ${newBorder}`}
                _hover={{ bg: primary, color: white }}
                onClick={handleDeleteConfirmation}
                isDisabled={deleteButtonDisabled}
              >
                <Icon
                  as={TbTrashX}
                  mr="0.5rem"
                  h="1rem"
                  w="1rem"
                  style={{
                    pointerEvents: "none",
                  }}
                />
                <Text
                  fontSize="0.875rem"
                  fontWeight="500"
                  lineHeight="1"
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  {deleteLabel || t("button.delete")}
                </Text>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
        {/* Render the delete confirmation modal when necessary */}
        {showDeleteConfirmation && (
          <ConfirmDeleteModal
            deleteTitle={deleteTitle}
            deleteName={deleteName}
            warningLabel={warningLabel}
            isOpen={showDeleteConfirmation}
            onClose={() => setShowDeleteConfirmation(false)}
            confirmAction={() => {
              deleteHandler(id); // Pass the id to the deleteHandler
              setShowDeleteConfirmation(false);
            }}
          />
        )}
        {/* Render the delete confirmation modal when necessary */}
        {showCancelConfirmation && (
          <ConfirmCancelModal
            isOpen={showCancelConfirmation}
            onClose={() => setShowCancelConfirmation(false)}
            confirmAction={() => {
              cancelHandler(id); // Pass the id to the deleteHandler
              setShowCancelConfirmation(false);
            }}
          />
        )}
        {/* Render the delete confirmation modal when necessary */}
        {showApproveConfirmation && (
          <ConfirmApproveModal
            isOpen={showApproveConfirmation}
            onClose={() => setShowApproveConfirmation(false)}
            confirmAction={() => {
              approveHandler(id); // Pass the id to the deleteHandler
              setShowApproveConfirmation(false);
            }}
          />
        )}
      </Box>
    );
  }
);

export default MoreButton;
