import React from "react";
import { Box } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { white, primary, text, border, textBlack } from "../../utils/colors";
import useIsMobile from "../../hooks/useIsMobile";

const SidePanel = ({
  title,
  open,
  close,
  children,
  noBorder,
  isTop,
  isLong,
}) => {
  const isMobile = useIsMobile();

  return (
    <>
      <AnimatePresence>
        {open && (
          <>
            <Box
              as={motion.div}
              position="fixed"
              h="calc(100vh + 30px)"
              w={isTop ? "calc(100vw + 6px)" : "calc(100vw + 1px)"}
              top={isTop ? "-16px" : "-30px"}
              left={isTop ? "-276px" : "0px"}
              background="rgba(0, 0, 0, 0.4)"
              zIndex="1001"
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.2,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.1,
                },
              }}
              onClick={close}
            />
            <Box
              as={motion.div}
              position="fixed"
              w={
                isLong
                  ? ["100%", "1020px", "1020px"]
                  : ["100%", "820px", "820px"]
              }
              bg={white}
              m={isTop ? "0 auto" : "auto"}
              top="0"
              right={isTop ? "0" : isMobile ? "0" : "30px"}
              bottom="0"
              zIndex="1002"
              h={isTop ? "calc(100vh - 33px)" : "calc(100vh - 60px)"}
              borderRadius="2rem"
              overflow="hidden"
              initial={{
                scale: 1,
                x: "100vw",
              }}
              animate={{
                scale: 1,
                x: 0,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                scale: 0,
                x: "100vw",
                transition: {
                  duration: 0.1,
                },
              }}
            >
              <Box
                as={motion.div}
                w="100%"
                p="0 0rem"
                h="100%"
                // initial={{
                //   opacity: 0,
                //   x: 100,
                // }}
                // animate={{
                //   opacity: 1,
                //   x: 0,
                //   transition: {
                //     delay: 0.3,
                //   },
                // }}
                // exit={{
                //   opacity: 0,
                //   transition: {
                //     duration: 0.1,
                //   },
                // }}
                className="modal-content"
              >
                <Box p="0" h="100%">
                  {children}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default SidePanel;
