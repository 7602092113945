import React from 'react';
import styled from 'styled-components';

const TableImage = ({ className, image, name }) => {
  const initials = name
    .split(' ')
    .map((n) => n[0])
    .join('');

  return (
    <>
      <ProductImageWrapper className={className}>
        {image && <ProductImage src={image} />}
        {!image && <p>{initials}</p>}
      </ProductImageWrapper>
    </>
  );
};

const ProductImageWrapper = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 8px;
  overflow: hidden;
  background: #dceeff;
  display: flex;
  align-items: center;
  justify-content: center;

  &.small {
    height: 50px;
    width: 50px;
  }
  &.circle {
    border-radius: 100px;
  }

  p {
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    color: #23629d;
    font-size: 18px;
    padding: 0;
  }
`;

const ProductImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export default TableImage;
