import styled from 'styled-components';

const TableCell = styled.div`
  display: flex;
  align-items: center;
  &.sku {
    width: 50px;
  }
`;

export default TableCell;
