import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TabPanels,
  TabPanel,
  HStack,
  Flex,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import {
  listEmployeeDetails,
  deleteEmployee,
} from "../../../../actions/employeeActions";
import Panel from "../../../../components/shared/Panel";
import { primary } from "../../../../utils/colors";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import StatusButton from "../../../../components/buttons/StatusButton";
import SinglePageTopBar from "../../../../components/layout/SinglePageTopBar";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import EditEmployee from "../sidepanels/EditEmployee";
import { EMPLOYEE_UPDATE_RESET } from "../../../../constants/employeeConstants";
import { useTranslation } from "react-i18next";
// import TabAttendeeCourses from "./tabs/TabAttendeeCourses";
// import AttendeeInfo from "./partials/AttendeeInfo";
// import AttendeeCharts from "./partials/AttendeeCharts";
import SinglePageImageWrapper from "../../../../components/partials/SinglePageImageWrapper";
import MoreButton from "../../../../components/buttons/MoreButton";

const EmployeeSingle = () => {
  const employeeId = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();

  const tabsData = [{ label: t("employee.treatmentPlans") }];

  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [phone, setPhone] = useState("");
  const [profession, setProfession] = useState([]);
  const [status, setStatus] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [place, setPlace] = useState("");

  const employeeDetails = useSelector((state) => state.employeeDetails);
  const { loading, employee } = employeeDetails;

  const employeeUpdate = useSelector((state) => state.employeeUpdate);
  const { success: successUpdate } = employeeUpdate;

  const employeeDelete = useSelector((state) => state.employeeDelete);
  const { success: successDelete } = employeeDelete;

  const formatDateToInputValue = (isoString) => {
    return new Date(isoString).toISOString().split("T")[0];
  };

  const deleteEmployeeHandler = (id) => {
    dispatch(deleteEmployee(id));
    toast({
      title: t("employee.employeeDeleted"),
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: EMPLOYEE_UPDATE_RESET });
      navigate("/employees/list");
    } else if (successDelete) {
      dispatch({ type: EMPLOYEE_UPDATE_RESET });
      navigate("/employees/list");
    } else {
      if (!employee.firstName || employee._id !== employeeId) {
        dispatch(listEmployeeDetails(employeeId));
      } else {
        setFirstName(employee.firstName);
        setLastName(employee.lastName);
        setImage(employee.image);
        setEmail(employee.email);
        setBirthDate(formatDateToInputValue(employee.birthDate));
        setPhone(employee.phone);
        setProfession(employee.profession);
        setStatus(employee.status);

        if (employee?.address[0]?.city) {
          setCity(employee?.address[0]?.city);
        }

        if (employee?.address[0]?.street) {
          setPlace(
            employee?.address[0]?.street + `, ` + employee?.address[0]?.zip ||
              ""
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, employeeId, employee, successUpdate, successDelete]);

  const openEditSidePanel = () => {
    setEditSidePanelOpen(true);
  };

  const closeEditSidePanel = () => {
    setEditSidePanelOpen(false);
  };

  return (
    <>
      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanel}
        title={t("employee.editEmployee")}
        noBorder={true}
      >
        <EditEmployee employeeId={employeeId} />
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <SinglePageTopBar
              backFunction={() => navigate(-1)}
              title={firstName}
              type={t("employee.employee")}
              loading={loading}
            >
              <StatusButton
                currentStatus={status}
                // onUpdateStatus={handleUpdateStatus}
                options="active"
                isBig
              />
              <MoreButton
                id={employeeId}
                isBig
                deleteHandler={deleteEmployeeHandler}
                editHandler={(e) => {
                  e.stopPropagation();
                  openEditSidePanel(employeeId);
                }}
              />
            </SinglePageTopBar>
            {!loading ? (
              <>
                <HStack
                  gap={0}
                  p="2.5rem 2rem 2.5rem"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  w="100%"
                >
                  <SinglePageImageWrapper image={image} />

                  <HStack
                    gap={0}
                    p="0rem 0rem 0rem 2rem"
                    w="calc(100% - 260px)"
                    alignItems="flex-start"
                  >
                    <>
                      {/* <AttendeeInfo attendee={patient} />
                      <AttendeeCharts attendee={attendee} /> */}
                    </>
                  </HStack>
                </HStack>
              </>
            ) : (
              <Flex justify="center" align="center" w="100%" p={20}>
                <Spinner size="xl" color={primary} />
              </Flex>
            )}
            <Box p="0 2.5rem">
              <TabsComponent
                activeTab={activeTab}
                tabs={tabsData}
                setActiveTab={setActiveTab}
                isTitleValid={true}
                disabledTabs={[2, 3]}
                isSinglePage
              >
                <TabPanels>
                  <TabPanel pb={4} px={0}>
                    {/* <TabAttendeeCourses /> */}
                  </TabPanel>
                </TabPanels>
              </TabsComponent>
            </Box>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default EmployeeSingle;
