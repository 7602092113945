import Wrapper from './PanelWrapper';
import Container from './PanelContainer';
import Body from './PanelBody';
import Navigation from './PanelNavigation';

const Panel = {
  Wrapper,
  Container,
  Body,
  Navigation,
};

export default Panel;
