import React from "react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { menuSlide, menuSlideMobile } from "../../animations/animations";
import Curve from "./Curve";
import { Box, Flex } from "@chakra-ui/react";
import { white } from "../../utils/colors";
import useIsMobile from "../../hooks/useIsMobile";

export default function SidePanelLong({ children, close }) {
  const isMobile = useIsMobile();

  return (
    <>
      <MotionBackdrop
        as={motion.div}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.2,
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: 0.4,
          },
        }}
        onClick={close}
      />

      <Box
        as={motion.div}
        variants={isMobile ? menuSlideMobile : menuSlide}
        initial="initial"
        animate="enter"
        exit="exit"
        h="calc(100vh - 2rem)"
        backgroundColor={white}
        borderRadius={["0rem", "2rem", "2rem"]}
        overflow="hidden"
        position="fixed"
        right="0"
        top={["0rem", "1rem", "1rem"]}
        zIndex="1001"
        w={["100%", "80%", "80%"]}
      >
        <Flex h="100%" p="0 0rem" direction="column" justify="space-between">
          <Flex direction="column" m="0px 0 0 0" h="100%" overflow-y="scroll">
            {children}
          </Flex>
        </Flex>
        <Curve />
      </Box>
    </>
  );
}

const MotionBackdrop = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
`;
