import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Input,
  IconButton,
  Stack,
  Avatar,
  Tooltip,
  Spinner,
  useToast,
  Select,
  InputGroup,
  InputLeftElement,
  Icon,
  Image,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  white,
  newBorder,
  textPrimary,
  textSecondary,
  backgroundLight,
  buttonRed,
  buttonRedText,
} from "../../../../../utils/colors";
import StatusBadge from "../../../../../components/badges/StatusBadge";
import StatusButton from "../../../../../components/buttons/StatusButton";
import ServiceSelect from "../../../../../components/select/ServiceSelect";
import { TbDots } from "react-icons/tb";
import EmployeeSelect from "../../../../../components/select/EmployeeSelect";
// import EmployeePopover from "../../../../../components/popovers/EmployeePopover";
import PricePopover from "./PricePopover";
import TeethPopoverAlt from "./TeethPopoverAlt";
import DatePopover3 from "./DatePopover3";
import DatePopover from "./DatePopover";
import EmployeePopover from "./EmployeePopover";
import EmployeePopoverTest from "./EmployeePopoverTest";
import { FiSearch } from "react-icons/fi";
import { TbCircleArrowDown, TbCircleArrowUp, TbTrash } from "react-icons/tb";
import noServices from "../../../../../assets/images/empty-box.png";
import MoreButton from "../../../../../components/buttons/MoreButton";
import { useTranslation } from "react-i18next";
import QuantityPopover from "./QuantityPopover";
import ConfirmAddServiceModal from "../../../../../components/modals/ConfirmAddServiceModal";
import useSize from "../../../../../hooks/useSize";
import { listServices } from "../../../../../actions/serviceActions";
import { useDispatch } from "react-redux";
import UserPopover from "./UserPopover";

const ServiceList = ({
  loading,
  error,
  services,
  addServiceToList,
  serviceStatusHandler,
  removeService,
  calculateTotalPriceForService,
  saveEditedService,
  servicePaidHandler,
  handleEmployeeChange, // Add this prop
  employeeOptions, // Add this prop
  employees,
  appointmentDates,
  moveServiceUp,
  moveServiceDown,
  addServiceModalOpen,
  setAddServiceModalOpen,
  isEdit,
  status,
  isDisabled,
  handleCoordinatorChange,
  initialCoordinator,
  setCoordinator,
  isAppointment,
  w,
}) => {
  // GENERAL

  const dispatch = useDispatch();
  const { t } = useTranslation();

  // CONSTANTS
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddService, setShowAddService] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [showAddServiceConfirmation, setShowAddServiceConfirmation] =
    useState(false);

  const scrollRef = useRef(null);

  const serviceList = useSelector((state) => state.serviceList);
  const {
    loading: loadingServices,
    error: errorServices,
    services: allServices,
  } = serviceList;

  // FILTER

  const filteredServices = searchTerm
    ? services.filter((service) =>
        service.service.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : services;

  // HANDLERS

  const ref = useRef(null);

  const handleAddServiceConfirmation = () => {
    setAddServiceModalOpen(!addServiceModalOpen);

    dispatch(listServices("", "", "", "", true));
  };

  const handleAddServiceConfirmationApproved = () => {
    console.log("handleAddServiceConfirmationApproved");
    setShowAddServiceConfirmation(true);
    setIsOpen(false); // Close the menu after clicking "Delete"
  };

  const showAddServiceHandler = () => {
    setAddServiceModalOpen(!addServiceModalOpen);
  };

  const closeAddServiceHandler = () => {
    setAddServiceModalOpen(!addServiceModalOpen);
    scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  // USE EFFECT

  // useEffect to handle scrolling when the add service modal is opened
  // useEffect(() => {
  //   if (addServiceModalOpen) {
  //     ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  //   } else {
  //     // scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  // }, [addServiceModalOpen]); // Depend on addServi

  // Initialize selectedEmployees with default employees when the component mounts
  useEffect(() => {
    setSelectedEmployees(services.map((service) => service.employee));
  }, [services]);

  useEffect(() => {
    if (!loadingServices && allServices && allServices.length > 0) {
    }
  }, [loadingServices, allServices]);

  // LOGS

  console.log("services", services);
  // console.log("selectedEmployees", selectedEmployees);
  console.log("filteredServices", filteredServices);

  console.log("employees", employees);

  return (
    <>
      {showAddServiceConfirmation && (
        <ConfirmAddServiceModal
          isOpen={showAddServiceConfirmation}
          onClose={() => setShowAddServiceConfirmation(false)}
          confirmAction={() => {
            setAddServiceModalOpen(!addServiceModalOpen);
            setShowAddServiceConfirmation(false);
          }}
        />
      )}

      {loading ? (
        <Flex justify="center" align="center" h="100%">
          <Spinner color={primary} />
        </Flex>
      ) : error ? (
        <Text>{error}</Text>
      ) : (
        <Box w="100%">
          <Flex
            direction="column"
            w="100%"
            bg={backgroundLight}
            borderRadius="1rem"
            p="0rem 1.5rem 0rem"
          >
            {services && services.length > 0 ? (
              <Flex w="100%" direction="column">
                <Box>
                  <Flex
                    direction="column"
                    w="100%"
                    bg={backgroundLight}
                    p="1rem 0"
                    borderRadius="1rem"
                    overflow="hidden"
                  >
                    {filteredServices
                      .sort((a, b) => a.order - b.order)
                      .map((service, index) => (
                        <Box
                          key={service._id}
                          w="100%"
                          bg={white}
                          p="1rem 1.5rem 0.75rem"
                          borderRadius="1rem"
                          mb="1rem"
                          _last={{ mb: "0rem" }}
                          border={
                            service.quantity === 0
                              ? `2px solid #ffabb6`
                              : `1px solid ${white}`
                          }
                          ref={scrollRef}
                        >
                          <Flex
                            direction="column"
                            align="center"
                            justify="space-between"
                            w="100%"
                          >
                            <Flex
                              w="100%"
                              className="green"
                              borderBottom={`1px solid ${newBorder}`}
                              p="0 0 0.5rem"
                            >
                              <Flex>
                                <Text
                                  fontWeight="500"
                                  fontSize="sm"
                                  color={textSecondary}
                                  mr={4}
                                  lineHeight={1.7}
                                >
                                  {service.order && service.order}
                                </Text>
                              </Flex>
                              <Flex
                                align="center"
                                justify="space-between"
                                w="100%"
                              >
                                <Flex direction="column" w="100%">
                                  <Flex
                                    justify="space-between"
                                    align="flex-start"
                                    p="0rem 0 0rem"
                                    w="100%"
                                  >
                                    <Flex align="baseline" w="100%">
                                      <Stack gap={0} width="300px">
                                        <Tooltip label={service.service.name}>
                                          <Text
                                            fontWeight="600"
                                            fontSize="1rem"
                                            color={textPrimary}
                                            overflow="hidden"
                                            isTruncated
                                            noOfLines={1}
                                            whiteSpace="normal"
                                            maxWidth="280px"
                                            verticalAlign="middle"
                                            lineHeight={1.5}
                                          >
                                            {service.service.name || ""}
                                          </Text>
                                        </Tooltip>
                                        <Text
                                          color={textSecondary}
                                          fontWeight="500"
                                          fontSize="sm"
                                        >
                                          {service.service.serviceGroup &&
                                            service.service.serviceGroup.name}
                                        </Text>
                                      </Stack>
                                      <TeethPopoverAlt
                                        service={service}
                                        saveEditedService={saveEditedService}
                                        initialTeeth={service.teeth}
                                        isEdit={isEdit}
                                        isDisabled={
                                          status === "cancelled" ||
                                          status === "completed" ||
                                          status === "approved" ||
                                          status === "ongoing"
                                        }
                                      />
                                    </Flex>
                                    <Flex align="center">
                                      <StatusButton
                                        isServiceButton
                                        isRounded
                                        currentStatus={service.status}
                                        options="service"
                                        onUpdateStatus={() =>
                                          serviceStatusHandler(service._id)
                                        }
                                        onChangeStatus
                                        isUnclickable
                                        isDisabled={
                                          status === "consulting" ||
                                          status === "planning" ||
                                          status === "cancelled"
                                        }
                                      />

                                      <MoreButton
                                        id={service._id}
                                        deleteHandler={(e) => {
                                          // e.stopPropagation();
                                          // console.log("delete");
                                          removeService(service._id);
                                        }}
                                        upHandler={(e) => {
                                          e.stopPropagation();
                                          moveServiceUp(service._id);
                                        }}
                                        downHandler={(e) => {
                                          e.stopPropagation();
                                          moveServiceDown(service._id);
                                        }}
                                        isDisabled={
                                          status === "cancelled" ||
                                          status === "completed"
                                        }
                                      />
                                    </Flex>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Flex>

                            <Flex
                              align="center"
                              justify="space-between"
                              p="0.5rem 0 0"
                              w="100%"
                            >
                              <Flex align="center" w="50%" justify="flex-start">
                                <Box ml={3}>
                                  <DatePopover
                                    key={service._id}
                                    initialDate={
                                      appointmentDates[service._id]
                                        ? appointmentDates[service._id]
                                        : null
                                    }
                                    service={service}
                                    saveEditedService={saveEditedService}
                                    isDisabled={
                                      status === "cancelled" ||
                                      status === "completed"
                                    }
                                  />
                                </Box>

                                <Box ml={0}>
                                  <UserPopover
                                    initialUser={
                                      isEdit ? service.employee : null
                                    }
                                    options={employees}
                                    onChange={(selectedEmployee) =>
                                      handleEmployeeChange(
                                        service._id,
                                        selectedEmployee
                                          ? selectedEmployee._id
                                          : null
                                      )
                                    }
                                    isDisabled={
                                      status === "cancelled" ||
                                      status === "completed"
                                    }
                                  />
                                </Box>
                              </Flex>

                              <Flex
                                align="center"
                                justify="flex-end"
                                ml={0}
                                w="45%"
                              >
                                <Flex align="center">
                                  <PricePopover
                                    initialPrice={service.price}
                                    service={service}
                                    saveEditedService={saveEditedService}
                                    isDisabled={
                                      status === "cancelled" ||
                                      status === "completed" ||
                                      status === "approved" ||
                                      status === "ongoing"
                                    }
                                  />

                                  <Box ml={0}>
                                    <QuantityPopover
                                      initialQty={service.quantity}
                                      service={service}
                                      saveEditedService={saveEditedService}
                                      totalPrice={
                                        isEdit
                                          ? service.price * service.quantity
                                          : service.totalPrice
                                      }
                                      isDisabled={
                                        status === "cancelled" ||
                                        status === "completed" ||
                                        status === "approved" ||
                                        status === "ongoing" ||
                                        (isEdit
                                          ? service.service.priceVariations ===
                                            false
                                          : service.priceVariations === false)
                                      }
                                    />
                                  </Box>
                                </Flex>

                                <Flex align="center" ml={1}>
                                  <Text
                                    color={textSecondary}
                                    fontWeight="500"
                                    fontSize="xs"
                                    whiteSpace="nowrap"
                                  >
                                    {t("stats.totalPrice")}:
                                  </Text>

                                  <Text
                                    ml={1}
                                    color={textSecondary}
                                    fontWeight="500"
                                    fontSize="0.875rem"
                                    whiteSpace="nowrap"
                                  >
                                    {service.price * service.quantity} €
                                  </Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Box>
                      ))}
                  </Flex>
                </Box>
              </Flex>
            ) : (
              <Flex
                w="100%"
                bg={backgroundLight}
                p="0rem 0 0rem"
                borderRadius="1rem"
                align="center"
                justify="center"
                direction="column"
              >
                {!addServiceModalOpen && (
                  <>
                    <Flex
                      py={4}
                      textAlign="center"
                      justify="center"
                      direction="column"
                      align="center"
                    >
                      <Image h="2.5rem" w="2.5rem" mb={2} src={noServices} />
                      <Text
                        fontSize="sm"
                        fontWeight="300"
                        color={textSecondary}
                      >
                        {t("service.NoService")}
                      </Text>
                    </Flex>
                  </>
                )}
              </Flex>
            )}
          </Flex>
          <Flex
            direction="column"
            w="100%"
            minHeight={isAppointment ? "initial" : "initial"}
          >
            {!addServiceModalOpen && (
              <Flex w="100%" p="1rem 0" justify="center" xw>
                <Button
                  ml={3}
                  bg={backgroundLight}
                  border={`0px solid ${newBorder}`}
                  color={textSecondary}
                  _hover={{
                    bg: "#F4DEDB",
                    color: textPrimary,
                  }}
                  px={10}
                  variant="ghost"
                  onClick={
                    status === "approved" || status === "ongoing"
                      ? handleAddServiceConfirmationApproved
                      : handleAddServiceConfirmation
                  }
                  borderRadius="10.75rem"
                  fontSize="md"
                  size="lg"
                  fontWeight="500"
                  isDisabled={status === "cancelled" || status === "completed"}
                >
                  {t("service.AddNew")}
                </Button>
              </Flex>
            )}
            {addServiceModalOpen && (
              <Flex
                w="100%"
                justify="center"
                p={isAppointment ? "0rem" : "2rem 0 0rem"}
                direction="column"
                m="0 auto"
                align="center"
              >
                {!isAppointment && (
                  <Text
                    color={textPrimary}
                    fontSize="mfd"
                    fontWeight="500"
                    mt={2}
                    mb={2}
                  >
                    {t("service.AddServiceToTreatmentPlan")}
                  </Text>
                )}

                <Flex
                  align="center"
                  justify="center"
                  direction="column"
                  w={w}
                  bg={backgroundLight}
                  borderRadius="1rem"
                  p="1rem"
                >
                  <Flex align="center" w="100%">
                    <ServiceSelect
                      value={""}
                      onChange={addServiceToList}
                      services={allServices}
                    />
                    {/* <Button onClick={showAddServiceHandler} ml={4} size="sm">
                  Cancel
                </Button> */}
                  </Flex>
                </Flex>
                <Button onClick={closeAddServiceHandler} mt={4} size="sm">
                  {t("button.cancel")}
                </Button>
              </Flex>
            )}
            {/* <div>
          <Button bg={primary} onClick={handleClick}>
            Scroll to element
          </Button>

          <div style={{ height: "150rem" }} />

          <div ref={ref} style={{ backgroundColor: "lightblue" }}>
            Coding Beauty
          </div>

          <div style={{ height: "150rem" }} />
        </div> */}
          </Flex>

          <div ref={ref} />
        </Box>
      )}
    </>
  );
};

export default ServiceList;
