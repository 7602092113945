import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Icon,
} from "@chakra-ui/react";
import {
  white,
  newBorder,
  textPrimary,
  backgroundLight,
  primary,
  textSecondary,
} from "../../utils/colors";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { TbDots } from "react-icons/tb";
import { useTranslation } from "react-i18next";

dayjs.extend(duration);

const FormControlSliderPicker = ({
  isRequired,
  label,
  isError,
  isTouched,
  w,
  errorMessage,
  isSmall,
  min,
  max,
  value,
  setValue,
  step,
}) => {
  const { t } = useTranslation(); // Initialize the translation function

  // This function will be called whenever the slider values change
  const handleChange = (value) => {
    setValue(value);
  };

  const formatDuration = (minutes) => {
    // Already in minutes, so no need for conversion
    const dur = dayjs.duration(minutes, "minutes");
    let formatted = "";
    if (dur.hours() > 0) {
      formatted += `${dur.hours()} ${t("common.hours")} `;
    }
    if (dur.minutes() > 0 || dur.hours() === 0) {
      formatted += `${dur.minutes()} ${t("common.minutes")}`;
    }
    return formatted;
  };

  console.log("min", min);
  console.log("max", max);
  console.log("step", step);

  return (
    <FormControl
      mb={8}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel
        fontSize={isSmall ? "xs" : "sm"}
        position="relative"
        color={textPrimary}
      >
        {label}

        {isError && isTouched && (
          <Box position="absolute" top={-1} right={0} zIndex={2}>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </Box>
        )}
      </FormLabel>
      <Box w="100%" p="0 1rem">
        <Slider
          defaultValue={value}
          min={min}
          max={max}
          step={step}
          onChange={handleChange}
        >
          <SliderTrack bg={backgroundLight}>
            <SliderFilledTrack bg={primary} />
          </SliderTrack>
          <SliderThumb boxSize={6}>
            <Icon color={textSecondary} as={TbDots} />
            <Text
              fontSize="13px"
              color={textSecondary}
              fontWeight="500"
              position="absolute"
              top="100%"
              mt={2}
              whiteSpace="nowrap"
            >
              {formatDuration(value)}
            </Text>
          </SliderThumb>
        </Slider>
      </Box>
    </FormControl>
  );
};

export default FormControlSliderPicker;
