import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_RESET,
  USER_UPDATE_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_PROFILE_RESET,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_UPDATE_STATUS_REQUEST,
  USER_UPDATE_STATUS_SUCCESS,
  USER_UPDATE_STATUS_FAIL,
  USER_UPDATE_STATUS_RESET,
  USER_PATIENTS_LIST_REQUEST,
  USER_PATIENTS_LIST_SUCCESS,
  USER_PATIENTS_LIST_FAIL,
  USER_EMPLOYEES_LIST_REQUEST,
  USER_EMPLOYEES_LIST_SUCCESS,
  USER_EMPLOYEES_LIST_FAIL,
  USER_CREATE_RESET,
  USER_ADD_NOTE_REQUEST,
  USER_ADD_NOTE_SUCCESS,
  USER_ADD_NOTE_FAIL,
  USER_ADD_NOTE_RESET,
  USER_LIST_NOTES_REQUEST,
  USER_LIST_NOTES_SUCCESS,
  USER_LIST_NOTES_FAIL,
  USER_LIST_NOTES_RESET,
  USER_CANCEL_ALL_TREATMENTS_REQUEST,
  USER_CANCEL_ALL_TREATMENTS_SUCCESS,
  USER_CANCEL_ALL_TREATMENTS_FAIL,
  USER_CANCEL_ALL_TREATMENTS_RESET,
  PATIENT_STATISTICS_REQUEST,
  PATIENT_STATISTICS_FAIL,
  PATIENT_STATISTICS_SUCCESS,
  PATIENT_STATISTICS_RESET,
  USER_CREATE_EMPLOYEE_REQUEST,
  USER_CREATE_EMPLOYEE_SUCCESS,
  USER_CREATE_EMPLOYEE_FAIL,
  USER_CREATE_EMPLOYEE_RESET,
  USER_EMPLOYEE_DETAILS_REQUEST,
  USER_EMPLOYEE_DETAILS_SUCCESS,
  USER_EMPLOYEE_DETAILS_FAIL,
  USER_EMPLOYEE_DETAILS_RESET,
  USER_DOCTOR_SERVICES_REQUEST,
  USER_DOCTOR_SERVICES_SUCCESS,
  USER_DOCTOR_SERVICES_FAIL,
  USER_DOCTOR_SERVICES_RESET,
  USER_ADDRESS_STATES_REQUEST,
  USER_ADDRESS_STATES_SUCCESS,
  USER_ADDRESS_STATES_FAIL,
  USER_ADDRESS_STATES_RESET,
  USER_SEND_TREATMENTPLAN_LIST_EMAIL_REQUEST,
  USER_SEND_TREATMENTPLAN_LIST_EMAIL_SUCCESS,
  USER_SEND_TREATMENTPLAN_LIST_EMAIL_FAIL,
  USER_SEND_TREATMENTPLAN_LIST_EMAIL_RESET,
  USER_PATIENTS_SIMPLE_LIST_REQUEST,
  USER_PATIENTS_SIMPLE_LIST_SUCCESS,
  USER_PATIENTS_SIMPLE_LIST_FAIL,
  USER_PATIENTS_SIMPLE_LIST_RESET,
} from "../constants/userConstants";

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return { loading: true };
    case USER_CREATE_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case USER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { user: {} };
    default:
      return state;
  }
};

export const userEmployeeDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_EMPLOYEE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_EMPLOYEE_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_EMPLOYEE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_EMPLOYEE_DETAILS_RESET:
      return { user: {} };
    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true, users: [] };
    case USER_LIST_SUCCESS:
      return {
        loading: false,
        users: action.payload.users,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case USER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const userUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_RESET:
      return {
        user: {},
      };
    default:
      return state;
  }
};

export const userUpdateStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_STATUS_REQUEST:
      return { loading: true };
    case USER_UPDATE_STATUS_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_STATUS_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_STATUS_RESET:
      return {};
    default:
      return state;
  }
};

export const userPatientsListReducer = (
  state = { patients: [], countsByStatus: {}, totalCount: 0 },
  action
) => {
  switch (action.type) {
    case USER_PATIENTS_LIST_REQUEST:
      return { loading: true, patients: [] };
    case USER_PATIENTS_LIST_SUCCESS:
      return {
        loading: false,
        patients: action.payload.patients,
        pages: action.payload.pages,
        page: action.payload.page,
        countsByStatus: action.payload.countsByStatus,
        totalCount: action.payload.totalCount,
      };
    case USER_PATIENTS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userEmployeesListReducer = (
  state = {
    employees: [],
    countsByStatus: {},
    globalTotalCount: 0,
    countsByProfession: {},
  },
  action
) => {
  switch (action.type) {
    case USER_EMPLOYEES_LIST_REQUEST:
      return { loading: true, employees: [] };
    case USER_EMPLOYEES_LIST_SUCCESS:
      return {
        loading: false,
        employees: action.payload.employees,
        pages: action.payload.pages,
        page: action.payload.page,
        countsByStatus: action.payload.countsByStatus,
        globalTotalCount: action.payload.globalTotalCount,
        countsByProfession: action.payload.countsByProfession,
      };
    case USER_EMPLOYEES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userAddNoteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ADD_NOTE_REQUEST:
      return { loading: true };
    case USER_ADD_NOTE_SUCCESS:
      return { loading: false, success: true };
    case USER_ADD_NOTE_FAIL:
      return { loading: false, error: action.payload };
    case USER_ADD_NOTE_RESET:
      return {};
    default:
      return state;
  }
};
export const userListNotesReducer = (state = { notes: [] }, action) => {
  switch (action.type) {
    case USER_LIST_NOTES_REQUEST:
      return { loading: true, notes: [] };
    case USER_LIST_NOTES_SUCCESS:
      return {
        loading: false,
        notes: action.payload, // Assuming payload is the array itself
      };
    case USER_LIST_NOTES_FAIL:
      return { loading: false, error: action.payload };
    case USER_LIST_NOTES_RESET:
      return { notes: [] };
    default:
      return state;
  }
};

export const userCancelAllTreatmentsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CANCEL_ALL_TREATMENTS_REQUEST:
      return { loading: true };
    case USER_CANCEL_ALL_TREATMENTS_SUCCESS:
      return { loading: false, success: true };
    case USER_CANCEL_ALL_TREATMENTS_FAIL:
      return { loading: false, error: action.payload };
    case USER_CANCEL_ALL_TREATMENTS_RESET:
      return {};
    default:
      return state;
  }
};

export const patientStatisticsReducer = (state = { stats: {} }, action) => {
  switch (action.type) {
    case PATIENT_STATISTICS_REQUEST:
      return { loading: true, stats: {} };
    case PATIENT_STATISTICS_SUCCESS:
      return {
        loading: false,
        stats: action.payload,
      };
    case PATIENT_STATISTICS_FAIL:
      return { loading: false, error: action.payload };
    case PATIENT_STATISTICS_RESET:
      return { stats: {} };
    default:
      return state;
  }
};

export const userCreateEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_EMPLOYEE_REQUEST:
      return { loading: true };
    case USER_CREATE_EMPLOYEE_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case USER_CREATE_EMPLOYEE_FAIL:
      return { loading: false, error: action.payload };
    case USER_CREATE_EMPLOYEE_RESET:
      return {};

    default:
      return state;
  }
};

export const userDoctorServicesReducer = (
  state = { doctorServices: [] },
  action
) => {
  switch (action.type) {
    case USER_DOCTOR_SERVICES_REQUEST:
      return { loading: true, doctorServices: [] };
    case USER_DOCTOR_SERVICES_SUCCESS:
      return {
        loading: false,
        doctorServices: action.payload,
      };
    case USER_DOCTOR_SERVICES_FAIL:
      return { loading: false, error: action.payload };
    case USER_DOCTOR_SERVICES_RESET:
      return { doctorServices: [] };
    default:
      return state;
  }
};

export const userAddressStatesReducer = (
  state = { addressStates: [] },
  action
) => {
  switch (action.type) {
    case USER_ADDRESS_STATES_REQUEST:
      return { loading: true, addressStates: [] };
    case USER_ADDRESS_STATES_SUCCESS:
      return {
        loading: false,
        addressStates: action.payload,
      };
    case USER_ADDRESS_STATES_FAIL:
      return { loading: false, error: action.payload };
    case USER_ADDRESS_STATES_RESET:
      return { addressStates: [] };
    default:
      return state;
  }
};

export const userSendTreatmentPlanListEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SEND_TREATMENTPLAN_LIST_EMAIL_REQUEST:
      return { loading: true };
    case USER_SEND_TREATMENTPLAN_LIST_EMAIL_SUCCESS:
      return { loading: false, success: true };
    case USER_SEND_TREATMENTPLAN_LIST_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    case USER_SEND_TREATMENTPLAN_LIST_EMAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const userPatientsSimpleListReducer = (
  state = { patients: [] },
  action
) => {
  switch (action.type) {
    case USER_PATIENTS_SIMPLE_LIST_REQUEST:
      return { loading: true, patients: [] };
    case USER_PATIENTS_SIMPLE_LIST_SUCCESS:
      return {
        loading: false,
        patients: action.payload.patients,
      };
    case USER_PATIENTS_SIMPLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
