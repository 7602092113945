import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Button,
  HStack,
  Text,
  Stack,
  Heading,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, updateUser } from "../../../../actions/userActions";
import {
  newBorder,
  backgroundLight,
  textSecondary,
  textPrimary,
  white,
  primary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import dayjs from "dayjs";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlDatePicker from "../../../../components/input/FormControlDatePicker";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlAddress from "../../../../components/input/FormControlAddress";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import FormControlProfession from "../../../../components/input/FormControlProfession";
import FormControlBank from "../../../../components/input/FormControlBank";
import FormControlRolePermission from "../../../../components/input/FormControlRolePermission";

const EditEmployee = ({ userId, close }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // CONSTANTS
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userID, setUserID] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneAlt, setPhoneAlt] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [gender, setGender] = useState("");
  const [personalCode, setPersonalCode] = useState("");
  const [status, setStatus] = useState("");
  const [address, setAddress] = useState("");
  const [rolePermission, setRolePermission] = useState("");

  const [profession, setProfession] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [iban, setIban] = useState("");
  const [bankName, setBankName] = useState("");

  const [ageType, setAgeType] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [birthDateError, setBirthDateError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [streetError, setStreetError] = useState("");
  const [zipError, setZipError] = useState("");
  const [personalCodeError, setPersonalCodeError] = useState("");
  const [cityError, setCityError] = useState("");

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { success } = userUpdate;

  // VALIDATION
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [birthDateTouched, setBirthDateTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [streetTouched, setStreetTouched] = useState(false);
  const [zipTouched, setZipTouched] = useState(false);
  const [personalCodeTouched, setPersonalCodeTouched] = useState(false);
  const [cityTouched, setCityTouched] = useState(false);
  const [countryTouched, setCountryTouched] = useState(false);
  const [professionTouched, setProfessionTouched] = useState(false);

  const isFirstNameError = firstName === "";
  const isLastNameError = lastName === "";
  const isBirthDateError = birthDate === "";
  const isPersonalCodeError = personalCode === "";
  const isEmailError = email === "";
  const isPhoneError = phone === "";
  const isStreetError = street === "";
  const isZipError = zip === "";
  const isCityError = city === "";
  const isCountryError = country === "";
  const isProfessionError = profession === "";

  // FUNCTIONS

  const calculateAge = (birthDate) => {
    const birthday = dayjs(birthDate);
    const age = dayjs().diff(birthday, "year");
    return age;
  };

  // HANDLERS

  const handleScriptLoad = (loaded) => {
    setIsGoogleScriptLoaded(loaded);
  };

  // Handler for when an address is selected
  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateUser({
        _id: userId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        birthDate: birthDate,
        phone,
        phoneAlt,
        personalCode,
        status,
        street,
        zip,
        city,
        country,
        state,
        profession,
        licenseNumber,
        iban,
        bankName,
        rolePermission,
      })
    );

    close();
  };

  const closeHandler = () => {
    close();
    dispatch({ type: "USER_UPDATE_RESET" });
  };

  // Handler for profession change
  const onProfessionChange = (selectedOption) => {
    setProfession(selectedOption.value);
    // Assuming selectedOption has the defaultRolePermission property
    const defaultRolePermission = selectedOption.defaultRolePermission;
    if (defaultRolePermission) {
      setRolePermission(defaultRolePermission);
    }
  };

  // USEEFFECT

  useEffect(() => {
    if (birthDate) {
      const age = calculateAge(birthDate);
      const newAgeType = age >= 18 ? "adult" : "kid";

      setAgeType(newAgeType);
    }
  }, [birthDate]);

  useEffect(() => {
    if (user._id !== userId) {
      dispatch(getUserDetails(userId));
    } else {
      setUserID(user.userID);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setBirthDate(user.birthDate);
      setPersonalCode(user.personalCode);
      setEmail(user.email);
      setPhone(user.phone);
      setPhoneAlt(user.phoneAlt);
      setStatus(user.status);
      if (user.address && user.address.length > 0) {
        setStreet(user.address[0].street);
        setZip(user.address[0].zip);
        setCity(user.address[0].city);
        setCountry(user.address[0].country);
        setState(user.address[0].state);
      }
      setProfession(user.profession);
      setLicenseNumber(user.licenseNumber);
      // Check if bankAccountDetails exists and has at least one entry
      if (user.bankAccountDetails && user.bankAccountDetails.length > 0) {
        setIban(user.bankAccountDetails[0].iban);
        setBankName(user.bankAccountDetails[0].bankName || "");
      } else {
        setIban("");
        setBankName("");
      }

      setAddress(user.address);
      setRolePermission(user.rolePermission);
    }
  }, [dispatch, userId, user]);

  // console.log("firstName", firstName);
  // console.log("lastName", lastName);
  // console.log("image", image);
  // console.log("email", email);
  console.log("street", street);
  // console.log("role", role);
  // console.log("country", country);
  // console.log("street", street);
  // console.log("zip", zip);
  // console.log("city", city);
  // console.log("state", state);

  return (
    <>
      <Box position="relative">
        <form
          onSubmit={submitHandler}
          style={{
            height: "100%",
          }}
        >
          <Flex
            justify="space-between"
            align="center"
            p="1.5rem 2rem 1.5rem"
            borderBottom={`1px solid ${newBorder}`}
            mb="0rem"
            position="absolute"
            zIndex={2}
            bg={white}
            w="100%"
          >
            <Flex align="center">
              <IconButton
                bg="none"
                border={`1px solid ${newBorder}`}
                color={textSecondary}
                onClick={close}
                icon={<FaArrowRightFromBracket />}
                borderRadius="0.75rem"
                fontSize="0.75rem"
              />
              <Stack gap={0} ml={4}>
                <Flex align="center">
                  <Heading
                    fontSize="1.275rem"
                    color={textPrimary}
                    fontWeight="600"
                    mr={4}
                  >
                    {t("employee.editEmployee")}
                  </Heading>

                  {/* <StatusBadge status={status} /> */}
                </Flex>

                <Text fontSize="sm" color={textSecondary} fontWeight="500">
                  {ageType === "adult" ? t("common.adult") : t("common.child")}
                </Text>
              </Stack>
            </Flex>
            <Stack gap={0}>
              <Flex align="center"></Flex>
            </Stack>
          </Flex>

          <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="7rem 0 0"
            >
              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("employee.details")}
              </Heading>
              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1.5rem"
              >
                <HStack gap={4} w="100%">
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.firstName")}
                    value={firstName}
                    placeholder={t("placeholder.firstName")}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setFirstNameTouched(true);
                    }}
                    isTouched={firstNameTouched}
                    isError={isFirstNameError}
                    w="50%"
                    errorMessage={t("formError.firstNameRequired")}
                  />

                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.lastName")}
                    value={lastName}
                    placeholder={t("placeholder.lastName")}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setLastNameTouched(true);
                    }}
                    isTouched={lastNameTouched}
                    isError={isLastNameError}
                    w="50%"
                    errorMessage={t("formError.lastNameRequired")}
                  />
                </HStack>

                <HStack gap={4} w="100%">
                  <FormControlDatePicker
                    type="date"
                    isRequired
                    label={t("form.birthDate")}
                    value={birthDate}
                    // value="1970-01-01T00:00:34.357Z"
                    // value="1970-01-01T00:00:33.246Z"
                    placeholder={t("placeholder.birthDate")}
                    onChange={(newValue) => {
                      setBirthDate(newValue);
                    }}
                    onOpen={() => {}}
                    onClose={() => setBirthDateTouched(true)}
                    isTouched={birthDateTouched}
                    isError={isBirthDateError}
                    w="50%"
                    errorMessage={t("formError.birthDateRequired")}
                  />
                  <FormControlDefault
                    type="number"
                    isRequired
                    label={t("form.personalCode")}
                    value={personalCode}
                    placeholder={t("placeholder.personalCode")}
                    onChange={(e) => {
                      setPersonalCode(e.target.value);
                      setPersonalCodeTouched(true);
                    }}
                    isTouched={personalCodeTouched}
                    isError={isPersonalCodeError}
                    w="50%"
                    errorMessage={t("formError.personalCodeRequired")}
                  />
                </HStack>
                <HStack gap={4} w="100%">
                  <FormControlProfession
                    label={t("form.profession")}
                    value={profession}
                    placeholder={t("placeholder.profession")}
                    onChange={onProfessionChange}
                    setIsTouched={() => setProfessionTouched(true)}
                    isRequired={true}
                    isTouched={professionTouched}
                    isError={isProfessionError}
                    errorMessage={t("formError.professionRequired")}
                    w="50%"
                    onProfessionChange={onProfessionChange}
                  />

                  <FormControlDefault
                    type="text"
                    // isRequired
                    label={t("form.licenseNumber")}
                    value={licenseNumber}
                    placeholder={t("placeholder.licenseNumber")}
                    onChange={(e) => {
                      setLicenseNumber(e.target.value);
                    }}
                    w="50%"
                  />
                </HStack>
              </Flex>
            </Flex>

            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="0 0 4rem"
            >
              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("employee.addressContactDetails")}
              </Heading>
              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1.5rem"
              >
                <HStack gap={4} w="100%">
                  <FormControlPhone
                    label={t("form.phone")}
                    value={phone}
                    onChange={(newPhone) => {
                      setPhone(newPhone);
                      setPhoneTouched(true);
                    }}
                    isRequired={true}
                    isTouched={phoneTouched}
                    isError={isPhoneError}
                    errorMessage={t("formError.phoneRequired")}
                    w="50%"
                    defaultCountry="lt"
                  />

                  <FormControlPhone
                    label={t("form.phoneAlt")}
                    value={phoneAlt}
                    onChange={(newPhone) => {
                      setPhoneAlt(newPhone);
                    }}
                    isRequired={false}
                    errorMessage={t("formError.phoneRequired")}
                    w="50%"
                    defaultCountry="lt"
                  />
                </HStack>

                <FormControlDefault
                  isDisabled
                  type="email"
                  isRequired
                  label={t("form.email")}
                  value={email}
                  placeholder={t("placeholder.email")}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailTouched(true);
                  }}
                  isTouched={emailTouched}
                  isError={isEmailError}
                  w="100%"
                  errorMessage={t("formError.emailRequired")}
                />

                <HStack gap={4} w="100%">
                  <FormControlAddress
                    currentValue={street}
                    label={t("form.address")}
                    onAddressSelect={handleAddressSelect}
                    isRequired={true}
                    isTouched={streetTouched} // Assuming you have similar touch state for street
                    isError={isStreetError} // Assuming you have similar error state for street
                    setIsTouched={setStreetTouched}
                    errorMessage={t("formError.addressRequired")}
                    w="100%"
                    googleApiKey="AIzaSyCNQfHnntkLgj5Es5V8V0H2BfIetOOZ9yw"
                  />
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.zip")}
                    value={zip}
                    placeholder={t("placeholder.zip")}
                    onChange={(e) => {
                      setZip(e.target.value);
                      setZipTouched(true);
                    }}
                    isTouched={zipTouched}
                    isError={isZipError}
                    w="40%"
                    errorMessage={t("formError.zipRequired")}
                  />
                </HStack>

                <HStack gap={4} w="100%">
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.city")}
                    value={city}
                    placeholder={t("placeholder.city")}
                    onChange={(e) => {
                      setCity(e.target.value);
                      setCityTouched(true);
                    }}
                    isTouched={cityTouched}
                    isError={isCityError}
                    w="50%"
                    errorMessage={t("formError.cityRequired")}
                  />

                  <FormControlCountry
                    label={t("form.country")}
                    value={country}
                    isTouched={countryTouched}
                    isError={isCountryError}
                    onChange={(selectedOption) => {
                      setCountry(selectedOption ? selectedOption.value : "");
                      setCountryTouched(true);
                      if (selectedOption) setCityError("");
                    }}
                    isRequired={true}
                    w="50%"
                    errorMessage={t("formError.countryRequired")}
                  />
                </HStack>
              </Flex>
              <Flex direction="column" w="100%" p="0rem 0" mb={2}>
                <Accordion allowToggle>
                  <AccordionItem border="none">
                    <AccordionButton
                      borderRadius="0rem"
                      borderBottom={`1px solid ${newBorder}`}
                      transition="0.2s all"
                      p="0"
                      _hover={{
                        bg: "none",
                        pl: 2,
                        transition: "0.2s all",
                      }}
                      _expanded={{
                        borderBottom: "none",
                      }}
                    >
                      <Flex align="center" justify="space-between" w="100%">
                        <Heading
                          fontSize="1.125rem"
                          color={textPrimary}
                          fontWeight="600"
                          m="1rem 0"
                        >
                          {t("employee.bankDetails")}
                        </Heading>
                        <AccordionIcon color={textSecondary} />
                      </Flex>
                    </AccordionButton>

                    <AccordionPanel pb={0} px={0}>
                      <Flex
                        direction="column"
                        w="100%"
                        bg={backgroundLight}
                        borderRadius="1rem"
                        p="1.5rem"
                      >
                        <HStack gap={4} w="100%">
                          <FormControlDefault
                            type="text"
                            // isRequired
                            label={t("form.iban")}
                            value={iban}
                            placeholder={t("placeholder.iban")}
                            onChange={(e) => {
                              setIban(e.target.value);
                            }}
                            // isTouched={firstNameTouched}
                            // isError={isFirstNameError}
                            w="50%"
                            // errorMessage={t("formError.firstNameRequired")}
                          />

                          <FormControlBank
                            label={t("form.bankName")}
                            value={bankName}
                            placeholder={t("placeholder.bankName")}
                            onChange={(selectedOption) => {
                              setBankName(selectedOption.value);
                            }}
                            w="50%"
                          />
                        </HStack>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>

              <Flex direction="column" w="100%" p="0rem 0">
                <Accordion allowToggle>
                  <AccordionItem border="none">
                    <AccordionButton
                      borderRadius="0rem"
                      borderBottom={`1px solid ${newBorder}`}
                      transition="0.2s all"
                      p="0"
                      _hover={{
                        bg: "none",
                        pl: 2,
                        transition: "0.2s all",
                      }}
                      _expanded={{
                        borderBottom: "none",
                      }}
                    >
                      <Flex align="center" justify="space-between" w="100%">
                        <Heading
                          fontSize="1.125rem"
                          color={textPrimary}
                          fontWeight="600"
                          m="1rem 0"
                        >
                          {t("employee.additionalDetails")}
                        </Heading>
                        <AccordionIcon color={textSecondary} />
                      </Flex>
                    </AccordionButton>

                    <AccordionPanel pb={0} px={0}>
                      <Flex
                        direction="column"
                        w="100%"
                        bg={backgroundLight}
                        borderRadius="1rem"
                        p="1.5rem"
                      >
                        <HStack gap={4} w="100%">
                          <FormControlRolePermission
                            isRequired
                            label={t("form.rolePermission")}
                            value={rolePermission}
                            placeholder={t("placeholder.rolePermission")}
                            onChange={(selectedOption) => {
                              setRolePermission(selectedOption.value);
                            }}
                            w="50%"
                          />
                        </HStack>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>
            </Flex>
            <Flex
              mt={4}
              justify="center"
              direction="column"
              borderTop={`1px solid ${newBorder}`}
              p="1.5rem 0"
              position="relative"
              zIndex={2}
              bg={white}
              bottom="0"
              w="100%"
            >
              <Flex justify="flex-end">
                <Button
                  minWidth="10rem"
                  mr={4}
                  bg={backgroundLight}
                  size="lg"
                  color={textSecondary}
                  fontSize="md"
                  borderRadius="10rem"
                  onClick={closeHandler}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  minWidth="10rem"
                  type="submit"
                  size="lg"
                  bg={primary}
                  color={white}
                  fontSize="md"
                  borderRadius="10rem"
                  isDisabled={
                    !firstName?.trim() ||
                    !lastName?.trim() ||
                    !personalCode?.trim() ||
                    !email?.trim() ||
                    !birthDate?.trim() ||
                    !phone.trim() ||
                    !street?.trim() ||
                    !zip?.trim() ||
                    !city?.trim() ||
                    !country?.trim() ||
                    !profession?.trim()
                  }
                >
                  {t("employee.editEmployee")}
                </Button>
              </Flex>
            </Flex>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default EditEmployee;
