export const SPEAKER_LIST_REQUEST = "SPEAKER_LIST_REQUEST";
export const SPEAKER_LIST_SUCCESS = "SPEAKER_LIST_SUCCESS";
export const SPEAKER_LIST_FAIL = "SPEAKER_LIST_FAIL";

export const SPEAKER_DETAILS_REQUEST = "SPEAKER_DETAILS_REQUEST";
export const SPEAKER_DETAILS_SUCCESS = "SPEAKER_DETAILS_SUCCESS";
export const SPEAKER_DETAILS_FAIL = "SPEAKER_DETAILS_FAIL";
export const SPEAKER_DETAILS_RESET = "SPEAKER_DETAILS_RESET";

export const SPEAKER_UPDATE_REQUEST = "SPEAKER_UPDATE_REQUEST";
export const SPEAKER_UPDATE_SUCCESS = "SPEAKER_UPDATE_SUCCESS";
export const SPEAKER_UPDATE_FAIL = "SPEAKER_UPDATE_FAIL";
export const SPEAKER_UPDATE_RESET = "SPEAKER_UPDATE_RESET";

export const SPEAKER_CREATE_REQUEST = "SPEAKER_CREATE_REQUEST";
export const SPEAKER_CREATE_SUCCESS = "SPEAKER_CREATE_SUCCESS";
export const SPEAKER_CREATE_FAIL = "SPEAKER_CREATE_FAIL";
export const SPEAKER_CREATE_RESET = "SPEAKER_CREATE_RESET";

export const SPEAKER_DELETE_REQUEST = "SPEAKER_DELETE_REQUEST";
export const SPEAKER_DELETE_SUCCESS = "SPEAKER_DELETE_SUCCESS";
export const SPEAKER_DELETE_FAIL = "SPEAKER_DELETE_FAIL";
export const SPEAKER_DELETE_RESET = "SPEAKER_DELETE_RESET";

export const SPEAKER_COURSE_LIST_REQUEST = "SPEAKER_COURSE_LIST_REQUEST";
export const SPEAKER_COURSE_LIST_SUCCESS = "SPEAKER_COURSE_LIST_SUCCESS";
export const SPEAKER_COURSE_LIST_FAIL = "SPEAKER_COURSE_LIST_FAIL";

export const SPEAKER_ADD_COURSE_REQUEST = "SPEAKER_ADD_COURSE_REQUEST";
export const SPEAKER_ADD_COURSE_SUCCESS = "SPEAKER_ADD_COURSE_SUCCESS";
export const SPEAKER_ADD_COURSE_FAIL = "SPEAKER_ADD_COURSE_FAIL";
export const SPEAKER_ADD_COURSE_RESET = "SPEAKER_ADD_COURSE_RESET";

export const SPEAKER_REMOVE_COURSE_REQUEST = "SPEAKER_REMOVE_COURSE_REQUEST";
export const SPEAKER_REMOVE_COURSE_SUCCESS = "SPEAKER_REMOVE_COURSE_SUCCESS";
export const SPEAKER_REMOVE_COURSE_FAIL = "SPEAKER_REMOVE_COURSE_FAIL";
export const SPEAKER_REMOVE_COURSE_RESET = "SPEAKER_REMOVE_COURSE_RESET";

export const SPEAKER_STATUS_UPDATE_REQUEST =
  "SPEAKER_ACTIVE_STATUS_UPDATE_REQUEST";
export const SPEAKER_STATUS_UPDATE_SUCCESS =
  "SPEAKER_ACTIVE_STATUS_UPDATE_SUCCESS";
export const SPEAKER_STATUS_UPDATE_FAIL = "SPEAKER_ACTIVE_STATUS_UPDATE_FAIL";
export const SPEAKER_STATUS_UPDATE_RESET = "SPEAKER_ACTIVE_STATUS_UPDATE_RESET";

export const SPEAKER_DESCRIPTION_UPDATE_REQUEST =
  "SPEAKER_DESCRIPTION_UPDATE_REQUEST";
export const SPEAKER_DESCRIPTION_UPDATE_SUCCESS =
  "SPEAKER_DESCRIPTION_UPDATE_SUCCESS";
export const SPEAKER_DESCRIPTION_UPDATE_FAIL =
  "SPEAKER_DESCRIPTION_UPDATE_FAIL";
export const SPEAKER_DESCRIPTION_UPDATE_RESET =
  "SPEAKER_DESCRIPTION_UPDATE_RESET";

export const SPEAKER_IMAGE_UPDATE_REQUEST = "SPEAKER_IMAGE_UPDATE_REQUEST";
export const SPEAKER_IMAGE_UPDATE_SUCCESS = "SPEAKER_IMAGE_UPDATE_SUCCESS";
export const SPEAKER_IMAGE_UPDATE_FAIL = "SPEAKER_IMAGE_UPDATE_FAIL";
export const SPEAKER_IMAGE_UPDATE_RESET = "SPEAKER_IMAGE_UPDATE_RESET";
