import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { textPrimary, textSecondary } from "../../utils/colors";
import { useTranslation } from "react-i18next";

const ConfirmApproveModal = ({ isOpen, onClose, confirmAction }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent textAlign="center" p="1rem 0">
        <ModalHeader color={textPrimary}>
          {t("common.approveTreatmentPlanConfirmationTitle")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p="1rem 2rem"
          color={textSecondary}
          fontWeight="500"
          fontSize="1rem"
        >
          {t("common.approveTreatmentPlanConfirmationText")}
        </ModalBody>

        <ModalFooter display="flex" justifyContent="center" alignItems="center">
          <Button
            colorScheme="green"
            size="lg"
            borderRadius="10rem"
            onClick={confirmAction}
            m={2}
          >
            {t("common.yes")}
          </Button>
          <Button
            variant="ghost"
            size="lg"
            borderRadius="10rem"
            onClick={onClose}
            m={2}
          >
            {t("common.no")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmApproveModal;
