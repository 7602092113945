import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
  Stack,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { textPrimary, textSecondary, newBorder } from "../../utils/colors";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import useIsMobile from "../../hooks/useIsMobile";

const ConfirmApprovePublicModal = ({
  isOpen,
  onClose,
  confirmAction,
  treatmentPlanDetails,
}) => {
  const { t } = useTranslation();

  const isMobile = useIsMobile();
  console.log("treatmentPlanDetails", treatmentPlanDetails);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isMobile ? false : true}
    >
      <ModalOverlay />
      <ModalContent
        textAlign="center"
        p={["1rem 0", "1rem 0", "1rem 0"]}
        borderRadius="2rem"
        overflow="hidden"
        maxW={["calc(100% - 3rem)", "600px", "600px"]}
      >
        <ModalHeader
          color={textPrimary}
          maxW={["100%", "100%", "100%"]}
          m="0 auto"
          fontSize={["1rem", "1rem", "1.25rem"]}
          p={["1rem 4rem", "2rem", "2rem"]}
        >
          {t("common.approveTreatmentPlanConfirmationTitlePublic")}
        </ModalHeader>
        <ModalCloseButton top={["2rem", "1.5rem", "1.5rem"]} right="1.5rem" />
        <ModalBody
          p="0rem 2rem"
          color={textSecondary}
          fontWeight="500"
          fontSize="1rem"
        >
          <Grid
            align="center"
            justify="space-around"
            w="100%"
            p="2rem 0 2rem"
            templateColumns={[
              "repeat(2, 1fr)",
              "repeat(4, 1fr)",
              "repeat(4, 1fr)",
            ]}
            gap={4}
          >
            <GridItem colSpan={1}>
              <Stack gap={1}>
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  textAlign="left"
                >
                  {t("patient.patient")}
                </Text>
                <Text
                  fontSize="sm"
                  color={textPrimary}
                  fontWeight="600"
                  textAlign="left"
                >
                  {treatmentPlanDetails &&
                    treatmentPlanDetails.patient &&
                    treatmentPlanDetails.patient.firstName +
                      " " +
                      treatmentPlanDetails.patient.lastName}
                </Text>
              </Stack>
            </GridItem>

            <GridItem colSpan={1}>
              <Stack gap={1}>
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  textAlign="left"
                >
                  {t("treatmentPlan.treatmentPlan")}
                </Text>
                <Text
                  fontSize="sm"
                  color={textPrimary}
                  fontWeight="600"
                  textAlign="left"
                >
                  {treatmentPlanDetails && treatmentPlanDetails.treatmentPlanID}
                </Text>
              </Stack>
            </GridItem>

            <GridItem colSpan={1}>
              <Stack gap={1}>
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  textAlign="left"
                >
                  {t("treatmentPlan.createdAt")}
                </Text>
                <Text
                  fontSize="sm"
                  color={textPrimary}
                  fontWeight="600"
                  textAlign="left"
                  textTransform="capitalize"
                >
                  {treatmentPlanDetails &&
                    dayjs(treatmentPlanDetails.createdAt).format("YYYY MMMM D")}
                </Text>
              </Stack>
            </GridItem>

            <GridItem colSpan={1}>
              <Stack gap={1}>
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  textAlign="left"
                >
                  {t("treatmentPlan.columnPrice")}
                </Text>
                <Text
                  fontSize="sm"
                  color={textPrimary}
                  fontWeight="600"
                  textAlign="left"
                >
                  {treatmentPlanDetails && treatmentPlanDetails.totalPrice} €
                </Text>
              </Stack>
            </GridItem>
          </Grid>

          <Flex
            p={["1rem 0rem 2rem", "1rem 2.5rem 2rem", "1rem 2.5rem 2rem"]}
            borderTop={`1px solid ${newBorder}`}
            borderBottom={`1px solid ${newBorder}`}
          >
            <Stack gap={1} w="100%" p="0rem 0">
              <Text fontSize="sm" color={textSecondary} fontWeight="500" mt={4}>
                {t("common.approveTreatmentPlanConfirmationTextPublic")}
              </Text>

              <Text fontSize="sm" color={textSecondary} fontWeight="500" mt={4}>
                Atvykę į pirmą vizitą pasirašysite su gydymo planu susijusius
                dokumentus.
              </Text>
            </Stack>
          </Flex>
        </ModalBody>

        <ModalFooter
          display="flex"
          justifyContent="center"
          alignItems="center"
          p="1.5rem 0 1rem"
        >
          <Button
            variant="ghost"
            size="lg"
            borderRadius="10rem"
            onClick={onClose}
            mx={2}
          >
            {t("button.close")}
          </Button>
          <Button
            colorScheme="green"
            size="lg"
            borderRadius="10rem"
            onClick={confirmAction}
            mx={2}
          >
            {t("button.approve")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmApprovePublicModal;
