import React from "react";
import {
  Text,
  Avatar,
  HStack,
  Image,
  Circle,
  Box,
  Icon,
  Flex,
  Stack,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  textSecondary,
  textPrimary,
  backgroundLight,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";
import { TbCalendar, TbCake, TbClock, TbCheck } from "react-icons/tb";
import blankNumber from "../../../../assets/icons/blankNumber.svg";
import blank from "../../../../assets/icons/blank.svg";
import StatusBadge from "../../../../components/badges/StatusBadge";

export const columns = (
  translate,
  deletePatientHandler,
  openEditPatientSidePanelModal,
  handleSortChange,
  isDoctor,
  isAdmin,
  isMobile
) => {
  const allColumns = [
    {
      Header: () => (
        <Flex onClick={() => handleSortChange("userID")} h="100%">
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("patient.columnID")}</Text>
          </Flex>
        </Flex>
      ),
      accessor: "userID",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { userID } = row.original;

        return (
          <Box>
            <Text
              maxWidth="50px"
              overflow="hidden"
              whiteSpace="nowrap"
              fontSize="xs"
              color={textSecondary}
              fontWeight="500"
            >
              {userID}
            </Text>
          </Box>
        );
      },
      // getHeaderProps: (column) => ({
      //   onClick: () => handleSortChange("userID"),
      // }),
    },

    {
      Header: () => (
        <Flex onClick={() => handleSortChange("lastName")} h="100%">
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("patient.columnName")}</Text>
          </Flex>
        </Flex>
      ),
      disableSortBy: true,
      accessor: "lastName",
      Cell: ({ row }) => {
        const { firstName, lastName, image, userID, status } = row.original;
        const name = `${lastName} ${firstName}`;

        return (
          <HStack gap={2}>
            {image ? (
              <Image
                size="sm"
                src={image}
                alt={name}
                borderRadius="10rem"
                objectFit="cover"
              />
            ) : (
              <>
                {!isMobile && (
                  <Avatar
                    h={["2.5rem", "2rem", "2rem"]}
                    w={["2.5rem", "2rem", "2rem"]}
                    name={name}
                    bg="#dceeff"
                    color="#3a74a9"
                  />
                )}
              </>
            )}

            <Stack gap={0}>
              <Text
                fontSize="md"
                color={textPrimary}
                fontWeight="600"
                isTruncated // This will add ellipsis
                whiteSpace="nowrap"
                w="100%"
                minWidth={["200px", "260px", "260px"]}
                maxWidth={["200px", "260px", "260px"]}
              >
                {lastName}
                {/* {firstName} */}
                <Text as="span" fontWeight="400">
                  {" "}
                  {/* {lastName} */}
                  {firstName}
                </Text>
              </Text>
              {isMobile && (
                <Flex align="baseline" m="0.25rem 0 0">
                  <StatusBadge isSmall status={status} options="user" />
                  <Text
                    fontSize="xs"
                    color={textSecondary}
                    fontWeight="500"
                    ml={2}
                  >
                    {userID}
                  </Text>
                </Flex>
              )}
            </Stack>
          </HStack>
        );
      },
      // getHeaderProps: (column) => ({
      //   onClick: () => handleSortChange("lastName"),
      // }),
    },

    {
      Header: () => (
        <Flex onClick={() => handleSortChange("age")} h="100%">
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("patient.columnAge")}</Text>
          </Flex>
        </Flex>
      ),
      accessor: "age",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { birthDate } = row.original;

        if (!birthDate)
          return (
            <Text fontSize="sm" fontWeight="500" color={textSecondary}>
              N/A
            </Text>
          );

        const age = dayjs().year() - dayjs(birthDate).year();
        return (
          <Flex align="center">
            <Circle
              size="18px"
              bg={backgroundLight}
              color={textSecondary}
              mr={1}
            >
              <Icon as={TbCake} fontSize="sm" />
            </Circle>
            <Text
              fontSize="sm"
              color={textSecondary}
              fontWeight="500"
              whiteSpace="nowrap"
            >
              {age} {translate("patient.yearsOld")}
            </Text>
          </Flex>
        );
      },
      // getHeaderProps: (column) => ({
      //   onClick: () => handleSortChange("age"),
      // }),
    },

    {
      Header: () => (
        <Flex onClick={() => handleSortChange("phone")} h="100%">
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("patient.columnPhone")}</Text>
          </Flex>
        </Flex>
      ),
      accessor: "phone",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { phone } = row.original;

        return (
          <>
            {isDoctor ? (
              <Image src={blank} size="sm" />
            ) : (
              <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                {phone ? phone : "-"}
              </Text>
            )}
          </>
        );
      },
      getHeaderProps: (column) => ({
        onClick: () => handleSortChange("phone"),
      }),
    },
    {
      Header: () => (
        <Flex onClick={() => handleSortChange("state")} h="100%">
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("patient.columnState")}</Text>
          </Flex>
        </Flex>
      ),
      accessor: "state",
      disableSortBy: true,
      Cell: ({ row }) => {
        const rowOriginal = row.original;

        const city =
          rowOriginal.address.length > 0 ? rowOriginal.address[0].city : null;
        const state =
          rowOriginal.address.length > 0 ? rowOriginal.address[0].state : null;

        return (
          <>
            {isDoctor ? (
              <Image src={blank} size="sm" />
            ) : (
              <Text fontSize="sm" fontWeight="500" color={textSecondary}>
                {state ? state : "-"}
              </Text>
            )}
          </>
        );
      },
      // getHeaderProps: (column) => ({
      //   onClick: () => handleSortChange("state"),
      // }),
    },
    {
      Header: () => (
        <Flex onClick={() => handleSortChange("status")} w="100%" h="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("patient.columnStatus")}</Text>
          </Flex>
        </Flex>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { status, _id } = row.original;

        const handleUpdateStatus = (newStatus) => {
          // dispatch(updateSpeakerStatus(_id, newStatus));
        };

        const name = `${row.original.firstName} ${row.original.lastName}`;

        const hasTreatmentPlans = row.original.treatmentPlans.length > 0;

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              isUnclickable
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
              options="user"
            />
            <MoreButton
              id={_id}
              deleteHandler={deletePatientHandler}
              deleteButtonDisabled={!isAdmin}
              deleteTitle={translate("patient.deletePatientTitle")}
              deleteName={name}
              warningLabel={
                hasTreatmentPlans ? translate("patient.deleteWarning") : null
              }
              editHandler={(e) => {
                e.stopPropagation();
                openEditPatientSidePanelModal(_id);
              }}
              editButtonDisabled={isDoctor}
            />
          </HStack>
        );
      },
      // getHeaderProps: (column) => ({
      //   onClick: () => handleSortChange("status"),
      // }),
    },
  ];
  // If on a mobile device, filter out the first column
  if (isMobile) {
    return allColumns.filter((_, index) => index !== 0);
  }
  return allColumns;
};
