import React from "react";
import { Flex, Heading, Stack, Text, IconButton } from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  textPrimary,
} from "../../utils/colors";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import StatusBadge from "../badges/StatusBadge";
import { useTranslation } from "react-i18next";

const SidePanelHeader = ({ title, status, ageType, close }) => {
  const { t } = useTranslation();

  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        p="1.5rem 2rem 1.5rem"
        borderBottom={`1px solid ${newBorder}`}
        mb="0rem"
        position="absolute"
        zIndex={2}
        bg={white}
        w="100%"
      >
        <Flex align="center">
          <IconButton
            bg="none"
            border={`1px solid ${newBorder}`}
            color={textSecondary}
            onClick={close}
            icon={<FaArrowRightFromBracket />}
            borderRadius="0.75rem"
            fontSize="0.75rem"
          />
          <Stack gap={0} ml={4}>
            <Flex align="center">
              <Heading
                fontSize="1.275rem"
                color={textPrimary}
                fontWeight="600"
                mr={4}
              >
                {title}
              </Heading>
              {status && <StatusBadge status={status} options="user" />}
            </Flex>
            {ageType && (
              <Text fontSize="sm" color={textSecondary} fontWeight="500">
                {ageType === "adult" ? t("common.adult") : t("common.child")}
              </Text>
            )}
          </Stack>
        </Flex>
        <Stack gap={0}>
          <Flex align="center"></Flex>
        </Stack>
      </Flex>
    </>
  );
};

export default SidePanelHeader;
