import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
import {
  buttonGreen,
  buttonGreenText,
  buttonRed,
  buttonRedText,
} from '../../utils/colors';
import { useTranslation } from 'react-i18next';

const ToggleButton = ({ initialStatus, onToggle }) => {
  // Initialize a state variable to store the current value (true/false)
  const [value, setValue] = useState(initialStatus);
  const { t } = useTranslation();

  // Function to toggle the value when the button is clicked
  const toggleValue = () => {
    const newValue = !value; // Toggle the value
    setValue(newValue);
    // Call the onToggle callback with the new value
    onToggle(newValue);
  };

  return (
    <Button
      borderRadius="100rem"
      size="md"
      fontSize="0.875rem"
      onClick={toggleValue}
      bg={value ? buttonGreen : buttonRed}
      color={value ? buttonGreenText : buttonRedText}
    >
      {value ? t('common.paid') : t('common.unPaid')}
    </Button>
  );
};

export default ToggleButton;
