import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTopServices,
  getTopServiceGroups,
  getTopEmployees,
  getTopServicesDoctor,
} from "../../../../actions/statisticActions";
import {
  Flex,
  Heading,
  Spinner,
  Button,
  Icon,
  Grid,
  GridItem,
  Box,
  Text,
  Image,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  newBorder,
  textPrimary,
  primary,
  textSecondary,
  white,
  secondary,
  backgroundLight,
  greenText,
} from "../../../../utils/colors";
import useCountUp from "../../../../hooks/useCountUp";
import { FaCaretDown } from "react-icons/fa6";
import BoxListItem from "./BoxListItem";
import { tint } from "polished";
import noServices from "../../../../assets/images/empty-box.png";
import useRoleCheck from "../../../../hooks/useRoleCheck";

const DashboardTopGainers = () => {
  // GENERAL
  const { t } = useTranslation();

  // ROLES
  const isAdmin = useRoleCheck("admin");
  const isDoctor = useRoleCheck("employee_doctor");

  // CONSTANTS
  const [statsTopServices, setStatsTopServices] = useState(0);
  const [statsTopServiceGroups, setStatsTopServiceGroups] = useState(0);
  const [statsTopEmployees, setStatsTopEmployees] = useState(0);
  const [statsTopSericesDoctor, setStatsTopSericesDoctor] = useState(0);

  // REDUX
  const dispatch = useDispatch();

  const statisticTopService = useSelector((state) => state.statisticTopService);
  const { loading, error, statisticTopServices } = statisticTopService;

  const statisticTopServiceGroup = useSelector(
    (state) => state.statisticTopServiceGroup
  );
  const {
    loading: loadingTopServiceGroup,
    error: errorTopServiceGroup,
    statisticTopServiceGroups,
  } = statisticTopServiceGroup;

  const statisticTopEmployee = useSelector(
    (state) => state.statisticTopEmployee
  );
  const {
    loading: loadingTopEmployee,
    error: errorTopEmployee,
    statisticTopEmployees,
  } = statisticTopEmployee;

  const statisticTopServiceDoctor = useSelector(
    (state) => state.statisticTopServiceDoctor
  );
  const {
    loading: loadingTopServiceDoctor,
    error: errorTopServiceDoctor,
    statisticTopServicesDoctor,
  } = statisticTopServiceDoctor;

  // States for statistics
  const [totalPatients, setTotalPatients] = useState(0);

  const animatedTotalPatients = useCountUp(totalPatients);

  // USEEFFECT
  useEffect(() => {
    dispatch(getTopServices());
    dispatch(getTopServiceGroups());
    dispatch(getTopEmployees());
    dispatch(getTopServicesDoctor());
  }, [dispatch]);

  useEffect(() => {
    // Your existing code...
    // Additionally, set isRevenueAvailable based on the data being loaded
    setIsRevenueAvailable(statisticTopServicesDoctor && !isAdmin);
  }, [statisticTopServicesDoctor, isAdmin]);

  // LOG
  console.log("isDoctor", isDoctor);
  console.log("isAdmin", isAdmin);

  // console.log("statisticTopServices", statisticTopServices);
  // console.log("statisticTopServiceGroups", statisticTopServiceGroups);
  // console.log("statisticTopEmployees", statisticTopEmployees);
  // console.log("statisticTopServicesDoctor", statisticTopServicesDoctor);

  const [isRevenueAvailable, setIsRevenueAvailable] = useState(false);

  // Helper function to render content based on data availability
  const renderContent = (
    data,
    loading,
    color,
    title,
    textColor,
    isRevenueAvailable
  ) => {
    if (loading) return <Spinner size="md" color={primary} />;

    if (data && data.length > 0) {
      return (
        <>
          <Text
            key={title}
            fontSize="11px"
            fontWeight="500"
            textTransform="uppercase"
            color={textSecondary}
            mb={2}
            pl={3}
          >
            {title}
          </Text>
          {data.map((item, index) => (
            <BoxListItem
              key={index}
              label={
                item.serviceName || item.serviceGroupName || item.employeeName
              }
              data={
                !isDoctor || isRevenueAvailable
                  ? item.totalRevenue
                  : item.serviceCount
              }
              bg={tint(index * 0.3, color)}
              isPrice={!isDoctor || isRevenueAvailable}
              color={textColor}
            />
          ))}
        </>
      );
    }

    // Render this when there's no data and not loading
    return (
      <>
        <Flex direction="column" justify="center" align="center" w="100%" p={0}>
          <Image h="initial" w="2.5rem" m="1rem 0 0" src={noServices} />
          <Text mt={2} color={textSecondary} fontSize="sm">
            {t("common.noData")}
          </Text>
        </Flex>
      </>
    );
  };

  if (error) return <p>Error: {error}</p>;

  return (
    <Flex
      w="100%"
      direction="column"
      p="0rem 1.5rem"
      border={`1px solid ${newBorder}`}
      borderRadius="1rem"
      mb={8}
    >
      <Flex w="100%" justify="space-between" align="center" py={4}>
        <Heading size="sm" fontWeight="600" color={textPrimary}>
          {isDoctor
            ? t("dashboard.topGainersDoctor")
            : t("dashboard.topGainers")}
        </Heading>
        <Button
          size="md"
          borderRadius="10rem"
          border={`1px solid ${newBorder}`}
          bg={white}
          color={textSecondary}
          fontWeight="500"
          fontSize="sm"
          isDisabled
        >
          {t("common.filter")}
          <Icon as={FaCaretDown} ml={1} h="0.75rem" w="0.6rem" />
        </Button>
      </Flex>

      <Grid templateColumns="repeat(3, 1fr)" gap={8} w="100%" pb={8}>
        <GridItem colSpan={1}>
          <Flex direction="column" w="100%" p="0 0rem">
            {renderContent(
              statisticTopServices,
              loading,
              primary,
              isDoctor
                ? t("dashboard.topGainersServicesClinic")
                : t("dashboard.topGainersServices"),
              greenText
            )}
          </Flex>
        </GridItem>
        <GridItem
          colSpan={1}
          // borderLeft={`1px solid ${newBorder}`}
          // borderRight={`1px solid ${newBorder}`}
        >
          <Flex direction="column" w="100%" p="0 0rem">
            {renderContent(
              isDoctor ? statisticTopServicesDoctor : statisticTopServiceGroups,
              loading,
              primary,
              isDoctor
                ? t("dashboard.topGainersServicesDoctor")
                : t("dashboard.topGainersServiceGroups"),
              textPrimary
            )}
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex direction="column" w="100%" p="0 0rem">
            {renderContent(
              isDoctor ? statisticTopServicesDoctor : statisticTopEmployees,
              loading,
              primary,
              isDoctor
                ? t("dashboard.topGainersServicesDoctorRevenue")
                : t("dashboard.topGainersEmployees"),
              textPrimary,
              isRevenueAvailable
            )}
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default DashboardTopGainers;
