import React, { useState, useEffect } from "react";
import { Box, Heading, Text, Button } from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  dark,
  white,
  primary,
  secondary,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const StepSuccess = ({ email, password, handleLoginClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(15); // 15 seconds timer

  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  useEffect(() => {
    // Redirect when timer hits 0
    if (timer === 0) {
      handleLoginClick();
      navigate("/patients"); // Update with your desired path
      return;
    }

    // Countdown timer
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Cleanup interval
    return () => clearInterval(interval);
  }, [timer, navigate]);

  return (
    <Box mt={0} w="100%">
      <Box w="100%" mb={12}>
        <Heading
          fontSize="1.875rem"
          fontWeight="700"
          color={textPrimary}
          mb={3}
        >
          {t("common.verificationSuccessHeading")}
        </Heading>
        <Text color={textSecondary} fontWeight="500" w="100%" fontSize="md">
          {t("common.verificationSuccessMessage")}
        </Text>
      </Box>
      <Button
        onClick={handleLoginClick}
        mt={4}
        mb={4}
        h="3.5rem"
        borderRadius="10rem"
        bg={dark}
        color={white}
        border={`1px solid ${dark}`}
        textAlign="center"
        w="100%"
        _hover={{
          bg: "rgba(13, 12, 34, 0.8)",
          color: white,
          border: `rgba(13, 12, 34, 0.8)`,
        }}
      >
        {t("common.login")} ({timer})
      </Button>
    </Box>
  );
};

export default StepSuccess;
