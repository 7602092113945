import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Flex,
  Box,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Heading,
} from "@chakra-ui/react";
import {
  white,
  primary,
  textPrimary,
  backgroundLight,
  newBorder,
  textSecondary,
} from "../../../../../utils/colors";
import {
  listCourseSpeakers,
  addCourseSpeaker,
  removeCourseSpeaker,
} from "../../../../../actions/courseActions";
import Table from "../../../../../components/shared/Table";
import { columns as courseSpeakersColumns } from "../../columns/CourseSpeakersColumns";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
import MultiSpeakerSelectMain from "../../../../../components/select/MultiSpeakerSelectMain";
import ToastComponent from "../../../../../components/partials/ToastComponent";
import {
  COURSE_SPEAKER_ADD_RESET,
  COURSE_SPEAKER_REMOVE_RESET,
} from "../../../../../constants/courseConstants";
import SidePanelHeader from "../../../../../components/sidepanel/SidePanelHeader";
import { shade } from "polished";

const TabCourseSpeakers = ({ title }) => {
  const courseId = useParams().id;
  const toast = useToast();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();
  const translate = (key) => t(`${key}`);

  const dispatch = useDispatch();

  const [addSidePanelOpen, setAddSidePanelOpen] = useState(false);

  const [keyword, setKeyword] = useState("");

  const [currentSpeakers] = useState([]);
  const [customSpeakers] = useState([]);
  const [updatedSpeakers, setUpdatedSpeakers] = useState([]);

  const courseSpeakersList = useSelector((state) => state.courseSpeakersList);
  const { loading, error, speakers } = courseSpeakersList;

  const courseSpeakerAdd = useSelector((state) => state.courseSpeakerAdd);
  const { success: successAddSpeaker } = courseSpeakerAdd;

  const courseSpeakerRemove = useSelector((state) => state.courseSpeakerRemove);
  const { success: successRemoveSpeaker } = courseSpeakerRemove;

  // SORTING
  const [sortField, setSortField] = useState("customId");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(listCourseSpeakers(courseId, keyword, sortField, sortOrder));
  };

  const addHandleNew = (values) => {
    const speakerIds = values.map((speaker) => speaker.value);

    dispatch(addCourseSpeaker(courseId, speakerIds));
    setUpdatedSpeakers([...updatedSpeakers, ...speakerIds]);
    closeAddSidePanel();
  };

  const removeHandler = (speakerId) => {
    dispatch(removeCourseSpeaker(courseId, speakerId));
  };

  useEffect(() => {
    if (successAddSpeaker) {
      dispatch(listCourseSpeakers(courseId, keyword, sortField, sortOrder));
      toastComponent.showToast(t("toasts.speakerAdd"), "success", 3000);
      dispatch({ type: COURSE_SPEAKER_ADD_RESET });
    } else if (successRemoveSpeaker) {
      dispatch(listCourseSpeakers(courseId, keyword, sortField, sortOrder));
      toastComponent.showToast(t("toasts.speakerRemove"), "success", 3000);
      dispatch({ type: COURSE_SPEAKER_REMOVE_RESET });
    } else {
      dispatch(listCourseSpeakers(courseId, keyword, sortField, sortOrder));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    courseId,
    keyword,
    sortField,
    sortOrder,
    successAddSpeaker,
    successRemoveSpeaker,
  ]);

  const openAddSidePanel = () => {
    setAddSidePanelOpen(true);
  };

  const closeAddSidePanel = () => {
    setAddSidePanelOpen(false);
  };

  // console.log("currentSpeakers", currentSpeakers);
  // console.log("customSpeakers", customSpeakers);
  // console.log("updatedSpeakers", updatedSpeakers);
  // console.log("speakers", speakers);

  return (
    <>
      <SidePanel
        open={addSidePanelOpen}
        close={closeAddSidePanel}
        title={t("course.addSpeaker")}
      >
        <Box position="relative">
          <SidePanelHeader
            close={closeAddSidePanel}
            title={t("course.addSpeaker")}
          />
          <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="7rem 0 4rem"
            >
              {/* <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("attendee.details")}
              </Heading> */}

              <Flex
                direction="column"
                w="100%"
                bg={white}
                borderRadius="1rem"
                p="0rem 1.5rem"
              >
                <FormControl mt={4}>
                  <>
                    <FormLabel fontSize="sm">
                      <Flex justify="space-between">{t("select.speaker")}</Flex>
                    </FormLabel>

                    <Flex w="100%" mb={10} flexDirection="column">
                      <Box w="100%">
                        <MultiSpeakerSelectMain
                          currentOptions={speakers}
                          value={customSpeakers}
                          onChange={addHandleNew}
                          isSelectedHidden={true}
                        />
                      </Box>
                    </Flex>
                  </>
                </FormControl>
              </Flex>

              <Flex
                mt={4}
                direction="column"
                borderTop={`1px solid ${newBorder}`}
                p="2rem 0 3rem"
                w="calc(100% - 6rem)"
                position="absolute"
                bottom="0px"
                left="3rem"
              >
                <Flex justify="flex-end" gap={2}>
                  <Button
                    minWidth="8rem"
                    mr={0}
                    bg={backgroundLight}
                    border={`1px solid ${newBorder}`}
                    size="lg"
                    color={textSecondary}
                    fontSize="md"
                    fontWeight="500"
                    borderRadius="10rem"
                    _hover={{
                      bg: shade(0.1, backgroundLight),
                    }}
                    onClick={closeAddSidePanel}
                  >
                    {t("common.back")}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </SidePanel>

      <Box>
        <Flex w="100%" direction="column">
          <Table.Top
            title={t("course.tabSpeakers")}
            subtitle={title}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder={t("course.search")}
            searchName={keyword}
            isFixedTitle
          >
            {/* <Button
              leftIcon={<FilterIcon />}
              bg={white}
              color={textSecondary}
              border={`1px solid ${isFilterVisible ? primary : newBorder}`}
              variant="solid"
              size="lg"
              borderRadius="10rem"
              fontSize="16px"
              onClick={() => setIsFilterVisible(!isFilterVisible)}
            >
              {t('common.filter')}
            </Button> */}
            <Button
              bg={primary}
              color={white}
              size="lg"
              borderRadius="10rem"
              fontSize="1rem"
              onClick={openAddSidePanel}
            >
              {t("course.addSpeaker")}
            </Button>
          </Table.Top>
          <Box borderRadius="0.75rem" overflow="hidden" p="0 0 2rem">
            <Table.Container
              loading={loading}
              data={speakers}
              error={error}
              columns={courseSpeakersColumns(
                translate,
                removeHandler,
                handleSortChange
              )}
              noItemsTitle={t("noItems.noSpeakers")}
              noItemsDescription={t("noItems.noSpeakersText")}
              noItemsButtonLabel={t("course.noSpeakersCourseButtonLabel")}
              noItemsButtonHandler={openAddSidePanel}
              sortField={sortField}
              sortOrder={sortOrder}
              onRowClick={() => {}}
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default TabCourseSpeakers;
