import {
  ATTENDEE_LIST_REQUEST,
  ATTENDEE_LIST_SUCCESS,
  ATTENDEE_LIST_FAIL,
  ATTENDEE_DETAILS_REQUEST,
  ATTENDEE_DETAILS_SUCCESS,
  ATTENDEE_DETAILS_FAIL,
  ATTENDEE_DETAILS_RESET,
  ATTENDEE_UPDATE_REQUEST,
  ATTENDEE_UPDATE_SUCCESS,
  ATTENDEE_UPDATE_FAIL,
  ATTENDEE_UPDATE_RESET,
  ATTENDEE_CREATE_REQUEST,
  ATTENDEE_CREATE_SUCCESS,
  ATTENDEE_CREATE_FAIL,
  ATTENDEE_CREATE_RESET,
  ATTENDEE_DELETE_REQUEST,
  ATTENDEE_DELETE_SUCCESS,
  ATTENDEE_DELETE_FAIL,
  ATTENDEE_DELETE_RESET,
  ATTENDEE_ADD_COURSE_REQUEST,
  ATTENDEE_ADD_COURSE_SUCCESS,
  ATTENDEE_ADD_COURSE_FAIL,
  ATTENDEE_ADD_COURSE_RESET,
  ATTENDEE_REMOVE_COURSE_REQUEST,
  ATTENDEE_REMOVE_COURSE_SUCCESS,
  ATTENDEE_REMOVE_COURSE_FAIL,
  ATTENDEE_REMOVE_COURSE_RESET,
  ATTENDEE_ACTIVE_STATUS_UPDATE_REQUEST,
  ATTENDEE_ACTIVE_STATUS_UPDATE_SUCCESS,
  ATTENDEE_ACTIVE_STATUS_UPDATE_FAIL,
  ATTENDEE_ACTIVE_STATUS_UPDATE_RESET,
  ATTENDEE_COURSES_LIST_REQUEST,
  ATTENDEE_COURSES_LIST_SUCCESS,
  ATTENDEE_COURSES_LIST_FAIL,
  ATTENDEE_COURSE_STATUS_UPDATE_REQUEST,
  ATTENDEE_COURSE_STATUS_UPDATE_SUCCESS,
  ATTENDEE_COURSE_STATUS_UPDATE_FAIL,
  ATTENDEE_COURSE_STATUS_UPDATE_RESET,
  ATTENDEE_COURSE_PAIDSTATUS_UPDATE_REQUEST,
  ATTENDEE_COURSE_PAIDSTATUS_UPDATE_SUCCESS,
  ATTENDEE_COURSE_PAIDSTATUS_UPDATE_FAIL,
  ATTENDEE_COURSE_PAIDSTATUS_UPDATE_RESET,
  ATTENDEE_UPDATE_IMAGE_REQUEST,
  ATTENDEE_UPDATE_IMAGE_SUCCESS,
  ATTENDEE_UPDATE_IMAGE_FAIL,
  ATTENDEE_UPDATE_IMAGE_RESET,
} from "../constants/attendeeConstants";

export const attendeeListReducer = (state = { attendees: [] }, action) => {
  switch (action.type) {
    case ATTENDEE_LIST_REQUEST:
      return { loading: true, attendees: [] };
    case ATTENDEE_LIST_SUCCESS:
      return {
        loading: false,
        attendees: action.payload.attendees,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case ATTENDEE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const attendeeDetailsReducer = (state = { attendee: {} }, action) => {
  switch (action.type) {
    case ATTENDEE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ATTENDEE_DETAILS_SUCCESS:
      return { loading: false, attendee: action.payload };
    case ATTENDEE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ATTENDEE_DETAILS_RESET:
      return { attendee: {} };
    default:
      return state;
  }
};

export const attendeeUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTENDEE_UPDATE_REQUEST:
      return { loading: true };
    case ATTENDEE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case ATTENDEE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ATTENDEE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const attendeeCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTENDEE_CREATE_REQUEST:
      return { loading: true };
    case ATTENDEE_CREATE_SUCCESS:
      return { loading: false, success: true, attendee: action.payload };
    case ATTENDEE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ATTENDEE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const attendeeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTENDEE_DELETE_REQUEST:
      return { loading: true };
    case ATTENDEE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ATTENDEE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ATTENDEE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const attendeeAddCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTENDEE_ADD_COURSE_REQUEST:
      return { loading: true };
    case ATTENDEE_ADD_COURSE_SUCCESS:
      return { loading: false, success: true };
    case ATTENDEE_ADD_COURSE_FAIL:
      return { loading: false, error: action.payload };
    case ATTENDEE_ADD_COURSE_RESET:
      return {};
    default:
      return state;
  }
};

export const attendeeRemoveCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTENDEE_REMOVE_COURSE_REQUEST:
      return { loading: true };
    case ATTENDEE_REMOVE_COURSE_SUCCESS:
      return { loading: false, success: true };
    case ATTENDEE_REMOVE_COURSE_FAIL:
      return { loading: false, error: action.payload };
    case ATTENDEE_REMOVE_COURSE_RESET:
      return {};
    default:
      return state;
  }
};

export const attendeeStatusUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTENDEE_ACTIVE_STATUS_UPDATE_REQUEST:
      return { loading: true };
    case ATTENDEE_ACTIVE_STATUS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case ATTENDEE_ACTIVE_STATUS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ATTENDEE_ACTIVE_STATUS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const attendeeCoursesListReducer = (state = { courses: [] }, action) => {
  switch (action.type) {
    case ATTENDEE_COURSES_LIST_REQUEST:
      return { loading: true, courses: [] };
    case ATTENDEE_COURSES_LIST_SUCCESS:
      return { loading: false, courses: action.payload };
    case ATTENDEE_COURSES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const attendeeCourseStatusUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTENDEE_COURSE_STATUS_UPDATE_REQUEST:
      return { loading: true };
    case ATTENDEE_COURSE_STATUS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case ATTENDEE_COURSE_STATUS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ATTENDEE_COURSE_STATUS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const attendeeCoursePaidStatusUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTENDEE_COURSE_PAIDSTATUS_UPDATE_REQUEST:
      return { loading: true };
    case ATTENDEE_COURSE_PAIDSTATUS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case ATTENDEE_COURSE_PAIDSTATUS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ATTENDEE_COURSE_PAIDSTATUS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const attendeeUpdateImageReducer = (state = {}, action) => {
  switch (action.type) {
    case ATTENDEE_UPDATE_IMAGE_REQUEST:
      return { loading: true };
    case ATTENDEE_UPDATE_IMAGE_SUCCESS:
      return { loading: false, success: true };
    case ATTENDEE_UPDATE_IMAGE_FAIL:
      return { loading: false, error: action.payload };
    case ATTENDEE_UPDATE_IMAGE_RESET:
      return {};
    default:
      return state;
  }
};
