import {
  TREATMENTPLAN_LIST_REQUEST,
  TREATMENTPLAN_LIST_SUCCESS,
  TREATMENTPLAN_LIST_FAIL,
  TREATMENTPLAN_LIST_RESET,
  TREATMENTPLAN_DETAILS_REQUEST,
  TREATMENTPLAN_DETAILS_SUCCESS,
  TREATMENTPLAN_DETAILS_FAIL,
  TREATMENTPLAN_DETAILS_RESET,
  TREATMENTPLAN_CREATE_REQUEST,
  TREATMENTPLAN_CREATE_SUCCESS,
  TREATMENTPLAN_CREATE_FAIL,
  TREATMENTPLAN_CREATE_RESET,
  TREATMENTPLAN_UPDATE_REQUEST,
  TREATMENTPLAN_UPDATE_SUCCESS,
  TREATMENTPLAN_UPDATE_FAIL,
  TREATMENTPLAN_UPDATE_RESET,
  TREATMENTPLAN_DELETE_REQUEST,
  TREATMENTPLAN_DELETE_SUCCESS,
  TREATMENTPLAN_DELETE_FAIL,
  TREATMENTPLAN_DELETE_RESET,
  TREATMENTPLAN_UPDATE_SERVICES_ORDER_REQUEST,
  TREATMENTPLAN_UPDATE_SERVICES_ORDER_SUCCESS,
  TREATMENTPLAN_UPDATE_SERVICES_ORDER_FAIL,
  TREATMENTPLAN_UPDATE_SERVICES_ORDER_RESET,
  TREATMENTPLAN_UPDATE_STATUS_REQUEST,
  TREATMENTPLAN_UPDATE_STATUS_SUCCESS,
  TREATMENTPLAN_UPDATE_STATUS_FAIL,
  TREATMENTPLAN_UPDATE_STATUS_RESET,
  TREATMENTPLAN_APPROVE_REQUEST,
  TREATMENTPLAN_APPROVE_SUCCESS,
  TREATMENTPLAN_APPROVE_FAIL,
  TREATMENTPLAN_APPROVE_RESET,
  TREATMENTPLAN_CANCEL_REQUEST,
  TREATMENTPLAN_CANCEL_SUCCESS,
  TREATMENTPLAN_CANCEL_FAIL,
  TREATMENTPLAN_CANCEL_RESET,
  TREATMENTPLAN_STATISTICS_REQUEST,
  TREATMENTPLAN_STATISTICS_FAIL,
  TREATMENTPLAN_STATISTICS_SUCCESS,
  TREATMENTPLAN_STATISTICS_RESET,
  TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_REQUEST,
  TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_FAIL,
  TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_SUCCESS,
  TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_RESET,
  TREATMENTPLAN_SEND_EMAIL_REQUEST,
  TREATMENTPLAN_SEND_EMAIL_SUCCESS,
  TREATMENTPLAN_SEND_EMAIL_FAIL,
  TREATMENTPLAN_SEND_EMAIL_RESET,
  TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_REQUEST,
  TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_SUCCESS,
  TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_FAIL,
  TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_RESET,
  TREATMENTPLAN_DOWNLOAD_PDF_REQUEST,
  TREATMENTPLAN_DOWNLOAD_PDF_SUCCESS,
  TREATMENTPLAN_DOWNLOAD_PDF_FAIL,
  TREATMENTPLAN_DOWNLOAD_PDF_RESET,
} from "../constants/treatmentPlanConstants";

export const treatmentPlanListReducer = (
  state = { treatmentPlans: [], countsByStatus: {}, globalTotalCount: 0 },
  action
) => {
  switch (action.type) {
    case TREATMENTPLAN_LIST_REQUEST:
      return { loading: true, treatmentPlans: [] };
    case TREATMENTPLAN_LIST_SUCCESS:
      return {
        loading: false,
        treatmentPlans: action.payload.treatmentPlans,
        pages: action.payload.pages,
        page: action.payload.page,
        countsByStatus: action.payload.countsByStatus,
        globalTotalCount: action.payload.globalTotalCount,
      };
    case TREATMENTPLAN_LIST_FAIL:
      return { loading: false, error: action.payload };
    case TREATMENTPLAN_LIST_RESET:
      return { treatmentPlans: [] };
    default:
      return state;
  }
};

export const treatmentPlanDetailsReducer = (
  state = { treatmentPlan: {} },
  action
) => {
  switch (action.type) {
    case TREATMENTPLAN_DETAILS_REQUEST:
      return { loading: true, ...state };
    case TREATMENTPLAN_DETAILS_SUCCESS:
      return { loading: false, treatmentPlan: action.payload };
    case TREATMENTPLAN_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case TREATMENTPLAN_DETAILS_RESET:
      return { treatmentPlan: {} };
    default:
      return state;
  }
};

export const treatmentPlanCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TREATMENTPLAN_CREATE_REQUEST:
      return { loading: true };
    case TREATMENTPLAN_CREATE_SUCCESS:
      return { loading: false, success: true, treatmentPlan: action.payload };
    case TREATMENTPLAN_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case TREATMENTPLAN_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const treatmentPlanUpdateReducer = (
  state = { treatmentPlan: {} },
  action
) => {
  switch (action.type) {
    case TREATMENTPLAN_UPDATE_REQUEST:
      return { loading: true };
    case TREATMENTPLAN_UPDATE_SUCCESS:
      return { loading: false, success: true, treatmentPlan: action.payload };
    case TREATMENTPLAN_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case TREATMENTPLAN_UPDATE_RESET:
      return { treatmentPlan: {} };
    default:
      return state;
  }
};

export const treatmentPlanDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TREATMENTPLAN_DELETE_REQUEST:
      return { loading: true };
    case TREATMENTPLAN_DELETE_SUCCESS:
      return { loading: false, success: true };
    case TREATMENTPLAN_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case TREATMENTPLAN_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const treatmentPlanUpdateServicesOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case TREATMENTPLAN_UPDATE_SERVICES_ORDER_REQUEST:
      return { loading: true };
    case TREATMENTPLAN_UPDATE_SERVICES_ORDER_SUCCESS:
      return { loading: false, success: true };
    case TREATMENTPLAN_UPDATE_SERVICES_ORDER_FAIL:
      return { loading: false, error: action.payload };
    case TREATMENTPLAN_UPDATE_SERVICES_ORDER_RESET:
      return {};
    default:
      return state;
  }
};

export const treatmentPlanUpdateStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case TREATMENTPLAN_UPDATE_STATUS_REQUEST:
      return { loading: true };
    case TREATMENTPLAN_UPDATE_STATUS_SUCCESS:
      return { loading: false, success: true };
    case TREATMENTPLAN_UPDATE_STATUS_FAIL:
      return { loading: false, error: action.payload };
    case TREATMENTPLAN_UPDATE_STATUS_RESET:
      return {};
    default:
      return state;
  }
};

export const treatmentPlanApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case TREATMENTPLAN_APPROVE_REQUEST:
      return { loading: true };
    case TREATMENTPLAN_APPROVE_SUCCESS:
      return { loading: false, success: true };
    case TREATMENTPLAN_APPROVE_FAIL:
      return { loading: false, error: action.payload };
    case TREATMENTPLAN_APPROVE_RESET:
      return {};
    default:
      return state;
  }
};

export const treatmentPlanCancelReducer = (state = {}, action) => {
  switch (action.type) {
    case TREATMENTPLAN_CANCEL_REQUEST:
      return { loading: true };
    case TREATMENTPLAN_CANCEL_SUCCESS:
      return { loading: false, success: true };
    case TREATMENTPLAN_CANCEL_FAIL:
      return { loading: false, error: action.payload };
    case TREATMENTPLAN_CANCEL_RESET:
      return {};
    default:
      return state;
  }
};

export const treatmentPlanStatisticsReducer = (
  state = { stats: {} },
  action
) => {
  switch (action.type) {
    case TREATMENTPLAN_STATISTICS_REQUEST:
      return { loading: true };
    case TREATMENTPLAN_STATISTICS_SUCCESS:
      return { loading: false, stats: action.payload };
    case TREATMENTPLAN_STATISTICS_FAIL:
      return { loading: false, error: action.payload };
    case TREATMENTPLAN_STATISTICS_RESET:
      return { stats: {} };
    default:
      return state;
  }
};

export const treatmentPlanStatisticsPerEmployeeReducer = (
  state = { stats: {} },
  action
) => {
  switch (action.type) {
    case TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_REQUEST:
      return { loading: true };
    case TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_SUCCESS:
      return { loading: false, stats: action.payload };
    case TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_FAIL:
      return { loading: false, error: action.payload };
    case TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_RESET:
      return { stats: {} };
    default:
      return state;
  }
};

export const treatmentPlanSendEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case TREATMENTPLAN_SEND_EMAIL_REQUEST:
      return { loading: true };
    case TREATMENTPLAN_SEND_EMAIL_SUCCESS:
      return { loading: false, success: true };
    case TREATMENTPLAN_SEND_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    case TREATMENTPLAN_SEND_EMAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const treatmentPlanUpdateAvailablePublicReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_REQUEST:
      return { loading: true };
    case TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_SUCCESS:
      return { loading: false, success: true };
    case TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const treatmentPlanDownloadPdfReducer = (state = {}, action) => {
  switch (action.type) {
    case TREATMENTPLAN_DOWNLOAD_PDF_REQUEST:
      return { loading: true };
    case TREATMENTPLAN_DOWNLOAD_PDF_SUCCESS:
      return { loading: false, pdf: action.payload };
    case TREATMENTPLAN_DOWNLOAD_PDF_FAIL:
      return { loading: false, error: action.payload };
    case TREATMENTPLAN_DOWNLOAD_PDF_RESET:
      return {};
    default:
      return state;
  }
};
