import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createEmployee } from "../../../../actions/userActions";
import { backgroundLight } from "../../../../utils/colors";
import {
  Button,
  Flex,
  HStack,
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  textPrimary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import StatusBadge from "../../../../components/badges/StatusBadge";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlDatePicker from "../../../../components/input/FormControlDatePicker";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlAddress from "../../../../components/input/FormControlAddress";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import FormControlProfession from "../../../../components/input/FormControlProfession";
import FormControlBank from "../../../../components/input/FormControlBank";
import FormControlRolePermission from "../../../../components/input/FormControlRolePermission";
import FormControlPassword from "../../../../components/input/FormControlPassword";
import { calculateAge } from "../../../../utils/calculations";
import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";
import { USER_CREATE_EMPLOYEE_RESET } from "../../../../constants/userConstants";

const CreateEmployee = ({ defaultRole, close }) => {
  //GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // CONSTANTS
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [role, setRole] = useState(defaultRole || "employee");
  const [birthDate, setBirthDate] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneAlt, setPhoneAlt] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [gender, setGender] = useState("other");
  const [personalCode, setPersonalCode] = useState("");
  const [status, setStatus] = useState("inactive");
  const [child, setChild] = useState(false);
  const [profession, setProfession] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [iban, setIban] = useState("");
  const [bankName, setBankName] = useState("none");

  const [createAccount, setCreateAccount] = useState(false);

  const [password, setPassword] = useState("admin");
  const [rolePermission, setRolePermission] = useState("employee_default");

  // REDUX
  const userInfo = useSelector((state) => state.authLogin.userInfo); // Assuming your state structure
  const tenantID = userInfo?.tenantID; // Get tenantID from userInfo

  // AGE
  const [ageType, setAgeType] = useState("adult");

  // TOUCHED
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [birthDateTouched, setBirthDateTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [streetTouched, setStreetTouched] = useState(false);
  const [zipTouched, setZipTouched] = useState(false);
  const [personalCodeTouched, setPersonalCodeTouched] = useState(false);
  const [cityTouched, setCityTouched] = useState(false);
  const [countryTouched, setCountryTouched] = useState(false);
  const [professionTouched, setProfessionTouched] = useState(false);

  // ERRORS
  const isFirstNameError = firstName === "";
  const isLastNameError = lastName === "";
  const isBirthDateError = birthDate === "";
  const isPersonalCodeError = personalCode === "";
  const isEmailError = email === "";
  const isPhoneError = phone === "";
  const isStreetError = street === "";
  const isZipError = zip === "";
  const isCityError = city === "";
  const isCountryError = country === "";
  const isProfessionError = profession === "";

  // FUNCTIONS

  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  // Handler for profession change
  const onProfessionChange = (selectedOption) => {
    setProfession(selectedOption.value);
    // Assuming selectedOption has the defaultRolePermission property
    const defaultRolePermission = selectedOption.defaultRolePermission;
    if (defaultRolePermission) {
      setRolePermission(defaultRolePermission);
    }
  };

  // Add this handler for the checkbox
  const handleCreateAccountChange = (e) => {
    setCreateAccount(e.target.checked);
  };

  // HANDLERS

  const closeHandler = () => {
    close();
    dispatch({ type: "USER_CREATE_EMPLOYEE_RESET" });
  };

  // SUBMIT

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      createEmployee(
        firstName,
        lastName,
        email,
        birthDate,
        phone,
        phoneAlt,
        gender,
        child,
        personalCode,
        role,
        profession,
        street,
        zip,
        city,
        state,
        country,
        iban,
        bankName,
        licenseNumber,
        rolePermission,
        password
      )
    );

    console.log("submitted data", {
      firstName,
      lastName,
      email,
      birthDate,
      phone,
      phoneAlt,
      gender,
      child,
      personalCode,
      role,
      profession,
      street,
      zip,
      city,
      state,
      country,
      iban,
      bankName,
      licenseNumber,
      rolePermission,
      password,
    });

    close();
  };

  // USE EFFECT

  useEffect(() => {
    if (birthDate) {
      const age = calculateAge(birthDate);
      const newAgeType = age >= 18 ? "adult" : "kid";

      setAgeType(newAgeType);

      // Set child based on newAgeType
      setChild(newAgeType === "kid");
    }
  }, [birthDate]);

  // UseEffect to update gender based on personalCode
  useEffect(() => {
    const firstChar = personalCode.charAt(0);
    if (firstChar === "3" || firstChar === "5") {
      setGender("male");
    } else if (firstChar === "4" || firstChar === "6") {
      setGender("female");
    }
  }, [personalCode]); // Dependency array includes personalCode

  // LOGS

  // console.log("firstName", firstName);
  // console.log("lastName", lastName);
  // console.log("email", email);
  // console.log("birthDate", birthDate);
  // console.log("phone", phone);
  // console.log("phoneAlt", phoneAlt);
  // console.log("gender", gender);
  // console.log("child", child);
  console.log("personalCode", personalCode);
  // console.log("role", role);
  // console.log("street", street);
  // console.log("zip", zip);
  // console.log("city", city);
  // console.log("country", country);
  // console.log("state", state);
  console.log("iban", iban);
  console.log("bankName", bankName);
  // console.log("profession", profession);
  console.log("licenseNumber", licenseNumber);

  // console.log("ageType", ageType);

  // console.log("profession", profession);

  // console.log("rolePermission", rolePermission);

  return (
    <>
      <Box position="relative">
        <form
          onSubmit={submitHandler}
          style={{
            height: "100%",
          }}
        >
          <SidePanelHeader
            close={closeHandler}
            title={t("employee.createEmployee")}
            ageType={ageType}
            status={status}
          />

          <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="7rem 0 0"
            >
              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("employee.details")}
              </Heading>

              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1.5rem"
              >
                <HStack gap={4} w="100%">
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.firstName")}
                    value={firstName}
                    placeholder={t("placeholder.firstName")}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setFirstNameTouched(true);
                    }}
                    isTouched={firstNameTouched}
                    isError={isFirstNameError}
                    w="50%"
                    errorMessage={t("formError.firstNameRequired")}
                  />

                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.lastName")}
                    value={lastName}
                    placeholder={t("placeholder.lastName")}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setLastNameTouched(true);
                    }}
                    isTouched={lastNameTouched}
                    isError={isLastNameError}
                    w="50%"
                    errorMessage={t("formError.lastNameRequired")}
                  />
                </HStack>

                <HStack gap={4} w="100%">
                  <FormControlDatePicker
                    type="date"
                    isRequired
                    label={t("form.birthDate")}
                    // value={birthDate}
                    // value="1970-01-01T00:00:34.357Z"
                    placeholder={t("placeholder.birthDate")}
                    onChange={(newValue) => {
                      setBirthDate(newValue);
                    }}
                    onOpen={() => {}}
                    onClose={() => setBirthDateTouched(true)}
                    isTouched={birthDateTouched}
                    isError={isBirthDateError}
                    w="50%"
                    errorMessage={t("formError.birthDateRequired")}
                  />

                  <FormControlDefault
                    type="number"
                    isRequired
                    label={t("form.personalCode")}
                    value={personalCode}
                    placeholder={t("placeholder.personalCode")}
                    onChange={(e) => {
                      setPersonalCode(e.target.value);
                      setPersonalCodeTouched(true);
                    }}
                    isTouched={personalCodeTouched}
                    isError={isPersonalCodeError}
                    w="50%"
                    errorMessage={t("formError.personalCodeRequired")}
                  />
                </HStack>

                <HStack gap={4} w="100%">
                  <FormControlProfession
                    label={t("form.profession")}
                    value={profession}
                    placeholder={t("placeholder.profession")}
                    onChange={onProfessionChange}
                    setIsTouched={() => setProfessionTouched(true)}
                    isRequired={true}
                    isTouched={professionTouched}
                    isError={isProfessionError}
                    errorMessage={t("formError.professionRequired")}
                    w="50%"
                    onProfessionChange={onProfessionChange}
                  />

                  <FormControlDefault
                    type="text"
                    // isRequired
                    label={t("form.licenseNumber")}
                    value={licenseNumber}
                    placeholder={t("placeholder.licenseNumber")}
                    onChange={(e) => {
                      setLicenseNumber(e.target.value);
                    }}
                    w="50%"
                  />
                </HStack>
              </Flex>
            </Flex>

            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="0 0 4rem"
            >
              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("employee.addressContactDetails")}
              </Heading>
              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1.5rem"
              >
                <HStack gap={4} w="100%">
                  <FormControlPhone
                    label={t("form.phone")}
                    value={phone}
                    onChange={(newPhone) => {
                      setPhone(newPhone);
                      setPhoneTouched(true);
                    }}
                    isRequired={true}
                    isTouched={phoneTouched}
                    isError={isPhoneError}
                    errorMessage={t("formError.phoneRequired")}
                    w="50%"
                    defaultCountry="lt"
                  />

                  <FormControlPhone
                    label={t("form.phoneAlt")}
                    value={phoneAlt}
                    onChange={(newPhone) => {
                      setPhoneAlt(newPhone);
                    }}
                    isRequired={false}
                    errorMessage={t("formError.phoneRequired")}
                    w="50%"
                    defaultCountry="lt"
                  />
                </HStack>

                <FormControlDefault
                  type="email"
                  isRequired
                  label={t("form.email")}
                  value={email}
                  placeholder={t("placeholder.email")}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailTouched(true);
                  }}
                  isTouched={emailTouched}
                  isError={isEmailError}
                  w="100%"
                  errorMessage={t("formError.emailRequired")}
                />

                <HStack gap={4} w="100%">
                  <FormControlAddress
                    label={t("form.address")}
                    onAddressSelect={handleAddressSelect}
                    isRequired={true}
                    isTouched={streetTouched} // Assuming you have similar touch state for street
                    isError={isStreetError} // Assuming you have similar error state for street
                    setIsTouched={setStreetTouched}
                    errorMessage={t("formError.addressRequired")}
                    w="100%"
                    googleApiKey="AIzaSyCNQfHnntkLgj5Es5V8V0H2BfIetOOZ9yw"
                  />

                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.zip")}
                    value={zip}
                    placeholder={t("placeholder.zip")}
                    onChange={(e) => {
                      setZip(e.target.value);
                      setZipTouched(true);
                    }}
                    isTouched={zipTouched}
                    isError={isZipError}
                    w="40%"
                    errorMessage={t("formError.zipRequired")}
                  />
                </HStack>

                <HStack gap={4} w="100%">
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.city")}
                    value={city}
                    placeholder={t("placeholder.city")}
                    onChange={(e) => {
                      setCity(e.target.value);
                      setCityTouched(true);
                    }}
                    isTouched={cityTouched}
                    isError={isCityError}
                    w="50%"
                    errorMessage={t("formError.cityRequired")}
                  />

                  <FormControlCountry
                    label={t("form.country")}
                    value={country}
                    isTouched={countryTouched}
                    isError={isCountryError}
                    onChange={(selectedOption) => {
                      setCountry(selectedOption ? selectedOption.value : "");
                      setCountryTouched(true);
                    }}
                    isRequired={true}
                    w="50%"
                    errorMessage={t("formError.countryRequired")}
                  />
                </HStack>
              </Flex>

              {/* <Checkbox
                  isChecked={createAccount}
                  onChange={handleCreateAccountChange}
                >
                  {t("employee.createAccount")}
                </Checkbox> */}

              <Flex direction="column" w="100%" p="0rem 0" mb={2}>
                <Accordion allowToggle>
                  <AccordionItem border="none">
                    <AccordionButton
                      borderRadius="0rem"
                      borderBottom={`1px solid ${newBorder}`}
                      transition="0.2s all"
                      p="0"
                      _hover={{
                        bg: "none",
                        pl: 2,
                        transition: "0.2s all",
                      }}
                      _expanded={{
                        borderBottom: "none",
                      }}
                    >
                      <Flex align="center" justify="space-between" w="100%">
                        <Heading
                          fontSize="1.125rem"
                          color={textPrimary}
                          fontWeight="600"
                          m="1rem 0"
                        >
                          {t("employee.bankDetails")}
                        </Heading>
                        <AccordionIcon color={textSecondary} />
                      </Flex>
                    </AccordionButton>

                    <AccordionPanel pb={0} px={0}>
                      <Flex
                        direction="column"
                        w="100%"
                        bg={backgroundLight}
                        borderRadius="1rem"
                        p="1.5rem"
                      >
                        <HStack gap={4} w="100%">
                          <FormControlDefault
                            type="text"
                            // isRequired
                            label={t("form.iban")}
                            value={iban}
                            placeholder={t("placeholder.iban")}
                            onChange={(e) => {
                              setIban(e.target.value);
                            }}
                            // isTouched={firstNameTouched}
                            // isError={isFirstNameError}
                            w="50%"
                            // errorMessage={t("formError.firstNameRequired")}
                          />

                          <FormControlBank
                            label={t("form.bankName")}
                            value={bankName}
                            placeholder={t("placeholder.bankName")}
                            onChange={(selectedOption) => {
                              setBankName(selectedOption.value);
                            }}
                            w="50%"
                          />
                        </HStack>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>

              <Flex direction="column" w="100%" p="0rem 0">
                <Accordion allowToggle>
                  <AccordionItem border="none">
                    <AccordionButton
                      borderRadius="0rem"
                      borderBottom={`1px solid ${newBorder}`}
                      transition="0.2s all"
                      p="0"
                      _hover={{
                        bg: "none",
                        pl: 2,
                        transition: "0.2s all",
                      }}
                      _expanded={{
                        borderBottom: "none",
                      }}
                    >
                      <Flex align="center" justify="space-between" w="100%">
                        <Heading
                          fontSize="1.125rem"
                          color={textPrimary}
                          fontWeight="600"
                          m="1rem 0"
                        >
                          {t("employee.additionalDetails")}
                        </Heading>
                        <AccordionIcon color={textSecondary} />
                      </Flex>
                    </AccordionButton>

                    <AccordionPanel pb={0} px={0}>
                      <Flex
                        direction="column"
                        w="100%"
                        bg={backgroundLight}
                        borderRadius="1rem"
                        p="1.5rem"
                      >
                        <HStack gap={4} w="100%">
                          <FormControlRolePermission
                            isRequired
                            label={t("form.rolePermission")}
                            value={rolePermission}
                            placeholder={t("placeholder.rolePermission")}
                            onChange={(selectedOption) => {
                              setRolePermission(selectedOption.value);
                            }}
                            w="50%"
                          />

                          <FormControlPassword
                            isRequired={false}
                            label={t("form.password")}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            w="50%"
                          />
                        </HStack>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>
            </Flex>

            <Flex
              mt={4}
              justify="center"
              direction="column"
              borderTop={`1px solid ${newBorder}`}
              p="1.5rem 0"
              position="relative"
              zIndex={0}
              bg={white}
              bottom="0"
              w="100%"
            >
              <Flex justify="flex-end">
                <Button
                  minWidth="10rem"
                  mr={4}
                  bg={backgroundLight}
                  size="lg"
                  color={textSecondary}
                  fontSize="md"
                  borderRadius="10rem"
                  onClick={closeHandler}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  minWidth="10rem"
                  type="submit"
                  size="lg"
                  bg={primary}
                  color={white}
                  fontSize="md"
                  borderRadius="10rem"
                  isDisabled={
                    !firstName.trim() ||
                    !lastName.trim() ||
                    !personalCode.trim() ||
                    !email.trim() ||
                    !birthDate.trim() ||
                    !phone.trim() ||
                    !street.trim() ||
                    !zip.trim() ||
                    !city.trim() ||
                    !country.trim() ||
                    !profession.trim() ||
                    !password.trim()
                  }
                >
                  {t("employee.createEmployee")}
                </Button>
              </Flex>
            </Flex>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default CreateEmployee;
