import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Avatar, Stack, Text } from "@chakra-ui/react";
import {
  textSecondary,
  newBorder,
  textPrimary,
} from "../../../../../utils/colors";
import { listPublicTenantDetails } from "../../../../../actions/publicActions";
import useIsMobile from "../../../../../hooks/useIsMobile";

const TenantPublicInfo = ({ tenantID }) => {
  // REDUX

  const dispatch = useDispatch();
  const publicTenantDetails = useSelector((state) => state.publicTenantDetails);
  const { loading, error, tenant } = publicTenantDetails;

  const defaultImageUrl = "https://via.placeholder.com/150";

  const isMobile = useIsMobile();

  useEffect(() => {
    dispatch(listPublicTenantDetails(tenantID));
  }, [dispatch, tenantID]);

  // LOGS
  console.log("tenantID: ", tenantID);
  console.log("tenant: ", tenant);

  // RENDER
  return (
    <Flex align="center">
      {tenant.image ? (
        <Avatar
          w="2.5rem"
          h="2.5rem"
          name={tenant.clinicName || "-"}
          src={tenant.image}
          bg="#dceeff"
          fontSize="0.5rem"
          color="#3a74a9"
          border={`1px solid ${newBorder}`}
        />
      ) : (
        <Avatar
          w="2.5rem"
          h="2.5rem"
          name={tenant.clinicName || "-"}
          bg="#dceeff"
          fontSize="0.5rem"
          color="#3a74a9"
          border={`1px solid ${newBorder}`}
        />
      )}
      <Stack gap={1.5} ml={3}>
        <Text
          fontSize="15px"
          fontWeight="600"
          color={textPrimary}
          whiteSpace="nowrap"
          lineHeight={1}
        >
          {tenant.clinicName || "-"}
        </Text>

        <Flex align="center">
          {isMobile ? (
            <Flex align="center">
              <Text
                fontSize="sm"
                color={textSecondary}
                lineHeight={1}
                fontWeight="500"
                maxWidth="150px"
                isTruncated
              >
                {tenant.phone || "-"}{" "}
              </Text>
              <Flex bg={newBorder} h="12px" w="1px" mx="0.5rem" />
              <Text
                fontSize="sm"
                color={textSecondary}
                lineHeight={1}
                fontWeight="500"
                maxWidth="150px"
                isTruncated
              >
                {tenant.email || "-"}
              </Text>
            </Flex>
          ) : (
            <Text
              fontSize="sm"
              color={textSecondary}
              lineHeight={1}
              fontWeight="500"
              maxWidth="200px"
              isTruncated
            >
              {(tenant.address &&
                tenant.address.length > 0 &&
                `${tenant.address[0].street}, ${tenant.address[0].city}`) ||
                "-"}
            </Text>
          )}

          {!isMobile && (
            <>
              <Flex bg={newBorder} h="12px" w="1px" mx="0.5rem" />
              <Text
                fontSize="sm"
                color={textSecondary}
                lineHeight={1}
                fontWeight="500"
                maxWidth="200px"
                isTruncated
              >
                {tenant.phone || "-"}
              </Text>
              <Flex bg={newBorder} h="12px" w="1px" mx="0.5rem" />
              <Text
                fontSize="sm"
                color={textSecondary}
                lineHeight={1}
                fontWeight="500"
                maxWidth="200px"
                isTruncated
              >
                {tenant.email || "-"}
              </Text>
            </>
          )}
        </Flex>
      </Stack>
    </Flex>
  );
};

export default TenantPublicInfo;
