import React from "react";
import { Flex, Text, Tooltip, Icon, Image } from "@chakra-ui/react";
import { TbDeviceMobileStar } from "react-icons/tb";
import { white, textSecondary } from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import blank from "../../../../assets/icons/blank.svg";
import { shade } from "polished";

const InfoEntryTooltip = ({ isDisabled, textLabel, label, icon }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      label={
        !isDisabled && (
          <Flex align="center">
            <Text color={shade(0.3, white)} fontSize="sm" fontWeight="400">
              {textLabel}:
            </Text>

            <Text color={white} fontSize="sm" fontWeight="500" ml={1}>
              {label}
            </Text>
          </Flex>
        )
      }
      placement="top-start"
    >
      <Flex align="center" mb={4} _last={{ mb: 0 }}>
        <Icon as={icon} h="1.25rem" w="1.25rem" color={textSecondary} mr={2} />
        {!isDisabled ? (
          <Text
            color={textSecondary}
            fontSize="sm"
            fontWeight="500"
            lineHeight="1.2"
            isTruncated
            maxW="160px"
            position="relative"
            top="1px"
          >
            {label}
          </Text>
        ) : (
          <Image src={blank} size="sm" />
        )}
      </Flex>
    </Tooltip>
  );
};

export default InfoEntryTooltip;
