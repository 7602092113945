import React, { useState, useEffect } from "react";
import {
  Text,
  Flex,
  Button,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  Heading,
  Box,
  HStack,
  useDisclosure,
  useToast,
  Switch,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  textSecondary,
  textPrimary,
  backgroundLight,
  white,
  buttonBlue,
  newBorder,
  buttonYellow,
  buttonYellowText,
} from "../../utils/colors";
import dayjs from "dayjs";
import { FaXmark } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import FormControlDefault from "../input/FormControlDefault";
import FormControlStatusPatient from "../input/FormControlStatusPatient";
import FormControlAddressState from "../input/FormControlAddressState";
import FormControlStatus from "../input/FormControlStatus";
import FormControlServiceGroup from "../input/FormControlServiceGroup";
import FormControlServiceGroupMulti from "../input/FormControlServiceGroupMulti";
import FormControlDuration from "../input/FormControlDuration";
import FormControlRangePicker from "../input/FormControlRangePicker";
import FormControlRangePickerDuration from "../input/FormControlRangePickerDuration";
import FormControlDatePickerRangeDouble from "../input/FormControlDatePickerRangeDouble";
import FormControlEmployee from "../input/FormControlEmployee";
import ToastComponent from "../partials/ToastComponent";
import useRoleCheck from "../../hooks/useRoleCheck";
import FormControlProfessionMulti from "../input/FormControlProfessionMulti";
import FormControlConsulting from "../input/FormControlConsulting";
import { parseISO } from "date-fns";

const FilterPopover = ({
  onFilterApply,
  isFilterActive,
  setIsFilterActive,
  filterConfig,
  filterStatusOptions,
  savedFilters,
  savedFiltersKey,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toastComponent = ToastComponent();

  const isAdmin = useRoleCheck("admin");
  const isDoctor = useRoleCheck("employee_doctor");

  const [filterState, setFilterState] = useState({
    minAge: 0,
    maxAge: 100,
    minPrice: 0,
    maxPrice: 50000,
    startDate: "",
    endDate: "",
    status: [],
    state: [],
    serviceGroup: [],
    minDuration: 0,
    maxDuration: 14400,
    employeeId: "",
    creatorEmployeeId: "",
    profession: [],
    isConsulting: false,
  });

  const applyFilters = () => {
    console.log("filterState in applyFilters", filterState);

    // If only endDate is selected, set startDate to the same as endDate
    if (!filterState.startDate && filterState.endDate) {
      filterState.startDate = filterState.endDate;
    }

    // If only startDate is selected, set endDate to the current date
    if (filterState.startDate && !filterState.endDate) {
      filterState.endDate = new Date();
    }

    const formattedFilterState = {
      ...filterState,

      status: filterState.status.length > 0 ? filterState.status.join(",") : "",
      state: filterState.state.length > 0 ? filterState.state.join(",") : "",
      profession:
        filterState.profession.length > 0
          ? filterState.profession.join(",")
          : "",
      serviceGroup:
        filterState.serviceGroup.length > 0
          ? filterState.serviceGroup.join(",")
          : "",

      employeeId:
        filterState.employeeId.length > 0
          ? filterState.employeeId.join(",")
          : "",

      creatorEmployeeId:
        filterState.creatorEmployeeId.length > 0
          ? filterState.creatorEmployeeId.join(",")
          : "",
    };
    onFilterApply(formattedFilterState);
    onClose();
  };

  // Update the state when the range changes
  const handleAgeChange = (values) => {
    setFilterState({ ...filterState, minAge: values[0], maxAge: values[1] });
  };

  // Update the handler to work with date range
  const handleDateRangeChange = (start, end) => {
    setFilterState({ ...filterState, startDate: start, endDate: end });
  };

  const handlePriceChange = (values) => {
    setFilterState({
      ...filterState,
      minPrice: values[0],
      maxPrice: values[1],
    });
  };

  const handleDurationChange = (values) => {
    setFilterState({
      ...filterState,
      minDuration: values[0],
      maxDuration: values[1],
    });
  };

  const handleStatusChange = (selectedOptions) => {
    // console.log("Selected Options: ", selectedOptions);

    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterState({ ...filterState, status: selectedValues });
  };

  const handleServiceGroupChange = (selectedOptions) => {
    // console.log("Selected Options: ", selectedOptions);

    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterState({ ...filterState, serviceGroup: selectedValues });
  };

  const handleProfessionChange = (selectedOptions) => {
    // console.log("Selected Options: ", selectedOptions);

    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterState({ ...filterState, profession: selectedValues });
  };

  const handleStateChange = (selectedOptions) => {
    // console.log("Selected Options: ", selectedOptions);

    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterState({ ...filterState, state: selectedValues });
  };

  const handleEmployeeChange = (selectedOptions) => {
    // console.log("Selected Options: ", selectedOptions);

    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterState({ ...filterState, employeeId: selectedValues });
  };

  const handleCreatorEmployeeChange = (selectedOptions) => {
    // console.log("Selected Options: ", selectedOptions);

    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterState({ ...filterState, creatorEmployeeId: selectedValues });
  };

  const handleIsConsultingChange = (e) => {
    setFilterState({
      ...filterState,
      isConsulting: e.target.checked,
    });
  };

  // CLEAR FILTERS
  const clearFilters = () => {
    const resetState = {
      minAge: 0,
      maxAge: 100,
      minPrice: 0,
      maxPrice: 50000,
      startDate: "",
      endDate: "",
      status: [],
      state: [],
      serviceGroup: [],
      minDuration: 0,
      maxDuration: 14440,
      employeeId: [],
      creatorEmployeeId: [],
      profession: [],
      isConsulting: false,
    };
    setFilterState(resetState);
    onFilterApply(resetState);
    setIsFilterActive(false);

    localStorage.removeItem(savedFiltersKey);
    onClose();
  };

  // GENERAL
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  // useEffect(() => {
  //   const savedFiltersStr = localStorage.getItem(savedFiltersKey);
  //   if (savedFiltersStr) {
  //     const savedFilters = JSON.parse(savedFiltersStr);

  //     // Ensure arrays are correctly parsed
  //     // No manual conversion should be necessary if saved as an array

  //     // Parse dates if necessary
  //     savedFilters.startDate = savedFilters.startDate
  //       ? parseISO(savedFilters.startDate)
  //       : "";
  //     savedFilters.endDate = savedFilters.endDate
  //       ? parseISO(savedFilters.endDate)
  //       : "";

  //     setFilterState(savedFilters);
  //   }
  // }, [savedFiltersKey]);

  useEffect(() => {
    const savedFiltersStr = localStorage.getItem(savedFiltersKey);
    if (savedFiltersStr) {
      let savedFilters = JSON.parse(savedFiltersStr);

      // Explicitly convert `status` back to an array if it's not already
      if (typeof savedFilters.status === "string") {
        savedFilters.status = savedFilters.status.split(",");
      }
      if (typeof savedFilters.state === "string") {
        savedFilters.state = savedFilters.state.split(",");
      }

      if (typeof savedFilters.employeeId === "string") {
        savedFilters.employeeId = savedFilters.employeeId.split(",");
      }

      // Repeat similar checks for any other field that should be an array
      // For example:
      // if (typeof savedFilters.serviceGroup === 'string') {
      //   savedFilters.serviceGroup = savedFilters.serviceGroup.split(",");
      // }

      // Handle date fields if necessary
      savedFilters.startDate = savedFilters.startDate
        ? parseISO(savedFilters.startDate)
        : "";
      savedFilters.endDate = savedFilters.endDate
        ? parseISO(savedFilters.endDate)
        : "";

      setFilterState(savedFilters);
    }
  }, [savedFiltersKey]);

  // console.log("filterState", filterState);
  // console.log("localStorage", localStorage.getItem(savedFiltersKey));

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement={"bottom-end"}>
      <PopoverTrigger position="relative">
        <Button
          border={`1px solid ${newBorder}`}
          borderRadius="10rem"
          size="lg"
          fontSize="md"
          fontWeight="500"
          // color={isFilterActive ? buttonYellowText : textSecondary}
          color={textSecondary}
          onClick={onOpen}
          zIndex="0"
          bg={white}
          align="center"
          transition="0.2s all"
          _hover={{
            bg: buttonBlue,

            border: `1px solid ${buttonBlue}`,
          }}
          mr={1}
        >
          {t("filter.filter")}
          {isFilterActive && (
            <Flex
              position="absolute"
              top="-0.5rem"
              right="-0.5rem"
              bg={buttonYellow}
              borderRadius="50%"
              w="1.5rem"
              h="1.5rem"
              justify="center"
              align="center"
              zIndex="1"
            >
              <Text
                fontSize="0.75rem"
                fontWeight="600"
                color={buttonYellowText}
              >
                !
              </Text>
            </Flex>
          )}
        </Button>
      </PopoverTrigger>
      {isOpen && (
        <PopoverContent
          border="none"
          boxShadow="0px 4px 12px 3px rgba(0, 0, 0, 0.20)"
          borderRadius="1rem"
          bg={white}
          w="460px"
        >
          <PopoverArrow />
          {/* <PopoverCloseButton /> */}
          <PopoverHeader
            borderTopLeftRadius="1rem"
            borderTopRightRadius="1rem"
            borderBottom={`1px solid ${newBorder}`}
            p="1rem 1.5rem"
          >
            <Flex w="100%" justify="space-between" align="center">
              <Heading fontSize="1rem" fontWeight="500" color={textPrimary}>
                {t("filter.filter")}
              </Heading>
              <IconButton
                h="1.5rem"
                w="1rem"
                icon={<FaXmark />}
                bg="none"
                onClick={handleClose}
                color={textSecondary}
              />
            </Flex>
          </PopoverHeader>
          <PopoverBody p="1rem 1.5rem 0rem">
            {filterConfig.patients && (
              <>
                <FormControlDatePickerRangeDouble
                  type="date"
                  label={t("form.regDate")}
                  placeholder={t("placeholder.regStartDate")}
                  onChange={(start, end) => handleDateRangeChange(start, end)}
                  startDate={filterState.startDate}
                  endDate={filterState.endDate}
                  onOpen={() => {}}
                  w="50%"
                  isSmall
                />
                <FormControlRangePicker
                  isSmall
                  label={t("form.age")}
                  min={filterState.minAge}
                  max={filterState.maxAge}
                  minFilter={0}
                  maxFilter={120}
                  step={1}
                  setMin={handleAgeChange} // Pass the new handler
                  setMax={handleAgeChange} // Pass the new handler
                />
                <FormControlStatus
                  label={t("form.patientStatus")}
                  value={filterState.status}
                  placeholder={t("placeholder.patientStatus")}
                  options={filterStatusOptions}
                  onChange={handleStatusChange}
                  // isRequired={true}
                  // isTouched={durationTouched}
                  // isError={isDurationError}
                  // errorMessage={t("formError.durationRequired")}
                  w="100%"
                  isSmall
                  isMulti
                />
                <Box w="100%" mb={8}>
                  <FormControlAddressState
                    label={t("form.addressState")}
                    value={filterState.state}
                    placeholder={t("placeholder.addressState")}
                    onChange={handleStateChange}
                    // isRequired={true}
                    // isTouched={durationTouched}
                    // isError={isDurationError}
                    // errorMessage={t("formError.durationRequired")}
                    w="100%"
                    isSmall
                    isMulti
                  />
                </Box>
              </>
            )}

            {filterConfig.employees && (
              <>
                <FormControlProfessionMulti
                  label={t("form.profession")}
                  value={filterState.profession}
                  placeholder={t("placeholder.profession")}
                  onChange={handleProfessionChange}
                  w="100%"
                  isSmall
                  isMulti
                />
                {isAdmin && (
                  <>
                    <FormControlDatePickerRangeDouble
                      type="date"
                      label={t("form.regDate")}
                      placeholder={t("placeholder.regStartDate")}
                      onChange={(start, end) =>
                        handleDateRangeChange(start, end)
                      }
                      startDate={filterState.startDate}
                      endDate={filterState.endDate}
                      onOpen={() => {}}
                      w="50%"
                      isSmall
                    />

                    <FormControlStatus
                      label={t("form.patientStatus")}
                      value={filterState.status}
                      placeholder={t("placeholder.patientStatus")}
                      options={filterStatusOptions}
                      onChange={handleStatusChange}
                      // isRequired={true}
                      // isTouched={durationTouched}
                      // isError={isDurationError}
                      // errorMessage={t("formError.durationRequired")}
                      w="100%"
                      isSmall
                      isMulti
                    />
                  </>
                )}
              </>
            )}
            {filterConfig.services && (
              <>
                <FormControlRangePicker
                  isSmall
                  label={t("form.price")}
                  min={filterState.minPrice}
                  max={filterState.maxPrice}
                  minFilter={0}
                  maxFilter={5000}
                  step={100}
                  setMin={handlePriceChange} // Pass the new handler
                  setMax={handlePriceChange} // Pass the new handler
                />

                <FormControlRangePickerDuration
                  isSmall
                  label={t("form.duration")}
                  min={filterState.minDuration}
                  max={filterState.maxDuration}
                  minFilter={0}
                  maxFilter={14400}
                  step={900}
                  setMin={handleDurationChange} // Pass the new handler
                  setMax={handleDurationChange} // Pass the new handler
                />

                {isAdmin && (
                  <Box w="100%" mb={0}>
                    <FormControlStatus
                      label={t("form.status")}
                      value={filterState.status}
                      placeholder={t("placeholder.status")}
                      options={filterStatusOptions}
                      onChange={handleStatusChange}
                      // isRequired={true}
                      // isTouched={durationTouched}
                      // isError={isDurationError}
                      // errorMessage={t("formError.durationRequired")}
                      w="100%"
                      isSmall
                      isMulti
                    />
                  </Box>
                )}

                <Box w="100%" mb={8}>
                  {/* <FormControlServiceGroup
                  label={t("form.serviceGroup")}
                  value={filterState.serviceGroup}
                  placeholder={t("placeholder.serviceGroup")}
                  onChange={(selectedOption) => {
                    setFilterState({
                      ...filterState,
                      serviceGroup: selectedOption.value,
                    });
                    // setDurationTouched(true);
                  }}
                  // isRequired={true}
                  // isTouched={durationTouched}
                  // isError={isDurationError}
                  // errorMessage={t("formError.durationRequired")}
                  w="100%"
                  isSmall
                /> */}

                  <FormControlServiceGroupMulti
                    label={t("form.serviceGroup")}
                    value={filterState.serviceGroup}
                    placeholder={t("placeholder.serviceGroup")}
                    onChange={handleServiceGroupChange}
                    w="100%"
                    isSmall
                    isMulti
                  />
                </Box>
              </>
            )}

            {filterConfig.treatmentPlans && (
              <>
                {!isDoctor && (
                  <>
                    <FormControlEmployee
                      label={t("form.employeeInService")}
                      value={filterState.employeeId}
                      placeholder={t("placeholder.employeeInService")}
                      onChange={handleEmployeeChange}
                      isMulti
                      isSmall
                    />

                    {/* <FormControlEmployee
                      label={t("form.employeeCreator")}
                      value={filterState.creatorEmployeeId}
                      placeholder={t("placeholder.employeeCreator")}
                      onChange={handleCreatorEmployeeChange}
                      isMulti
                      isSmall
                    /> */}
                  </>
                )}

                <FormControlDatePickerRangeDouble
                  type="date"
                  label={t("form.createdAt")}
                  placeholder={t("placeholder.createdAt")}
                  onChange={(start, end) => handleDateRangeChange(start, end)}
                  startDate={filterState.startDate}
                  endDate={filterState.endDate}
                  onOpen={() => {}}
                  w="50%"
                  isSmall
                />

                <FormControlRangePicker
                  isSmall
                  label={t("form.price")}
                  min={filterState.minPrice}
                  max={filterState.maxPrice}
                  minFilter={0}
                  maxFilter={50000}
                  step={100}
                  setMin={handlePriceChange} // Pass the new handler
                  setMax={handlePriceChange} // Pass the new handler
                />

                {/* <FormControlRangePicker
                isSmall
                label={t("form.age")}
                min={filterState.minAge}
                max={filterState.maxAge}
                minFilter={0}
                maxFilter={120}
                step={1}
                setMin={handleAgeChange} // Pass the new handler
                setMax={handleAgeChange} // Pass the new handler
              /> */}

                <Box w="100%" mb={10}>
                  <FormControlStatus
                    label={t("form.status")}
                    value={filterState.status}
                    placeholder={t("placeholder.status")}
                    options={filterStatusOptions}
                    onChange={handleStatusChange}
                    // isRequired={true}
                    // isTouched={durationTouched}
                    // isError={isDurationError}
                    // errorMessage={t("formError.durationRequired")}
                    w="100%"
                    isSmall
                    isMulti
                  />
                </Box>
              </>
            )}

            {filterConfig.serviceGroups && (
              <Box w="100%" mb={0}>
                {isAdmin && (
                  <FormControlStatus
                    label={t("form.status")}
                    value={filterState.status}
                    placeholder={t("placeholder.status")}
                    options={filterStatusOptions}
                    onChange={handleStatusChange}
                    // isRequired={true}
                    // isTouched={durationTouched}
                    // isError={isDurationError}
                    // errorMessage={t("formError.durationRequired")}
                    w="100%"
                    isSmall
                    isMulti
                  />
                )}

                <FormControlConsulting
                  label={t("form.isConsulting")}
                  value={filterState.isConsulting}
                  placeholder={t("placeholder.isConsulting")}
                  onChange={handleIsConsultingChange}
                  w="100%"
                  isSmall
                />
              </Box>
            )}
          </PopoverBody>
          <PopoverFooter p="1rem 1.5rem">
            <Flex w="100%" align="center" justify="space-between">
              <Flex align="center">
                <Button
                  variant="outline"
                  size="md"
                  fontSize="sm"
                  color={textSecondary}
                  borderRadius="10rem"
                  fontWeight="500"
                  onClick={clearFilters}
                  p="1rem 1.5rem"
                  mr={2}
                >
                  {t("filter.clearFilter")}
                </Button>
              </Flex>

              <Flex align="center" justify="flex-end">
                <Button
                  variant="outline"
                  size="md"
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  borderRadius="10rem"
                  onClick={handleClose}
                  p="1rem 1.5rem"
                >
                  {t("button.close")}
                </Button>
                <Button
                  size="md"
                  fontSize="sm"
                  fontWeight="600"
                  bg={primary}
                  color={white}
                  borderRadius="10rem"
                  p="1rem 1.5rem"
                  onClick={applyFilters}
                  ml={2}
                >
                  {t("button.filterApply")}
                </Button>
              </Flex>
            </Flex>
          </PopoverFooter>
        </PopoverContent>
      )}
    </Popover>
  );
};

export default FilterPopover;
