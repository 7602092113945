import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  listAttendees,
  deleteAttendee,
  updateAttendeeStatus,
  createAttendee,
  updateAttendee,
} from "../../../../actions/attendeeActions";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import { Box, Button, Flex } from "@chakra-ui/react";
import {
  textSecondary,
  white,
  newBorder,
  primary,
} from "../../../../utils/colors";
import { icons } from "../../../../utils/icons";
import { columns } from "../columns/AttendeesColumns"; // Adjust the path according to your file structure
import CreateAttendee from "../sidepanels/CreateAttendee";
import EditAttendee from "../sidepanels/EditAttendee";
import { useTranslation } from "react-i18next";
import {
  ATTENDEE_ACTIVE_STATUS_UPDATE_RESET,
  ATTENDEE_CREATE_RESET,
  ATTENDEE_UPDATE_RESET,
  ATTENDEE_DELETE_RESET,
  ATTENDEE_DETAILS_RESET,
} from "../../../../constants/attendeeConstants";
import ToastComponent from "../../../../components/partials/ToastComponent";

const Attendees = () => {
  const dispatch = useDispatch();

  const toast = useToast();
  const toastComponent = ToastComponent();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const translate = (key) => t(key);

  // STATE
  const [attendeeId, setAttendeeId] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [searchCity] = useState({
    value: "",
    label: "All cities",
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sidePanelModalOpen, setSidePanelOpen] = useState(false);
  const [editSidePanelOpen, setEditSidePanelOpen] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const pageSizes = [4, 10, 25, 100];

  // REDUX

  const attendeeList = useSelector((state) => state.attendeeList);
  const { loading, error, attendees, pages } = attendeeList;

  const attendeeDelete = useSelector((state) => state.attendeeDelete);
  const { success: successDelete } = attendeeDelete;

  const attendeeCreate = useSelector((state) => state.attendeeCreate);
  const {
    error: errorCreate,
    success: successCreate,
    attendee: createdAttendee,
  } = attendeeCreate;

  const attendeeUpdate = useSelector((state) => state.attendeeUpdate);
  const { success: successUpdate } = attendeeUpdate;

  const attendeeStatusUpdate = useSelector(
    (state) => state.attendeeStatusUpdate
  );

  const { success: successStatusUpdate } = attendeeStatusUpdate;

  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  // SORTING
  const [sortField, setSortField] = useState("customId");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (column) => {
    const newSortOrder =
      sortField === column && sortOrder === "asc" ? "desc" : "asc";
    console.log(`Sorting ${column} in ${newSortOrder} order`); // Log the sorting action

    setSortField(column);
    setSortOrder(newSortOrder);
    console.log("sortField", sortField);
    console.log("sortOrder", sortOrder);

    dispatch(listAttendees(searchTitle, 1, pageSize, sortField, sortOrder));
  };

  // HANDLERS

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const deleteAttendeeHandler = (attendeeId) => {
    dispatch(deleteAttendee(attendeeId));
  };

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    }

    if (successCreate) {
      toastComponent.showToast(t("toasts.attendeeCreate"), "success", 3000);
      closeSidePanelModal();
      document.body.classList.remove("no-scroll");

      dispatch({ type: ATTENDEE_CREATE_RESET });
      dispatch({ type: ATTENDEE_DETAILS_RESET });

      dispatch(listAttendees(searchTitle, 1, pageSize, sortField, sortOrder));
    } else if (successUpdate) {
      toastComponent.showToast(t("toasts.attendeeUpdate"), "success", 3000);
      closeEditSidePanelModal();
      document.body.classList.remove("no-scroll");
      dispatch({ type: ATTENDEE_UPDATE_RESET });
      dispatch({ type: ATTENDEE_DETAILS_RESET });

      dispatch(listAttendees(searchTitle, 1, pageSize, sortField, sortOrder));
    } else if (successStatusUpdate) {
      toastComponent.showToast(t("toasts.attendeeStatus"), "success", 3000);
      dispatch({ type: ATTENDEE_ACTIVE_STATUS_UPDATE_RESET });
      document.body.classList.remove("no-scroll");
    } else {
      dispatch(listAttendees(searchTitle, 1, pageSize, sortField, sortOrder));
    }
  }, [
    dispatch,
    toast,
    userInfo,
    navigate,
    searchTitle,
    page,
    pageSize,
    successCreate,
    successUpdate,
    createdAttendee,
    successUpdate,
    successStatusUpdate,
    sortField,
    sortOrder,
  ]);

  useEffect(() => {
    if (successDelete) {
      toastComponent.showToast(t("toasts.attendeeDelete"), "success", 3000);
      dispatch({ type: ATTENDEE_DELETE_RESET }); // Reset after successful delete
      if (attendees.length === 1 && page > 1) {
        setPage((prevPage) => Math.max(1, prevPage - 1));
      } else {
        dispatch(listAttendees(searchTitle, page, pageSize, searchCity.value));
      }
    }
    // No dependencies on attendees array here to avoid re-fetching due to array changes
  }, [
    dispatch,
    successDelete,
    page,
    pageSize,
    searchTitle,
    searchCity.value,
    toastComponent,
    t,
  ]);

  useEffect(() => {
    if (errorCreate) {
      toastComponent.showToast(errorCreate, "error", 3000);
      console.log("errorCreate", errorCreate);
    }
  }, [errorCreate]);

  const gotoPage = (page) => {
    setPage(page);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const openSidePanelModal = () => {
    setSidePanelOpen(true);
    document.body.classList.add("no-scroll");
  };

  const openEditSidePanelModal = (value) => {
    setEditSidePanelOpen(true);
    setAttendeeId(value);
    document.body.classList.add("no-scroll");
  };

  const closeSidePanelModal = () => {
    setSidePanelOpen(false);
    setEditSidePanelOpen(false);
    setAttendeeId("");
    document.body.classList.remove("no-scroll");
  };
  const closeEditSidePanelModal = () => {
    setEditSidePanelOpen(false);
    setSidePanelOpen(false);
    setAttendeeId("");
    document.body.classList.remove("no-scroll");
  };

  const handleRowClick = (value) => {
    navigate(`/academy/attendees/${value}`);
  };

  const FilterIcon = () => <img src={icons.filter} alt="filter" />;

  return (
    <>
      <Helmet>
        <title>Admin Attendees</title>
        <meta
          name="description"
          content="Megagen SMT Tool administration attendees list"
        />
      </Helmet>
      <SidePanel
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("attendee.createAttendee")}
        noBorder={false}
      >
        <CreateAttendee close={closeSidePanelModal} />
      </SidePanel>

      <SidePanel
        open={editSidePanelOpen}
        close={closeEditSidePanelModal}
        title={t("attendee.editAttendee")}
        noBorder={false}
      >
        <EditAttendee attendeeId={attendeeId} close={closeEditSidePanelModal} />
      </SidePanel>

      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Box px={8}>
              <Table.Top
                title={t("attendee.attendees")}
                onChange={onChangeSearchTitle}
                placeholder={t("attendee.search")}
                searchName={searchTitle}
                buttonLabel={t("attendee.buttonLabel")}
                buttonHandler={openSidePanelModal}
                isFixedTitle
              >
                <Button
                  leftIcon={<FilterIcon />}
                  bg={white}
                  color={textSecondary}
                  border={`1px solid ${isFilterVisible ? primary : newBorder}`}
                  variant="solid"
                  size="md"
                  borderRadius="10rem"
                  mr={6}
                  fontSize="sm"
                  onClick={() => setIsFilterVisible(!isFilterVisible)}
                >
                  {t("common.filter")}
                </Button>
              </Table.Top>
            </Box>

            <Table.Container
              columns={columns(
                dispatch,
                openEditSidePanelModal,
                translate,
                deleteAttendeeHandler,
                updateAttendeeStatus,
                page,
                pageSize,
                handleSortChange
              )}
              data={attendees}
              loading={loading}
              error={error}
              navigate={navigate}
              navigateTo="/academy/attendees"
              noItemsButtonHandler={openSidePanelModal}
              noItemsTitle={t("noItems.noAttendees")}
              noItemsDescription={t("noItems.noAttendeesText")}
              noItemsButtonLabel={t("attendee.createAttendee")}
              onRowClick={handleRowClick}
              sortField={sortField}
              sortOrder={sortOrder}
            />
            <Table.Pagination
              page={page}
              pages={pages}
              nextPage={nextPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
              handlePageSizeChange={handlePageSizeChange}
              pageSize={pageSize}
              pageSizes={pageSizes}
              navigate={navigate}
            />
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default Attendees;
