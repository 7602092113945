import React, { useState, useEffect } from "react";
import { Flex, Box, Grid, GridItem } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  backgroundLight,
  newBorder,
  textPrimary,
  primary,
} from "../../../../utils/colors";
import BoxUser from "./BoxUser";
import useCountUp from "../../../../hooks/useCountUp";

const PatientStats2 = ({ loading, countsByStatus, globalTotalCount }) => {
  // GENERAL
  const { t } = useTranslation();

  //CONSTANTS

  const [globalTotal, setGlobalTotal] = useState(0);
  const [totalPatients, setTotalPatients] = useState(0);
  const [newPatients, setNewPatients] = useState(0);
  const [activePatients, setActivePatients] = useState(0);
  const [inactivePatients, setInactivePatients] = useState(0);

  const [consultingPatients, setConsultingPatients] = useState(0);
  const [planningPatients, setPlanningPatients] = useState(0);
  const [cancelledPatients, setCancelledPatients] = useState(0);
  const [completedPatients, setCompletedPatients] = useState(0);
  const [ongoingPatients, setOngoingPatients] = useState(0);
  const [approvedPatients, setApprovedPatients] = useState(0);

  // USE EFFECT

  useEffect(() => {
    if (countsByStatus) {
      setGlobalTotal(globalTotalCount || 0);
      setTotalPatients(countsByStatus.total || 0);
      setNewPatients(countsByStatus.new || 0);
      setActivePatients(countsByStatus.active || 0);
      setInactivePatients(countsByStatus.inactive || 0);

      setConsultingPatients(countsByStatus.consulting || 0);
      setPlanningPatients(countsByStatus.planningTreatment || 0);
      setCancelledPatients(countsByStatus.cancelledTreatment || 0);
      setCompletedPatients(countsByStatus.completedTreatment || 0);
      setOngoingPatients(countsByStatus.ongoingTreatment || 0);
      setApprovedPatients(countsByStatus.approvedTreatment || 0);
    }
  }, [countsByStatus, globalTotalCount]);

  const animatedTotalPatients = useCountUp(totalPatients);
  const animatedNewPatients = useCountUp(newPatients);
  const animatedActivePatients = useCountUp(activePatients);
  const animatedInactivePatients = useCountUp(inactivePatients);

  const animatedConsultingPatients = useCountUp(consultingPatients);
  const animatedPlanningPatients = useCountUp(planningPatients);
  const animatedCancelledPatients = useCountUp(cancelledPatients);
  const animatedCompletedPatients = useCountUp(completedPatients);
  const animatedOngoingPatients = useCountUp(ongoingPatients);
  const animatedApprovedPatients = useCountUp(approvedPatients);

  // LOGS
  // console.log("countsByStatus", countsByStatus);
  // console.log("globalTotalCount", globalTotalCount);
  // console.log("consultingPatients", consultingPatients);

  return (
    <Box w="100%" px="2rem" mb={6}>
      <Flex
        w="100%"
        direction="column"
        p="0rem 0rem"
        border={`1px solid ${newBorder}`}
        borderRadius="1rem"
      >
        <Grid
          w="100%"
          pt={4}
          pb={4}
          templateColumns={[
            "repeat(2, 1fr)",
            "repeat(4, 1fr)",
            "repeat(4, 1fr)",
          ]}
        >
          <GridItem
            colSpan={1}
            borderRight={[
              `1px solid ${newBorder}`,
              `1px solid ${newBorder}`,
              `1px solid ${newBorder}`,
            ]}
            borderBottom={[
              `1px solid ${newBorder}`,
              `0px solid ${newBorder}`,
              `0px solid ${newBorder}`,
            ]}
            p="0 0 0.5rem 0"
          >
            <BoxUser
              loading={loading}
              label={t("dashboard.patientsTotal")}
              data={animatedTotalPatients}
              of={globalTotal > 0 && globalTotal}
              color={primary}
            />
          </GridItem>
          <GridItem
            colSpan={1}
            borderRight={[
              `0px solid ${newBorder}`,
              `1px solid ${newBorder}`,
              `1px solid ${newBorder}`,
            ]}
            borderBottom={[
              `1px solid ${newBorder}`,
              `0px solid ${newBorder}`,
              `0px solid ${newBorder}`,
            ]}
            p="0 0 0.5rem 0"
          >
            <BoxUser
              loading={loading}
              label={t("dashboard.patientsConsultingPlanning")}
              data={animatedConsultingPatients + animatedPlanningPatients}
              color="#A0D1FF"
            />
          </GridItem>

          <GridItem
            colSpan={1}
            borderRight={[
              `1px solid ${newBorder}`,
              `1px solid ${newBorder}`,
              `1px solid ${newBorder}`,
            ]}
            p="0.5rem 0 0 0"
          >
            <BoxUser
              loading={loading}
              label={t("dashboard.patientsOngoingApproved")}
              data={animatedOngoingPatients + animatedApprovedPatients}
              color="#65C790"
            />
          </GridItem>

          <GridItem colSpan={1} p="0.5rem 0 0 0">
            <BoxUser
              loading={loading}
              label={t("dashboard.patientsInactive")}
              data={animatedInactivePatients}
              color="#D6D6D7"
            />
          </GridItem>
        </Grid>
      </Flex>
    </Box>
  );
};

export default PatientStats2;
