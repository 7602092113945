import React from "react";
import TabLink from "./TabLink";
import { Flex } from "@chakra-ui/react";

const TabContainer = ({ tabs }) => {
  return (
    <Flex
      p="1rem 0 0 2rem"
      direction="column"
      w="100%"
      h="100%"
      overflowY="auto"
      className="customScrollbar"
    >
      {tabs.map((tab) => (
        <TabLink key={tab.id} {...tab} />
      ))}
    </Flex>
  );
};

export default TabContainer;
