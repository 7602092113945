import React, { useState, useEffect } from "react";
import { Flex, Heading, Text, Button } from "@chakra-ui/react"; // Add these imports
import { useTable, useSortBy } from "react-table";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Box,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import Lottie from "lottie-react"; // Make sure to import Lottie if you're using it
import {
  bgLight,
  backgroundLight,
  textLightBlue,
  primary,
  white,
  textPrimary,
  newBorder,
  textSecondary,
} from "../../../utils/colors"; // Assuming colors
import noItemsFound from "../../../assets/lottie/no-items-found.json"; // Assuming you have a lottie file
import noServices from "../../../assets/images/empty-box.png";
import { useTranslation } from "react-i18next";

const TableContainer = ({
  navigate,
  loading,
  error,
  isSinglePage,
  columns,
  data,
  className,
  navigateTo,
  noItemsTitle,
  noItemsDescription,
  noItemsButtonHandler,
  noItemsButtonLabel,
  noItemsButtonDisabled,
  onRowClick,
  toast,
  dispatch,
  deleteHandler,
  isNarrow,
  sortField,
  sortOrder,
  isHeaderWhite,
  noItemsAlt,
  p,
}) => {
  const { t } = useTranslation();

  const [sortState, setSortState] = useState({ sortField, sortOrder });
  const [initialLoading, setInitialLoading] = useState(true);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        // initialState: {
        //   sortBy: [{ id: sortField, desc: sortOrder !== "asc" }], // Assuming "asc" means ascending order and anything else is descending
        // },
      },
      useSortBy
    );

  // Update to generate sorting indicators manually based on sortState
  const generateSortingIndicator = (columnId) => {
    return sortState.sortField === columnId ? (
      sortState.sortOrder === "desc" ? (
        <TriangleDownIcon aria-label="sorted descending" />
      ) : (
        <TriangleUpIcon aria-label="sorted ascending" />
      )
    ) : null;
  };

  // Example function to handle sort change (if you want to implement sorting changes via UI)
  const handleSortChange = (columnId) => {
    const isAsc =
      sortState.sortField === columnId && sortState.sortOrder === "asc";
    const newSortOrder = isAsc ? "desc" : "asc";
    setSortState({ sortField: columnId, sortOrder: newSortOrder });
    // Here you would trigger the data fetch based on new sort criteria
  };

  useEffect(() => {
    // If `loading` is `false`, it means the data has been loaded (either successfully or with an error)
    if (!loading) {
      setInitialLoading(false);
    }
  }, [loading]);

  if (initialLoading) {
    // If the component is in the initial loading state, you can return a loader or null
    return (
      <Flex justify="center" align="center" w="100%" p={20}>
        <Spinner size="xl" color={primary} />
      </Flex>
    );
  }

  const noItemsAlternative = () => {
    return (
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        margin="0 auto 2rem"
        p="0 0 0rem"
        w="100%"
        borderRadius="0.75rem"

        // border={isSinglePage ? `1px dashed ${newBorder}` : "none"}
      >
        <Image h="initial" w="3rem" m="1rem 0 0" src={noServices} />
        <Text mt={2} color={textSecondary} fontSize="sm">
          {t("common.noData")}
        </Text>
      </Flex>
    );
  };

  const noItemsDefault = () => {
    return (
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        margin="0 auto 4rem"
        p="0 0 4rem"
        w="100%"
        borderRadius="0.75rem"
        border={isSinglePage ? `1px dashed ${newBorder}` : "none"}
      >
        {!isSinglePage && (
          <Box
            w="100%"
            h="3rem"
            bg={backgroundLight}
            borderBottom={`1px solid ${newBorder}`}
          ></Box>
        )}
        {/* <Image src={images.noData} alt="no Data" /> */}
        <Flex
          maxWidth="600px"
          margin="0 auto"
          overflow="hidden"
          h={isSinglePage ? "160px" : "260px"}
          justifyContent="center"
          alignItems="baseline"
        >
          <Lottie
            style={{
              width: "100%",
              height: "100%",
            }}
            animationData={noItemsFound}
            loop={true}
          />
        </Flex>

        <Heading
          fontSize="1.375rem"
          fontWeight="600"
          color={textPrimary}
          margin="1.5rem 0 0.5rem"
        >
          {noItemsTitle}
        </Heading>
        <Text color={textSecondary}>{noItemsDescription}</Text>

        {noItemsButtonHandler && (
          <Button
            size="lg"
            fontSize="md"
            borderRadius="10rem"
            bg={primary}
            color={white}
            m="2rem 0 0"
            onClick={noItemsButtonHandler}
            isDisabled={noItemsButtonDisabled}
          >
            {noItemsButtonLabel}
          </Button>
        )}
      </Flex>
    );
  };

  // You can add a new condition here to handle the case when the data length is zero
  if (!data || (data.length === 0 && !loading)) {
    return <>{noItemsAlt ? noItemsAlternative() : noItemsDefault()}</>;
  }

  const handleRowClick = (e, rowId) => {
    // Exclude clicks on input elements like switches or buttons
    if (!["INPUT", "BUTTON"].includes(e.target.tagName)) {
      onRowClick(rowId);
    }
  };

  return (
    <>
      {loading ? (
        <Flex justify="center" align="center" w="100%" p={20}>
          <Spinner size="xl" color={primary} />
        </Flex>
      ) : error ? (
        <Flex justify="center" align="center" w="100%" p={20}>
          <Text variant="danger">{error}</Text>
        </Flex>
      ) : (
        <Flex w="100%" overflowX={["scroll", "scroll", "scroll"]}>
          <Table {...getTableProps()} className={className} w="100%">
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th
                      bg={isHeaderWhite ? white : bgLight}
                      color={textLightBlue}
                      h={isHeaderWhite ? "2.5rem" : "3rem"}
                      fontSize="0.688rem"
                      fontWeight="600"
                      borderBottom={
                        isHeaderWhite ? `1px solid ${newBorder}` : "none"
                      }
                      p="0 1rem"
                      // p={isHeaderWhite ? 2 : 4}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      onClick={() => handleSortChange(column.id)} // Optional: if you want to allow sorting changes via UI
                    >
                      <Flex align="center" h="100%">
                        {column.render("Header")}
                        {generateSortingIndicator(column.id)}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            <Tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    onClick={(e) => handleRowClick(e, row.original._id)}
                    _hover={{ bg: backgroundLight, cursor: "pointer" }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <Td p={p ? p : "4"} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Flex>
      )}
    </>
  );
};

export default TableContainer;
