import {
  STATISTIC_PATIENT_STATS_REQUEST,
  STATISTIC_PATIENT_STATS_SUCCESS,
  STATISTIC_PATIENT_STATS_FAIL,
  STATISTIC_PATIENT_STATS_RESET,
  STATISTIC_TREATMENT_PLAN_STATS_REQUEST,
  STATISTIC_TREATMENT_PLAN_STATS_SUCCESS,
  STATISTIC_TREATMENT_PLAN_STATS_FAIL,
  STATISTIC_TREATMENT_PLAN_STATS_RESET,
  STATISTIC_TOP_SERVICES_REQUEST,
  STATISTIC_TOP_SERVICES_SUCCESS,
  STATISTIC_TOP_SERVICES_FAIL,
  STATISTIC_TOP_SERVICES_RESET,
  STATISTIC_TOP_SERVICE_GROUPS_REQUEST,
  STATISTIC_TOP_SERVICE_GROUPS_SUCCESS,
  STATISTIC_TOP_SERVICE_GROUPS_FAIL,
  STATISTIC_TOP_SERVICE_GROUPS_RESET,
  STATISTIC_TOP_EMPLOYEES_REQUEST,
  STATISTIC_TOP_EMPLOYEES_SUCCESS,
  STATISTIC_TOP_EMPLOYEES_FAIL,
  STATISTIC_TOP_EMPLOYEES_RESET,
  STATISTIC_COORDINATORS_REQUEST,
  STATISTIC_COORDINATORS_SUCCESS,
  STATISTIC_COORDINATORS_FAIL,
  STATISTIC_COORDINATORS_RESET,
  STATISTIC_DOCTOR_TOP_SERVICE_REQUEST,
  STATISTIC_DOCTOR_TOP_SERVICE_SUCCESS,
  STATISTIC_DOCTOR_TOP_SERVICE_FAIL,
  STATISTIC_DOCTOR_TOP_SERVICE_RESET,
  STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_REQUEST,
  STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_SUCCESS,
  STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_FAIL,
  STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_RESET,
  STATISTIC_COORDINATOR_USER_REQUEST,
  STATISTIC_COORDINATOR_USER_SUCCESS,
  STATISTIC_COORDINATOR_USER_FAIL,
  STATISTIC_COORDINATOR_USER_RESET,
  STATISTIC_EMPLOYEE_DOCTOR_STATS_REQUEST,
  STATISTIC_EMPLOYEE_DOCTOR_STATS_SUCCESS,
  STATISTIC_EMPLOYEE_DOCTOR_STATS_FAIL,
  STATISTIC_EMPLOYEE_DOCTOR_STATS_RESET,
} from "../constants/statisticContstants";

export const statisticPatientReducer = (
  state = { statisticPatients: {} },
  action
) => {
  switch (action.type) {
    case STATISTIC_PATIENT_STATS_REQUEST:
      return { loading: true };
    case STATISTIC_PATIENT_STATS_SUCCESS:
      return { loading: false, statisticPatients: action.payload };
    case STATISTIC_PATIENT_STATS_FAIL:
      return { loading: false, error: action.payload };
    case STATISTIC_PATIENT_STATS_RESET:
      return { statisticPatients: {} };
    default:
      return state;
  }
};

export const statisticTreatmentPlanReducer = (
  state = { statisticTreatmentPlans: {} },
  action
) => {
  switch (action.type) {
    case STATISTIC_TREATMENT_PLAN_STATS_REQUEST:
      return { loading: true };
    case STATISTIC_TREATMENT_PLAN_STATS_SUCCESS:
      return { loading: false, statisticTreatmentPlans: action.payload };
    case STATISTIC_TREATMENT_PLAN_STATS_FAIL:
      return { loading: false, error: action.payload };
    case STATISTIC_TREATMENT_PLAN_STATS_RESET:
      return { statisticTreatmentPlans: {} };
    default:
      return state;
  }
};

export const statisticTopServiceReducer = (
  state = { statisticTopServices: {} },
  action
) => {
  switch (action.type) {
    case STATISTIC_TOP_SERVICES_REQUEST:
      return { loading: true };
    case STATISTIC_TOP_SERVICES_SUCCESS:
      return { loading: false, statisticTopServices: action.payload };
    case STATISTIC_TOP_SERVICES_FAIL:
      return { loading: false, error: action.payload };
    case STATISTIC_TOP_SERVICES_RESET:
      return { statisticTopServices: {} };
    default:
      return state;
  }
};

export const statisticTopServiceGroupReducer = (
  state = { statisticTopServiceGroups: {} },
  action
) => {
  switch (action.type) {
    case STATISTIC_TOP_SERVICE_GROUPS_REQUEST:
      return { loading: true };
    case STATISTIC_TOP_SERVICE_GROUPS_SUCCESS:
      return { loading: false, statisticTopServiceGroups: action.payload };
    case STATISTIC_TOP_SERVICE_GROUPS_FAIL:
      return { loading: false, error: action.payload };
    case STATISTIC_TOP_SERVICE_GROUPS_RESET:
      return { statisticTopServiceGroups: {} };
    default:
      return state;
  }
};

export const statisticTopEmployeeReducer = (
  state = { statisticTopEmployees: {} },
  action
) => {
  switch (action.type) {
    case STATISTIC_TOP_EMPLOYEES_REQUEST:
      return { loading: true };
    case STATISTIC_TOP_EMPLOYEES_SUCCESS:
      return { loading: false, statisticTopEmployees: action.payload };
    case STATISTIC_TOP_EMPLOYEES_FAIL:
      return { loading: false, error: action.payload };
    case STATISTIC_TOP_EMPLOYEES_RESET:
      return { statisticTopEmployees: {} };
    default:
      return state;
  }
};

export const statisticCoordinatorReducer = (
  state = { statisticCoordinators: {} },
  action
) => {
  switch (action.type) {
    case STATISTIC_COORDINATORS_REQUEST:
      return { loading: true };
    case STATISTIC_COORDINATORS_SUCCESS:
      return { loading: false, statisticCoordinators: action.payload };
    case STATISTIC_COORDINATORS_FAIL:
      return { loading: false, error: action.payload };
    case STATISTIC_COORDINATORS_RESET:
      return { statisticCoordinators: {} };
    default:
      return state;
  }
};

export const statisticTopServiceDoctorReducer = (
  state = { statisticTopServicesDoctor: {} },
  action
) => {
  switch (action.type) {
    case STATISTIC_DOCTOR_TOP_SERVICE_REQUEST:
      return { loading: true };
    case STATISTIC_DOCTOR_TOP_SERVICE_SUCCESS:
      return { loading: false, statisticTopServicesDoctor: action.payload };
    case STATISTIC_DOCTOR_TOP_SERVICE_FAIL:
      return { loading: false, error: action.payload };
    case STATISTIC_DOCTOR_TOP_SERVICE_RESET:
      return { statisticTopServicesDoctor: {} };
    default:
      return state;
  }
};

export const statisticTreatmentPlanEmployeeReducer = (
  state = { statisticTreatmentPlansEmployee: {} },
  action
) => {
  switch (action.type) {
    case STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_REQUEST:
      return { loading: true };
    case STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_SUCCESS:
      return {
        loading: false,
        statisticTreatmentPlansEmployee: action.payload,
      };
    case STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_FAIL:
      return { loading: false, error: action.payload };
    case STATISTIC_TREATMENT_PLAN_EMPLOYEE_STATS_RESET:
      return { statisticTreatmentPlansEmployee: {} };
    default:
      return state;
  }
};

export const statisticCoordinatorUserReducer = (
  state = { statisticCoordinatorsUser: {} },
  action
) => {
  switch (action.type) {
    case STATISTIC_COORDINATOR_USER_REQUEST:
      return { loading: true };
    case STATISTIC_COORDINATOR_USER_SUCCESS:
      return {
        loading: false,
        statisticCoordinatorsUser: action.payload,
      };
    case STATISTIC_COORDINATOR_USER_FAIL:
      return { loading: false, error: action.payload };
    case STATISTIC_COORDINATOR_USER_RESET:
      return { statisticCoordinatorsUser: {} };
    default:
      return state;
  }
};

// Initial state of the reducer
const initialState = {
  loading: false,
  employeeDoctorStats: {},
  error: null,
};

// Reducer function
export const statisticEmployeeDoctorReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case STATISTIC_EMPLOYEE_DOCTOR_STATS_REQUEST:
      // Handle the initial request state
      return { ...state, loading: true };
    case STATISTIC_EMPLOYEE_DOCTOR_STATS_SUCCESS:
      // Handle the success state
      return {
        loading: false,
        employeeDoctorStats: action.payload,
        error: null,
      };
    case STATISTIC_EMPLOYEE_DOCTOR_STATS_FAIL:
      // Handle the failure state
      return { loading: false, error: action.payload };
    case STATISTIC_EMPLOYEE_DOCTOR_STATS_RESET:
      // Handle the reset state to clear the data
      return { ...initialState };
    default:
      // Return the current state if no action matches
      return state;
  }
};
