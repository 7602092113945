export const EMPLOYEE_LIST_REQUEST = "EMPLOYEE_LIST_REQUEST";
export const EMPLOYEE_LIST_SUCCESS = "EMPLOYEE_LIST_SUCCESS";
export const EMPLOYEE_LIST_FAIL = "EMPLOYEE_LIST_FAIL";

export const EMPLOYEE_DETAILS_REQUEST = "EMPLOYEE_DETAILS_REQUEST";
export const EMPLOYEE_DETAILS_SUCCESS = "EMPLOYEE_DETAILS_SUCCESS";
export const EMPLOYEE_DETAILS_FAIL = "EMPLOYEE_DETAILS_FAIL";

export const EMPLOYEE_CREATE_REQUEST = "EMPLOYEE_CREATE_REQUEST";
export const EMPLOYEE_CREATE_SUCCESS = "EMPLOYEE_CREATE_SUCCESS";
export const EMPLOYEE_CREATE_FAIL = "EMPLOYEE_CREATE_FAIL";
export const EMPLOYEE_CREATE_RESET = "EMPLOYEE_CREATE_RESET";

export const EMPLOYEE_UPDATE_REQUEST = "EMPLOYEE_UPDATE_REQUEST";
export const EMPLOYEE_UPDATE_SUCCESS = "EMPLOYEE_UPDATE_SUCCESS";
export const EMPLOYEE_UPDATE_FAIL = "EMPLOYEE_UPDATE_FAIL";
export const EMPLOYEE_UPDATE_RESET = "EMPLOYEE_UPDATE_RESET";

export const EMPLOYEE_DELETE_REQUEST = "EMPLOYEE_DELETE_REQUEST";
export const EMPLOYEE_DELETE_SUCCESS = "EMPLOYEE_DELETE_SUCCESS";
export const EMPLOYEE_DELETE_FAIL = "EMPLOYEE_DELETE_FAIL";
export const EMPLOYEE_DELETE_RESET = "EMPLOYEE_DELETE_RESET";
