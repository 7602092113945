import React, { useState } from "react";
import {
  Flex,
  Heading,
  Text,
  Button,
  Stack,
  IconButton,
  Avatar,
  Spinner,
} from "@chakra-ui/react"; // Add these imports
import { useDispatch } from "react-redux";
import { cancelAllTreatments } from "../../../../../actions/userActions";

import { FaArrowRightFromBracket, FaXmark } from "react-icons/fa6";
import {
  white,
  backgroundLight,
  textPrimary,
  textSecondary,
  newBorder,
  buttonBlue,
  buttonBlueText,
  secondary,
  primary,
} from "../../../../../utils/colors";
import StatusButton from "../../../../../components/buttons/StatusButton";
import MoreButton from "../../../../../components/buttons/MoreButton";
import { useTranslation } from "react-i18next";
import SidePanel from "../../../../../components/sidepanel/SidePanel";
import EditPatient from "../../sidepanels/EditPatient";
import { motion } from "framer-motion";
import useIsMobile from "../../../../../hooks/useIsMobile";

const PatientHeader = ({ user, close, isDoctor }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cancelAllTreatmentsHandler = () => {
    dispatch(cancelAllTreatments(user._id));
  };

  const [sidePanelModalOpen, setSidePanelModalOpen] = useState(false);

  const openSidePanelModalHandler = () => {
    setSidePanelModalOpen(true);
  };

  const closeSidePanelModal = () => {
    setSidePanelModalOpen(false);
  };

  // Animation variants
  const headerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  const isMobile = useIsMobile();

  return (
    <>
      {/* SIDEPANELS  */}
      <SidePanel
        isTop
        open={sidePanelModalOpen}
        close={closeSidePanelModal}
        title={t("patient.createTreatmentPlan")}
        noBorder={true}
      >
        <EditPatient userId={user._id} close={closeSidePanelModal} />
      </SidePanel>

      <Flex
        align="center"
        justify="space-between"
        p={["1.75rem 1.5rem", "1.75rem 2rem", "1.75rem 2rem"]}
        borderBottom={`1px solid ${newBorder}`}
        position="fixed"
        width="100%"
        bg={white}
        zIndex="1000"
      >
        <Flex align="center">
          {!isMobile && (
            <IconButton
              bg="none"
              border={`1px solid ${newBorder}`}
              color={textSecondary}
              onClick={close}
              icon={<FaArrowRightFromBracket />}
              borderRadius="0.75rem"
              fontSize="0.75rem"
            />
          )}

          <Flex align="center" m={["0 0 0 0rem", "0 0 0 1rem", "0 0 0 1rem"]}>
            <Avatar
              h="3rem"
              w="3rem"
              src={user.image}
              name={`${user.lastName} + ${user.firstName}`}
            />

            <Stack gap={2}>
              <Heading
                ml={2}
                color={textPrimary}
                fontSize="1.275rem"
                fontWeight="600"
                lineHeight="1"
              >
                {user.lastName} {user.firstName}
              </Heading>
              <Flex align="center">
                <Text ml={2} color={textSecondary} fontSize="sm" lineHeight="1">
                  {t("patient.headerCardNumber")}
                  <Text as="span" fontWeight="600" ml={1} lineHeight="1">
                    {user &&
                      user.userID &&
                      user.userID.length > 0 &&
                      user.userID}
                  </Text>
                </Text>

                {!isMobile && (
                  <Text
                    ml={2}
                    color={textSecondary}
                    fontSize="sm"
                    lineHeight="1"
                  >
                    {t("patient.headerLastAppointment")}
                    <Text as="span" fontWeight="600" ml={1}>
                      -
                    </Text>
                  </Text>
                )}
              </Flex>
            </Stack>
          </Flex>
        </Flex>

        <Flex align="center">
          {/* <StatusButton
              isUnclickable
              currentStatus={user.status}
              options="user"
            /> */}

          <MoreButton
            id={user._id}
            editHandler={(e) => {
              e.stopPropagation();
              openSidePanelModalHandler();
            }}
            editButtonDisabled={isDoctor}
            // cancelHandler={cancelAllTreatmentsHandler}
          />

          {isMobile && (
            <IconButton
              bg="none"
              border={`1px solid ${newBorder}`}
              color={textSecondary}
              onClick={close}
              icon={<FaXmark />}
              borderRadius="0.75rem"
              fontSize="0.75rem"
              ml={2}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default PatientHeader;
