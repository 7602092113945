import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createUser } from "../../../../actions/userActions";
import { backgroundLight } from "../../../../utils/colors";
import {
  Button,
  FormControl,
  FormLabel,
  Flex,
  Input,
  TabPanels,
  TabPanel,
  Select,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
} from "../../../../utils/colors";
// import MultiCourseSelect from "../../../../components/select/MultiCourseSelect";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import { useTranslation } from "react-i18next";
import ImageUpload from "../../../../components/input/ImageUpload";

const CreateUser = () => {
  //GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // TABS
  const [activeTab, setActiveTab] = useState(0);
  const tabsData = [{ label: t("user.tabDetails") }];

  // CONSTANTS
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState(""); // [
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("admin");

  // HANDLERS
  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(createUser(firstName, lastName, image, email, password, role));
  };

  // LOGS
  console.log("firstName", firstName);
  console.log("lastName", lastName);
  console.log("image", image);
  console.log("email", email);
  console.log("password", password);
  console.log("role", role);

  return (
    <form onSubmit={submitHandler}>
      <TabsComponent
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabsData}
        isTitleValid={true}
        isProfessionValid={true}
        disabledTabs={[]}
      >
        <TabPanels m="1.5rem 0 0">
          <TabPanel p={0}>
            <FormControl mb={4}>
              <FormLabel fontSize="sm">{t("form.image")}</FormLabel>
              <ImageUpload image={image} setImage={setImage} />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel fontSize="sm">{t("form.firstName")}</FormLabel>
              <Input
                type="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                size="lg"
                fontSize="0.938rem"
                fontWeight="500"
                border={`1px solid ${newBorder}`}
                borderRadius="0.75rem"
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel fontSize="sm">{t("form.lastName")}</FormLabel>
              <Input
                type="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                size="lg"
                fontSize="0.938rem"
                fontWeight="500"
                border={`1px solid ${newBorder}`}
                borderRadius="0.75rem"
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel fontSize="sm">{t("form.role")}</FormLabel>
              <Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                size="lg"
                fontSize="0.938rem"
                fontWeight="500"
                border={`1px solid ${newBorder}`}
                borderRadius="0.75rem"
              >
                <option value="" disabled>
                  Select a Role
                </option>
                <option value="admin">Admin</option>
                <option value="employee">Employee</option>
                <option value="patient">Patient</option>
              </Select>
            </FormControl>

            <FormControl mb={4}>
              <FormLabel fontSize="sm">{t("form.email")}</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="lg"
                fontSize="0.938rem"
                fontWeight="500"
                border={`1px solid ${newBorder}`}
                borderRadius="0.75rem"
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel fontSize="sm">{t("form.password")}</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                size="lg"
                fontSize="0.938rem"
                fontWeight="500"
                border={`1px solid ${newBorder}`}
                borderRadius="0.75rem"
              />
            </FormControl>

            {/* <HStack spacing={4} mb={0}>
              <InputField
                label={t("form.birthDate")}
                value={birthDate}
                name="birthDate"
                placeholder={t("placeholder.birthDate")}
                onChange={(e) => setBirthDate(e.target.value)}
                isDate
              />

              <InputField
                label={t("form.phone")}
                value={phone}
                name="phone"
                placeholder={t("placeholder.phone")}
                onChange={(e) => setPhone(e.target.value)}
              />
            </HStack> */}
          </TabPanel>

          <TabPanel p={0}>test</TabPanel>
        </TabPanels>
        <Flex
          mt={4}
          justify="flex-end"
          direction="column"
          borderTop={`1px solid ${newBorder}`}
          p="2rem 0"
        >
          <Flex justify="flex-end">
            <Button
              minWidth="10rem"
              type="submit"
              size="lg"
              bg={primary}
              color={white}
              fontSize="md"
              borderRadius="10rem"
            >
              {t("user.createUser")}
            </Button>

            {activeTab < 1 && (
              <Button
                minWidth="10rem"
                ml={4}
                bg={backgroundLight}
                size="lg"
                color={textSecondary}
                fontSize="md"
                borderRadius="10rem"
                onClick={() => setActiveTab(activeTab + 1)}
              >
                {t("common.continue")}
              </Button>
            )}
          </Flex>
        </Flex>
      </TabsComponent>
    </form>
  );
};

export default CreateUser;
