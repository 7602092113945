import axios from "axios";
import {
  SERVICEGROUP_LIST_REQUEST,
  SERVICEGROUP_LIST_SUCCESS,
  SERVICEGROUP_LIST_FAIL,
  SERVICEGROUP_DETAILS_REQUEST,
  SERVICEGROUP_DETAILS_SUCCESS,
  SERVICEGROUP_DETAILS_FAIL,
  SERVICEGROUP_CREATE_REQUEST,
  SERVICEGROUP_CREATE_SUCCESS,
  SERVICEGROUP_CREATE_FAIL,
  SERVICEGROUP_UPDATE_REQUEST,
  SERVICEGROUP_UPDATE_SUCCESS,
  SERVICEGROUP_UPDATE_FAIL,
  SERVICEGROUP_DELETE_REQUEST,
  SERVICEGROUP_DELETE_SUCCESS,
  SERVICEGROUP_DELETE_FAIL,
  SERVICEGROUP_UPDATE_STATUS_REQUEST,
  SERVICEGROUP_UPDATE_STATUS_SUCCESS,
  SERVICEGROUP_UPDATE_STATUS_FAIL,
  SERVICEGROUP_UPDATE_ISCONSULTING_REQUEST,
  SERVICEGROUP_UPDATE_ISCONSULTING_SUCCESS,
  SERVICEGROUP_UPDATE_ISCONSULTING_FAIL,
} from "../constants/serviceGroupConstants";

import { logout } from "./userActions";

export const listServiceGroups =
  (
    keyword = "",
    pageNumber = "",
    pageSize = "",
    sortField = "",
    sortOrder = "",
    fetchAll = false,
    status = "",
    isConsulting = ""
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICEGROUP_LIST_REQUEST });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      console.log("Received Params in Action:", {
        keyword,
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
        fetchAll,
        status,
        isConsulting,
      });

      // Constructing the query string
      let query = `/api/service-groups?keyword=${encodeURIComponent(keyword)}`;
      query += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      if (sortField) query += `&sortField=${encodeURIComponent(sortField)}`;
      if (sortOrder) query += `&sortOrder=${encodeURIComponent(sortOrder)}`;

      if (status) query += `&status=${status}`;
      if (isConsulting) query += `&isConsulting=${isConsulting}`;

      if (fetchAll) query += `&fetchAll=${fetchAll}`;

      console.log("Query:", query);

      const { data } = await axios.get(query, config);

      dispatch({
        type: SERVICEGROUP_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICEGROUP_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listServiceGroupDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SERVICEGROUP_DETAILS_REQUEST });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/service-groups/${id}`, config);

    dispatch({
      type: SERVICEGROUP_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERVICEGROUP_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createServiceGroup =
  (name, isConsulting) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SERVICEGROUP_CREATE_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/service-groups`,
        { name, isConsulting },
        config
      );

      dispatch({
        type: SERVICEGROUP_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICEGROUP_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response && error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

export const updateServiceGroup =
  (serviceGroup) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SERVICEGROUP_UPDATE_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/service-groups/${serviceGroup._id}`,
        serviceGroup,
        config
      );

      dispatch({
        type: SERVICEGROUP_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: SERVICEGROUP_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SERVICEGROUP_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response.status === 401) {
        dispatch(logout());
      }

      dispatch({
        type: SERVICEGROUP_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteServiceGroup = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERVICEGROUP_DELETE_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/service-groups/${id}`, config);

    dispatch({
      type: SERVICEGROUP_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SERVICEGROUP_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateServiceGroupStatus =
  (serviceGroupId, newStatus) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SERVICEGROUP_UPDATE_STATUS_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/service-groups/${serviceGroupId}/status`,
        { status: newStatus }, // Send the new status in the request body
        config
      );

      dispatch({
        type: SERVICEGROUP_UPDATE_STATUS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICEGROUP_UPDATE_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateServiceGroupIsConsulting =
  (serviceGroupId, newIsConsulting) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SERVICEGROUP_UPDATE_ISCONSULTING_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/service-groups/${serviceGroupId}/isConsulting`,
        { isConsulting: newIsConsulting }, // Send the new status in the request body
        config
      );

      dispatch({
        type: SERVICEGROUP_UPDATE_ISCONSULTING_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICEGROUP_UPDATE_ISCONSULTING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
