import React, { useState, useEffect } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Button,
  Input,
  Flex,
  Text,
  Stack,
  Select,
  Heading,
  useDisclosure,
  IconButton,
  PopoverFooter,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  Icon,
} from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  newBorder,
  primary,
  secondary,
  white,
  buttonBlue,
  backgroundLight,
} from "../../utils/colors";
import { FaXmark, FaCaretDown } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { shade } from "polished";

const PricePopover = ({ price, defaultPrice, onChange, isDisabled }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // GENERAL
  const { t } = useTranslation();

  // CONSTANTS
  const [isCaretUp, setIsCaretUp] = useState(false);

  // 1. Initialize local state for input value and a flag for confirming the changes
  const [tempPrice, setTempPrice] = useState(price); // temporary price in the input
  const [isChanged, setIsChanged] = useState(false); // flag to check if price has been changed
  const [discount, setDiscount] = useState(0); // Discount in percentage
  const [selectedDiscount, setSelectedDiscount] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(0); // Discounted price

  const handlePriceChange = (e) => {
    let newPrice = e.target.value;
    setTempPrice(newPrice);
    setIsChanged(true);

    const totalPrice = newPrice * (1 - discount / 100);

    setDiscountedPrice(totalPrice.toFixed(2));
  };

  const handleConfirmClick = () => {
    // Decide which price to use: discountedPrice if a discount is applied, or tempPrice otherwise.
    const finalPrice = discount > 0 ? discountedPrice : tempPrice;

    onChange(finalPrice); // Call the onChange prop with the finalPrice
    setIsChanged(false);
    onClose(); // Close the popover after confirming the changes
  };

  const handleOpen = () => {
    onOpen();
    setIsCaretUp(true); // Rotate the caret up
  };

  const handleClose = () => {
    onClose();
    setIsCaretUp(false); // Rotate the caret down
  };

  const handleDiscountChange = (e) => {
    let newDiscount = e.target.value;
    setDiscount(newDiscount);
    const discountedPrice = tempPrice * (1 - newDiscount / 100);
    setDiscountedPrice(discountedPrice.toFixed(2));
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      //   w="initial"
      //   maxWidth="600px"
    >
      {/* Pass isOpen and onClose here */}
      <PopoverTrigger>
        <Button
          border={`0px solid ${newBorder}`}
          borderRadius="10rem"
          h="2rem"
          fontWeight="500"
          color={textSecondary}
          onClick={handleOpen}
          isDisabled={isDisabled}
          zIndex="0"
          bg={isOpen ? backgroundLight : white}
          alignItems="center"
          justifyContent="space-between"
          px={4}
          transition="0.2s all"
          size="sm"
          role="group"
          // minWidth="136px"
          textAlign="left"
          _hover={{
            bg: backgroundLight,
            px: 4,
            transition: "0.2s all",
          }}
        >
          {price} €
          <Icon
            as={FaCaretDown}
            h="0.75rem"
            w={isOpen ? "0.75rem" : "0rem"}
            ml={1}
            opacity="0.5"
            transition="all 0.2s ease"
            color={textSecondary}
            transform={isOpen ? "rotate(180deg)" : "rotate(0)"} // Rotate caret when menu is open
            _groupHover={{
              opacity: 1,
              width: "0.75rem",
              transition: "all 0.2s ease",
            }}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        border="none"
        boxShadow="0px 4px 12px 3px rgba(0, 0, 0, 0.20)"
        borderRadius="1rem"
        bg={white}
      >
        <PopoverArrow />
        {/* <PopoverCloseButton /> */}
        <PopoverHeader borderBottom={`1px solid ${newBorder}`} p="1rem 1.5rem">
          <Flex w="100%" justify="space-between" align="center">
            <Heading fontSize="sm" fontWeight="500" color={textPrimary}>
              {t("common.setPrice")}
            </Heading>
            <IconButton
              h="1.5rem"
              w="1rem"
              icon={<FaXmark />}
              bg="none"
              onClick={handleClose}
              color={textSecondary}
            />
          </Flex>
        </PopoverHeader>
        <PopoverBody p="1rem 1.5rem 0rem">
          <Flex align="center" mb={2}>
            <Text
              color={textSecondary}
              fontWeight="400"
              width="200px"
              fontSize="sm"
            >
              {t("common.newPrice")} (€):
            </Text>
            <Input
              size="md"
              fontSize="sm"
              borderRadius="10rem"
              maxWidth="100px"
              textAlign="right"
              color={textPrimary}
              type="number"
              value={tempPrice} // Display the temporary price if it's changed, otherwise show the service price
              onChange={handlePriceChange}
              placeholder=""
            />
          </Flex>

          <Flex align="center" mb={4}>
            <Text
              color={textSecondary}
              fontWeight="400"
              width="200px"
              fontSize="sm"
            >
              {t("common.discount")} (%):
            </Text>
            <Input
              size="md"
              fontSize="sm"
              borderRadius="10rem"
              maxWidth="100px"
              textAlign="right"
              color={textPrimary}
              type="number"
              value={discount}
              onChange={handleDiscountChange}
            />
          </Flex>

          <Flex
            direction="column"
            w="100%"
            borderTop={`1px solid ${newBorder}`}
            p="0.75rem 0"
          >
            <Flex
              align="center"
              mb={0}
              p="0rem 0"
              w="100%"
              justify="space-between"
            >
              <Text
                color={textSecondary}
                fontWeight="400"
                width="200px"
                fontSize="sm"
              >
                {t("common.registerPrice")}:
              </Text>
              <Text color={textPrimary} fontWeight="500" fontSize="sm">
                {defaultPrice} €
              </Text>
            </Flex>
          </Flex>

          <Flex
            direction="column"
            w="100%"
            borderTop={`1px solid ${newBorder}`}
          >
            <Flex
              align="flex-start"
              justify="space-between"
              mb={0}
              p="0.75rem 0"
              direction="column"
              w="100%"
            >
              <Flex
                align="center"
                mb={0}
                p="0rem 0"
                w="100%"
                justify="space-between"
              >
                <Text
                  color={textSecondary}
                  fontWeight="400"
                  width="200px"
                  fontSize="sm"
                >
                  {t("common.totalPriceDiscount")}:
                </Text>
                <Text color={textPrimary} fontWeight="500" fontSize="sm">
                  {discount > 0 ? `${discountedPrice} €` : `${tempPrice} €`}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </PopoverBody>
        <PopoverFooter p="1rem 1.5rem">
          <Flex w="100%" align="center" justify="flex-end">
            <Button
              variant="outline"
              size="sm"
              color={textSecondary}
              borderRadius="10rem"
              onClick={handleClose}
              p="1rem 1.5rem"
              _hover={{ bg: backgroundLight }}
            >
              {t("button.cancel")}
            </Button>
            <Button
              size="sm"
              bg={primary}
              color={white}
              borderRadius="10rem"
              onClick={handleConfirmClick} // 4. Call the handleConfirmClick function when the "Confirm" button is clicked
              p="1rem 1.5rem"
              ml={2}
              _hover={{ bg: shade(0.3, primary) }}
            >
              {t("button.apply")}
            </Button>
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default PricePopover;
