import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  useToast,
  HStack,
  Input,
  TabPanels,
  TabPanel,
  Text,
  Icon,
  Tooltip,
  Heading,
} from "@chakra-ui/react";
import {
  newBorder,
  textSecondary,
  white,
  primary,
  textPrimary,
  backgroundLight,
} from "../../../../utils/colors";
import {
  updateAttendee,
  listAttendeeDetails,
  addAttendeeCourse,
  removeAttendeeCourse,
} from "../../../../actions/attendeeActions";
import { useTranslation } from "react-i18next";
import ToastComponent from "../../../../components/partials/ToastComponent";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlDatePicker from "../../../../components/input/FormControlDatePicker";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlAddress from "../../../../components/input/FormControlAddress";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import FormControlProfession from "../../../../components/input/FormControlProfession";
import FormControlClient from "../../../../components/input/FormControlClient";
import { shade } from "polished";
import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";

const EditAttendee = ({ attendeeId, close }) => {
  const dispatch = useDispatch();
  const toastComponent = ToastComponent();
  const toast = useToast();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  const tabsData = [
    { label: t("attendee.tabDetails") },
    { label: t("attendee.tabCourses") },
    // { label: t('attendee.tabClinics') },
  ];

  const [courseId, setCourseId] = useState("");

  const [activeTab, setActiveTab] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [profession, setProfession] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [client, setClient] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [street, setStreet] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [courses, setCourses] = useState([]);

  const attendeeDetails = useSelector((state) => state.attendeeDetails);
  const { attendee } = attendeeDetails;

  // const attendeeAddCourse = useSelector((state) => state.attendeeAddCourse);
  // const { success: successAddCourse } = attendeeAddCourse;

  // const attendeeRemoveCourse = useSelector(
  //   (state) => state.attendeeRemoveCourse
  // );
  // const { success: successRemoveCourse } = attendeeRemoveCourse;

  const [showAddCourse, setShowAddCourse] = useState(false);
  const [newlyAddedCourses] = useState([]);
  const [customCourses] = useState([]);
  const [updatedCourses, setUpdatedCourses] = useState([]);

  const [selectedProfession, setSelectedProfession] = useState(null);

  // TOUCHED
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [professionTouched, setProfessionTouched] = useState(false);

  // EMAIL VALIDATION
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // ERRORS
  const isFirstNameError = firstName === "";
  const isLastNameError = lastName === "";
  const isEmailError = email === "";
  const isProfessionError = profession === "";

  // VALIDATION
  const isFirstNameValid = firstName.length >= 1;
  const isLastNameValid = lastName.length >= 1;
  const isEmailValid = isValidEmail(email);
  // const isProfessionValid = profession.length >= 1;

  // console.log("isProfessionValid", isProfessionValid);
  console.log("selectedProfession", selectedProfession);

  // Determine if the Continue button should be disabled
  const isContinueDisabled =
    !isFirstNameValid || !isLastNameValid || !isEmailValid;

  const handleShowAddCourse = () => setShowAddCourse(!showAddCourse);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateAttendee({
        _id: attendeeId,
        firstName: firstName,
        lastName: lastName,
        image: image,
        email: email,
        birthDate: birthDate,
        client: client,
        phone: phone,
        city: city,
        street: street,
        zip: zip,
        country: country,
        state: state,
        courses: updatedCourses,
        profession: profession,
      })
    );
  };

  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  const handleProfessionChange = (selectedOption) => {
    setSelectedProfession(selectedOption.value);
    setProfession(selectedOption.value);
  };

  const removeCourseHandler = (courseId) => {
    console.log("courseId", courseId);
    dispatch(removeAttendeeCourse(attendeeId, courseId));

    console.log("courses before", courses);
    setCourses(courses.filter((course) => course.course._id !== courseId));
    console.log("courses after", courses);

    console.log("updatedCourses before", updatedCourses);
    setUpdatedCourses(updatedCourses.filter((course) => course !== courseId));
    console.log("updatedCourses after", updatedCourses);
    toastComponent.showToast(t("toasts.courseRemove"), "success", 3000);
  };

  const addCourseHandleNew = (values) => {
    console.log("values", values);
    // Get the array of speaker IDs from the values array
    const courseIds = values.map((course) => course.value);

    console.log("courseIds", courseIds);

    // Check if any of the course IDs already exist in the attendees list
    const courseExists = courseIds.some((courseId) =>
      courses.some((a) => a._id === courseId)
    );

    if (courseExists) {
      console.log("Course already assigned");
      toastComponent.showToast(t("toasts.courseError"), "error", 3000);
    } else {
      console.log("attendeeId", attendeeId);
      dispatch(addAttendeeCourse(attendeeId, courseIds));

      const currentCourses = [...courses];

      console.log("currentCourses", currentCourses);

      setUpdatedCourses([...courseIds]);

      console.log("updatedCourses", updatedCourses);
      toastComponent.showToast(t("toasts.courseEdit"), "success", 3000);
      // You might need to update the course list after the action is dispatched
    }
  };

  useEffect(() => {
    console.log("Edit Attendee loaded", attendee); // Add this line

    if (!attendee.firstName || attendee._id !== attendeeId) {
      dispatch(listAttendeeDetails(attendeeId));
    } else {
      setFirstName(attendee.firstName);
      setLastName(attendee.lastName);
      setEmail(attendee.email);
      setBirthDate(attendee.birthDate);
      setClient(attendee.client && attendee.client._id);
      setPhone(attendee.phone);
      setImage(attendee.image);
      setProfession(attendee.profession);

      if (attendee.address) {
        setCity(attendee.address[0]?.city || "");
        setStreet(attendee.address[0]?.street || "");
        setZip(attendee.address[0]?.zip || "");
        setCountry(attendee.address[0]?.country || "");
        setState(attendee.address[0]?.state || "");
      }

      setCourses(attendee.courses);
      setUpdatedCourses(attendee.courses.map((course) => course.course._id));
    }
  }, [dispatch, attendeeId, attendee]);

  // useEffect(() => {
  //   if (successAddCourse) {
  //     dispatch(listAttendeeDetails(attendeeId));
  //     setCourseId("");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [successAddCourse, attendeeId, dispatch]);

  // useEffect(() => {
  //   if (successRemoveCourse) {
  //     dispatch(listAttendeeDetails(attendeeId));
  //   }
  // }, [successRemoveCourse, attendeeId, dispatch]);

  // const formattedCourses = courses;
  const formattedCourses = courses.map((course) => ({
    value: course.course._id, // Assuming _id can be used as a unique identifier
    label: course.course.title, // Display the attendee's name as the label
    image: course.course.image, // You might want to add the image here if needed
    status: course.course.status,
    startDate: course.course.startDate,
    endDate: course.course.endDate,
  }));

  const isTitleValid = firstName.length >= 3;

  // console.log('attendee', attendee);
  // console.log('selected profession', selectedProfession);
  console.log("profession", profession);
  console.log("client", client);
  // console.log('isProfessionValid', isProfessionValid);
  // console.log('isTitleValid', isTitleValid);

  // console.log('courses', courses);
  // console.log('custom courses', customCourses);

  return (
    <>
      <Box position="relative">
        <form
          onSubmit={submitHandler}
          style={{
            height: "100%",
          }}
        >
          <SidePanelHeader close={close} title={t("attendee.editAttendee")} />

          <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="7rem 0 4rem"
            >
              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("attendee.details")}
              </Heading>

              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1rem 1.5rem"
              >
                {/* <FormControl mb={6}>
          <FormLabel fontSize="sm">{t("course.courseImage")}</FormLabel>
          <ImageUpload image={image} setImage={setImage} />
        </FormControl> */}

                <HStack gap={4} w="100%">
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.firstName")}
                    value={firstName}
                    placeholder={t("placeholder.firstName")}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setFirstNameTouched(true);
                    }}
                    onBlur={() => setFirstNameTouched(true)}
                    isTouched={firstNameTouched}
                    isError={isFirstNameError}
                    w="50%"
                    errorMessage={t("formError.firstNameRequired")}
                  />

                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.lastName")}
                    value={lastName}
                    placeholder={t("placeholder.lastName")}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setLastNameTouched(true);
                    }}
                    onBlur={() => setLastNameTouched(true)}
                    isTouched={lastNameTouched}
                    isError={isLastNameError}
                    w="50%"
                    errorMessage={t("formError.lastNameRequired")}
                  />
                </HStack>

                <FormControlDefault
                  type="email"
                  label={t("form.email")}
                  isRequired
                  value={email}
                  placeholder={t("placeholder.email")}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailTouched(true);
                  }}
                  onBlur={() => setEmailTouched(true)}
                  isTouched={emailTouched}
                  isError={!isEmailValid}
                  w="100%"
                  errorMessage={t("formError.emailInvalid")}
                />
                <FormControlProfession
                  label={t("form.profession")}
                  value={profession._id}
                  placeholder={t("select.profession")}
                  onChange={handleProfessionChange}
                  setIsTouched={() => setProfessionTouched(true)}
                  isRequired={true}
                  isTouched={professionTouched}
                  isError={isProfessionError}
                  errorMessage={t("formError.professionRequired")}
                  w="100%"
                  onProfessionChange={handleProfessionChange}
                />

                <HStack gap={4} w="100%">
                  <FormControlDatePicker
                    value={birthDate}
                    type="date"
                    isRequired={false}
                    label={t("form.birthDate")}
                    placeholder={t("placeholder.birthDate")}
                    onChange={(newValue) => {
                      setBirthDate(newValue);
                    }}
                    onOpen={() => {}}
                    w="50%"
                    errorMessage={t("formError.birthDateRequired")}
                  />

                  <FormControlPhone
                    label={t("form.phone")}
                    value={phone}
                    onChange={(newPhone) => {
                      setPhone(newPhone);
                    }}
                    errorMessage={t("formError.phoneRequired")}
                    w="50%"
                    defaultCountry="lt"
                  />
                </HStack>

                <HStack gap={4} w="100%">
                  <FormControlAddress
                    currentValue={street}
                    label={t("form.address")}
                    onAddressSelect={handleAddressSelect}
                    isRequired={false}
                    errorMessage={t("formError.addressRequired")}
                    w="100%"
                    googleApiKey="AIzaSyCNQfHnntkLgj5Es5V8V0H2BfIetOOZ9yw"
                  />

                  <FormControlDefault
                    type="text"
                    isRequired={false}
                    label={t("form.zip")}
                    value={zip}
                    placeholder={t("placeholder.zip")}
                    onChange={(e) => {
                      setZip(e.target.value);
                    }}
                    w="40%"
                    errorMessage={t("formError.zipRequired")}
                  />
                </HStack>

                <HStack gap={4} w="100%">
                  <FormControlDefault
                    type="text"
                    isRequired={false}
                    label={t("form.city")}
                    value={city}
                    placeholder={t("placeholder.city")}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                    w="50%"
                    errorMessage={t("formError.cityRequired")}
                  />

                  <FormControlCountry
                    label={t("form.country")}
                    value={country}
                    onChange={(selectedOption) => {
                      setCountry(selectedOption ? selectedOption.value : "");
                    }}
                    isRequired={false}
                    w="50%"
                    errorMessage={t("formError.countryRequired")}
                  />
                </HStack>

                {/* <FormControlClient
            value={client}
            label={t("attendee.client")}
            placeholder={t("select.client")}
            onChange={(selectedOption) => setClient(selectedOption.value)}
            isRequired={false}
            w="100%"
          /> */}
              </Flex>
            </Flex>

            <Flex
              mt={4}
              direction="column"
              borderTop={`1px solid ${newBorder}`}
              p="2rem 0 3rem"
            >
              <Flex justify="flex-end">
                <Tooltip
                  bg={white}
                  borderRadius="1rem"
                  p="1rem"
                  label={
                    <Flex
                      direction="column"
                      p="0rem"
                      justify="center"
                      textAlign="center"
                    >
                      {isFirstNameError && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.firstNameRequired")}
                        </Text>
                      )}
                      {isLastNameError && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.lastNameRequired")}
                        </Text>
                      )}
                      {!isEmailValid && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.emailInvalid")}
                        </Text>
                      )}
                      {isProfessionError && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.professionRequired")}
                        </Text>
                      )}
                    </Flex>
                  }
                  isDisabled={!isContinueDisabled}
                  hasArrow
                  placement="top"
                  fontSize="md"
                >
                  <Button
                    minWidth="10rem"
                    type="submit"
                    size="lg"
                    bg={primary}
                    color={white}
                    fontSize="md"
                    borderRadius="10rem"
                    isDisabled={isContinueDisabled}
                    _hover={{
                      bg: shade(0.1, primary),
                    }}
                    ml={4}
                  >
                    {t("attendee.editAttendee")}
                  </Button>
                </Tooltip>

                <Flex align="center">
                  {activeTab > 0 && (
                    <Button
                      minWidth="8rem"
                      mr={0}
                      bg={backgroundLight}
                      border={`1px solid ${newBorder}`}
                      size="lg"
                      color={textSecondary}
                      fontSize="md"
                      fontWeight="500"
                      borderRadius="10rem"
                      onClick={() => setActiveTab(activeTab - 1)}
                      isDisabled={isContinueDisabled}
                      _hover={{
                        bg: shade(0.1, backgroundLight),
                      }}
                    >
                      {t("common.back")}
                    </Button>
                  )}

                  {activeTab < 0 && (
                    <Tooltip
                      bg={white}
                      borderRadius="1rem"
                      p="1rem"
                      label={
                        <Flex
                          direction="column"
                          p="0rem"
                          justify="center"
                          textAlign="center"
                        >
                          {isFirstNameError && (
                            <Text
                              fontSize="13px"
                              color="#e53e3e"
                              whiteSpace="nowrap"
                            >
                              * {t("formError.firstNameRequired")}
                            </Text>
                          )}
                          {isLastNameError && (
                            <Text
                              fontSize="13px"
                              color="#e53e3e"
                              whiteSpace="nowrap"
                            >
                              * {t("formError.lastNameRequired")}
                            </Text>
                          )}
                          {!isEmailValid && (
                            <Text
                              fontSize="13px"
                              color="#e53e3e"
                              whiteSpace="nowrap"
                            >
                              * {t("formError.emailInvalid")}
                            </Text>
                          )}
                          {isProfessionError && (
                            <Text
                              fontSize="13px"
                              color="#e53e3e"
                              whiteSpace="nowrap"
                            >
                              * {t("formError.professionRequired")}
                            </Text>
                          )}
                        </Flex>
                      }
                      isDisabled={!isContinueDisabled}
                      hasArrow
                      placement="top"
                      fontSize="md"
                    >
                      <Button
                        minWidth="8rem"
                        ml={4}
                        bg={backgroundLight}
                        border={`1px solid ${newBorder}`}
                        size="lg"
                        color={textSecondary}
                        fontSize="md"
                        fontWeight="500"
                        borderRadius="10rem"
                        onClick={() => setActiveTab(activeTab + 1)}
                        isDisabled={isContinueDisabled}
                        _hover={{
                          bg: shade(0.1, backgroundLight),
                        }}
                      >
                        {t("common.continue")}
                      </Button>
                    </Tooltip>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default EditAttendee;
