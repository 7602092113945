import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Text, Image, Flex, Button } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import {
  textPrimary,
  textSecondary,
  white,
  backgroundLight,
  primary,
  secondary,
  newBorder,
} from "../../utils/colors";
import logoDark from "../../assets/icons/logoDark.svg";

const Home = () => {
  const navigate = useNavigate();

  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  console.log("userInfo in Home", userInfo);

  useEffect(() => {
    if (userInfo) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Flex
        bg={secondary}
        h="100vh"
        w="100vw"
        p="0rem"
        justify="center"
        align="center"
        direction="column"
      >
        <Image src={logoDark} h="3rem" mb={6} />

        <Button
          mt={6}
          bg="none"
          border={`1px solid ${primary}`}
          onClick={() => navigate("/login")}
          color={primary}
          borderRadius="10rem"
          p="0 2rem"
          _hover={{
            bg: primary,
            color: white,
          }}
        >
          Login
        </Button>
      </Flex>
    </>
  );
};

export default Home;
