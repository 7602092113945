import { Button, Box, Flex, Stack, Text, Icon } from "@chakra-ui/react";
import {
  white,
  textPrimary,
  textSecondary,
  buttonRed,
  buttonRedText,
} from "../../utils/colors";
import { Fade, Slide } from "@chakra-ui/transition";
import { FaXmark, FaTrash } from "react-icons/fa6";
import { TbTrash } from "react-icons/tb";
import { useDroppable } from "@dnd-kit/core";

const RemoveAreaModal = ({ isOpen }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: "trash-can",
  });

  // Define a hover style when an item is dragged over the drop area
  const hoverStyle = {
    backgroundColor: isOver ? buttonRed : white, // Change color when hovered
    color: isOver ? buttonRedText : textSecondary,
    transition: "background-color 0.3s ease", // Smooth transition for background color
  };

  return (
    <Slide
      direction="bottom"
      in={isOpen}
      style={{
        zIndex: 9000,
        width: "calc(80% - 280px)",
        left: "initial",
        right: "calc(10% + 2rem)",
        paddingRight: "0rem",
        bottom: "2rem",
        boxShadow: `1px 1px 10px rgba(0, 0, 0, 0.1)`,
        borderRadius: "1rem",
        overflow: "hidden",
        p: "0",
      }}
    >
      <Fade in={isOpen}>
        <Box position="relative" bottom="0" right="0" zIndex="overlay">
          <Flex
            bg={white}
            p={0}
            align="center"
            justify="space-between"
            borderRadius="md"
            w="100%"
            h="80px"
            maxW="100%" // Adjust the width as needed, ensuring it doesn't go full width
            m="0 auto"
            ref={setNodeRef}
            style={hoverStyle}
          >
            <Flex
              w="100%"
              h="100%"
              position="relative"
              m="0 auto"
              display="flex"
              justifyContent="center"
              alignItems="center"
              zIndex="100"
              pointerEvents="none"
            >
              <Icon
                as={FaTrash}
                h="3"
                color={isOver ? buttonRedText : textSecondary}
                pointerEvents="none"
              />
              <Text
                fontSize="sm"
                color={isOver ? buttonRedText : textSecondary}
                ml={2}
                pointerEvents="none"
              >
                Drop here to delete
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Fade>
    </Slide>
  );
};

export default RemoveAreaModal;
