import axios from "axios";
import {
  TENANT_REGISTER_REQUEST,
  TENANT_REGISTER_SUCCESS,
  TENANT_REGISTER_FAIL,
  TENANT_VERIFY_REQUEST,
  TENANT_VERIFY_SUCCESS,
  TENANT_VERIFY_FAIL,
  TENANT_ALREADY_VERIFIED,
  TENANT_ALREADY_EXISTS,
  TENANT_DETAILS_REQUEST,
  TENANT_DETAILS_SUCCESS,
  TENANT_DETAILS_FAIL,
  TENANT_UPDATE_REQUEST,
  TENANT_UPDATE_SUCCESS,
  TENANT_UPDATE_FAIL,
  TENANT_UPDATE_ADMIN_USER_REQUEST,
  TENANT_UPDATE_ADMIN_USER_SUCCESS,
  TENANT_UPDATE_ADMIN_USER_FAIL,
  TENANT_UPDATE_TYPE_REQUEST,
  TENANT_UPDATE_TYPE_SUCCESS,
  TENANT_UPDATE_TYPE_FAIL,
  TENANT_UPDATE_OPERATING_ROOMS_REQUEST,
  TENANT_UPDATE_OPERATING_ROOMS_SUCCESS,
  TENANT_UPDATE_OPERATING_ROOMS_FAIL,
  TENANT_UPDATE_IMAGE_REQUEST,
  TENANT_UPDATE_IMAGE_SUCCESS,
  TENANT_UPDATE_IMAGE_FAIL,
} from "../constants/tenantConstants";
import { AUTH_LOGIN_SUCCESS } from "../constants/authConstants";

// Register Tenant
export const registerTenant =
  (clinicName, email, password) => async (dispatch) => {
    try {
      dispatch({ type: TENANT_REGISTER_REQUEST });

      // API call to register the tenant
      const { data } = await axios.post("/api/tenants/register", {
        clinicName,
        email,
        password,
      });

      dispatch({
        type: TENANT_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      if (
        error.response &&
        error.response.data.message === "Tenant already exists"
      ) {
        dispatch({ type: TENANT_ALREADY_EXISTS });
      } else {
        dispatch({
          type: TENANT_REGISTER_FAIL,
          payload: error.response?.data.message || error.message,
        });
      }
    }
  };

// Verify Email
export const verifyEmail = (email, verificationCode) => async (dispatch) => {
  try {
    dispatch({ type: TENANT_VERIFY_REQUEST });

    const { data } = await axios.post("/api/tenants/verify", {
      email,
      verificationCode,
    });

    dispatch({
      type: TENANT_VERIFY_SUCCESS,
      payload: data,
    });

    // // If verification is successful and a token is received, automatically log in
    // if (data.token) {
    //   dispatch({
    //     type: AUTH_LOGIN_SUCCESS,
    //     payload: data,
    //   });

    //   localStorage.setItem("userInfo", JSON.stringify(data));
    // }
  } catch (error) {
    if (
      error.response &&
      error.response.data.message === "Email is already verified"
    ) {
      dispatch({ type: TENANT_ALREADY_VERIFIED });
    } else {
      dispatch({
        type: TENANT_VERIFY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
};

export const listTenantDetails = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TENANT_DETAILS_REQUEST });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const tenantID = userInfo.tenantID;

    const { data } = await axios.get(`/api/tenants/${tenantID}`, config);

    dispatch({
      type: TENANT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TENANT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateTenant = (tenantData) => async (dispatch, getState) => {
  try {
    dispatch({ type: TENANT_UPDATE_REQUEST });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const tenantID = userInfo.tenantID;

    const { data } = await axios.put(
      `/api/tenants/${tenantID}`,
      tenantData,
      config
    );

    dispatch({
      type: TENANT_UPDATE_SUCCESS,
      payload: data,
    });

    console.log("updateTenant data", data);
  } catch (error) {
    dispatch({
      type: TENANT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateTenantAdminUser =
  (adminUser) => async (dispatch, getState) => {
    try {
      dispatch({ type: TENANT_UPDATE_ADMIN_USER_REQUEST });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const tenantID = userInfo.tenantID;

      const { data } = await axios.put(
        `/api/tenants/${tenantID}/adminUser`,
        adminUser,
        config
      );

      dispatch({
        type: TENANT_UPDATE_ADMIN_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TENANT_UPDATE_ADMIN_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateTenantType = (type) => async (dispatch, getState) => {
  try {
    console.log("updateTenantType received:", type); // Add this log

    dispatch({ type: TENANT_UPDATE_TYPE_REQUEST });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const tenantID = userInfo.tenantID;

    const { data } = await axios.put(
      `/api/tenants/${tenantID}/type`,
      type,
      config
    );

    console.log("updateTenantType data", data);

    dispatch({
      type: TENANT_UPDATE_TYPE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TENANT_UPDATE_TYPE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateTenantOperatingRooms =
  (operatingRooms) => async (dispatch, getState) => {
    try {
      dispatch({ type: TENANT_UPDATE_OPERATING_ROOMS_REQUEST });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const tenantID = userInfo.tenantID;

      const { data } = await axios.put(
        `/api/tenants/${tenantID}/operatingRooms`,
        operatingRooms,
        config
      );

      dispatch({
        type: TENANT_UPDATE_OPERATING_ROOMS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TENANT_UPDATE_OPERATING_ROOMS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateTenantImage = (image) => async (dispatch, getState) => {
  try {
    dispatch({ type: TENANT_UPDATE_IMAGE_REQUEST });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const tenantID = userInfo.tenantID;

    const { data } = await axios.put(
      `/api/tenants/${tenantID}/logo`,
      image,
      config
    );

    dispatch({
      type: TENANT_UPDATE_IMAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TENANT_UPDATE_IMAGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
