import React, { useState, useEffect } from "react";
import {
  Flex,
  Heading,
  Text,
  Image,
  Button,
  Box,
  Tooltip,
  Icon,
  Link,
  Stack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import {
  textPrimary,
  textSecondary,
  backgroundLight,
  white,
  primary,
  secondary,
  newBorder,
  buttonBlue,
  buttonBlueText,
  buttonYellowText,
  buttonGreen,
  buttonGreenText,
} from "../../../../../utils/colors";
import AppointmentCard from "../../../../../components/cards/AppointmentCard";
import noServices from "../../../../../assets/images/empty-box.png";
import { useTranslation } from "react-i18next";
import { TbMailForward, TbLink, TbEyeCheck } from "react-icons/tb";
import { shade, tint } from "polished";
import dayjs from "dayjs";
import { sendTreatmentPlanListEmail } from "../../../../../actions/userActions";

const PatientTreatmentPlansShare = ({ user }) => {
  const { t } = useTranslation();

  // REDUX
  const dispatch = useDispatch();

  // LOGS
  // console.log("user:", user);

  // Extracting tenantId and patientId from the user prop
  const { tenantID, _id } = user;

  const [shareableLink, setShareableLink] = useState("");

  const port = 3000;

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setShareableLink(
        `http://localhost:${port}/public/tenant/${tenantID}/patient/${_id}/treatment-plans`
      );
    } else {
      setShareableLink(
        `http://dentacasa.com/public/tenant/${tenantID}/patient/${_id}/treatment-plans`
      );
    }
  }, [tenantID, _id]);

  const pinCode = user.pinCode;

  // today date with dayjs
  const today = dayjs().format("MMMM DD, HH:mm");

  const sentDate = user.treatmentPlanLinkSentAt;
  const sentBy = user.treatmentPlanLinkSentBy;

  const userHasTreatmentPlans = user.treatmentPlans.length > 0;
  const userHasAtLeastOneTreatmentPlanAvailablePublic =
    user.treatmentPlans.some((treatmentPlan) => treatmentPlan.availablePublic);

  console.log("userHasTreatmentPlans:", userHasTreatmentPlans);
  console.log(
    "userHasAtLeastOneTreatmentPlanAvailablePublic:",
    userHasAtLeastOneTreatmentPlanAvailablePublic
  );

  const handleSendTreatmentPlanListEmail = (e) => {
    e.preventDefault();
    console.log("id:", user._id);
    const id = user._id;

    console.log("id:", id);
    dispatch(sendTreatmentPlanListEmail(id));
  };

  return (
    <>
      <Flex direction="column" w="100%" mb={8}>
        <Heading
          fontSize="1.125rem"
          color={textPrimary}
          fontWeight="600"
          m={0}
          p="0 0 0.75rem"
        >
          {t("patient.treatmentPlansShare")}
        </Heading>
        <Flex
          w="100%"
          bg={white}
          direction="column"
          p="0.75rem 1.5rem"
          borderRadius="1rem"
          border={`1px solid ${newBorder}`}
        >
          <Flex align="center" justify="space-between" w="100%">
            <Flex bg={white} borderRadius="1rem" direction="column" gap={1}>
              <Tooltip
                bg={shade(0.6, secondary)}
                p="0.5rem 1rem"
                borderRadius="1rem"
                label={
                  <Stack gap={1} align="flex-start">
                    <Flex align="center" justify="center">
                      <Text
                        fontSize="xs"
                        color={tint(0.6, secondary)}
                        fontWeight="500"
                        textAlign="center"
                        mr={1}
                      >
                        {t("patient.sharableLink")}:
                      </Text>
                      <Link
                        href={shareableLink}
                        isExternal
                        color={primary}
                        fontWeight="500"
                        fontSize="13px"
                        isTruncated
                        maxW="128px"
                      >
                        {shareableLink}
                      </Link>
                    </Flex>

                    <Flex align="center" justify="center">
                      <Text
                        fontSize="xs"
                        color={tint(0.6, secondary)}
                        fontWeight="500"
                        textAlign="center"
                        mr={1}
                      >
                        {t("patient.currentPinCode")}:
                      </Text>
                      <Text
                        color={white}
                        fontWeight="500"
                        fontSize="13px"
                        isTruncated
                        maxW="128px"
                      >
                        {pinCode}
                      </Text>
                    </Flex>
                  </Stack>
                }
              >
                <Flex align="center">
                  <Flex
                    w="1.5rem"
                    h="1.5rem"
                    bg={backgroundLight}
                    borderRadius="0.5rem"
                    align="center"
                    justify="center"
                    mr={1.5}
                  >
                    <Icon
                      as={TbLink}
                      color={buttonBlueText}
                      fontSize="0.75rem"
                    />
                  </Flex>

                  <Link
                    href={shareableLink}
                    isExternal
                    color={primary}
                    fontWeight="500"
                    fontSize="13px"
                    isTruncated
                    maxW="128px"
                  >
                    {shareableLink}
                  </Link>
                </Flex>
              </Tooltip>

              <Tooltip
                bg={shade(0.6, secondary)}
                p="0.5rem 1rem"
                borderRadius="1rem"
                isDisabled={sentDate === null}
                label={
                  <Stack gap={1} align="flex-start">
                    <Flex align="center" justify="center">
                      <Text
                        fontSize="xs"
                        color={tint(0.6, secondary)}
                        fontWeight="500"
                        textAlign="center"
                        mr={1}
                      >
                        {t("patient.shareLinkLastSentAt")}:
                      </Text>
                      <Text
                        fontSize="xs"
                        color={white}
                        fontWeight="600"
                        textAlign="center"
                        textTransform="capitalize"
                        _hover={{
                          cursor: "default",
                        }}
                      >
                        {sentDate !== null
                          ? dayjs(sentDate).format("MMMM DD, HH:mm")
                          : "-"}
                      </Text>
                    </Flex>

                    <Flex align="center" justify="center">
                      <Text
                        fontSize="xs"
                        color={tint(0.6, secondary)}
                        fontWeight="500"
                        textAlign="center"
                        mr={1}
                      >
                        {t("patient.shareLinkSentBy")}:
                      </Text>
                      <Text
                        fontSize="xs"
                        color={white}
                        fontWeight="600"
                        textAlign="center"
                        textTransform="capitalize"
                      >
                        {sentBy !== null ? sentBy : "-"}
                      </Text>
                    </Flex>
                  </Stack>
                }
              >
                <Flex align="center">
                  <Flex
                    w="1.5rem"
                    h="1.5rem"
                    bg={backgroundLight}
                    borderRadius="0.5rem"
                    align="center"
                    justify="center"
                    mr={1.5}
                  >
                    <Icon
                      as={TbMailForward}
                      color={buttonBlueText}
                      fontSize="0.75rem"
                    />
                  </Flex>
                  <Text
                    color={textSecondary}
                    fontWeight="500"
                    fontSize="13px"
                    textTransform="capitalize"
                  >
                    {sentDate !== null
                      ? dayjs(sentDate).format("MMMM DD, HH:mm")
                      : "-"}
                  </Text>
                </Flex>
              </Tooltip>
            </Flex>
            <Tooltip
              p="0.5rem 1rem"
              borderRadius="1rem"
              isDisabled={
                userHasTreatmentPlans &&
                userHasAtLeastOneTreatmentPlanAvailablePublic
              }
              label={
                <Flex direction="column">
                  {!userHasTreatmentPlans &&
                    !userHasAtLeastOneTreatmentPlanAvailablePublic && (
                      <Text fontSize="xs" color={white} fontWeight="500">
                        {t("patient.noTreatmentPlansAvailable")}
                      </Text>
                    )}
                  {userHasTreatmentPlans &&
                    !userHasAtLeastOneTreatmentPlanAvailablePublic && (
                      <Flex align="center">
                        <Text
                          fontSize="xs"
                          color={white}
                          fontWeight="500"
                          maxW="180px"
                        >
                          {t("patient.noTreatmentPlansAvailablePublic")}
                        </Text>
                        <Flex
                          h="1.5rem"
                          w="1.5rem"
                          bg={buttonGreen}
                          borderRadius="0.875rem"
                          align="center"
                          justify="center"
                          flexShrink={0}
                        >
                          <Icon
                            as={TbEyeCheck}
                            color={buttonGreenText}
                            fontSize="0.75rem"
                            pointerEvents="none"
                          />
                        </Flex>
                      </Flex>
                    )}
                </Flex>
              }
            >
              <Button
                bg={primary}
                color={white}
                size="md"
                borderRadius="10rem"
                fontSize="sm"
                _hover={{
                  bg: secondary,
                }}
                onClick={handleSendTreatmentPlanListEmail}
                isDisabled={
                  !userHasTreatmentPlans ||
                  !userHasAtLeastOneTreatmentPlanAvailablePublic
                }
              >
                {t("patient.sendShareLinkAgain")}
              </Button>
            </Tooltip>
          </Flex>
          {/* <AppointmentCard />
          <AppointmentCard />
          <AppointmentCard />
          <AppointmentCard /> */}
        </Flex>
      </Flex>
    </>
  );
};

export default PatientTreatmentPlansShare;
