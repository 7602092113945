import { useState, useEffect } from "react";

const useCountUp = (targetValue, duration = 300) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const end = isNaN(targetValue) ? 0 : parseInt(targetValue, 10);

    if (end === value) {
      return; // No need to update if the value is already equal to the target
    }

    let start = value; // Use the current value as the starting point
    let totalTicks = Math.round(duration / 60);
    let increment = (end - start) / totalTicks;

    let timer = setInterval(() => {
      start += increment;
      if ((increment >= 0 && start >= end) || (increment < 0 && start <= end)) {
        start = end; // Ensure the final value is exactly the target value
        clearInterval(timer);
      }
      setValue(start);
    }, 20);

    return () => clearInterval(timer);
  }, [targetValue, duration, value]);

  return Math.round(value);
};

export default useCountUp;
