export const PUBLIC_PATIENT_TREATMENT_PLAN_LIST_REQUEST =
  "PUBLIC_PATIENT_TREATMENT_PLAN_LIST_REQUEST";
export const PUBLIC_PATIENT_TREATMENT_PLAN_LIST_SUCCESS =
  "PUBLIC_PATIENT_TREATMENT_PLAN_LIST_SUCCESS";
export const PUBLIC_PATIENT_TREATMENT_PLAN_LIST_FAIL =
  "PUBLIC_PATIENT_TREATMENT_PLAN_LIST_FAIL";
export const PUBLIC_PATIENT_TREATMENT_PLAN_LIST_RESET =
  "PUBLIC_PATIENT_TREATMENT_PLAN_LIST_RESET";

export const PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_REQUEST =
  "PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_REQUEST";
export const PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_SUCCESS =
  "PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_SUCCESS";
export const PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_FAIL =
  "PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_FAIL";
export const PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_RESET =
  "PUBLIC_PATIENT_TREATMENT_PLAN_DETAILS_RESET";

export const PUBLIC_TENANT_DETAILS_REQUEST = "PUBLIC_TENANT_DETAILS_REQUEST";
export const PUBLIC_TENANT_DETAILS_SUCCESS = "PUBLIC_TENANT_DETAILS_SUCCESS";
export const PUBLIC_TENANT_DETAILS_FAIL = "PUBLIC_TENANT_DETAILS_FAIL";
export const PUBLIC_TENANT_DETAILS_RESET = "PUBLIC_TENANT_DETAILS_RESET";

export const PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_REQUEST =
  "PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_REQUEST";
export const PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_SUCCESS =
  "PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_SUCCESS";
export const PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_FAIL =
  "PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_FAIL";
export const PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_RESET =
  "PUBLIC_PATIENT_APPROVE_TREATMENT_PLAN_RESET";

export const PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_REQUEST =
  "PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_REQUEST";
export const PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_SUCCESS =
  "PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_SUCCESS";
export const PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_FAIL =
  "PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_FAIL";
export const PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_RESET =
  "PUBLIC_PATIENT_CANCEL_TREATMENT_PLAN_RESET";

export const PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_REQUEST =
  "PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_REQUEST";
export const PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_SUCCESS =
  "PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_SUCCESS";
export const PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_FAIL =
  "PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_FAIL";
export const PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_RESET =
  "PUBLIC_PATIENT_DOWNLOADPDF_TREATMENT_PLAN_RESET";
