import React, { useEffect } from "react";
import { Flex, Text, Spinner, Circle, Box, Tooltip } from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";
import { white, greenText, primary } from "../../../../utils/colors";
import { useTranslation } from "react-i18next";

const BoxListItem = ({ loading, label, data, bg, isPrice, color }) => {
  const { t } = useTranslation();
  const translate = (key) => t(key);

  return (
    <Flex
      w="100%"
      align="center"
      justify="space-between"
      bg={bg}
      p="1rem 1rem"
      borderRadius="1rem"
      mb={2}
      _last={{ mb: 0 }}
    >
      {!loading ? (
        <>
          <Flex align="center">
            <Circle bg={white} h="0.25rem" w="0.25rem" mr={2} />
            <Tooltip label={label} aria-label="Full service name">
              <Text
                fontSize="sm"
                color={white}
                fontWeight="500"
                isTruncated
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                maxWidth="150px"
              >
                {label}
              </Text>
            </Tooltip>
          </Flex>
          <Flex align="center" justify="flex-end">
            <Box
              bg={white}
              p="0.25rem 0.5rem"
              borderRadius="0.875rem"
              width="80px"
              textAlign="center"
            >
              <Text
                color={color}
                fontSize="sm"
                fontWeight="600"
                whiteSpace="nowrap"
              >
                {isPrice ? "€ " : ""}
                {data}
              </Text>
            </Box>
          </Flex>
        </>
      ) : (
        <Spinner size="md" color={white} />
      )}
    </Flex>
  );
};

export default BoxListItem;
