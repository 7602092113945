import React from "react";
import {
  Text,
  Avatar,
  Stack,
  HStack,
  Image,
  Circle,
  Box,
  Tooltip,
  Icon,
  Progress,
  Flex,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  textSecondary,
  textPrimary,
  buttonBlue,
  buttonBlueText,
  newBorder,
  buttonGreenText,
  buttonRedText,
  white,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";
import {
  FaRegNoteSticky,
  FaRegCircleCheck,
  FaRegCircle,
  FaRegCircleDot,
} from "react-icons/fa6";
import { format } from "morgan";
import { TbDiscount2 } from "react-icons/tb";

export const columns = (translate) => {
  return [
    {
      Header: "#",
      id: "index",
      Cell: ({ row }) => {
        const { order } = row.original;

        return (
          <Flex align="center" justify="center" minH="60px">
            <Text
              maxWidth="10px"
              overflow="hidden"
              whiteSpace="nowrap"
              fontSize="13px"
              fontWeight="500"
              color={textSecondary}
            >
              {order}
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: translate("service.serviceName"),
      accessor: "serviceName",
      Cell: ({ row }) => {
        const { service, teeth } = row.original;
        // console.log("row.original", row.original);

        return (
          <Stack gap={0} maxWidth="350px" overflow="hidden">
            <Text
              fontSize="15px"
              color={textPrimary}
              fontWeight="600"
              isTruncated // This will add ellipsis
              noOfLines={1} // This will limit the text to two lines
              whiteSpace="nowrap"
              overflow="hidden"
              w="100%"
              maxWidth="100%"
              minWidth="300px"
            >
              {(service && service.name && service.name) || "-"}
            </Text>
            {teeth && teeth.length > 0 && (
              <Text fontSize="xs" color={textSecondary} fontWeight="500">
                {teeth && teeth.length > 0 ? teeth.join(", ") : "-"}
              </Text>
            )}
          </Stack>
        );
      },
    },
    {
      Header: translate("service.appointmentDate"),
      accessor: "appointmentDate",
      Cell: ({ row }) => {
        const { appointment } = row.original;

        // console.log("row.original", row.original);

        return (
          <Stack gap={0} overflow="hidden">
            <Text
              fontSize="sm"
              color={textPrimary}
              fontWeight="500"
              isTruncated // This will add ellipsis
              noOfLines={1} // This will limit the text to two lines
              whiteSpace="nowrap"
              overflow="hidden"
              w="100%"
              maxWidth="100%"
              textTransform="capitalize"
            >
              {(appointment && dayjs(appointment).format("YYYY MMMM DD")) ||
                "-"}
            </Text>
            {appointment && (
              <Text fontSize="xs" color={textSecondary} fontWeight="500">
                {(appointment && dayjs(appointment).format("HH:ss")) || "-"}
              </Text>
            )}
          </Stack>
        );
      },
    },
    {
      Header: translate("service.quantity"),
      accessor: "quantity",
      Cell: ({ row }) => {
        const { quantity } = row.original;

        return (
          <Text
            fontSize="sm"
            color={textSecondary}
            fontWeight="500"
            isTruncated // This will add ellipsis
            noOfLines={1} // This will limit the text to two lines
            whiteSpace="nowrap"
            overflow="hidden"
            w="100%"
          >
            {quantity}
            <Text as="span" color={textSecondary} ml={1}>
              {translate("common.qty")}
            </Text>
          </Text>
        );
      },
    },
    {
      Header: translate("form.price"),
      accessor: "price",
      Cell: ({ row }) => {
        const { service, price } = row.original;
        const originalPrice = service && service.price;
        const discountAmount = originalPrice - price;
        const discountPercentage =
          discountAmount > 0 ? (discountAmount / originalPrice) * 100 : 0;

        return (
          <>
            {discountAmount > 0 ? (
              <Stack gap={0}>
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  isTruncated
                  noOfLines={1}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  w="100%"
                >
                  {originalPrice} €
                </Text>
                <Flex align="center">
                  <Icon
                    as={TbDiscount2}
                    color={buttonRedText}
                    fontSize="13px"
                    mr={1}
                  />
                  <Text
                    fontSize="13px"
                    color={buttonRedText}
                    fontWeight="500"
                    whiteSpace="noWrap"
                  >
                    -{discountAmount.toFixed(2)} € (
                    {discountPercentage.toFixed(0)}%)
                  </Text>
                </Flex>
              </Stack>
            ) : (
              <Text
                fontSize="sm"
                color={textSecondary}
                fontWeight="500"
                isTruncated
                noOfLines={1}
                whiteSpace="nowrap"
                overflow="hidden"
                w="100%"
              >
                {price} €
              </Text>
            )}
          </>
        );
      },
    },
    {
      Header: translate("common.total"),
      accessor: "total",
      Cell: ({ row }) => {
        const { price } = row.original;

        return (
          <>
            <Text
              fontSize="sm"
              color={textPrimary}
              fontWeight="500"
              isTruncated // This will add ellipsis
              noOfLines={1} // This will limit the text to two lines
              whiteSpace="nowrap"
              overflow="hidden"
              w="100%"
            >
              {price} €
            </Text>
          </>
        );
      },
    },
  ];
};
