import React, { useEffect } from "react";
import {
  Text,
  Avatar,
  Stack,
  HStack,
  Image,
  Circle,
  Box,
  Tooltip,
  Icon,
  Progress,
  Flex,
  Button,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  textSecondary,
  textPrimary,
  buttonBlue,
  buttonBlueText,
  newBorder,
  buttonGreen,
  buttonGreenText,
  buttonRed,
  buttonRedText,
  white,
  backgroundLight,
  darkBlue,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";
import {
  FaRegNoteSticky,
  FaRegCircleCheck,
  FaRegCircle,
  FaRegCircleDot,
} from "react-icons/fa6";
import { format } from "morgan";
import {
  TREATMENTPLAN_DELETE_RESET,
  TREATMENTPLAN_APPROVE_RESET,
  TREATMENTPLAN_CANCEL_RESET,
} from "../../../../constants/treatmentPlanConstants";
import {
  TbMailCancel,
  TbMailForward,
  TbMailOpened,
  TbEyeOff,
  TbEyeCheck,
  TbSignature,
  TbSignatureOff,
  TbFingerprintOff,
  TbFingerprint,
  TbUserCheck,
} from "react-icons/tb";
import { shade, tint } from "polished";
import ToastComponent from "../../../../components/partials/ToastComponent";

export const columns = (
  dispatch,
  translate,
  updateTreatmentPlanStatus,
  approveTreatmentPlan,
  cancelTreatmentPlan,
  deleteTreatmentPlan,
  sendTreatmentPlanEmail,
  isDoctor,
  isAdmin,
  updateTreatmentPlanAvailablePublic,
  successUpdateAvailablePublic
) => {
  const toastComponent = ToastComponent();

  const deleteTreatmentPlanHandler = (treatmentPlanId) => {
    dispatch(deleteTreatmentPlan(treatmentPlanId));
  };

  const updateTreatmentPlanStatusHandler = (treatmentPlanId, status) => {
    console.log("updateTreatmentPlanStatusHandler", treatmentPlanId, status);
    dispatch(updateTreatmentPlanStatus(treatmentPlanId, status));
  };

  const updateTreatmentPlanAvailablePublicHandler = (
    treatmentPlanId,
    availablePublic
  ) => {
    console.log(
      "updateTreatmentPlanAvailablePublicHandler",
      treatmentPlanId,
      availablePublic
    );
    dispatch(
      updateTreatmentPlanAvailablePublic(treatmentPlanId, availablePublic)
    );
  };

  const approveTreatmentPlanHandler = (treatmentPlanId) => {
    console.log("approveTreatmentPlanHandler", treatmentPlanId);
    dispatch(approveTreatmentPlan(treatmentPlanId));
    dispatch({ type: TREATMENTPLAN_APPROVE_RESET });
  };

  const cancelTreatmentPlanHandler = (treatmentPlanId, userId) => {
    dispatch(cancelTreatmentPlan(treatmentPlanId));
  };

  const sendTreatmentPlanEmailHandler = (treatmentPlanId) => {
    console.log("sendTreatmentPlanEmailHandler", treatmentPlanId);
    dispatch(sendTreatmentPlanEmail(treatmentPlanId));
  };

  // console.log("plans", plans);
  // console.log("userId", userId);

  return [
    {
      Header: translate("patient.columnID"),
      accessor: (row) => {
        const treatmentPlanID = row.treatmentPlanID;

        return (
          <Text
            maxWidth="60px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="xs"
            fontWeight="500"
            color={textSecondary}
          >
            {treatmentPlanID}
          </Text>
        );
      },
    },
    {
      Header: translate("patient.columnDate"),
      disableSortBy: false,
      accessor: (row) => {
        const { createdAt } = row;

        const isCurrentYear = dayjs().year() === dayjs(createdAt).year();

        return (
          <HStack gap={2}>
            <Stack gap={0} overflow="hidden">
              {createdAt && (
                <Text
                  fontSize="13px"
                  color={textSecondary}
                  fontWeight="500"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  isTruncated
                  textTransform="capitalize"
                >
                  {dayjs(createdAt).format("MMMM D")}
                </Text>
              )}
              {!isCurrentYear && (
                <Text
                  fontSize="13px"
                  color={textSecondary}
                  fontWeight="500"
                  isTruncated
                  noOfLines={1}
                  whiteSpace="normal"
                  overflow="hidden"
                  w="100%"
                  maxWidth="100px"
                >
                  {dayjs(createdAt).format("YYYY")}
                </Text>
              )}
            </Stack>
          </HStack>
        );
      },
    },
    {
      Header: translate("patient.columnCreatedBy"),
      accessor: (row) => {
        const { employee } = row;

        const name = employee
          ? employee.firstName + " " + employee.lastName
          : "N/A";

        const nameAlt = employee
          ? employee.firstName + " " + employee.lastName[0] + "."
          : "N/A";

        return (
          <Flex align="center" h="100%">
            <Tooltip
              bg={shade(0.3, secondary)}
              p="0.5rem 1rem"
              borderRadius="1rem"
              fontSize="sm"
              label={
                <Stack gap={0.5} ml={1}>
                  <Text
                    fontSize="13px"
                    fontWeight="500"
                    color={white}
                    whiteSpace="nowrap"
                    lineHeight={1}
                  >
                    {employee ? nameAlt : "N/A"}
                  </Text>
                  <Text
                    fontSize="xs"
                    color={white}
                    textTransform="capitalize"
                    lineHeight={1}
                    fontWeight="500"
                    maxWidth="150px"
                    isTruncated
                  >
                    {employee.profession && employee.profession.name}
                  </Text>
                </Stack>
              }
            >
              <Avatar
                h="1.5rem"
                w="1.5rem"
                name={employee ? name : "N/A"}
                src={employee.image ? employee.image : null}
                bg="#dceeff"
                fontSize="0.5rem"
                color="#3a74a9"
              />
            </Tooltip>

            {/* <Stack gap={0.5} ml={1}>
              <Text
                fontSize="13px"
                fontWeight="500"
                color={textSecondary}
                whiteSpace="nowrap"
                lineHeight={1}
              >
                {employee ? nameAlt : "N/A"}
              </Text>
              <Text
                fontSize="xs"
                color={textSecondary}
                textTransform="capitalize"
                lineHeight={1}
                fontWeight="500"
                maxWidth="150px"
                isTruncated
              >
                {employee.profession && employee.profession.name}
              </Text>
            </Stack> */}
          </Flex>
        );
      },
    },

    {
      Header: translate("patient.columnServices"),
      disableSortBy: false,
      accessor: (row) => {
        const { services } = row;

        // console.log("services", services);

        // Count total number of services
        const totalServices = services.length;

        // Count the number of completed services
        const completedServices = services.reduce((acc, service) => {
          return acc + (service.status === "completed" ? 1 : 0);
        }, 0);

        // Calculate the total cost of services
        const servicesTotal = services.reduce((acc, service) => {
          const price = Number(service.price || 0);
          const quantity = Number(service.quantity || 0);
          return acc + price * quantity;
        }, 0);

        return (
          <Flex align="center" pr={0} borderRight={`0px solid ${newBorder}`}>
            {/* <Icon as={TbMessageCircle} fontSize="lg" color="#989EA7" /> */}
            <Stack gap={0} textAlign="left" mr={4}>
              <Text fontSize="13px" color={textSecondary} fontWeight="500">
                <Text as="span" color={textPrimary} fontWeight="500">
                  {completedServices}
                </Text>
                /{totalServices}
              </Text>
              <Progress
                className="attendeesTotal"
                w="40px"
                value={(completedServices / totalServices) * 100}
                mt="0.25rem"
                color={primary}
                size="sm"
                height="3px"
                borderRadius="0.5rem"
              />
            </Stack>

            <Text
              fontSize="13px"
              color={textSecondary}
              fontWeight="500"
              whiteSpace="nowrap"
            >
              {servicesTotal.toFixed(2)} €
            </Text>
          </Flex>
        );
      },
    },
    // {
    //   Header: "old",
    //   disableSortBy: false,
    //   accessor: (row) => {
    //     const { services } = row;

    //     // Count total number of services
    //     const totalServices = services.length;

    //     // Count the number of completed services
    //     const completedServices = services.reduce((acc, service) => {
    //       return acc + (service.status === "completed" ? 1 : 0);
    //     }, 0);

    //     return (
    //       <Stack gap={0} textAlign="left">
    //         <Text fontSize="sm" color={textSecondary} fontWeight="500">
    //           <Text as="span" color={textPrimary} fontWeight="500">
    //             {completedServices}
    //           </Text>
    //           /{totalServices}
    //         </Text>
    //         <Progress
    //           className="attendeesTotal"
    //           w="100%"
    //           value={(completedServices / totalServices) * 100}
    //           mt="0.25rem"
    //           color={primary}
    //           size="sm"
    //           height="3px"
    //           borderRadius="0.5rem"
    //         />
    //       </Stack>
    //     );
    //   },
    // },
    // {
    //   Header: translate("patient.columnPrice"),
    //   disableSortBy: false,
    //   accessor: (row) => {
    //     const { services } = row;

    //     const servicesTotal = services.reduce((acc, service) => {
    //       return acc + service.price;
    //     }, 0);

    //     // console.log("servicesTotal", servicesTotal);

    //     return (
    //       <Text
    //         fontSize="sm"
    //         color={textPrimary}
    //         fontWeight="500"
    //         whiteSpace="nowrap"
    //       >
    //         {servicesTotal} €
    //       </Text>
    //     );
    //   },
    // },
    // {
    //   Header: "a",
    //   accessor: (row) => {
    //     const { services } = row;

    //     console.log("row", row);

    //     return (
    //       <Flex
    //         align="center"
    //         pr={0}
    //         borderRight={`1px solid ${newBorder}`}
    //       ></Flex>
    //     );
    //   },
    // },
    // {
    //   Header: translate("treatmentPlan.columnEmailSent"),
    //   accessor: (row) => {
    //     const { publicEmailDate } = row;

    //     // console.log("row  ", row);

    //     return (
    //       <>
    //         <Flex align="center">
    //           {publicEmailDate !== null ? (
    //             <Tooltip
    //               bg={shade(0.6, secondary)}
    //               p="0.5rem 1rem"
    //               borderRadius="1rem"
    //               label={
    //                 <Flex align="center" justify="center">
    //                   <Text
    //                     fontSize="xs"
    //                     color={tint(0.6, secondary)}
    //                     fontWeight="500"
    //                     textAlign="center"
    //                     mr={1}
    //                   >
    //                     {translate("treatmentPlan.emailSentToPatient")}
    //                   </Text>
    //                   <Text
    //                     fontSize="xs"
    //                     color={white}
    //                     fontWeight="600"
    //                     textAlign="center"
    //                     textTransform="capitalize"
    //                   >
    //                     {dayjs(publicEmailDate).format("MMMM D, YYYY, HH:mm")}
    //                   </Text>
    //                 </Flex>
    //               }
    //             >
    //               <Flex
    //                 w="1.5rem"
    //                 h="1.5rem"
    //                 bg={buttonBlue}
    //                 borderRadius="0.5rem"
    //                 align="center"
    //                 justify="center"
    //               >
    //                 <Icon
    //                   as={TbMailForward}
    //                   color={buttonBlueText}
    //                   fontSize="0.75rem"
    //                 />
    //               </Flex>
    //             </Tooltip>
    //           ) : (
    //             <Flex
    //               w="1.5rem"
    //               h="1.5rem"
    //               bg={buttonRed}
    //               borderRadius="0.5rem"
    //               align="center"
    //               justify="center"
    //             >
    //               <Icon
    //                 as={TbMailCancel}
    //                 color={buttonRedText}
    //                 fontSize="0.75rem"
    //               />
    //             </Flex>
    //           )}
    //         </Flex>
    //       </>
    //     );
    //   },
    // },
    {
      Header: translate("treatmentPlan.columnShared"),
      accessor: (row) => {
        const {
          publicEmailDate,
          availablePublic,
          approvedFromSharedLink,
          status,
          approvedFromSharedLinkDate,
        } = row;

        // console.log("row  ", row);
        // console.log("publicEmailDate", publicEmailDate);
        // console.log("availablePublic", availablePublic);

        const treatmentPlanID = row.treatmentPlanID;

        const handleUpdateAvailablePublic = (availablePublic) => {
          updateTreatmentPlanAvailablePublicHandler(row._id, availablePublic);

          if (availablePublic) {
            toastComponent.showToast(
              `${translate(
                "toasts.treatmentPlanUpdateAvailablePublic"
              )} - ${treatmentPlanID}`,

              "success",
              3000,
              translate("toasts.treatmentPlanUpdateAvailablePublicDescription")
            );
          } else {
            toastComponent.showToast(
              `${translate(
                "treatmentPlan.notAvailablePublic"
              )} - ${treatmentPlanID}`,
              "warning"
            );
          }
        };

        const approved = status === "approved";

        // console.log("row  ", row);

        return (
          <>
            <Flex align="center" gap={2}>
              <Tooltip
                bg={shade(0.6, secondary)}
                p="0.75rem 0.75rem"
                borderRadius="1rem"
                label={
                  <Flex align="center" justify="center">
                    <Text
                      fontSize="xs"
                      color={!availablePublic ? tint(0.6, secondary) : white}
                      fontWeight={!availablePublic ? "500" : "600"}
                      textAlign="center"
                    >
                      {!availablePublic
                        ? translate("treatmentPlan.notAvailablePublic")
                        : translate("treatmentPlan.availablePublic")}
                    </Text>
                  </Flex>
                }
                isDisabled={status === "approved" || status === "ongoing"}
              >
                <Button
                  size="sm"
                  bg={!availablePublic ? backgroundLight : buttonGreen}
                  borderRadius="0.5rem"
                  align="center"
                  justify="center"
                  h="2rem"
                  w="2.25rem"
                  onClick={() => handleUpdateAvailablePublic(!availablePublic)}
                  border={`1px solid ${newBorder}`}
                  isDisabled={status === "approved" || status === "ongoing"}
                >
                  <Icon
                    as={!availablePublic ? TbEyeOff : TbEyeCheck}
                    color={!availablePublic ? textSecondary : buttonGreenText}
                    fontSize="0.875rem"
                    pointerEvents="none"
                  />
                </Button>
              </Tooltip>

              <Tooltip
                bg={shade(0.6, secondary)}
                p="0.75rem 0.75rem"
                borderRadius="1rem"
                label={
                  <Flex align="center" justify="center">
                    <Text
                      fontSize="xs"
                      color={white}
                      fontWeight={!approvedFromSharedLink ? "500" : "600"}
                      textAlign="center"
                    >
                      {!approvedFromSharedLink ? (
                        translate("treatmentPlan.approvedByEmployee")
                      ) : (
                        <Flex>
                          {translate("treatmentPlan.approvedByPatient")}
                          {approvedFromSharedLinkDate && (
                            <Text
                              ml={1}
                              fontSize="xs"
                              color={white}
                              fontWeight="500"
                              textAlign="center"
                              textTransform="capitalize"
                            >
                              {dayjs(approvedFromSharedLinkDate).format(
                                "MMMM D, YYYY, HH:mm"
                              )}
                            </Text>
                          )}
                        </Flex>
                      )}
                    </Text>
                  </Flex>
                }
                isDisabled={!approved}
              >
                <Flex
                  bg={approved ? buttonGreen : backgroundLight}
                  h="2rem"
                  w="2.25rem"
                  borderRadius="0.5rem"
                  align="center"
                  justify="center"
                  border={`1px solid ${newBorder}`}
                >
                  {approved ? (
                    <Icon
                      as={!approvedFromSharedLink ? TbUserCheck : TbSignature}
                      color={
                        !approvedFromSharedLink
                          ? buttonGreenText
                          : buttonGreenText
                      }
                      fontSize="0.875rem"
                      pointerEvents="none"
                    />
                  ) : (
                    <Icon
                      as={TbSignatureOff}
                      color={textSecondary}
                      fontSize="0.875rem"
                      pointerEvents="none"
                    />
                  )}
                </Flex>
              </Tooltip>
            </Flex>
          </>
        );
      },
    },
    {
      Header: () => (
        <Flex w="100%" h="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("patient.columnStatus")}</Text>
          </Flex>
        </Flex>
      ),
      accessor: "status",
      Cell: ({ row }) => {
        const {
          status,
          _id,
          treatmentPlanID,
          cancelledFromSharedLink,
          cancelledFromSharedLinkDate,
          cancelledReason,
          cancelledComment,
        } = row.original;

        let cancelledBy = "-";

        if (cancelledFromSharedLink === true) {
          cancelledBy = translate("patient.patient");
        } else {
          cancelledBy = translate("employee.employee");
        }

        const handleUpdateStatus = (status) => {
          updateTreatmentPlanStatusHandler(_id, status);
        };

        const handleUpdateAvailablePublic = (availablePublic) => {
          updateTreatmentPlanAvailablePublicHandler(row._id, availablePublic);

          if (availablePublic) {
            toastComponent.showToast(
              `${translate(
                "toasts.treatmentPlanUpdateAvailablePublic"
              )} - ${treatmentPlanID}`,

              "success",
              3000,
              translate("toasts.treatmentPlanUpdateAvailablePublicDescription")
            );
          } else {
            toastComponent.showToast(
              `${translate(
                "treatmentPlan.notAvailablePublic"
              )} - ${treatmentPlanID}`,
              "warning"
            );
          }
        };

        console.log("row", row.original);

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              isUnclickable
              isSmall
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
              options="treatment"
              isTooltip={status === "cancelled"}
              tooltipLabel={
                <Stack gap={1} align="flex-start">
                  <Flex align="center" justify="center">
                    <Text
                      fontSize="xs"
                      color={tint(0.6, secondary)}
                      fontWeight="500"
                      textAlign="center"
                      mr={1}
                    >
                      {translate("reason.cancelledBy")}:
                    </Text>
                    <Text
                      isExternal
                      color={white}
                      fontWeight="500"
                      fontSize="13px"
                      isTruncated
                      maxW="128px"
                    >
                      {cancelledBy}
                    </Text>
                  </Flex>

                  {cancelledFromSharedLinkDate !== null && (
                    <Flex align="center" justify="center">
                      <Text
                        fontSize="xs"
                        color={tint(0.6, secondary)}
                        fontWeight="500"
                        textAlign="center"
                        mr={1}
                      >
                        {translate("reason.cancelledAt")}:
                      </Text>
                      <Text
                        color={white}
                        fontWeight="500"
                        fontSize="13px"
                        isTruncated
                        maxW="128px"
                        textTransform="capitalize"
                      >
                        {dayjs(cancelledFromSharedLinkDate).format(
                          "MMMM D, YYYY, HH:mm"
                        )}
                      </Text>
                    </Flex>
                  )}

                  {cancelledReason && cancelledReason.length > 0 && (
                    <Flex align="center" justify="center">
                      <Text
                        fontSize="xs"
                        color={tint(0.6, secondary)}
                        fontWeight="500"
                        textAlign="center"
                        mr={1}
                      >
                        {translate("reason.cancelledReason")}:
                      </Text>
                      <Text
                        color={white}
                        fontWeight="500"
                        fontSize="13px"
                        isTruncated
                        maxW="128px"
                      >
                        {translate(`reason.${cancelledReason}`)}
                      </Text>
                    </Flex>
                  )}

                  {cancelledComment && cancelledComment.length > 0 && (
                    <Flex align="center" justify="center">
                      <Text
                        fontSize="xs"
                        color={tint(0.6, secondary)}
                        fontWeight="500"
                        textAlign="center"
                        mr={1}
                      >
                        {translate("reason.cancelledComment")}:
                      </Text>
                      <Text
                        color={white}
                        fontWeight="500"
                        fontSize="13px"
                        isTruncated
                        maxW="128px"
                      >
                        {cancelledComment}
                      </Text>
                    </Flex>
                  )}
                </Stack>
              }
            />

            {/* <Button
              onClick={handleApproveClick}
              size="sm"
              colorScheme="green"
              isDisabled={[
                "ongoing",
                "completed",
                "draft",
                "cancelled",
              ].includes(status)}
            >
              Approve
            </Button> */}

            <MoreButton
              isSmall
              id={_id}
              deleteHandler={() => deleteTreatmentPlanHandler(_id)}
              deleteButtonDisabled={!isAdmin}
              approveHandler={() => approveTreatmentPlanHandler(_id)}
              disabledApproveStatus={[
                "ongoing",
                "completed",
                "draft",
                "cancelled",
                "approved",
              ].includes(status)}
              cancelHandler={() => cancelTreatmentPlanHandler(_id)}
              cancelButtonDisabled={isDoctor}
              disabledCancelStatus={[
                "ongoing",
                "completed",
                "cancelled",
                "draft",
              ].includes(status)}
              viewHandler={() =>
                handleUpdateAvailablePublic(!row.availablePublic)
              }
              viewButtonDisabled={false}
              viewLabel={
                !row.availablePublic
                  ? translate("treatmentPlan.makeAvailablePublic")
                  : translate("treatmentPlan.makeUnavailablePublic")
              }
              viewIcon={!row.availablePublic ? TbEyeCheck : TbEyeOff}
            />
          </HStack>
        );
      },
    },
  ];
};
