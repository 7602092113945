import React, { useState, useRef, useEffect, forwardRef } from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Text,
  Flex,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import {
  textSecondary,
  buttonBlue,
  buttonBlueText,
  buttonBlueDark,
  buttonGreen,
  buttonGreenText,
  buttonGreenDark,
  buttonRed,
  buttonRedText,
  buttonRedDark,
  buttonCyan,
  buttonCyanText,
  buttonCyanDark,
  buttonPurple,
  buttonPurpleText,
  buttonPurpleDark,
  buttonYellow,
  buttonYellowText,
  buttonYellowDark,
  white,
  backgroundLight,
  newBorder,
  buttonGreenVivid,
  buttonGreenVividText,
  buttonGreenVividDark,
  buttonAltGrey,
  buttonAltPrimary,
  buttonAltGreen,
  buttonAltYellow,
  buttonAltSuccess,
  buttonAltRed,
  buttonAltBlue,
  text,
  textPrimary,
  secondary,
  greenVivid,
  buttonRedNew,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import {
  FaRegCircleDot,
  FaRegCircle,
  FaCircleHalfStroke,
  FaCircleXmark,
  FaClock,
  FaCircleCheck,
  FaCircleExclamation,
  FaCaretDown,
  FaRegClock,
  FaRegCircleCheck,
} from "react-icons/fa6";
import { tint, shade } from "polished";

const StatusButton = forwardRef(
  (
    {
      currentStatus,
      onUpdateStatus,
      options,
      isUnclickable,
      isBig,
      isSmall,
      isRounded,
      onChangeStatus,
      isServiceButton,
      isDisabled,
      isToggle,
      toggleHandler,
      isLong,
      isTooltip,
      tooltipLabel,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const btnRef = useRef();
    const { t } = useTranslation();
    const translate = (key) => t(key);

    const handleStatusChange = (status) => {
      onUpdateStatus(status);
      console.log("handleStatusChange", status);
      setIsOpen(false);
    };

    const handleToggleClick = () => {
      console.log("handleToggleClick");
      toggleHandler();
      setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
      if (btnRef.current && !btnRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    if (options === "user") {
      options = [
        {
          value: "new",
          label: translate("status.patientNew"),
          icon: FaRegCircleDot,
          bg: buttonAltBlue,
          bgDark: buttonBlueDark,
          color: buttonBlueText,
          borderColor: buttonBlue,
          bgHover: "#b6cce1",
        },
        {
          value: "inactive",
          label: translate("status.patientInactive"),
          icon: FaRegCircle,
          bg: buttonAltGrey,
          bgDark: textSecondary,
          color: text,
          borderColor: buttonAltGrey,
          bgHover: "#ddd",
        },

        {
          value: "active",
          label: translate("status.patientActive"),
          icon: FaCircleCheck,
          bg: buttonAltGreen,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonAltGreen,
          bgHover: "#9ebfc2",
        },
        {
          value: "consulting",
          label: translate("status.patientConsulting"),
          icon: FaClock,
          bg: buttonAltPrimary,
          bgDark: buttonBlueDark,
          color: textPrimary,
          borderColor: newBorder,
          bgHover: "#ddd",
        },
        {
          value: "planningTreatment",
          label: translate("status.patientPlanningTreatment"),
          icon: FaClock,
          bg: buttonAltPrimary,
          bgDark: buttonYellowDark,
          color: textPrimary,
          borderColor: buttonYellow,
          bgHover: "#9ebfc2",
        },
        {
          value: "approvedTreatment",
          label: translate("status.patientApprovedTreatment"),
          icon: FaCircleCheck,
          bg: buttonAltGreen,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonAltGreen,
          bgHover: "#9ebfc2",
        },
        {
          value: "ongoingTreatment",
          label: translate("status.patientOngoingTreatment"),
          icon: FaCircleHalfStroke,
          bg: buttonAltYellow,
          bgDark: buttonYellowDark,
          color: textPrimary,
          borderColor: buttonAltYellow,
          bgHover: "#9ebfc2",
        },
        {
          value: "cancelledTreatment",
          label: translate("status.patientCancelledTreatment"),
          icon: FaCircleXmark,
          bg: buttonAltRed,
          bgDark: buttonRedDark,
          color: buttonRedText,
          borderColor: buttonRed,
          bgHover: "#dda3a3",
        },
        {
          value: "completedTreatment",
          label: translate("status.patientCompletedTreatment"),
          icon: FaCircleCheck,
          bg: buttonAltSuccess,
          bgDark: buttonGreenVividDark,
          color: textPrimary,
          borderColor: buttonAltSuccess,
          bgHover: "#dda3a3",
        },
      ];
    } else if (options === "appointment") {
      options = [
        {
          value: "scheduled",
          label: translate("status.appointmentScheduled"),
          icon: FaRegCircle,
          bg: buttonBlue,
          bgDark: buttonBlueDark,
          color: buttonBlueText,
          borderColor: newBorder,
          bgHover: "#ddd",
        },

        {
          value: "confirmed",
          label: translate("status.appointmentConfirmed"),
          icon: FaCircleCheck,
          bg: buttonGreen,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },
        {
          value: "completed",
          label: translate("status.appointmentCompleted"),
          icon: FaCircleCheck,
          bg: buttonGreen,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },
        {
          value: "cancelled",
          label: translate("status.appointmentCancelled"),
          icon: FaCircleXmark,
          bg: buttonRed,
          bgDark: buttonRedDark,
          color: buttonRedText,
          borderColor: buttonRed,
          bgHover: "#dda3a3",
        },
      ];
    } else if (options === "service") {
      options = [
        {
          value: "completed",
          label: translate("status.serviceCompleted"),
          icon: FaCircleCheck,
          bg: buttonGreen,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },
        {
          value: "uncompleted",
          label: translate("status.serviceUncompleted"),
          icon: FaCircleXmark,
          bg: buttonRed,
          bgDark: buttonRedDark,
          color: buttonRedText,
          borderColor: buttonRed,
          bgHover: buttonRedDark,
        },
      ];
    } else if (options === "treatment") {
      options = [
        {
          value: "draft",
          label: translate("status.draft"),
          icon: FaRegCircle,
          bg: buttonAltGrey,
          bgDark: textSecondary,
          color: textSecondary,
          borderColor: newBorder,
          bgHover: "#ddd",
        },
        {
          value: "approved",
          label: translate("status.treatmentPlanApproved"),
          icon: FaCircleCheck,
          bg: buttonAltGreen,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },

        {
          value: "consulting",
          label: translate("status.treatmentPlanConsulting"),
          icon: FaClock,
          bg: buttonAltPrimary,
          bgDark: buttonBlueDark,
          color: textPrimary,
          borderColor: newBorder,
          bgHover: "#ddd",
        },
        {
          value: "planning",
          label: translate("status.treatmentPlanPlanning"),
          icon: FaClock,
          bg: buttonAltPrimary,
          bgDark: buttonYellowDark,
          color: textPrimary,
          borderColor: buttonYellow,
          bgHover: "#9ebfc2",
        },
        {
          value: "completed",
          label: translate("status.treatmentPlanCompleted"),
          icon: FaCircleCheck,
          bg: buttonAltSuccess,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },
        {
          value: "cancelled",
          label: translate("status.treatmentPlanCancelled"),
          icon: FaCircleXmark,
          bg: buttonAltRed,
          bgDark: buttonRedDark,
          color: buttonRedText,
          borderColor: buttonRed,
          bgHover: "#dda3a3",
        },
        {
          value: "ongoing",
          label: translate("status.ongoing"),
          icon: FaCircleHalfStroke,
          bg: buttonAltYellow,
          bgDark: buttonYellowDark,
          color: textPrimary,
          borderColor: buttonAltYellow,
          bgHover: "#9ebfc2",
        },
      ];
    } else if (options === "treatmentPublic") {
      options = [
        {
          value: "draft",
          label: translate("status.draft"),
          icon: FaRegCircle,
          bg: buttonAltGrey,
          bgDark: textSecondary,
          color: textSecondary,
          borderColor: newBorder,
          bgHover: "#ddd",
        },
        {
          value: "approved",
          label: translate("status.treatmentPlanApproved"),
          icon: FaCircleCheck,
          bg: buttonAltGreen,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },

        {
          value: "consulting",
          label: translate("status.waitingForApprovalPublic"),
          icon: FaClock,
          bg: buttonAltPrimary,
          bgDark: buttonBlueDark,
          color: textPrimary,
          borderColor: newBorder,
          bgHover: shade(0.8, buttonAltPrimary),
        },
        {
          value: "planning",
          label: translate("status.waitingForApprovalPublic"),
          icon: FaClock,
          bg: buttonAltPrimary,
          bgDark: buttonYellowDark,
          color: textPrimary,
          borderColor: buttonYellow,
          bgHover: "#9ebfc2",
        },
        {
          value: "completed",
          label: translate("status.treatmentPlanCompleted"),
          icon: FaCircleCheck,
          bg: buttonAltSuccess,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },
        {
          value: "cancelled",
          label: translate("status.treatmentPlanCancelled"),
          icon: FaCircleXmark,
          bg: buttonAltRed,
          bgDark: buttonRedDark,
          color: buttonRedText,
          borderColor: buttonRed,
          bgHover: "#dda3a3",
        },
        {
          value: "ongoing",
          label: translate("status.ongoing"),
          icon: FaCircleHalfStroke,
          bg: buttonAltYellow,
          bgDark: buttonYellowDark,
          color: textPrimary,
          borderColor: buttonAltYellow,
          bgHover: "#9ebfc2",
        },
      ];
    } else if (options === "attendees") {
      options = [
        {
          value: "registered",
          label: translate("status.registered"),
          icon: FaRegCircleDot,
          bg: buttonBlue,
          bgDark: buttonBlue,
          color: textPrimary,
          borderColor: buttonBlue,
          bgHover: "#ddd",
        },
        {
          value: "confirmed",
          label: translate("status.confirmed"),
          icon: FaCircleCheck,
          bg: buttonGreen,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },
        {
          value: "attended",
          label: translate("status.attended"),
          icon: FaRegCircleCheck,
          bg: greenVivid,
          bgDark: greenVivid,
          color: textPrimary,
          borderColor: greenVivid,
          bgHover: greenVivid,
        },

        {
          value: "cancelled",
          label: translate("status.cancelled"),
          icon: FaCircleXmark,
          bg: buttonRed,
          bgDark: buttonRedDark,
          color: buttonRedText,
          borderColor: buttonRed,
          bgHover: "#dda3a3",
        },

        {
          value: "not_attended",
          label: translate("status.notAttended"),
          icon: FaCircleExclamation,
          bg: buttonRedNew,
          bgDark: buttonPurpleDark,
          color: white,
          borderColor: buttonPurple,
          bgHover: "#9ebfc2",
        },
      ];
    } else if (options === "active") {
      options = [
        {
          value: "inactive",
          label: translate("status.inactive"),
          icon: FaRegCircle,
          bg: backgroundLight,
          bgDark: textSecondary,
          color: textSecondary,
          borderColor: newBorder,
          bgHover: "#ddd",
        },

        {
          value: "active",
          label: translate("status.active"),
          icon: FaCircleCheck,
          bg: buttonGreen,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },
      ];
    } else if (options === "payment") {
      options = [
        {
          value: "unpaid",
          label: translate("status.unpaid"),
          icon: FaCircleXmark,
          bg: buttonAltRed,
          bgDark: buttonRedDark,
          color: buttonRedText,
          borderColor: buttonRed,
          bgHover: "#dda3a3",
        },

        {
          value: "paid",
          label: translate("status.paid"),
          icon: FaCircleCheck,
          bg: buttonAltSuccess,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },
      ];
    } else if (options === "course") {
      options = [
        {
          value: "draft",
          label: translate("status.draft"),
          icon: FaRegCircle,
          bg: buttonAltGrey,
          bgDark: textSecondary,
          color: textSecondary,
          borderColor: newBorder,
          bgHover: "#ddd",
        },

        {
          value: "upcoming",
          label: translate("status.upcoming"),
          icon: FaClock,
          bg: buttonBlue,
          bgDark: buttonBlueDark,
          color: buttonBlueText,
          borderColor: buttonBlue,
          bgHover: "#b6cce1",
        },
        {
          value: "cancelled",
          label: translate("status.cancelled"),
          icon: FaCircleXmark,
          bg: buttonRed,
          bgDark: buttonRedDark,
          color: buttonRedText,
          borderColor: buttonRed,
          bgHover: "#dda3a3",
        },
        {
          value: "completed",
          label: translate("status.completed"),
          icon: FaCircleCheck,
          bg: buttonGreen,
          bgDark: buttonGreenDark,
          color: buttonGreenText,
          borderColor: buttonGreen,
          bgHover: "#9ebfc2",
        },
      ];
    } else {
      // You can leave this part as it is
    }

    const currentStatusOption = options.find(
      (option) => option.value === currentStatus
    );

    const handleClick = () => {
      if (onChangeStatus) {
        // Call onChangeStatus when provided
        onUpdateStatus(currentStatus);
      } else {
        setIsOpen(!isOpen);
      }
    };

    // console.log("currentStatusOption", currentStatusOption);

    return (
      <>
        <Tooltip
          p="0.5rem"
          bg={shade(0.6, secondary)}
          borderRadius="1rem"
          label={tooltipLabel}
          isDisabled={!isTooltip}
        >
          <Box ref={ref} p={0} borderRadius="0.75rem">
            <Menu isLazy>
              <MenuButton
                size={isBig ? "lg" : isSmall ? "sm" : "md"}
                as={Button}
                bg={currentStatusOption ? currentStatusOption.bg : white}
                color={
                  currentStatusOption
                    ? currentStatusOption.color
                    : textSecondary
                }
                // border={
                //   currentStatusOption.borderColor
                //     ? `1px solid ${currentStatusOption.borderColor}`
                //     : `0px solid ${textSecondary}`
                // }
                display="flex"
                alignItems="center"
                justifyContent="center"
                _hover={{
                  bg: shade(
                    0.3,
                    currentStatusOption ? currentStatusOption.bg : white
                  ),
                  color: isBig ? white : white,
                }}
                minWidth={
                  isBig
                    ? "160px"
                    : isSmall
                    ? "140px"
                    : isServiceButton
                    ? "140px"
                    : "170px"
                }
                maxWidth={
                  isBig
                    ? "160px"
                    : isSmall
                    ? "140px"
                    : isServiceButton
                    ? "140px"
                    : isLong
                    ? "270px"
                    : "170px"
                }
                borderRadius={
                  isRounded ? "10.75rem" : isBig ? "0.75rem" : "0.75rem"
                }
                onClick={isToggle ? handleToggleClick : handleClick} // Handle click
                _active={{
                  bg: currentStatusOption.bg,
                  color: currentStatusOption.color,
                }}
                isDisabled={isDisabled}
              >
                {currentStatusOption ? (
                  <>
                    <Flex gap={0} align="center" justify="center">
                      <Flex align="center">
                        <Icon
                          as={currentStatusOption.icon}
                          mr={isSmall ? "0.25rem" : "0.5rem"}
                          h={isSmall ? "0.65rem" : "0.75rem"}
                          w={isSmall ? "0.65rem" : "0.75rem"}
                          opacity="1"
                        />
                        <Text
                          fontSize={
                            isBig ? "1rem" : isSmall ? "0.75rem" : "0.875rem"
                          }
                          fontWeight={isBig ? "500" : "500"}
                          lineHeight="1"
                          textTransform="capitalize"
                        >
                          {currentStatusOption.label}
                        </Text>
                      </Flex>

                      {isUnclickable ? null : (
                        <Icon
                          as={FaCaretDown}
                          ml="0.25rem"
                          h="0.75rem"
                          w="0.75rem"
                        />
                      )}
                    </Flex>
                  </>
                ) : (
                  "Unknown"
                )}
              </MenuButton>

              {isUnclickable ? null : (
                <MenuList p={0} m={0} borderRadius="1rem" overflow="hidden">
                  {options.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => handleStatusChange(option.value)}
                      color={option.color}
                      py={4}
                      px={6}
                      borderBottom={`1px solid ${newBorder}`}
                    >
                      <Icon
                        as={option.icon}
                        color={option.bgDark}
                        mr="0.5rem"
                        h="0.75rem"
                        w="0.75rem"
                        style={{
                          pointerEvents: "none",
                        }}
                      />
                      <Text
                        fontSize="0.875rem"
                        fontWeight="500"
                        lineHeight="1"
                        style={{
                          pointerEvents: "none",
                        }}
                        color={textSecondary}
                      >
                        {option.label}
                      </Text>
                    </MenuItem>
                  ))}
                </MenuList>
              )}
            </Menu>
          </Box>
        </Tooltip>
      </>
    );
  }
);

export default StatusButton;
