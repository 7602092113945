import React, { useState, useEffect } from "react";
import { Tabs, TabList, Tab, TabIndicator } from "@chakra-ui/react";
import {
  backgroundLight,
  primary,
  textPrimary,
  textSecondary,
  newBorder,
  white,
} from "../../utils/colors";

const TabsComponent = ({
  activeTab,
  setActiveTab,
  tabs,
  isTitleValid,
  children,
  disabledTabs,
  isSinglePage,
  isProfessionValid,
}) => {
  const [isScrolledPast, setIsScrolledPast] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;

    console.log("Scroll position:", position);
    setIsScrolledPast(position > 60);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log("disabledTabs", disabledTabs);

  return (
    <Tabs
      index={activeTab}
      onChange={(index) => setActiveTab(index)}
      variant="unstyled"
      w="100%"
      style={{
        position: isScrolledPast ? "absolute" : "relative", // Apply conditional styling
        top: isScrolledPast ? "0" : "auto", // Position at the top when scrolled past 60px
      }}
    >
      <TabList
        bg={backgroundLight}
        borderTopLeftRadius="10.875rem"
        borderBottomLeftRadius="10.875rem"
        borderBottom={isSinglePage ? `0px solid ${newBorder}` : "none"}
        borderTopRightRadius="10.875rem"
        borderBottomRightRadius="10.875rem"
        w="100%"
        h="4.5rem"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        p="0 0.75rem"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            fontSize="0.875rem"
            fontWeight="500"
            h="3.25rem"
            color={textSecondary}
            bg="none"
            p="0 2.25rem"
            m="0 0.35rem"
            borderRadius="10rem"
            isDisabled={
              // Check if the current index is in the disabledTabs array or if the title or profession is not valid
              (index !== 0 && (!isTitleValid || !isProfessionValid)) ||
              disabledTabs.includes(index)
            }
            _hover={{
              color: textPrimary,
              bg: "rgba(0, 0, 0, 0.05)",
              fontWeight: "500",
              boxShadow: "none",
            }}
            _selected={{
              color: textPrimary,
              bg: white,
              fontWeight: "600",
              boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.05)",
            }}
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>
      {/* <TabIndicator
        m="-1.5px auto 0"
        height="2px"
        borderRadius="1rem"
        bg={primary}
      /> */}
      {children}
    </Tabs>
  );
};

export default TabsComponent;
