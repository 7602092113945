import axios from "axios";
import {
  TREATMENTPLAN_LIST_REQUEST,
  TREATMENTPLAN_LIST_SUCCESS,
  TREATMENTPLAN_LIST_FAIL,
  TREATMENTPLAN_DETAILS_REQUEST,
  TREATMENTPLAN_DETAILS_SUCCESS,
  TREATMENTPLAN_DETAILS_FAIL,
  TREATMENTPLAN_CREATE_REQUEST,
  TREATMENTPLAN_CREATE_SUCCESS,
  TREATMENTPLAN_CREATE_FAIL,
  TREATMENTPLAN_UPDATE_REQUEST,
  TREATMENTPLAN_UPDATE_SUCCESS,
  TREATMENTPLAN_UPDATE_FAIL,
  TREATMENTPLAN_DELETE_REQUEST,
  TREATMENTPLAN_DELETE_SUCCESS,
  TREATMENTPLAN_DELETE_FAIL,
  TREATMENTPLAN_UPDATE_SERVICES_ORDER_REQUEST,
  TREATMENTPLAN_UPDATE_SERVICES_ORDER_SUCCESS,
  TREATMENTPLAN_UPDATE_SERVICES_ORDER_FAIL,
  TREATMENTPLAN_UPDATE_STATUS_REQUEST,
  TREATMENTPLAN_UPDATE_STATUS_SUCCESS,
  TREATMENTPLAN_UPDATE_STATUS_FAIL,
  TREATMENTPLAN_APPROVE_REQUEST,
  TREATMENTPLAN_APPROVE_SUCCESS,
  TREATMENTPLAN_APPROVE_FAIL,
  TREATMENTPLAN_CANCEL_REQUEST,
  TREATMENTPLAN_CANCEL_SUCCESS,
  TREATMENTPLAN_CANCEL_FAIL,
  TREATMENTPLAN_STATISTICS_REQUEST,
  TREATMENTPLAN_STATISTICS_FAIL,
  TREATMENTPLAN_STATISTICS_SUCCESS,
  TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_REQUEST,
  TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_SUCCESS,
  TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_FAIL,
  TREATMENTPLAN_SEND_EMAIL_REQUEST,
  TREATMENTPLAN_SEND_EMAIL_SUCCESS,
  TREATMENTPLAN_SEND_EMAIL_FAIL,
  TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_REQUEST,
  TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_SUCCESS,
  TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_FAIL,
  TREATMENTPLAN_DOWNLOAD_PDF_REQUEST,
  TREATMENTPLAN_DOWNLOAD_PDF_SUCCESS,
  TREATMENTPLAN_DOWNLOAD_PDF_FAIL,
} from "../constants/treatmentPlanConstants";

import { logout } from "./userActions";

export const listTreatmentPlans =
  (
    keyword = "",
    pageNumber = "",
    pageSize = "",
    sortField = "",
    sortOrder = "",
    fetchAll = false,
    employeeId,
    status,
    startDate,
    endDate,
    minPrice,
    maxPrice
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: TREATMENTPLAN_LIST_REQUEST });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      console.log("Received Params in list TP plans Action:", {
        keyword,
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
        fetchAll,
        employeeId,
        status,
        startDate,
        endDate,
        minPrice,
        maxPrice,
      });

      let query = `/api/treatment-plans?keyword=${encodeURIComponent(keyword)}`;
      query += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      if (sortField) query += `&sortField=${sortField}`;
      if (sortOrder) query += `&sortOrder=${sortOrder}`;

      if (fetchAll) query += `&all=${fetchAll}`;

      // if (employeeId) {
      //   // Assuming employeeId is an array
      //   query += `&employeeId=${encodeURIComponent(employeeId.join(","))}`;
      // }
      if (employeeId) query += `&employeeId=${encodeURIComponent(employeeId)}`;
      if (status) query += `&status=${encodeURIComponent(status)}`;
      if (minPrice) query += `&minPrice=${encodeURIComponent(minPrice)}`;
      if (maxPrice) query += `&maxPrice=${encodeURIComponent(maxPrice)}`;
      if (startDate) query += `&startDate=${encodeURIComponent(startDate)}`;
      if (endDate) query += `&endDate=${encodeURIComponent(endDate)}`;

      // if (creatorEmployeeId) {
      //   // Assuming creatorEmployeeId is an array
      //   query += `&creatorEmployeeId=${encodeURIComponent(
      //     creatorEmployeeId.join(",")
      //   )}`;
      // }

      console.log("TP plans query:", query);

      const { data } = await axios.get(query, config);

      dispatch({
        type: TREATMENTPLAN_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TREATMENTPLAN_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listTreatmentPlanDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TREATMENTPLAN_DETAILS_REQUEST });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/treatment-plans/${id}`, config);

    dispatch({
      type: TREATMENTPLAN_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TREATMENTPLAN_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createTreatmentPlan =
  ({ patient, employee, services, status, coordinator }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: TREATMENTPLAN_CREATE_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/treatment-plans`,
        { patient, employee, services, status, coordinator },
        config
      );

      dispatch({
        type: TREATMENTPLAN_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TREATMENTPLAN_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response && error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

export const updateTreatmentPlan =
  (treatmentPlan) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TREATMENTPLAN_UPDATE_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/treatment-plans/${treatmentPlan._id}`,
        treatmentPlan,
        config
      );

      dispatch({
        type: TREATMENTPLAN_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: TREATMENTPLAN_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: TREATMENTPLAN_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      if (error.response && error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

export const deleteTreatmentPlan = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TREATMENTPLAN_DELETE_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/treatment-plans/${id}`, config);

    dispatch({
      type: TREATMENTPLAN_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: TREATMENTPLAN_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateServicesOrder =
  (id, services) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TREATMENTPLAN_UPDATE_SERVICES_ORDER_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.put(
        `/api/treatment-plans/${id}/order`,
        { newOrder: services },
        config
      );

      dispatch({
        type: TREATMENTPLAN_UPDATE_SERVICES_ORDER_SUCCESS,
      });

      dispatch(listTreatmentPlanDetails(id));
    } catch (error) {
      dispatch({
        type: TREATMENTPLAN_UPDATE_SERVICES_ORDER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateTreatmentPlanStatus =
  (id, newStatus) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TREATMENTPLAN_UPDATE_STATUS_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.put(
        `/api/treatment-plans/${id}/status`,
        { newStatus },
        config
      );

      dispatch({
        type: TREATMENTPLAN_UPDATE_STATUS_SUCCESS,
      });

      dispatch(listTreatmentPlanDetails(id));
    } catch (error) {
      dispatch({
        type: TREATMENTPLAN_UPDATE_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const approveTreatmentPlan = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TREATMENTPLAN_APPROVE_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.put(`/api/treatment-plans/${id}/approve`, {}, config);

    dispatch({
      type: TREATMENTPLAN_APPROVE_SUCCESS,
    });

    dispatch(listTreatmentPlanDetails(id));
  } catch (error) {
    dispatch({
      type: TREATMENTPLAN_APPROVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const cancelTreatmentPlan = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TREATMENTPLAN_CANCEL_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.put(`/api/treatment-plans/${id}/cancel`, {}, config);

    dispatch({
      type: TREATMENTPLAN_CANCEL_SUCCESS,
    });

    dispatch(listTreatmentPlanDetails(id));
  } catch (error) {
    dispatch({
      type: TREATMENTPLAN_CANCEL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getTreatmentPlanStatistics = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TREATMENTPLAN_STATISTICS_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    let url = `/api/treatment-plans/stats`;

    const { data } = await axios.get(url, config);

    dispatch({
      type: TREATMENTPLAN_STATISTICS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TREATMENTPLAN_STATISTICS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getTreatmentPlanStatsPerEmployee =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      let url = `/api/treatment-plans/stats/employee`;

      const { data } = await axios.get(url, config);

      dispatch({
        type: TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TREATMENTPLAN_STATISTICS_PER_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const sendTreatmentPlanEmail = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TREATMENTPLAN_SEND_EMAIL_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.post(`/api/treatment-plans/${id}/send-email`, {}, config);

    dispatch({
      type: TREATMENTPLAN_SEND_EMAIL_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: TREATMENTPLAN_SEND_EMAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateTreatmentPlanAvailablePublic =
  (id, availablePublic) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_REQUEST,
      });

      const {
        authLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.put(
        `/api/treatment-plans/${id}/available-public`,
        { availablePublic },
        config
      );

      dispatch({
        type: TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_SUCCESS,
      });

      dispatch(listTreatmentPlanDetails(id));
    } catch (error) {
      dispatch({
        type: TREATMENTPLAN_UPDATE_AVAILABLEPUBLIC_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const downloadTreatmentPlanPdf = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TREATMENTPLAN_DOWNLOAD_PDF_REQUEST,
    });

    const {
      authLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
      responseType: "blob", // Important to handle binary data
    };

    const { data } = await axios.get(
      `/api/treatment-plans/${id}/generate-download-pdf`,
      config
    );

    dispatch({
      type: TREATMENTPLAN_DOWNLOAD_PDF_SUCCESS,
      payload: data,
    });
    return { success: true, payload: data };
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: TREATMENTPLAN_DOWNLOAD_PDF_FAIL,
      payload: errorMessage,
    });

    // Return an object indicating failure and the error message
    return { success: false, error: errorMessage };
  }
};
