import React from "react";
import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { primary, textPrimary, textSecondary } from "../../../utils/colors";
import { useTranslation } from "react-i18next";

const Agreement = () => {
  const { t } = useTranslation();
  return (
    <Flex
      w="100%"
      align="center"
      jusityf="center"
      position="relative"
      pt={10}
      textAlign="center"
      direction="column"
    >
      <Text
        fontSize="xs"
        fontWeight="500"
        lineHeight="1.8"
        color={textSecondary}
      >
        {t("common.byCreatingAccount")}
        <Text
          as="span"
          color={textSecondary}
          textDecoration="underline"
          _hover={{
            color: primary,
          }}
        >
          {/* <Link to="/terms-of-service"> {t("common.termsOfService")}</Link> */}
          <Link> {t("common.termsOfService")}</Link>
        </Text>
        ,{" "}
        <Text
          as="span"
          color={textSecondary}
          textDecoration="underline"
          _hover={{
            color: primary,
          }}
        >
          {/* <Link to="/privacy-policy"> {t("common.privacyPolicy")}</Link> */}
          <Link> {t("common.privacyPolicy")}</Link>
        </Text>
        , {t("common.termsAnd")}
        <Text
          as="span"
          color={textSecondary}
          textDecoration="underline"
          _hover={{
            color: primary,
          }}
        >
          {/* <Link to="/notification-settings">
            {" "}
            {t("common.notificationSettings")}
          </Link> */}
          <Link> {t("common.notificationSettings")}</Link>
        </Text>
        .
      </Text>

      <Box mt={4}>
        <Text fontSize="sm" fontWeight="500" color={textPrimary}>
          {t("common.alreadyHaveAccount")}
          <Link to="/login">
            <Button
              ml={1}
              variant="link"
              color={primary}
              p={0}
              size="sm"
              role="group"
              _hover={{
                transition: "0.2s all",
              }}
            >
              <Text
                as="span"
                color={primary}
                _groupHover={{
                  pr: "0.25rem",
                  transition: "0.2s all",
                }}
              >
                {t("common.login")}
              </Text>{" "}
              <Icon ml={1} h="0.6rem" as={FaArrowRightLong} />
            </Button>
          </Link>
        </Text>
      </Box>
    </Flex>
  );
};

export default Agreement;
