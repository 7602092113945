import axios from "axios";
import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_FAIL,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
} from "../constants/authConstants";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_LOGIN_REQUEST });
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.post(
      "/api/auth/login",
      { email, password },
      config
    );
    dispatch({ type: AUTH_LOGIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
    return data; // Return the response data
  } catch (error) {
    dispatch({
      type: AUTH_LOGIN_FAIL,
      payload: error.response?.data.message || error.message,
    });
    throw error; // Throw the error to be caught in the handleSubmit
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("cartItems");
  localStorage.removeItem("shippingAddress");
  localStorage.removeItem("paymentMethod");
  dispatch({ type: AUTH_LOGOUT });
  document.location.href = "/login";
};
