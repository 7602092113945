import React from "react";
import { Flex, Heading, Text, Image, Button } from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  backgroundLight,
  white,
  primary,
  secondary,
} from "../../../../../utils/colors";
import AppointmentCard from "../../../../../components/cards/AppointmentCard";
import noServices from "../../../../../assets/images/empty-box.png";
import { useTranslation } from "react-i18next";

const PatientAppointments = () => {
  const { t } = useTranslation();
  return (
    <>
      <Flex direction="column" w="100%">
        <Heading
          fontSize="1.125rem"
          color={textPrimary}
          fontWeight="600"
          m={0}
          p="0 0 0.75rem"
        >
          {t("patient.appointments")}
        </Heading>
        <Flex
          w="100%"
          bg={backgroundLight}
          direction="column"
          p="1.5rem"
          borderRadius="1rem"
        >
          <Flex
            direction="column"
            justify="center"
            align="center"
            w="100%"
            p="3rem 0"
            bg={white}
            borderRadius="1rem"
          >
            <Image h="auto" w="3rem" mb={2} src={noServices} />
            <Text color={textSecondary} fontWeight="400">
              {t("patient.appointmentsNone")}
            </Text>
            <Button
              m="1rem 0 0"
              bg={primary}
              color={white}
              onClick={() => {
                console.log("clicked");
              }}
              borderRadius="10rem"
              fontWeight="500"
              fontSize="sm"
              size="md"
              _hover={{
                bg: secondary,
              }}
              isDisabled
            >
              {t("patient.appointmentsCreate")}
            </Button>
          </Flex>

          {/* <AppointmentCard />
          <AppointmentCard />
          <AppointmentCard />
          <AppointmentCard /> */}
        </Flex>
      </Flex>
    </>
  );
};

export default PatientAppointments;
