import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_FAIL,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_RESET,
  AUTH_LOGOUT,
} from "../constants/authConstants";

export const authLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return { loading: true };
    case AUTH_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case AUTH_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case AUTH_LOGIN_RESET:
      return {};
    case AUTH_LOGOUT:
      return {};
    default:
      return state;
  }
};
