export const PROFESSION_LIST_REQUEST = "PROFESSION_LIST_REQUEST";
export const PROFESSION_LIST_SUCCESS = "PROFESSION_LIST_SUCCESS";
export const PROFESSION_LIST_FAIL = "PROFESSION_LIST_FAIL";

export const PROFESSION_DETAILS_REQUEST = "PROFESSION_DETAILS_REQUEST";
export const PROFESSION_DETAILS_SUCCESS = "PROFESSION_DETAILS_SUCCESS";
export const PROFESSION_DETAILS_FAIL = "PROFESSION_DETAILS_FAIL";

export const PROFESSION_CREATE_REQUEST = "PROFESSION_CREATE_REQUEST";
export const PROFESSION_CREATE_SUCCESS = "PROFESSION_CREATE_SUCCESS";
export const PROFESSION_CREATE_FAIL = "PROFESSION_CREATE_FAIL";
export const PROFESSION_CREATE_RESET = "PROFESSION_CREATE_RESET";

export const PROFESSION_UPDATE_REQUEST = "PROFESSION_UPDATE_REQUEST";
export const PROFESSION_UPDATE_SUCCESS = "PROFESSION_UPDATE_SUCCESS";
export const PROFESSION_UPDATE_FAIL = "PROFESSION_UPDATE_FAIL";
export const PROFESSION_UPDATE_RESET = "PROFESSION_UPDATE_RESET";

export const PROFESSION_DELETE_REQUEST = "PROFESSION_DELETE_REQUEST";
export const PROFESSION_DELETE_SUCCESS = "PROFESSION_DELETE_SUCCESS";
export const PROFESSION_DELETE_FAIL = "PROFESSION_DELETE_FAIL";
export const PROFESSION_DELETE_RESET = "PROFESSION_DELETE_RESET";

export const PROFESSION_UPDATE_STATUS_REQUEST =
  "PROFESSION_UPDATE_STATUS_REQUEST";
export const PROFESSION_UPDATE_STATUS_SUCCESS =
  "PROFESSION_UPDATE_STATUS_SUCCESS";
export const PROFESSION_UPDATE_STATUS_FAIL = "PROFESSION_UPDATE_STATUS_FAIL";
export const PROFESSION_UPDATE_STATUS_RESET = "PROFESSION_UPDATE_STATUS_RESET";
