import React from "react";
import {
  Text,
  Avatar,
  Stack,
  HStack,
  Image,
  Circle,
  Box,
  Tooltip,
  Icon,
  Progress,
  Flex,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  textSecondary,
  textPrimary,
  buttonBlue,
  buttonBlueText,
  newBorder,
  buttonGreenText,
  buttonRedText,
  white,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";
import {
  FaRegNoteSticky,
  FaRegCircleCheck,
  FaRegCircle,
  FaRegCircleDot,
} from "react-icons/fa6";
import { format } from "morgan";

export const columns = (
  dispatch,
  translate,
  deleteServiceHandler,
  openEditSidePanelModal,
  page,
  pageSize
) => {
  return [
    {
      Header: "#",
      id: "index",
      accessor: (_row, i) => {
        // Adjust the index based on the page and pageSize
        const adjustedIndex = (page - 1) * pageSize + i + 1;
        return (
          <Text
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="sm"
            color={textSecondary}
            fontWeight="500"
          >
            {adjustedIndex}
          </Text>
        );
      },
    },
    {
      Header: translate("treatmentStep.columnName"),
      disableSortBy: false,
      accessor: (row) => {
        const { name } = row;

        return (
          <HStack gap={2}>
            <Stack gap={0} maxWidth="200px" overflow="hidden">
              <Text
                fontSize="md"
                color={textPrimary}
                fontWeight="600"
                isTruncated // This will add ellipsis
                noOfLines={2} // This will limit the text to two lines
                whiteSpace="normal"
                overflow="hidden"
                w="100%"
                maxWidth="200px"
              >
                {name}
              </Text>
            </Stack>
          </HStack>
        );
      },
    },
    {
      Header: translate("treatmentStep.columnOrder"),
      accessor: (row) => {
        const { order } = row;

        return (
          <Text fontSize="sm" color={textSecondary} fontWeight="500">
            {order}
          </Text>
        );
      },
    },
    // {
    //   Header: translate("service.columnServiceGroup"),
    //   accessor: (row) => {
    //     const { serviceGroup } = row;

    //     const name = serviceGroup ? serviceGroup.name : "";

    //     console.log("serviceGroup", serviceGroup);
    //     return (
    //       <Text fontSize="sm" color={textSecondary} fontWeight="500">
    //         {name}
    //       </Text>
    //     );
    //   },
    // },
    // {
    //   Header: translate("service.columnCustomId"),
    //   accessor: (row) => {
    //     const { customId } = row;
    //     return (
    //       <Text
    //         overflow="hidden"
    //         whiteSpace="nowrap"
    //         fontSize="sm"
    //         color={textSecondary}
    //         fontWeight="500"
    //       >
    //         {customId}
    //       </Text>
    //     );
    //   },
    // },
    // {
    //   Header: translate("service.columnDuration"),
    //   accessor: (row) => {
    //     const { duration } = row;

    //     const hours = Math.floor(duration / 3600);
    //     const minutes = Math.floor((duration - hours * 3600) / 60);
    //     const seconds = duration - hours * 3600 - minutes * 60;

    //     let formattedDuration = "";
    //     if (hours > 0) formattedDuration += `${hours}h `;
    //     if (minutes > 0) formattedDuration += `${minutes}min `;
    //     if (seconds > 0) formattedDuration += `${seconds}s`;

    //     return (
    //       <Text fontSize="sm" color={textSecondary} fontWeight="500">
    //         {formattedDuration.trim()}
    //       </Text>
    //     );
    //   },
    // },

    {
      Header: translate("service.columnStatus"),
      accessor: (row) => {
        const { status, _id } = row;

        const handleUpdateStatus = (newStatus) => {
          // dispatch(updateSpeakerStatus(_id, newStatus));
        };

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
              options="active"
            />
            <MoreButton
              id={_id}
              deleteHandler={deleteServiceHandler}
              editHandler={(e) => {
                e.stopPropagation();
                openEditSidePanelModal(_id);
              }}
            />
          </HStack>
        );
      },
    },
  ];
};
