import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { authLoginReducer } from "./reducers/authReducers";
import {
  tenantRegisterReducer,
  tenantVerifyReducer,
  tenantDetailsReducer,
  tenantUpdateReducer,
  tenantUpdateAdminUserReducer,
  tenantUpdateTypeReducer,
  tenantUpdateOperatingRoomsReducer,
  tenantUpdateImageReducer,
} from "./reducers/tenantReducers";

import {
  appointmentListReducer,
  appointmentCreateReducer,
  appointmentDeleteReducer,
  appointmentDetailsReducer,
  appointmentUpdateReducer,
} from "./reducers/appointmentReducers";
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
  productSupplierAddReducer,
  productSuppliersListReducer,
  productSupplierUpdateReducer,
  productSupplierDeleteReducer,
  productListByCategoryReducer,
} from "./reducers/productReducers";
import { cartReducer } from "./reducers/cartReducers";
import {
  userRegisterReducer,
  userCreateReducer,
  userDetailsReducer,
  userEmployeeDetailsReducer,
  userUpdateProfileReducer,
  userDeleteReducer,
  userUpdateReducer,
  userListReducer,
  userUpdateStatusReducer,
  userPatientsListReducer,
  userEmployeesListReducer,
  userAddNoteReducer,
  userListNotesReducer,
  userCancelAllTreatmentsReducer,
  patientStatisticsReducer,
  userCreateEmployeeReducer,
  userDoctorServicesReducer,
  userAddressStatesReducer,
  userSendTreatmentPlanListEmailReducer,
  userPatientsSimpleListReducer,
} from "./reducers/userReducers";
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderListMyReducer,
  orderListReducer,
} from "./reducers/orderReducers";

import {
  supplierListReducer,
  supplierCreateReducer,
  supplierDeleteReducer,
  supplierDetailsReducer,
  supplierUpdateReducer,
} from "./reducers/supplierReducers";

import {
  categoryListReducer,
  categoryDetailsReducer,
  categoryCreateReducer,
  categoryUpdateReducer,
  categoryDeleteReducer,
} from "./reducers/categoryReducers";

import {
  employeeListReducer,
  employeeDetailsReducer,
  employeeCreateReducer,
  employeeUpdateReducer,
  employeeDeleteReducer,
} from "./reducers/employeeReducers";

import {
  serviceListReducer,
  serviceDetailsReducer,
  serviceCreateReducer,
  serviceUpdateReducer,
  serviceDeleteReducer,
  serviceUpdateStatusReducer,
} from "./reducers/serviceReducers";

import {
  serviceGroupListReducer,
  serviceGroupDetailsReducer,
  serviceGroupCreateReducer,
  serviceGroupUpdateReducer,
  serviceGroupDeleteReducer,
  serviceGroupUpdateStatusReducer,
  serviceGroupUpdateIsConsultingReducer,
} from "./reducers/serviceGroupReducers";

import {
  professionListReducer,
  professionDetailsReducer,
  professionCreateReducer,
  professionUpdateReducer,
  professionDeleteReducer,
  professionUpdateStatusReducer,
} from "./reducers/professionReducers";

import {
  professionGroupListReducer,
  professionGroupDetailsReducer,
  professionGroupCreateReducer,
  professionGroupUpdateReducer,
  professionGroupDeleteReducer,
  professionGroupUpdateStatusReducer,
  professionGroupUpdateIsConsultingReducer,
} from "./reducers/professionGroupReducers";

import {
  treatmentPlanListReducer,
  treatmentPlanDetailsReducer,
  treatmentPlanCreateReducer,
  treatmentPlanUpdateReducer,
  treatmentPlanDeleteReducer,
  treatmentPlanUpdateServicesOrderReducer,
  treatmentPlanUpdateStatusReducer,
  treatmentPlanApproveReducer,
  treatmentPlanCancelReducer,
  treatmentPlanStatisticsReducer,
  treatmentPlanStatisticsPerEmployeeReducer,
  treatmentPlanSendEmailReducer,
  treatmentPlanUpdateAvailablePublicReducer,
  treatmentPlanDownloadPdfReducer,
} from "./reducers/treatmentPlanReducers";

import {
  treatmentStepListReducer,
  treatmentStepDetailsReducer,
  treatmentStepCreateReducer,
  treatmentStepUpdateReducer,
  treatmentStepDeleteReducer,
} from "./reducers/treatmentStepReducers";

import {
  statisticPatientReducer,
  statisticTreatmentPlanReducer,
  statisticTopServiceReducer,
  statisticTopServiceGroupReducer,
  statisticTopEmployeeReducer,
  statisticCoordinatorReducer,
  statisticTopServiceDoctorReducer,
  statisticTreatmentPlanEmployeeReducer,
  statisticCoordinatorUserReducer,
  statisticEmployeeDoctorReducer,
} from "./reducers/statisticReducers";

import {
  speakerListReducer,
  speakerDetailsReducer,
  speakerCreateReducer,
  speakerUpdateReducer,
  speakerDeleteReducer,
  speakerAddCourseReducer,
  speakerRemoveCourseReducer,
  speakerStatusUpdateReducer,
  speakerDescriptionUpdateReducer,
  speakerCoursesListReducer,
  speakerImageUpdateReducer,
} from "./reducers/speakerReducers";

import {
  attendeeListReducer,
  attendeeDetailsReducer,
  attendeeCreateReducer,
  attendeeUpdateReducer,
  attendeeDeleteReducer,
  attendeeAddCourseReducer,
  attendeeRemoveCourseReducer,
  attendeeStatusUpdateReducer,
  attendeeCoursesListReducer,
  attendeeCourseStatusUpdateReducer,
  attendeeCoursePaidStatusUpdateReducer,
  attendeeUpdateImageReducer,
} from "./reducers/attendeeReducers";

import {
  courseListReducer,
  courseDetailsReducer,
  courseUpdateReducer,
  courseCreateReducer,
  courseDeleteReducer,
  courseAttendeesListReducer,
  courseAttendeeAddReducer,
  courseAttendeeRemoveReducer,
  courseSpeakersListReducer,
  courseSpeakerAddReducer,
  courseSpeakerRemoveReducer,
  courseStatusUpdateReducer,
  courseAttendeeStatusUpdateReducer,
  courseDescriptionUpdateReducer,
  courseTasksListReducer,
  courseAttendeePaidStatusUpdateReducer,
  courseTaskAddReducer,
  courseTaskUpdateReducer,
  courseTasksPositionsUpdateReducer,
  courseUpdatePricingReducer,
  courseUpdateImageReducer,
  courseTaskRemoveReducer,
  courseTaskSubtaskAddReducer,
  courseTaskSubtaskRemoveReducer,
  courseAttendeePriceUpdateReducer,
} from "./reducers/courseReducers";

import {
  publicPatientTreatmentPlanListReducer,
  publicPatientTreatmentPlanDetailsReducer,
  publicTenantDetailsReducer,
  publicPatientApproveTreatmentPlanReducer,
  publicPatientCancelTreatmentPlanReducer,
  publicPatientDownloadPdfTreatmentPlanReducer,
} from "./reducers/publicReducers";

import {
  clientListReducer,
  clientDetailsReducer,
  clientCreateReducer,
  clientUpdateReducer,
  clientDeleteReducer,
} from "./reducers/clientReducers";

const reducer = combineReducers({
  authLogin: authLoginReducer,

  tenantRegister: tenantRegisterReducer,
  tenantVerify: tenantVerifyReducer,
  tenantDetails: tenantDetailsReducer,
  tenantUpdate: tenantUpdateReducer,
  tenantUpdateAdminUser: tenantUpdateAdminUserReducer,
  tenantUpdateType: tenantUpdateTypeReducer,
  tenantUpdateOperatingRooms: tenantUpdateOperatingRoomsReducer,
  tenantUpdateImage: tenantUpdateImageReducer,

  treatmentStepList: treatmentStepListReducer,
  treatmentStepDetails: treatmentStepDetailsReducer,
  treatmentStepCreate: treatmentStepCreateReducer,
  treatmentStepUpdate: treatmentStepUpdateReducer,
  treatmentStepDelete: treatmentStepDeleteReducer,

  treatmentPlanList: treatmentPlanListReducer,
  treatmentPlanDetails: treatmentPlanDetailsReducer,
  treatmentPlanCreate: treatmentPlanCreateReducer,
  treatmentPlanUpdate: treatmentPlanUpdateReducer,
  treatmentPlanDelete: treatmentPlanDeleteReducer,
  treatmentPlanUpdateServicesOrder: treatmentPlanUpdateServicesOrderReducer,
  treatmentPlanUpdateStatus: treatmentPlanUpdateStatusReducer,
  treatmentPlanApprove: treatmentPlanApproveReducer,
  treatmentPlanCancel: treatmentPlanCancelReducer,
  treatmentPlanStatistics: treatmentPlanStatisticsReducer,
  treatmentPlanStatisticsPerEmployee: treatmentPlanStatisticsPerEmployeeReducer,
  treatmentPlanSendEmail: treatmentPlanSendEmailReducer,
  treatmentPlanUpdateAvailablePublic: treatmentPlanUpdateAvailablePublicReducer,
  treatmentPlanDownloadPdf: treatmentPlanDownloadPdfReducer,

  serviceList: serviceListReducer,
  serviceDetails: serviceDetailsReducer,
  serviceCreate: serviceCreateReducer,
  serviceUpdate: serviceUpdateReducer,
  serviceDelete: serviceDeleteReducer,
  serviceUpdateStatus: serviceUpdateStatusReducer,

  serviceGroupList: serviceGroupListReducer,
  serviceGroupDetails: serviceGroupDetailsReducer,
  serviceGroupCreate: serviceGroupCreateReducer,
  serviceGroupUpdate: serviceGroupUpdateReducer,
  serviceGroupDelete: serviceGroupDeleteReducer,
  serviceGroupUpdateStatus: serviceGroupUpdateStatusReducer,
  serviceGroupUpdateIsConsulting: serviceGroupUpdateIsConsultingReducer,

  professionList: professionListReducer,
  professionDetails: professionDetailsReducer,
  professionCreate: professionCreateReducer,
  professionUpdate: professionUpdateReducer,
  professionDelete: professionDeleteReducer,
  professionUpdateStatus: professionUpdateStatusReducer,

  professionGroupList: professionGroupListReducer,
  professionGroupDetails: professionGroupDetailsReducer,
  professionGroupCreate: professionGroupCreateReducer,
  professionGroupUpdate: professionGroupUpdateReducer,
  professionGroupDelete: professionGroupDeleteReducer,
  professionGroupUpdateStatus: professionGroupUpdateStatusReducer,
  professionGroupUpdateIsConsulting: professionGroupUpdateIsConsultingReducer,

  employeeList: employeeListReducer,
  employeeDetails: employeeDetailsReducer,
  employeeCreate: employeeCreateReducer,
  employeeUpdate: employeeUpdateReducer,
  employeeDelete: employeeDeleteReducer,

  appointmentList: appointmentListReducer,
  appointmentDetails: appointmentDetailsReducer,
  appointmentCreate: appointmentCreateReducer,
  appointmentUpdate: appointmentUpdateReducer,
  appointmentDelete: appointmentDeleteReducer,

  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  productTopRated: productTopRatedReducer,
  productSupplierAdd: productSupplierAddReducer,
  productSuppliersList: productSuppliersListReducer,
  productSupplierUpdate: productSupplierUpdateReducer,
  productSupplierDelete: productSupplierDeleteReducer,
  productListByCategory: productListByCategoryReducer,

  cart: cartReducer,
  userRegister: userRegisterReducer,
  userCreate: userCreateReducer,
  userDetails: userDetailsReducer,
  userEmployeeDetails: userEmployeeDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userUpdateStatus: userUpdateStatusReducer,
  userPatientsList: userPatientsListReducer,
  userEmployeesList: userEmployeesListReducer,
  userAddNote: userAddNoteReducer,
  userListNotes: userListNotesReducer,
  userCancelAllTreatments: userCancelAllTreatmentsReducer,
  userCreateEmployee: userCreateEmployeeReducer,
  userDoctorServices: userDoctorServicesReducer,
  userAddressStates: userAddressStatesReducer,
  userSendTreatmentPlanListEmail: userSendTreatmentPlanListEmailReducer,
  userPatientsSimpleList: userPatientsSimpleListReducer,

  patientStatistics: patientStatisticsReducer,

  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,

  supplierList: supplierListReducer,
  supplierDetails: supplierDetailsReducer,
  supplierCreate: supplierCreateReducer,
  supplierUpdate: supplierUpdateReducer,
  supplierDelete: supplierDeleteReducer,

  categoryList: categoryListReducer,
  categoryDetails: categoryDetailsReducer,
  categoryCreate: categoryCreateReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryDelete: categoryDeleteReducer,

  statisticPatient: statisticPatientReducer,
  statisticTreatmentPlan: statisticTreatmentPlanReducer,
  statisticTopService: statisticTopServiceReducer,
  statisticTopServiceGroup: statisticTopServiceGroupReducer,
  statisticTopEmployee: statisticTopEmployeeReducer,
  statisticCoordinator: statisticCoordinatorReducer,
  statisticTopServiceDoctor: statisticTopServiceDoctorReducer,
  statisticTreatmentPlanEmployee: statisticTreatmentPlanEmployeeReducer,
  statisticCoordinatorUser: statisticCoordinatorUserReducer,
  statisticEmployeeDoctor: statisticEmployeeDoctorReducer,

  speakerList: speakerListReducer,
  speakerDetails: speakerDetailsReducer,
  speakerCreate: speakerCreateReducer,
  speakerUpdate: speakerUpdateReducer,
  speakerDelete: speakerDeleteReducer,
  speakerAddCourse: speakerAddCourseReducer,
  speakerRemoveCourse: speakerRemoveCourseReducer,
  speakerStatusUpdate: speakerStatusUpdateReducer,
  speakerDescriptionUpdate: speakerDescriptionUpdateReducer,
  speakerCoursesList: speakerCoursesListReducer,
  speakerUpdateImage: speakerImageUpdateReducer,

  attendeeList: attendeeListReducer,
  attendeeDetails: attendeeDetailsReducer,
  attendeeCreate: attendeeCreateReducer,
  attendeeUpdate: attendeeUpdateReducer,
  attendeeDelete: attendeeDeleteReducer,
  attendeeAddCourse: attendeeAddCourseReducer,
  attendeeRemoveCourse: attendeeRemoveCourseReducer,
  attendeeStatusUpdate: attendeeStatusUpdateReducer,
  attendeeCoursesList: attendeeCoursesListReducer,
  attendeeCourseStatusUpdate: attendeeCourseStatusUpdateReducer,
  attendeeCoursePaidStatusUpdate: attendeeCoursePaidStatusUpdateReducer,
  attendeeUpdateImage: attendeeUpdateImageReducer,

  courseList: courseListReducer,
  courseDetails: courseDetailsReducer,
  courseUpdate: courseUpdateReducer,
  courseCreate: courseCreateReducer,
  courseDelete: courseDeleteReducer,
  courseAttendeesList: courseAttendeesListReducer,
  courseAttendeeAdd: courseAttendeeAddReducer,
  courseAttendeeRemove: courseAttendeeRemoveReducer,
  courseSpeakersList: courseSpeakersListReducer,
  courseSpeakerAdd: courseSpeakerAddReducer,
  courseSpeakerRemove: courseSpeakerRemoveReducer,
  courseStatusUpdate: courseStatusUpdateReducer,
  courseAttendeeStatusUpdate: courseAttendeeStatusUpdateReducer,
  courseDescriptionUpdate: courseDescriptionUpdateReducer,
  courseTasksList: courseTasksListReducer,
  courseAttendeePaidStatusUpdate: courseAttendeePaidStatusUpdateReducer,
  courseTaskAdd: courseTaskAddReducer,
  courseTaskUpdate: courseTaskUpdateReducer,
  courseTasksPositionsUpdate: courseTasksPositionsUpdateReducer,
  courseUpdatePricing: courseUpdatePricingReducer,
  courseUpdateImage: courseUpdateImageReducer,
  courseTaskRemove: courseTaskRemoveReducer,
  courseTaskSubtaskAdd: courseTaskSubtaskAddReducer,
  courseTaskSubtaskRemove: courseTaskSubtaskRemoveReducer,
  courseAttendeePriceUpdate: courseAttendeePriceUpdateReducer,

  publicPatientTreatmentPlanList: publicPatientTreatmentPlanListReducer,
  publicPatientTreatmentPlanDetails: publicPatientTreatmentPlanDetailsReducer,
  publicTenantDetails: publicTenantDetailsReducer,
  publicPatientApproveTreatmentPlan: publicPatientApproveTreatmentPlanReducer,
  publicPatientCancelTreatmentPlan: publicPatientCancelTreatmentPlanReducer,
  publicPatientDownloadPdfTreatmentPlan:
    publicPatientDownloadPdfTreatmentPlanReducer,

  clientList: clientListReducer,
  clientDetails: clientDetailsReducer,
  clientCreate: clientCreateReducer,
  clientUpdate: clientUpdateReducer,
  clientDelete: clientDeleteReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

const paymentMethodFromStorage = localStorage.getItem("paymentMethod")
  ? JSON.parse(localStorage.getItem("paymentMethod"))
  : {};

/* const productFromStorage = localStorage.getItem("product")
  ? JSON.parse(localStorage.getItem("product"))
  : {};
 */
const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
    paymentMethod: paymentMethodFromStorage,
  },
  authLogin: { userInfo: userInfoFromStorage },
  orderPay: { success: false },

  /* productDetails: { product: productFromStorage }, */
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
