import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  listTenantDetails,
  updateTenant,
} from "../../../../actions/tenantActions";
import { useNavigate } from "react-router-dom";
import Panel from "../../../../components/shared/Panel";
import Table from "../../../../components/shared/Table";
import { Box, Select, Input, Flex, useToast } from "@chakra-ui/react";
import ToastComponent from "../../../../components/partials/ToastComponent";
import useRoleCheck from "../../../../hooks/useRoleCheck";
import TenantInfo from "../partials/TenantInfo";
import { useTranslation } from "react-i18next";
import TenantSettings from "../partials/TenantSettings";
import SidePanel from "../../../../components/sidepanel/SidePanel";
import EditTenantDetails from "../sidepanels/EditTenantDetails";
import { TENANT_UPDATE_RESET } from "../../../../constants/tenantConstants";

const TenantDetails = () => {
  // GENERAL
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const translate = (key) => t(key);

  // STATE

  // REDUX
  const dispatch = useDispatch();

  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo } = authLogin;

  const tenantDetails = useSelector((state) => state.tenantDetails);
  const { loading, error, tenant } = tenantDetails;

  const tenantUpdate = useSelector((state) => state.tenantUpdate);
  const { success: successUpdate } = tenantUpdate;

  const tenantUpdateType = useSelector((state) => state.tenantUpdateType);
  const { success: successUpdateType } = tenantUpdateType;

  // SIDEPANEL
  const [sidepanel, setSidepanel] = useState(false);

  // HANDLERS
  const openSidePanel = () => {
    setSidepanel(true);
    window.document.body.style.overflow = "hidden";
  };
  const closeSidePanel = () => {
    setSidepanel(false);
    window.document.body.style.overflow = "auto";
  };

  // USEEFFECT
  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      dispatch(listTenantDetails(userInfo.tenantID));
    }
  }, [dispatch]);

  useEffect(() => {
    if (successUpdate) {
      toast({
        title: t("toasts.tenantDetailsUpdate"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      dispatch({ type: TENANT_UPDATE_RESET });
      dispatch(listTenantDetails(userInfo.tenantID));
    } else if (successUpdateType) {
      dispatch({ type: TENANT_UPDATE_RESET });
      dispatch(listTenantDetails(userInfo.tenantID));
    }
  }, [successUpdate, successUpdateType]);

  // LOGS
  // console.log("userInfo", userInfo);
  console.log("tenantDetails", tenant);

  return (
    <>
      {/* HELMET  */}
      <Helmet>
        <title>{t("tenant.pageTitle")}</title>
        <meta name="description" content={t("tenant.pageContent")} />
      </Helmet>

      <SidePanel
        open={sidepanel}
        close={closeSidePanel}
        title={t("patient.createPatientTreatmentPlan")}
        noBorder={true}
      >
        <EditTenantDetails close={closeSidePanel} tenant={tenant} />
      </SidePanel>

      {/* MAIN  */}
      <Panel.Wrapper>
        <Panel.Container>
          <Panel.Body>
            <Flex w="100%" direction="column" p="0 0 3rem">
              <TenantInfo tenant={tenant} editHandler={openSidePanel} />
              <TenantSettings tenant={tenant} />
            </Flex>
          </Panel.Body>
        </Panel.Container>
      </Panel.Wrapper>
    </>
  );
};

export default TenantDetails;
