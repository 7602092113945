import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Box, Flex, Text, Image, Button } from "@chakra-ui/react";
import Select, { components } from "react-select"; // Import components from react-select
import { useSelector, useDispatch } from "react-redux";
import { listSpeakers } from "../../actions/speakerActions";
import { white, textSecondary, newBorder } from "../../utils/colors";
import { useTranslation } from "react-i18next";
import OptionsOutsideSelect from "./OptionsOutsideSelect";

const MultiSpeakerSelectNoInactive = ({
  value,
  onChange,
  isSelectedHidden,
  placeholderText,
  title,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const speakerList = useSelector((state) => state.speakerList);
  const { speakers } = speakerList;

  const [speakerOptions, setSpeakerOptions] = useState([]);

  useEffect(() => {
    dispatch(listSpeakers());

    console.log("Multi Speakers Select No Inactive loaded", speakers);
  }, [dispatch]);

  useEffect(() => {
    if (speakers && speakers.length > 0) {
      const activeSpeakers = speakers.filter(
        (speaker) => speaker.status === "active"
      );

      // Create options for active speakers
      const options = activeSpeakers.map((s) => ({
        value: s._id,
        label: s.firstName + " " + s.lastName,
        image: s.image,
      }));

      setSpeakerOptions([...options]);
    }
  }, [speakers]);

  return (
    <>
      <div>
        <OptionsOutsideSelect
          onChange={onChange}
          isMulti
          options={speakerOptions}
          value={value}
          title={title}
          isSelectedHidden={isSelectedHidden}
          placeholderText={placeholderText}
        />
      </div>
    </>
  );
};

export default MultiSpeakerSelectNoInactive;
