// IMORT START
import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  useToast,
  HStack,
  Input,
  TabPanels,
  TabPanel,
  Text,
  Icon,
  Spinner,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  listTreatmentStepDetails,
  updateTreatmentStep,
} from "../../../../actions/treatmentStepActions";
import {
  newBorder,
  backgroundLight,
  textSecondary,
  textPrimary,
  white,
  primary,
} from "../../../../utils/colors";
import ImageUpload from "../../../../components/input/ImageUpload";
import CitySelect from "../../../../components/select/CitySelect";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import InputField from "../../../../components/input/InputField";
import { useTranslation } from "react-i18next";
import { TbPlus } from "react-icons/tb";
// IMPORT END

const EditTreatmentStep = ({ treatmentStepId }) => {
  // GENERAL
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();

  // TAB
  const tabsData = [{ label: t("treatmentStep.editTreatmentStep") }];
  const [activeTab, setActiveTab] = useState(0);

  // FORM
  const [name, setName] = useState("");
  const [order, setOrder] = useState("");

  // REDUX
  const treatmentStepDetails = useSelector(
    (state) => state.treatmentStepDetails
  );
  const {
    loading: loadingDetails,
    error: errorDetails,
    treatmentStep,
  } = treatmentStepDetails;

  const treatmentStepUpdate = useSelector((state) => state.treatmentStepUpdate);
  const { loading: loadingUpdate, error: errorUpdate } = treatmentStepUpdate;

  const showErrorToast = (error) => {
    toast({
      title: "An error occurred",
      description: error,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  };

  // FUNCTIONS

  const formatDateToInputValue = (isoString) => {
    return new Date(isoString).toISOString().split("T")[0];
  };

  // HANDLERS

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateTreatmentStep({
        _id: treatmentStepId,
        name: name,
        order: order,
      })
    );
  };

  // USEEFFECT

  useEffect(() => {
    if (!treatmentStep.name || treatmentStep._id !== treatmentStepId) {
      dispatch(listTreatmentStepDetails(treatmentStepId));
    } else {
      setName(treatmentStep.name);
      setOrder(treatmentStep.order);
    }
  }, [dispatch, treatmentStepId, treatmentStep]);

  useEffect(() => {
    if (errorDetails) {
      showErrorToast(errorDetails);
    }
    if (errorUpdate) {
      showErrorToast(errorUpdate);
    }
  }, [errorDetails, errorUpdate]);

  // LOGS

  console.log("name", name);
  console.log("order", order);

  // CHAKRA UI
  return (
    <>
      {loadingDetails ? (
        <Flex justify="center" align="center" h="100%">
          <Spinner color={primary} />
        </Flex>
      ) : (
        <form onSubmit={submitHandler}>
          <TabsComponent
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabsData}
            isTitleValid={true}
            isProfessionValid={true}
            disabledTabs={[]}
          >
            <TabPanels m="1.5rem 0 0">
              <TabPanel p={0}>
                <FormControl mb={4}>
                  <FormLabel fontSize="sm">
                    {t("form.treatmentSetpName")}
                  </FormLabel>
                  <Input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    size="lg"
                    fontSize="0.938rem"
                    fontWeight="500"
                    border={`1px solid ${newBorder}`}
                    borderRadius="0.75rem"
                  />
                </FormControl>

                <FormControl mb={4}>
                  <FormLabel fontSize="sm">{t("form.order")}</FormLabel>
                  <Input
                    type="text"
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                    size="lg"
                    fontSize="0.938rem"
                    fontWeight="500"
                    border={`1px solid ${newBorder}`}
                    borderRadius="0.75rem"
                  />
                </FormControl>
              </TabPanel>

              <TabPanel p={0}>test</TabPanel>

              <Flex
                mt={4}
                justify="flex-end"
                direction="column"
                borderTop={`1px solid ${newBorder}`}
                p="2rem 0"
              >
                <Flex justify="flex-end">
                  <Button
                    minWidth="10rem"
                    type="submit"
                    size="lg"
                    bg={primary}
                    color={white}
                    fontSize="md"
                    borderRadius="10rem"
                  >
                    {t("button.saveAndExit")}
                  </Button>

                  {activeTab < 1 && (
                    <Button
                      minWidth="10rem"
                      ml={4}
                      bg={backgroundLight}
                      size="lg"
                      color={textSecondary}
                      fontSize="md"
                      borderRadius="10rem"
                      onClick={() => setActiveTab(activeTab + 1)}
                    >
                      {t("common.continue")}
                    </Button>
                  )}
                </Flex>
              </Flex>
            </TabPanels>
          </TabsComponent>
        </form>
      )}
    </>
  );
};

export default EditTreatmentStep;
