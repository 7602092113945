import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/landing/Login";
import Register from "../pages/landing/Register"; // Placeholder
import NotFound from "../pages/general/NotFound";
import PatientPlansPublic from "../pages/client/patients/single/PatientPlansPublic";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/public/tenant/:tenantId/patient/:patientId/treatment-plans/"
        element={<PatientPlansPublic />}
      />

      {/* Other routes */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default PublicRoutes;
