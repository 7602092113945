import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import { white, newBorder, textPrimary } from "../../utils/colors";

const FormControlDefault = ({
  type,
  isRequired,
  label,
  value,
  placeholder,
  onChange,
  isTouched,
  isError,
  w,
  errorMessage,
  isSmall,
  isDisabled,
}) => {
  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
      isDisabled={isDisabled}
    >
      <FormLabel
        fontSize={isSmall ? "xs" : "sm"}
        position="relative"
        color={textPrimary}
      >
        {label}
        {isError && isTouched && (
          <Box position="absolute" top={-1} right={0} zIndex={2}>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </Box>
        )}
      </FormLabel>
      <Input
        type={type}
        bg={white}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        size={isSmall ? "md" : "lg"}
        fontSize={isSmall ? "sm" : "0.938rem"}
        fontWeight="500"
        color={textPrimary}
        border={`1px solid ${newBorder}`}
        borderRadius="0.75rem"
        boxShadow="none !important"
      />
    </FormControl>
  );
};

export default FormControlDefault;
