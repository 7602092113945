export const PROFESSIONGROUP_LIST_REQUEST = "PROFESSIONGROUP_LIST_REQUEST";
export const PROFESSIONGROUP_LIST_SUCCESS = "PROFESSIONGROUP_LIST_SUCCESS";
export const PROFESSIONGROUP_LIST_FAIL = "PROFESSIONGROUP_LIST_FAIL";

export const PROFESSIONGROUP_DETAILS_REQUEST =
  "PROFESSIONGROUP_DETAILS_REQUEST";
export const PROFESSIONGROUP_DETAILS_SUCCESS =
  "PROFESSIONGROUP_DETAILS_SUCCESS";
export const PROFESSIONGROUP_DETAILS_FAIL = "PROFESSIONGROUP_DETAILS_FAIL";

export const PROFESSIONGROUP_CREATE_REQUEST = "PROFESSIONGROUP_CREATE_REQUEST";
export const PROFESSIONGROUP_CREATE_SUCCESS = "PROFESSIONGROUP_CREATE_SUCCESS";
export const PROFESSIONGROUP_CREATE_FAIL = "PROFESSIONGROUP_CREATE_FAIL";
export const PROFESSIONGROUP_CREATE_RESET = "PROFESSIONGROUP_CREATE_RESET";

export const PROFESSIONGROUP_UPDATE_REQUEST = "PROFESSIONGROUP_UPDATE_REQUEST";
export const PROFESSIONGROUP_UPDATE_SUCCESS = "PROFESSIONGROUP_UPDATE_SUCCESS";
export const PROFESSIONGROUP_UPDATE_FAIL = "PROFESSIONGROUP_UPDATE_FAIL";
export const PROFESSIONGROUP_UPDATE_RESET = "PROFESSIONGROUP_UPDATE_RESET";

export const PROFESSIONGROUP_DELETE_REQUEST = "PROFESSIONGROUP_DELETE_REQUEST";
export const PROFESSIONGROUP_DELETE_SUCCESS = "PROFESSIONGROUP_DELETE_SUCCESS";
export const PROFESSIONGROUP_DELETE_FAIL = "PROFESSIONGROUP_DELETE_FAIL";
export const PROFESSIONGROUP_DELETE_RESET = "PROFESSIONGROUP_DELETE_RESET";

export const PROFESSIONGROUP_UPDATE_STATUS_REQUEST =
  "PROFESSIONGROUP_UPDATE_STATUS_REQUEST";
export const PROFESSIONGROUP_UPDATE_STATUS_SUCCESS =
  "PROFESSIONGROUP_UPDATE_STATUS_SUCCESS";
export const PROFESSIONGROUP_UPDATE_STATUS_FAIL =
  "PROFESSIONGROUP_UPDATE_STATUS_FAIL";
export const PROFESSIONGROUP_UPDATE_STATUS_RESET =
  "PROFESSIONGROUP_UPDATE_STATUS_RESET";

export const PROFESSIONGROUP_UPDATE_ISCONSULTING_REQUEST =
  "PROFESSIONGROUP_UPDATE_ISCONSULTING_REQUEST";
export const PROFESSIONGROUP_UPDATE_ISCONSULTING_SUCCESS =
  "PROFESSIONGROUP_UPDATE_ISCONSULTING_SUCCESS";
export const PROFESSIONGROUP_UPDATE_ISCONSULTING_FAIL =
  "PROFESSIONGROUP_UPDATE_ISCONSULTING_FAIL";
export const PROFESSIONGROUP_UPDATE_ISCONSULTING_RESET =
  "PROFESSIONGROUP_UPDATE_ISCONSULTING_RESET";
