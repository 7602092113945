// FormControlEmployee component

import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
} from "@chakra-ui/react";
import Select from "react-select";
import {
  newBorder,
  white,
  primary,
  textPrimary,
  textSecondary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { listUsersEmployees } from "../../actions/userActions";

const FormControlTenantType = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
  isMulti,
  placeholder,
  noBottom,
}) => {
  // TRANSLATION
  const { t } = useTranslation();

  // Hardcoded options for tenant type
  const tenantTypeOptions = [
    { value: "clinic", label: t("tenant.typeClinic") }, // Assuming you have translations for these
    { value: "lab", label: t("tenant.typeLab") },
  ];

  // Find the selected option based on the current value
  const selectedOption =
    tenantTypeOptions.find((option) => option.value === value) || null;

  console.log("value", value);
  console.log("selectedOption", selectedOption);

  // Custom styles for react-select
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: isError ? "red" : state.isFocused ? newBorder : newBorder,
      borderRadius: "1rem",
      backgroundColor: white,
      border: `1px solid ${newBorder}`,
      fontSize: isSmall ? "0.875rem" : "0.938rem",
      fontWeight: "500",
      minHeight: isSmall ? "2.5rem" : "3rem",
      height: isMulti ? "initial" : isSmall ? "2.5rem" : "3rem",
      padding: "0 0rem",
      ":focus-visible": {
        borderColor: primary,
        boxShadow: `0 0 0 1px ${newBorder}`,
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      color: textPrimary,
      ":hover": {
        bg: "red",
      },
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? primary : null,
      color: state.isFocused ? white : state.data.color,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: primary,
      color: white,
      borderRadius: "0.75rem",
      fontSize: "0.938rem",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: state.data.color,
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      ":hover": {
        backgroundColor: state.data.bgDark,
        color: state.data.color,
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontSize: "11px",
      color: textSecondary,
      fontWeight: "500",
    }),
  };

  console.log("value", value);
  console.log("selectedOption", selectedOption);

  return (
    <FormControl
      mb={noBottom ? 0 : 4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize={isSmall ? "xs" : "sm"} position="relative">
        {label}
        {isError && isTouched && (
          <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
        )}
      </FormLabel>
      <Select
        isMulti={isMulti}
        isSearchable
        value={selectedOption}
        onChange={onChange}
        options={tenantTypeOptions}
        placeholder={placeholder}
        styles={customStyles}
      />
    </FormControl>
  );
};

export default FormControlTenantType;
