import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useRoleCheck from "../hooks/useRoleCheck";

const MenuItems = (rolePermission) => {
  const { t } = useTranslation();

  // ROLES
  const isAdmin = useRoleCheck("admin");

  // console.log("MenuItems component rendered", rolePermission);

  let menuItems = [
    {
      id: 1,
      name: t("menu.dashboard"),
      route: "/dashboard",
      icon: "/icons/dashboard.svg",
      hasSubMenu: false,
      subMenuItems: [],
      isDisabled: false,
    },

    // {
    //   id: 2,
    //   name: t("menu.appointments"),
    //   route: "/appointments",
    //   icon: "/icons/appointments.svg",
    //   hasSubMenu: true,
    //   subMenuItems: [
    //     {
    //       name: t("menu.appointmentCalendar"),
    //       route: "/appointments/calendar",
    //       isDisabled: false,
    //     },
    //     {
    //       name: t("menu.appointmentList"),
    //       route: "/appointments/list",
    //       isDisabled: false,
    //     },
    //   ],
    //   isDisabled: false,
    // },
    {
      id: 3,
      name: t("menu.patients"),
      route: "/patients",
      icon: "/icons/clients.svg",
      hasSubMenu: false,
      subMenuItems: [
        {
          name: t("menu.patientList"),
          route: "/patients/list",
          isDisabled: false,
        },
      ],
      isDisabled: false,
    },

    {
      id: 4,
      name: t("menu.treatmentPlans"),
      route: "/treatment-plans",
      icon: "/icons/treatment.svg",
      hasSubMenu: false,
      subMenuItems: [],
      isDisabled: false,
    },
    {
      id: 6,
      name: t("menu.employees"),
      route: "/employees",
      icon: "/icons/clients.svg",
      hasSubMenu: false,
      subMenuItems: [],
      isDisabled: false,
    },

    // {
    //   id: 23,
    //   name: t("menu.academy"),
    //   route: "/academy",
    //   icon: "/icons/academy.svg",
    //   hasSubMenu: true,
    //   isDisabled: false,
    //   subMenuItems: [
    //     {
    //       name: t("menu.courses"),
    //       route: "academy/courses",
    //       isDisabled: false,
    //     },
    //     {
    //       name: t("menu.speakers"),
    //       route: "academy/speakers",
    //       isDisabled: false,
    //     },
    //     {
    //       name: t("menu.attendees"),
    //       route: "academy/attendees",
    //       isDisabled: false,
    //     },
    //   ],
    // },
    {
      id: 88,
      name: t("menu.settings"),
      route: "/settings",
      icon: "/icons/settings.svg",
      hasSubMenu: true,
      isDisabled: false,
      subMenuItems: [
        {
          name: t("menu.serviceList"),
          route: "settings/services",
          isDisabled: false,
        },
        {
          name: t("menu.serviceGroupList"),
          route: "settings/service-groups",
          isDisabled: false,
        },
        // {
        //   name: t("menu.employees"),
        //   route: "settings/employees",
        //   isDisabled: false,
        // },

        {
          name: t("menu.professions"),
          route: "settings/professions",
          isDisabled: false,
        },
        {
          name: t("menu.professionGroups"),
          route: "settings/profession-groups",
          isDisabled: false,
        },
        {
          name: t("menu.companySettings"),
          route: "settings/company-settings",
          isDisabled: isAdmin ? false : true,
        },
      ],
    },
  ];

  // Filter out settings and appointments menu if user is not an admin
  if (!isAdmin) {
    menuItems = menuItems.filter(
      (menuItem) => menuItem.id !== 10 && menuItem.id !== 2
    );
  }

  return menuItems;
};

export default MenuItems;
