import React, { useEffect, useState } from "react";

import {
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  useToast,
  HStack,
  Input,
  TabPanels,
  TabPanel,
  Text,
  Icon,
  Tooltip,
  Heading,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  listSpeakerDetails,
  updateSpeaker,
  addSpeakerCourse,
  removeSpeakerCourse,
} from "../../../../actions/speakerActions";

import {
  newBorder,
  backgroundLight,
  textSecondary,
  textPrimary,
  white,
  primary,
} from "../../../../utils/colors";
import ImageUpload from "../../../../components/input/ImageUpload";
import CitySelect from "../../../../components/select/CitySelect";
import MultiCourseSelect from "../../../../components/select/MultiCourseSelect";
import MultiCourseSelectNoCurrent from "../../../../components/select/MultiCourseSelectNoCurrent";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import InputField from "../../../../components/input/InputField";
import MiniNoItems from "../../../../components/partials/MiniNoItems";
import CourseList from "./partials/CourseList";
import { useTranslation } from "react-i18next";
import { TbPlus } from "react-icons/tb";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlDatePicker from "../../../../components/input/FormControlDatePicker";
import FormControlAddress from "../../../../components/input/FormControlAddress";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import { shade } from "polished";
import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";

const EditSpeaker = ({ speakerId, close }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();

  const tabsData = [
    { label: t("speaker.details") },
    // { label: t("speaker.courses") },
  ];

  const [activeTab, setActiveTab] = useState(0);
  const [image, setImage] = useState("");
  const [courses, setCourses] = useState([]);
  const [updatedCourses, setUpdatedCourses] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  const speakerDetails = useSelector((state) => state.speakerDetails);
  const { speaker } = speakerDetails;

  const speakerCourseAdd = useSelector((state) => state.speakerCourseAdd) || {};
  const { success: successAddCourse } = speakerCourseAdd;

  const [showAddCourse, setShowAddCourse] = useState(false);
  const [newlyAddedCourses] = useState([]);

  const [emptyCourses] = useState([]);

  const handleShowAddCourse = () => setShowAddCourse(!showAddCourse);

  // CUSTOM COURSES

  const [customCourses, setCustomCourses] = useState([]);

  const customCoursesHandle = (values) => {
    setCustomCourses(values);
    setCourses(
      values.map((course) => {
        return course.value;
      })
    );
  };

  // TOUCHED
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // ERRORS
  const isFirstNameError = firstName === "";
  const isLastNameError = lastName === "";
  const isEmailError = email === "";

  // VALIDATION
  const isFirstNameValid = firstName.length >= 1;
  const isLastNameValid = lastName.length >= 1;
  const isEmailValid = isValidEmail(email);

  // Determine if the Continue button should be disabled
  const isContinueDisabled =
    !isFirstNameValid || !isLastNameValid || !isEmailValid;

  // FUNCTIONS

  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  // SUBMIT

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateSpeaker({
        _id: speakerId,
        firstName: firstName,
        lastName: lastName,
        image: image,
        email: email,
        birthDate: birthDate,
        phone: phone,
        city: city,
        street: street,
        zip: zip,
        country: country,
        state: state,
        courses: updatedCourses,
      })
    );
  };

  const removeCourseHandler = (courseId) => {
    dispatch(removeSpeakerCourse(speakerId, courseId));

    setCourses(courses.filter((course) => course._id !== courseId));

    setUpdatedCourses(updatedCourses.filter((id) => id !== courseId));

    console.log("remove courses", courses);
    console.log("remove updatedCourses", updatedCourses);

    toast({
      title: "Success",
      description: "Course removed from this speaker.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const addCourseHandleNew = (values) => {
    // Get the array of speaker IDs from the values array
    const courseIds = values.map((course) => course.value);

    console.log("courseIds", courseIds);

    // Check if any of the course IDs already exist in the speakers list
    const courseExists = courseIds.some((courseId) =>
      courses.some((a) => a._id === courseId)
    );

    if (courseExists) {
      console.log("Course already assigned");
      toast({
        title: "Error",
        description: "This course is already assigned to this speaker.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      dispatch(addSpeakerCourse(speakerId, courseIds));

      const currentCourses = [...courses];

      setUpdatedCourses([...currentCourses, ...courseIds]);
      toast({
        title: "Success",
        description: "Courses assigned to this speaker.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // You might need to update the course list after the action is dispatched
    }
  };

  useEffect(() => {
    console.log("Edit Speaker loaded", speaker); // Add this line

    if (!speaker.firstName || speaker._id !== speakerId) {
      dispatch(listSpeakerDetails(speakerId));
    } else {
      setFirstName(speaker.firstName);
      setLastName(speaker.lastName);
      setImage(speaker.image);
      setEmail(speaker.email);
      setBirthDate(speaker.birthDate);
      setPhone(speaker.phone);
      setCity(speaker?.address[0]?.city || "");
      setStreet(speaker.address[0]?.street || "");
      setZip(speaker.address[0]?.zip || "");
      setCountry(speaker.address[0]?.country || "");
      setState(speaker.address[0]?.state || "");
      setCourses(speaker.courses);
      setUpdatedCourses(speaker.courses.map((course) => course._id));
    }
  }, [dispatch, speakerId, speaker]);

  // console.log("courses", courses);
  // console.log("customCourses", customCourses);

  return (
    <>
      <Box position="relative">
        <form
          onSubmit={submitHandler}
          style={{
            height: "100%",
          }}
        >
          <SidePanelHeader close={close} title={t("speaker.editSpeaker")} />

          <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
            <Flex
              direction="column"
              w="100%"
              align="flex-start"
              justify="center"
              p="7rem 0 4rem"
            >
              <Heading
                fontSize="1.125rem"
                color={textPrimary}
                fontWeight="600"
                m="1rem 0"
              >
                {t("speaker.details")}
              </Heading>

              <Flex
                direction="column"
                w="100%"
                bg={backgroundLight}
                borderRadius="1rem"
                p="1rem 1.5rem"
              >
                <FormControl mb={4}>
                  <FormLabel fontSize="sm">
                    {t("speaker.speakerImage")}
                  </FormLabel>
                  <ImageUpload
                    currentImage={image}
                    image={image}
                    setImage={setImage}
                  />
                </FormControl>

                <HStack gap={4} w="100%">
                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.firstName")}
                    value={firstName}
                    placeholder={t("placeholder.firstName")}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setFirstNameTouched(true);
                    }}
                    onBlur={() => setFirstNameTouched(true)}
                    isTouched={firstNameTouched}
                    isError={isFirstNameError}
                    w="50%"
                    errorMessage={t("formError.firstNameRequired")}
                  />

                  <FormControlDefault
                    type="text"
                    isRequired
                    label={t("form.lastName")}
                    value={lastName}
                    placeholder={t("placeholder.lastName")}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setLastNameTouched(true);
                    }}
                    onBlur={() => setLastNameTouched(true)}
                    isTouched={lastNameTouched}
                    isError={isLastNameError}
                    w="50%"
                    errorMessage={t("formError.lastNameRequired")}
                  />
                </HStack>

                <FormControlDefault
                  type="email"
                  isRequired
                  label={t("form.email")}
                  value={email}
                  placeholder={t("placeholder.email")}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailTouched(true);
                  }}
                  onBlur={() => setEmailTouched(true)}
                  isTouched={emailTouched}
                  isError={!isEmailValid}
                  w="100%"
                  errorMessage={t("formError.emailInvalid")}
                />

                <HStack gap={4} w="100%">
                  <FormControlDatePicker
                    type="date"
                    isRequired={false}
                    value={birthDate}
                    label={t("form.birthDate")}
                    placeholder={t("placeholder.birthDate")}
                    onChange={(newValue) => {
                      setBirthDate(newValue);
                    }}
                    onOpen={() => {}}
                    w="50%"
                    errorMessage={t("formError.birthDateRequired")}
                  />

                  <FormControlPhone
                    label={t("form.phone")}
                    value={phone}
                    onChange={(newPhone) => {
                      setPhone(newPhone);
                    }}
                    errorMessage={t("formError.phoneRequired")}
                    w="50%"
                    defaultCountry="lt"
                  />
                </HStack>

                <HStack gap={4} w="100%">
                  <FormControlAddress
                    currentValue={street}
                    label={t("form.address")}
                    onAddressSelect={handleAddressSelect}
                    isRequired={false}
                    errorMessage={t("formError.addressRequired")}
                    w="100%"
                    googleApiKey="AIzaSyCNQfHnntkLgj5Es5V8V0H2BfIetOOZ9yw"
                  />

                  <FormControlDefault
                    type="text"
                    isRequired={false}
                    label={t("form.zip")}
                    value={zip}
                    placeholder={t("placeholder.zip")}
                    onChange={(e) => {
                      setZip(e.target.value);
                    }}
                    w="40%"
                    errorMessage={t("formError.zipRequired")}
                  />
                </HStack>

                <HStack gap={4} w="100%">
                  <FormControlDefault
                    type="text"
                    isRequired={false}
                    label={t("form.city")}
                    value={city}
                    placeholder={t("placeholder.city")}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                    w="50%"
                    errorMessage={t("formError.cityRequired")}
                  />

                  <FormControlCountry
                    label={t("form.country")}
                    value={country}
                    onChange={(selectedOption) => {
                      setCountry(selectedOption ? selectedOption.value : "");
                    }}
                    isRequired={false}
                    w="50%"
                    errorMessage={t("formError.countryRequired")}
                  />
                </HStack>
              </Flex>

              <Flex
                mt={4}
                direction="column"
                borderTop={`1px solid ${newBorder}`}
                p="2rem 0 0"
                w="100%"
              >
                <Flex justify="flex-end">
                  <Tooltip
                    bg={white}
                    borderRadius="1rem"
                    p="1rem"
                    label={
                      <Flex
                        direction="column"
                        p="0rem"
                        justify="center"
                        textAlign="center"
                      >
                        {isFirstNameError && (
                          <Text
                            fontSize="13px"
                            color="#e53e3e"
                            whiteSpace="nowrap"
                          >
                            * {t("formError.firstNameRequired")}
                          </Text>
                        )}
                        {isLastNameError && (
                          <Text
                            fontSize="13px"
                            color="#e53e3e"
                            whiteSpace="nowrap"
                          >
                            * {t("formError.lastNameRequired")}
                          </Text>
                        )}
                        {!isEmailValid && (
                          <Text
                            fontSize="13px"
                            color="#e53e3e"
                            whiteSpace="nowrap"
                          >
                            * {t("formError.emailInvalid")}
                          </Text>
                        )}{" "}
                      </Flex>
                    }
                    isDisabled={!isContinueDisabled}
                    hasArrow
                    placement="top"
                    fontSize="md"
                  >
                    <Button
                      minWidth="10rem"
                      type="submit"
                      size="lg"
                      bg={primary}
                      color={white}
                      fontSize="md"
                      borderRadius="10rem"
                      isDisabled={isContinueDisabled}
                      _hover={{
                        bg: shade(0.1, primary),
                      }}
                      ml={4}
                    >
                      {t("speaker.editSpeaker")}
                    </Button>
                  </Tooltip>

                  {activeTab > 0 && (
                    <Button
                      minWidth="8em"
                      mr={4}
                      bg={backgroundLight}
                      border={`1px solid ${newBorder}`}
                      size="lg"
                      color={textSecondary}
                      fontSize="md"
                      fontWeight="500"
                      borderRadius="10rem"
                      onClick={() => setActiveTab(activeTab - 1)}
                      isDisabled={isContinueDisabled}
                      _hover={{
                        bg: shade(0.1, backgroundLight),
                      }}
                    >
                      {t("common.back")}
                    </Button>
                  )}

                  {activeTab < 0 && (
                    <Tooltip
                      bg={white}
                      borderRadius="1rem"
                      p="1rem"
                      label={
                        <Flex
                          direction="column"
                          p="0rem"
                          justify="center"
                          textAlign="center"
                        >
                          {isFirstNameError && (
                            <Text
                              fontSize="13px"
                              color="#e53e3e"
                              whiteSpace="nowrap"
                            >
                              * {t("formError.firstNameRequired")}
                            </Text>
                          )}
                          {isLastNameError && (
                            <Text
                              fontSize="13px"
                              color="#e53e3e"
                              whiteSpace="nowrap"
                            >
                              * {t("formError.lastNameRequired")}
                            </Text>
                          )}
                          {!isEmailValid && (
                            <Text
                              fontSize="13px"
                              color="#e53e3e"
                              whiteSpace="nowrap"
                            >
                              * {t("formError.emailInvalid")}
                            </Text>
                          )}
                        </Flex>
                      }
                      isDisabled={!isContinueDisabled}
                      hasArrow
                      placement="top"
                      fontSize="md"
                    >
                      <Button
                        minWidth="8rem"
                        ml={4}
                        bg={backgroundLight}
                        border={`1px solid ${newBorder}`}
                        size="lg"
                        color={textSecondary}
                        fontSize="md"
                        borderRadius="10rem"
                        onClick={() => setActiveTab(activeTab + 1)}
                        isDisabled={isContinueDisabled}
                        _hover={{
                          bg: shade(0.1, backgroundLight),
                        }}
                      >
                        {t("common.continue")}
                      </Button>
                    </Tooltip>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default EditSpeaker;
