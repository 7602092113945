export const TENANT_REGISTER_REQUEST = "TENANT_REGISTER_REQUEST";
export const TENANT_REGISTER_SUCCESS = "TENANT_REGISTER_SUCCESS";
export const TENANT_REGISTER_FAIL = "TENANT_REGISTER_FAIL";
export const TENANT_REGISTER_RESET = "TENANT_REGISTER_RESET";

export const TENANT_VERIFY_REQUEST = "TENANT_VERIFY_REQUEST";
export const TENANT_VERIFY_SUCCESS = "TENANT_VERIFY_SUCCESS";
export const TENANT_VERIFY_FAIL = "TENANT_VERIFY_FAIL";
export const TENANT_VERIFY_RESET = "TENANT_VERIFY_RESET";

export const TENANT_ALREADY_EXISTS = "TENANT_ALREADY_EXISTS";
export const TENANT_ALREADY_EXISTS_RESET = "TENANT_ALREADY_EXISTS_RESET";

export const TENANT_ALREADY_VERIFIED = "TENANT_ALREADY_VERIFIED";
export const TENANT_ALREADY_VERIFIED_RESET = "TENANT_ALREADY_VERIFIED_RESET";

export const TENANT_DETAILS_REQUEST = "TENANT_DETAILS_REQUEST";
export const TENANT_DETAILS_SUCCESS = "TENANT_DETAILS_SUCCESS";
export const TENANT_DETAILS_FAIL = "TENANT_DETAILS_FAIL";
export const TENANT_DETAILS_RESET = "TENANT_DETAILS_RESET";

export const TENANT_UPDATE_REQUEST = "TENANT_UPDATE_REQUEST";
export const TENANT_UPDATE_SUCCESS = "TENANT_UPDATE_SUCCESS";
export const TENANT_UPDATE_FAIL = "TENANT_UPDATE_FAIL";
export const TENANT_UPDATE_RESET = "TENANT_UPDATE_RESET";

export const TENANT_UPDATE_ADMIN_USER_REQUEST =
  "TENANT_UPDATE_ADMIN_USER_REQUEST";
export const TENANT_UPDATE_ADMIN_USER_SUCCESS =
  "TENANT_UPDATE_ADMIN_USER_SUCCESS";
export const TENANT_UPDATE_ADMIN_USER_FAIL = "TENANT_UPDATE_ADMIN_USER_FAIL";
export const TENANT_UPDATE_ADMIN_USER_RESET = "TENANT_UPDATE_ADMIN_USER_RESET";

export const TENANT_UPDATE_TYPE_REQUEST = "TENANT_UPDATE_TYPE_REQUEST";
export const TENANT_UPDATE_TYPE_SUCCESS = "TENANT_UPDATE_TYPE_SUCCESS";
export const TENANT_UPDATE_TYPE_FAIL = "TENANT_UPDATE_TYPE_FAIL";
export const TENANT_UPDATE_TYPE_RESET = "TENANT_UPDATE_TYPE_RESET";

export const TENANT_UPDATE_OPERATING_ROOMS_REQUEST =
  "TENANT_UPDATE_OPERATING_ROOMS_REQUEST";
export const TENANT_UPDATE_OPERATING_ROOMS_SUCCESS =
  "TENANT_UPDATE_OPERATING_ROOMS_SUCCESS";
export const TENANT_UPDATE_OPERATING_ROOMS_FAIL =
  "TENANT_UPDATE_OPERATING_ROOMS_FAIL";
export const TENANT_UPDATE_OPERATING_ROOMS_RESET =
  "TENANT_UPDATE_OPERATING_ROOMS_RESET";

export const TENANT_UPDATE_IMAGE_REQUEST = "TENANT_UPDATE_IMAGE_REQUEST";
export const TENANT_UPDATE_IMAGE_SUCCESS = "TENANT_UPDATE_IMAGE_SUCCESS";
export const TENANT_UPDATE_IMAGE_FAIL = "TENANT_UPDATE_IMAGE_FAIL";
export const TENANT_UPDATE_IMAGE_RESET = "TENANT_UPDATE_IMAGE_RESET";
