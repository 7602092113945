import React, { useState, useMemo, useEffect, Component } from "react";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";
import escapeRegExp from "lodash/escapeRegExp";
import { Box, Spinner, Flex, Text, FormControl } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { listPatientsSimple } from "../../actions/userActions"; // Adjust the import path as needed
import dayjs from "dayjs";
import StatusBadge from "../../components/badges/StatusBadge";
import { tint } from "polished";
import {
  white,
  newBorder,
  textPrimary,
  textSecondary,
  primary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";

const height = 40;

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const FormControlPatientTest = ({
  onChange,
  isError,
  isRequired,
  isTouched,
  w,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { error, loading, patients } = useSelector(
    (state) => state.userPatientsSimpleList
  );
  const [inputValue, setInputValue] = useState("");

  // Fetch patient data on component mount
  useEffect(() => {
    dispatch(listPatientsSimple());
  }, [dispatch]);

  const filteredOptions = useMemo(() => {
    return patients
      .filter(
        (patient) =>
          !inputValue ||
          new RegExp(escapeRegExp(inputValue), "i").test(
            `${patient.firstName} ${patient.lastName}`
          )
      )
      .map((patient) => ({
        value: patient._id,
        label:
          patient.firstName && patient.lastName
            ? `${patient.firstName} ${patient.lastName}`
            : "Loading...",
        firstName: patient.firstName,
        lastName: patient.lastName,
        birthDate: patient.birthDate,
        status: patient.status,
      }));
  }, [inputValue, patients]);

  //   console.log(filteredOptions);

  console.log(inputValue);

  // Custom rendering for options
  const formatOptionLabel = ({ firstName, lastName, birthDate, status }) => (
    <Flex align="center" justify="space-between" w="100%">
      <Flex align="center">
        <Text fontSize="md" fontWeight="500">
          {lastName}{" "}
          <Text as="span" fontWeight="400">
            {firstName}
          </Text>
        </Text>
        <Text ml={2} fontSize="sm">
          {dayjs(birthDate).format("YYYY-MM-DD")}
        </Text>
      </Flex>

      <StatusBadge isSmall options="user" status={status} ml={2} />
    </Flex>
  );

  if (error) return <p>Error: {error}</p>;
  if (loading)
    return (
      <Box>
        <Spinner size="lg" color={primary} />
      </Box>
    );

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <Select
        components={{ MenuList }}
        options={filteredOptions}
        onInputChange={(newInputValue, { action }) => {
          if (action === "input-change") {
            setInputValue(newInputValue);
          }
        }}
        onChange={onChange}
        formatOptionLabel={formatOptionLabel}
        placeholder={t("placeholder.selectPatient")}
        filterOption={() => true} // Since you're manually filtering options, always return true here
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            color: textSecondary,
            borderRadius: "1rem",
            backgroundColor: `${white}`,
            border: `1px solid ${newBorder}`,
            fontSize: "0.938rem",
            fontWeight: "500",
            height: "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
            ":hover": {
              cursor: "pointer",
            },
          }),
          option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : textPrimary, // Color of text in options
            backgroundColor: state.isSelected ? primary : white, // Background color of selected option
            ":hover": {
              backgroundColor: state.isSelected ? primary : tint(0.3, primary), // Background color when hovering
              color: "white",
              cursor: "pointer",
            },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            const color =
              state.data.value === "" ? "desired color" : "default color";

            return { ...provided, opacity, transition, color };
          },
        }}
        noOptionsMessage={() => t("placeholder.noOptions")}
      />
    </FormControl>
  );
};

export default FormControlPatientTest;
