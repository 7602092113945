import React, { useState, useEffect } from "react";
import { Flex, Heading, Text, Button } from "@chakra-ui/react"; // Add these imports
import { useTable, useSortBy } from "react-table";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Box,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import Lottie from "lottie-react"; // Make sure to import Lottie if you're using it
import {
  bgLight,
  backgroundLight,
  textLightBlue,
  primary,
  white,
  textPrimary,
  newBorder,
  textSecondary,
} from "../../utils/colors"; // Assuming colors
import noItemsFound from "../../assets/lottie/no-items-found.json"; // Assuming you have a lottie file
import noServices from "../../assets/images/empty-box.png";

const TableContainerAlt = ({
  navigate,
  loading,
  error,
  isSinglePage,
  columns,
  data,
  className,
  navigateTo,
  noItemsTitle,
  noItemsDescription,
  noItemsButtonHandler,
  noItemsButtonLabel,
  onRowClick,
  toast,
  dispatch,
  deleteHandler,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  // Define a state variable to track if the component is in the initial loading state
  const [initialLoading, setInitialLoading] = useState(true);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <TriangleDownIcon aria-label="sorted descending" />
      ) : (
        <TriangleUpIcon aria-label="sorted ascending" />
      )
    ) : null;
  };

  const navigateFunction = (value) => {
    if (value) {
      navigate(`${navigateTo}/${value}`);
    }
  };

  useEffect(() => {
    // If `loading` is `false`, it means the data has been loaded (either successfully or with an error)
    if (!loading) {
      setInitialLoading(false);
    }
  }, [loading]);

  if (initialLoading) {
    // If the component is in the initial loading state, you can return a loader or null
    return (
      <Flex justify="center" align="center" w="100%" p={20}>
        <Spinner size="xl" color={primary} />
      </Flex>
    );
  }

  // You can add a new condition here to handle the case when the data length is zero
  if (!data || (data.length === 0 && !loading)) {
    return (
      <Flex p="0 0rem" w="100%">
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          margin="0 auto 0rem"
          p="0 0 4rem"
          w="100%"
          borderRadius="1rem"
          bg={white}
          border={
            isSinglePage ? `0px dashed ${newBorder}` : `0px dashed ${newBorder}`
          }
        >
          {!isSinglePage && (
            <Box
              w="100%"
              h="3rem"
              bg={backgroundLight}
              borderBottom={`1px solid ${newBorder}`}
            ></Box>
          )}
          {/* <Image src={images.noData} alt="no Data" /> */}
          <Image h="4rem" w="auto" m="4rem 0 0" src={noServices} />
          {/* <Flex
            maxWidth="600px"
            margin="0 auto"
            overflow="hidden"
            h={isSinglePage ? "160px" : "260px"}
            justifyContent="center"
            alignItems="baseline"
          >
          
            <Lottie
              style={{
                width: "100%",
                height: "100%",
              }}
              animationData={noItemsFound}
              loop={true}
            />
          </Flex> */}

          <Heading
            fontSize="1.375rem"
            fontWeight="600"
            color={textPrimary}
            margin="1.5rem 0 0.5rem"
          >
            {noItemsTitle}
          </Heading>
          <Text color={textSecondary}>{noItemsDescription}</Text>
          {noItemsButtonHandler && (
            <Button
              size={isSinglePage ? "md" : "lg"}
              fontSize={isSinglePage ? "0.875rem" : "1rem"}
              borderRadius="10rem"
              bg={primary}
              color={white}
              m="2rem 0 0"
              onClick={noItemsButtonHandler}
            >
              {noItemsButtonLabel}
            </Button>
          )}
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      {loading ? (
        <Flex justify="center" align="center" w="100%" p={20}>
          <Spinner size="xl" color={primary} />
        </Flex>
      ) : error ? (
        <Flex justify="center" align="center" w="100%" p={20}>
          <Text variant="danger">{error}</Text>
        </Flex>
      ) : (
        <Table
          w="100%"
          // bg={backgroundLight}
          {...getTableProps()}
          className={className}
          // borderRadius="1rem"

          //   borderRadius="1rem"
          //   overflow="hidden"
        >
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    bg={bgLight}
                    color={textLightBlue}
                    fontSize="0.688rem"
                    fontWeight="600"
                    px={2}
                    pt={0}
                    pb={0}
                    py={3}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <Box>
                      {column.render("Header")}
                      <chakra.span pl="1">
                        {generateSortingIndicator(column)}
                      </chakra.span>
                    </Box>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()} w="100%">
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  justify="space-between"
                  align="center"
                  // bg={white}
                  // borderRadius="0.75rem"
                  mb={2}
                  px={0}
                  overflow="hidden"
                  // border={`1px solid ${newBorder}`}
                  {...row.getRowProps()}
                  onClick={(e) => {
                    if (e.target.tagName !== "BUTTON") {
                      onRowClick(row.original); // Pass the user ID to the onRowClick handler
                    }
                  }}
                  borderBottom={`1px solid ${newBorder}`}
                  _hover={{ bg: backgroundLight, cursor: "pointer" }}
                  _last={{ mb: 0, border: "none" }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td
                        // w="100%"
                        px={2}
                        {...cell.getCellProps()}
                        verticalAlign="middle"
                        borderBottom="none"
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
    </>
  );
};

export default TableContainerAlt;
