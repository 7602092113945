// 1. import `extendTheme` function
import { extendTheme } from "@chakra-ui/react";

// 2. Add your color mode config
const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
  fonts: {
    heading: `'Gilroy', sans-serif`,
    body: `'Gilroy', sans-serif`,
  },
  colors: {
    dentacasa: {
      50: "#e6e6ff",
      100: "#b7b7fc",
      200: "#8787f7",
      300: "#5857f3",
      400: "#2b29ef",
      500: "#1410d5",
      600: "#0e0ca7",
      700: "#080878",
      800: "#03054a",
      900: "#01011d",
    },
  },
};

// 3. extend the theme
const theme = extendTheme({ config });

export default theme;
