import React, { useState, useEffect } from "react";
import { Flex, Box, Heading, Select, Text, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  backgroundLight,
  newBorder,
  textPrimary,
  primary,
  buttonGreen,
  buttonAltGreen,
  buttonGreenText,
  white,
  buttonAltPrimary,
} from "../../../../utils/colors";
import BoxUser2 from "./BoxUser2";
import useCountUp from "../../../../hooks/useCountUp";
import dayjs from "dayjs";
import {
  FaFile,
  FaMoneyCheckDollar,
  FaClock,
  FaFileCircleXmark,
  FaCircleCheck,
} from "react-icons/fa6"; // Import the icons

const ServiceGroupsStats = ({ loading, countsByStatus, globalTotalCount }) => {
  // GENERAL
  const { t } = useTranslation();

  //CONSTANTS
  const [globalTotal, setGlobalTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [active, setActive] = useState(0);
  const [inactive, setInactive] = useState(0);

  // ANIMATIONS
  const animatedTotal = useCountUp(total);
  const animatedGlobalTotal = useCountUp(globalTotal);
  const animatedActive = useCountUp(active);
  const animatedInactive = useCountUp(inactive);

  // USE EFFECT

  useEffect(() => {
    if (globalTotalCount) setGlobalTotal(globalTotalCount);

    if (countsByStatus) {
      setTotal(countsByStatus.total || 0);
      setActive(countsByStatus.active || 0);
      setInactive(countsByStatus.inactive || 0);
    }
  }, [countsByStatus, globalTotalCount]);

  // LOGS

  return (
    <Box w="100%" px="2rem" mb={6}>
      <Flex
        w="100%"
        direction="column"
        p="0rem 0rem"
        border={`1px solid ${newBorder}`}
        borderRadius="1rem"
      >
        <Flex w="100%" pt={4} pb={4}>
          <BoxUser2
            loading={loading}
            label={t("dashboard.serviceGroupsTotal")}
            data={animatedTotal}
            of={globalTotal > 0 && globalTotal}
            color={primary}
            icon={FaFile}
            iconColor={white}
          />
          <BoxUser2
            loading={loading}
            label={t("dashboard.serviceGroupsConsulting")}
            data={animatedActive}
            color={buttonAltPrimary}
            icon={FaClock}
            iconColor={textPrimary}
          />
          <BoxUser2
            loading={loading}
            label={t("dashboard.serviceGroupsActive")}
            data={animatedActive}
            color={buttonGreen}
            icon={FaCircleCheck}
            iconColor={buttonGreenText}
          />

          <BoxUser2
            loading={loading}
            label={t("dashboard.serviceGroupsInactive")}
            data={animatedInactive}
            color="#D6D6D7"
            icon={FaFileCircleXmark}
            iconColor={white}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default ServiceGroupsStats;
