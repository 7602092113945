import React from "react";
import {
  Text,
  Avatar,
  Stack,
  HStack,
  Image,
  Circle,
  Box,
  Tooltip,
  Icon,
  Progress,
  Flex,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  textSecondary,
  textPrimary,
  buttonBlue,
  buttonBlueText,
  newBorder,
  buttonGreenText,
  buttonRedText,
  white,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";
import {
  FaRegNoteSticky,
  FaRegCircleCheck,
  FaRegCircle,
  FaRegCircleDot,
} from "react-icons/fa6";
import { format } from "morgan";

export const columns = (
  dispatch,
  translate,
  deleteUserHandler,
  openEditSidePanelModal,
  updateUserStatus,
  page,
  pageSize
) => {
  const roleMapping = {
    employee: translate("role.employee"),
    admin: translate("role.admin"),
    patient: translate("role.patient"),
  };

  const professionMapping = {
    doctor: translate("profession.doctor"),
    administration: translate("profession.administration"),
    nurse: translate("profession.nurse"),
    other: translate("profession.other"),
  };

  return [
    {
      Header: "#",
      id: "index",
      accessor: (_row, i) => {
        // Adjust the index based on the page and pageSize
        const adjustedIndex = (page - 1) * pageSize + i + 1;
        return (
          <Text
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="sm"
            color={textSecondary}
            fontWeight="500"
          >
            {adjustedIndex}
          </Text>
        );
      },
    },
    {
      Header: translate("user.columnUser"),
      disableSortBy: false,
      accessor: (row) => {
        const { firstName, lastName, image, birthDate } = row;

        return (
          <HStack gap={2}>
            {image ? (
              <Image
                w="3rem"
                h="3rem"
                src={image}
                alt={firstName}
                borderRadius="10rem"
                objectFit="cover"
              />
            ) : (
              <Avatar name={firstName} bg="#dceeff" color="#3a74a9" />
            )}
            <Stack gap={0} maxWidth="200px" overflow="hidden">
              <Text
                fontSize="md"
                color={textPrimary}
                fontWeight="600"
                isTruncated // This will add ellipsis
                noOfLines={2} // This will limit the text to two lines
                whiteSpace="normal"
                overflow="hidden"
                w="100%"
                maxWidth="200px"
              >
                {firstName} {lastName}
              </Text>
              {birthDate && (
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  isTruncated
                  noOfLines={1}
                  whiteSpace="normal"
                  overflow="hidden"
                  w="100%"
                  maxWidth="200px"
                >
                  {dayjs(birthDate).format("YYYY-MM-DD")}
                </Text>
              )}
            </Stack>
          </HStack>
        );
      },
    },
    {
      Header: translate("user.columnEmail"),
      accessor: (row) => {
        const { email } = row;

        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {email ? email : "N/A"}
          </Text>
        );
      },
    },
    {
      Header: translate("user.columnRole"),
      accessor: (row) => {
        const { role } = row;
        const displayRole = roleMapping[role] || "N/A"; // This will fall back to "N/A" if the role isn't in the mapping.

        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {displayRole}
          </Text>
        );
      },
    },
    {
      Header: translate("user.columnProfession"),
      accessor: (row) => {
        const { profession } = row;
        const displayProfession = professionMapping[profession] || "N/A"; // This will fall back to "N/A" if the role isn't in the mapping.

        return (
          <Text fontSize="sm" fontWeight="500" color={textSecondary}>
            {displayProfession}
          </Text>
        );
      },
    },
    {
      Header: translate("patient.columnStatus"),
      accessor: (row) => {
        const { status, _id } = row;

        const handleUpdateStatus = (newStatus) => {
          dispatch(updateUserStatus(_id, newStatus));
        };

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
              options="user"
            />
            <MoreButton
              id={_id}
              deleteHandler={deleteUserHandler}
              editHandler={(e) => {
                e.stopPropagation();
                openEditSidePanelModal(_id);
              }}
            />
          </HStack>
        );
      },
    },
  ];
};
