import styled from 'styled-components';

const PanelContainer = styled.div`
  width: 100%;
  padding: 2rem 2rem 6rem;
  display: flex;
  flex-direction: column;
`;

export default PanelContainer;
