import React, { useEffect, useState } from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import Select from "react-select";
import {
  white,
  newBorder,
  textPrimary,
  textSecondary,
  primary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { listProfessions } from "../../actions/professionActions";

const FormControlProfession = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
  onProfessionChange,
}) => {
  //TRANSLATION
  const { t } = useTranslation();

  // REDUX
  const dispatch = useDispatch();

  const professionList = useSelector((state) => state.professionList);
  const { professions } = professionList;

  // CONSTANTS
  const [professionOptions, setProfessionOptions] = useState([]);

  const handleProfessionChange = (selectedOption) => {
    onChange(selectedOption); // existing onChange call
    if (onProfessionChange) {
      onProfessionChange(selectedOption); // call the callback with selected option
    }
  };

  // USE EFFECT LOAD SERVICE GROUPS
  useEffect(() => {
    // Dispatch the action with fetchAll set to true
    dispatch(listProfessions("", "", "", "", "", true));
  }, [dispatch]);

  // USE EFFECT TRANSFORM SERVICE GROUPS FOR REACT SELECT AND SET OPTIONS, EXCLUDE INACTIVE SERVICE GROUPS
  useEffect(() => {
    if (professions && professions.length > 0) {
      const activeProfessions = professions.filter(
        (group) => group.status !== "inactive"
      );

      // Grouping professions by their professionGroup
      const groupedByProfessionGroup = activeProfessions.reduce(
        (acc, group) => {
          acc[group.professionGroup.name] =
            acc[group.professionGroup.name] || [];
          acc[group.professionGroup.name].push({
            value: group._id,
            label: group.name,
            status: group.status,
          });
          return acc;
        },
        {}
      );

      // Transforming into format suitable for react-select
      // This should be done where you are setting the profession options
      const transformedProfessions = professions.map((profession) => ({
        value: profession._id,
        label: profession.name,
        defaultRolePermission: profession.professionGroup.defaultRolePermission, // Include this
      }));

      setProfessionOptions(transformedProfessions);
    }
  }, [professions]);

  // LOG
  console.log("professions", professions);
  // console.log("professionOptions", professionOptions);

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel
        fontSize={isSmall ? "xs" : "sm"}
        position="relative"
        color={textPrimary}
      >
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>

      <Select
        isSearchable
        value={professionOptions.find((option) => option.value === value)}
        onChange={handleProfessionChange}
        options={professionOptions}
        placeholder={t("placeholder.selectProfession")}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,
            color: textSecondary,
            borderRadius: "1rem",
            backgroundColor: `${white}`,
            border: `1px solid ${newBorder}`,
            fontSize: isSmall ? "0.875rem" : "0.938rem",
            fontWeight: "500",
            height: isSmall ? "2.5rem" : "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
          }),
          option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : "black", // Color of text in options
            backgroundColor: state.isSelected ? primary : white, // Background color of selected option
            ":hover": {
              backgroundColor: primary, // Background color when hovering
              color: "white",
            },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            const color =
              state.data.value === "" ? "desired color" : "default color";

            return { ...provided, opacity, transition, color };
          },
        }}
      />
    </FormControl>
  );
};

export default FormControlProfession;
