import React from "react";
import {
  Text,
  Avatar,
  Stack,
  HStack,
  Image,
  Circle,
  Box,
  Tooltip,
  Icon,
  Progress,
  Flex,
} from "@chakra-ui/react";
import {
  primary,
  secondary,
  textSecondary,
  textPrimary,
  buttonBlue,
  buttonBlueText,
  newBorder,
  buttonGreenText,
  buttonRedText,
  white,
  backgroundLight,
  buttonAltPrimary,
} from "../../../../utils/colors";
import dayjs from "dayjs";
import StatusButton from "../../../../components/buttons/StatusButton";
import MoreButton from "../../../../components/buttons/MoreButton";

import { format } from "morgan";
import { FaCircleInfo, FaInfo } from "react-icons/fa6";
import { TbInfoCircle } from "react-icons/tb";

export const columns = (
  dispatch,
  translate,
  deleteServiceHandler,
  openEditSidePanelModal,
  handleSortChange,
  updateServiceStatus,
  isAdmin
) => {
  return [
    // {
    //   Header: "#",
    //   id: "index",
    //   accessor: (_row, i) => {
    //     // Adjust the index based on the page and pageSize
    //     const adjustedIndex = (page - 1) * pageSize + i + 1;
    //     return (
    //       <Text
    //         overflow="hidden"
    //         whiteSpace="nowrap"
    //         fontSize="sm"
    //         color={textSecondary}
    //         fontWeight="500"
    //       >
    //         {adjustedIndex}
    //       </Text>
    //     );
    //   },
    // },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("name")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("service.columnName")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "name",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { name } = row.original;

        // console.log("handleSortChange", handleSortChange);

        return (
          <HStack gap={2}>
            <Stack gap={0} maxWidth="350px" overflow="hidden">
              <Text
                fontSize="15px"
                color={textPrimary}
                fontWeight="600"
                isTruncated // This will add ellipsis
                noOfLines={1} // This will limit the text to two lines
                whiteSpace="nowrap"
                overflow="hidden"
                w="100%"
                maxWidth="100%"
                minWidth="300px"
              >
                {name}
              </Text>
            </Stack>
          </HStack>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("professionGroup")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("profession.columnProfessionGroup")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "professionGroup",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { professionGroup } = row.original;

        const name = professionGroup ? professionGroup.name : "";

        // console.log("professionGroup", professionGroup);
        return (
          <Text fontSize="sm" color={textSecondary} fontWeight="500">
            {name}
          </Text>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("patient.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { status, _id } = row.original;

        const handleUpdateStatus = (newStatus) => {
          dispatch(updateServiceStatus(_id, newStatus));
        };

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={status}
              onUpdateStatus={handleUpdateStatus}
              options="active"
              isUnclickable={!isAdmin}
            />
            <MoreButton
              id={_id}
              deleteHandler={deleteServiceHandler}
              deleteButtonDisabled={!isAdmin}
              editHandler={(e) => {
                e.stopPropagation();
                openEditSidePanelModal(_id);
              }}
              editButtonDisabled={!isAdmin}
            />
          </HStack>
        );
      },
    },
  ];
};
