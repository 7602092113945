import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
  Stack,
  Radio,
  RadioGroup,
  Input,
  Box,
  Tooltip,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import {
  textPrimary,
  textSecondary,
  newBorder,
  buttonRed,
  buttonRedText,
  secondary,
  white,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import useIsMobile from "../../hooks/useIsMobile";

const ConfirmCancelPublicModal = ({
  isOpen,
  onClose,
  confirmAction,
  treatmentPlanDetails,
  reason,
  reasonComment,
  setReason,
  setReasonComment,
}) => {
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  console.log("treatmentPlanDetails", treatmentPlanDetails);

  const reasons = [
    { value: "price", label: t("reason.price"), text: t("reason.priceText") },
    { value: "hr", label: t("reason.hr"), text: t("reason.hrText") },
    {
      value: "treatment",
      label: t("reason.treatment"),
      text: t("reason.treatmentText"),
    },
    {
      value: "custom",
      label: t("reason.custom"),
      text: t("reason.customText"),
    },
  ];

  const handleConfirmAction = () => {
    // Implement the logic to handle confirmation with the selected reason
    // For custom reason, use the customReason state value

    confirmAction();
  };

  const CustomRadio = ({ value, label, text }) => (
    <Box
      onClick={() => setReason(value)}
      p="0.75rem 1rem"
      borderWidth="1px"
      borderRadius="1rem"
      borderColor={reason === value ? "red.500" : "gray.200"}
      bg={reason === value ? "red.50" : ""}
      cursor="pointer"
    >
      <Text
        color={reason === value ? "red.500" : textPrimary}
        fontWeight="600"
        fontSize="sm"
      >
        {label}
      </Text>
      <Text fontSize="13px" color={textSecondary}>
        {text}
      </Text>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isMobile ? false : true}
    >
      <ModalOverlay />
      <ModalContent
        textAlign="center"
        p={["1rem 0", "1rem 0", "1rem 0"]}
        borderRadius="2rem"
        overflow="hidden"
        maxW={["calc(100% - 3rem)", "600px", "600px"]}
      >
        <ModalHeader
          color={textPrimary}
          maxW={["100%", "100%", "100%"]}
          m="0 auto"
          fontSize={["1rem", "1rem", "1.25rem"]}
          p={["1rem 4rem", "2rem", "2rem"]}
        >
          {t("common.approveTreatmentPlanConfirmationTitlePublic")}
        </ModalHeader>
        <ModalCloseButton top={["2rem", "1.5rem", "1.5rem"]} right="1.5rem" />
        <ModalBody
          p="0rem 2rem"
          color={textSecondary}
          fontWeight="500"
          fontSize="1rem"
        >
          <Grid
            align="center"
            justify="space-around"
            w="100%"
            p="2rem 0 2rem"
            templateColumns={[
              "repeat(2, 1fr)",
              "repeat(4, 1fr)",
              "repeat(4, 1fr)",
            ]}
            gap={4}
          >
            <GridItem colSpan={1}>
              <Stack gap={1}>
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  textAlign="left"
                >
                  {t("patient.patient")}
                </Text>
                <Text
                  fontSize="sm"
                  color={textPrimary}
                  fontWeight="600"
                  textAlign="left"
                >
                  {treatmentPlanDetails &&
                    treatmentPlanDetails.patient &&
                    treatmentPlanDetails.patient.firstName +
                      " " +
                      treatmentPlanDetails.patient.lastName}
                </Text>
              </Stack>
            </GridItem>

            <GridItem colSpan={1}>
              <Stack gap={1}>
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  textAlign="left"
                >
                  {t("treatmentPlan.treatmentPlan")}
                </Text>
                <Text
                  fontSize="sm"
                  color={textPrimary}
                  fontWeight="600"
                  textAlign="left"
                >
                  {treatmentPlanDetails && treatmentPlanDetails.treatmentPlanID}
                </Text>
              </Stack>
            </GridItem>

            <GridItem colSpan={1}>
              <Stack gap={1}>
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  textAlign="left"
                >
                  {t("treatmentPlan.createdAt")}
                </Text>
                <Text
                  fontSize="sm"
                  color={textPrimary}
                  fontWeight="600"
                  textAlign="left"
                  textTransform="capitalize"
                >
                  {treatmentPlanDetails &&
                    dayjs(treatmentPlanDetails.createdAt).format("YYYY MMMM D")}
                </Text>
              </Stack>
            </GridItem>

            <GridItem colSpan={1}>
              <Stack gap={1}>
                <Text
                  fontSize="sm"
                  color={textSecondary}
                  fontWeight="500"
                  textAlign="left"
                >
                  {t("treatmentPlan.columnPrice")}
                </Text>
                <Text
                  fontSize="sm"
                  color={textPrimary}
                  fontWeight="600"
                  textAlign="left"
                >
                  {treatmentPlanDetails && treatmentPlanDetails.totalPrice} €
                </Text>
              </Stack>
            </GridItem>
          </Grid>

          <Flex
            p={["0.5rem 0rem 2rem", "1rem 2.5rem 2rem", "1rem 2.5rem 2rem"]}
            borderTop={`1px solid ${newBorder}`}
            borderBottom={`1px solid ${newBorder}`}
          >
            <Stack gap={1} w="100%" p="0rem 0">
              <Text fontSize="sm" color={textSecondary} fontWeight="500" mt={4}>
                {t("treatmentPlan.cancelReasonTitle")}
              </Text>
              <Stack direction="column" spacing={2} mt={4}>
                {reasons.map((reason) => (
                  <CustomRadio
                    key={reason.value}
                    value={reason.value}
                    label={reason.label}
                    text={reason.text}
                  />
                ))}
                {reason === "custom" && (
                  <Input
                    placeholder={t("reason.customPlaceholder")}
                    value={reasonComment}
                    onChange={(e) => setReasonComment(e.target.value)}
                    size="lg"
                    fontSize="sm"
                    borderRadius="1rem"
                    mt={4}
                    fontWeight="500"
                    color={textPrimary}
                  />
                )}
              </Stack>
            </Stack>
          </Flex>
        </ModalBody>

        <ModalFooter
          display="flex"
          justifyContent="center"
          alignItems="center"
          p="1.5rem 0 1rem"
        >
          <Tooltip
            bg={secondary}
            label={t("reason.tooltipSelectReason")}
            color={white}
            fontSize="sm"
            hasArrow
            placement="top"
            borderRadius="1rem"
            p="0.5rem"
            textAlign="center"
            isDisabled={reason}
          >
            <Button
              colorScheme="red"
              size="lg"
              borderRadius="10rem"
              onClick={handleConfirmAction}
              m={2}
              isDisabled={!reason}
            >
              {t("button.cancel")}
            </Button>
          </Tooltip>
          <Button
            variant="ghost"
            size="lg"
            borderRadius="10rem"
            onClick={onClose}
            m={2}
          >
            {t("button.close")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmCancelPublicModal;
