import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";
import {
  textSecondary,
  buttonBlue,
  buttonBlueText,
  buttonBlueDark,
  buttonGreen,
  buttonGreenText,
  buttonGreenDark,
  buttonRed,
  buttonRedText,
  buttonRedDark,
  buttonCyan,
  buttonCyanText,
  buttonCyanDark,
  buttonPurple,
  buttonPurpleText,
  buttonPurpleDark,
  buttonYellow,
  buttonYellowText,
  buttonYellowDark,
  white,
  backgroundLight,
  newBorder,
  buttonOrange,
  buttonOrangeText,
  buttonOrangeDark,
  buttonGreenVivid,
  buttonGreenVividText,
  buttonGreenVividDark,
  buttonAltGrey,
  buttonAltPrimary,
  buttonAltGreen,
  buttonAltYellow,
  buttonAltSuccess,
  buttonAltRed,
  buttonAltBlue,
  text,
  textPrimary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import {
  FaRegCircleDot,
  FaRegCircle,
  FaCircleHalfStroke,
  FaCircleXmark,
  FaClock,
  FaCircleCheck,
  FaCircleExclamation,
  FaCaretDown,
} from "react-icons/fa6";
import { shade } from "polished";

const StatusBadge = ({ status, options, onChange, isSmall, noText }) => {
  const { t } = useTranslation();
  const translate = (key) => t(key);

  if (options === "paid") {
    options = [
      {
        value: "unpaid",
        label: translate("status.unpaid"),
        icon: FaCircleXmark,
        bg: buttonOrange,
        bgDark: buttonOrangeDark,
        color: buttonOrangeText,
        borderColor: buttonOrange,
        bgHover: "#dda3a3",
      },
      {
        value: "paid",
        label: translate("status.paid"),
        icon: FaCircleCheck,
        bg: buttonGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },
    ];
  } else if (options === "treatment") {
    options = [
      {
        value: "draft",
        label: translate("status.draft"),
        icon: FaRegCircle,
        bg: buttonAltGrey,
        bgDark: textSecondary,
        color: textSecondary,
        borderColor: newBorder,
        bgHover: "#ddd",
      },
      {
        value: "approved",
        label: translate("status.treatmentPlanApproved"),
        icon: FaCircleCheck,
        bg: buttonAltGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },

      {
        value: "consulting",
        label: translate("status.treatmentPlanConsulting"),
        icon: FaClock,
        bg: buttonAltPrimary,
        bgDark: buttonBlueDark,
        color: textPrimary,
        borderColor: newBorder,
        bgHover: "#ddd",
      },
      {
        value: "planning",
        label: translate("status.treatmentPlanPlanning"),
        icon: FaClock,
        bg: buttonAltPrimary,
        bgDark: buttonYellowDark,
        color: textPrimary,
        borderColor: buttonYellow,
        bgHover: "#9ebfc2",
      },
      {
        value: "completed",
        label: translate("status.treatmentPlanCompleted"),
        icon: FaCircleCheck,
        bg: buttonAltSuccess,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },
      {
        value: "cancelled",
        label: translate("status.treatmentPlanCancelled"),
        icon: FaCircleXmark,
        bg: buttonAltRed,
        bgDark: buttonRedDark,
        color: buttonRedText,
        borderColor: buttonRed,
        bgHover: "#dda3a3",
      },
      {
        value: "ongoing",
        label: translate("status.ongoing"),
        icon: FaCircleHalfStroke,
        bg: buttonAltYellow,
        bgDark: buttonYellowDark,
        color: textPrimary,
        borderColor: buttonAltYellow,
        bgHover: "#9ebfc2",
      },
    ];
  } else if (options === "treatmentPublic") {
    options = [
      {
        value: "draft",
        label: translate("status.draft"),
        icon: FaRegCircle,
        bg: buttonAltGrey,
        bgDark: textSecondary,
        color: textSecondary,
        borderColor: newBorder,
        bgHover: "#ddd",
      },
      {
        value: "approved",
        label: translate("status.treatmentPlanApproved"),
        icon: FaCircleCheck,
        bg: buttonAltGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },

      {
        value: "consulting",
        label: translate("status.waitingForApprovalPublic"),
        icon: FaClock,
        bg: buttonAltPrimary,
        bgDark: buttonBlueDark,
        color: textPrimary,
        borderColor: newBorder,
        bgHover: shade(0.8, buttonAltPrimary),
      },
      {
        value: "planning",
        label: translate("status.waitingForApprovalPublic"),
        icon: FaClock,
        bg: buttonAltPrimary,
        bgDark: buttonYellowDark,
        color: textPrimary,
        borderColor: buttonYellow,
        bgHover: "#9ebfc2",
      },
      {
        value: "completed",
        label: translate("status.treatmentPlanCompleted"),
        icon: FaCircleCheck,
        bg: buttonAltSuccess,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },
      {
        value: "cancelled",
        label: translate("status.treatmentPlanCancelled"),
        icon: FaCircleXmark,
        bg: buttonAltRed,
        bgDark: buttonRedDark,
        color: buttonRedText,
        borderColor: buttonRed,
        bgHover: "#dda3a3",
      },
      {
        value: "ongoing",
        label: translate("status.ongoing"),
        icon: FaCircleHalfStroke,
        bg: buttonAltYellow,
        bgDark: buttonYellowDark,
        color: textPrimary,
        borderColor: buttonAltYellow,
        bgHover: "#9ebfc2",
      },
    ];
  } else if (options === "user") {
    options = [
      {
        value: "new",
        label: translate("status.patientNew"),
        icon: FaRegCircleDot,
        bg: buttonAltBlue,
        bgDark: buttonBlueDark,
        color: buttonBlueText,
        borderColor: buttonBlue,
        bgHover: "#b6cce1",
      },
      {
        value: "inactive",
        label: translate("status.patientInactive"),
        icon: FaRegCircle,
        bg: buttonAltGrey,
        bgDark: textSecondary,
        color: text,
        borderColor: buttonAltGrey,
        bgHover: "#ddd",
      },

      {
        value: "active",
        label: translate("status.patientActive"),
        icon: FaCircleCheck,
        bg: buttonAltGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonAltGreen,
        bgHover: "#9ebfc2",
      },
      {
        value: "consulting",
        label: translate("status.patientConsulting"),
        icon: FaClock,
        bg: buttonAltPrimary,
        bgDark: buttonBlueDark,
        color: textPrimary,
        borderColor: newBorder,
        bgHover: "#ddd",
      },
      {
        value: "planningTreatment",
        label: translate("status.patientPlanningTreatment"),
        icon: FaClock,
        bg: buttonAltPrimary,
        bgDark: buttonYellowDark,
        color: textPrimary,
        borderColor: buttonYellow,
        bgHover: "#9ebfc2",
      },
      {
        value: "approvedTreatment",
        label: translate("status.patientApprovedTreatment"),
        icon: FaCircleCheck,
        bg: buttonAltGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonAltGreen,
        bgHover: "#9ebfc2",
      },
      {
        value: "ongoingTreatment",
        label: translate("status.patientOngoingTreatment"),
        icon: FaCircleHalfStroke,
        bg: buttonAltYellow,
        bgDark: buttonYellowDark,
        color: buttonYellowText,
        borderColor: buttonAltYellow,
        bgHover: "#9ebfc2",
      },
      {
        value: "cancelledTreatment",
        label: translate("status.patientCancelledTreatment"),
        icon: FaCircleXmark,
        bg: buttonAltRed,
        bgDark: buttonRedDark,
        color: buttonRedText,
        borderColor: buttonRed,
        bgHover: "#dda3a3",
      },
      {
        value: "completedTreatment",
        label: translate("status.patientCompletedTreatment"),
        icon: FaCircleCheck,
        bg: buttonAltSuccess,
        bgDark: buttonGreenVividDark,
        color: textPrimary,
        borderColor: buttonAltSuccess,
        bgHover: "#dda3a3",
      },
    ];
  } else if (options === "appointment") {
    options = [
      {
        value: null,
        label: translate("status.unpaid"),
        icon: FaCircleXmark,
        bg: buttonRed,
        bgDark: buttonRedDark,
        color: buttonRedText,
        borderColor: buttonRed,
        bgHover: "#dda3a3",
      },
      {
        value: "no_appointment",
        label: translate("status.noAppointment"),
        icon: FaCircleXmark,
        bg: buttonRed,
        bgDark: buttonRedDark,
        color: buttonRedText,
        borderColor: buttonRed,
        bgHover: "#dda3a3",
      },
    ];
  } else {
    options = [
      {
        value: "new",
        label: translate("status.new"),
        icon: FaClock,
        bg: buttonYellow,
        bgDark: buttonYellowDark,
        color: buttonYellowText,
        borderColor: buttonYellow,
        bgHover: "#b6cce1",
      },
      {
        value: "cancelled",
        label: translate("status.cancelled"),
        icon: FaCircleXmark,
        bg: buttonRed,
        bgDark: buttonRedDark,
        color: buttonRedText,
        borderColor: buttonRed,
        bgHover: "#dda3a3",
      },
      {
        value: "completed",
        label: translate("status.completed"),
        icon: FaCircleCheck,
        bg: buttonGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },
      {
        value: "planning",
        label: translate("status.planning"),
        icon: FaClock,
        bg: buttonYellow,
        bgDark: buttonYellowDark,
        color: buttonYellowText,
        borderColor: buttonYellow,
        bgHover: "#b6cce1",
      },
      {
        value: "approved",
        label: translate("status.approved"),
        icon: FaCircleCheck,
        bg: buttonGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },
      {
        value: "consulting",
        label: "Consulting",
        icon: FaCircleHalfStroke,
        bg: buttonBlue,
        bgDark: buttonBlueDark,
        color: buttonBlueText,
        borderColor: newBorder,
        bgHover: "#ddd",
      },
      {
        value: "draft",
        label: translate("status.draft"),
        icon: FaRegCircle,
        bg: backgroundLight,
        bgDark: textSecondary,
        color: textSecondary,
        borderColor: newBorder,
        bgHover: "#ddd",
      },
      {
        value: "ongoing",
        label: translate("status.ongoing"),
        icon: FaCircleCheck,
        bg: buttonGreen,
        bgDark: buttonGreenDark,
        color: buttonGreenText,
        borderColor: buttonGreen,
        bgHover: "#9ebfc2",
      },
    ];
  }

  const currentStatusOption = options.find((option) => option.value === status);

  return (
    <Box>
      {status && (
        <Flex
          align="center"
          justify="center"
          bg={currentStatusOption ? currentStatusOption.bg : white}
          borderRadius="10rem"
          p={isSmall ? "0.1rem 0.5rem" : "0.15rem 0.75rem"}
          onClick={() => onChange && onChange(status)}
        >
          <Icon
            h={isSmall ? "0.65rem" : "0.75rem"}
            w={isSmall ? "0.65rem" : "0.75rem"}
            color={
              currentStatusOption ? currentStatusOption.color : textSecondary
            }
            as={FaCircleCheck}
          />
          {!noText && (
            <Text
              ml={isSmall ? 1 : 2}
              color={
                currentStatusOption ? currentStatusOption.color : textSecondary
              }
              fontWeight="500"
              fontSize={isSmall ? "xs" : "sm"}
            >
              {currentStatusOption && currentStatusOption.label}
            </Text>
          )}
        </Flex>
      )}
    </Box>
  );
};

export default StatusBadge;
