import React from 'react';
import {
  Flex,
  Heading,
  Box,
  Button,
  Text,
  Grid,
  Stack,
  Image,
  Card,
  CardBody,
  CardFooter,
  ButtonGroup,
} from '@chakra-ui/react';
import {
  textPrimary,
  textSecondary,
  white,
  newBorder,
  backgroundLight,
} from '../../../../utils/colors';
import { icons } from '../../../../utils/icons';

const CoursesCards = ({ courses, translate }) => {
  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4} p="0 2rem">
      {courses.map((course) => (
        <Card
          key={course._id}
          maxW="sm"
          borderRadius="1rem"
          border={`1px solid ${newBorder}`}
          p="0rem 0rem 1.5rem 0rem"
          boxShadow="none"
          mb={4}
        >
          <CardBody p={0}>
            <Box
              borderTopLeftRadius="0.75rem"
              borderTopRightRadius="0.75rem"
              h="10rem"
              w="100%"
              overflow="hidden"
              bg={backgroundLight}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {course.image ? (
                <Image
                  h="100%"
                  w="100%"
                  objectFit="cover"
                  src={course.image}
                  alt="Green double couch with wooden legs"
                />
              ) : (
                <Image src={icons.noImage} />
              )}
            </Box>
            <Stack spacing="0" p="1rem 1rem 0">
              <Heading fontSize="1rem" fontWeight="600" color={textPrimary}>
                {course.title}
              </Heading>
              <Stack spacing={0.5} py={4}>
                <Flex align="center">
                  <Text
                    fontSize="0.875rem"
                    color={textSecondary}
                    fontWeight="500"
                    w="100px"
                  >
                    Price:
                  </Text>
                  <Text
                    fontSize="0.875rem"
                    color={textSecondary}
                    fontWeight="500"
                  >
                    $ 200
                  </Text>
                </Flex>
                <Flex align="center">
                  <Text
                    fontSize="0.875rem"
                    color={textSecondary}
                    fontWeight="500"
                    w="100px"
                  >
                    Speaker:
                  </Text>
                  <Text
                    fontSize="0.875rem"
                    color={textSecondary}
                    fontWeight="500"
                  >
                    speaker
                  </Text>
                </Flex>
                <Flex align="center">
                  <Text
                    fontSize="0.875rem"
                    color={textSecondary}
                    fontWeight="500"
                    w="100px"
                  >
                    Attendees:
                  </Text>
                  <Text
                    fontSize="0.875rem"
                    color={textSecondary}
                    fontWeight="500"
                  >
                    80/88
                  </Text>
                </Flex>
              </Stack>
            </Stack>
          </CardBody>

          <CardFooter m={0} p="0 1rem">
            <ButtonGroup spacing="0" w="100%">
              {/* <IconButton
                  bg="#dceeff"
                  color="#3a74a9"
                  _hover={{ bg: '#3a74a9', color: white }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openEditSidePanelModal();
                  }}
                  aria-label="Edit"
                  icon={<EditIcon />}
                />
                <IconButton
                  bg="#fbd3d3"
                  color="#bf5959"
                  _hover={{ bg: '#bf5959', color: white }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  aria-label="Delete"
                  icon={<DeleteIcon />}
                /> */}
              <Button
                w="100%"
                bg="#dceeff"
                color="#3a74a9"
                _hover={{ bg: '#3a74a9', color: white }}
                py={4}
                px={8}
                aria-label="Edit"
                fontSize="sm"
              >
                {translate('button.view')}
              </Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      ))}
    </Grid>
  );
};

export default CoursesCards;
