export const ATTENDEE_LIST_REQUEST = "ATTENDEE_LIST_REQUEST";
export const ATTENDEE_LIST_SUCCESS = "ATTENDEE_LIST_SUCCESS";
export const ATTENDEE_LIST_FAIL = "ATTENDEE_LIST_FAIL";

export const ATTENDEE_DETAILS_REQUEST = "ATTENDEE_DETAILS_REQUEST";
export const ATTENDEE_DETAILS_SUCCESS = "ATTENDEE_DETAILS_SUCCESS";
export const ATTENDEE_DETAILS_FAIL = "ATTENDEE_DETAILS_FAIL";
export const ATTENDEE_DETAILS_RESET = "ATTENDEE_DETAILS_RESET";

export const ATTENDEE_UPDATE_REQUEST = "ATTENDEE_UPDATE_REQUEST";
export const ATTENDEE_UPDATE_SUCCESS = "ATTENDEE_UPDATE_SUCCESS";
export const ATTENDEE_UPDATE_FAIL = "ATTENDEE_UPDATE_FAIL";
export const ATTENDEE_UPDATE_RESET = "ATTENDEE_UPDATE_RESET";

export const ATTENDEE_CREATE_REQUEST = "ATTENDEE_CREATE_REQUEST";
export const ATTENDEE_CREATE_SUCCESS = "ATTENDEE_CREATE_SUCCESS";
export const ATTENDEE_CREATE_FAIL = "ATTENDEE_CREATE_FAIL";
export const ATTENDEE_CREATE_RESET = "ATTENDEE_CREATE_RESET";

export const ATTENDEE_DELETE_REQUEST = "ATTENDEE_DELETE_REQUEST";
export const ATTENDEE_DELETE_SUCCESS = "ATTENDEE_DELETE_SUCCESS";
export const ATTENDEE_DELETE_FAIL = "ATTENDEE_DELETE_FAIL";
export const ATTENDEE_DELETE_RESET = "ATTENDEE_DELETE_RESET";

export const ATTENDEE_COURSES_LIST_REQUEST = "ATTENDEE_COURSES_LIST_REQUEST";
export const ATTENDEE_COURSES_LIST_SUCCESS = "ATTENDEE_COURSES_LIST_SUCCESS";
export const ATTENDEE_COURSES_LIST_FAIL = "ATTENDEE_COURSES_LIST_FAIL";

export const ATTENDEE_ADD_COURSE_REQUEST = "ATTENDEE_ADD_COURSE_REQUEST";
export const ATTENDEE_ADD_COURSE_SUCCESS = "ATTENDEE_ADD_COURSE_SUCCESS";
export const ATTENDEE_ADD_COURSE_FAIL = "ATTENDEE_ADD_COURSE_FAIL";
export const ATTENDEE_ADD_COURSE_RESET = "ATTENDEE_ADD_COURSE_RESET";

export const ATTENDEE_REMOVE_COURSE_REQUEST = "ATTENDEE_REMOVE_COURSE_REQUEST";
export const ATTENDEE_REMOVE_COURSE_SUCCESS = "ATTENDEE_REMOVE_COURSE_SUCCESS";
export const ATTENDEE_REMOVE_COURSE_FAIL = "ATTENDEE_REMOVE_COURSE_FAIL";
export const ATTENDEE_REMOVE_COURSE_RESET = "ATTENDEE_REMOVE_COURSE_RESET";

export const ATTENDEE_ACTIVE_STATUS_UPDATE_REQUEST =
  "ATTENDEE_ACTIVE_STATUS_UPDATE_REQUEST";
export const ATTENDEE_ACTIVE_STATUS_UPDATE_SUCCESS =
  "ATTENDEE_ACTIVE_STATUS_UPDATE_SUCCESS";
export const ATTENDEE_ACTIVE_STATUS_UPDATE_FAIL =
  "ATTENDEE_ACTIVE_STATUS_UPDATE_FAIL";
export const ATTENDEE_ACTIVE_STATUS_UPDATE_RESET =
  "ATTENDEE_ACTIVE_STATUS_UPDATE_RESET";

export const ATTENDEE_COURSE_STATUS_UPDATE_REQUEST =
  "ATTENDEE_COURSE_STATUS_UPDATE_REQUEST";
export const ATTENDEE_COURSE_STATUS_UPDATE_SUCCESS =
  "ATTENDEE_COURSE_STATUS_UPDATE_SUCCESS";
export const ATTENDEE_COURSE_STATUS_UPDATE_FAIL =
  "ATTENDEE_COURSE_STATUS_UPDATE_FAIL";
export const ATTENDEE_COURSE_STATUS_UPDATE_RESET =
  "ATTENDEE_COURSE_STATUS_UPDATE_RESET";

export const ATTENDEE_COURSE_PAIDSTATUS_UPDATE_REQUEST =
  "ATTENDEE_COURSE_PAIDSTATUS_UPDATE_REQUEST";
export const ATTENDEE_COURSE_PAIDSTATUS_UPDATE_SUCCESS =
  "ATTENDEE_COURSE_PAIDSTATUS_UPDATE_SUCCESS";
export const ATTENDEE_COURSE_PAIDSTATUS_UPDATE_FAIL =
  "ATTENDEE_COURSE_PAIDSTATUS_UPDATE_FAIL";
export const ATTENDEE_COURSE_PAIDSTATUS_UPDATE_RESET =
  "ATTENDEE_COURSE_PAIDSTATUS_UPDATE_RESET";

export const ATTENDEE_UPDATE_IMAGE_REQUEST = "ATTENDEE_UPDATE_IMAGE_REQUEST";
export const ATTENDEE_UPDATE_IMAGE_SUCCESS = "ATTENDEE_UPDATE_IMAGE_SUCCESS";
export const ATTENDEE_UPDATE_IMAGE_FAIL = "ATTENDEE_UPDATE_IMAGE_FAIL";
export const ATTENDEE_UPDATE_IMAGE_RESET = "ATTENDEE_UPDATE_IMAGE_RESET";
