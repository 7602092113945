import React from "react";
import { Flex, Text, Heading, Icon, Spinner } from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";
import {
  primary,
  white,
  textSecondary,
  textPrimary,
  newBorder,
} from "../../../../utils/colors";

const BoxUser = ({ label, color, data, loading }) => {
  return (
    <Flex
      w="25%"
      justify="center"
      align="center"
      direction="column"
      borderRight={`1px solid ${newBorder}`}
      _last={{ borderRight: "none" }}
    >
      <Flex
        borderRadius="0.75rem"
        bg={color}
        w="2.5rem"
        h="2.5rem"
        jusityf="center"
        align="center"
        textAlign="center"
      >
        <Icon as={FaUser} color={white} w="initial" h="1.25rem" m="0 auto" />
      </Flex>
      <Text mt={2} color={textSecondary} fontSize="sm" fontWeight="500" py={2}>
        {label}
      </Text>
      {!loading ? (
        <Heading size="md" fontWeight="600" color={textPrimary}>
          {data}
        </Heading>
      ) : (
        <Spinner size="md" color={primary} />
      )}
    </Flex>
  );
};

export default BoxUser;
