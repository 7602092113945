import React from "react";
import { Routes, Route } from "react-router-dom";
import AppointmentList from "../pages/client/appointments/list/AppointmentList";
import AppointmentCalendar from "../pages/client/appointments/calendar/AppointmentCalendar";
import PatientList from "../pages/client/patients/list/PatientList";
import EmployeeList from "../pages/client/employees/list/EmployeeList";
import PatientSingle from "../pages/client/patients/single/PatientSingle";
import EmployeeSingle from "../pages/client/employees/single/EmployeeSingle";
import ServiceList from "../pages/client/services/list/ServiceList";
import ServiceGroupList from "../pages/client/serviceGroups/list/ServiceGroupList";
import TreatmentPlanList from "../pages/client/treatmentplans/list/TreatmentPlanList";
import TreatmentStepList from "../pages/client/treatmentsteps/list/TreatmentStepList";
import ProfessionList from "../pages/client/professions/list/ProfessionList";
import ProfessionGroupList from "../pages/client/professionGroups/list/ProfessionGroupList";
import UserList from "../pages/client/users/list/UserList";
import Speakers from "../pages/client/speakers/list/Speakers";
import SpeakerSingle from "../pages/client/speakers/single/SpeakerSingle";
import Attendees from "../pages/client/attendees/list/Attendees";
import AttendeeSingle from "../pages/client/attendees/single/AttendeeSingle";
import Courses from "../pages/client/courses/list/Courses";
import CourseSingle from "../pages/client/courses/single/CourseSingle";
import NotFound from "../pages/general/NotFound";
import Dashboard from "../pages/client/dashboard/Dashboard";
import Profile from "../pages/client/profile/single/Profile";
import Home from "../pages/general/Home";
import PatientPlansPublic from "../pages/client/patients/single/PatientPlansPublic";
import TenantDetails from "../pages/client/settings/single/TenantDetails";

const PrivateRoutes = () => {
  // console.log("PrivateRoutes rendered");

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/appointments" element={<AppointmentList />} />
      <Route path="/appointments/calendar" element={<AppointmentCalendar />} />
      <Route path="/patients" element={<PatientList />} />
      <Route path="/patients/:id" element={<PatientSingle />} />
      <Route path="/employees" element={<EmployeeList />} />
      <Route path="/employees/:id" element={<EmployeeSingle />} />
      <Route path="/settings/services" element={<ServiceList />} />
      <Route path="/settings/service-groups" element={<ServiceGroupList />} />
      <Route path="/settings/company-settings" element={<TenantDetails />} />
      <Route path="/treatment-plans" element={<TreatmentPlanList />} />
      {/* <Route
        path="/treatment-plans/tenantId/patientId/list"
        element={<Dashboard />}
      /> */}
      <Route
        path="/public/tenant/:tenantId/patient/:patientId/treatment-plans/"
        element={<PatientPlansPublic />}
      />
      <Route path="/treatment-steps/list" element={<TreatmentStepList />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/settings/professions" element={<ProfessionList />} />
      <Route path="/academy/speakers" element={<Speakers />} />
      <Route path="/academy/speakers/:id" element={<SpeakerSingle />} />
      <Route path="/academy/attendees" element={<Attendees />} />
      <Route path="/academy/attendees/:id" element={<AttendeeSingle />} />
      <Route path="/academy/courses" element={<Courses />} />
      <Route path="/academy/courses/:id" element={<CourseSingle />} />
      <Route
        path="/settings/profession-groups"
        element={<ProfessionGroupList />}
      />
      <Route path="/settings/admin-users" element={<UserList />} />

      {/* Other routes */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default PrivateRoutes;
