import React from "react";
import {
  Text,
  Image,
  HStack,
  Progress,
  Avatar,
  VStack,
  Stack,
  Box,
  Tooltip,
  Flex,
  Button,
} from "@chakra-ui/react";
import dayjs from "dayjs"; // Import dayjs library
import {
  primary,
  textSecondary,
  textPrimary,
  newBorder,
  white,
  buttonYellowText,
  buttonBlueText,
  buttonGreenText,
  buttonRedText,
  newDarkBlue,
  buttonBlue,
  buttonGreen,
  buttonRed,
  greenVivid,
  buttonRedNew,
} from "../../../../utils/colors";
import MoreButton from "../../../../components/buttons/MoreButton";
import StatusButton from "../../../../components/buttons/StatusButton";
import StatusBadgeAlt from "../../../../components/badges/StatusBadgeAlt";
import TooltipStatusEntry from "../../../../components/partials/TooltipStatusEntry";

export const columns = (
  handleSortChange,
  translate,
  navigate,
  removeCourseHandler
) => {
  return [
    {
      Header: () => (
        <Box onClick={() => handleSortChange("customId")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.customId")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "customId",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { customId } = row.original;

        return (
          <Text
            maxWidth="50px"
            overflow="hidden"
            whiteSpace="nowrap"
            fontSize="11px"
            color={textSecondary}
            fontWeight="500"
          >
            {customId}
          </Text>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("courseStartDate")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("course.courseDate")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "courseStartDate",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { startDate, endDate } = row.original;
        const currentYear = dayjs().year();

        if (
          startDate &&
          endDate &&
          dayjs(startDate).isValid() &&
          dayjs(endDate).isValid()
        ) {
          const startYear = dayjs(startDate).year();
          const endYear = dayjs(endDate).year();
          const displayYear =
            startYear !== currentYear || endYear !== currentYear
              ? ` ${startYear}`
              : "";

          if (dayjs(startDate).isSame(endDate, "day")) {
            return (
              <Stack gap={0} maxWidth="100px">
                <Text
                  fontSize="13px"
                  fontWeight="500"
                  color={textSecondary}
                  textTransform="capitalize"
                  maxWidth="100px"
                >
                  {dayjs(startDate).format("DD MMMM")}
                </Text>
                <Text fontSize="xs" fontWeight="500" color={textSecondary}>
                  {displayYear}
                </Text>
              </Stack>
            );
          } else {
            return (
              <Stack gap={0} maxWidth="100px">
                <Text
                  fontSize="13px"
                  fontWeight="500"
                  color={textSecondary}
                  textTransform="capitalize"
                  maxWidth="100px"
                >
                  {`${dayjs(startDate).format("DD MMM")} - ${dayjs(
                    endDate
                  ).format("DD MMM")}`}
                </Text>
                <Text fontSize="xs" fontWeight="500" color={textSecondary}>
                  {displayYear}
                </Text>
              </Stack>
            );
          }
        } else if (startDate && dayjs(startDate).isValid()) {
          // Display only the startDate
          const startYear = dayjs(startDate).year();
          const displayYear = startYear !== currentYear ? ` ${startYear}` : "";

          return (
            <Stack gap={0}>
              <Text
                fontSize="13px"
                fontWeight="500"
                color={textSecondary}
                textTransform="capitalize"
              >
                {dayjs(startDate).format("DD MMM")}
              </Text>
              <Text fontSize="xs" fontWeight="500" color={textSecondary}>
                {displayYear}
              </Text>
            </Stack>
          );
        } else {
          return (
            <Text fontSize="sm" fontWeight="500" color={textSecondary}>
              -
            </Text>
          );
        }
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("title")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("course.course")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "title",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { title, image, status } = row.original;

        return (
          <HStack gap={2}>
            {image ? (
              <Image
                w="2.5rem"
                h="2.5rem"
                src={image}
                alt={title}
                borderRadius="0.875rem"
                objectFit="cover"
              />
            ) : (
              <Avatar
                w="2.75rem"
                h="2.75rem"
                name={title}
                borderRadius="0.875rem"
              />
            )}
            <Stack gap={0}>
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textPrimary}
                maxWidth="180px"
                isTruncated // This will add ellipsis
                noOfLines={1} // This will limit the text to two lines
                whiteSpace="normal"
                overflow="hidden"
                w="100%"
              >
                {title}
              </Text>
              {/* <Box>
                <StatusBadgeAlt status={status} options="course" isSmall />
              </Box> */}
            </Stack>
          </HStack>
        );
      },
    },

    // {
    //   Header: translate('common.city'),
    //   accessor: (row) => {
    //     const city = row.address.length > 0 ? row.address[0].city : null;

    //     return (
    //       <Text fontSize="sm" fontWeight="500" color={textSecondary}>
    //         {city ? city : '-'}
    //       </Text>
    //     );
    //   },
    // },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("city")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("form.city")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "city",
      disableSortBy: true,
      Cell: ({ row }) => {
        const city =
          row.original.address &&
          row.original.address[0] &&
          row.original.address[0].city
            ? row.original.address[0].city
            : "-";

        return (
          <Text
            fontSize="sm"
            position="relative"
            fontWeight="500"
            color={textSecondary}
          >
            {city && city ? city : "-"}
          </Text>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("totalAttendees")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.attendees")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "totalAttendees",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { attendees, maximumAttendees } = row.original;

        const maxAttendees = maximumAttendees || 0;

        const attendeesCount = attendees.length;
        const registeredCount = attendees.filter(
          (attendee) => attendee.status === "registered"
        ).length;

        const attendedCount = attendees.filter(
          (attendee) => attendee.status === "attended"
        ).length;

        const cancelledCount = attendees.filter(
          (attendee) => attendee.status === "cancelled"
        ).length;

        const confirmedCount = attendees.filter(
          (attendee) => attendee.status === "confirmed"
        ).length;

        const notAttendedCount = attendees.filter(
          (attendee) => attendee.status === "not_attended"
        ).length;

        return (
          <Tooltip
            p={4}
            borderRadius="1rem"
            bg={newDarkBlue}
            label={
              <Flex direction="column" justify="flex-start" align="flex-start">
                <TooltipStatusEntry
                  bg={buttonBlue}
                  color={textPrimary}
                  label={translate("status.registered")}
                  count={registeredCount}
                  icon="FaRegCircleDot"
                />

                <TooltipStatusEntry
                  bg={buttonGreen}
                  color={buttonGreenText}
                  label={translate("status.confirmed")}
                  count={confirmedCount}
                  icon="FaCircleCheck"
                />

                <TooltipStatusEntry
                  bg={greenVivid}
                  color={textPrimary}
                  label={translate("status.attended")}
                  count={attendedCount}
                  icon="FaRegCircleCheck"
                />

                <TooltipStatusEntry
                  bg={buttonRed}
                  color={buttonRedText}
                  label={translate("status.cancelled")}
                  count={cancelledCount}
                  icon="FaCircleXmark"
                />
                <TooltipStatusEntry
                  bg={buttonRedNew}
                  color={white}
                  label={translate("status.notAttended")}
                  count={notAttendedCount}
                  icon="FaCircleExclamation"
                />
              </Flex>
            }
            aria-label="Attendees"
          >
            <Flex direction="column">
              <Flex fontSize="sm" mb="0.25rem">
                <Text fontWeight="500" color={textPrimary}>
                  {attendeesCount}
                </Text>
                {maxAttendees > 0 ? (
                  <Text fontWeight="500" color={textSecondary}>
                    /{maxAttendees}
                  </Text>
                ) : (
                  <Text fontWeight="500" color={textSecondary}>
                    /-
                  </Text>
                )}
              </Flex>
              {attendeesCount > 0 && maxAttendees > 0 && (
                <Progress
                  className="attendeesTotal"
                  value={attendeesCount}
                  max={maxAttendees}
                  height="2px"
                  borderRadius="0.5rem"
                  maxWidth="40px"
                />
              )}
            </Flex>
          </Tooltip>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("common.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { status, _id } = row.original;

        // const handleUpdateStatus = (newStatus) => {
        //   dispatch(updateCourseAttendeeStatus(courseId, attendeeId, newStatus));
        // };

        // console.log("row", row);

        return (
          <HStack gap={0} justify="flex-end">
            <StatusButton
              currentStatus={status}
              options="course"
              isUnclickable
            />

            <MoreButton
              id={_id}
              deleteHandler={removeCourseHandler}
              deleteLabel={translate("speaker.removeCourse")}
              viewId={_id}
              viewLabel={translate("button.viewCourse")}
              viewHandler={(e) => {
                navigate(`/academy/courses/${_id}`);
              }}
            />
          </HStack>
        );
      },
    },
    // {
    //   Header: 'Status',
    //   disableSortBy: false,
    //   accessor: (row) => {
    //     return (
    //       <Flex justifyContent="flex-end">
    //         <Button bg="#E9F4F5" color="#00565C" fontSize="sm" p="0.5rem 2rem">
    //           Completed
    //         </Button>
    //       </Flex>
    //     );
    //   },
    // },
  ];
};
