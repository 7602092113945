import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  useToast,
  HStack,
  Input,
  TabPanels,
  TabPanel,
  Text,
  Icon,
  Select,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, updateUser } from "../../../../actions/userActions";

import {
  newBorder,
  backgroundLight,
  textSecondary,
  textPrimary,
  white,
  primary,
} from "../../../../utils/colors";
import ImageUpload from "../../../../components/input/ImageUpload";
import CitySelect from "../../../../components/select/CitySelect";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import InputField from "../../../../components/input/InputField";
import { useTranslation } from "react-i18next";
import { TbPlus } from "react-icons/tb";

const EditUser = ({ userId }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();

  const tabsData = [{ label: t("user.tabDetails") }];

  const [activeTab, setActiveTab] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [role, setRole] = useState("");
  const [profession, setProfession] = useState("");

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { success } = userUpdate;

  // HANDLERS
  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateUser({
        _id: userId,
        firstName: firstName,
        lastName: lastName,
        image: image,
        email: email,
        birthDate: birthDate,
        role: role,
        profession: profession,
      })
    );
  };

  // USEEFFECT
  useEffect(() => {
    if (!user.firstName || user._id !== userId) {
      dispatch(getUserDetails(userId));
    } else {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setImage(user.image);
      setEmail(user.email);
      setBirthDate(user.birthDate);
      setRole(user.role);
      setProfession(user.profession);
    }
  }, [dispatch, userId, user]);

  console.log("firstName", firstName);
  console.log("lastName", lastName);
  console.log("image", image);
  console.log("email", email);
  console.log("birthDate", birthDate);
  console.log("role", role);
  console.log("profession", profession);

  return (
    <>
      <form onSubmit={submitHandler}>
        <TabsComponent
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabsData}
          isTitleValid={true}
          isProfessionValid={true}
          disabledTabs={[]}
        >
          <TabPanels m="1.5rem 0 0">
            <TabPanel p={0}>
              <FormControl mb={4}>
                <FormLabel fontSize="sm">{t("form.image")}</FormLabel>
                <ImageUpload image={image} setImage={setImage} />
              </FormControl>

              <FormControl mb={4}>
                <FormLabel fontSize="sm">{t("form.firstName")}</FormLabel>
                <Input
                  type="text" // Use type "time" for time input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  size="lg"
                  fontSize="0.938rem"
                  fontWeight="500"
                  border={`1px solid ${newBorder}`}
                  borderRadius="0.75rem"
                />
              </FormControl>

              <FormControl mb={4}>
                <FormLabel fontSize="sm">{t("form.lastName")}</FormLabel>
                <Input
                  type="text" // Use type "time" for time input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  size="lg"
                  fontSize="0.938rem"
                  fontWeight="500"
                  border={`1px solid ${newBorder}`}
                  borderRadius="0.75rem"
                />
              </FormControl>

              <FormControl mb={4}>
                <FormLabel fontSize="sm">{t("form.role")}</FormLabel>
                <Select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  size="lg"
                  fontSize="0.938rem"
                  fontWeight="500"
                  border={`1px solid ${newBorder}`}
                  borderRadius="0.75rem"
                >
                  <option value="" disabled>
                    Select a Role
                  </option>
                  <option value="admin">Admin</option>
                  <option value="employee">Employee</option>
                  <option value="patient">Patient</option>
                </Select>
              </FormControl>

              <InputField
                label={t("form.email")}
                value={email}
                name="email"
                placeholder={t("placeholder.email")}
                onChange={(e) => setEmail(e.target.value)}
              />

              <InputField
                label={t("form.birthDate")}
                value={birthDate}
                name="birthDate"
                placeholder={t("placeholder.birthDate")}
                onChange={(e) => setBirthDate(e.target.value)}
                isDate
              />

              <FormControl mb={4}>
                <FormLabel fontSize="sm">{t("form.profession")}</FormLabel>
                <Select
                  value={profession}
                  onChange={(e) => setProfession(e.target.value)}
                  size="lg"
                  fontSize="0.938rem"
                  fontWeight="500"
                  border={`1px solid ${newBorder}`}
                  borderRadius="0.75rem"
                >
                  <option value="" disabled>
                    {t("placeholder.selectProfession")}
                  </option>
                  <option value="doctor">{t("profession.doctor")}</option>
                  <option value="administration">
                    {t("profession.administration")}
                  </option>
                  <option value="nurse">{t("profession.nurse")}</option>
                  <option value="other">{t("profession.other")}</option>
                </Select>
              </FormControl>
            </TabPanel>

            <TabPanel p={0}>test</TabPanel>

            <Flex
              mt={4}
              justify="flex-end"
              direction="column"
              borderTop={`1px solid ${newBorder}`}
              p="2rem 0"
            >
              <Flex justify="flex-end">
                <Button
                  minWidth="10rem"
                  type="submit"
                  size="lg"
                  bg={primary}
                  color={white}
                  fontSize="md"
                  borderRadius="10rem"
                >
                  {t("user.editUser")}
                </Button>

                {activeTab < 1 && (
                  <Button
                    minWidth="10rem"
                    ml={4}
                    bg={backgroundLight}
                    size="lg"
                    color={textSecondary}
                    fontSize="md"
                    borderRadius="10rem"
                    onClick={() => setActiveTab(activeTab + 1)}
                  >
                    {t("common.continue")}
                  </Button>
                )}
              </Flex>
            </Flex>
          </TabPanels>
        </TabsComponent>
      </form>
    </>
  );
};

export default EditUser;
