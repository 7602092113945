import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMemo, useState } from "react";
import TaskCard from "./TaskCard";
import { FaTrash, FaPlus } from "react-icons/fa6";
import { Box, Flex, Input, Text, IconButton } from "@chakra-ui/react";
import { white, backgroundLight } from "../../utils/colors";

function ColumnContainer({
  column,
  deleteColumn,
  updateColumn,
  createTask,
  tasks,
  deleteTask,
  updateTask,
  courseId,
  onSubtaskAdded,
  maxAttendees,
}) {
  const [editMode, setEditMode] = useState(false);

  const tasksIds = useMemo(() => {
    return tasks.map((task) => task._id);
  }, [tasks]);

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: column.id,
    data: {
      type: "Column",
      column,
    },
    disabled: editMode,
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <Flex
        ref={setNodeRef}
        style={style}
        bg="columnBackgroundColor"
        opacity="0.4"
        border="2px"
        borderColor="pink.500"
        w="250px"
        h="500px"
        maxH="500px"
        rounded="md"
        flexDir="column"
      />
    );
  }

  return (
    <Flex
      ref={setNodeRef}
      style={style}
      bg={backgroundLight}
      w="33.33%"
      maxH="500px"
      minH="500px"
      rounded="md"
      flexDir="column"
    >
      <Flex
        {...attributes}
        {...listeners}
        onClick={() => setEditMode(true)}
        bg={column.bg}
        cursor="grab"
        p="2.5"
        fontWeight="bold"
        alignItems="center"
        justifyContent="space-between"
        borderRadius="0.875rem"
        mb={2}
      >
        <Flex align="center" justify="space-between" gap="2" w="100%">
          <Text
            outline="none"
            px="2"
            color={column.color}
            fontSize="sm"
            fontWeight="600"
            value={column.title}
            onChange={(e) => updateColumn(column.id, e.target.value)}
            autoFocus
            onBlur={() => setEditMode(false)}
            onKeyDown={(e) => {
              if (e.key !== "Enter") return;
              setEditMode(false);
            }}
          >
            {column.title}
          </Text>

          {/* {editMode && (
            <Input
              bg="black"
              focusBorderColor="rose.500"
              rounded="md"
              outline="none"
              fontSize="sm"
              px="2"
              value={column.title}
              onChange={(e) => updateColumn(column.id, e.target.value)}
              autoFocus
              onBlur={() => setEditMode(false)}
              onKeyDown={(e) => {
                if (e.key !== "Enter") return;
                setEditMode(false);
              }}
            />
          )} */}

          <IconButton
            bg="none"
            size="sm"
            color={column.color}
            className="flex gap-2 items-center border-columnBackgroundColor border-2 rounded-md p-4 border-x-columnBackgroundColor hover:bg-mainBackgroundColor hover:text-rose-500 active:bg-black"
            onClick={() => {
              createTask(column.id);
            }}
            _hover={{
              bg: white,
            }}
          >
            <FaPlus />
          </IconButton>
        </Flex>
      </Flex>

      <Flex
        flexDir="column"
        flex="1"
        gap="4"
        p="2"
        overflowX="hidden"
        overflowY="auto"
      >
        <SortableContext items={tasksIds}>
          {tasks.map((task) => (
            <TaskCard
              key={task._id}
              task={task}
              deleteTask={deleteTask}
              updateTask={updateTask}
              courseId={courseId}
              onSubtaskAdded={onSubtaskAdded}
              maxAttendees={maxAttendees}
            />
          ))}
        </SortableContext>
      </Flex>
    </Flex>
  );
}

export default ColumnContainer;
