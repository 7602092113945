import {
  SERVICEGROUP_LIST_REQUEST,
  SERVICEGROUP_LIST_SUCCESS,
  SERVICEGROUP_LIST_FAIL,
  SERVICEGROUP_DETAILS_REQUEST,
  SERVICEGROUP_DETAILS_SUCCESS,
  SERVICEGROUP_DETAILS_FAIL,
  SERVICEGROUP_CREATE_REQUEST,
  SERVICEGROUP_CREATE_SUCCESS,
  SERVICEGROUP_CREATE_FAIL,
  SERVICEGROUP_CREATE_RESET,
  SERVICEGROUP_UPDATE_REQUEST,
  SERVICEGROUP_UPDATE_SUCCESS,
  SERVICEGROUP_UPDATE_FAIL,
  SERVICEGROUP_UPDATE_RESET,
  SERVICEGROUP_DELETE_REQUEST,
  SERVICEGROUP_DELETE_SUCCESS,
  SERVICEGROUP_DELETE_FAIL,
  SERVICEGROUP_UPDATE_STATUS_REQUEST,
  SERVICEGROUP_UPDATE_STATUS_SUCCESS,
  SERVICEGROUP_UPDATE_STATUS_FAIL,
  SERVICEGROUP_UPDATE_STATUS_RESET,
  SERVICEGROUP_UPDATE_ISCONSULTING_REQUEST,
  SERVICEGROUP_UPDATE_ISCONSULTING_SUCCESS,
  SERVICEGROUP_UPDATE_ISCONSULTING_FAIL,
  SERVICEGROUP_UPDATE_ISCONSULTING_RESET,
} from "../constants/serviceGroupConstants";

export const serviceGroupListReducer = (
  state = { serviceGroups: [], countsByStatus: {}, globalTotalCount: 0 },
  action
) => {
  switch (action.type) {
    case SERVICEGROUP_LIST_REQUEST:
      return { loading: true, serviceGroups: [] };
    case SERVICEGROUP_LIST_SUCCESS:
      return {
        loading: false,
        serviceGroups: action.payload.serviceGroups,
        pages: action.payload.pages,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        countsByStatus: action.payload.countsByStatus,
        globalTotalCount: action.payload.globalTotalCount,
      };
    case SERVICEGROUP_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const serviceGroupDetailsReducer = (
  state = { serviceGroup: {} },
  action
) => {
  switch (action.type) {
    case SERVICEGROUP_DETAILS_REQUEST:
      return { loading: true, ...state };
    case SERVICEGROUP_DETAILS_SUCCESS:
      return { loading: false, serviceGroup: action.payload };
    case SERVICEGROUP_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const serviceGroupCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICEGROUP_CREATE_REQUEST:
      return { loading: true };
    case SERVICEGROUP_CREATE_SUCCESS:
      return { loading: false, success: true, serviceGroup: action.payload };
    case SERVICEGROUP_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case SERVICEGROUP_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const serviceGroupUpdateReducer = (
  state = { serviceGroup: {} },
  action
) => {
  switch (action.type) {
    case SERVICEGROUP_UPDATE_REQUEST:
      return { loading: true };
    case SERVICEGROUP_UPDATE_SUCCESS:
      return { loading: false, success: true, serviceGroup: action.payload };
    case SERVICEGROUP_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SERVICEGROUP_UPDATE_RESET:
      return { serviceGroup: {} };
    default:
      return state;
  }
};

export const serviceGroupDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICEGROUP_DELETE_REQUEST:
      return { loading: true };
    case SERVICEGROUP_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SERVICEGROUP_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const serviceGroupUpdateStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICEGROUP_UPDATE_STATUS_REQUEST:
      return { loading: true };
    case SERVICEGROUP_UPDATE_STATUS_SUCCESS:
      return { loading: false, success: true };
    case SERVICEGROUP_UPDATE_STATUS_FAIL:
      return { loading: false, error: action.payload };
    case SERVICEGROUP_UPDATE_STATUS_RESET:
      return {};
    default:
      return state;
  }
};

export const serviceGroupUpdateIsConsultingReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICEGROUP_UPDATE_ISCONSULTING_REQUEST:
      return { loading: true };
    case SERVICEGROUP_UPDATE_ISCONSULTING_SUCCESS:
      return { loading: false, success: true };
    case SERVICEGROUP_UPDATE_ISCONSULTING_FAIL:
      return { loading: false, error: action.payload };
    case SERVICEGROUP_UPDATE_ISCONSULTING_RESET:
      return {};
    default:
      return state;
  }
};
