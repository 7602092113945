import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { login } from "../../actions/authActions";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Image,
  Flex,
  Divider,
  AbsoluteCenter,
  Icon,
  Spinner,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  buttonRedText,
  secondary,
  textPrimary,
  dark,
} from "../../utils/colors";
import { mobile } from "../../utils/breakpoints";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import LanguageButton from "../../components/buttons/LanguageButton";
import logo from "../../assets/icons/logo.svg";
import { motion } from "framer-motion";
import GoogleButton from "../../components/buttons/GoogleButton";
import PasswordInput from "../../components/input/PasswordInput";
import { FaArrowRightLong } from "react-icons/fa6";
import { AUTH_LOGIN_RESET } from "../../constants/authConstants";
import ToastComponent from "../../components/partials/ToastComponent";

const Login = () => {
  const { i18n, t } = useTranslation();
  const toastComponent = ToastComponent();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const storedLanguage = localStorage.getItem("language");
  const initialLanguage = storedLanguage || "lt"; // Default to 'en'
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const authLogin = useSelector((state) => state.authLogin);
  const { userInfo, loading, error } = authLogin;

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const initialLanguage = storedLanguage || "lt";
    setCurrentLanguage(initialLanguage);

    i18n.changeLanguage(initialLanguage);
    dayjs.locale(initialLanguage);
    // eslint-disable-next-line
  }, []);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    dayjs.locale(lng);
    setCurrentLanguage(lng);
    console.log("Language changed to", lng);
  };

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    changeLanguage(selectedLanguage);
  };

  const handleMenuClick = (language) => {
    changeLanguage(language);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // USE EFFECT
  // Separate useEffects for error handling
  useEffect(() => {
    if (error) {
      toastComponent.showToast(t("toasts.loginError"), "error", 3000);
      dispatch({ type: AUTH_LOGIN_RESET });
    }
  }, [error, dispatch, toastComponent, t]);

  console.log("Login page rendered. userInfo:", userInfo);

  useEffect(() => {
    if (userInfo) {
      navigate("/dashboard");
    }
  }, [userInfo, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await dispatch(login(email, password));
      // Navigate to a different page if login is successful, if needed
      // navigate("/some-path");
    } catch (error) {
      // Extract the error message from the error object
      // const errorMessage = error.response?.data.message || error.message;
      // // Display the toast with the extracted error message
      // toastComponent.showToast(errorMessage, "error", 3000);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.login")}</title>
        <meta name="description" content={t("pageDescription.login")} />
      </Helmet>

      <Box
        position="fixed"
        top={["1.5rem", "3rem", "3rem"]}
        right={["1.5rem", "1.5rem", "3rem"]}
        zIndex="9"
      >
        <LanguageButton
          currentLanguage={currentLanguage}
          languages={["en", "lt", "lv", "pl"]}
          onChangeLanguage={handleChangeLanguage}
          onMenuClick={handleMenuClick}
        />
      </Box>

      <Flex
        w="100%"
        h="100vh"
        align="stretch"
        position={["fixed", "relative", "relative"]}
        justify="flex-start"
        bg={white}
      >
        <Box
          position="fixed"
          top="2rem"
          left={["1.5rem", "2rem", "2rem"]}
          zIndex={2}
        >
          <Link to="/">
            <Image src={logo} h="1.5rem" w="initial" />
          </Link>
        </Box>
        <Box
          w={["100%", "100%", "29%"]}
          position={["fixed", "relative", "relative"]}
          h="100%"
          flexShrink={0}
          as={motion.div}
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
          maxH="100vh"
        >
          <Video
            playsInline
            autoPlay
            className="auth-sidebar-video"
            loop
            muted
            //src="https://cdn.dribbble.com/uploads/48226/original/b8bd4e4273cceae2889d9d259b04f732.mp4?1689028949"
            src="https://cdn.dribbble.com/uploads/48292/original/30fd1f7b63806eff4db0d4276eb1ac45.mp4?1689187515"
          ></Video>
        </Box>

        <Flex
          w="100%"
          h={["100%", "100%", "100%"]}
          p={["0px 1.5rem 4rem", "0px", "0px 0px 0px 100px"]}
          align="flex-start"
          justify={["flex-end", "center", "center"]}
          direction="column"
          as={motion.div}
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
          maxH="100vh"
        >
          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
              maxWidth: "410px",
            }}
          >
            <Heading
              fontSize="1.875rem"
              fontWeight="700"
              color={textPrimary}
              mb={6}
            >
              {t("common.loginHeading")}
            </Heading>
            {!loading ? (
              <>
                <Box display={["none", "none", "block"]}>
                  <GoogleButton
                    label={t("common.loginGoogle")}
                    isWhite
                    isDisabled
                  />
                </Box>

                <Box
                  position="relative"
                  py={3}
                  mb={6}
                  display={["none", "none", "block"]}
                >
                  <Divider />
                  <AbsoluteCenter bg="none" px="8">
                    <Text
                      fontSize="sm"
                      whiteSpace="nowrap"
                      color={textSecondary}
                      fontWeight="500"
                    >
                      {t("common.loginOr")}
                    </Text>
                  </AbsoluteCenter>
                </Box>

                <FormControl>
                  <FormLabel htmlFor="email">{t("form.email")}</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    bg={["rgba(255, 255, 255, 0.6)", "none", "none"]}
                    onChange={handleEmailChange}
                    required
                    size="lg"
                    h="3.5rem"
                    fontSize="1rem"
                    fontWeight="500"
                    border={["none", "none", `1px solid ${newBorder}`]}
                    borderRadius={["10rem", "10rem", "1rem"]}
                    placeholder={t("placeholder.email")}
                    mb={4}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize="1rem" htmlFor="password">
                    {t("form.password")}
                  </FormLabel>
                  <PasswordInput
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </FormControl>

                <Flex w="100%" justify="flex-end" align="center" mt={2}>
                  <Button
                    variant="text"
                    fontSize="0.875rem"
                    fontWeight="500"
                    color={textSecondary}
                    p={0}
                    size="sm"
                    _hover={{
                      color: textPrimary,
                    }}
                  >
                    {t("common.forgotPassword")}
                  </Button>
                </Flex>
                <Stack mt={4} mb={10}>
                  <Button
                    type="submit"
                    h="3.5rem"
                    fontSize="md"
                    bg={dark}
                    color={white}
                    w="100%"
                    borderRadius="10rem"
                    _hover={{
                      bg: primary,
                      color: white,
                    }}
                  >
                    {t("common.signin")}
                  </Button>
                </Stack>
              </>
            ) : (
              <Flex direction="column" justify="center" align="center" my={20}>
                <Spinner size="xl" />
              </Flex>
            )}

            <Flex
              mt={4}
              w="100%"
              align="center"
              justify="center"
              position="relative"
            >
              <Text fontSize="0.875rem" fontWeight="500" color={textPrimary}>
                {t("common.dontHaveAccount")}
              </Text>
              <Link to="/register">
                <Button
                  ml={1}
                  variant="link"
                  color={primary}
                  p={0}
                  size="sm"
                  role="group"
                  _hover={{
                    transition: "0.2s all",
                  }}
                >
                  <Text
                    as="span"
                    fontSize="0.875rem"
                    color={primary}
                    _groupHover={{
                      pr: "0.25rem",
                      transition: "0.2s all",
                    }}
                  >
                    {t("common.signUp")}
                  </Text>{" "}
                  <Icon ml={1} h="0.6rem" as={FaArrowRightLong} />
                </Button>
              </Link>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </>
  );
};

const Video = styled.video`
  display: block;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
`;

export default Login;
