import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addCourseTask } from "../../../../../../actions/courseActions";

import {
  Button,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Select,
  HStack,
  Switch,
  Box,
  Heading,
  Checkbox,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  textSecondary,
  textPrimary,
  backgroundLight,
} from "../../../../../../utils/colors";
import { useTranslation } from "react-i18next";
// import SidePanelHeader from "../../../../components/sidepanel/SidePanelHeader";
import FormControlDefault from "../../../../../../components/input/FormControlDefault";
import FormControlTaskType from "../../../../../../components/input/FormControlTaskType";
import { shade } from "polished";
import SidePanelHeader from "../../../../../../components/sidepanel/SidePanelHeader";

const CreateCourseTask = ({ courseId, close, fromColumnId }) => {
  // GENERAL
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // CONSTANTS

  const [title, setTitle] = useState("");
  const [columnId, setColumnId] = useState(fromColumnId || "todo");
  const [professionGroup, setProfessionGroup] = useState();
  const [status, setStatus] = useState("inactive");

  // HANDLERS

  const closeHandler = () => {
    close();
    // dispatch({ type: PROFESSION_CREATE_RESET });
  };

  // CUSTOM COURSES

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(addCourseTask(courseId, title, columnId));
    closeHandler();
  };

  // TOUCHED

  const [titleTouched, setTitleTouched] = useState(false);

  // ERRORS
  const isTitleError = title === "";

  const isTitleValid = title !== "";

  const isCreateDisabled = !isTitleValid;

  // LOGS

  console.log("title", title);
  console.log("columnId", columnId);
  console.log("fromColumnId", fromColumnId);

  return (
    <Box position="relative">
      <form onSubmit={submitHandler}>
        <SidePanelHeader close={close} title={t("course.createTask")} />
        <Box w="100%" h="calc(100vh - 60px)" overflow="scroll" p="0 2rem">
          <Flex
            direction="column"
            w="100%"
            align="flex-start"
            justify="center"
            p="7rem 0 4rem"
          >
            <Flex
              direction="column"
              w="100%"
              bg={white}
              borderRadius="1rem"
              p="0rem 1.5rem"
            >
              <FormControlDefault
                type="text"
                isRequired
                label={t("form.title")}
                value={title}
                placeholder={t("placeholder.title")}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setTitleTouched(true);
                }}
                isTouched={titleTouched}
                isError={isTitleError}
                w="100%"
                errorMessage={t("formError.titleRequired")}
              />
              <FormControlTaskType
                label={t("form.taskStatus")}
                value={columnId}
                onChange={(selectedOption) => {
                  setColumnId(selectedOption.value);
                }}
                w="100%"
              />
            </Flex>

            <Flex
              mt={4}
              direction="column"
              borderTop={`1px solid ${newBorder}`}
              p="2rem 0 3rem"
              w="100%"
            >
              <Flex justify="flex-end">
                <Tooltip
                  bg={white}
                  borderRadius="1rem"
                  p="1rem"
                  label={
                    <Flex
                      direction="column"
                      p="0rem"
                      justify="center"
                      textAlign="center"
                    >
                      {isTitleError && (
                        <Text
                          fontSize="13px"
                          color="#e53e3e"
                          whiteSpace="nowrap"
                        >
                          * {t("formError.titleRequired")}
                        </Text>
                      )}
                    </Flex>
                  }
                  isDisabled={!isCreateDisabled}
                  hasArrow
                  placement="top"
                  fontSize="md"
                >
                  <Button
                    minWidth="10rem"
                    type="submit"
                    size="lg"
                    bg={primary}
                    color={white}
                    fontSize="md"
                    borderRadius="10rem"
                    isDisabled={isCreateDisabled}
                    _hover={{
                      bg: shade(0.1, primary),
                    }}
                    ml={4}
                  >
                    {t("course.createTask")}
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </form>
    </Box>
  );
};

export default CreateCourseTask;
