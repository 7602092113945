import styled from 'styled-components';

const PanelBody = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export default PanelBody;
